/**
 * Created by user on 04.10.16.
 */
import { connect } from 'react-redux';
import { cyclicNotificationEdit, shadeMenuChange, checkTooltip, editClient, cyclicNotificationChange, deleteCyclicNotification, updateCyclicNotification, createCyclicNotification } from 'modules/clients';

import CyclicNotificationModal from './CyclicNotificationModal';

const mapStateToProps = (state) => ({
    client: state.clients.client,
    services: state.company.company.services,
    catalogues: state.company.company.catalogues,
    notifications: state.company.company.notifications,
    cyclicNotification: state.clients.cyclicNotification,
    editClientChange: state.clients.editClientChange,
    errorMenu: state.clients.errorMenu,
    errorMessage: state.clients.errorMessage,
    cyclNotifNameTooltipShow: state.clients.cyclNotifNameTooltipShow,
    fetching: state.clients.fetching
});

export default connect(mapStateToProps, Object.assign({}, {
    cyclicNotificationEdit,
    shadeMenuChange,
    checkTooltip,
    editClient,
    cyclicNotificationChange,
    deleteCyclicNotification,
    updateCyclicNotification,
    createCyclicNotification
}))(CyclicNotificationModal);
