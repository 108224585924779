import React from 'react';
import classes from './CallsMain.module.scss';
import cx from 'classnames';
import Employees from './Employees';
import Lids from './Lids';
import Statistic from './Statistic';

class CallsMain extends React.Component {
    state = {
        contentStatus: 'employees'
    };

    changeContentStatus(status) {
        this.setState({ contentStatus: status });
    }

    render() {
        let content;
        switch (this.state.contentStatus) {
        case 'employees':
            content = <Employees />;
            break;
        case 'lids':
            content = <Lids />;
            break;
        case 'statistic':
            content = <Statistic />;
            break;
        default:
        }

        return (
            <div className={classes.main}>
                <div className={classes.header}>
                    <div
                        className={cx({ [classes.link]: true, [classes.employees]: true, [classes.active]: this.state.contentStatus === 'employees' })}
                        onClick={this.changeContentStatus.bind(this, 'employees')}
                    >
                        <div className={classes.wrapper}>Работники отдела</div>
                    </div>
                    <div
                        className={cx({ [classes.link]: true, [classes.lids]: true, [classes.active]: this.state.contentStatus === 'lids' })}
                        onClick={this.changeContentStatus.bind(this, 'lids')}
                    >
                        <div className={classes.wrapper}>Управление лидами</div>
                    </div>
                    <div
                        className={cx({ [classes.link]: true, [classes.statistic]: true, [classes.active]: this.state.contentStatus === 'statistic' })}
                        onClick={this.changeContentStatus.bind(this, 'statistic')}
                    >
                        <div className={classes.wrapper}>Статистика и анализ</div>
                    </div>
                </div>
                <div className={classes.body}>
                    { content }
                </div>
            </div>
        );
    }
}

export default CallsMain;
