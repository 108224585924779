import { connect } from 'react-redux';
/* import { actions } from '../../modules/settings'; */
import { actions } from 'modules/company';
import { fetchCatalogues, fetchPlainCatalogues } from 'modules/hangar.js';
import {
    showService, editService, checkServiceNameTooltip, changeService
} from './modules/services';

import Services from './Services';

const mapStateToProps = state => ({
    company: state.company.company,
    catalogues: state.hangar.catalogues,
    plainCatalogues: state.hangar.plainCatalogues,
    addDivision: state.settings.addDivision,
    responseErrorMessage: state.services.responseErrorMessage,
    editServiceChange: state.services.editServiceChange
});

export default connect(mapStateToProps, Object.assign(actions, {
    fetchCatalogues,
    fetchPlainCatalogues,
    showService,
    editService,
    checkServiceNameTooltip,
    changeService
}))(Services);
