import { api, httpBuildQuery } from './../utils';
import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------

const REQUEST_CALLS = 'requestCalls';
const RECEIVE_CALLS = 'receiveCalls';

const REQUEST_CALL = 'requestCall';
const RECEIVE_CALL = 'receiveCall';

const REQUEST_STATISTICS = 'requestStatistics';
const RECEIVE_STATISTICS = 'receiveStatistics';
const RECEIVE_ANCHOR_STATISTICS = 'receiveAnchorStatistics';

const ON_CREATE_CALL = 'onCreateCall';
const CREATE_CALL = 'createCall';

const CHANGE_CALL = 'changeCall';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchStoreCall = (call, e) => {
    if (e) {
        e.preventDefault();
    }
    return function(dispatch) {
        dispatch(sendToCreateCall());
        return api('/v1/partner/calls', {
            method: 'POST',
            body: JSON.stringify(call)
        }, dispatch)
            .then(response => {
                dispatch(createCall(response));
            });
    };
};
function sendToCreateCall() {
    return {
        type: ON_CREATE_CALL
    };
}
function createCall(res) {
    return {
        type: CREATE_CALL,
        payload: res
    };
}

export const fetchProcessCall = (call, e) => {
    if (e) {
        e.preventDefault();
    }
    if (!call.employee_id) {
        delete call.employee_id;
    }
    return function(dispatch) {
        dispatch(sendToProcessCall());
        return api('/v1/partner/calls/' + call.id, {
            method: 'PUT',
            body: JSON.stringify(call)
        }, dispatch)
            .then(response => dispatch(receiveCall(response)));
    };
};

function sendToProcessCall() {
    return {
        type: REQUEST_CALL
    };
}

export const changeCall = (property, value, e) => {
    if (e) {
        e.preventDefault();
    }
    return {
        type: CHANGE_CALL,
        payload: { property: property, value: value }
    };
};

export const fetchCalls = (from, to, limit) => {
    return function(dispatch) {
        dispatch(requestCalls());
        return api('/v1/partner/calls?' + httpBuildQuery({ from: from, to: to, limit: limit }, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveCalls(response)));
    };
};

function requestCalls() {
    return {
        type: REQUEST_CALLS
    };
}

function receiveCalls(data) {
    return {
        type: RECEIVE_CALLS,
        payload: data
    };
}

export const fetchCall = (callId) => {
    return function(dispatch) {
        dispatch(requestCall());
        return api('/v1/partner/calls/' + callId, {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveCall(response)));
    };
};

function requestCall() {
    return {
        type: REQUEST_CALL
    };
}

function receiveCall(data) {
    return {
        type: RECEIVE_CALL,
        payload: data
    };
}

export const spreadCalls = () => {
    return function(dispatch) {
        dispatch(requestCalls());
        return api('/v1/partner/calls/spread', {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveCalls(response)));
    };
};

export const fetchStatistics = (data) => {
    return function(dispatch) {
        dispatch(requestStatistics());
        return api('/v1/partner/calls/statistic?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveStatistics(response)));
    };
};

function requestStatistics() {
    return {
        type: REQUEST_STATISTICS
    };
}

function receiveStatistics(responce) {
    return {
        type: RECEIVE_STATISTICS,
        payload: responce
    };
}

export const fetchAnchorStatistics = (data) => {
    return function(dispatch) {
        dispatch(requestStatistics());
        return api('/v1/partner/notifications/statistic?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveAnchorStatistics(response)));
    };
};

function receiveAnchorStatistics(responce) {
    return {
        type: RECEIVE_ANCHOR_STATISTICS,
        payload: responce
    };
}

export const actions = {
    fetchCalls,
    fetchCall,
    fetchStatistics,
    fetchAnchorStatistics,
    changeCall,
    fetchProcessCall,
    fetchStoreCall,
    spreadCalls
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [REQUEST_CALLS]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_CALLS]: (state, action) => {
        return ({ ...state, calls: action.payload.calls, fetching: false });
    },
    [REQUEST_CALL]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_CALL]: (state, action) => {
        const calls = _.clone(state.calls); const indexOfCall = _.findIndex(state.calls, { id: action.payload.call.id });
        if (indexOfCall !== -1) {
            calls[indexOfCall] = action.payload.call;
        }
        return ({ ...state, calls: calls, call: action.payload.call, fetching: false });
    },
    [CHANGE_CALL]: (state, action) => {
        const call = Object.assign({}, state.call);
        call[action.payload.property] = action.payload.value;
        return ({ ...state, call: call });
    },
    [ON_CREATE_CALL]: (state) => {
        return ({ ...state, fetching_create: true });
    },
    [CREATE_CALL]: (state, action) => {
        const calls = state.calls;
        return ({ ...state, calls: calls, call: action.payload.call, fetching_create: false });
    },
    [REQUEST_STATISTICS]: (state) => {
        return ({ ...state, fetching_statistics: true });
    },
    [RECEIVE_STATISTICS]: (state, action) => {
        return ({ ...state, statistics: action.payload, fetching_statistics: false });
    },
    [RECEIVE_ANCHOR_STATISTICS]: (state, action) => {
        return ({ ...state, anchorStatistics: action.payload, fetching_statistics: false });
    }
};

const initialState = {
    fetching: false,
    fetching_create: false,
    fetching_statistics: false,
    calls: [],
    call: {},
    statistics: {},
    anchorStatistics: {}
};

export default function callsReducer(state = initialState, action) {
    state = Object.assign({}, initialState, state);

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
