import { connect } from 'react-redux';
import { actions } from 'modules/company';
import { fetchCatalogues } from 'modules/hangar.js';

import Editor from './Editor';

const mapStateToProps = state => ({
    company: state.company.company,
    responseErrorMessage: state.services.responseErrorMessage,
    resources: state.company.resources,
    fetching: state.company.fetching.services
});

export default connect(mapStateToProps, Object.assign({ fetchCatalogues }, actions))(Editor);
