import React from 'react';
import classes from './Orders.module.scss';
import moment from 'moment';
import OrderModal from './../OrderModal/OrderModal';
import DatePicker from 'components/DatePicker';
import Preloader from 'components/Preloader';
import { formatPhone, HELP_CSS } from 'utils';
import _ from 'lodash';
import ClientSearch from 'components/ClientSearch';
import ClientsList from 'components/ClientsList';

const LIMIT = 15;

class Orders extends React.Component {
    constructor(props) {
        super(props);
        this.emptyBlock = React.createRef();
        this.scrollAction = this.scrollAction.bind(this);
    }

    state = {
        order: null,
        confirmCancelOrder: false
    };

    UNSAFE_componentWillMount() {
        if (!_.get(this.props.company, 'id') && this.props.partner) {
            this.props.fetchCompany(this.props.partner.company_id, [
                'city',
                'divisions',
                'resources',
                'resources.divisions',
                'resources.schedules',
                'files',
                'files.thumbs',
                'notifications',
                'catalogues',
                'services'
            ]);
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scrollAction, false);
        const data = {
            offset: 0,
            limit: LIMIT * this.props.page,
            range_start: this.props.ordersDates.rangeStart.format(),
            range_end: this.props.ordersDates.rangeEnd.format()
        };
        if (this.props.status === 'EXPIRED') {
            data.is_expired = 1;
        } else {
            if (data.status) {
                data.status = this.props.status;
            }
            // data.is_expired = 0;
        }
        if (_.get(this.props.partner, 'company_id')) {
            this.props.fetchOrders(this.props.partner.company_id, data);
        }
        this.props.showHeader('hide');
    }

    componentDidUpdate() {
        if (this.emptyBlock) {
            // this.emptyBlock.height = '100px';
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollAction);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const newParams = nextProps.ordersDates; const oldParams = this.props.ordersDates;
        if (newParams.rangeStart.format() !== oldParams.rangeStart.format() || newParams.rangeEnd.format() !== oldParams.rangeEnd.format() ||
            _.get(nextProps.client, 'id') !== _.get(this.props.client, 'id') || this.props.status !== nextProps.status) {
            const data = {
                offset: 0,
                limit: LIMIT,
                range_start: nextProps.ordersDates.rangeStart.format(),
                range_end: nextProps.ordersDates.rangeEnd.format()
            };
            if (nextProps.client) {
                data.client_angara_id = _.get(_.find(nextProps.client.client_companies), 'angara_client_id', null);
            }
            if (nextProps.status === 'EXPIRED') {
                data.is_expired = 1;
            } else {
                data.status = nextProps.status;
                // data.is_expired = 0;
            }
            this.props.changeOrdersPage(1);
            this.props.fetchOrders(this.props.partner.company_id, data);
        }
    }

    scrollAction() {
        const el = document.getElementById('orders');

        const rect = el.getBoundingClientRect();

        const orders = this.props.orders;
        if (rect.bottom - 20 <= document.documentElement.clientHeight && !this.props.fetching && orders.orders.length < orders.ordersTotalCount) {
            this.fetchNextOrders();
        }
    }

    changeStatus(status) {
        if (this.props.status !== status) {
            this.props.changeStatus(status);
            /*            let data = {
                offset: 0,
                limit: LIMIT,
                range_start: this.props.ordersDates.rangeStart.format(),
                range_end: this.props.ordersDates.rangeEnd.format()
            };
            if (status === 'EXPIRED') {
                data.is_expired = 1;
            } else {
                data.status = status;
                data.is_expired = 0;
            }
            this.props.changeOrdersPage(1);
            this.props.fetchOrders(this.props.partner.company_id, data); */
        }
    }

    fetchNextOrders() {
        const data = {
            offset: LIMIT * this.props.page,
            limit: LIMIT,
            range_start: this.props.ordersDates.rangeStart.format(),
            range_end: this.props.ordersDates.rangeEnd.format()
        };
        if (this.props.status === 'EXPIRED') {
            data.is_expired = 1;
        } else {
            data.status = this.props.status;
            // data.is_expired = 0;
        }
        this.props.changeOrdersPage(this.props.page + 1);
        this.props.fetchOrders(this.props.partner.company_id, data, true);
    }

    switchOrderModal(order, e) {
        if (e) {
            e.preventDefault();
        }

        if (order) {
            const resource = _.find(this.props.company.resources, { angara_id: _.find(order.orderDetails).resource.id });
            order.resourceName = _.get(resource, 'name', '');
        }
        if (order) {
            order = _.cloneDeep(order);
        }

        this.setState({ order: order });
    }

    switchConfirmCancelOrder() {
        const confirmCancelOrder = this.state.confirmCancelOrder;
        this.setState({ confirmCancelOrder: !confirmCancelOrder });
    }

    cancelOrder(id, e) {
        e.preventDefault();
        e.stopPropagation();
        const data = {
            offset: 0,
            limit: LIMIT * this.props.page,
            range_start: this.props.ordersDates.rangeStart.format(),
            range_end: this.props.ordersDates.rangeEnd.format()
        };

        if (this.props.status === 'EXPIRED') {
            data.is_expired = 1;
        } else {
            data.status = this.props.status;
            data.is_expired = 0;
        }

        this.setState({ confirmCancelOrder: false, order: null });
        this.props.cancelOrder(id).then(() => this.props.fetchOrders(this.props.partner.company_id, data));
    }

    executeOrder(id, e) {
        e.preventDefault();
        e.stopPropagation();
        const data = {
            offset: 0,
            limit: LIMIT * this.props.page,
            range_start: this.props.ordersDates.rangeStart.format(),
            range_end: this.props.ordersDates.rangeEnd.format()
        };

        if (this.props.status === 'EXPIRED') {
            data.is_expired = 1;
        } else {
            data.status = this.props.status;
            data.is_expired = 0;
        }
        const details = this.state.order.orderDetails;

        this.setState({ confirmCancelOrder: false, order: null });
        this.props.executeOrder(id, details).then(() => this.props.fetchOrders(this.props.partner.company_id, data));
    }

    changeServicePrice(key, e) {
        const template = /^[0-9]+$/;

        let value = e.target.value;

        const order = this.state.order;
        if (!value) {
            value = 0;
        }

        if (template.test(value)) {
            order.orderDetails[key].price = parseInt(value);

            this.setState({ order: order });
        }
    }

    render() {
        const companyTimeZone = _.get(this.props.company, 'angaraInfo.timezone', 0);

        const orders = _.get(this.props.orders, 'orders', null);

        const status = this.props.status;

        const rangeStart = this.props.ordersDates.rangeStart;

        const rangeEnd = this.props.ordersDates.rangeEnd;

        const statuses = {
            ACTIVE: {
                menuText: ['Активные', 'заказы'],
                orderText: 'в работе',
                headerTitle: <div>Список заказов <span>в работе</span></div>
            },
            EXPIRED: {
                menuText: ['Просроченные', 'заказы (' + _.get(this.props.orders, 'ordersExpiredCount', 0) + '!)'],
                orderText: 'просрочен',
                headerTitle: <div>Список <span>просроченных</span> заказов</div>
            },
            CANCELED: {
                menuText: ['Отмененные', 'заказы'],
                orderText: 'отменен',
                headerTitle: <div>Список <span>отмененных</span> заказов</div>
            },
            EXECUTED: {
                menuText: ['Выполненные', 'заказы (архив)'],
                orderText: 'выполнен',
                headerTitle: <div>Список <span>выполненных</span> заказов</div>
            }
        };

        let orderModal;

        const order = this.state.order;
        if (order) {
            orderModal = (
                <OrderModal
                    order={order}
                    companyTimeZone={companyTimeZone}
                    confirmCancelOrder={this.state.confirmCancelOrder}
                    switchOrderModal={this.switchOrderModal.bind(this)}
                    switchConfirmCancelOrder={this.switchConfirmCancelOrder.bind(this)}
                    cancelOrder={this.cancelOrder.bind(this)}
                    executeOrder={this.executeOrder.bind(this)}
                    changeServicePrice={this.changeServicePrice.bind(this)}
                />
            );
        }

        const ordersList = {}; let listDate;
        _.map(orders, order => {
            if (order.isExpired) {
                order.status = 'EXPIRED';
            }
            let orderDate = moment(_.find(order.orderDetails).scheduledTo).utcOffset(companyTimeZone);
            _.map(order.orderDetails, service => {
                if (moment(service.scheduledTo).utcOffset(companyTimeZone).isBefore(orderDate)) {
                    orderDate = moment(service.scheduledTo).utcOffset(companyTimeZone);
                }
            });
            if (moment(orderDate).format('DD MMMM') !== listDate) {
                listDate = moment(orderDate).format('YYYY.MM.DD');
            }
            if (!ordersList[listDate]) {
                ordersList[listDate] = [];
            }
            ordersList[listDate].push(
                <div className={classes.order} key={order.id} onClick={this.switchOrderModal.bind(this, order)}>
                    <div className={classes.time}>{ orderDate.format('HH : mm') }</div>
                    <div className={classes.clientName}>{ order.clientName }</div>
                    <div className={classes.clientPhone}>{ formatPhone(order.clientPhone) }</div>
                    <div className={classes.orderNumber}>{ order.number }</div>
                    <div
                        className={classes.orderStatus + ' ' + classes[order.status]}
                    >{ _.get(statuses[order.status], 'orderText', '...') }
                    </div>
                </div>
            );
        });
        const client = this.props.client ? _.merge(_.pick(_.find(this.props.client.client_companies), ['angara_client_id', 'nick']), { phone: this.props.client.phone }) : null;
        return (
            <div id='orders' className={classes.orders}>
                <div className={classes.menu}>
                    { _.map(statuses, (item, key) => {
                        return (
                            <div
                                className={classes.link + ' ' + classes[key] + ' ' + (status === key ? classes.current : '')}
                                onClick={() => {
                                    this.changeStatus(key === this.props.status ? null : key);
                                }}
                                key={key}
                            >
                                <div>{ item.menuText[0] }</div>
                                <div>{ item.menuText[1] }</div>
                            </div>
                        );
                    }) }
                </div>
                <div className={classes.wrapper}>
                    <div className={classes.header}>
                        <div className={classes.title + ' ' + classes[status]}>
                            { _.get(statuses[status], 'headerTitle', 'Все заказы') }
                        </div>
                        <div className={classes.filter}>
                            <div>{ _.get(this.props.orders, 'ordersTotalCount', 0) } шт. за все время с</div>
                            <DatePicker
                                stringFormatting={(date) => {
                                    return moment(date).locale('ru').format('DD MMMM YY');
                                }}
                                selected={rangeStart}
                                className='inline orange'
                                align={['center', 'bottom']}
                                onChange={(date) => {
                                    this.props.changeOrdersDates(date, 'rangeStart');
                                }}
                                locale='ru'
                            />
                            <div>по</div>
                            <DatePicker
                                stringFormatting={(date) => {
                                    return moment(date).locale('ru').format('DD MMMM YY');
                                }}
                                selected={rangeEnd}
                                className='inline orange'
                                align={['center', 'bottom']}
                                onChange={(date) => {
                                    this.props.changeOrdersDates(date.endOf('day'), 'rangeEnd');
                                }}
                                locale='ru'
                            />
                            {
                                client
                                    ? (
                                        <div className={classes.client}>
                                            <div className={classes.info}>{ client.nick } { client.phone }</div>&nbsp;
                                            <div className={HELP_CSS.hlpRemoveIcon} onClick={() => (this.props.clearClient())} />
                                        </div>
                                    )
                                    : (
                                        <div className={classes.clientFilter}>
                                            <ClientSearch placeholder='Клиент' />
                                            <ClientsList
                                                modal
                                                change={(client) => (this.props.changeClient(client))}
                                            />
                                        </div>
                                    )
                            }

                        </div>
                    </div>

                    <div className={classes.list}>
                        { !_.isEmpty(ordersList)
                            ? _.map(ordersList, (orders, key) => {
                                return (
                                    <div key={key}>
                                        <div className={classes.date}>{ moment(key, 'YYYY.MM.DD').format('DD MMMM') }</div>
                                        { orders }
                                    </div>
                                );
                            })
                            : (
                                <div className={classes.emptyBlock} ref={this.emptyBlock}>
                                    Нет заказов по вашему запросу
                                </div>
                            ) }

                    </div>
                </div>
                { orderModal }
                <Preloader trigger={this.props.fetching} fixed />
            </div>
        );
    }
}

export default Orders;
