import { connect } from 'react-redux';
import { actions } from '../../modules/settings';

import Posts from './Posts';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => ({
    company: state.company.company,
    resources: state.company.resources,
    addResource: state.settings.addResource,
    editResource: state.settings.editResource
});

export default connect(mapStateToProps, Object.assign(actions, {}))(withRouter(Posts));
