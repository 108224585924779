// Libs
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Preloader from 'components/Preloader';
import InputElement from 'react-input-mask';
import classes from './client.module.scss';
// Utils
import { formatPhone, getInitials, beforeMaskedPhoneChange } from 'utils';

const LIMIT = 5;

class Client extends React.Component {
    state = {
        nick: '',
        phone: '',
        newClient: false,
        clientNotFound: false,
        page: 1
    };

    UNSAFE_componentWillMount() {
        // this.props.changeBooking('client', null);

    }

    componentDidMount() {
        window.addEventListener('scroll', this.scrollAction);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.fetching && !nextProps.fetching) {
            if (!_.get(nextProps.clients, 'items.length')) {
                if (this.state.phone.replace(/[^\d]/gi, '').length === 11 && this.state.nick) {
                    this.setState({ newClient: true });
                    if (this.props.select) {
                        this.props.select({ name: this.state.nick, phone: '+' + this.state.phone });
                    }
                } else {
                    this.setState({ clientNotFound: true });
                }
            }

            if (_.get(nextProps.clients, 'items.length') === 1 && this.state.phone.length === 11) {
                this.selectClientHandler(nextProps.clients.items[0]);
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollAction);
        if (this.updateTimer) {
            clearTimeout(this.updateTimer);
        }
        this.props.clearClientsBySearch();
    }

    scrollAction = () => {
        const html = document.documentElement; const body = document.body;

        const scrollTop = html.scrollTop || (body && body.scrollTop) || 0;

        const scrollHeight = html.scrollHeight || (body && body.scrollHeight) || 0;

        const clientHeight = html.clientHeight || (body && body.clientHeight) || 0;

        if (clientHeight + scrollTop + 1 >= scrollHeight && (this.state.page * LIMIT < _.get(this.props.clients, 'total', 0))) {
            if ((this.state.nick.length >= 2 || this.state.phone.length >= 2) && !this.props.fetching) {
                const data = {
                    offset: LIMIT * this.state.page,
                    limit: LIMIT
                };

                if (this.state.nick.length >= 2) {
                    data.nick = this.state.nick;
                }

                if (this.state.phone.length >= 2) {
                    data.phone = this.state.phone;
                }

                this.setState({ page: this.state.page + 1 });
                this.props.searchClientsByNickAndPhone(data, true);
            }
        }
    };

    searchFunction = (e) => {
        if (this.props.onChange) {
            this.props.onChange();
        }

        let value = e.target.value;

        const name = e.target.name;

        const searchLimit = 2;

        const data = { limit: LIMIT, offset: 0 };

        if (name === 'phone') {
            value = value.replace(/[^\d]/gi, '');

            /*            if (nick.length >= searchLimit) {
                data.nick = nick;
            } */
        }

        /*        if (name === 'nick' && phone.length >= searchLimit) {
            data.phone = phone;
        } */

        if (value.length >= searchLimit) {
            data[name] = value;
        }

        // если введен полный номер телефона, игнорируем имя
        /*        if (data.phone && data.phone.length === 11) {
            delete data.nick;
        } */

        if (_.get(data, 'nick.length') >= searchLimit || _.get(data, 'phone.length') >= searchLimit) {
            if (this.updateTimer) {
                clearTimeout(this.updateTimer);
                this.updateTimer = null;
            }

            this.updateTimer = setTimeout(() => {
                this.props.searchClientsByNickAndPhone(data);
                this.setState({ newClient: false, clientNotFound: false, page: 1 });
            }, 1000);
        } else {
            if (this.updateTimer) {
                clearTimeout(this.updateTimer);
                this.updateTimer = null;
            }
            this.props.clearClientsBySearch();
            this.setState({ newClient: false, clientNotFound: false });
        }

        this.setState({ [name]: value });
        // this.props.changeBooking('client', null);
    };

    selectClientHandler(client) {
        const data = {
            id: client.id,
            name: client.company_nick,
            phone: client.phone
        };

        if (client.avatar) {
            data.avatar = client.avatar;
        }

        if (this.props.select) {
            this.props.select(data);
        }
        this.setState({ nick: data.name, phone: data.phone.replace(/[^\d]/gi, '') });
        this.props.clearClientsBySearch();
    }

    render() {
        const { clients, fetching, booking, selected } = this.props;

        const { nick, phone, newClient, clientNotFound } = this.state;
        let content, clientAvatar;

        if (_.get(clients, 'items.length')) {
            content = (
                <div className={classes.list}>
                    <div className={classes.title}>Результаты поиска по базе клиентов :</div>
                    { _.map(_.get(clients, 'items'), client => {
                        const avatar = _.get(client, 'avatar.thumbnails');

                        let avatarUrl;
                        if (avatar) {
                            avatarUrl = _.get(avatar, 'mobile-avatar', _.get(avatar, 'avatar'));
                        }

                        return (
                            <div
                                className={classes.item + ((selected || []).indexOf(client.id) !== -1 ? ' ' + classes.active : '')}
                                key={client.id}
                                onClick={
                                    () => {
                                        this.selectClientHandler(client);
                                    }
                                }
                            >
                                <div className={classes.avatar}>
                                    { avatarUrl
                                        ? <img src={avatarUrl} alt='' />
                                        : <span>{ getInitials(client.company_nick) }</span> }
                                </div>
                                <div className={classes.info}>
                                    <div className={classes.name}>{ client.company_nick }</div>
                                    <div className={classes.phone}>{ formatPhone(client.phone) }</div>
                                </div>
                            </div>
                        );
                    }) }
                </div>
            );
        }

        if (this.props.selectedIsOk) {
            content = (
                <div className={classes.emptyClients + ' ' + classes.oldClient}>
                    <div className={classes.text}>Клиент выбран и теперь его<br />можно записать</div>
                </div>
            );
        }

        if (!fetching && nick.length <= 1 && phone.replace(/[^\d]/gi, '').length <= 1) {
            content = (
                <div className={classes.emptyClients + ' ' + classes.searchInfo}>
                    <div className={classes.text}>Для поиска клиента по базе начните вводить<br />
                        его имя или телефон
                    </div>
                </div>
            );
        }

        if (newClient) {
            content = (
                <div className={classes.emptyClients + ' ' + classes.newClient}>
                    <div className={classes.text}>Клиента нет в базе, он будет добавлен в базу<br />
                        автоматически при записи
                    </div>
                </div>
            );
        }

        if (clientNotFound) {
            content = (
                <div className={classes.emptyClients + ' ' + classes.notFound}>
                    <div className={classes.text}>Клиент не найден<br />
                        { !nick && phone.length === 11
                            ? 'Для добавления клиента введите его имя'
                            : null }
                    </div>

                </div>
            );
        }

        if (_.get(booking, 'client.avatar.thumbnails.avatar')) {
            clientAvatar = (
                <img src={booking.client.avatar.thumbnails.avatar} alt='' />
            );
        }

        return (
            <div className={classes.client}>
                <div className={classes.search}>
                    <div className={classes.title}>Данные клиента</div>
                    <div className={classes.field}>
                        <div className={classes.label}>Имя клиента</div>
                        <input
                            className={clientNotFound && !nick ? classes.error : ''}
                            type='text'
                            autoComplete='off'
                            placeholder='Введите имя клиента'
                            value={nick}
                            name='nick'
                            onChange={this.searchFunction}
                        />
                    </div>
                    <div className={classes.field}>
                        <div className={classes.label}>Номер телефона клиента</div>
                        <InputElement
                            className={classes.phone}
                            type='tel'
                            name='phone'
                            placeholder='+7 ( ___ ) ___ - __ - __'
                            value={phone}
                            onChange={this.searchFunction}
                            beforeMaskedValueChange={beforeMaskedPhoneChange}
                            mask='+7 ( 999 ) 999 - 99 - 99'
                        />
                    </div>
                    <div className={classes.avatar}>{ clientAvatar }</div>
                </div>
                <div className={classes.body}>
                    { content }
                </div>
                <Preloader trigger={fetching} />
            </div>
        );
    }
}

Client.contextTypes = {
    router: PropTypes.object
};

export default Client;
