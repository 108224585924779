/**
 * Created by user on 19.07.16.
 */
import React from 'react';
import _ from 'lodash';
import classes from './PrivateInfo.module.scss';
import cx from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import InputElement from 'react-input-mask';
import Button from '../../../../../../../components/Button';
import AddCar from './AddCar';
import DatePicker from 'components/DatePicker';

class Cars extends React.Component {
    state = {
        newCar: {
            id: 'newCar',
            body: null,
            engine: null,
            power: 0,
            transmission: null,
            year: null,
            vin: '',
            model: {}
        },
        showNewCar: false,
        openedCars: []
    };

    UNSAFE_componentWillMount() {
        if (!this.props.cars) {
            this.props.fetchClientCars(this.props.client.id);
        }
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (this.props.client.id !== nextProps.client.id) {
            this.props.fetchClientCars(nextProps.client.id);
        }
    }

    renderTrack({ style, ...props }) {
        const trackStyle = {};
        return (
            <div
                className={classes.scrollTrack}
                style={{ ...style, ...trackStyle }}
                {...props}
            />
        );
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {};
        return (
            <div
                className={classes.scrollThumb}
                style={{ ...style, ...thumbStyle }}
                {...props}
            />
        );
    }

    getCars(item) {
        const carFields = [];
        carFields.push({ name: 'Марка', label: 'marks', title: _.get(item.model.mark, 'title', ''), type: 'select', orange: true, action: 'fetchCarMarks' });
        carFields.push({ name: 'Модель', label: 'models', updateLabel: 'model_id', title: _.get(item.model, 'title', ''), type: 'select', orange: true, action: 'fetchCarModels' });
        carFields.push({ name: 'Год', label: 'year', title: item.year, type: 'edit', orange: true });
        carFields.push({ name: 'Тип ДВС', label: 'engines', updateLabel: 'engine', title: _.get(item.engine, 'title', ''), type: 'select', action: 'fetchCarEngines' });
        carFields.push({ name: 'Тип КПП', label: 'transmissions', updateLabel: 'transmission', title: _.get(item.transmission, 'title', ''), type: 'select', action: 'fetchCarTransmissions' });
        carFields.push({ name: 'Вин', label: 'vin', title: item.vin, type: 'edit' });
        carFields.push({ name: 'Тип Кузова', label: 'bodies', updateLabel: 'body', title: _.get(item.body, 'title', ''), type: 'select', action: 'fetchCarBodies' });
        carFields.push({ name: 'Мощность', label: 'power', title: item.power, type: 'edit' });
        carFields.push({ name: 'Пробег', label: 'mileage', title: item.mileage, type: 'edit' });
        carFields.push({ name: 'Дата окончания страховки', label: 'insurance_deadline', title: item.insurance_deadline, type: 'date' });
        carFields.push({ name: 'Номер', label: 'license_plate', title: item.license_plate, type: 'edit' });
        carFields.push({ name: 'Регион', label: 'region_id', title: item.region_id, type: 'edit' });

        if (_.get(item, 'car_clients[0].company_id')) {
            return this.getCompanyCar(item, carFields);
        } else {
            return this.getClientCar(item, carFields);
        }
    }

    getCompanyCar(item, carFields) {
        return (
            <div key={'car' + item.id}>
                { _.map(carFields, field => {
                    let renderField;
                    switch (field.type) {
                    case 'edit':
                        if (field.label === 'license_plate') {
                            field.title = this.formatLicensePlate(field.title);
                        }
                        renderField = this.props.editClientChange === (item.id + '-' + field.label)
                            ? this.carFieldInput(field, item)
                            : (
                                <div
                                    className={classes.changeValue} key={'changeValue' + item.id}
                                    onClick={this.props.editClient.bind(this, item.id + '-' + field.label)}
                                >
                                    <div className={classes.value + ' ' + (field.orange ? classes.orange : '')} key={'value' + field.title + item.id}>
                                        { field.label === 'power' && field.title ? field.title + ' л.с.' : field.title }
                                    </div>
                                    <div className={classes.edit} key={'type' + field.title + item.id} />
                                </div>
                            );
                        break;
                    case 'date':
                        renderField = (
                            <div className={classes.changeValue}>
                                <DatePicker
                                    className='full-orange no-header-arrows'
                                    align={['center', 'bottom']}
                                    selected={field.title ? moment(field.title) : null}
                                    placeholder='Не выбрано'
                                    onChange={(date) => {
                                        this.props.updateClientCars(field.label, moment(date).format(), item.id);
                                    }}
                                />
                            </div>
                        );
                        break;
                    default:
                        renderField = (
                            <div
                                className={classes.changeValue} key={'changeValue' + item.id}
                                onClick={this.editClient.bind(this, item.id, field)}
                            >
                                <div className={classes.value + ' ' + (field.orange ? classes.orange : '')} key={'value' + field.title + item.id}>
                                    { field.title ? field.title : 'Не выбрано' }
                                </div>
                                <div className={classes.select} key={'type' + field.title + item.id} />
                                { this.props.editClientChange === (item.id + field.label)
                                    ? this.carFieldSelect(field, item)
                                    : null }
                            </div>
                        );
                        break;
                    }

                    return (
                        <div className={classes.carData} key={'data' + field.label + item.id}>
                            <div className={classes.title} key={'title' + field.title + item.id}>{ field.name }:</div>
                            { renderField }
                        </div>
                    );
                }) }
            </div>
        );
    }

    getClientCar(item, carFields) {
        return (
            <div key={'car' + item.id}>
                { _.map(carFields, field => {
                    let renderField;
                    switch (field.type) {
                    case 'edit':
                        if (field.label === 'license_plate') {
                            field.title = this.formatLicensePlate(field.title);
                        }
                        renderField = (
                            <div className={classes.changeValue + ' ' + classes.immutable} key={'changeValue' + item.id}>
                                <div className={classes.value + ' ' + (field.orange ? classes.orange : '')} key={'value' + field.title + item.id}>
                                    { field.label === 'power' && field.title ? field.title + ' л.с.' : field.title }
                                </div>
                            </div>
                        );
                        break;
                    case 'date':
                        renderField = (
                            <div className={classes.changeValue + ' ' + classes.immutable}>
                                <div className={classes.value + ' ' + (field.title ? classes.orange : '')}>
                                    { field.title ? moment(field.title).format('DD MMMM`YY') : 'Не выбрано' }
                                </div>
                            </div>
                        );
                        break;
                    default:
                        renderField = (
                            <div className={classes.changeValue + ' ' + classes.immutable} key={'changeValue' + item.id}>
                                <div
                                    className={classes.value + ' ' + (field.orange ? classes.orange : '')}
                                    key={'value' + field.title + item.id}
                                >
                                    { field.title ? field.title : 'Не выбрано' }
                                </div>
                            </div>
                        );
                        break;
                    }

                    return (
                        <div className={classes.carData} key={'data' + field.label + item.id}>
                            <div className={classes.title} key={'title' + field.title + item.id}>{ field.name }:</div>
                            { renderField }
                        </div>
                    );
                }) }
            </div>
        );
    }

    editClient(id, field, e) {
        const markId = _.find(this.props.cars, ['id', id]).model.mark.id;

        const models = _.get(this.props.carReferences, 'models');

        const { openedCars } = this.state;

        if (field.label === 'models' && models && !_.find(models, ['mark_id', markId])) {
            this.props.fetchCarModels(markId);
        }

        if (!this.props.carReferences || !this.props.carReferences[field.label]) {
            this.props[field.action](markId);
        }

        if (!_.includes(openedCars, id)) {
            openedCars.push(id);
            this.setState({ openedCars: openedCars });
        }

        this.props.editClient(id + field.label, e);
    }

    carFieldSelect(field, car) {
        if (!this.props.carReferences || !this.props.carReferences[field.label]) {
            return false;
        }
        return (
            <div>
                <div className={classes.triangle} />
                <div className={classes.outerMenu} onClick={e => { e.stopPropagation(); }}>
                    <Scrollbars
                        id='fieldScrollbar'
                        ref='fieldScrollbar'
                        thumbSize={16}
                        autoHeight
                        autoHide={false}
                        renderTrackVertical={this.renderTrack}
                        renderThumbVertical={this.renderThumb}
                        onWheel={event => {
                            const { fieldScrollbar } = this.refs;
                            const scroll = fieldScrollbar.getValues();
                            if (event.deltaY > 0 && scroll.clientHeight + scroll.scrollTop === scroll.scrollHeight) {
                                event.preventDefault();
                            }
                            if (event.deltaY < 0 && scroll.scrollTop === 0) {
                                event.preventDefault();
                            }
                        }}
                    >
                        { _.map(this.props.carReferences[field.label], item => {
                            if (field.label === 'models' &&
                                _.find(this.props.cars, ['id', car.id]).model.mark.id === item.mark_id) {
                                return (
                                    <div
                                        key={'carReference' + item.id}
                                        className={classes.item}
                                        onClick={this.updateClientCars.bind(this, field.updateLabel, field.title, car.id, item)}
                                    >
                                        { item.title }
                                    </div>);
                            } else if (field.label !== 'models') {
                                return (
                                    <div
                                        key={'carReference' + item.id}
                                        className={classes.item}
                                        onClick={this.updateClientCars.bind(this, field.updateLabel, field.title, car.id, item)}
                                    >
                                        { item.title }
                                    </div>
                                );
                            }
                        }) }
                    </Scrollbars>
                </div>
            </div>
        );
    }

    updateClientCars(label, title, carId, field, e) {
        if (title === field.title) {
            this.props.editClient(false, e);
            return true;
        }
        const value = _.includes(['engine', 'transmission', 'body'], label) ? field : field.id;

        if (!label) {
            this.props.updateClientCarMark(field, carId);
        } else {
            this.props.updateClientCars(label, value, carId).then(() => this.props.editClient(false));
        }
    }

    carFieldInput(field, item) {
        let mask;

        let maskChar;

        let text;

        const formatChars = { 9: '[0-9]', V: '[A-HJ-NPR-Za-hj-npr-z0-9]', R: '[а-яёЁА-Я0-9]', N: '[авекм-ор-ухАВЕКМ-ОР-УХ]' };

        const car = _.find(this.props.changedCars, ['id', item.id]);

        switch (field.label) {
        case 'year':
            mask = '9999';
            maskChar = 'Г';
            text = 'Введите год автомобиля';
            break;
        case 'vin':
            mask = 'VVV  VVVVVV  VVVVVVVV';
            maskChar = '_';
            text = 'Вин должен состоять из 17 символов (цифры и буквы латинского алфавита, кроме I Q O)';
            break;
        case 'license_plate':
            mask = 'N 999 NN';
            maskChar = '_';
            text = 'Неверный формат номера';
            break;
        default:
        }

        if (text) {
            return (
                <div className={classes.changeValue}>
                    <InputElement
                        type='text'
                        name={field.label}
                        autoFocus
                        value={car[field.label]}
                        onBlur={this.updateCarField.bind(this, field.label, car[field.label], item.id)}
                        onChange={this.props.changeClientCars.bind(this, item.id)}
                        onClick={e => e.stopPropagation()}
                        mask={mask}
                        maskChar={maskChar}
                        formatChars={formatChars}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                this.updateCarField(field.label, car[field.label], item.id, e);
                            }
                        }}
                        onKeyUp={e => {
                            if (e.key === 'Escape') {
                                e.preventDefault();
                                this.props.editClient(false, e);
                            }
                        }}
                    />
                    <div
                        className={classes.editOk}
                        onClick={this.updateCarField.bind(this, field.label, car[field.label], item.id)}
                    />
                    { this.props.errorMenu
                        ? (
                            <div className={classes.errorMenu} onClick={e => e.stopPropagation()}>
                                { text }
                            </div>
                        )
                        : null }
                </div>
            );
        } else {
            return (
                <div className={classes.changeValue}>
                    <input
                        type='text'
                        name={field.label}
                        autoFocus
                        value={car[field.label]}
                        onBlur={this.updateCarField.bind(this, field.label, car[field.label], item.id)}
                        onChange={this.props.changeClientCars.bind(this, item.id)}
                        style={field.label === 'power' ? { width: 9 + 'em' } : { width: 11 + 'em' }}
                        onClick={e => e.stopPropagation()}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                this.updateCarField(field.label, car[field.label], item.id, e);
                            }
                        }}
                        onKeyUp={e => {
                            if (e.key === 'Escape') {
                                e.preventDefault();
                                this.props.editClient(false, e);
                            }
                        }}
                    />
                    {
                        field.label === 'power'
                            ? <div className={classes.power}>&nbsp;&nbsp;л.с.</div>
                            : null
                    }
                    <div
                        className={classes.editOk}
                        onClick={this.updateCarField.bind(this, field.label, car[field.label], item.id)}
                    />
                    { this.props.errorMenu === 'power'
                        ? (
                            <div className={classes.errorMenu} onClick={e => e.stopPropagation()}>
                                В этом поле можно ввести только цифры
                            </div>
                        )
                        : null }
                </div>
            );
        }
    }

    updateCarField(label, value, id, e) {
        let test;
        switch (label) {
        case 'year':
            test = /[Г]/.test(value);
            break;
        case 'vin':
        case 'license_plate':
            value = value.replace(/\s+/g, '');
            test = /[_]/.test(value);
            break;
        case 'power':
            test = !/^\d+$/.test(value);
            break;
        default:
        }

        if (test && value.length !== 0) {
            this.props.errorMenuChange(label, e);
        } else {
            this.props.updateClientCars(label, value, id);
        }
    }

    deleteCar(id) {
        if (window.confirm('Удалить автомобиль клиента?')) {
            this.props.deleteClientCar(id);
        }
    }

    addCar() {
        this.setState({ showNewCar: true });
    }

    openCar(id) {
        const openedCars = Object.assign([], this.state.openedCars);
        if (_.includes(openedCars, id)) {
            _.remove(openedCars, item => {
                return item === id;
            });
        } else {
            openedCars.push(id);
        }

        this.setState({ openedCars: openedCars });
    }

    formatLicensePlate(value) {
        if (value) {
            value = value.toUpperCase();
            return value.substr(0, 1) + ' ' + value.substr(1, 3) + ' ' + value.substr(4, 2);
        } else {
            return null;
        }
    }

    render() {
        const car = _.map(this.props.cars, item => {
            const opened = _.includes(this.state.openedCars, item.id);
            return (
                <div
                    className={cx({
                        [classes.car]: true,
                        [classes.open]: opened && !_.get(item, 'car_clients[0].company_id'),
                        [classes.companyCarOpen]: opened && _.get(item, 'car_clients[0].company_id')
                    })}
                    key={'clientCars' + item.id}
                >
                    <div className={classes.openButtonContainer} onClick={this.openCar.bind(this, item.id)}>
                        <div className={classes.openButton + ' ' + (_.includes(this.state.openedCars, item.id) ? classes.open : '')} />
                    </div>
                    { this.getCars(item) }
                    <hr />
                    { _.get(item, 'car_clients[0].company_id')
                        ? (
                            <div className={classes.btnContainer}>
                                <Button
                                    value='Отвязать авто'
                                    className='carRemove'
                                    onClick={this.deleteCar.bind(this, item.id)}
                                />
                            </div>
                        )
                        : null }
                </div>
            );
        });

        let newCar;
        if (this.props.shadeMenuStatus === 'addCar') {
            newCar = (
                <AddCar {...this.props} />
            );
        }

        return (
            <div className={classes.cars}>
                { car }
                { newCar }
                <div className={classes.btnContainer}>
                    <Button
                        value='Добавить авто'
                        className='primary'
                        onClick={this.props.shadeMenuChange.bind(this, 'addCar')}
                    />
                </div>
            </div>
        );
    }
}

export default Cars;
