import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

require('./resources.scss');

class Resources extends React.Component {
    render() {
        const resources = _.get(this.props, 'resources', []);

        if (resources.length < 1) {
            return null;
        }

        const menu = [];
        _.each(resources, (resource) => {
            const imageUrl = _.get(_.find(_.get(resource, 'thumbnails', []), { templateName: 's1' }), 'url');
            menu.push(
                <div
                    key={resource.id}
                    className={`resource${_.get(this.props, 'selectedResources', []).indexOf(resource.id) !== -1 ? ' active' : ''}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        this.props.selectHandler(resource.angara_id);
                    }}
                >
                    <div className='resource__image'>
                        <div className='resource__image__wrapper'>
                            { imageUrl ? (<img src={imageUrl} alt='' />) : null }
                        </div>
                    </div>
                    <div className='resource__info'>
                        <div className='resource__info__title'>{ resource.name }</div>
                    </div>

                </div>
            );
        });
        return (<div className='resourcesList'>{ menu }</div>);
    }
}

Resources.contextTypes = {
    router: PropTypes.object
};

export default Resources;
