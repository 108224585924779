/**
 * Created by user on 26.05.2016.
 */

import { connect } from 'react-redux';
import { actions } from 'modules/orders';
import { fetchCompany, fetchSchedule } from 'modules/company';

import ViewOrderModal from './ViewOrderModal';

const mapStateToProps = (state) => ({
    company: state.company.company,
    loading: state.company.fetching,
    toOrder: state.orders.toOrder,
    orders: state.orders.orders,
    currentDay: state.company.currentDay,
    confirmCancelOrder: state.orders.confirmCancelOrder
});

export default connect(mapStateToProps, Object.assign(actions, { fetchCompany, fetchSchedule }))(ViewOrderModal);
