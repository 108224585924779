import React from 'react';
// Libs
import cn from 'classnames';
import moment from 'moment';
import _ from 'lodash';
// Comps
import WithClickOutside from 'extends/WithClickOutside';
import Select from 'components/Select';
// Utils
import { getOffsetRect, formatNumber, formatDuration } from 'utils';

import classes from './EditOrderModal.module.scss';

class EditOrderModal extends WithClickOutside {
    state = {
        activeService: null
    };

    checkModalHandler = this.checkModalPosition.bind(this);

    componentDidMount() {
        super.componentDidMount();
        window.addEventListener('scroll', this.checkModalHandler);
        window.addEventListener('resize', this.checkModalHandler);
        // грузим каталоги по выбранному ресурсу
        if (!this.props.services[this.props.toOrder.resId]) {
            this.props.fetchServices(this.props.toOrder.resId);
        }
        this.checkModalPosition();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener('scroll', this.checkModalHandler);
        window.removeEventListener('resize', this.checkModalHandler);
    }

    checkModalPosition() {
        // точки на чанке, к которым будет хорошо прикрепить модальное окно
        let chunkPoints = [
            { x: 12, y: 0, modalClass: 'topAlign', points: [] },
            { x: 25, y: 12, modalClass: 'rightAlign', points: [] },
            { x: 12, y: 25, modalClass: 'bottomAlign', points: [] },
            { x: 0, y: 12, modalClass: 'leftAlign', points: [] }
        ];
        // определяем размер окна браузера и координаты ценра вьюпорта
        const client_w = document.documentElement.clientWidth; const client_h = document.documentElement.clientHeight;

        const center_x = Math.round(client_w / 2);

        const center_y = Math.round(client_h / 2);
        const el = document.getElementById('ordermodal');
        if (el) {
            const chunk = el.parentNode;
            const chunkPos = getOffsetRect(chunk);
            // определяем ближайшую точку на чанке
            _.each(chunkPoints, point => {
                point.distance = Math.round(Math.sqrt(Math.pow(center_x - (chunkPos.boxLeft + point.x), 2) + Math.pow(center_y - (chunkPos.boxTop + point.y), 2)));
            });

            chunkPoints = _.orderBy(chunkPoints, ['distance'], ['asc']);
            this.setState({ modalClass: chunkPoints[0].modalClass });
        }
    }

    outerClick(e) {
        if (_.get(e, 'target.className') !== 'contextModalTrigger') {
            this.props.chooseToOrder(null);
        }
    }

    handlerFetchOrder = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const data = { order_details: [] };

        let scheduledTo = this.props.toOrder.date;
        const order = _.find(this.props.orders.items, { id: this.props.orderId });

        const resource = _.find(this.props.company.resources, { angara_id: this.props.toOrder.resId });
        _.map(order.orderDetails, item => {
            const orderDetail = {
                id: item.id,
                price: item.price,
                duration: item.duration,
                scheduled_to: scheduledTo.format(),
                resource: { id: resource.angara_id }
            };
            data.order_details.push(orderDetail);
            scheduledTo = scheduledTo.add(item.duration, 'm');
        });
        this.props.updateOrderDetails(this.props.orderId, data);
    };

    clearOrder(e) {
        e.preventDefault();
        e.stopPropagation();

        const currentDay = this.props.currentDay ? this.props.currentDay : moment().startOf('date');

        this.props.fetchSchedule(this.props.toOrder.resId, {
            range_start: currentDay.format('YYYY-MM-DD'),
            range_end: moment(currentDay).add(1, 'd').format('YYYY-MM-DD')
        }, this.props.company.id);
        this.props.fetchOrders(this.props.company.id, {
            range_start: moment(currentDay).format(),
            range_end: moment(currentDay).add(2, 'd').format()
        });

        this.props.clearOrder();
    }

    changeService(value) {
        let averageDuration = 30; let totalPrice = 0; const catalogue = _.find(this.props.services[this.props.toOrder.resId],
            catalogue => {
                return _.some(catalogue.services, service => {
                    if (service.id === value.value) {
                        averageDuration = service.averageDuration;
                    }
                    return service.id === value.value;
                });
            });

        const catalogueId = catalogue ? catalogue.id : null;

        let service = null;
        if (value.value) {
            const newService = { id: value.value, name: value.label, duration: averageDuration, price: value.price };
            service = this.props.toOrder.service || [];
            service.push(newService);
            totalPrice = value.price;
            if (this.props.toOrder.service) {
                averageDuration += this.props.toOrder.averageDuration;
                totalPrice += this.props.toOrder.totalPrice;
            }
        }
        this.props.chooseToOrder({
            service: service,
            catalogueId: catalogueId,
            averageDuration: averageDuration,
            totalPrice: totalPrice
        });
    }

    changeServiceDuration(id, value) {
        const services = this.props.toOrder.service;

        const serviceKey = _.findKey(services, ['id', id]);

        let totalDuration = null;
        services[serviceKey].duration = value.value;
        _.map(services, item => {
            totalDuration += item.duration;
        });
        this.props.chooseToOrder({
            service: services,
            averageDuration: totalDuration
        });
    }

    removeService(service) {
        const services = this.props.toOrder.service;
        _.remove(services, ['id', service.id]);

        this.props.chooseToOrder({
            service: services,
            averageDuration: this.props.toOrder.averageDuration - service.duration,
            totalPrice: this.props.toOrder.totalPrice - service.price
        });
    }

    changeActiveService(id, e) {
        if (e) {
            e.stopPropagation();
        }
        this.setState({ activeService: id });
    }

    changeServicePrice(e) {
        const template = /^[0-9]+$/;

        let value = e.target.value;

        const services = this.props.toOrder.service;

        const serviceKey = _.findKey(services, ['id', this.state.activeService]);

        let totalPrice = this.props.toOrder.totalPrice - services[serviceKey].price;
        if (!value) {
            value = 0;
        }

        if (template.test(value)) {
            services[serviceKey].price = parseInt(value);
            totalPrice += parseInt(value);

            this.props.chooseToOrder({
                service: services,
                totalPrice: totalPrice
            });
        }
    }

    updateOrder(e) {
        e.preventDefault();
        e.stopPropagation();
        const data = { order_details: [] };

        let scheduledTo = this.props.toOrder.date;

        _.map(this.props.toOrder.service, item => {
            const orderDetail = {
                price: item.price,
                duration: item.duration,
                scheduled_to: scheduledTo.format(),
                resource: { id: this.props.toOrder.resId }
            };

            if (item.orderDetailsId) {
                orderDetail.id = item.orderDetailsId;
            } else {
                orderDetail.service = { id: item.id };
            }
            data.order_details.push(orderDetail);
            scheduledTo = scheduledTo.add(item.duration, 'm');
        });
        this.props.updateOrderDetails(this.props.toOrder.orderId, data);
    }

    closeModal = () => {
        this.props.chooseToOrder(null);
    };

    render() {
        moment.locale('ru');
        // TODO закинуть order в state
        const order = _.find(this.props.orders.items, { id: this.props.orderId });
        let modal = null;

        let servicesCounter = 0;
        // отрабатываем вывод
        const resource = _.find(this.props.company.resources, resource => {
            return resource.angara_id === this.props.toOrder.resId;
        });
        if (this.props.toOrder.status === 'edit') {
            const specs = (_.find(_.get(this.props.company, 'fields', []), { label: 'company_specs' }) || {}).value || [];

            const services = _.filter(_.get(this.props.groupedServices, this.props.toOrder.resId, []), service => {
                return _.indexOf(specs, service.id) !== -1;
            });

            const currentDay = this.props.orderDay ? this.props.orderDay : moment().startOf('date');

            // получаем массив для селекта услуг
            const selServices = [];
            _.map(services, catalogue => {
                selServices.push({ value: catalogue.id, label: catalogue.name, type: 'group' });
                _.map(catalogue.services, service => {
                    if (!_.find(this.props.toOrder.service, ['id', service.id])) {
                        selServices.push({
                            value: service.id,
                            label: service.serviceTemplate.name,
                            type: 'item',
                            price: service.minPrice
                        });
                    }
                });
            });
            const schedule = _.get(_.find(this.props.schedules, { id: resource.id }), moment(currentDay).format('YYYY-MM-DD'));
            let lastAvailableChunkService;
            let orderDuration = 0;
            _.map(order.orderDetails, item => {
                orderDuration += item.duration;
            });
            const firstChunkService = (parseInt(this.props.toOrder.date.format('H') * 60 + parseInt(this.props.toOrder.date.format('m'))) / (60 * this.props.chunkSize));
            lastAvailableChunkService = schedule.indexOf('0', firstChunkService + orderDuration / 30);
            if (lastAvailableChunkService === -1) {
                lastAvailableChunkService = schedule.length;
            }
            const availableDurationChunks = lastAvailableChunkService - firstChunkService;

            const availableDuration = availableDurationChunks * 60 * this.props.chunkSize;
            const proposedDurations = [
                { value: 30, label: '30 мин', type: 'item' },
                { value: 60, label: '1 ч.', type: 'item' },
                { value: 90, label: '1 ч. 30 мин.', type: 'item' },
                { value: 120, label: '2 ч.', type: 'item' },
                { value: 150, label: '2 ч. 30 мин', type: 'item' },
                { value: 180, label: '3 ч.', type: 'item' },
                { value: 210, label: '3 ч. 30 мин', type: 'item' },
                { value: 240, label: '4 ч.', type: 'item' },
                { value: 270, label: '4 ч. 30 мин', type: 'item' },
                { value: 300, label: '5 ч.', type: 'item' },
                { value: 330, label: '5 ч. 30 мин', type: 'item' },
                { value: 360, label: '6 ч.', type: 'item' },
                { value: 390, label: '6 ч. 30 мин', type: 'item' },
                { value: 420, label: '7 ч.', type: 'item' },
                { value: 450, label: '7 ч. 30 мин', type: 'item' },
                { value: 480, label: '8 ч.', type: 'item' }
            ];

            // если в фиксированном списке длительности нет рекомендованного для выбранной услуги
            if (this.props.toOrder.averageDuration && !_.find(proposedDurations, { value: this.props.toOrder.averageDuration })) {
                proposedDurations.push(
                    {
                        value: this.props.toOrder.averageDuration,
                        label: formatDuration(this.props.toOrder.averageDuration),
                        type: 'item'
                    });
            }

            // определяем возможные для выбора длительности

            const durations = _.filter(proposedDurations, duration => {
                return (duration.value / (60 * this.props.chunkSize)) <= availableDurationChunks;
            });

            let durationError = null;
            if (this.props.toOrder.averageDuration && !_.find(durations, { value: this.props.toOrder.averageDuration })) {
                durationError = 'Скорректируйте продолжительность оказания услуги';
            }

            modal = (
                <div
                    id='ordermodal'
                    className={classes.editordermodal + ' ' + (this.state.modalClass ? classes[this.state.modalClass] : '')}
                    onClick={this.changeActiveService.bind(this, null)}
                >
                    <div className={classes.header}>
                        Запись клиента <span>{ order.clientName }</span>
                    </div>
                    <div className={classes.body}>
                        <div className={classes.resource}>Исполнитель услуги: <span>{ resource.name }</span>
                        </div>
                        <div className={classes.dateAndService}>
                            <div className={classes.date}>
                                <span>{ moment(this.props.toOrder.date).format('DD MMMM') }</span> в&nbsp;
                                <span>{ moment(this.props.toOrder.date).format('HH:mm') }</span> на услугу:
                            </div>
                            <div className={classes.services}>
                                <Select
                                    name='services'
                                    placeholder='Выберите услугу'
                                    value='Добавить услугу'
                                    options={selServices}
                                    type='select'
                                    onChange={this.changeService.bind(this)}
                                    search
                                />
                            </div>
                        </div>
                        <div className={classes.services}>
                            { _.map(this.props.toOrder.service, service => {
                                servicesCounter++;
                                const availableServiceDuration = availableDuration - (this.props.toOrder.averageDuration - service.duration);

                                const serviceDurations = _.filter(durations, duration => {
                                    return duration.value <= availableServiceDuration;
                                });
                                return (
                                    <div className={classes.row} key={'service' + service.id}>
                                        <div className={classes.name}>{ servicesCounter + '. ' + service.name }</div>
                                        <div className={classes.duration}>
                                            <Select
                                                className='serviceDuration'
                                                name='duration'
                                                placeholder='...'
                                                value={service.duration}
                                                error={durationError}
                                                options={serviceDurations}
                                                type='link'
                                                onChange={this.changeServiceDuration.bind(this, service.id)}
                                            />
                                        </div>
                                        <div
                                            className={classes.price}
                                            onClick={this.changeActiveService.bind(this, service.id)}
                                        >
                                            { service.id === this.state.activeService
                                                ? (
                                                    <input
                                                        value={service.price}
                                                        onChange={this.changeServicePrice.bind(this)}
                                                        autoFocus
                                                        onKeyPress={e => {
                                                            if (e.key === 'Enter') {
                                                                this.changeActiveService(null);
                                                            } else {
                                                                return null;
                                                            }
                                                        }}
                                                    />
                                                )
                                                : (
                                                    <div className={classes.value}>
                                                        { formatNumber(service.price) } руб
                                                    </div>
                                                ) }

                                        </div>
                                        <div
                                            className={classes.remove}
                                            onClick={this.removeService.bind(this, service)}
                                        />
                                    </div>
                                );
                            }) }
                        </div>
                        <div className={classes.totalPrice}>
                            <div className={classes.label}>Сумма заказа:</div>
                            <div className={classes.value}>
                                { formatNumber(this.props.toOrder.totalPrice) } руб
                            </div>
                        </div>
                        <hr />
                        <div className={classes.footer}>
                            <div
                                className={classes.cancel}
                                onClick={this.props.chooseToOrder.bind(this, null)}
                            >
                                Отмена
                            </div>
                            <div
                                className={cn({
                                    [classes.fetching]: this.props.fetching.sendOrder,
                                    [classes.order]: true,
                                    [classes.blocked]: !this.props.toOrder.service.length
                                })}
                                onClick={
                                    availableDuration >= this.props.toOrder.averageDuration && this.props.toOrder.service.length
                                        ? this.updateOrder.bind(this)
                                        : e => e.preventDefault()
                                }
                            >
                                Сохранить
                            </div>
                        </div>
                    </div>
                    <div className={classes.triangle + ' ' + classes.left} />
                    <div className={classes.triangle + ' ' + classes.top} />
                    <div className={classes.triangle + ' ' + classes.right} />
                    <div className={classes.triangle + ' ' + classes.bottom} />
                </div>
            );
        }
        if (this.props.toOrder.status === 'finish') {
            // плашка окончания переноса записи
            modal = (
                <div
                    id='ordermodal'
                    className={classes.editordermodal + ' ' + classes.finish + ' ' + (this.state.modalClass ? classes[this.state.modalClass] : '')}
                >
                    <div className={classes.text}>
                        Запись успешно { this.props.toOrder.service ? 'изменена' : 'перенесена' }
                    </div>
                    <div className={classes.clear} onClick={this.clearOrder.bind(this)}>Продолжить</div>
                    <div className={classes.triangle + ' ' + classes.left} />
                    <div className={classes.triangle + ' ' + classes.top} />
                    <div className={classes.triangle + ' ' + classes.right} />
                    <div className={classes.triangle + ' ' + classes.bottom} />
                </div>
            );
        }
        if (this.props.toOrder.status === 'shift') {
            // плашка записи клиента
            const shiftDate = moment().startOf('date').add(this.props.toOrder.chunk * 30, 'm');

            // определяем возможный временной промежуток записи
            const resource = _.find(this.props.company.resources, resource => {
                return resource.angara_id === this.props.toOrder.resId;
            });

            modal = (
                <div
                    id='ordermodal'
                    className={classes.editordermodal + ' ' + (this.state.modalClass ? classes[this.state.modalClass] : '')}
                >
                    <div className={classes.header}>
                        Перенос записи клиента <span>{ order.clientName }</span>
                    </div>
                    <div className={classes.body}>
                        <div className={classes.resource}>Исполнитель услуги:
                            <span>{ resource.name }
                            </span>
                        </div>
                        { _.map(order.orderDetails, (orderDetail, key) => {
                            const orderDurationHours = parseInt(orderDetail.duration / 60);

                            const orderDurationMinutes = parseInt(orderDetail.duration % 60);// сколько минут займет
                            return (
                                <div className={classes.dateAndService} key={orderDetail.id}>
                                    <div className={classes.date + ' ' + (key !== 0 ? classes.hidden : '')}>
                                        <span>{ shiftDate.format('DD MMMM') }</span> в&nbsp;
                                        <span>{ shiftDate.format('HH:mm') }</span> на { order.orderDetails.length === 1 ? 'услугу' : 'услуги' }:
                                    </div>
                                    <div className={classes.service}>
                                        { orderDetail.service.serviceTemplate.name }
                                        <div className={classes.serviceDuration}>Длительность:
                                            <span>
                                                { orderDurationHours ? orderDurationHours + 'ч.' : '' }&nbsp;
                                                { orderDurationMinutes ? orderDurationMinutes + 'мин.' : '' }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        }) }

                        <hr />
                        <div className={classes.footer}>
                            <div
                                className={classes.cancel}
                                onClick={this.closeModal}
                            >
                                Отмена
                            </div>
                            <div
                                className={cn({ [classes.order]: true, [classes.fetching]: this.props.fetching.sendOrder })}
                                onClick={this.handlerFetchOrder}
                            >Перенести
                            </div>
                        </div>
                    </div>
                    <div className={classes.triangle + ' ' + classes.left} />
                    <div className={classes.triangle + ' ' + classes.top} />
                    <div className={classes.triangle + ' ' + classes.right} />
                    <div className={classes.triangle + ' ' + classes.bottom} />
                </div>
            );
        }
        return modal;
    }
}

export default EditOrderModal;
