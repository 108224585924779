// We only need to import the modules necessary for initial render
import CoreLayout from '../layouts/CoreLayout';
import Calendar from './Calendar';
import Login from './Login';
import RemindPassword from './RemindPassword';
import Settings from './Settings';
import Statistics from './Statistics';
import Orders from './Orders';
import CreateCompany from './CreateCompany';
import Callcenter from './Callcenter';
import FeedBack from './FeedBack';
import NotFound from './NotFound';
import Clients from './Clients';
import Widgets from './Widgets';
import Okk from './Okk';
import Download from './Download';

/*  Note: Instead of using JSX, we recommend using react-router
 PlainRoute objects to build route definitions.   */

const createRoutes = function(store) {
    return {
        path: '/',
        component: CoreLayout,
        indexRoute: Calendar(store),
        childRoutes: [
            Login(store),
            RemindPassword(store),
            Settings(store),
            Statistics(store),
            Orders(store),
            CreateCompany(store),
            Callcenter(store),
            FeedBack(store),
            Clients(store),
            Widgets(store),
            Okk(store),
            Download(store),
            NotFound(store)

        ]
    };
};

export default createRoutes;
