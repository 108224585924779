import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';

class WithClickOutside extends React.Component {
    componentDidMount() {
        this.handleClickOutside = this.handleClickOutside.bind(this);
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside(event) {
        if (this.outerClick) {
            // выцепил чужой кусок кода, потому что в мозилле и сафари нет path у mouse событий
            if (event.target.className === 'modalWindow__shadowScreen') {
                this.outerClick(event);
                return;
            }
            if (!(event.path)) {
                event.path = [event.target];
                let currentElem = event.target;
                while (currentElem) {
                    currentElem = currentElem.parentElement;
                    event.path.push(currentElem);
                }
            }
            const domNode = ReactDOM.findDOMNode(this);
            if (domNode) {
                const domNodeRect = domNode.getBoundingClientRect();
                let conditionInTree, conditionInRect, conditionInRIB;
                if (!event.path.includes(domNode)) {
                    conditionInTree = true;
                }
                if (!(event.clientX >= domNodeRect.left && event.clientX <= domNodeRect.right && event.clientY >= domNodeRect.top && event.clientY <= domNodeRect.bottom)) {
                    conditionInRect = true;
                }
                if (!_.some(event.path, { className: 'renderInBody' })) {
                    conditionInRIB = true;
                }
                if (conditionInRect && conditionInTree && conditionInRIB) {
                    this.outerClick(event);
                }
            }
        }
    }
}

export default WithClickOutside;
