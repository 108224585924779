/**
 * Created by user on 17.05.2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classes from './Button.module.scss';

export const Button = (props) => (
    <button onClick={props.onClick} className={classes[props.className]}>{ props.value }</button>
);

Button.propTypes = {
    value: PropTypes.string.isRequired,
    // onClick: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired
};

export default Button;
