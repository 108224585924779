import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import Pager from 'components/Pager';
import Preloader from 'components/Preloader';
import _ from 'lodash';
import moment from 'moment';
require('./list.scss');

class List extends React.Component {
    limit = 30;
    state = {
        page: 1
    };

    componentDidMount() {
        const params = this.props.location && this.props.location.query;
        this.onChangeRoute(params);
        this.props.showHeader('hide');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const newParams = nextProps.location.query;
        const oldParams = this.props.location.query;
        if (JSON.stringify(newParams) !== JSON.stringify(oldParams)) {
            this.onChangeRoute(newParams);
        }
    }

    onChangeRoute(params) {
        const data = {
            offset: this.limit * (((params && params.page) || 1) - 1),
            limit: this.limit
        };
        if (params && params.status) {
            data.status = params.status;
        }
        if (params && params.rangeStart && params.rangeEnd) {
            data.rangeStart = params.rangeStart;
            data.rangeEnd = params.rangeEnd;
        }
        if (params && params.is_expired === null) {
            data.is_expired = 1;
        }
        this.props.fetchFeedBack(data);
    }

    render() {
        // высняем отсутствие фильтр параметров

        const total = _.get(this.props.feedback, 'total', '...');
        return (
            <div className='feedback-component'>
                <div className='feedback__header'>
                    <div className='feedback__header__link feedback__header__link--header'>Сортировка по статусу:</div>
                    <div className={cx({ feedback__header__link: true, active: true })}>
                        <Link to='/feedback'>Все <span>({ total })</span></Link>
                    </div>
                </div>
                <div className='feedback__body'>
                    <div className='feedback-component-feedback'>
                        { (this.props.feedback && total === 0) ? (
                            <div>Сообщений от клиентов не поступало</div>) : '' }
                        { _.map(_.get(this.props.feedback, 'items', []), item => {
                            return (
                                <div className='feedback-component-feedback__item' key={item.id}>
                                    <div className='feedback__row'>
                                        <div className='feedback__number'>№ { item.id }, { moment(item.created_at).locale('ru').format('LLLL') }</div>
                                        <div className='feedback__client'>
                                            <span>{ item.client.nick }</span>,&nbsp;
                                            <span>{ item.client.phone }</span>
                                        </div>
                                        <div className='feedback__text'>
                                            { item.text }
                                        </div>
                                    </div>
                                </div>
                            );
                        }) }
                    </div>
                    <Pager {...this.props} total={total} size={this.limit} />
                </div>
                <Preloader trigger={this.props.fetching} fixed />
            </div>
        );
    }
}

export default List;
