import { api, httpBuildQuery } from 'utils';
import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------

const REQUEST_EVENTS = 'requestEvents';
const RECEIVE_EVENTS = 'receiveEvents';

const RECEIVE_EVENT = 'receiveEvent';

const CLEAR_EVENTS = 'clearEvents';

const I_READ_IT = 'iReadIt';

const CHANGE_EVENTS_TYPE = 'changeEventsType';

const RECEIVE_LAST_CALL = 'receiveLastCall';

// ------------------------------------
// Actions
// ------------------------------------

export const pushEvent = (res) => {
    return function(dispatch) {
        dispatch(receiveEvent(res));
    };
};

function receiveEvent(res) {
    return {
        type: RECEIVE_EVENT,
        payload: res
    };
}

export const fetchEvents = (data, merge) => {
    return function(dispatch) {
        dispatch(requestEvents());
        return api('/v3/partner/events?' + httpBuildQuery(data), {
            method: 'GET'
        }, dispatch)
            .then(response => {
                if (response) {
                    return dispatch(receiveEvents(response, data.offset, merge));
                }
            });
    };
};

function requestEvents() {
    return {
        type: REQUEST_EVENTS
    };
}

function receiveEvents(response, offset, merge) {
    return {
        type: RECEIVE_EVENTS,
        payload: response,
        offset: offset,
        merge: merge
    };
}
export const markAsRead = (data) => {
    return function(dispatch) {
        return api('/v1/partner/events/mark_as_read', {
            method: 'PUT',
            body: JSON.stringify(data)
        }, dispatch)
            .then(() => {
                dispatch({ type: I_READ_IT });
            });
    };
};

export const clearEvents = () => {
    return {
        type: CLEAR_EVENTS
    };
};

export const changeEventsType = (value) => {
    return {
        type: CHANGE_EVENTS_TYPE,
        payload: value
    };
};

export const fetchLastCall = () => {
    return function(dispatch) {
        dispatch(requestEvents());
        return api('/v3/partner/events?offset=0&limit=1&type=client incoming call', {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (_.get(res, 'items.length')) {
                    return dispatch(receiveLastCall(res.items[0]));
                }
            });
    };
};

function receiveLastCall(res) {
    return {
        type: RECEIVE_LAST_CALL,
        payload: res
    };
}

export const actions = {
    fetchEvents,
    markAsRead,
    clearEvents,
    changeEventsType,
    fetchLastCall
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [REQUEST_EVENTS]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_EVENTS]: (state, action) => {
        let events = Object.assign(state.events);

        let lastCall = state.lastCall ? Object.assign({}, state.lastCall) : null;

        if (!action.payload.items) {
            action.payload.items = [];
        }
        _.each(_.get(action.payload, 'items', []), e => {
            try {
                e.event_details = JSON.parse(e.event_details);
            } catch (e) {
                e.event_details = {};
            }
        });

        if (action.merge) {
            events.items = events.items.concat(action.payload.items);
        } else {
            events = action.payload;
            const newLastCall = _.find(action.payload.items, ['type', 'client incoming call']);

            if (newLastCall) {
                lastCall = newLastCall;
            }
        }
        return ({ ...state, events: events, fetching: false, iReadIt: false, offset: action.offset, lastCall: lastCall });
    },
    [RECEIVE_EVENT]: (state, action) => {
        const events = Object.assign(state.events); const res = action.payload;
        const newEvent = {
            client: {
                id: _.get(res, 'data.client.id', null),
                nick: _.get(res, 'data.clientName', ''),
                phone: _.get(res, 'data.clientPhone', '')
            },
            client_id: _.get(res, 'data.client.id', null),
            company_id: _.get(res, 'data.company.id', null),
            created_at: _.get(res, 'data.created_at', ''),
            entity_id: _.get(res, 'data.id', ''),
            id: _.get(res, 'data.id', ''),
            type: _.get(res, 'name', null),
            updated_at: _.get(res, 'data.updated_at', null),
            viewed: false
        };
        events.items.push(newEvent);
        events.unviewedTotal++;
        return ({ ...state, events: events, fetching: false, iReadIt: false });
    },
    [I_READ_IT]: (state) => {
        const events = Object.assign(state.events);
        return ({ ...state, fetching: false, readEvents: events, iReadIt: true });
    },
    [CLEAR_EVENTS]: (state) => {
        return ({ ...state, events: {} });
    },
    [CHANGE_EVENTS_TYPE]: (state, action) => {
        return ({ ...state, eventsType: action.payload });
    },
    [RECEIVE_LAST_CALL]: (state, action) => {
        try {
            action.payload.event_details = JSON.parse(action.payload.event_details);
        } catch (e) {
            action.payload.event_details = {};
        }
        return ({ ...state, lastCall: action.payload });
    }
};

const initialState = {
    fetching: false,
    iReadIt: false,
    events: {},
    offset: 0,
    readEvents: {},
    eventsType: 'withoutCalls',
    lastCall: null
};

export default function eventsReducer(state = initialState, action) {
    state = Object.assign({}, initialState, state);

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
