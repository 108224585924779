import { connect } from 'react-redux';
import { actions } from './../modules/clientsExcel';
import { fetchClient, updateClientV2, showClientCard } from 'modules/clients';
import { showHeader, clearAjaxError } from 'modules/hangar';

import Clients from '../components/Clients';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => ({
    clients: state.clientsExcel.clients,
    fetching: state.clientsExcel.fetching,
    importResult: state.clientsExcel.importResult,
    exportClientsId: state.clientsExcel.exportClientsId,
    exportingClients: state.clientsExcel.exportingClients,
    partner: state.account.partner,
    ajaxError: state.hangar.ajaxError

});

export default connect(mapStateToProps, Object.assign(actions, { fetchClient, updateClientV2, showClientCard, showHeader, clearAjaxError }))(withRouter(Clients));
