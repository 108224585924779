import React from 'react';

import Slim from '../../../../../../lib/slim/slim.react.jsx';

import '../../../../../../lib/slim/slim.scss';
import _ from 'lodash';

require('./styles_gallery.scss');

class Gallery extends React.Component {
    state = {
        newCompanyImage: false
    };

    imageWillSave(data, ready) {
        this.showLoader();
        this.setState({ newCompanyImage: true });
        ready(data);
    }

    imageDidUpload(data, ready, file) {
        // ResourcesAPI.uploadResourceImage(this.cropper.instance);
        this.hideLoader();
        if (_.get(file, 'cover.id')) {
            this.props.changeCompanyCover(file.cover);
        }
    }

    galleryImageDidUpload(data, ready, file) {
        this.props.pushCompanyFile(
            _.merge({
                id: _.get(file, 'gallery.id'),
                thumbnails: _.get(file, 'gallery.thumbnails')
            }, _.get(file, 'gallery.fileInfo'))
        );
    }

    deleteFile(fileId, e) {
        if (e) {
            e.preventDefault();
        }
        if (window.confirm('Удалить файл компании?')) {
            this.props.deleteCompanyFile(this.props.company.id, fileId);
        } else {
            return false;
        }
    }

    deleteImageSlim(data, remove) {
        if (window.confirm('Удалить обложку компании?')) {
            this.props.deleteCompanyCover(this.props.company.id);
            this.setState({ newCompanyImage: false });
            remove();
        } else {
            return false;
        }
    }

    showLoader() {

    }

    hideLoader() {

    }

    render() {
        const company = this.props.company;
        // ежели нетути телефонов, то один закинем для генерации полей в форме
        return (
            <div className='settings-block'>
                <div className='settings-block__h1'>Галерея компании</div>
                <div className='settings-block--gallery'>

                    { _.map(_.filter(company.files, f => f.thumbnails.gallery), file => (
                        <div key={file.id} className='settings-block--gallery__block'>
                            <img src={file.thumbnails.gallery} alt='' />
                            <button
                                className='slim-btn slim-btn-remove'
                                title='Удалить'
                                type='button'
                                onClick={this.deleteFile.bind(this, file.id)}
                            >
                                Удалить
                            </button>
                        </div>
                    )) }
                    <div className='settings-block--gallery__block settings-block--gallery__block--button'>
                        <Slim
                            ref={node => (this.cropper = node)}
                            ratio='268:129'
                            service={`${process.env.REACT_APP_API_PATH}/v1/partner/companies/${company.id}/images?scheme=gallery`}
                            label=''
                            push
                            buttonEditLabel='Редактировать'
                            buttonRemoveLabel='Удалить'
                            buttonDownloadLabel='Скачать'
                            buttonUploadLabel='Загрузить'
                            buttonCancelLabel='Отмена'
                            buttonConfirmLabel='Ок'
                            maxFileSize='3.5'
                            statusFileSize='Максимально допустимый размер: $0 Мб'
                            minSize={{ width: 10, height: 10 }}
                            statusImageTooSmall='Минимально допустимый размер: $0 пикселей'
                            statusNoSupport='Не поддерживается браузером'
                            statusUnknownResponse='Ошибка загрузки'
                            statusUploadSuccess='Ок'
                            willSave={this.imageWillSave.bind(this)}
                            didUpload={this.galleryImageDidUpload.bind(this)}
                        >
                            <input type='file' name='file' id='slimGallery' />
                        </Slim>
                        <label
                            htmlFor='slimGallery'
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <div className='text'>Добавить фото</div>
                        </label>
                    </div>
                </div>
            </div>
        );
    }
}

export default Gallery;
