import React from 'react';
import PropTypes from 'prop-types';
import classes from './Clients.module.scss';
import Pager from 'components/Pager';
import Button from 'components/Button';
import CreateClient from '../../Calendar/components/ModalWindows/CreateClient';
import _ from 'lodash';
import moment from 'moment';
import Preloader from 'components/Preloader';

const LIMIT = 15;

class Clients extends React.Component {
    constructor(props) {
        super(props);
        this.getBySearch = this.getBySearch.bind(this);
        this.showCreateClient = this.showCreateClient.bind(this);
        this.hideCreateClient = this.hideCreateClient.bind(this);
        this.createClientHandler = this.createClientHandler.bind(this);
    }

    state = {
        changedClients: {},
        search: '',
        addClient: false
    };

    UNSAFE_componentWillMount() {
        moment.locale('ru');
        if (_.get(this.props.partner, 'role', null) !== 'PARTNER') {
            this.props.history.push('/');
        }
        this.props.clearAjaxError();
    }

    componentDidMount() {
        const params = this.props.location.query;
        this.onChangeRoute(params);
        this.props.showHeader('hide');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const newParams = nextProps.location.query;
        const oldParams = this.props.location.query;
        console.log('this.props.location', this.props.location);
        if (JSON.stringify(newParams) !== JSON.stringify(oldParams)) {
            this.onChangeRoute(newParams);
        }
        if (!this.props.importResult && _.get(nextProps.importResult, 'importedClients', null)) {
            this.onChangeRoute(newParams);
        }
    }

    onChangeRoute(params) {
        const data = {
            offset: LIMIT * (((params && params.page) || 1) - 1),
            limit: LIMIT
        };

        if (this.state.search) {
            data.data = this.state.search;
            this.props.fetchSearchClientsList(data);
        } else {
            this.props.fetchClients(data);
        }
    }

    importClients() {
        const form = document.getElementById('importForm');

        const formData = new FormData(form);

        this.props.importClients(formData);

        document.getElementById('reset').click();
    }

    exportClients() {
        this.props.downloadExportClients(this.props.exportClientsId);
    }

    viewClient(id) {
        this.props.history.push('/');
        this.props.showClientCard(true);
        this.props.fetchClient(id);
    }

    closeModal() {
        this.props.clearImportResult();
        this.props.clearAjaxError();
    }

    changeClient(client) {
        const comment = client.comment.replace(/<br>/g, '');
        const changedClients = this.state.changedClients;
        if (!changedClients[client.id]) {
            changedClients[client.id] = { id: client.id };
        }
        changedClients[client.id].comment = comment;
        this.setState({ changedClients: changedClients });
        this.props.updateClientV2({ id: client.id, comment: comment });
    }

    getBySearch(e) {
        if (this.updateTimer) {
            clearTimeout(this.updateTimer);
        }

        const value = e.target.value; const searchLimit = 2;

        const searching = value.length >= searchLimit; const resettingSearch = value.length === 0;

        if (searching || resettingSearch) {
            const data = { offset: 0, limit: LIMIT };

            this.updateTimer = setTimeout(function() {
                if (!this.props.location.search) {
                    if (searching) {
                        data.data = value;
                        this.props.fetchSearchClientsList(data);
                    } else {
                        this.props.fetchClients(data);
                    }
                } else {
                    this.props.history.push('/clients');
                }
            }.bind(this), 1000);
        }

        this.setState({ search: value });
    }

    showCreateClient() {
        this.setState({ addClient: true });
    }

    hideCreateClient() {
        this.setState({ addClient: false });
    }

    createClientHandler() {
        this.onChangeRoute(this.props.location.query);
    }

    render() {
        const { search } = this.state;
        const clients = this.props.clients;
        if (!clients) {
            return null;
        }
        const importResult = this.props.importResult;

        const importError = this.props.ajaxError;

        let modalContent;

        if (importError) {
            modalContent = (
                <div className={classes.error}>{ importError.response.message }</div>
            );
        }
        if (importResult) {
            modalContent = (
                <div>
                    <div>
                        <div className={classes.text}>Всего строк в документе:</div>
                        <div className={classes.value}>{ importResult.totalRows }</div>
                    </div>
                    <div>
                        <div className={classes.text}>Импортировано клиентов:</div>
                        <div className={classes.value}>{ importResult.importedClients }</div>
                    </div>
                </div>
            );
        }

        let exportPopup;
        if (this.props.exportingClients) {
            if (this.props.exportClientsId) {
                exportPopup = (
                    <div className={classes.exportPopup}>
                        Экспорт клиентов завершен. Файл будет доступен в течение 5 минут.&nbsp;
                        <span onClick={this.exportClients.bind(this)}>Скачать файл</span>
                    </div>
                );
            } else {
                exportPopup = (
                    <div className={classes.exportPopup}>
                        Экспортируем клиентов...
                    </div>
                );
            }
        }

        let exportClients;
        if (this.props.exportingClients) {
            if (this.props.exportClientsId) {
                exportClients = (
                    <div className={classes.excel} onClick={this.exportClients.bind(this)}>Скачать</div>
                );
            } else {
                exportClients = (
                    <div className={classes.excel + ' ' + classes.processing}>Подождите, идет экспорт...</div>
                );
            }
        } else {
            exportClients = (
                <div className={classes.excel} onClick={this.props.exportClients.bind(this, clients.total)}>Экспорт в
                    excel
                </div>
            );
        }

        return (
            <div className={classes.wrapper}>
                <div className={classes.body}>
                    <form id='importForm' encType='multipart/form-data' method='post'>
                        <input
                            id='importFile' accept='text/csv' type='file' name='list'
                            onChange={this.importClients.bind(this)}
                        />
                        <input id='reset' type='reset' />
                    </form>
                    <label htmlFor='importFile'>
                        <div className={classes.excel}>Импорт из excel</div>
                    </label>
                    { exportClients }
                    <div className={classes.search}>
                        <div className={classes.text}>Поиск клиента</div>
                        <input
                            type='text'
                            value={search}
                            onChange={this.getBySearch}
                        />
                        <div className={classes.addClient} onClick={this.showCreateClient}>+</div>
                    </div>
                    <div className={classes.client + ' ' + classes.header}>
                        <div className={classes.nick}>Имя</div>
                        <div className={classes.phone}>Телефон</div>
                        <div className={classes.birthday}>Дата рождения</div>
                        <div className={classes.comment}>Комментарий</div>
                    </div>
                    { _.map(this.props.clients.items, client => {
                        const birthdayField = _.get(_.find(client.fields, ['label', 'birthday']), 'pivot.value', null);

                        const birthdayString = birthdayField ? moment(birthdayField).format('DD MMMM YYYY') + ' г.' : 'Не указана';

                        return (
                            <div
                                key={client.id}
                                className={classes.client + (!client.user_id ? (' ' + classes.noOrtus) : '')}
                                title={client.user_id ? '' : 'Клиент не зарегистрирован в системе Ortus.'}
                            >
                                <div className={classes.nick} onClick={this.viewClient.bind(this, client.id)}>
                                    { client.client_companies[0].nick }
                                </div>
                                <div className={classes.phone}>{ client.phone }</div>
                                <div className={classes.birthday}>
                                    { birthdayString }
                                </div>
                                <div
                                    className={classes.comment} contentEditable
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }
                                    }}
                                    onKeyUp={(e) => {
                                        e.preventDefault();
                                        if (e.keyCode === 27) {
                                            e.target.blur();
                                            const changedClients = this.state.changedClients;
                                            if (changedClients[client.id]) {
                                                delete changedClients[client.id];
                                            }
                                            e.target.innerHTML = client.client_companies[0].comment;
                                            this.setState({ changedClients: changedClients });
                                        }
                                        if (e.keyCode === 13) {
                                            e.preventDefault();
                                            e.target.blur();
                                            const changedClients = _.clone(this.state.changedClients);
                                            this.setState({ changedClients: changedClients });
                                            this.changeClient(changedClients[client.id]);
                                        }
                                    }}
                                    onInput={(e) => {
                                        e.preventDefault();
                                        const changedClients = this.state.changedClients;
                                        if (!changedClients[client.id]) {
                                            changedClients[client.id] = { id: client.id };
                                        }
                                        changedClients[client.id].comment = e.target.innerHTML;
                                    }}
                                    suppressContentEditableWarning
                                >{ this.state.changedClients[client.id] ? this.state.changedClients[client.id].comment : client.client_companies[0].comment }
                                </div>
                            </div>
                        );
                    }) }
                </div>
                <Pager {...this.props} total={clients.total} size={LIMIT} />
                <Preloader trigger={this.props.fetching} />
                <div
                    className={classes.modal + ' ' + (modalContent ? classes.show : '')}
                    onClick={this.closeModal.bind(this)}
                >
                    <div className={classes.content} onClick={e => e.stopPropagation()}>
                        { modalContent }
                        <div className={classes.btnContainer}>
                            <Button
                                value='Ok'
                                className='orange'
                                onClick={this.closeModal.bind(this)}
                            />
                        </div>
                    </div>
                </div>
                { exportPopup }
                { this.state.addClient ? <CreateClient closeHandler={this.hideCreateClient} createHandler={this.createClientHandler} /> : null }
            </div>
        );
    }
}

Clients.contextTypes = {
    router: PropTypes.object
};

export default Clients;
