import { api, httpBuildQuery } from 'utils';
import download from 'downloadjs';

const EXPORT_EXPIRED_TIMEOUT = 300000;
const EXPORT_DOWNLOAD_TIMEOUT = 2000;
// ------------------------------------
// Constants
// ------------------------------------
export const SEND_REQUEST = 'sendRequest';

export const RECEIVE_CLIENTS = 'receiveClients';

export const RECEIVE_IMPORT_RESULT = 'receiveImportResult';

export const CLEAR_IMPORT_RESULT = 'clearImportResult';

export const CHANGE_EXPORT_CLIENTS_ID = 'changeExportClientsId';

export const CHANGE_EXPORTING_CLIENTS = 'changeExportingClients';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchClients = (data) => {
    return function(dispatch) {
        dispatch(sendRequest());
        return api('/v3/partner/clients?' + httpBuildQuery(data), {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receiveClients(res)));
    };
};

export const fetchSearchClientsList = (data) => {
    return function(dispatch) {
        dispatch(sendRequest());
        return api('/v3/partner/clients/search?' + httpBuildQuery(data), {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receiveClients(res)));
    };
};

function sendRequest() {
    return {
        type: SEND_REQUEST
    };
}

function receiveClients(res) {
    return {
        type: RECEIVE_CLIENTS,
        payload: res
    };
}

export const importClients = (data) => {
    return function(dispatch) {
        return api('/v3/partner/clients/import', {
            method: 'POST',
            body: data
        }, dispatch, true)
            .then(res => {
                if (res) {
                    dispatch(receiveImportResult(res));
                }
            });
    };
};

function receiveImportResult(res) {
    return {
        type: RECEIVE_IMPORT_RESULT,
        payload: res
    };
}

export const clearImportResult = () => {
    return {
        type: CLEAR_IMPORT_RESULT
    };
};

export const exportClients = (limit) => {
    return function(dispatch) {
        dispatch(changeExportingClients(true));
        return api('/v3/partner/clients/export?limit=' + limit, {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res.refId) {
                    return getExportClients(res.refId, dispatch);
                }
            });
    };
};

function getExportClients(refId, dispatch) {
    return fetch(process.env.REACT_APP_API_PATH + '/v3/partner/clients/export/' + refId,
        {
            method: 'get',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Api-Token': localStorage.getItem('token')
            }
        }
    ).then(res => {
        if (res.status === 200) {
            if (dispatch) {
                dispatch(changeExportClientsId(refId));
                setTimeout(() => {
                    dispatch(changeExportingClients(false));
                    dispatch(changeExportClientsId(null));
                }, EXPORT_EXPIRED_TIMEOUT);
            }
        } else if (res.status === 404) {
            setTimeout(() => {
                getExportClients(refId, dispatch);
            }, EXPORT_DOWNLOAD_TIMEOUT);
        } else {
            if (dispatch) {
                dispatch(changeExportingClients(false));
                dispatch(changeExportClientsId(null));
            }
        }
    });
}

function changeExportClientsId(res) {
    return {
        type: CHANGE_EXPORT_CLIENTS_ID,
        payload: res
    };
}

export const downloadExportClients = (refId) => {
    return function(dispatch) {
        return fetch(process.env.REACT_APP_API_PATH + '/v3/partner/clients/export/' + refId,
            {
                method: 'get',
                mode: 'cors',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Api-Token': localStorage.getItem('token')
                }
            }
        ).then(res => {
            if (res.status === 200) {
                dispatch(changeExportingClients(false));
                dispatch(changeExportClientsId(null));
                return res.blob();
            }
        }).then(blob => {
            if (blob) {
                download(blob, 'Список клиентов.xlsx');
            }
        });
    };
};

function changeExportingClients(status) {
    return {
        type: CHANGE_EXPORTING_CLIENTS,
        payload: status
    };
}

export const actions = {
    fetchClients,
    importClients,
    exportClients,
    clearImportResult,
    downloadExportClients,
    fetchSearchClientsList
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [SEND_REQUEST]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_CLIENTS]: (state, action) => {
        return ({ ...state, clients: action.payload, fetching: false });
    },
    [RECEIVE_IMPORT_RESULT]: (state, action) => {
        return ({ ...state, importResult: action.payload });
    },
    [CLEAR_IMPORT_RESULT]: (state) => {
        return ({ ...state, importResult: null, importError: null });
    },
    [CHANGE_EXPORT_CLIENTS_ID]: (state, action) => {
        return ({ ...state, exportClientsId: action.payload });
    },
    [CHANGE_EXPORTING_CLIENTS]: (state, action) => {
        return ({ ...state, exportingClients: action.payload });
    }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    clients: null,
    fetching: false,
    importResult: null,
    exportClientsId: null,
    exportingClients: false
};
export default function clientsExcelReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
