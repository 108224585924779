import React from 'react';
import PropTypes from 'prop-types';
// Libs
import { Scrollbars } from 'react-custom-scrollbars';
import classes from './FieldInput.module.scss';
import _ from 'lodash';

// Comps
import RenderInBody from 'components/RenderInBody';

class FieldInput extends React.Component {
    state = {
        onEdit: false,
        randomId: 'fieldInput' + Math.floor(Math.random() * 9000 + 1000)
    };

    componentDidMount() {

    }

    UNSAFE_componentWillReceiveProps() {

    }

    componentWillUnmount() {

    }

    renderTrack({ style, ...props }) {
        const trackStyle = {};
        return (
            <div
                className={classes.scrollTrack}
                style={{ ...style, ...trackStyle }}
                {...props}
            />
        );
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {};
        return (
            <div
                className={classes.scrollThumb}
                style={{ ...style, ...thumbStyle }}
                {...props}
            />
        );
    }

    close() {
        this.setState({ onEdit: false });
    }

    getFieldValue(field) {
        if (field.type === 'reference') {
            let fieldValue,
                parentId;
            if (field.value) {
                parentId = _.find(field.references, reference => {
                    return reference.id === parseInt(field.value);
                }).parent;
                parentId && !_.find(this.props.client.fields, item => {
                    return parseInt(item.value) === parentId;
                })// обнуление значения поля при изменении значения родительского поля
                    ? this.props.changeSelectField(null, field, this.props.client.id)
                    : fieldValue = _.find(field.references, reference => {
                        return reference.id === parseInt(field.value);
                    }).title;
            }
            return (
                <div
                    className='changeValue' key={field.id}
                    onClick={() => (this.setState({ onEdit: !this.state.onEdit }))}
                >
                    <div className='value'>{ field.value ? fieldValue : 'Не выбрано' }</div>
                    <div className='select' id={this.state.randomId} />
                    { this.state.onEdit === true
                        ? (
                            <RenderInBody
                                byId={this.state.randomId}
                                align={['center', 'bottom']}
                                closeHandler={this.close.bind(this)}
                            >
                                <div className='triangle' />
                                <div className='clubselect'>
                                    <div
                                        className='clubselect__outerMenu' onClick={e => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <Scrollbars
                                            id='fieldScrollbar'
                                            ref='fieldScrollbar'
                                            thumbSize={16}
                                            autoHeight
                                            autoHide={false}
                                            renderTrackVertical={this.renderTrack}
                                            renderThumbVertical={this.renderThumb}
                                            onWheel={event => {
                                                const { fieldScrollbar } = this.refs;
                                                const scroll = fieldScrollbar.getValues();
                                                if (event.deltaY > 0 && scroll.clientHeight + scroll.scrollTop === scroll.scrollHeight) {
                                                    event.preventDefault();
                                                }
                                                if (event.deltaY < 0 && scroll.scrollTop === 0) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        >
                                            { _.map(field.references, item => {
                                                return (
                                                    <div
                                                        key={item.label}
                                                        className='item'
                                                        onClick={() => {
                                                            this.props.onChange(item.id);
                                                            this.setState({ onEdit: false });
                                                        }}
                                                    >
                                                        { item.title }
                                                    </div>);
                                            }) }
                                        </Scrollbars>
                                    </div>
                                </div>
                            </RenderInBody>
                        )
                        : null }
                </div>
            );
        } else {
            return (
                this.state.onEdit === true
                    ? (
                        <div className='changeValue'>
                            <input
                                type='text'
                                name={field.id}
                                autoFocus
                                value={field.value}
                                onChange={(e) => {
                                    this.props.onChange(e);
                                }}
                                onClick={e => e.stopPropagation()}
                                onKeyPress={e => {
                                    if (e.key === 'Enter') {
                                        this.props.onFinishChange(e.currentTarget.value);
                                        this.setState({ onEdit: false });
                                    }
                                }}
                            />
                            <div
                                className='editOk'
                                onClick={() => {
                                    this.props.onFinishChange(field.value);
                                    this.setState({ onEdit: !this.state.onEdit });
                                }}
                            />
                        </div>
                    )
                    : (
                        <div
                            className='changeValue' onClick={() => (this.setState({ onEdit: !this.state.onEdit }))}
                            key={'changeValue' + field.id}
                        >
                            <div className='value'>{ field.label === 'power' && field.value ? field.value + ' л.с.' : field.value }</div>
                            <div className='edit' key={'edit' + field.id} />
                        </div>
                    )
            );
        }
    }

    render() {
        const field = this.props.field;
        if (!field) return null;
        return (
            <div className={'fieldInput ' + (this.props.className ? this.props.className : '')}>
                <div className={classes.title}>{ field.title }:</div>
                { this.getFieldValue(field) }
            </div>
        );
    }
}

FieldInput.contextTypes = {
    router: PropTypes.object
};

export default FieldInput;
