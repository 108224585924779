import { connect } from 'react-redux';
import { actions } from 'modules/auth';
import { clearAjaxError } from 'modules/hangar';

import RemindPassword from './../components/RemindPassword';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => ({
    partner: state.account.partner,
    token: state.account.token,
    phone: state.account.phone,
    activationCode: state.account.activationCode,
    activationKey: state.account.activationKey,
    reminded: state.account.reminded,
    error: state.account.error,
    ajaxError: state.hangar.ajaxError,
    loginPhone: state.account.login
});

export default connect(mapStateToProps, Object.assign(actions, { clearAjaxError }))(withRouter(RemindPassword));
