/**
 * Created by user on 26.05.2016.
 */

import { connect } from 'react-redux';

import Resource from './Resource';

import { setOrderDay, chooseToOrder } from 'modules/orders';

const mapStateToProps = () => ({

});

export default connect(mapStateToProps, { setOrderDay, chooseToOrder })(Resource);
