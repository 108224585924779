import { connect } from 'react-redux';
import { actions } from '../../modules/statistics';

import ByOrders from './ByOrders';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => ({
    statistics: state.statistics.statistics,
    fetching: state.fetching
});

export default connect(mapStateToProps, actions)(withRouter(ByOrders));
