import React from 'react';
// import PropTypes from 'prop-types';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import CoreLayout from '../layouts/CoreLayout';
import Calendar from '../routes/Calendar/calendarContainer';
import Login from '../routes/Login/containers/LoginContainer';
import RemindPassword from '../routes/RemindPassword/containers/RemindContainer';
import CreateCompany from '../routes/CreateCompany/containers/CreateCompanyContainer';
import Orders from '../routes/Orders/containers/OrdersContainer';
import Settings from '../routes/Settings/Main/containers/SettingsContainer';
import Clients from '../routes/Clients/containers/ClientsExcelContainer';
import Widgets from '../routes/Widgets/containers/WidgetsContainer';
import Statistics from '../routes/Statistics/Main/containers/StatisticsContainer';
import ByResources from '../routes/Statistics/Main/components/ByResources/container';
import ByServices from '../routes/Statistics/Main/components/ByServices/container';
import ByOrders from '../routes/Statistics/Main/components/ByOrders/container';
import BySms from '../routes/Statistics/Main/components/BySms/container';
import Feedback from '../routes/FeedBack/List/containers/feedbackContainer';
import Common from '../routes/Settings/Main/components/Common/container';
import Posts from '../routes/Settings/Main/components/Posts/container';
import Services from '../routes/Settings/Main/components/Services/container';
import Cpro from '../routes/Settings/Main/components/Cpro/container';
import Callcenter from '../routes/Callcenter/container';
import Download from '../routes/Download/containers/DownloadContainer';
import Okk from '../routes/Okk/Main/containers/OkkContainer';
import CustomLists from '../routes/Okk/CustomLists/containers/OkkContainer';
import Archive from '../routes/Okk/Archive/containers/OkkContainer';
import Reports from '../routes/Okk/Reports/Reports/containers/OkkContainer';
import OkkLayout from '../layouts/OkkLayout';
import ReportByAnswer from '../routes/Okk/Reports/ReportByAnswer/containers/OkkContainer';
import NotFound from '../routes/NotFound/NotFound';

const AppContainer = () => {
    return (
        <div style={{ height: '100%' }}>
            <CoreLayout>
                <Switch>
                    <Route path={['/login', '/remind-password', '/create-company']}>
                        <Switch>
                            <Route path='/login' component={Login} />
                            <Route path='/remind-password' component={RemindPassword} />
                            <Route path='/create-company' component={CreateCompany} />
                        </Switch>
                    </Route>
                    <Route path='/' component={Calendar} exact />
                    <Route path='/callcenter' component={Callcenter} />
                    <Route path='/download' component={Download} />
                    <Route path='/clients' component={Clients} />
                    <Route path='/orders' component={Orders} />
                    <Route path='/widgets' component={Widgets} />
                    <Route path='/feedback' component={Feedback} />
                    <Route path={['/statistics']}>
                        <Statistics>
                            <Route path='/statistics' exact>
                                <Redirect to='statistics/resources' />
                            </Route>
                            <Route path='/statistics/resources' component={ByResources} />
                            <Route path='/statistics/services' component={ByServices} />
                            <Route path='/statistics/orders' component={ByOrders} />
                            <Route path='/statistics/sms' component={BySms} />
                        </Statistics>
                    </Route>
                    <Route path={['/settings']}>
                        <Settings>
                            <Switch>
                                <Route path='/settings' exact>
                                    <Redirect to='/settings/common' />
                                </Route>
                                <Route path='/settings/common' component={Common} />
                                <Route path='/settings/posts' component={Posts} exact />
                                <Route
                                    path='/settings/posts/:id' render={({ match }) => {
                                        return <Posts params={match && match.params} />;
                                    }}
                                />
                                <Route path='/settings/services' component={Services} />
                                <Route path='/settings/connect-pro' component={Cpro} />
                            </Switch>
                        </Settings>
                    </Route>
                    <Route path={['/okk']}>
                        <OkkLayout>
                            <Okk>
                                <Switch>
                                    <Route path='/okk' component={CustomLists} />
                                    <Route path='/okk/custom-lists' component={CustomLists} />
                                    <Route path='/okk/archive' component={Archive} />
                                    <Route path='/okk/reports' component={Reports} exact />
                                    <Route path='/okk/reports/by-answer' component={ReportByAnswer} />
                                </Switch>
                            </Okk>
                        </OkkLayout>
                    </Route>
                    <Route component={NotFound} />
                </Switch>
            </CoreLayout>
        </div>
    );
};

export default withRouter(AppContainer);
