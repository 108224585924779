import { connect } from 'react-redux';
import { actions } from '../../modules/okk';

import Archive from '../components/Archive';

const mapStateToProps = () => ({

});

export default connect(mapStateToProps, actions)(Archive);
