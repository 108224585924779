import React from 'react';
import classes from './Client.module.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import MainInfo from './MainInfo';
import PrivateInfo from './PrivateInfo';
import BoardComputer from './BoardComputer';
import ClientHistory from './ClientHistory';
import { formatPhone } from 'utils';
import Preloader from 'components/Preloader';
import _ from 'lodash';
import rsScroller from 'react-smooth-scroller';
import ActivateClientModal from '../../../ModalWindows/ActivateClient';

class Client extends React.Component {
    state = {
        shadeMenuStatus: null,
        menuStatus: 'mainInfo'
    };

    UNSAFE_componentWillMount() {
        if (this.props.clientId && !this.props.client.id) {
            this.props.fetchClient(this.props.clientId);
        } else if (localStorage.getItem('clients.v1') && localStorage.getItem('partner') && !this.props.client.id) {
            const clients = JSON.parse(localStorage.getItem('clients.v1'));

            const partner = JSON.parse(localStorage.getItem('partner'));

            if (clients && clients[partner.account_id] && clients[partner.account_id].length) {
                this.props.fetchClient(clients[partner.account_id][0].id);
            }
        }
    }

    componentDidUpdate() {
        if (document.getElementById('maxWidth')) {
            const maxWidth = document.getElementById('maxWidth').getBoundingClientRect().width;

            // if (this.props.client.self_change) {
            //    nameWidth = document.getElementById('nameWidth').getBoundingClientRect().width;
            // } else {
            const nameWidth = (document.getElementById('nameWidth').getBoundingClientRect().width +
                document.getElementById('pencilWidth').getBoundingClientRect().width) * 1.02; // it's a magic
            // }

            this.props.checkTooltip(nameWidth, maxWidth, 'clientNameTooltipShow');
        }
        if (document.getElementById('clientScrollBar') && document.getElementById('fieldScrollbar')) {
            const { clientScrollBar } = this.refs;
            const clientBottom = document.getElementById('clientScrollBar').getBoundingClientRect().bottom;

            const fieldBottom = document.getElementById('fieldScrollbar').getBoundingClientRect().bottom;

            const magicNumber = 12; // ибо иначе при открытии селект-меню для поля обрезается нижняя граница
            if (parseInt(fieldBottom - clientBottom) >= -magicNumber) {
                clientScrollBar.scrollTop(clientScrollBar.getValues().scrollTop + fieldBottom - clientBottom + magicNumber);
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.client.id !== this.props.client.id) {
            this.props.clearClientCars();
        }
    }

    componentWillUnmount() {
        this.props.clearClientCars();
    }

    orderClient(e) {
        e.preventDefault();
        const scrollSpeed = 1800; const // пикселей в секунду
            revise = document.body.scrollTop;

        const el = document.getElementById('orderfooter');

        const rect = el.getBoundingClientRect();

        const topScroll = rect.top;

        const duration = Math.abs(parseInt(topScroll / scrollSpeed * 1000));
        rsScroller.scrollToTarget('orderfooter', {
            easing: 'easeInSine',
            duration: duration,
            frame: 25,
            revise: revise
        });
        this.props.setFooterMinHeight(document.documentElement.clientHeight + 'px');
    }

    renderTrack({ style, ...props }) {
        const trackStyle = {};
        return (
            <div
                className={classes.scrollTrack}
                style={{ ...style, ...trackStyle }}
                {...props}
            />
        );
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {};
        return (
            <div
                className={classes.scrollThumb}
                style={{ ...style, ...thumbStyle }}
                {...props}
            />
        );
    }

    editNick() {
        return (
            this.props.editClientChange === 'nick'
                ? (
                    <div className={classes.changeValue}>
                        <input
                            type='text'
                            name='nick'
                            maxLength='50'
                            autoFocus
                            value={this.props.changedClient.nick}
                            onChange={this.props.changeClient}
                            onClick={e => e.stopPropagation()}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    return this.props.updateClient(this.props.client.id);
                                }
                            }}
                        />
                        <div
                            className={classes.editOk}
                            onClick={this.props.updateClient.bind(this, this.props.client.id)}
                        />
                    </div>
                )
                : (
                    <div
                        id='maxWidth' className={classes.changeValue}
                        onClick={this.props.editClient.bind(this, 'nick')}
                    >
                        <div id='nameWidth' className={classes.value}>{ this.props.client.nick },</div>
                        <div id='pencilWidth' className={classes.edit} />
                    </div>
                )
        );
    }

    changeMenuStatus(status) {
        this.setState({ menuStatus: status });
    }

    render() {
        const clients = _.get(this.props.clients, 'items', []);
        if (clients.length > 0) {
            return null;
        }

        if (this.props.client.is_activated) {
            const temper = _.find(this.props.client.fields, ['label', 'temper']);
            if (temper && temper.value) {
                this.props.client.temper = _.find(temper.references, reference => {
                    return reference.id === parseInt(temper.value);
                }).title;
            }
        }

        const startWindow = (
            <div className={classes.element}>
                <div className={classes.startWindow}>
                    <div className={classes.icons}>
                        <div className={classes.search} />
                        <div className={classes.or} />
                        <div className={classes.add} />
                    </div>
                    <div className={classes.startText}>Для начала работы воспользуйтесь поиском или добавьте клиента.
                    </div>
                </div>
            </div>
        );

        let content;
        switch (this.state.menuStatus) {
        case 'mainInfo':
            content = <MainInfo />;
            break;
        case 'privateInfo':
            content = <PrivateInfo />;
            break;
        case 'boardСomputer':
            content = <BoardComputer />;
            break;
        case 'clientHistory':
            content = <ClientHistory location={this.props.location} />;
            break;
        default:
        }

        const client = (
            <div className={classes.element}>
                <div className={classes.header}>
                    <div
                        className={this.state.menuStatus === 'mainInfo'
                            ? classes.menu + ' ' + classes.active + ' ' + classes.mainInfo
                            : classes.menu + ' ' + classes.mainInfo}
                        onClick={this.changeMenuStatus.bind(this, 'mainInfo')}
                    >
                        <div className={classes.text}>Основная<br />информация</div>
                        { this.state.menuStatus === 'mainInfo' ? <div className={classes.triangleDown} /> : null }
                    </div>
                    <div
                        className={this.state.menuStatus === 'privateInfo'
                            ? classes.menu + ' ' + classes.active + ' ' + classes.privateInfo
                            : classes.menu + ' ' + classes.privateInfo}
                        onClick={this.changeMenuStatus.bind(this, 'privateInfo')}
                    >
                        <div className={classes.text}>Личная<br />информация</div>
                        { this.state.menuStatus === 'privateInfo'
                            ? <div className={classes.triangleDown} /> : null }
                    </div>
                    <div
                        className={this.state.menuStatus === 'boardСomputer'
                            ? classes.menu + ' ' + classes.active + ' ' + classes.boardComp
                            : classes.menu + ' ' + classes.boardComp}
                        onClick={this.changeMenuStatus.bind(this, 'boardСomputer')}
                    >
                        <div className={classes.text}>Бортовой<br />компьютер</div>
                        { this.state.menuStatus === 'boardСomputer'
                            ? <div className={classes.triangleDown} /> : null }
                    </div>
                    <div
                        className={this.state.menuStatus === 'clientHistory'
                            ? classes.menu + ' ' + classes.active + ' ' + classes.history
                            : classes.menu + ' ' + classes.history}
                        onClick={this.changeMenuStatus.bind(this, 'clientHistory')}
                    >
                        <div className={classes.text}>История<br />посещений</div>
                        { this.state.menuStatus === 'clientHistory'
                            ? <div className={classes.triangleDown} /> : null }
                    </div>
                </div>
                <div className={classes.body}>
                    <div className={classes.clientHeader}>
                        <div className={classes.client}>
                            <div
                                className={classes.tooltip + (this.props.clientNameTooltipShow ? '' : ' ' + classes.hidden)}
                            >
                                { this.props.client.nick }
                            </div>
                            <div
                                className={classes.shadowTriangle + (this.props.clientNameTooltipShow ? '' : ' ' + classes.hidden)}
                            />
                            <div className={classes.name}>
                                { this.editNick() }
                            </div>
                            <div className={classes.pencil} />
                            <div className={classes.phone}>{ formatPhone(this.props.client.phone) }</div>
                        </div>
                    </div>
                    <div className={classes.scrollBlock}>
                        <Scrollbars
                            ref='clientScrollBar'
                            id='clientScrollBar'
                            thumbSize={16}
                            autoHide={false}
                            renderTrackVertical={this.renderTrack}
                            renderThumbVertical={this.renderThumb}
                        >
                            { content }
                        </Scrollbars>
                    </div>
                    <div className={classes.record}>
                        {
                            (this.props.client.user && !this.props.client.canReactivate)
                                ? null
                                : (
                                    <div
                                        className={classes.activateButton}
                                        onClick={this.props.shadeMenuChange.bind(this, 'activateClient')}
                                    >
                                        Активировать клиента
                                    </div>
                                )
                        }
                    </div>
                </div>
                <Preloader trigger={this.props.fetching} />
            </div>
        );

        return (
            <div className={classes.wrapper}>
                { _.isEmpty(this.props.client)
                    ? startWindow
                    : client }
                { this.props.shadeMenuStatus === 'activateClient'
                    ? (
                        <ActivateClientModal
                            cancel={() => {
                                this.props.shadeMenuChange(null);
                            }}
                        />
                    )
                    : null }
            </div>
        );
    }
}

export default Client;
