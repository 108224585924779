/**
 * Created by user on 25.05.2016.
 */
import React from 'react';
import PropTypes from 'prop-types';

class CustomLists extends React.Component {
    state = {

    };

    UNSAFE_componentWillMount() {

    }

    UNSAFE_componentWillReceiveProps() {

    }

    render() {
        return (
            <div>
                CustomLists
            </div>
        );
    }
}
CustomLists.contextTypes = {
    router: PropTypes.object
};
export default CustomLists;
