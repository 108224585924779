// Libs
import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import BitArray from 'node-bitarray';
import _ from 'lodash';

// Comps
import Select from 'components/Select';
import Preloader from 'components/Preloader';

// Utils
import { parseWorkTime } from 'utils';
import DatePicker from '../DatePicker';

// возможные паттерны расписания FIVE_DAYS, CUSTOM, TWO_BY_TWO,ONE_BY_THREE, CUSTOM
const FIVE_DAYS = 'fiveDays';
const TWO_BY_TWO = 'twoByTwo';
const ONE_BY_THREE = 'oneByThree';
const CUSTOM = 'custom';
const DISABLED = 'disabled';
const SCHEDULE_MINUTES_STEP = 15;

const DEFAULT_BREAK = { begin: { hour: 13, minute: 0 }, end: { hour: 14, minute: 0 } };

const S_PERIOD = 90;// период на который устанавливается расписание

class Schedule extends React.Component {
    state = {
        pattern: CUSTOM,
        workDates: null,
        offDates: null,
        selectedDates: [],
        mode: null,
        defaultWorkTime: { begin: { hour: 0, minute: 0 }, end: { hour: 24, minute: 0 }, lunchBreak: null },
        companyWorkTime: null,
        unsaved: false
    };

    componentDidMount() {
        // отслеживание клика по закрывашке
        const el = document.getElementById('editPost');
        if (el) {
            el.onclick = function(e) {
                if (e.target.id === 'editPost' && this.state.unsaved &&
                    !window.confirm('Вы уверены, что не хотите сохранить изменения в расписании ' +
                        _.get(this.props.company, 'angaraInfo.resourceTitleGenitive', 'мастера').toLowerCase() + '?')) {
                    e.stopPropagation();
                }
            }.bind(this);
        }
        if (this.props.setProceed) {
            this.props.setProceed(this.saveSchedulesHandler.bind(this));
        }
    }

    UNSAFE_componentWillMount() {
        if (this.props.resource && this.props.resource.id) {
            this.props.fetchCustomSchedules(this.props.resource.id, moment().add(1, 'd').format('YYYY-MM-DD'), moment().add(S_PERIOD + 1, 'd').format('YYYY-MM-DD'));
        }

        let _defaultWorkTime = _.find((_.get(this.props.company, 'fields') || []), { label: 'company_worktime' }, {});

        const companyWorkDays = _.find((_.get(this.props.company, 'fields') || []), { label: 'company_workdays' }, {});

        if (_defaultWorkTime.value) {
            _defaultWorkTime = parseWorkTime(_defaultWorkTime.value);
        } else {
            _defaultWorkTime = { begin: { hour: 0, minute: 0 }, end: { hour: 24, minute: 0 }, lunchBreak: null };
        }

        this.setState({
            defaultWorkTime: _defaultWorkTime,
            companyWorkDays,
            companyWorkTime: _defaultWorkTime
        });
    }

    componentWillUnmount() {
        const el = document.getElementById('root');
        el.onclick = null;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.fetching && !nextProps.fetching && nextProps.schedules && (this.state.workDates === null || this.state.offDates === null)) {
            this.processSchedules(nextProps.schedules);
        }
    }

    componentDidUpdate(prevProps) {
        // коли мы создали новый ресурс, то при получении id сохраняем расписание
        if (_.get(this.props.resource, 'id') !== _.get(prevProps.resource, 'id')) {
            this.proceedSelected();
        }
        /*        if (prevState.unsaved === false && this.state.unsaved === true) {
         this.saveSchedulesHandler();
         } */
    }

    // обрабатываем существующие кастомные расписания
    processSchedules(schedules) {
        const
            _defaultWorkTime = _.get(_.find((_.get(this.props.company, 'fields') || []), { label: 'company_worktime' }, {}), 'value', '111111111111111111111111111111111111111111111111');

        let _workTime = '';

        const restDayPattern = _defaultWorkTime.replace(/1/g, '0');

        const offDates = [];

        const workDates = [];

        // обрабатываем полученные расписания
        _.map(schedules, (schedule) => {
            _workTime = this.processSchedule(_defaultWorkTime, schedule);
            if (_workTime === restDayPattern) {
                offDates.push({ date: moment(schedule.date).startOf('date').format(), schedule: null });
            } else {
                workDates.push({
                    date: moment(schedule.date).startOf('date').format(),
                    schedule: parseWorkTime(_workTime)
                });
            }
        });
        if (!_.isEmpty(schedules)) {
            this.setState({ workDates, offDates });
        }
    }

    processSchedule(schedule, customSchedule) {
        let _chunk;
        let result = '';
        _.map(schedule, (chunk, i) => {
            _chunk = parseInt(chunk) + parseInt(customSchedule.includePattern[i]) - parseInt(customSchedule.excludePattern[i]);
            if (_chunk > 1) {
                _chunk = '1';
            }
            if (_chunk < 0) {
                _chunk = '0';
            }
            result += _chunk;
        });
        return result;
    }

    deProcessSchedule(schedule) {
        let _defaultWorkTime = _.get(_.find((_.get(this.props.company, 'fields') || []), { label: 'company_worktime' }, {}), 'value', '111111111111111111111111111111111111111111111111');

        const chunkSize = 60 * 24 / _defaultWorkTime.length;

        let _chunk;

        let _result = '';

        _.map(_.range(0, _defaultWorkTime.length), (chunk) => {
            if (schedule.begin.hour * 60 + schedule.begin.minute <= chunk * chunkSize &&
                schedule.end.hour * 60 + schedule.end.minute > chunk * chunkSize &&
                (schedule.lunchBreak === null ||
                    (schedule.lunchBreak.end.hour * 60 + schedule.lunchBreak.end.minute <= chunk * chunkSize ||
                        schedule.lunchBreak.begin.hour * 60 + schedule.lunchBreak.begin.minute > chunk * chunkSize
                    )
                )
            ) {
                _chunk = '1';
            } else {
                _chunk = '0';
            }
            _result += _chunk;
        });
        _result = BitArray.fromBinary(_result).toArray().reverse();
        _defaultWorkTime = BitArray.fromBinary(_defaultWorkTime).toArray().reverse();

        return {
            includePattern: BitArray.and(_result, BitArray.not(_defaultWorkTime)).join(''),
            excludePattern: BitArray.and(BitArray.not(_result), _defaultWorkTime).join('')
        };
    }

    proceedSelected(mode = this.state.mode) {
        if (this.state.pattern === CUSTOM) {
            /*            if (this.state.mode === 'clear') {
                 let editedDates = this.state.workDates, otherDates = this.state.offDates;
                 _.map(this.state.selectedDates, date => {
                 if (_.findIndex(editedDates, {date: date}) !== -1) {
                 editedDates.splice(_.findIndex(editedDates, {date: date}), 1);
                 }
                 if (_.findIndex(otherDates, {date: date}) !== -1) {
                 otherDates.splice(_.findIndex(otherDates, {date: date}), 1);
                 }
                 });
                 this.setState({
                 unsaved: true,
                 selectedDates: [],
                 workDates: editedDates,
                 offDates: otherDates
                 });
                 } */
            if (mode === 'offDates' || mode === 'workDates') {
                const editedDates = this.state[mode] || [];

                const otherDates = this.state[mode === 'workDates' ? 'offDates' : 'workDates'] || [];

                _.map(this.state.selectedDates, (date) => {
                    if (_.findIndex(editedDates, { date }) !== -1) {
                        editedDates.splice(_.findIndex(editedDates, { date }), 1);
                    }
                    editedDates.push({ date, schedule: mode === 'workDates' ? this.state.defaultWorkTime : null });
                    if (_.findIndex(otherDates, { date }) !== -1) {
                        otherDates.splice(_.findIndex(otherDates, { date }), 1);
                    }
                });

                this.setState({
                    unsaved: true,
                    selectedDates: [],
                    [mode]: editedDates,
                    [mode === 'workDates' ? 'offDates' : 'workDates']: otherDates
                });
            }
            this.setState({
                unsaved: true
            });
        }
        if (this.state.pattern === DISABLED) {
            const editedDates = this.state.workDates;
            const otherDates = this.state.offDates;
            _.map(this.state.selectedDates, (date) => {
                if (_.findIndex(editedDates, { date }) !== -1) {
                    editedDates.splice(_.findIndex(editedDates, { date }), 1);
                }
                if (_.findIndex(otherDates, { date }) !== -1) {
                    otherDates.splice(_.findIndex(otherDates, { date }), 1);
                }
            });
            this.setState({
                unsaved: true,
                selectedDates: [],
                workDates: editedDates,
                offDates: otherDates
            });
        }
    }

    changeDate(date) {
        date = moment(date).format();
        let workDates = _.clone(this.state.workDates) || [];

        let offDates = _.clone(this.state.offDates) || [];

        let dateDiff;

        let dateTo;

        let selectedDates;

        switch (this.state.pattern) {
        case DISABLED:
            break;
        case CUSTOM:
        default:
            dateTo = moment(date).startOf('date');
            // очищаем существующую опцию по выбранному дню
            workDates = _.filter(workDates, d => d.date !== dateTo.format());
            offDates = _.filter(offDates, d => d.date !== dateTo.format());
            switch (this.state.mode) {
            case 'clear':
                break;
            case 'workDates':
                workDates.push({ date: dateTo.format(), schedule: this.state.defaultWorkTime });
                break;
            case 'offDates':
                offDates.push({ date: dateTo.format(), schedule: null });
                break;
            default:
                selectedDates = _.cloneDeep(this.state.selectedDates);
                if (selectedDates.indexOf(date) === -1) {
                    selectedDates.push(date);
                } else {
                    selectedDates.splice(selectedDates.indexOf(date), 1);
                }
                this.setState({ selectedDates });
                break;
            }
            break;
        case FIVE_DAYS:
            selectedDates = _.cloneDeep(this.state.selectedDates);
            if (selectedDates.indexOf(date) === -1) {
                selectedDates.push(date);
            } else {
                selectedDates.splice(selectedDates.indexOf(date), 1);
            }
            this.setState({ selectedDates });
            break;
        case TWO_BY_TWO:
        case ONE_BY_THREE:
            workDates = [];
            offDates = [];
            dateDiff = Math.abs(moment().add(1, 'd').startOf('day').diff(date, 'days')) % 4 - 4;
            for (let i = 1; i <= S_PERIOD; i++) {
                dateTo = moment().startOf('date').add(i, 'd');
                if (
                    ((Math.abs((i - dateDiff) % 4) === 1 || Math.abs((i - dateDiff) % 4) === 2) && this.state.pattern === TWO_BY_TWO) ||
                        ((Math.abs((i - dateDiff) % 4) === 1) && this.state.pattern === ONE_BY_THREE)
                ) {
                    workDates.push({ date: dateTo.format(), schedule: this.state.defaultWorkTime });
                } else {
                    offDates.push({ date: dateTo.format(), schedule: null });
                }
            }
            break;
        }
        this.setState({
            unsaved: true,
            workDates,
            offDates
        });
    }

    changeMode(value) {
        this.setState({ mode: this.state.mode === value ? null : value });
    }

    triggerPush(dates, date, schedule = null) {
        const key = _.findIndex(dates, { date });

        if (key !== -1) {
            dates.splice(key, 1);
        } else {
            dates.push({ date, schedule });
        }
        return dates;
    }

    saveSchedulesHandler() {
        const chunkSize = (_.get(_.find((_.get(this.props.company, 'fields') || []), { label: 'company_worktime' }, {}), 'value', '111111111111111111111111111111111111111111111111')).length;

        const workDates = this.state.workDates;

        const offDates = this.state.offDates;

        const schedules = [];

        // если есть селектированные дни которые никак не помечены, то отправляем в
        if (this.state.selectedDates && this.state.selectedDates.length) {
            _.map(this.state.selectedDates, (date) => {
                workDates.push({ date, schedule: this.state.defaultWorkTime });
            });
        }

        _.map(workDates, (sch) => {
            schedules.push(_.merge({ date: moment(sch.date).format('YYYY-MM-DD') }, this.deProcessSchedule(sch.schedule)));
        });
        _.map(offDates, (sch) => {
            schedules.push({
                date: moment(sch.date).format('YYYY-MM-DD'),
                includePattern: _.fill(new Array(chunkSize), '0').join(''),
                excludePattern: _.fill(new Array(chunkSize), '1').join('')
            });
        });

        if (!_.isEmpty(this.props.schedules)) {
            this.props.deleteCustomSchedules(this.props.resource.id, _.map(this.props.schedules, sch => sch.id)).then(() => {
                // и сохраняем то что наваяли
                if (!_.isEmpty(schedules)) {
                    this.props.updateCustomSchedules(this.props.resource.id, schedules).then(() => {
                        this.props.fetchCustomSchedules(this.props.resource.id, moment().add(1, 'd').format('YYYY-MM-DD'), moment().add(S_PERIOD + 1, 'd').format('YYYY-MM-DD'));
                    });
                }
            });
        } else if (!_.isEmpty(schedules)) {
            this.props.updateCustomSchedules(this.props.resource.id, schedules).then(() => {
                this.props.fetchCustomSchedules(this.props.resource.id, moment().add(1, 'd').format('YYYY-MM-DD'), moment().add(S_PERIOD + 1, 'd').format('YYYY-MM-DD'));
            });
        }
        this.setState({ unsaved: false, selectedDates: [] });
    }

    clearAll() {
        this.setState({ selectedDates: [] });
    }

    checkValidSchedule(schedule) {
        // проверяем целостность массива
        const _schedule = {
            begin: { hour: _.get(schedule, 'begin.hour', false), minute: _.get(schedule, 'begin.minute', false) },
            end: { hour: _.get(schedule, 'end.hour', false), minute: _.get(schedule, 'end.minute', false) },
            lunchBreak: _.get(schedule, 'lunchBreak', false)
        };
        const { companyWorkTime } = this.state;

        if (_schedule.begin.hour === false ||
            _schedule.begin.minute === false ||
            _schedule.end.hour === false ||
            _schedule.end.minute === false ||
            _schedule.lunchBreak === false) {
            return false;
        }
        // проверяем логичность расписания
        const begin = _schedule.begin.hour + _schedule.begin.minute / 60;

        const end = _schedule.end.hour + _schedule.end.minute / 60;

        const companyBegin = companyWorkTime.begin.hour + companyWorkTime.begin.minute / 60;

        const companyEnd = companyWorkTime.end.hour + companyWorkTime.end.minute / 60;
        if (begin >= end || begin < companyBegin || end > companyEnd) {
            return false;
        }
        if (end > 24) {
            return false;
        }

        if (_schedule.lunchBreak) {
            const breakBegin = _schedule.lunchBreak.begin.hour + _schedule.lunchBreak.begin.minute / 60;

            const breakEnd = _schedule.lunchBreak.end.hour + _schedule.lunchBreak.end.minute / 60;
            if (breakBegin >= breakEnd) {
                return false;
            }
            if (breakBegin <= begin || breakEnd >= end) {
                return false;
            }
        }
        return true;
    }

    updateDefaultWorkTime(defaultWorkTime) {
        const workDates = _.clone(this.state.workDates);
        /*        _.each(workDates, date => {
         date.schedule = defaultWorkTime;
         }); */
        this.setState({ defaultWorkTime, workDates });
    }

    getHours(type) { // begin, end
        const { defaultWorkTime, companyWorkTime } = this.state;
        let from;
        let
            to;

        if (type === 'begin') {
            from = companyWorkTime.begin.hour;
            if (defaultWorkTime.lunchBreak) {
                to = defaultWorkTime.lunchBreak.begin.minute === '00' ? defaultWorkTime.lunchBreak.begin.hour : defaultWorkTime.lunchBreak.begin.hour + 1;
            } else {
                to = defaultWorkTime.end.minute === '00' ? defaultWorkTime.end.hour : defaultWorkTime.end.hour + 1;
            }
        }

        if (type === 'end') {
            if (defaultWorkTime.lunchBreak) {
                from = defaultWorkTime.lunchBreak.end.minute >= 45 ? defaultWorkTime.lunchBreak.end.hour + 1 : defaultWorkTime.lunchBreak.end.hour;
            } else {
                from = companyWorkTime.begin.minute >= 45 ? companyWorkTime.begin.hour + 1 : companyWorkTime.begin.hour;
            }
            to = companyWorkTime.end.hour + 1;
        }

        return (
            _.map(_.range(from, to, 1), h => ({ value: h, label: (h < 10) ? (`0${h}`) : h, type: 'item' }))
        );
    }

    changeHour(item, type) {
        const _workTime = _.cloneDeep(this.state.defaultWorkTime);

        const { companyWorkTime } = this.state;
        _workTime[type].hour = parseInt(item.value);

        if (type === 'begin') {
            if (_workTime.begin.hour === companyWorkTime.begin.hour && _workTime.begin.minute <= companyWorkTime.begin.minute) {
                _workTime.begin.minute = companyWorkTime.begin.minute;
            }
            if (_workTime.lunchBreak) {
                if (_workTime.begin.hour === _workTime.lunchBreak.begin.hour && _workTime.begin.minute >= _workTime.lunchBreak.begin.minute) {
                    _workTime.begin.minute = _workTime.lunchBreak.begin.minute >= SCHEDULE_MINUTES_STEP ? _workTime.lunchBreak.begin.minute - SCHEDULE_MINUTES_STEP : 0;
                }
            } else if (_workTime.begin.hour === companyWorkTime.end.hour && _workTime.begin.minute >= companyWorkTime.end.minute) {
                _workTime.begin.minute = companyWorkTime.end.minute >= SCHEDULE_MINUTES_STEP ? companyWorkTime.end.minute - SCHEDULE_MINUTES_STEP : 0;
            }
        }

        if (type === 'end') {
            if (_workTime.lunchBreak) {
                if (_workTime.end.hour === _workTime.lunchBreak.end.hour && _workTime.end.minute <= _workTime.lunchBreak.end.minute) {
                    _workTime.end.minute = _workTime.lunchBreak.end.minute + SCHEDULE_MINUTES_STEP;
                }
            } else if (_workTime.end.hour === companyWorkTime.begin.hour && _workTime.end.minute <= companyWorkTime.begin.minute) {
                _workTime.end.minute = companyWorkTime.begin.minute + SCHEDULE_MINUTES_STEP;
            }
            if (_workTime.end.hour === companyWorkTime.end.hour && _workTime.end.minute >= companyWorkTime.end.minute) {
                _workTime.end.minute = companyWorkTime.end.minute;
            }
        }

        if (this.checkValidSchedule(_workTime)) {
            this.updateDefaultWorkTime(_workTime);
        }
    }

    getBreakHours(type) { // begin, end
        const { defaultWorkTime } = this.state;
        let from;
        let
            to;

        if (type === 'begin') {
            from = defaultWorkTime.begin.minute === 45 ? defaultWorkTime.begin.hour + 1 : defaultWorkTime.begin.hour;
            if (defaultWorkTime.lunchBreak) {
                to = defaultWorkTime.lunchBreak.end.minute === '00' ? defaultWorkTime.lunchBreak.end.hour : defaultWorkTime.lunchBreak.end.hour + 1;
            }
        }

        if (type === 'end') {
            if (defaultWorkTime.lunchBreak) {
                from = defaultWorkTime.lunchBreak.begin.minute === 45 ? defaultWorkTime.lunchBreak.begin.hour + 1 : defaultWorkTime.lunchBreak.begin.hour;
            }
            to = defaultWorkTime.end.minute === '00' ? defaultWorkTime.end.hour : defaultWorkTime.end.hour + 1;
        }

        return (
            _.map(_.range(from, to, 1), h => ({ value: h, label: (h < 10) ? (`0${h}`) : h, type: 'item' }))
        );
    }

    changeBreakHour(item, type) {
        const _workTime = _.cloneDeep(this.state.defaultWorkTime);
        _workTime.lunchBreak[type].hour = parseInt(item.value);

        if (type === 'begin') {
            if (_workTime.lunchBreak.begin.hour === _workTime.begin.hour && _workTime.lunchBreak.begin.minute <= _workTime.begin.minute) {
                _workTime.lunchBreak.begin.minute = _workTime.begin.minute + SCHEDULE_MINUTES_STEP;
            }
            if (_workTime.lunchBreak.begin.hour === _workTime.lunchBreak.end.hour && _workTime.lunchBreak.begin.minute >= _workTime.lunchBreak.end.minute) {
                _workTime.lunchBreak.begin.minute = _workTime.lunchBreak.end.minute - SCHEDULE_MINUTES_STEP;
            }
        }

        if (type === 'end') {
            if (_workTime.lunchBreak.end.hour === _workTime.lunchBreak.begin.hour && _workTime.lunchBreak.end.minute <= _workTime.lunchBreak.begin.minute) {
                _workTime.lunchBreak.end.minute = _workTime.lunchBreak.begin.minute + SCHEDULE_MINUTES_STEP;
            }
            if (_workTime.lunchBreak.end.hour === _workTime.end.hour && _workTime.lunchBreak.end.minute >= _workTime.end.minute) {
                _workTime.lunchBreak.end.minute = _workTime.end.minute - SCHEDULE_MINUTES_STEP;
            }
        }

        if (this.checkValidSchedule(_workTime)) {
            this.updateDefaultWorkTime(_workTime);
        }
    }

    mergeSchedules(companyWorkDays, companyWorkTime, workDates, offDates) {
        const result = { workDates: [], offDates: [] };
        let dateTo;
        let rDay;
        for (let i = 1; i <= S_PERIOD; i++) {
            dateTo = moment().startOf('date').add(i, 'd');
            rDay = _.find(workDates, { date: dateTo.format() }) || _.find(offDates, { date: dateTo.format() });
            if (rDay) {
                if (rDay.schedule) {
                    result.workDates.push(rDay);
                } else {
                    result.offDates.push(rDay);
                }
            } else if (companyWorkDays.value.indexOf(dateTo.locale('en').format('dddd').toUpperCase()) !== -1) {
                result.workDates.push({ date: dateTo.format(), schedule: companyWorkTime, byCompany: true });
            } else {
                result.offDates.push({ date: dateTo.format(), schedule: null, byCompany: true });
            }
        }
        return result;
    }

    render() {
        const { defaultWorkTime } = this.state;
        const modes = [
            { type: 'item', label: 'назначить рабочими', value: 'workDates' },
            { type: 'item', label: 'назначить выходными', value: 'offDates' },
            { type: 'item', label: 'очистить', value: 'clear' }
        ];

        const minutes = _.map(_.range(0, 60, SCHEDULE_MINUTES_STEP), m => ({
            value: m,
            label: (m < 10) ? (`0${m}`) : m,
            type: 'item'
        }));

        const hoursBegin = this.getHours('begin');

        const hoursEnd = this.getHours('end');

        const breakHoursBegin = this.getBreakHours('begin');

        const breakHoursEnd = this.getBreakHours('end');

        const daysToPicker = this.mergeSchedules(this.state.companyWorkDays, this.state.companyWorkTime, this.state.workDates, this.state.offDates);

        return (
            <div className='schedule'>
                <DatePicker
                    pattern={this.state.pattern}
                    selectedDates={this.state.selectedDates}
                    workDates={daysToPicker.workDates}
                    offDates={daysToPicker.offDates}
                    availableDates={[moment().startOf('date'), moment().startOf('date').add(S_PERIOD + 1, 'day')]}
                    clearAll={this.clearAll.bind(this)}
                    onChange={this.changeDate.bind(this)}
                />
                <div
                    className='schedule__patterns'
                    onClick={(e) => {
                        // если паттерн пятидневный, то сразу применяем, иначе переключаемся
                        const workDates = [];
                        const offDates = [];

                        const pattern = e.target.getAttribute('data-pattern');
                        if (pattern === TWO_BY_TWO || pattern === ONE_BY_THREE) {
                            this.setState({
                                selectedDates: [],
                                workDates,
                                offDates,
                                pattern: e.target.getAttribute('data-pattern')
                            });
                        }
                        if (pattern === FIVE_DAYS) {
                            let dateTo;
                            for (let i = 1; i <= S_PERIOD; i++) {
                                dateTo = moment().startOf('date').add(i, 'd');
                                if (dateTo.day() >= 1 && dateTo.day() <= 5) {
                                    workDates.push({ date: dateTo.format(), schedule: defaultWorkTime });
                                } else {
                                    offDates.push({ date: dateTo.format(), schedule: null });
                                }
                            }
                            this.setState({
                                selectedDates: [],
                                workDates,
                                offDates,
                                pattern: e.target.getAttribute('data-pattern')
                            });
                        }
                        if (pattern === CUSTOM) {
                            this.processSchedules(this.props.schedules);
                            this.setState({
                                unsaved: false,
                                selectedDates: [],
                                pattern: e.target.getAttribute('data-pattern')
                            });
                        }
                        if (pattern === DISABLED && (!this.state.unsaved || (this.state.unsaved && window.confirm('Заданное по шаблону расписание будет утеряно, вы уверены?')))) {
                            this.processSchedules(this.props.schedules);
                            const selectedDates = [];
                            for (let i = 1; i <= S_PERIOD; i++) {
                                selectedDates.push(moment().startOf('date').add(i, 'd').format());
                            }
                            this.setState({
                                unsaved: true,
                                mode: 'offDates',
                                workDates: [],
                                offDates: [],
                                pattern: e.target.getAttribute('data-pattern')
                            });
                        }
                    }}
                >
                    { /* <div data-pattern={FIVE_DAYS} className={cx({ active: this.state.pattern === FIVE_DAYS })}>Пятидневка</div>
                     <div data-pattern={TWO_BY_TWO} className={cx({ active: this.state.pattern === TWO_BY_TWO })}>2
                     через 2</div>
                     <div data-pattern={ONE_BY_THREE}
                     className={cx({ active: this.state.pattern === ONE_BY_THREE })}>1 через 3</div> */ }
                    <div data-pattern={DISABLED} className={cx({ active: this.state.pattern === DISABLED })}>
                        мастер
                        работает
                        как компания
                    </div>
                    <div data-pattern={CUSTOM} className={cx({ active: this.state.pattern === CUSTOM })}>
                        мастер работает по своему графику
                    </div>
                </div>
                { this.state.pattern === CUSTOM
                    ? (
                        <div className='selectMode'>
                            <div className='selectMode__title'>
                                Выбранные дни
                            </div>
                            { _.map(modes, (mode, key) => {
                                return (
                                    <div
                                        className={'selectMode__link ' + mode.value +
                                        (this.state.mode === mode.value ? ' selected' : '')} onClick={() => {
                                            this.proceedSelected(mode.value);
                                            this.changeMode(mode.value);
                                        }} key={key}
                                    >
                                        { mode.label }
                                    </div>
                                );
                            }) }
                        </div>
                    )
                    : null }

                {
                    !this.state.mode || this.state.mode === 'workDates'
                        ? (
                            <div className='daySchedule'>
                                <div className='time'>
                                    Время работы&nbsp;
                                    <Select
                                        name='hBegin'
                                        value={defaultWorkTime.begin.hour}
                                        options={hoursBegin}
                                        type='select'
                                        className='autowidth'
                                        onChange={item => this.changeHour(item, 'begin')}
                                    />
                                    &nbsp;:&nbsp;
                                    <Select
                                        name='mBegin'
                                        value={defaultWorkTime.begin.minute}
                                        options={minutes}
                                        type='select'
                                        className='autowidth'
                                        onChange={(item) => {
                                            const _workTime = _.cloneDeep(defaultWorkTime);
                                            _workTime.begin.minute = parseInt(item.value);
                                            if (this.checkValidSchedule(_workTime)) {
                                                this.updateDefaultWorkTime(_workTime);
                                            }
                                        }}
                                    />
                                    &nbsp;до&nbsp;
                                    <Select
                                        name='hEnd'
                                        value={defaultWorkTime.end.hour}
                                        options={hoursEnd}
                                        type='select'
                                        className='autowidth'
                                        onChange={item => this.changeHour(item, 'end')}
                                    />
                                    &nbsp;:&nbsp;
                                    <Select
                                        name='mEnd'
                                        value={defaultWorkTime.end.minute}
                                        options={minutes}
                                        type='select'
                                        className='autowidth'
                                        onChange={(item) => {
                                            const _workTime = _.cloneDeep(defaultWorkTime);
                                            _workTime.end.minute = parseInt(item.value);
                                            if (this.checkValidSchedule(_workTime)) {
                                                this.updateDefaultWorkTime(_workTime);
                                            }
                                        }}
                                    />
                                </div>
                                <div
                                    className={`break${defaultWorkTime.lunchBreak === null ? ' disabled' : ''}`}
                                >
                                    Перерыв с&nbsp;
                                    <Select
                                        name='hBegin'
                                        value={_.get(defaultWorkTime.lunchBreak, 'begin.hour', 13)}
                                        options={breakHoursBegin}
                                        type='select'
                                        className='autowidth'
                                        disabled={defaultWorkTime.lunchBreak === null}
                                        onChange={item => this.changeBreakHour(item, 'begin')}
                                    />
                                    &nbsp;:&nbsp;
                                    <Select
                                        name='mBegin'
                                        value={_.get(defaultWorkTime.lunchBreak, 'begin.minute', 0)}
                                        options={minutes}
                                        type='select'
                                        className='autowidth'
                                        disabled={defaultWorkTime.lunchBreak === null}
                                        onChange={(item) => {
                                            const _workTime = _.cloneDeep(defaultWorkTime);
                                            _workTime.lunchBreak.begin.minute = parseInt(item.value);
                                            if (this.checkValidSchedule(_workTime)) {
                                                this.updateDefaultWorkTime(_workTime);
                                            }
                                        }}
                                    />

                                    &nbsp;до&nbsp;

                                    <Select
                                        name='hEnd'
                                        value={_.get(defaultWorkTime.lunchBreak, 'end.hour', 14)}
                                        options={breakHoursEnd}
                                        type='select'
                                        className='autowidth'
                                        disabled={defaultWorkTime.lunchBreak === null}
                                        onChange={item => this.changeBreakHour(item, 'end')}
                                    />
                                    &nbsp;:&nbsp;
                                    <Select
                                        name='mEnd'
                                        value={_.get(defaultWorkTime.lunchBreak, 'end.minute', 0)}
                                        options={minutes}
                                        type='select'
                                        className='autowidth'
                                        disabled={defaultWorkTime.lunchBreak === null}
                                        onChange={(item) => {
                                            const _workTime = _.cloneDeep(defaultWorkTime);
                                            _workTime.lunchBreak.end.minute = parseInt(item.value);
                                            if (this.checkValidSchedule(_workTime)) {
                                                this.updateDefaultWorkTime(_workTime);
                                            }
                                        }}
                                    />
                                    <div className='breakTrigger'>
                                        <label>
                                            <input
                                                type='checkbox'
                                                checked={defaultWorkTime.lunchBreak === null}
                                                onChange={() => {
                                                    const _workTime = _.cloneDeep(defaultWorkTime);
                                                    _workTime.lunchBreak = _workTime.lunchBreak === null ? DEFAULT_BREAK : null;
                                                    this.updateDefaultWorkTime(_workTime);
                                                }}
                                            />
                                            без перерыва
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )
                        : null

                }
                { this.props.resource && this.props.resource.id
                    ? (
                        <div className='saveSchedule'>
                            <div
                                className={`saveSchedule__button${
                                    ((this.state.unsaved || this.state.selectedDates.length > 0) && !this.props.fetching) ? ' saveSchedule__button--active' : ''}`}
                                onClick={this.saveSchedulesHandler.bind(this)}

                            >
                                { this.state.pattern === CUSTOM ? 'Назначить и сохранить' : 'Сохранить' }
                            </div>
                        </div>
                    )
                    : null }

                <Preloader trigger={this.props.fetching} fixed />
            </div>
        );
    }
}

export default Schedule;
