import React from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Select';
import Peninput from 'components/Peninput';
import _ from 'lodash';
import { formatDuration } from 'utils';
import 'lib/slim/slim.scss';
import Slim from 'lib/slim/slim.react.jsx';
import Schedule from '../Schedule';

require('../styles_posts.scss');

class EditPost extends React.Component {
    state = {
        editServiceDuration: null,
        editServicePrice: null
    };

    defaultDuration = 30;

    updateTimer = null;

    UNSAFE_componentWillMount() {
        if (!this.props.resource) {
            this.props.fetchResources(this.props.id);
        }
    }

    componentWillUnmount() {
        this.props.toEditResource(null);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.props.resource && nextProps.resources) {
            const resources = _.get(nextProps, 'resources', []);

            const resource = _.find(resources, { id: parseInt(this.props.id) });
            if (resource) {
                this.props.toEditResource(resource);
            }
        }
        if (this.props.resource && this.props.resource.id && nextProps.resource && (JSON.stringify(nextProps.resource) !== JSON.stringify(this.props.resource))) {
            if (this.updateTimer) {
                clearTimeout(this.updateTimer);
            }
            this.updateTimer = setTimeout(() => {
                const data = _.clone(nextProps.resource);
                data.is_online = _.get(this.props.resource, 'isOnline', false);
                data.divisions = _.map(data.divisions, (d) => {
                    if (d.id) {
                        return d.id;
                    }
                    if (parseInt(d) === d) {
                        return d;
                    }
                });
                _.each(data.services, (s) => {
                    if (!s.duration && s.averageDuration) {
                        s.duration = s.averageDuration;
                    }
                    if (!s.duration && !s.averageDuration) {
                        s.duration = this.defaultDuration;
                    }
                });

                this.props.updateResource(data).then(() => {
                    this.props.fetchCompany(this.props.company.id, [
                        'city',
                        'divisions',
                        'resources',
                        'resources.divisions',
                        'resources.schedules',
                        'files',
                        'files.thumbs',
                        'notifications',
                        'catalogues',
                        'services'
                    ]);
                });
            }, 1000);
        }
    }

    changeResourceName(e) {
        e.preventDefault();
        const value = e.target.value;
        this.props.changeEditedResource('name', value);
    }

    changeServices(service) {
        const services = _.cloneDeep(_.get(this.props.resource, 'services', []));
        const alreadyIn = _.find(services, { id: service.value.id }) || false;
        if (alreadyIn === false) {
            services.push(service.value);
            this.props.changeEditedResource('services', services);
        }
    }

    removeService(removeId, e) {
        e.preventDefault();
        let services = _.get(this.props.resource, 'services', []);
        services = _.filter(services, service => service.id !== removeId);
        this.props.changeEditedResource('services', services);
    }

    changeDuration(id, duration, e) {
        if (e) {
            e.preventDefault();
        }
        const services = _.get(this.props.resource, 'services', []);

        const serviceKey = _.findKey(services, { id });
        services[serviceKey].duration = duration;
    }

    changePrice(id, price, e) {
        if (e) {
            e.preventDefault();
        }
        const services = _.get(this.props.resource, 'services', []);

        const serviceKey = _.findKey(services, { id });
        services[serviceKey].price = price;
    }

    editResource(e) {
        if (e) {
            e.preventDefault();
        }
        if (!_.isEmpty(this.props.resource.name)) {
            const data = _.clone(this.props.resource);
            data.is_online = _.get(this.props.resource, 'isOnline', false);
            data.divisions = _.map(data.divisions, (d) => {
                if (d.id) {
                    return d.id;
                }
                if (parseInt(d) === d) {
                    return d;
                }
            });
            _.each(data.services, (s) => {
                if (!s.duration && s.averageDuration) {
                    s.duration = s.averageDuration;
                }
                if (!s.duration && !s.averageDuration) {
                    s.duration = this.defaultDuration;
                }
            });
            this.props.updateResource(data).then(() => {
                // TODO сделать обновление данных без загрузки компании
                this.props.fetchCompany(this.props.company.id, [
                    'city',
                    'divisions',
                    'resources',
                    'resources.divisions',
                    'resources.schedules',
                    'files',
                    'files.thumbs',
                    'notifications',
                    'catalogues',
                    'services'
                ]);
            });
        }
    }

    deleteResource(e) {
        e.preventDefault();
        if (window.confirm('Вы уверены в удалении данного ' + _.get(this.props.company, 'angaraInfo.resourceTitleGenitive', 'мастера') + '?')) {
            this.props.deleteResource(this.props.resource.id);
            this.props.history.push('/settings/posts');
        }
    }

    switchResourceIsOnline(value) {
        this.props.changeEditedResource('isOnline', value);
    }

    imageWillSave(data, ready) {
        ready(data);
    }

    imageDidUpload(error, data, response) {
        if (_.get(response, 'id')) {
            this.props.addResourceAvatar(response);
            this.props.addEditResourceAvatar(response);
        }
        if (error) {
            console.log(error);
        }
    }

    deleteResourceAvatar(id) {
        if (window.confirm('Удалить аватар ' + _.get(this.props.company, 'angaraInfo.resourceTitleGenitive', 'мастера').toLowerCase() + '?')) {
            this.props.deleteResourceAvatar(id).then((res) => {
                if (res.payload) {
                    this.props.removeEditResourceAvatar();
                }
            });
        }
    }

    render() {
        if (!this.props.resource || !this.props.company || !this.props.company.services) {
            return null;
        }
        const company = this.props.company;

        const divisions = _.get(company, 'divisions', []);

        const resource = this.props.resource;

        const selDivisions = [];
        _.map(divisions, (division) => {
            if (!_.find(resource.divisions, ['id', division.id])) {
                selDivisions.push({
                    label: division.title, title: division.title, value: division.id, type: 'item', id: division.id
                });
            }
        });
        const services = _.map(_.get(resource, 'services', []), (rService) => {
            const service = _.find(company.services.services, { id: rService.id });

            if (_.isUndefined(service)) {
                return null;
            }
            return (
                <div className='service' key={service.id}>
                    <div className='service__title'>{ service.serviceTemplate.name }</div>
                    <div className='service__duration'>
                        { this.state.editServiceDuration === service.id
                            ? (
                                <div>
                                    <input
                                        type='number'
                                        defaultValue={rService.duration ? rService.duration : service.averageDuration}
                                        onChange={(e) => {
                                            this.changeDuration(service.id, parseInt(e.target.value));
                                        }}
                                    />
                                    <div
                                        className='trigger'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.editResource();
                                            this.setState({ editServiceDuration: null });
                                        }}
                                    />
                                </div>
                            )
                            : (
                                <div onClick={() => {
                                    this.setState({ editServiceDuration: service.id });
                                }}
                                >
                                    { formatDuration(rService.duration ? rService.duration : service.averageDuration) }
                                </div>
                            ) }
                    </div>
                    {
                        this.state.editServicePrice === service.id
                            ? (
                                <div className='service__price'>
                                    <input
                                        type='number'
                                        defaultValue={_.get(rService, 'price', service.minPrice)}
                                        onChange={(e) => {
                                            this.changePrice(service.id, parseInt(e.target.value));
                                        }}
                                    />
                                    <div
                                        className='trigger'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.editResource();
                                            this.setState({ editServicePrice: null });
                                        }}
                                    />
                                </div>
                            )
                            : (
                                <div
                                    className='service__price'
                                    onClick={() => {
                                        this.setState({ editServicePrice: service.id });
                                    }}
                                >
                                    { _.get(rService, 'price', service.minPrice) }
                                    { ' ' }
                                    руб.
                                </div>
                            )
                    }

                    <div className='service__remove'>
                        <div onClick={this.removeService.bind(this, service.id)} />
                    </div>
                </div>
            );
        });

        const selServices = [];
        _.map(company.services.services, (service) => {
            if (!_.some(resource.services, ['id', service.id])) {
                selServices.push({
                    label: service.serviceTemplate.name,
                    value: { id: service.id, duration: service.averageDuration },
                    type: 'item'
                });
            }
        });

        const isOnline = this.props.resource.isOnline;

        let avatarUpload;
        if (resource.imageUrl) {
            avatarUpload = (
                <div className='slim slim-no-bg'>
                    <div className='avatar'>
                        <img src={resource.imageUrl} alt='' />
                    </div>
                    <div className='slim-btn-group'>
                        <button
                            className='slim-btn slim-btn-remove'
                            title='Удалить'
                            type='button'
                            onClick={this.deleteResourceAvatar.bind(this, resource.angara_id)}
                        >
                            Удалить
                        </button>
                    </div>
                </div>
            );
        } else {
            avatarUpload = (
                <Slim
                    ref='avatarUpload'
                    ratio='1:1'
                    service={`${process.env.REACT_APP_API_PATH}/v1/partner/resources/${resource.angara_id}/avatar`}
                    label='Добавить<br/>изображение'
                    buttonEditLabel='Редактировать'
                    buttonRemoveLabel='Удалить'
                    buttonDownloadLabel='Скачать'
                    buttonUploadLabel='Загрузить'
                    buttonCancelLabel='Отмена'
                    buttonConfirmLabel='Ок'
                    maxFileSize='6.5'
                    statusFileSize='Максимально допустимый размер: $0 Мб'
                    minSize={{ width: 10, height: 10 }}
                    statusImageTooSmall='Минимально допустимый размер: $0 пикселей'
                    statusNoSupport='Не поддерживается браузером'
                    statusUnknownResponse='Ошибка загрузки'
                    statusUploadSuccess='Ок'
                    edit={false}
                    instantEdit
                    push
                    willSave={this.imageWillSave.bind(this)}
                    didUpload={this.imageDidUpload.bind(this)}
                >
                    <input type='file' name='payload' />
                </Slim>
            );
        }
        return (
            <div
                className='createPost'
                id='editPost'
                onClick={() => {
                    this.props.history.push('/settings/posts');
                }}
            >
                <div
                    className='createPost__content'
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <div className='createPost__header'>
                        <div className='col-xs-3'>
                            { avatarUpload }
                        </div>
                        <div className='col-xs-9'>
                            <h1>Настройки мастера</h1>
                            <div className='peninput__label'>Имя мастера</div>
                            <Peninput
                                name='name'
                                defaultValue={'Именование ' + _.get(this.props.company, 'angaraInfo.resourceTitleGenitive', 'мастера').toLowerCase()}
                                value={_.get(this.props.resource, 'name', '')}
                                changeHandler={this.changeResourceName.bind(this)}
                            />
                            <div className='trigger'>
                                Онлайн запись
                                <div
                                    className={`link ${isOnline ? 'online' : ''}`}
                                    onClick={this.switchResourceIsOnline.bind(this, !isOnline)}
                                >
                                    { isOnline ? 'Включена' : 'Выключена' }
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='body'>
                        <div className='schedule'>
                            <div className='createPost__infoBlock'>
                                <div className='createPost__infoBlock__logo' />
                                <div className='createPost__infoBlock__info'>
                                    <h2>Режим
                                        работы { _.get(this.props.company, 'angaraInfo.resourceTitleGenitive', 'мастера').toLowerCase() }
                                    </h2>
                                    { company.integrated_with_1c
                                        ? (
                                            <div className='info'>
                                                Редактирование расписания отключено.
                                                <br />
                                                Режим работы ресурсов компании интегрируется из 1С.
                                            </div>
                                        )
                                        : (
                                            <div>
                                                <div className='info'>
                                                    <div className='info__text'>
                                                        { _.get(this.props.company, 'angaraInfo.resourceTitle', 'Мастер') } будет
                                                        работать согласно указанному вами графику либо по общему
                                                        расписанию
                                                        компании.
                                                    </div>
                                                    <div className='info__legend'>
                                                        <div className='info__legend__company'>
                                                            <div className='icon' />
                                                            <div className='text'>
                                                                мастер работает<br />как компания
                                                            </div>
                                                        </div>
                                                        <div className='info__legend__custom'>
                                                            <div className='icon' />
                                                            <div className='text'>
                                                                мастер работает<br />по своему графику
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <Schedule
                                                    resource={this.props.resource} setProceed={(proceed) => {
                                                        this.saveSchedule = proceed;
                                                    }}
                                                />

                                            </div>
                                        ) }
                                </div>
                            </div>
                        </div>
                        <div className='services'>
                            <div className='createPost__infoBlock'>
                                <div className='createPost__infoBlock__logo' />
                                <div className='createPost__infoBlock__info'>
                                    <h2>Привязка услуг</h2>
                                    <div className='text'>
                                        Услуга будет доступна
                                        у { _.get(this.props.company, 'angaraInfo.resourceTitleGenitive', 'мастера').toLowerCase() }&nbsp;
                                        к которому привязана. Возможна привязка услуги к
                                        нескольким мастерам (ресурсам). Непривязанная услуга будет недоступна для онлайн
                                        записи.
                                    </div>
                                </div>
                                <div className='list'>
                                    { services }
                                </div>
                                { selServices.length
                                    ? (
                                        <div className='selectService'>
                                            <Select
                                                name='services'
                                                placeholder='Прикрепить услугу'
                                                value={null}
                                                options={selServices}
                                                type='select'
                                                onChange={this.changeServices.bind(this)}
                                                search
                                            />
                                        </div>
                                    )
                                    : null }
                            </div>
                        </div>
                        { company.integrated_with_1c
                            ? null
                            : (
                                <div className='createPost-footer'>
                                    <div
                                        className='createPost-footer__delete'
                                        onClick={this.deleteResource.bind(this)}
                                    >
                                        Удалить { _.get(this.props.company, 'angaraInfo.resourceTitleGenitive', 'мастера').toLowerCase() }
                                    </div>
                                    <div
                                        className='createPost-footer__save'
                                        onClick={() => {
                                            this.saveSchedule();
                                        }}
                                    >
                                        Сохранить изменения
                                    </div>
                                </div>
                            ) }
                    </div>
                </div>
            </div>
        );
    }
}

EditPost.contextTypes = {
    router: PropTypes.object
};

export default EditPost;
