import { api, httpBuildQuery } from './../../../../utils';
import download from 'downloadjs';

const EXPORT_EXPIRED_TIMEOUT = 300000;
const EXPORT_DOWNLOAD_TIMEOUT = 2000;
// ------------------------------------
// Constants
// ------------------------------------
export const REQUEST_STATISTICS = 'requestStatistics';
export const RECEIVE_STATISTICS = 'receiveStatistics';

export const CHANGE_EXPORTING_SMS = 'changeExportingSms';
export const CHANGE_EXPORT_SMS_ID = 'changeExportSmsId';

export const SET_SMS_EXPORT_ERROR = 'setSmsExportError';
export const RESET_SMS_EXPORT_ERROR = 'resetSmsExportError';

// ------------------------------------
// Actions
// ------------------------------------

export const statisticByResources = (data) => {
    return function(dispatch) {
        dispatch(requestStatistics());
        return api('/v1/partner/statistic/resources?' + httpBuildQuery(data), {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receiveStatistics('byResources', res)));
    };
};

export const statisticByServices = (data) => {
    return function(dispatch) {
        dispatch(requestStatistics());
        return api('/v1/partner/statistic/services?' + httpBuildQuery(data), {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receiveStatistics('byServices', res)));
    };
};

export const statisticByOkk = (data) => {
    return function(dispatch) {
        dispatch(requestStatistics());
        return api('/v1/partner/statistic/okk?' + httpBuildQuery(data), {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receiveStatistics('byOkk', res)));
    };
};

export const statisticByOrders = (data) => {
    return function(dispatch) {
        dispatch(requestStatistics());
        return api('/v1/partner/statistic/orders?' + httpBuildQuery(data), {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receiveStatistics('byOrders', res)));
    };
};

function requestStatistics() {
    return {
        type: REQUEST_STATISTICS
    };
}

function receiveStatistics(byType, res) {
    return {
        type: RECEIVE_STATISTICS,
        byType: byType,
        payload: res
    };
}

export const exportSms = (data) => {
    return function(dispatch, getState) {
        dispatch(changeExportingSms(true));
        return api('/v3/partner/statistic/sms/generate?' + httpBuildQuery(data), {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res.unid) {
                    return getExportSms(res.unid, dispatch);
                } else if (getState().hangar.ajaxError) {
                    dispatch(setSmsExportError(getState().hangar.ajaxError.response.message));
                }
            });
    };
};

function getExportSms(unid, dispatch) {
    return fetch(process.env.REACT_APP_API_PATH + '/v3/partner/statistic/sms/' + unid,
        {
            method: 'get',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Api-Token': localStorage.getItem('token')
            }
        }
    ).then(res => {
        if (res.status === 200) {
            if (dispatch) {
                dispatch(changeExportSmsId(unid));
                setTimeout(() => {
                    dispatch(changeExportingSms(false));
                    dispatch(changeExportSmsId(null));
                }, EXPORT_EXPIRED_TIMEOUT);
            }
        } else if (res.status === 404) {
            setTimeout(() => {
                getExportSms(unid, dispatch);
            }, EXPORT_DOWNLOAD_TIMEOUT);
        } else {
            if (dispatch) {
                dispatch(changeExportingSms(false));
                dispatch(changeExportSmsId(null));
            }
        }
    });
}

export const downloadExportSms = (unid) => {
    return function(dispatch) {
        return fetch(process.env.REACT_APP_API_PATH + '/v3/partner/statistic/sms/' + unid,
            {
                method: 'get',
                mode: 'cors',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Api-Token': localStorage.getItem('token')
                }
            }
        ).then(res => {
            if (res.status === 200) {
                dispatch(changeExportingSms(false));
                dispatch(changeExportSmsId(null));
                return res.blob();
            }
        }).then(blob => {
            if (blob) {
                download(blob, 'Отчет по sms.xlsx');
            }
        });
    };
};

function changeExportingSms(status) {
    return {
        type: CHANGE_EXPORTING_SMS,
        payload: status
    };
}

function changeExportSmsId(res) {
    return {
        type: CHANGE_EXPORT_SMS_ID,
        payload: res
    };
}

function setSmsExportError(res) {
    return {
        type: SET_SMS_EXPORT_ERROR,
        payload: res
    };
}

export const resetSmsExportError = () => {
    return {
        type: RESET_SMS_EXPORT_ERROR
    };
};

export const actions = {
    statisticByResources,
    statisticByServices,
    statisticByOkk,
    statisticByOrders,
    exportSms,
    downloadExportSms,
    resetSmsExportError
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [REQUEST_STATISTICS]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_STATISTICS]: (state, action) => {
        const statistics = Object.assign({}, state.statistic);
        statistics[action.byType] = action.payload;

        return ({ ...state, statistics: statistics, fetching: false });
    },
    [CHANGE_EXPORTING_SMS]: (state, action) => {
        return ({ ...state, exportingSms: action.payload });
    },
    [CHANGE_EXPORT_SMS_ID]: (state, action) => {
        return ({ ...state, exportSmsId: action.payload });
    },
    [SET_SMS_EXPORT_ERROR]: (state, action) => {
        return ({ ...state, smsError: action.payload, exportingSms: false });
    },
    [RESET_SMS_EXPORT_ERROR]: (state) => {
        return ({ ...state, smsError: null });
    }
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
    fetching: false,
    statistics: { byResources: [], byServices: [], byOkk: [], byOrders: [] },
    exportingSms: false,
    exportSmsId: null,
    smsError: null
};

export default function reducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
