/**
 * Created by user on 17.05.2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import './modalWindow.styles.scss';

export const ModalWindow = (props) => (
    <div className='modalWindow'>
        <div className='modalWindow__shadowScreen'>
            <div className='modalWindow__shadowScreen__window'>{ props.children }</div>
        </div>
    </div>
);

ModalWindow.propTypes = {
    children: PropTypes.object.isRequired
};

export default ModalWindow;
