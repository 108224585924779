import React from 'react';
import classes from '../OrderFooter.module.scss';
import moment from 'moment';
import _ from 'lodash';
import { Link } from 'react-router-dom';
// Comps
import Chunk from '../Chunk';

class Resource extends React.Component {
    shouldComponentUpdate(nextProps) {
        if (_.isEqual(nextProps, this.props)) {
            return false;
        }
        if ((_.get(nextProps.toOrder, 'mode') === 'takeOff' || _.get(this.props.toOrder, 'mode') === 'takeOff')) {
            if (_.get(nextProps.toOrder, 'takeOffResourcesIds', []).length === 0) {
                return true;
            } else if (_.includes(_.get(nextProps.toOrder, 'takeOffResourcesIds'), this.props.id) || _.includes(_.get(this.props.toOrder, 'takeOffResourcesIds'), this.props.id)) {
                return true;
            }
        }
        const resource = _.find(this.props.company.resources, { id: this.props.id });
        if (!_.isEqual(nextProps.toOrder, this.props.toOrder) && nextProps.dayTime === this.props.dayTime) {
            // если отключаем модальное окно
            if (resource.angara_id !== _.get(this.props.toOrder, 'resId') && resource.angara_id !== _.get(nextProps.toOrder, 'resId')) {
                return false;
            }
        }
        if (_.get(nextProps.toOrder, 'status', '') === 'finish' && resource.angara_id !== _.get(nextProps.toOrder, 'resId')) {
            return false;
        }

        return true;
    }

    getResourceChunks(chunks, currentDay, division, resource, orders, reserves, toOrder) {
        const scheduleHours = [];
        let chunkDate, chunkDateDay;
        const evenOddOrder = { counter: 0, _orderId: null }; // счетчик для определения четного нечетного заказа
        const evenOddReserve = { counter: 0, _reserveId: null }; // счетчик для определения четного нечетного заказа

        _.each(chunks, i => {
            chunkDate = moment(currentDay).add(i / 2, 'hours');// датавремя для каждого чанка
            chunkDateDay = chunkDate.format('DD MMMM');// дата чанка
            // определяем четность, нечетность заказа, брони
            if (
                (orders[resource.angara_id] && orders[resource.angara_id].chunks[i]) ||
                (reserves[resource.angara_id] && reserves[resource.angara_id].chunks[i])

            ) {
                // заказ или бронь
                // заказ
                if (orders[resource.angara_id] && orders[resource.angara_id].chunks[i]) {
                    // здесь есть заказ
                    if (evenOddOrder._orderId !== orders[resource.angara_id].chunks[i]) {
                        // если заказ отличный от предыдущего, то увеличиваем счетчик
                        // и если перед этим был свободный промежуто то четность не меняем
                        if (evenOddOrder._orderId) {
                            evenOddOrder.counter = evenOddOrder.counter + 1;
                        }
                        evenOddOrder._orderId = orders[resource.angara_id].chunks[i];
                    }
                }
                // бронь
                if (reserves[resource.angara_id] && reserves[resource.angara_id].chunks[i]) {
                    // здесь есть заказ
                    if (evenOddReserve._reserveId !== reserves[resource.angara_id].chunks[i]) {
                        // если бронь отличная от предыдущего, то увеличиваем счетчик
                        // и если перед этим был свободный промежуто то четность не меняем
                        if (evenOddReserve._reserveId) {
                            evenOddReserve.counter = evenOddReserve.counter + 1;
                        }
                        evenOddReserve._reserveId = reserves[resource.angara_id].chunks[i];
                    }
                }
            } else {
                evenOddOrder._orderId = null;
                evenOddReserve._orderId = null;
            }

            scheduleHours.push(
                <Chunk
                    key={i}
                    division={division}
                    i={i}
                    toOrder={toOrder}
                    services={this.props.services}
                    company={this.props.company}
                    schedules={this.props.schedules}
                    client={this.props.client}
                    schedule={_.get(_.find(this.props.schedules, { id: resource.id }), currentDay.format('YYYY-MM-DD'), '')}
                    resource={resource}
                    orders={orders}
                    reserves={reserves}
                    evenOddOrder={_.clone(evenOddOrder)}
                    evenOddReserve={_.clone(evenOddReserve)}
                    currentDay={currentDay}
                    chunkDateDay={chunkDateDay}
                    chunkDate={chunkDate}
                    setOrderDay={this.props.setOrderDay.bind(this)}
                    takeOffChunk={this.props.takeOffChunk}
                    chooseToOrder={this.props.chooseToOrder.bind(this)}
                />
            );
        });
        return scheduleHours;
    }

    render() {
        const company = this.props.company;

        const division = this.props.division;

        const resource = _.find(company.resources, { id: this.props.id });

        const dayTime = this.props.dayTime;

        const orders = this.props.orders;

        const tomorrowOrders = this.props.tomorrowOrders;

        const reserves = this.props.reserves;

        const tomorrowReserves = this.props.tomorrowReserves;

        let scheduleHours;
        const currentDay = this.props.currentDay ? this.props.currentDay : moment().startOf('date');// день на который грузится расписание

        // если есть ресурс таковой...
        if (!resource) {
            return null;
        }

        let { toOrder } = this.props;

        if (toOrder && _.includes(['viewReserve', 'viewOrder', 'onOrder'], toOrder.mode) && toOrder.resId !== resource.angara_id) {
            toOrder = null;
        }

        // генерим чанки
        if (dayTime === 'night') {
            // чанки для ночного времени и части утра 40-47, 0-17
            scheduleHours = _.concat(
                this.getResourceChunks(_.range(40, 48), currentDay, division, resource, orders, reserves, toOrder),
                this.getResourceChunks(_.range(0, 18), moment(currentDay).add(1, 'd'), division, resource, tomorrowOrders, tomorrowReserves, toOrder)
            );
        } else {
            // начало и конец рабочего дня согласно дизайна с 8 и до 8, чански с 16 по 42
            scheduleHours = this.getResourceChunks(_.range(16, 42), currentDay, division, resource, orders, reserves, toOrder);
        }

        // выводим ресурс
        const activeClass = (this.props.toOrder && this.props.toOrder.resId === resource.angara_id) ? classes.active : '';
        return (
            <div className={classes.resource + ' ' + activeClass} key={resource.id} id={'division' + division.id + 'resource' + resource.angara_id}>
                <div className={classes.title}>
                    <Link className={classes.name} to={'/settings/posts/' + resource.id} title={resource.descr}>{ resource.name }</Link>
                    <span className={classes.description} title={resource.descr}>{ resource.descr }&nbsp;</span>
                    <div
                        className={classes.tooltip + (resource.name.length < 18 ? ' ' + classes.hidden : '')}
                    >
                        { resource.name }
                    </div>
                </div>
                <div
                    className={classes.chunks +
                    (this.props.fetching && this.props.fetching.schedules[resource.id] ? (' ' + classes.loading) : '')}
                >
                    { scheduleHours }
                </div>
                <div className={classes.line + ' ' + classes.first} />
                <div className={classes.line + ' ' + classes.second} />
                <div className={classes.line + ' ' + classes.third} />
                <div className={classes.line + ' ' + classes.fourth} />
            </div>
        );
    }
}

export default Resource;
