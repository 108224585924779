/**
 * Created by user on 19.07.16.
 */
import React from 'react';
import classes from './MainInfo.module.scss';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import FieldInput from 'components/FieldInput';
import moment from 'moment';
import './../../../../../../../lib/slim/slim.scss';
import Slim from './../../../../../../../lib/slim/slim.react.jsx';
import { declOfNum } from 'utils';

import Preloader from 'components/Preloader';

// require('react-datepicker/dist/react-datepicker.css');

class MainInfo extends React.Component {
    componentDidMount() {
        if (this.props.client.avatarAdded) {
            this.props.fetchClientAvatar(this.props.client.id);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const client = nextProps.client;

        const oldClient = this.props.client;

        const avatarId = _.find(_.get(client, 'client_files', []), { type: 'avatar' });

        const oldAvatarId = _.find(_.get(oldClient, 'client_files', []), { type: 'avatar' });

        if ((client.id !== oldClient.id || !oldAvatarId) && avatarId) {
            this.props.fetchClientAvatar(client.id);
        }
    }

    renderTrack({ style, ...props }) {
        const trackStyle = {};
        return (
            <div
                className={classes.scrollTrack}
                style={{ ...style, ...trackStyle }}
                {...props}
            />
        );
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {};
        return (
            <div
                className={classes.scrollThumb}
                style={{ ...style, ...thumbStyle }}
                {...props}
            />
        );
    }

    changeBirth(date) {
        const birthday = _.find(this.props.client.fields, ['label', 'birthday']);
        this.props.changeSelectField(moment(date).format('YYYY-MM-DD'), birthday, this.props.client.id);
    }

    imageWillSave(data, ready) {
        this.showLoader();
        ready(data);
    }

    imageDidUpload(error, data, response) {
        this.hideLoader();
        this.props.addClientAvatar(_.get(response, 'avatar.id', null));
        if (error) {
            console.log(error);
        }
    }

    deleteImage(e) {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }
        if (window.confirm('Удалить аватар клиента?')) {
            this.props.deleteClientAvatar(this.props.client.id);
        } else {
            return false;
        }
    }

    deleteImageSlim(data, remove) {
        if (window.confirm('Удалить аватар клиента?')) {
            this.props.deleteClientAvatar(this.props.client.id);
            remove();
        } else {
            return false;
        }
    }

    showLoader() {

    }

    hideLoader() {

    }

    formatCreated(difference) {
        const years = parseInt(difference / 12);

        const months = (difference - years * 12);

        if (years && months) {
            return years + 'г. и ' + months + ' мес.';
        } else if (years) {
            return years + 'г.';
        } else if (months) {
            return months + ' мес.';
        } else if (difference === 0) {
            return 'меньше 1 месяца';
        } else {
            return 'никогда';
        }
    }

    render() {
        const client = this.props.client || {};

        const birthday = _.find(this.props.client.fields, ['label', 'birthday']) || {};

        const visits = [];

        const avatarId = _.get(_.find(_.get(client, 'client_files', []), { type: 'avatar' }) || {}, 'file_id', null);

        const avatar = this.props.avatar || {};

        const created = moment().diff(moment(_.get(this.props.client, 'companies[0].pivot.created_at', null)), 'months');

        const visitsCount = this.props.client.executedOrdersCount + ' ' + declOfNum(this.props.client.executedOrdersCount, ['раз', 'раза', 'раз']);
        if (this.props.client.notifications) {
            _.map(this.props.client.notifications, notification => {
                if (moment(notification.next_notified_at).isSameOrAfter(moment().startOf('day'))) {
                    visits.push(moment(notification.next_notified_at));
                }
            });
        }

        // возня с аватаром
        let avatarUpload;
        if (client.id && avatarId && avatar.id === avatarId) {
            avatarUpload =
                (
                    <div className='slim slim-no-bg'>
                        <div className='avatar'>
                            <img alt={this.props.client.name} src={_.get(avatar, 'thumbnails.avatar', '')} />
                        </div>
                        <div className='slim-btn-group'>
                            <button
                                className='slim-btn slim-btn-remove'
                                title='Удалить'
                                type='button'
                                onClick={this.deleteImage.bind(this)}
                            >
                                Удалить
                            </button>
                        </div>
                    </div>
                );
        } else {
            if (client.id) {
                avatarUpload =
                    (
                        <Slim
                            ref='avatarUpload'
                            clientId={this.props.client.id}
                            ratio='20:29'
                            service={process.env.REACT_APP_API_PATH + '/v3/partner/clients/' + client.id + '/avatar'}
                            label='Добавить изображение'
                            buttonEditLabel='Редактировать'
                            buttonRemoveLabel='Удалить'
                            buttonDownloadLabel='Скачать'
                            buttonUploadLabel='Загрузить'
                            buttonCancelLabel='Отмена'
                            buttonConfirmLabel='Ок'
                            maxFileSize='6.5'
                            statusFileSize='Максимально допустимый размер: $0 Мб'
                            minSize={{ width: 10, height: 10 }}
                            statusImageTooSmall='Минимально допустимый размер: $0 пикселей'
                            statusNoSupport='Не поддерживается браузером'
                            statusUnknownResponse='Ошибка загрузки'
                            statusUploadSuccess='Ок'
                            push
                            willRemove={this.deleteImageSlim.bind(this)}
                            willSave={this.imageWillSave.bind(this)}
                            didUpload={this.imageDidUpload.bind(this)}
                        >
                            <input type='file' name='avatar' />
                        </Slim>
                    );
            }
        }

        const isOrtusClient = this.props.client.user ? 'Зарегистрирован в системе Ortus' : 'Не зарегистрирован в системе Ortus';

        const lastActivity = this.props.client.lastActivity
            ? (
                <div className={classes.lastActivity}>
                    <div className={classes.low + ' ' + classes.grey}>Последняя активность в приложении:</div>
                    <div className={classes.high + ' ' + classes.green}>{ moment(this.props.client.lastActivity).format('llll') }</div>
                    <div className={classes.green}>{ moment(this.props.client.lastActivity).fromNow() }</div>
                </div>
            )
            : null;
        const sexField = _.find(_.get(this.props.client, 'fields', []), { label: 'sex' });

        const emailField = _.find(_.get(this.props.changedClient || this.props.client, 'fields', []), { label: 'email' });
        return (
            <div className={classes.body}>
                <div className={classes.clientData}>
                    <div className={classes.photoBlock}>
                        <div className={classes.photo}>
                            { avatarUpload }
                            <Preloader trigger={this.props.avatar.fetching} />
                        </div>
                    </div>
                    <div className={classes.dataBlock}>
                        <div className={classes.message_name}>
                            <div className={classes.label + ' ' + classes.low + ' ' + classes.grey}>Как обращаться:</div>
                            { this.props.editClientChange === 'message_name'
                                ? (
                                    <div>
                                        <input
                                            name='message_name'
                                            autoFocus
                                            value={this.props.changedClient.message_name}
                                            onChange={this.props.changeClient}
                                            onClick={e => e.stopPropagation()}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') {
                                                    return this.props.updateClient(this.props.client.id);
                                                }
                                            }}
                                        />
                                        <div className={classes.editOk} onClick={this.props.updateClient.bind(this, client.id)} />
                                    </div>
                                )
                                : (
                                    <div className={classes.pointer} onClick={this.props.editClient.bind(this, 'message_name')}>
                                        { client.message_name
                                            ? (
                                                <div className={classes.text + ' ' + classes.medium + ' ' + classes.orange}>
                                                    { client.message_name }
                                                </div>
                                            )
                                            : (
                                                <div className={classes.inlineBlock + ' ' + classes.medium + ' ' + classes.orange}>
                                                    Ввести
                                                </div>
                                            ) }
                                        <div className={classes.edit} />
                                    </div>
                                ) }
                        </div>
                        <FieldInput
                            field={sexField}
                            className='inline orange'
                            onChange={(id) => {
                                this.props.changeSelectField(id, sexField, this.props.client.id);
                            }}
                        />

                        <div className={classes.age}>
                            <div>
                                <div className={classes.grey}>
                                    Дата рождения (возраст):
                                </div>
                                <div
                                    className={classes.orange + ' ' + classes.high + ' ' + classes.inlineBlock + ' ' + classes.cursorPointer}
                                    onClick={this.props.editClient.bind(this, 'clientBirthday')}
                                >
                                    { birthday.value ? moment(birthday.value).format('D MMMM') : 'не выбран' } ({ birthday.value ? moment().diff(birthday.value, 'years') : '...' })
                                </div>
                                { this.props.editClientChange === 'clientBirthday'
                                    ? (
                                        <div
                                            id='datapicker'
                                            className={classes.datePicker}
                                            onClick={e => { e.stopPropagation(); }}
                                        >
                                            <div className='mainInfo'>
                                                <DatePicker
                                                    dateFormatCalendar='MMMM'
                                                    locale='ru'
                                                    inline
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={15}
                                                    maxDate={new Date()}
                                                    selected={birthday.value ? new Date(moment(birthday.value)) : new Date(moment().add(-30, 'y'))}
                                                    className='schdulePicker'
                                                    onChange={this.changeBirth.bind(this)}
                                                />
                                            </div>
                                        </div>
                                    )
                                    : null }
                            </div>
                        </div>
                        <div className={classes.block}>
                            <div className={classes.low + ' ' + classes.grey + ' ' + classes.inlineBlock}>В компании</div>
                            <div className={classes.medium + ' ' + classes.orange + ' ' + classes.inlineBlock}>
                                &nbsp;{ this.formatCreated(created) }
                            </div>
                            { this.props.client.executedOrdersCount
                                ? (
                                    <div>
                                        <div className={classes.medium + ' ' + classes.grey + ' ' + classes.inlineBlock}>
                                            (
                                            <div className={classes.orange + ' ' + classes.inlineBlock}>
                                                { visitsCount }
                                            </div>
                                        </div>
                                        { ' ' }
                                        <div className={classes.low + ' ' + classes.grey + ' ' + classes.inlineBlock}>
                                            посетил компанию
                                        </div>
                                        <div className={classes.medium + ' ' + classes.grey + ' ' + classes.inlineBlock}>
                                            )
                                        </div>
                                    </div>
                                )
                                : (
                                    <div className={classes.medium + ' ' + classes.grey + ' ' + classes.inlineBlock}>
                                        (
                                        <div className={classes.low + ' ' + classes.grey + ' ' + classes.inlineBlock}>не посещал компанию</div>
                                        )
                                    </div>
                                ) }
                        </div>

                        { /* <div className={classes.loyalty}>
                            <div className={classes.high + ' ' + classes.grey}>Лояльность:</div>
                            <div className={classes.starLine}>
                                <div className={classes.brightStar}></div>
                                <div className={classes.brightStar}></div>
                                <div className={classes.brightStar}></div>
                                <div className={classes.dimStar}></div>
                                <div className={classes.dimStar}></div>
                            </div>
                        </div> */ }
                        { /* <div className={classes.visit + ' ' + classes.block}>
                            <div className={classes.low + ' ' + classes.grey}>Ближайшее посещение:</div>
                            <div className={classes.high + ' ' + classes.green}>
                                {visits.length > 0 ?
                                    moment.min(visits).isSame(moment().startOf('day')) ?
                                        'сегодня' :
                                         moment.min(visits).format('DD MMMM YY') :
                                    'отсутствует'}
                            </div>
                        </div> */ }
                        <FieldInput
                            field={emailField}
                            type='email'
                            onChange={(e) => {
                                this.props.changeClient(e);
                            }}
                            onFinishChange={(value) => {
                                this.props.changeSelectField(value, emailField, this.props.client.id);
                            }}
                        />
                        <div className={classes.comment}>
                            <div className={classes.label + ' ' + classes.low + ' ' + classes.grey}>Комментарий:</div>
                            { this.props.editClientChange === 'comment'
                                ? (
                                    <div>
                                        <textarea
                                            rows='3'
                                            name='comment'
                                            autoFocus
                                            value={this.props.changedClient.comment}
                                            onChange={this.props.changeClient}
                                            onClick={e => e.stopPropagation()}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') {
                                                    return this.props.updateClient(this.props.client.id);
                                                }
                                            }}
                                        />
                                        <div className={classes.editOk} onClick={this.props.updateClient.bind(this, client.id)} />
                                    </div>
                                )
                                : (
                                    <div className={classes.pointer} onClick={this.props.editClient.bind(this, 'comment')}>
                                        { client.comment
                                            ? (
                                                <div className={classes.text + ' ' + classes.medium + ' ' + classes.grey}>
                                                    { client.comment }
                                                </div>
                                            )
                                            : (
                                                <div className={classes.inlineBlock + ' ' + classes.medium + ' ' + classes.orange}>
                                                    Добавить
                                                </div>
                                            ) }
                                        <div className={classes.edit} />
                                    </div>
                                ) }
                        </div>
                    </div>
                    <div className={classes.dataBlock}>
                        <div className={classes.high + ' ' + classes.green}>{ isOrtusClient }</div>
                        { lastActivity }
                        { /* <div className={classes.check}>
                            <div className={classes.high + ' ' + classes.grey}>Чек клиента</div>
                        </div>
                        <div className={classes.checkInfo}>
                            <div className={classes.checkLengthDepth}>
                                <div className={classes.checkLength}>
                                    <div className={classes.low + ' ' + classes.grey}>Длина чека:</div>
                                    <div className={classes.medium + ' ' + classes.orange}>10 позиций</div>
                                </div>
                                <div className={classes.checkDepth}>
                                    <div className={classes.low + ' ' + classes.grey}>Глубина чека:</div>
                                    <div className={classes.medium + ' ' + classes.orange}>4 подразд.</div>
                                </div>
                            </div>
                            <div className={classes.checkAverage}>
                                <div className={classes.low + ' ' + classes.grey}>Средний чек:</div>
                                <div className={classes.high + ' ' + classes.orange}>100500 руб.</div>
                            </div>
                        </div> */ }
                    </div>
                </div>
            </div>
        );
    }
}

export default MainInfo;
