import { api, httpBuildQuery } from 'utils';
import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
export const CHANGE_STATUS = 'CHANGE_STATUS';

export const REQUEST_FEEDBACK = 'REQUEST_FEEDBACK';
export const RECEIVE_FEEDBACK = 'RECEIVE_FEEDBACK';
export const RECEIVE_FEEDBACK_ITEM = 'receiveFeedbackItem';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchFeedBack = (data, merge = false) => {
    return function(dispatch) {
        dispatch(requestFeedBack());

        return api('/v1/partner/feedback?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveFeedback(response, merge)));
    };
};

function requestFeedBack() {
    return {
        type: REQUEST_FEEDBACK
    };
}

function receiveFeedback(data, merge) {
    return {
        type: RECEIVE_FEEDBACK,
        merge: merge,
        data: data
    };
}

export const fetchFeedBackItem = (id) => {
    return function(dispatch) {
        dispatch(requestFeedBack());

        return api('/v1/partner/feedback/' + id, {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receiveFeedbackItem(res)));
    };
};

function receiveFeedbackItem(res) {
    return {
        type: RECEIVE_FEEDBACK_ITEM,
        payload: res
    };
}

export const changeStatus = (status, e) => {
    if (e) {
        e.preventDefault();
    }
    return {
        type: CHANGE_STATUS,
        status: status
    };
};

export const actions = {
    fetchFeedBack,
    fetchFeedBackItem
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [CHANGE_STATUS]: (state, action) => {
        return ({ ...state, status: action.status });
    },
    [REQUEST_FEEDBACK]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_FEEDBACK]: (state, action) => {
        let feedback;
        if (action.merge) {
            feedback = Object.assign({}, state.feedback);
            _.map(action.data.items, item => {
                if (!_.some(feedback.items, { id: item.id })) {
                    feedback.items.push(item);
                }
            });
        } else {
            feedback = action.data;
        }
        return ({ ...state, feedback: feedback, fetching: false });
    },
    [RECEIVE_FEEDBACK_ITEM]: (state, action) => {
        return ({ ...state, feedbackItem: action.payload, fetching: false });
    }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    feedback: null,
    feedbackItem: null,
    fetching: false
};
export default function cmtFeedBackReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
