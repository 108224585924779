import { connect } from 'react-redux';
import { actions } from '../../../../modules/calls';

import CallView from './CallView';

const mapStateToProps = (state) => ({
    call: state.calls.call,
    calls: state.calls.calls
});

export default connect(mapStateToProps, actions)(CallView);
