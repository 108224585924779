import React from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import Preloader from 'components/Preloader';
import _ from 'lodash';

require('./settings.scss');

class Main extends React.Component {
    state = {
        prevCompany: this.props.company && this.props.company.id ? _.cloneDeep(this.props.company) : null
    };

    componentDidMount() {
        if (this.props.partner) {
            this.props.fetchCompany(this.props.partner.company_id, [
                'city',
                'divisions',
                'resources',
                'resources.divisions',
                'resources.schedules',
                'files',
                'files.thumbs',
                'notifications',
                'catalogues',
                'services'
            ]);
        }
        this.props.fetchResources();
        this.props.showHeader('hide');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.state.prevCompany && nextProps.company && nextProps.company.id) {
            this.setState({ prevCompany: _.cloneDeep(nextProps.company) });
        }
    }

    saveCompany(e) {
        e.preventDefault();
        const data = this.props.company;

        const expand = [
            'city',
            'divisions',
            'resources',
            'resources.divisions',
            'resources.schedules',
            'files',
            'files.thumbs',
            'notifications',
            'catalogues',
            'services'];
        // TODO удалить когда при регистрации компании будет задаваться город и в базе не будет пустых значений city_id
        // у компаний созданных до переделки города
        if (data.city_id === 0) {
            delete data.city_id;
        }
        // фильруем поле телефонов на предмет некорректных телефонов
        const phonesKey = _.findKey(this.props.company.fields, { label: 'company_phones' });

        const phones = this.props.company.fields[phonesKey];
        phones.value = _.filter(phones.value, phone => (phone.value !== '' && phone.label !== ''));
        this.setState({ prevCompany: null });
        this.props.saveCompany(data, expand);
    }

    render() {
        return (
            <div className='settings'>
                <div className='settings-menu'>
                    <NavLink
                        to='/settings/common'
                        activeClassName='active'
                        className={cx({
                            'settings-menu__link': true,
                            'settings-menu__link--common': true
                        })}
                    >
                        <span>Настройки компании</span>
                    </NavLink>
                    <NavLink
                        to='/settings/services'
                        activeClassName='active'
                        className={cx({
                            'settings-menu__link': true,
                            'settings-menu__link--services': true
                        })}
                    >
                        <span>Настройки услуг</span>
                    </NavLink>
                    <NavLink
                        to='/settings/posts'
                        activeClassName='active'
                        className={cx({
                            'settings-menu__link': true,
                            'settings-menu__link--resources': true
                        })}
                    >
                        <span>Настройки мастеров</span>
                    </NavLink>
                    <NavLink
                        to='/settings/connect-pro'
                        activeClassName='active'
                        className={cx({
                            'settings-menu__link': true,
                            'settings-menu__link--cpro': true
                        })}
                    >
                        <span>Настройка Connect PRO</span>
                    </NavLink>
                </div>
                { this.props.children }
                <div
                    onClick={this.saveCompany.bind(this)}
                    className={cx({
                        disabled: !this.props.company.unsaved,
                        settings__submit: true,
                        hidden: ['/settings/services', '/settings/posts'].indexOf(this.props.location.pathname) !== -1
                    })}
                >
                    Сохранить
                </div>
                <Preloader trigger={this.props.fetching} fixed notPointed />
            </div>
        );
    }
}

export default Main;
