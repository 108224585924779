/* eslint-disable */
/**
 * Created by user on 18.05.2016.
 */
import _ from 'lodash';
import qs from 'qs';
import 'whatwg-fetch';
import { setAjaxError } from '../modules/hangar';

export const HELP_CSS = {
    hlpInline: 'hlpInline',
    hlpRemoveIcon: 'hlpRemoveIcon',
    hlpAddIcon: 'hlpAddIcon',
    hlpAddIconBig: 'hlpAddIcon--big',
    hlpCheckbox: 'hlpCheckbox',
    hlpSelectIcon: 'hlpSelectIcon'
};

export const api = (uri, data, dispatch, fileUpload = false) => {
    let opts = {
        method: 'get',
        mode: 'cors',
        headers: {
            Accept: 'application/json'
        }
    };
    if (!fileUpload) {
        opts.headers['Content-Type'] = 'application/json';
    }
    const token = localStorage.getItem('token');
    if (token) {
        opts.headers['X-Api-Token'] = token;
    }
    opts = _.merge(opts, data);
    opts.headers = new Headers(opts.headers);
    const request =
        fetch(process.env.REACT_APP_API_PATH + uri, opts).then(function(response) {
            if (response.status !== 200) {
                if (dispatch) {
                    return (response.json()).then(
                        (res) => {
                            dispatch(setAjaxError({ response: res, status: response.status, url: response.url }));
                            return false;
                        }
                    );
                }
            } else {
                return response.json();
            }
        });
    return request;
};

// форматируем телефон вида +7XXXXXXXXXX в +7 (XXX)XXX-XX-XX
export const formatPhone = (phone) => {
    if (phone) {
        return phone.substr(0, 2) + ' (' + phone.substr(2, 3) + ') ' + phone.substr(5, 3) + '-' + phone.substr(8, 2) + '-' +
            phone.substr(10, 2);
    }
    return '';
};

// правильное окончание существительного коего... n
export const declOfNum = (number, titles) => {
    if (number) {
        number = number.toString().substr(-4);
    }
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
};

// Generate URL-encoded query string
// export const http_build_query = (formdata, numeric_prefix, arg_separator) => {
export const httpBuildQuery = (formdata, numeric_prefix, arg_separator) => {
    /* eslint-disable */
    let key; let use_val; let use_key; let i = 0; const tmp_arr = [];
    if (!arg_separator) {
        arg_separator = '&';
    }
    for (key in formdata) {
        if (formdata[key] !== undefined && formdata[key] !== null && formdata[key] !== '') {
            use_key = encodeURIComponent(key);
            use_val = encodeURIComponent((formdata[key].toString()));
            if (numeric_prefix && !isNaN(key)) {
                use_key = numeric_prefix + i;
            }
            tmp_arr[i] = use_key + '=' + use_val;
            i++;
        }
    }
    return tmp_arr.join(arg_separator);
};

export const stringifyQuery = (params) => {
    return qs.stringify(params, { arrayFormat: 'indices' });
};

export const parseQueryString = (query) => {
    return qs.parse(query, { arrayLimit: 0 });
};

// форматируем число 7321257 => 7 321 257
export const formatNumber = (price) => {
    return price.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
};

// передаем  определенное количество дней - получаем строку с названием промежутка времени
export const getPeriodTitle = (period) => {
    switch (period) {
    case 7:
        return 'неделю';
    default:
    case 30:
        return 'месяц';
    case 365:
        return 'год';
    }
};

// получаем позицию модального окна в зависимости от размеров окна браузера
export const getBlockPosition = (height, width) => {
    return {
        top: window.innerHeight < height ? 0 + 'px' : (window.innerHeight - height) / 2 + 'px',
        left: (window.innerWidth - width) / 2 + 'px',
        height: height + 'px',
        width: width + 'px'
    };
};

// определяем выход элемента за границы вьюпорта
export const getElScreenOffset = (el, offsetX, offsetY) => {
    if (el) {
        const rect = el.getBoundingClientRect();

        const html = document.documentElement;

        const vWidth = html.clientWidth;

        const vHeight = html.clientHeight;
        return {
            overflowTop: rect.top - offsetY < 0,
            overflowBottom: rect.bottom - offsetY > vHeight,
            overflowRight: rect.right - offsetX > vWidth,
            overflowLeft: rect.left - offsetX < 0

        };
    }
};
// позиция элемента на экране
export const getOffsetRect = (elem) => {
    const box = elem.getBoundingClientRect();

    const body = document.body;

    const docElem = document.documentElement;

    const scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop;

    const scrollLeft = window.pageXOffset || docElem.scrollLeft || body.scrollLeft;

    const clientTop = docElem.clientTop || body.clientTop || 0;

    const clientLeft = docElem.clientLeft || body.clientLeft || 0;

    const top = box.top + scrollTop - clientTop;

    const left = box.left + scrollLeft - clientLeft;
    return { top: Math.round(top), boxTop: box.top, left: Math.round(left), boxLeft: box.left };
};

// добавляем класс элементу
export const addClass = (el, className) => {
    if (el.classList) {
        el.classList.add(className);
    } else {
        el.className += ' ' + className;
    }
};
// удаляем класс у элемента
export const removeClass = (el, className) => {
    if (el.classList) {
        el.classList.remove(className);
    } else {
        el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
};
// проверка наличия класса у элемента
export const hasClass = (el, className) => {
    if (el) {
        if (el.classList) { return el.classList.contains(className); } else { return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className); }
    } else {
        return false;
    }
};

export const triggerPush = (arr, value) => {
    if (arr.indexOf(value) !== -1) {
        arr.splice(arr.indexOf(value), 1);
    } else {
        arr.push(value);
    }
    return arr;
};

export const isEmpty = (mixed_var) => { // Determine whether a variable is empty
    return (mixed_var === '' || mixed_var === 0 || mixed_var === '0' || mixed_var === null || mixed_var === false || ((_.isArray(mixed_var) || _.isObject(mixed_var)) && mixed_var.length === 0));
};

export const parseWorkTime = (value) => {
    let firstChunk = value.indexOf('1');

    let lastChunk = value.lastIndexOf('1');

    const chunkSize = 24 / value.length;

    let lunchBreak = null;

    if (firstChunk === -1) {
        firstChunk = 0;
    }
    if (lastChunk === -1) {
        lastChunk = value.length;
    }
    // вычисляем время обеденного перерыва вычисляем
    const firstBreakChunk = value.indexOf('10') + 1; const lastBreakChunk = value.lastIndexOf('01');
    if (firstBreakChunk === lastChunk + 1 || lastBreakChunk === firstChunk - 1 || firstBreakChunk === 0 || lastBreakChunk === -1) {
        lunchBreak = null;
    } else {
        lunchBreak = {
            begin: {
                hour: parseInt(firstBreakChunk * chunkSize),
                minute: (firstBreakChunk * chunkSize - parseInt(firstBreakChunk * chunkSize)) * 60
            },
            end: {
                hour: parseInt((lastBreakChunk + 1) * chunkSize),
                minute: ((lastBreakChunk + 1) * chunkSize - parseInt((lastBreakChunk + 1) * chunkSize)) * 60
            },
            firstBreakChunk: firstBreakChunk,
            lastBreakChunk: lastBreakChunk,
            switch: true
        };
    }

    return {
        begin: {
            hour: parseInt(firstChunk * chunkSize),
            minute: (firstChunk * chunkSize - parseInt(firstChunk * chunkSize)) * 60
        },
        end: {
            hour: parseInt((lastChunk + 1) * chunkSize),
            minute: ((lastChunk + 1) * chunkSize - parseInt((lastChunk + 1) * chunkSize)) * 60
        },
        lunchBreak: lunchBreak
    };
};

// получаем текст ошибки для вывода на экран
export const getErrorOutput = (error) => {
    switch (error) {
    case 'The nick field is required.':
        return 'Погоняло клиента обязательно';
    case 'The phone field is required.':
        return 'Номер телефона клиента обязателен';
    case 'The phone has already been taken.':
        return 'Данный номер занят другим клиентом';
    case 'The phone must be at least 12 characters.':
        return 'Неполный номер телефона';
    case 'The next serviced at field is required.':
        return 'Дата следующего оказания услуги обязательна';
    case 'The repeats during period field is required.':
        return 'Цикличность приглашений обязательна';
    case 'The service id field is required.':
        return 'Услуга обязательна';
    case 'The average duration field is required.':
        return 'Длительность услуги обязательна';
    case 'The min price field is required.':
        return 'Стоимость услуги обязательна';
    case 'The name field is required when service template id is not present.':
        return 'Название услуги обязательно';
    default:
        return 'Мы от вас такого не ожидали';
    }
};

export const formatDuration = (minutes) => {
    return (Math.floor(minutes / 60) ? Math.floor(minutes / 60) + ' ч ' : '') + (minutes % 60 ? minutes % 60 + ' мин' : '');
};

export const toggleArrayPush = (arr, value) => {
    if (arr.indexOf(value) === -1) {
        arr.push(value);
    } else {
        arr.splice(arr.indexOf(value), 1);
    }
    return arr;
};

export const handleClickOutside = (event, domNode) => {
    if (!(event.path)) {
        event.path = [event.target];
        let currentElem = event.target;
        while (currentElem) {
            currentElem = currentElem.parentElement;
            event.path.push(currentElem);
        }
    }
    if (domNode) {
        const domNodeRect = domNode.getBoundingClientRect();
        let conditionInTree, conditionInRect, conditionInRIB;
        if (!event.path.includes(domNode)) {
            conditionInTree = true;
        }
        if (!(event.clientX >= domNodeRect.left && event.clientX <= domNodeRect.right && event.clientY >= domNodeRect.top && event.clientY <= domNodeRect.bottom)) {
            conditionInRect = true;
        }
        if (!_.some(event.path, { className: 'renderInBody' })) {
            conditionInRIB = true;
        }
        if (conditionInRect && conditionInTree && conditionInRIB) {
            return true;
        }
    }

    return false;
};

export const togglePush = (value, arr) => {
    if (arr.indexOf(value) === -1) {
        arr.push(value);
    } else {
        arr = arr.splice(arr.indexOf(value), 1);
    }
};

export const togglePushObjectById = (value, objectsArr) => {
    const ids = _.map(objectsArr, o => (o.id));
    if (ids.indexOf(value.id) === -1) {
        objectsArr.push(value);
    } else {
        objectsArr = objectsArr.splice(ids.indexOf(value.id), 1);
    }
    return objectsArr;
};

export const beforeMaskedPhoneChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    if (oldState.value === '+7 (___) ___-__-__' && userInput && (userInput[0] === '8' || userInput[0] === '7' || (userInput[0] === '+' && userInput[1] === '7'))) {
        userInput = userInput.replace(/([^0-9+]*)/ig, '');
        if (userInput[0] === '+') {
            value = '+7' + userInput.slice(2);
            if (userInput.length < 12) {
                if (userInput.length === 11) {
                    cursorPosition--;
                } else {
                    cursorPosition = cursorPosition - 2;
                }
                selection = { start: cursorPosition, end: cursorPosition };
            }
        } else {
            value = '+7' + userInput.slice(1);
            if (userInput.length < 11) {
                cursorPosition--;
                selection = { start: cursorPosition, end: cursorPosition };
            }
        }
    }

    return {
        value,
        selection
    };
};

export const getInitials = (name) => {
    if (!name) {
        return '';
    }

    const nameParts = name.split(' ');

    let initials;

    if (nameParts.length > 1) {
        initials = nameParts[0].slice(0, 1) + nameParts[1].slice(0, 1);
    } else {
        initials = nameParts[0].slice(0, 2);
    }

    return initials.toUpperCase();
};

export function getParamFromLocation(location = window.location, start = 'range_start', end = 'range_end') {
    const queryString = location && location.search;
    const urlParams = new URLSearchParams(queryString);
    const from = urlParams.get(start);
    const to = urlParams.get(end);
    return {
        start: from,
        end: to
    };
}
