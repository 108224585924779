import React from 'react';

import _ from 'lodash';
import cn from 'classnames';

import Input from 'components/Input';
import InputElement from 'react-input-mask';
import Gallery from './Gallery';
import Slim from '../../../../../lib/slim/slim.react.jsx';

import './styles_common.scss';
import '../../../../../lib/slim/slim.scss';

const BUSINESS_TYPES = { 1: 'auto', 2: 'beauty' };

class Common extends React.Component {
    state = {
        mode: 'common', // common, description
        descriptionMode: 'full', // full, short
        searchCity: this.props.company && _.get(this.props.company, 'city.title', null),
        foundationYear: this.props.company && _.get(_.find(this.props.company.fields, { label: 'company_foundation_year' }), 'value', null),
        siteUrl: this.props.company && _.get(_.find(this.props.company.fields, { label: 'company_site_url' }), 'value', null),
        newCompanyImage: false,
        workTime: null,
        errors: {}
    };

    UNSAFE_componentWillMount() {
        if (this.props.company && this.props.company.id) {
            const workTimeChunks = _.get((_.find(this.props.company.fields, { label: 'company_worktime' }) || {}), 'value', '111111111111111111111111111111111111111111111111');

            const workTime = this.parseWorkTime(workTimeChunks);
            this.setState({ workTime });
        }
    }

    componentDidMount() {
        if (_.get(this.props.company, 'id') && _.get(this.props.company, 'logo_id')) {
            this.props.fetchLogo(this.props.company.id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevState.workTime) !== JSON.stringify(this.state.workTime)) {
            if (this.checkWorkTime(this.state.workTime).condition) {
                this.changeWorkTimeToStore(this.parseWorkTimeToChunks(this.state.workTime));
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const company = nextProps.company || {};
        const _company = this.props.company || {};

        if (!_company.id && company.id && company.logo_id) {
            this.props.fetchLogo(company.id);
            const workTimeChunks = _.get((_.find(company.fields, { label: 'company_worktime' }) || {}), 'value', '111111111111111111111111111111111111111111111111');

            const workTime = this.parseWorkTime(workTimeChunks);
            this.setState({ workTime });
        }
        if (!_company.id && company.id) {
            const workTimeChunks = _.get((_.find(company.fields, { label: 'company_worktime' }) || {}), 'value', '111111111111111111111111111111111111111111111111');

            const workTime = this.parseWorkTime(workTimeChunks);
            this.setState({ workTime });
        }

        const cityTitle = _.get(nextProps.company, 'city.title');

        const foundationYear = _.get(_.find(nextProps.company.fields, { label: 'company_foundation_year' }), 'value');

        const siteUrl = _.get(_.find(nextProps.company.fields, { label: 'company_site_url' }), 'value');
        if (this.state.searchCity === null && cityTitle && cityTitle !== this.state.searchCity) {
            this.setState({ searchCity: cityTitle });
        }
        if (this.state.foundationYear === null && foundationYear && foundationYear !== this.state.foundationYear) {
            this.setState({ foundationYear });
        }
        if (this.state.siteUrl === null && siteUrl && siteUrl !== this.state.siteUrl) {
            this.setState({ siteUrl });
        }
    }

    changeCompany(path, e) {
        const value = e.target.value;
        this.props.changeCompany(path, value);
    }

    changeCompanyName(e) {
        const value = e.target.value;
        this.props.changeCompany('name', value);
    }

    changeCompanyField(field, e) {
        const value = e.target.value;
        const fKey = this.props.company && _.findKey(this.props.company.fields, { label: field });
        this.props.changeCompany(`fields.${fKey}.value`, value);
    }

    imageWillSave(data, ready) {
        this.showLoader();
        this.setState({ newCompanyImage: true });
        ready(data);
    }

    imageDidUpload(data, ready, file) {
        // ResourcesAPI.uploadResourceImage(this.cropper.instance);
        this.hideLoader();
        if (_.get(file, 'cover.id')) {
            this.props.changeCompanyCover(file.cover);
        }
    }

    deleteImageCover(e) {
        e.preventDefault();
        if (window.confirm('Удалить обложку компании?')) {
            this.props.company && this.props.deleteCompanyCover(this.props.company.id);
            this.setState({ newCompanyImage: false });
        } else {
            return false;
        }
    }

    deleteImageSlim(data, remove) {
        if (window.confirm('Удалить обложку компании?')) {
            this.props.company && this.props.deleteCompanyCover(this.props.company.id);
            this.setState({ newCompanyImage: false });
            remove();
        } else {
            return false;
        }
    }

    showLoader() {

    }

    hideLoader() {

    }

    changeWorkDays(dayLabel, e) {
        e.preventDefault();
        let workDays = this.props.company && _.get((_.find(this.props.company.fields, { label: 'company_workdays' }) || {}), 'value', []);
        if (!(workDays instanceof Array)) {
            workDays = [];
        }
        const dayIsOn = Boolean(workDays && workDays.indexOf(dayLabel) !== -1);
        if (dayIsOn) {
            delete workDays[workDays.indexOf(dayLabel)];
        } else {
            workDays.push(dayLabel);
        }
        const fKey = this.props.company && _.findKey(this.props.company.fields, { label: 'company_workdays' });
        this.props.changeCompany(`fields.${fKey}.value`, workDays);
    }

    parseWorkTime(value) {
        let firstChunk = value.indexOf('1');

        let lastChunk = value.lastIndexOf('1');

        const chunkSize = 24 / value.length;

        let lunchBreak = null;

        if (firstChunk === -1) {
            firstChunk = 0;
        }
        if (lastChunk === -1) {
            lastChunk = value.length;
        }
        // вычисляем время обеденного перерыва вычисляем
        const firstBreakChunk = value.indexOf('10') + 1; const lastBreakChunk = value.lastIndexOf('01');
        if (firstBreakChunk === lastChunk + 1 || lastBreakChunk === firstChunk - 1 || firstBreakChunk === 0 || lastBreakChunk === -1) {
            lunchBreak = null;
        } else {
            lunchBreak = {
                begin: {
                    hour: (`0${parseInt(firstBreakChunk * chunkSize)}`).slice(-2),
                    minute: (`0${(firstBreakChunk * chunkSize - parseInt(firstBreakChunk * chunkSize)) * 60}`).slice(-2)
                },
                end: {
                    hour: (`0${parseInt((lastBreakChunk + 1) * chunkSize)}`).slice(-2),
                    minute: (`0${((lastBreakChunk + 1) * chunkSize - parseInt((lastBreakChunk + 1) * chunkSize)) * 60}`).slice(-2)
                },
                switch: true
            };
        }

        return {
            begin: {
                hour: (`0${parseInt(firstChunk * chunkSize)}`).slice(-2),
                minute: (`0${(firstChunk * chunkSize - parseInt(firstChunk * chunkSize)) * 60}`).slice(-2)
            },
            end: {
                hour: (`0${parseInt((lastChunk + 1) * chunkSize)}`).slice(-2),
                minute: (`0${((lastChunk + 1) * chunkSize - parseInt((lastChunk + 1) * chunkSize)) * 60}`).slice(-2)
            },
            lunchBreak
        };
    }

    checkWorkTime(workTime) {
        let result = { condition: true, message: '' };
        if (workTime === null) {
            return result;
        }
        // проверяем правильность значений
        if (parseInt(workTime.begin.hour) * 60 + parseInt(workTime.begin.minute) >= parseInt(workTime.end.hour) * 60 + parseInt(workTime.end.minute) ||
            parseInt(workTime.begin.hour) * 60 + parseInt(workTime.begin.minute) < 0 || parseInt(workTime.end.hour) * 60 + parseInt(workTime.end.minute) > 24 * 60
        ) {
            result = { condition: false, message: 'Некорректное время начала и конца рабочего дня' };
        }
        if (_.get(workTime, 'lunchBreak.switch', false)) {
            if ((parseInt(workTime.begin.hour) * 60 + parseInt(workTime.begin.minute) >= parseInt(workTime.lunchBreak.begin.hour) * 60 + parseInt(workTime.lunchBreak.begin.minute)) ||
                (parseInt(workTime.end.hour) * 60 + parseInt(workTime.end.minute) <= parseInt(workTime.lunchBreak.end.hour) * 60 + parseInt(workTime.lunchBreak.end.minute)) ||
                (parseInt(workTime.lunchBreak.begin.hour) * 60 + parseInt(workTime.lunchBreak.begin.minute) >= parseInt(workTime.lunchBreak.end.hour) * 60 + parseInt(workTime.lunchBreak.end.minute))
            ) {
                result = { condition: false, message: 'Некорректное время обеденного перерыва' };
            }
        }
        return result;
    }

    parseWorkTimeString(value) {
        let firstChunk = value.indexOf('1');

        let lastChunk = value.lastIndexOf('1');

        const chunkSize = 24 / value.length;

        if (firstChunk === -1) {
            firstChunk = 0;
        }
        if (lastChunk === -1) {
            lastChunk = value.length;
        }
        // вычисляем время обеденного перерыва вычисляем

        const worktime = {
            begin: {
                hour: (`0${parseInt(firstChunk * chunkSize)}`).slice(-2),
                minute: (`0${(firstChunk * chunkSize - parseInt(firstChunk * chunkSize)) * 60}`).slice(-2)
            },
            end: {
                hour: (`0${parseInt((lastChunk + 1) * chunkSize)}`).slice(-2),
                minute: (`0${((lastChunk + 1) * chunkSize - parseInt((lastChunk + 1) * chunkSize)) * 60}`).slice(-2)
            }
        };
        return `${worktime.begin.hour}:${worktime.begin.minute}-${worktime.end.hour}:${worktime.end.minute}`;
    }

    changeWorkTime(timeIs, e) {
        e.preventDefault();
        // запихиваем то, что ввел пользователь
        const _workTime = _.cloneDeep(this.state.workTime);
        let _value = e.target.value;
        if (_value.length === 1) {
            _value = `0${_value}`;
        }
        if (_value.length === 3) {
            _value = _value.substr(1);
        }

        switch (timeIs) {
        case 'beginHour':
            if (parseInt(_value) > 23) {
                _value = '23';
            }
            _.set(_workTime, 'begin.hour', _value);
            break;
        case 'beginMinute':
            if (parseInt(_value) > 59) {
                _value = '59';
            }
            _.set(_workTime, 'begin.minute', _value);
            break;
        case 'endHour':
            if (parseInt(_value) > 24) {
                _value = '24';
            }
            _.set(_workTime, 'end.hour', _value);
            break;
        case 'endMinute':
            if (parseInt(_value) > 59) {
                _value = '59';
            }
            _.set(_workTime, 'end.minute', _value);
            break;
            // обработка перерыва=======================================================================================
        case 'breakBeginHour':
            if (parseInt(_value) > 23) {
                _value = '23';
            }
            _.set(_workTime, 'lunchBreak.begin.hour', _value);
            break;
        case 'breakBeginMinute':
            if (parseInt(_value) > 59) {
                _value = '59';
            }
            _.set(_workTime, 'lunchBreak.begin.minute', _value);
            break;
        case 'breakEndHour':
            if (parseInt(_value) > 23) {
                _value = '23';
            }
            _.set(_workTime, 'lunchBreak.end.hour', _value);
            break;
        case 'breakEndMinute':
            if (parseInt(_value) > 59) {
                _value = '59';
            }
            _.set(_workTime, 'lunchBreak.end.minute', _value);
            break;
        default:
        }

        this.setState({ workTime: _workTime });
    }

    parseWorkTimeToChunks(workTime) {
        const chunkSize = (24 * 60) / (_.get((_.find(this.props.company.fields, { label: 'company_worktime' }) || {}), 'value', []).length || 48);
        const firstChunk = (workTime.begin.hour * 60) / chunkSize + Math.ceil(workTime.begin.minute / chunkSize);

        const lastChunk = (workTime.end.hour * 60) / chunkSize + Math.ceil(workTime.end.minute / chunkSize) - 1; let // отнимаем единице потому что с указанного времени работа уже закончилась
            firstChunkBreak = null;

        let lastChunkBreak = null;
        const result = _.fill(new Array((24 * 60) / chunkSize), '0');
        for (let i = firstChunk; i <= lastChunk; i++) {
            result[i] = '1';
        }

        if (_.get(workTime, 'lunchBreak.switch')) {
            firstChunkBreak = (workTime.lunchBreak.begin.hour * 60) / chunkSize + Math.ceil(workTime.lunchBreak.begin.minute / chunkSize);
            lastChunkBreak = (workTime.lunchBreak.end.hour * 60) / chunkSize + Math.ceil(workTime.lunchBreak.end.minute / chunkSize) - 1;// отнимаем единице потому что с указанного времени работа уже начинается
            for (let i = firstChunkBreak; i <= lastChunkBreak; i++) {
                result[i] = '0';
            }
        }
        return result.join('');
    }

    changeWorkTimeToStore(newWorkTimeChunks) {
        const workTimeKey = this.props.company && _.findKey(this.props.company.fields, { label: 'company_worktime' });
        this.props.changeCompany(`fields.${workTimeKey}.value`, newWorkTimeChunks);
    }

    citiesList() {
        if (_.isEmpty(this.props.cities)) {
            return null;
        }
        return (
            <div className='citiesList'>
                { _.map(this.props.cities, city => (
                    <div
                        className='citiesList__item'
                        key={city.id}
                        onClick={this.changeCity.bind(this, city)}
                    >
                        <span className='title'>{ city.title }</span>
                        <span className='region-title'>{ city.region.title }</span>
                    </div>
                )) }
            </div>
        );
    }

    changeCity(city) {
        this.setState({ searchCity: city.title });
        this.props.clearCitiesList();

        this.props.changeCompany('city_id', city.id);
        this.props.changeCompany('city', city);
    }

    changeAddress(aField, value) {
        const company = _.cloneDeep(this.props.company);

        const fKey = _.findKey(company.fields, { label: 'company_address' });
        if (_.isEmpty(company.fields[fKey].value)) {
            company.fields[fKey].value = {};
        }
        _.set(company.fields[fKey], `value.${aField}`, value);
        this.props.changeCompany(`fields.${fKey}.value`, company.fields[fKey].value);
    }

    changePhone(key, phone) {
        const company = this.props.company;

        const fKey = _.findKey(this.props.company.fields, { label: 'company_phones' });
        company.fields[fKey].value[key] = phone;
        this.props.changeCompany(`fields.${fKey}.value`, company.fields[fKey].value);
    }

    addPhone() {
        const company = this.props.company;

        const fKey = _.findKey(this.props.company.fields, { label: 'company_phones' });
        company.fields[fKey].value.push({ label: '', value: '' });
        this.props.changeCompany(`fields.${fKey}.value`, company.fields[fKey].value);
    }

    removePhone(key) {
        const company = this.props.company;

        const fKey = _.findKey(this.props.company.fields, { label: 'company_phones' });
        company.fields[fKey].value.splice(key, 1);
        this.props.changeCompany(`fields.${fKey}.value`, company.fields[fKey].value);
    }

    incorrectPhones(phones) {
        return _.some(phones, phone => phone.value === '' || phone.label === '');
    }

    render() {
        const company = this.props.company;

        const description = _.get(_.find(_.get(this.props.company, 'fields', []), { label: 'company_desc' }) || {}, 'value', '');

        const foundationYear = _.get(_.find(_.get(this.props.company, 'fields', []), { label: 'company_foundation_year' }) || {}, 'value', '');

        const siteUrl = _.get(_.find(_.get(this.props.company, 'fields', []), { label: 'company_site_url' }) || {}, 'value', '');

        const shortDescription = _.get(this.props.company, 'short_desc', '');

        const address = _.find(this.props.company.fields, { label: 'company_address' });

        const cWorkDays = _.get((_.find(company.fields, { label: 'company_workdays' }) || {}), 'value', []);

        const cover = company.cover_id ? _.find(company.files, { id: company.cover_id }) : null;

        const schedule = _.get((_.find(company.fields, { label: 'company_worktime' }) || {}), 'value', '111111111111111111111111111111111111111111111111');

        const workTimeString = this.parseWorkTimeString(schedule);
        // после редактирования опираемся на темпоральный _workTime, которые ежели корректный закидываем в workTime реальный
        const inputWorkTime = this.state.workTime;
        const workdays = [
            { label: 'MONDAY', title: 'Пн', isOn: true },
            { label: 'TUESDAY', title: 'Вт', isOn: true },
            { label: 'WEDNESDAY', title: 'Ср', isOn: true },
            { label: 'THURSDAY', title: 'Чт', isOn: true },
            { label: 'FRIDAY', title: 'Пт', isOn: true },
            { label: 'SATURDAY', title: 'Сб', isOn: false },
            { label: 'SUNDAY', title: 'Вс', isOn: true }
        ];
        _.map(workdays, (day) => {
            day.isOn = Boolean(cWorkDays && cWorkDays.indexOf(day.label) !== -1);
        });

        const phones = _.get(_.find(_.get(this.props.company, 'fields', []), { label: 'company_phones' }) || {}, 'value', [{
            label: '',
            value: ''
        }]);

        // возня с аватаром
        const coverBlock = (
            <div className='cover'>
                <Slim
                    ref={node => (this.cropper = node)}
                    ratio='1:1'
                    service={`${process.env.REACT_APP_API_PATH}/v1/partner/companies/${company.id}/images?scheme=cover`}
                    label=''
                    push
                    buttonEditLabel='Редактировать'
                    buttonRemoveLabel='Удалить'
                    buttonDownloadLabel='Скачать'
                    buttonUploadLabel='Загрузить'
                    buttonCancelLabel='Отмена'
                    buttonConfirmLabel='Ок'
                    maxFileSize='3.5'
                    statusFileSize='Максимально допустимый размер: $0 Мб'
                    minSize={{ width: 10, height: 10 }}
                    statusImageTooSmall='Минимально допустимый размер: $0 пикселей'
                    statusNoSupport='Не поддерживается браузером'
                    statusUnknownResponse='Ошибка загрузки'
                    statusUploadSuccess='Ок'
                    willSave={this.imageWillSave.bind(this)}
                    didUpload={this.imageDidUpload.bind(this)}
                    willRemove={this.deleteImageSlim.bind(this)}
                >
                    <input type='file' name='file' id='slimCover' />
                </Slim>
                { cover && !this.state.newCompanyImage ? (
                    <div className='cover-img'>
                        <img src={_.get(cover, 'thumbnails.cover')} alt='' />
                        <button
                            className='slim-btn slim-btn-remove'
                            title='Удалить'
                            type='button'
                            onClick={this.deleteImageCover.bind(this)}
                        >
                            Удалить
                        </button>
                    </div>
                ) : null }
                { !cover && !this.state.newCompanyImage
                    ? <div className={`defaultCover ${BUSINESS_TYPES[company.business_type]}`} />
                    : null }
            </div>
        );

        const breakSwitch = _.get(inputWorkTime, 'lunchBreak.switch', !!((inputWorkTime && inputWorkTime.lunchBreak)));
        return (
            <div className='settings-block'>
                <div className='settings-block__h1'>Общие настройки компании</div>
                <div className='settings-block--common'>
                    <div className='leftColumn'>
                        <div className='yellow-block'>
                            <div className='companyName'>
                                <div className='yellow-block__label'>Название компании</div>
                                <Input
                                    name='name'
                                    className='default'
                                    type='text'
                                    value={_.get(this.props.company, 'name', '')}
                                    onChange={this.changeCompanyName.bind(this)}
                                />
                            </div>
                            <div className='companyName'>
                                <div className='yellow-block__label'>Слоган компании</div>
                                <Input
                                    name='name'
                                    className='default'
                                    type='text'
                                    value={_.get(this.props.company, 'short_desc', '')}
                                    onChange={this.changeCompany.bind(this, 'short_desc')}
                                />
                            </div>
                            <div className='mainPhoto'>
                                <div className='yellow-block__label'>Главная фотография</div>
                                <label htmlFor='slimCover'>
                                    загрузить
                                </label>
                            </div>
                            <div className='foundation_year'>
                                <div className='yellow-block__label'>Год основания компании</div>
                                <InputElement
                                    name='foundationYear'
                                    className='default'
                                    type='text'
                                    mask='9999'
                                    autoComplete='off'
                                    value={foundationYear}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        this.props.changeCompanyField('company_foundation_year', String(e.target.value === '____' ? e.target.value : parseInt(e.target.value)));
                                    }}
                                />
                            </div>
                        </div>
                        <div className='yellow-block'>
                            <div className='siteUrl'>
                                <div className='yellow-block__label'>Адрес сайта</div>
                                <div className='siteUrl__input'>
                                    <input
                                        name='siteUrl'
                                        className='default'
                                        type='text'
                                        autoComplete='real-off'
                                        value={siteUrl || ''}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            e.preventDefault();
                                            this.props.changeCompanyField('company_site_url', e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='yellow-block'>
                            <div className='phones'>
                                <div className='yellow-block__label'>Телефоны компании</div>
                                <div className='phoneInputs'>
                                    { _.map(phones, (value, key) => (
                                        <div className='phoneInput' key={key}>
                                            <input
                                                name={`phone_value_${key}`}
                                                placeholder='номер'
                                                className='default'
                                                type='text'
                                                value={value.value}
                                                onChange={(e) => {
                                                    this.changePhone(key, {
                                                        label: key !== 0 ? value.label : 'Основной',
                                                        value: e.target.value
                                                    });
                                                }}
                                            />
                                            { key !== 0
                                                ? (
                                                    <div
                                                        className='removePhone'
                                                        onClick={this.removePhone.bind(this, key)}
                                                    />
                                                )
                                                : null }
                                            { key !== 0
                                                ? (
                                                    <input
                                                        name={`phone_label_${key}`}
                                                        placeholder='назначение'
                                                        className='default'
                                                        type='text'
                                                        value={value.label}
                                                        onChange={(e) => {
                                                            this.changePhone(key, {
                                                                label: e.target.value,
                                                                value: value.value
                                                            });
                                                        }}
                                                    />
                                                )
                                                : <div className='text'>Основной</div> }
                                        </div>
                                    )) }
                                </div>
                                { this.incorrectPhones(phones) ? null : (
                                    <div className='addPhone' onClick={this.addPhone.bind(this)}>
                                        Добавить
                                        доп. телефон
                                    </div>
                                ) }
                            </div>
                        </div>
                    </div>
                    <div className='rightColumn'>
                        <div className='yellow-block yellow-block__2'>
                            <div className='subBlock workdays-block'>
                                <h2>Расписание компании</h2>
                                <div className='workdays-block__days'>
                                    <div className='yellow-block__label'>Дни работы</div>
                                    { _.map(workdays, day => (
                                        <div
                                            className={cn({ 'workdays-block__day': true, is_on: day.isOn })}
                                            onClick={this.changeWorkDays.bind(this, day.label)}
                                            key={day.label}
                                        >
                                            { day.title }
                                        </div>
                                    )) }
                                </div>
                            </div>
                            <div
                                className={cn({
                                    'subBlock worktime-block': true,
                                    wrong: !this.checkWorkTime(this.state.workTime).condition,
                                    editing: this.state.editing === 'workTime'
                                })}
                                onClick={() => {
                                    this.setState({ editing: 'workTime' });
                                }}
                            >
                                <h1 className='yellow-block__label'>Время работы</h1>
                                <div className='worktime-block__time'>
                                    <div className='divider'>с</div>
                                    <div className='inputGroup'>
                                        <Input
                                            type='text'
                                            name='workTimeBeginHour'
                                            placeholder=''
                                            value={String(_.get(inputWorkTime, 'begin.hour', '00'))}
                                            onChange={this.changeWorkTime.bind(this, 'beginHour')}
                                            onBlur={() => {
                                                this.setState({ editing: '' });
                                            }}
                                        />
                                        :
                                        <Input
                                            type='text'
                                            name='workTimeBeginMinute'
                                            placeholder=''
                                            value={String(_.get(inputWorkTime, 'begin.minute', '00'))}
                                            onChange={this.changeWorkTime.bind(this, 'beginMinute')}
                                            onBlur={() => {
                                                this.setState({ editing: '' });
                                            }}
                                        />
                                    </div>
                                    <div className='divider'>до</div>
                                    <div className='inputGroup'>
                                        <Input
                                            type='text'
                                            name='workTimeEndHour'
                                            placeholder=''
                                            value={String(_.get(inputWorkTime, 'end.hour', '00'))}
                                            onChange={this.changeWorkTime.bind(this, 'endHour')}
                                            onBlur={() => {
                                                this.setState({ editing: '' });
                                            }}
                                        />
                                        :
                                        <Input
                                            type='text'
                                            name='workTimeEndMinute'
                                            placeholder=''
                                            value={String(_.get(inputWorkTime, 'end.minute', '00'))}
                                            onChange={this.changeWorkTime.bind(this, 'endMinute')}
                                            onBlur={() => {
                                                this.setState({ editing: '' });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='error'>{ this.checkWorkTime(this.state.workTime).message }</div>
                            </div>
                            <div className='subBlock breaktime-block'>
                                <h1 className='yellow-block__label'>
                                    Перерыв на обед
                                    <div className='breaktime-block__switch'>
                                        <label>
                                            <input
                                                type='checkbox'
                                                checked={!breakSwitch}
                                                onChange={() => {
                                                    const workTime = _.cloneDeep(this.state.workTime);
                                                    if (this.state.workTime.lunchBreak) {
                                                        if (_.get(workTime, 'lunchBreak.switch', false)) {
                                                            this.setState({ workTime: _.merge(workTime, { lunchBreak: { switch: false } }) });
                                                        } else {
                                                            this.setState({ workTime: _.merge(workTime, { lunchBreak: { switch: true } }) });
                                                        }
                                                    } else {
                                                        this.setState({
                                                            workTime: _.merge(workTime, {
                                                                lunchBreak: {
                                                                    begin: {
                                                                        hour: '13',
                                                                        minute: '00'
                                                                    },
                                                                    end: { hour: '14', minute: '00' },
                                                                    switch: true
                                                                }
                                                            })
                                                        });
                                                    }
                                                }}
                                            />
                                            { ' ' }
                                            без перерыва
                                        </label>
                                    </div>
                                </h1>
                                <div className={`breaktime-block__time${!breakSwitch ? ' hidden' : ''}`}>
                                    <div className='divider'>с</div>
                                    <div className={`inputGroup${!breakSwitch ? ' inputGroup--disabled' : ''}`}>
                                        <Input
                                            type='text'
                                            name='breakTimeBeginHour'
                                            placeholder=''
                                            value={inputWorkTime ? String(_.get(inputWorkTime, 'lunchBreak.begin.hour', '13')) : '13'}
                                            disabled={!breakSwitch}
                                            onChange={this.changeWorkTime.bind(this, 'breakBeginHour')}
                                        />
                                        :
                                        <Input
                                            type='text'
                                            name='breakTimeBeginMinute'
                                            placeholder=''
                                            value={inputWorkTime ? String(_.get(inputWorkTime, 'lunchBreak.begin.minute', '00')) : '00'}
                                            disabled={!breakSwitch}
                                            onChange={this.changeWorkTime.bind(this, 'breakBeginMinute')}
                                        />
                                    </div>
                                    <div className='divider'>до</div>
                                    <div className={`inputGroup${!breakSwitch ? ' inputGroup--disabled' : ''}`}>
                                        <Input
                                            type='text'
                                            name='breakTimeEndHour'
                                            placeholder=''
                                            value={inputWorkTime ? String(_.get(inputWorkTime, 'lunchBreak.end.hour', '14')) : '14'}
                                            disabled={!breakSwitch}
                                            onChange={this.changeWorkTime.bind(this, 'breakEndHour')}
                                        />
                                        :
                                        <Input
                                            type='text'
                                            name='breakTimeEndMinute'
                                            placeholder=''
                                            value={inputWorkTime ? String(_.get(inputWorkTime, 'lunchBreak.end.minute', '00')) : '00'}
                                            disabled={!breakSwitch}
                                            onChange={this.changeWorkTime.bind(this, 'breakEndMinute')}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='yellow-block yellow-block__4'>
                            <h2>Адреса компании</h2>
                            <div>
                                <div className='yellow-block__label'>Город</div>
                                <div className='form__location__citiesInput citiesInput'>
                                    <Input
                                        name='city'
                                        className='default'
                                        type='text'
                                        autoComplete='off-i-am-sure'
                                        value={this.state.searchCity || ''}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.setState({ searchCity: e.target.value });
                                            if (e.target.value.length > 1) {
                                                this.props.searchCity(e.target.value);
                                            }
                                        }}
                                        onKeyUp={(e) => {
                                            if (e.key === 'Escape') {
                                                e.preventDefault();
                                                this.props.clearCitiesList();
                                                this.setState({ searchCity: _.get(this.props.company, 'city.title', '') });
                                            }
                                        }}
                                    />
                                    { this.citiesList() }
                                </div>
                            </div>
                            <div>
                                <div className='yellow-block__label'>Улица</div>
                                <Input
                                    name='street'
                                    className='default'
                                    type='text'
                                    onChange={(e) => {
                                        this.changeAddress('street', e.target.value);
                                    }}
                                    value={_.get(address, 'value.street', '')}
                                />
                            </div>
                            <div className='form__location__address row'>
                                <div className='col-xs-4'>
                                    <div className='yellow-block__label'>Дом</div>
                                    <Input
                                        name='house'
                                        className='default'
                                        type='text'
                                        onChange={(e) => {
                                            this.changeAddress('house', e.target.value);
                                        }}
                                        value={_.get(address, 'value.house', '')}
                                    />
                                </div>
                                <div className='col-xs-4'>
                                    <div className='yellow-block__label'>Корпус</div>
                                    <Input
                                        name='corpse'
                                        className='default'
                                        type='text'
                                        onChange={(e) => {
                                            this.changeAddress('corpse', e.target.value);
                                        }}
                                        value={_.get(address, 'value.corpse', '')}
                                    />
                                </div>
                                <div className='col-xs-4'>
                                    <div className='yellow-block__label'>Офис</div>
                                    <Input
                                        name='office'
                                        className='default'
                                        type='text'
                                        onChange={(e) => {
                                            this.changeAddress('office', e.target.value);
                                        }}
                                        value={_.get(address, 'value.office', '')}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='column--screen'>
                        { coverBlock }
                        <div className='heart' />
                        <div
                            className='since_year'
                        >
                            { foundationYear ? `since ${foundationYear}` : '' }
                        </div>
                        <div className='name'>{ this.props.company.name }</div>
                        <div className='column--screen__short-description'>{ shortDescription }</div>
                        <div className='favorite'>
                            <div className='favorite__value' style={{ width: '80%' }} />
                        </div>
                        <div className='schedule_until'>Открыто до { String(_.get(inputWorkTime, 'end.hour', 'XX')) }:{ String(_.get(inputWorkTime, 'end.minute', 'XX')) }</div>
                        <div className='column--screen__url'>{ siteUrl || '' }</div>
                        <div className='phones'>
                            { _.map(phones, (phone, key) => (
                                <div key={key}>
                                    { phone.value }
                                    { ' ' }
                                    <span>{ phone.label }</span>
                                </div>
                            )) }
                        </div>
                        <div className='schedule'>
                            <div className='today'>
                                <span className='card-block__form__text'>Сегодня</span>
&nbsp;
                                <span className='time'>{ workTimeString }</span>
                            </div>
                        </div>
                        <div className='column--screen__location'>
                            <div className='city'>
                                { _.get(company, 'city.title', false) ? (company.city.title) : null }
                            </div>
                            <div className='address'>
                                { _.get(address, 'value.street', false) ? (address.value.street) : null }
                                { _.get(address, 'value.house', false) ? (`, ${address.value.house}`) : null }
                                { _.get(address, 'value.corpse', false) ? (`, корп. ${address.value.corpse}`) : null }
                                { _.get(address, 'value.office', false) ? (`, офис ${address.value.office}`) : null }
                            </div>
                        </div>
                        <div className='column--screen__description'>{ description }</div>
                    </div>
                    <div className='yellow-block'>
                        <div>
                            <div className='yellow-block__label'>Описание компании</div>
                            <textarea
                                rows='3'
                                value={description}
                                onChange={(e) => {
                                    if (e.target.value.length <= 2000) {
                                        this.props.changeCompanyField('company_desc', e.target.value);
                                    }
                                }}
                            />
                        </div>
                    </div>

                </div>
                <Gallery />
            </div>
        );
    }
}

export default Common;
