import React from 'react';
import classes from './Download.module.scss';
import GooglePlayImg from '../assets/android.png';
import AppStoreImg from '../assets/apple.png';
import { isIOS, isAndroid } from 'react-device-detect';

const GOOGLE_PLAY_MOBILE = 'market://details?id=ru.ortus.connect';
const GOOGLE_PLAY_DESKTOP = 'https://play.google.com/store/apps/details?id=ru.ortus.connect';
const APP_STORE = 'https://itunes.apple.com/ru/app/id1319102237';

class Download extends React.Component {
    UNSAFE_componentWillMount() {
        if (isIOS) {
            window.location.replace(APP_STORE);
        }
        if (isAndroid) {
            window.location.replace(GOOGLE_PLAY_DESKTOP);
        }
    }

    render() {
        return (
            <div className={classes.download}>
                <div className={classes.head}>
                    <div className={classes.text}><span>Connect</span>Pro</div>
                </div>
                <div className={classes.body}>
                    <div className={classes.text}>Данное приложение доступно</div>
                    <div className={classes.links}>
                        <a href={isAndroid ? GOOGLE_PLAY_MOBILE : GOOGLE_PLAY_DESKTOP} className={classes.android}>
                            <img src={GooglePlayImg} alt='' />
                        </a>
                        <a href={APP_STORE} className={classes.other}>
                            <img src={AppStoreImg} alt='' />
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Download;
