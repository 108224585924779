import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import { formatPhone } from 'utils';

require('./List.scss');

class ClientsList extends React.Component {
    constructor(props) {
        super(props);
        this.scrollClientsList = this.scrollClientsList.bind(this);
    }

    state = {
        limit: 10// лимит разовой выгрузки клиентов
    };

    renderTrack({ style, ...props }) {
        const trackStyle = {};
        return (
            <div
                className='scrollTrack'
                style={{ ...style, ...trackStyle }}
                {...props}
            />
        );
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {};
        return (
            <div
                className='scrollThumb'
                style={{ ...style, ...thumbStyle }}
                {...props}
            />
        );
    }

    prevNextClientId(on) {
        let nextClient, indexOfClient;
        if (this.props.client.id && (indexOfClient = _.findIndex(this.props.clients.entities, { id: this.props.client.id })) !== -1) {
            if (on === 'next') {
                nextClient = _.get(_.find(_.slice(this.props.clients.entities, indexOfClient), client => {
                    return client.id !== this.props.client.id;
                }), 'id');
            }
            if (on === 'prev') {
                nextClient = _.get(_.findLast(_.slice(this.props.clients.entities, 0, indexOfClient), client => {
                    return client.id !== this.props.client.id;
                }), 'id');
            }
            if (!nextClient) {
                nextClient = _.get(_.find(this.props.clients.entities), 'id');
            }
        } else {
            nextClient = _.get(_.find(this.props.clients.entities), 'id');
        }
        return nextClient;
    }

    scrollClientsList() {
        const { searchClientsScroll } = this.refs;
        const scroll = searchClientsScroll.getValues();
        if (scroll.clientHeight + scroll.scrollTop === scroll.scrollHeight) {
            // догружаем клиентов
            if (_.get(this.props.clients, 'items.length') === this.props.clients.limit + this.props.clients.offset) { // проверяем, что возможно еще есть что догружать
                this.props.fetchSearchClients(this.props.clientsSearch, this.props.clients.offset + this.state.limit, this.state.limit, true);
            }
        }
    }

    scrollInactiveClientsList() {
        const { inactiveClientsScroll } = this.refs;
        const scroll = inactiveClientsScroll.getValues();
        if (scroll.clientHeight + scroll.scrollTop === scroll.scrollHeight) {
            // догружаем неактивированных клиентов
            const clients = this.props.inactiveClients;
            if (clients.entities.length === clients.limit + clients.offset) { // проверяем, что возможно еще есть что догружать
                this.props.fetchInactiveClients(clients.offset + this.state.limit, this.state.limit, true);
            }
        }
    }

    render() {
        /* let clients = _.get(this.props.clients, 'entities', []);
        if (clients.length === 0) {
            return null;
        } */
        const searchClientsList = [];
        if (this.props.clients) {
            _.map(this.props.clients.items, item => {
                searchClientsList.push(
                    <div
                        className={item.id === this.props.client.id ? 'client active' : 'client'}
                        key={'client' + item.id}
                        onClick={() => {
                            if (this.props.change) {
                                this.props.change(item);
                            } else {
                                this.props.fetchClient(item.id);
                            }
                            this.props.clearClientsBySearch();
                        }}
                        id={'client' + item.id}
                    >
                        { item.id === this.props.client.id ? <div className='triangleRight' /> : null }
                        <div className='name' title={item.client_companies[0].nick}>{ item.client_companies[0].nick }</div>
                        <div className='phone'>{ formatPhone(item.phone) }</div>
                    </div>
                );
            });
        } else {
            return null;
        }

        return (
            <div className={'ClientsList' + (this.props.modal ? ' modal' : '')}>
                <div className='ClientsList__clients'>
                    <Scrollbars
                        ref='searchClientsScroll'
                        style={{ height: '100%' }}
                        thumbSize={16}
                        autoHide
                        renderTrackVertical={this.renderTrack}
                        renderThumbVertical={this.renderThumb}
                        onScrollStop={this.scrollClientsList}
                        onWheel={event => {
                            if (event.deltaY > 0) {
                                const { searchClientsScroll } = this.refs;
                                const scroll = searchClientsScroll.getValues();
                                if (scroll.clientHeight !== scroll.scrollHeight && scroll.clientHeight + scroll.scrollTop === scroll.scrollHeight) {
                                    event.preventDefault();
                                }
                            }
                        }}
                    >
                        { searchClientsList }
                    </Scrollbars>
                </div>
            </div>
        );
    }
}

export default ClientsList;
