import { connect } from 'react-redux';
import { actions } from './../modules/cmtOrders';
import { showHeader } from 'modules/hangar';
import { fetchCompany } from 'modules/company';

import Orders from '../components/Orders/Orders';

const mapStateToProps = (state) => ({
    orders: state.cmtOrders.orders,
    client: state.cmtOrders.client,
    company: state.company.company,
    partner: state.account.partner,
    status: state.cmtOrders.status, // фильтр отображаемых заказов
    fetching: state.cmtOrders.fetching,
    page: state.cmtOrders.page,
    ordersDates: state.cmtOrders.ordersDates
});

export default connect(mapStateToProps, Object.assign(actions, { showHeader, fetchCompany }))(Orders);
