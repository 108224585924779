import { injectReducer } from '../../store/reducers';

export default (store) => ({
    path: 'callcenter',
    getComponent(nextState, cb) {
        Promise.all([
            import('./reducer'),
            import('./container')
        ]).then(([reducer, Callcenter]) => {
            injectReducer(store, { key: 'callcenter', reducer: reducer.default });
            /*  Return getComponent   */
            cb(null, Callcenter.default);
        });
    }
});
