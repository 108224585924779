/**
 * Created by user on 17.05.2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { enableUniqueIds } from 'react-html-id';
import { store } from '../../../src/index';
require('./renderInBody.scss');

class RenderInBody extends React.Component {
    constructor(props) {
        super(props);
        this.updateStyle = this.updateStyle.bind(this);
    }

    state = {
        parentPosition: null
    };

    updateInterval = 100;

    updateTimerId = null;

    UNSAFE_componentWillMount() {
        enableUniqueIds(this);
    }

    componentDidMount() {
        this.popup = document.createElement('div');
        document.body.insertBefore(this.popup, document.body.firstChild);
        document.body.appendChild(this.popup);
        this.updateStyle();
        // document.addEventListener('resize', this.updateStyle);
        // document.addEventListener('scroll', this.updateStyle);
        // this.updateTimerId = setInterval(this.updateStyle, this.updateInterval);
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (!this.props.hidden) {
            if (this.state.parentPosition && nextState &&
                JSON.stringify(this.state.parentPosition) !== JSON.stringify(nextState.parentPosition)) {
                this.props.closeHandler();
            }
        }
    }

    updateStyle() {
        let el;
        if (this.props.byId) {
            el = document.getElementById(this.props.byId);
        } else {
            el = document.getElementById(this.lastUniqueId());
        }
        if (el) {
            const rect = el.getBoundingClientRect();
            this.setState({
                parentPosition: {
                    top: rect.top + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0),
                    left: rect.left + (window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0),
                    width: rect.width,
                    height: rect.height
                }
            });
        }
    }

    componentDidUpdate() {
        this._renderLayer();
    }

    componentWillUnmount() {
        // clearInterval(this.updateTimerId);
        // document.removeEventListener('resize', this.updateStyle);
        // document.removeEventListener('scroll', this.updateStyle);
        ReactDOM.unmountComponentAtNode(this.popup);
        document.body.removeChild(this.popup);
    }

    UNSAFE_componentWillReceiveProps() {
        this.updateStyle();
    }

    _renderLayer() {
        let leftOffset = 0; let topOffset;
        if (this.state.parentPosition) {
            leftOffset = this.props.align && this.props.align[0] === 'right' ? this.state.parentPosition.width : (this.props.align && this.props.align[0] === 'center' ? this.state.parentPosition.width / 2 : 0);
            topOffset = this.props.align && this.props.align[1] === 'bottom' ? this.state.parentPosition.height : (this.props.align && this.props.align[0] === 'middle' ? this.state.parentPosition.height / 2 : 0);
        }

        const style = {
            position: 'absolute',
            pointerEvents: 'none',
            display: this.props.hidden ? 'none' : 'block',
            width: this.state.parentPosition ? this.state.parentPosition.width : 'auto',
            height: this.state.parentPosition ? this.state.parentPosition.height : 'auto',
            left: this.state.parentPosition ? (this.state.parentPosition.left + leftOffset) : '-100%',
            top: this.state.parentPosition ? (this.state.parentPosition.top + topOffset) : 'auto',
            zIndex: 1002

        };

        ReactDOM.render(
            <Provider store={store}>
                <div className='renderInBody' data-owner={this.lastUniqueId()}>
                    <div className='renderInBody__anchor' style={style}>{ this.props.children }</div>
                </div>
            </Provider>,
            this.popup
        );
    }

    render() {
        // Render a placeholder
        return (<div id={this.nextUniqueId()} className='renderInBody__placeHolder' />);
    }
}

/* static propTypes = {
    store: PropTypes.object.isRequired
} */

RenderInBody.propTypes = {
    closeHandler: PropTypes.func.isRequired
    // store: PropTypes.object.isRequired
};

RenderInBody.contextTypes = {
    store: PropTypes.object
};

export default RenderInBody;
