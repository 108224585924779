import React from 'react';
import PropTypes from 'prop-types';
import './modalWindowCL.styles.scss';
import { getOffsetRect } from '../../utils';
import cn from 'classnames';
import _ from 'lodash';

class ModalWindowCL extends React.Component {
    state = {};

    componentDidMount() {
        window.addEventListener('scroll', this.checkModalHandler);
        window.addEventListener('resize', this.checkModalHandler);
        this.checkModalPosition();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.checkModalHandler);
        window.removeEventListener('resize', this.checkModalHandler);
    }

    checkModalPosition() {
        // точки на чанке, к которым будет хорошо прикрепить модальное окно
        let chunkPoints = [
            { x: 12, y: 0, modalClass: 'topAlign', points: [] },
            { x: 25, y: 12, modalClass: 'rightAlign', points: [] },
            { x: 12, y: 25, modalClass: 'bottomAlign', points: [] },
            { x: 0, y: 12, modalClass: 'leftAlign', points: [] }
        ];
        // определяем размер окна браузера и координаты ценра вьюпорта
        const client_w = document.documentElement.clientWidth; const client_h = document.documentElement.clientHeight;

        const center_x = Math.round(client_w / 2);

        const center_y = Math.round(client_h / 2);

        const el = document.getElementById('modelWindowCL'); const chunk = el.parentNode;
        const chunkPos = getOffsetRect(chunk);
        // определяем ближайшую точку на чанке
        _.each(chunkPoints, point => {
            point.distance = Math.round(Math.sqrt(Math.pow(center_x - (chunkPos.boxLeft + point.x), 2) + Math.pow(center_y - (chunkPos.boxTop + point.y), 2)));
        });

        chunkPoints = _.orderBy(chunkPoints, ['distance'], ['asc']);
        this.setState({ modalClass: chunkPoints[0].modalClass });
    }

    static propTypes = {
        children: PropTypes.node.isRequired
    };

    render() {
        return (
            <div
                id='modelWindowCL'
                className={cn({ 'modalWindowCL finish failed': true, [this.state.modalClass]: this.state.modalClass })}
            >
                { this.props.children }
                <div className='triangle left' />
                <div className='triangle top' />
                <div className='triangle right' />
                <div className='triangle bottom' />
            </div>
        );
    }
}

export default ModalWindowCL;
