import { connect } from 'react-redux';
import { actions } from 'modules/auth';

import Login from './../components/Login';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => ({
    login: state.account.login,
    password: state.account.password,
    partner: state.account.partner,
    token: state.account.token,
    error: state.account.error,
    duringAuthorization: state.account.duringAuthorization
});

export default connect(mapStateToProps, actions)(withRouter(Login));
