import { connect } from 'react-redux';
import { actions } from '../../../modules/okk';

import Reports from '../ReportByAnswer';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => ({
    showPollCard: state.okk.showPollCard,
    pollCardData: state.okk.pollCardData,
    reportsByAnswer: state.okk.reportsByAnswer,
    fetching: state.okk.fetching,
    sort: state.okk.sort,
    statisticDates: state.okk.statisticDates
});

export default connect(mapStateToProps, actions)(withRouter(Reports));
