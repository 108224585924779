import { connect } from 'react-redux';
import { fetchClientsStream } from 'modules/clients';
import { chooseToOrder, fetchOrders, fetchReserves } from 'modules/orders';
import { changeDayTime, changeScrollToOrder } from 'modules/hangar';
import { fetchSchedules, changeDate } from 'modules/company';

import ClientsStream from './ClientsStream';

const mapStateToProps = (state) => ({
    clientsStream: state.clients.clientsStream,
    partner: state.account.partner,
    activityFetching: state.orders.fetching,
    toOrder: state.orders.toOrder,
    company: state.company.company,
    currentDay: state.company.currentDay,
    dayTime: state.hangar.dayTime,
    scrollToOrder: state.hangar.scrollToOrder,
    fetching: state.company.fetching.allSchedules || state.orders.fetching.reserves || state.orders.fetching.orders
});

export default connect(mapStateToProps, {
    fetchClientsStream,
    chooseToOrder,
    changeDayTime,
    fetchOrders,
    fetchReserves,
    fetchSchedules,
    changeDate,
    changeScrollToOrder
})(ClientsStream);
