import { api } from '../utils';

// ------------------------------------
// Constants
// ------------------------------------
const SET_USER = 'setUser';
const SET_RESTORED_USER = 'setRestoredUser';
const LOGOUT = 'logout';
const SET_ONESIGNAL_ID = 'setOnesignalId';
const CLEAR_AJAX_ERROR = 'clearAjaxError'; // modules/hangar
const CHANGE_VALUE = 'changeValue';
const CHANGE_REMIND = 'changeRemind';
const CHANGE_REMIND_ON_SEND_PHONE = 'changeRemindOnSendPhone';
const SET_ERROR = 'setError';
const SET_PHONE = 'setPhone';
const DURING_AUTHORIZATION = 'duringAuthorization';

const RESET_ERROR = 'resetError';
// ------------------------------------
// Actions
// ------------------------------------

export const submitPhone = (e) => {
    e.preventDefault();
    return (dispatch, getState) => {
        const state = getState().account;
        return api('/partner/password/restore', {
            method: 'post',
            body: JSON.stringify({ phone: state.phone.replace(/([^0-9+]*)/ig, '') })
        }, dispatch)
            .then(res => {
                if (res !== false && res.restoreData && res.restoreData.activationKey && res.restoreData.id) {
                    dispatch(changeRemindOnSendPhone(res.restoreData));
                } else if (getState().hangar.ajaxError) {
                    dispatch(setError(getState().hangar.ajaxError.response.message));
                }
            });
    };
};

export const submitCode = (e) => {
    e.preventDefault();
    return (dispatch, getState) => {
        const state = getState().account;

        const request = api('/partner/password/confirm', {
            method: 'post',
            body: JSON.stringify({
                ownerId: state.partnerId,
                activationCode: state.activationCode,
                activationKey: state.activationKey
            })
        }, dispatch)
            .then(res => {
                if (res) {
                    dispatch(setUser(res));
                } else if (getState().hangar.ajaxError) {
                    dispatch(setError(getState().hangar.ajaxError.response.message));
                }
            });

        return request;
    };
};

export const setUser = (tokenAndUser) => {
    return {
        type: SET_USER,
        payload: tokenAndUser
    };
};

export const setError = (message) => {
    return {
        type: SET_ERROR,
        payload: message
    };
};

export const submit = (e) => {
    e.preventDefault();
    return (dispatch, getState) => {
        const state = getState().account;
        dispatch({ type: DURING_AUTHORIZATION });
        return api('/partner/login', {
            method: 'post',
            body: JSON.stringify({ phone: state.login.trim().replace(/([^0-9+]*)/ig, ''), password: state.password.trim() })

        }, dispatch)
            .then(res => {
                if (res !== false) {
                    dispatch(clearAjaxError());
                    dispatch((setUser(res)));
                } else {
                    dispatch(setError());
                }
            });
    };
};

export const changeRemindOnSendPhone = (res) => {
    return {
        type: CHANGE_REMIND_ON_SEND_PHONE,
        reminded: 'sendCode',
        activationKey: res.activationKey,
        partnerId: res.id
    };
};
export const changeRemind = (remindStatus) => {
    return {
        type: CHANGE_REMIND,
        reminded: remindStatus
    };
};

export const clearAjaxError = () => {
    return {
        type: CLEAR_AJAX_ERROR
    };
};

export const changeValue = (e) => {
    return {
        type: CHANGE_VALUE,
        payload: {
            name: e.target.name,
            value: e.target.value
        }
    };
};

export const fetchLogout = () => {
    return function(dispatch) {
        return api('/partner/logout', {
            method: 'GET'
        }, dispatch)
            .then(() => dispatch(logout()));
    };
};

export const logout = () => {
    return {
        type: LOGOUT
    };
};

export const setPhone = (phone) => {
    return {
        type: SET_PHONE,
        payload: phone
    };
};

export const storeOnesignalId = (id) => {
    return (dispatch) => {
        return api('/v3/partner/devices', {
            method: 'POST',
            body: JSON.stringify({ player: id })
        }, dispatch)
            .then(res => {
                if (res) {
                    dispatch(setOnesignalId(id));
                }
            });
    };
};

function setOnesignalId(id) {
    return {
        type: SET_ONESIGNAL_ID,
        payload: id
    };
}

export const resetError = () => {
    return {
        type: RESET_ERROR
    };
};

export const actions = {
    changeValue,
    setUser,
    setError,
    setPhone,
    submit,
    submitPhone,
    changeRemind,
    submitCode,
    fetchLogout,
    resetError
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [CHANGE_VALUE]: (state, action) => {
        return ({ ...state, error: false, [action.payload.name]: action.payload.value });
    },
    [DURING_AUTHORIZATION]: (state) => {
        return ({ ...state, duringAuthorization: true });
    },
    [SET_USER]: (state, action) => {
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem('partner', JSON.stringify(action.payload.partner));
        window.location = '/';
        return ({ ...state, partner: action.payload.partner, token: action.payload.token, duringAuthorization: false });
    },
    [LOGOUT]: (state) => {
        localStorage.removeItem('token');
        localStorage.removeItem('partner');
        return ({ ...state, partner: null, token: null });
    },
    [SET_ERROR]: (state, action) => {
        return ({ ...state, error: { message: action.payload }, password: '', duringAuthorization: false });
    },
    [CHANGE_VALUE]: (state, action) => {
        return ({ ...state, [action.payload.name]: action.payload.value, error: null });
    },
    [CHANGE_REMIND]: (state, action) => {
        return ({ ...state, reminded: action.reminded, error: null });
    },
    [CHANGE_REMIND_ON_SEND_PHONE]: (state, action) => {
        return ({
            ...state,
            reminded: action.reminded,
            partnerId: action.partnerId,
            activationKey: action.activationKey,
            error: null
        });
    },
    [SET_RESTORED_USER]: (state, action) => {
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem('partner', JSON.stringify(action.payload.partner));
        return ({ ...state });
    },
    [SET_PHONE]: (state, action) => {
        return ({
            ...state,
            phone: action.payload
        });
    },
    [RESET_ERROR]: (state) => {
        return ({ ...state, error: null });
    }
};

const initialState = {
    login: '',
    password: '',
    partner: null,
    token: null,
    phone: '',
    activationKey: null,
    activationCode: '',
    partnerId: '',
    reminded: 'sendPhone', // sendPhone, sendCode, sendPassword
    duringAuthorization: false,
    error: null
};

const partner = window.localStorage.getItem('partner');
const token = window.localStorage.getItem('token');
if (partner && token) {
    Object.assign(initialState, { partner: JSON.parse(partner), token: token });
}

export default function loginReducer(state = initialState, action) {
    state = Object.assign({}, initialState, state);

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
