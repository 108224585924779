/**
 * Created by user on 07.10.16.
 */
import React from 'react';
import classes from './Order.module.scss';
import Button from 'components/Button';
import { getBlockPosition } from 'utils';
import moment from 'moment';
import _ from 'lodash';

class OrderModal extends React.Component {
    formatServiceDuration(duration) {
        const hours = parseInt(duration / 60);

        const minutes = duration - hours * 60;

        let result;

        result = hours ? hours + ' ч. ' : '';
        result += minutes ? +minutes + ' минут' : '';
        return result;
    }

    render() {
        const status = {
            ACTIVE: 'Активный',
            EXECUTED: 'Выполнен',
            CANCELED: 'Отменен'
        };

        const services = (
            <div className={classes.services}>
                <div className={classes.title}>Услуги</div>
                <div>
                    { _.map(this.props.order.orderDetails, service => {
                        return (
                            <div className={classes.service} key={'service' + service.id}>
                                <div className={classes.name}>{ service.service.serviceTemplate.name }</div>
                                <div className={classes.duration}>
                                    { this.formatServiceDuration(service.service.averageDuration) }
                                </div>
                            </div>
                        );
                    }) }
                </div>
            </div>
        );

        return (
            <div className={classes.black} onClick={this.props.shadeMenuChange.bind(this, null)}>
                <div className={classes.wrapper} style={getBlockPosition(666, 690)} onClick={e => { e.stopPropagation(); }}>
                    <div className={classes.order}>
                        <div className={classes.header}>
                            Карточка посещения
                        </div>
                        <div className={classes.body}>
                            <div className={classes.row}>
                                <div className={classes.item}>
                                    <div className={classes.title}>Клиент</div>
                                    <div className={classes.orange}>{ this.props.client.nick },</div>
                                    <div className={classes.phone}>{ this.props.client.phone }</div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.title}>Дата и время посещения</div>
                                    <div className={classes.orange + ' ' + classes.inlineBlock}>
                                        { moment(this.props.order.orderDetails[0].scheduledTo).format('DD MMMM \'YY') },
                                    </div>
                                    <div className={classes.green + ' ' + classes.inlineBlock}>
                                        &nbsp;{ moment(this.props.order.orderDetails[0].scheduledTo).format('HH:mm') }
                                    </div>
                                </div>
                            </div>
                            <div className={classes.row}>
                                <div className={classes.item}>
                                    <div className={classes.title}>Причина посещения</div>
                                    <div>Оказание услуг</div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.title}>Статус</div>
                                    <div>{ status[this.props.order.status] }</div>
                                </div>
                            </div>
                            { services }
                            <div className={classes.comment}>{ this.props.order.descr }</div>
                            <div className={classes.btnContainer}>
                                <Button
                                    value='Закрыть карточку'
                                    className='save'
                                    onClick={this.props.shadeMenuChange.bind(this, null)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OrderModal;
