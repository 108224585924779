/**
 * Created by user on 19.07.16.
 */
import { connect } from 'react-redux';
import { fetchClientAvatar, changeSelectField, deleteClientAvatar, editClient, updateClient, changeClient, addClientAvatar } from 'modules/clients';

import MainInfo from './MainInfo';

const mapStateToProps = (state) => ({
    client: state.clients.client,
    avatar: state.clients.avatar,
    editClientChange: state.clients.editClientChange,
    changedClient: state.clients.changedClient
});

export default connect(mapStateToProps, Object.assign({}, {
    fetchClientAvatar,
    changeSelectField,
    deleteClientAvatar,
    editClient,
    updateClient,
    changeClient,
    addClientAvatar
}))(MainInfo);
