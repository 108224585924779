import { connect } from 'react-redux';
import { fetchCompany, fetchResources, saveCompany } from 'modules/company';
import { showHeader } from 'modules/hangar';
import { actions } from '../modules/settings';

import Main from '../Main';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => ({
    currentMode: state.settings.currentMode,
    currentEdited: state.settings.currentEdited,
    _workTime: state.settings.workTime,
    partner: state.account.partner,
    company: state.company.company,
    resources: state.company.resources,
    fetching: state.company.fetching.allSchedules ||
        state.company.fetching.company ||
        state.company.fetching.sendOrder ||
        state.company.fetching.services ||
        (state.services && state.services.fetching)
});

export default connect(mapStateToProps, Object.assign(actions, {
    fetchCompany, fetchResources, saveCompany, showHeader
}))(withRouter(Main));
