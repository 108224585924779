import { injectReducer } from 'store/reducers';

export default (store) => ({
    path: ':id',
    getComponent(nextState, cb) {
        Promise.all([
            import('../modules/feedback'),
            import('./containers/feedbackContainer')
        ]).then(([reducer, /* webpackChunkName: "feedbackItem" */FeedbackContainer]) => {
            injectReducer(store, { key: 'feedback', reducer: reducer.default });
            /*  Return getComponent   */
            cb(null, FeedbackContainer.default);
        });
    }
});
