import React from 'react';
import moment from 'moment';
import Dp, { registerLocale, setDefaultLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { handleClickOutside } from 'utils';
import RenderInBody from '../RenderInBody';

import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.scss';
import ReactDOM from 'react-dom';

class DatePicker extends React.Component {
    state = {
        open: false,
        randomId: 'dataPicker' + Math.floor(Math.random() * 9000 + 1000)
    };

    componentDidMount() {
        this.onDocumentClick = this.onDocumentClick.bind(this);
        document.addEventListener('click', this.onDocumentClick, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onDocumentClick);
    }

    onDocumentClick(event) {
        if (this.state.open && handleClickOutside(event, ReactDOM.findDOMNode(this))) {
            this.close();
        }
    }

    denyClosePicker(e) {
        e.stopPropagation();
    }

    componentDidUpdate() {
    }

    UNSAFE_componentWillReceiveProps() {
        if (this.state.open) {
            this.dpSwitcher();
        }
    }

    dpSwitcher(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({ open: !this.state.open });
    }

    change(date) {
        this.props.onChange(date);
    }

    close() {
        this.setState({ open: false });
    }

    render() {
        registerLocale('ru', ru);
        setDefaultLocale('ru');
        // moment.locale('ru');
        const currentDay = this.props.selected ? moment(this.props.selected).startOf('date') : moment().startOf('date');

        const referenceTime = currentDay.calendar(null, {
            sameDay: '(Сегодня)',
            nextDay: '(Завтра)',
            lastDay: '(Вчера)',
            nextWeek: '[]',
            lastWeek: '[]',
            sameElse: '[]'
        });

        const { openByDateClick, dateFormatCalendar, activeDate } = this.props;

        return (
            <div className={'club-datepicker ' + this.props.className}>
                <div
                    className='club-datepicker__leftArrow'
                    onClick={this.change.bind(this, moment(this.props.selected ? this.props.selected : null).add(-1, 'd'))}
                />
                <div className={'text' + (activeDate ? ' active' : '')} id={openByDateClick ? this.state.randomId : null} onClick={openByDateClick || activeDate ? this.dpSwitcher.bind(this) : null}>
                    { this.props.stringFormatting ? (
                        this.props.stringFormatting(currentDay)
                    )
                        : (
                            <div>
                                { this.props.selected
                                    ? currentDay.format('DD MMMM`YY')
                                    : (this.props.placeholder
                                        ? this.props.placeholder
                                        : moment().format('DD MMMM`YY')) }
                                { this.props.selected || !this.props.placeholder
                                    ? (<span>&nbsp;{ referenceTime }</span>)
                                    : null }
                            </div>
                        ) }
                </div>
                <div id={this.state.randomId} className={'dpSwitcher' + (this.state.open ? ' opened' : '')} onClick={this.dpSwitcher.bind(this)} />
                <div
                    className='club-datepicker__rightArrow'
                    onClick={this.change.bind(this, moment(this.props.selected ? this.props.selected : null).add(1, 'd'))}
                />
                { this.state.open
                    ? (
                        <RenderInBody
                            byId={this.state.randomId}
                            align={[(this.props.align && this.props.align[0]) ? this.props.align[0] : 'right', (this.props.align && this.props.align[1]) ? this.props.align[1] : 'top']}
                            hidden={!this.state.open}
                            closeHandler={this.close.bind(this)}
                        >
                            <div className='datePicker-wrapper' onClick={this.denyClosePicker.bind(this)}>
                                <Dp
                                    dateFormatCalendar={dateFormatCalendar || 'LLLL`yyyy'}
                                    inline
                                    className='schdulePicker'
                                    selected={new Date(this.props.selected) ? new Date(moment(this.props.selected).startOf('date')) : null}
                                    onChange={(date) => this.props.onChange(moment(date))}
                                    minDate={this.props.minDate ? this.props.minDate : null}
                                    maxDate={this.props.maxDate ? this.props.maxDate : null}
                                    showYearDropdown={!!this.props.showYearDropdown}
                                />
                            </div>
                        </RenderInBody>
                    )
                    : null }
            </div>
        );
    }
}

export default DatePicker;
