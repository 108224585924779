import { connect } from 'react-redux';
import { actions } from 'modules/events';
import { chooseToOrder, fetchOrders, fetchReserves } from 'modules/orders';
import { changeDayTime } from 'modules/hangar';
import { fetchSchedules, changeDate } from 'modules/company';
import { fetchClient } from 'modules/clients';

import Events from './Events';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => ({
    partner: state.account.partner,
    events: state.events.events,
    offset: state.events.offset,
    eventsType: state.events.eventsType,
    lastCall: state.events.lastCall,
    readEvents: state.events.readEvents,
    iReadIt: state.events.iReadIt,
    currentDay: state.company.currentDay,
    company: state.company.company,
    orders: state.orders.orders,
    reserves: state.orders.reserves,
    toOrder: state.orders.toOrder,
    dayTime: state.hangar.dayTime,
    scrollToOrder: state.hangar.scrollToOrder,
    fetching: state.company.fetching.allSchedules || state.orders.fetching.reserves || state.orders.fetching.orders
});

export default connect(mapStateToProps, Object.assign(actions, {
    chooseToOrder,
    fetchOrders,
    fetchReserves,
    changeDayTime,
    fetchSchedules,
    changeDate,
    fetchClient
}))(withRouter(Events));
