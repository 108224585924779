import { connect } from 'react-redux';
import { actions } from './reducer';
import { fetchCalls, fetchProcessCall } from '../../modules/calls';
import { fetchEmployees } from '../../modules/company';

import Main from './components/Main';

const mapStateToProps = (state) => ({
    partner: state.account.partner,
    navigate: state.callcenter.navigate,
    content: state.callcenter.content,
    calls: state.calls.calls,
    call: state.calls.call
});

export default connect(mapStateToProps, Object.assign(actions, { fetchEmployees, fetchCalls, fetchProcessCall }))(Main);
