import React from 'react';
import classes from './ChangePassword.module.scss';
import Input from 'components/Input';
import Button from 'components/Button';
import { getBlockPosition } from 'utils';
import _ from 'lodash';

class ChangePassword extends React.Component {
    UNSAFE_componentWillMount() {
        this.props.clearAjaxError();
    }

    componentWillUnmount() {
        this.props.clearAjaxError();
    }

    render() {
        let error = null;
        if (this.props.ajaxError) {
            error = _.get(this.props.ajaxError, 'response.message', null);
        }
        return (
            <div>
                <div className={classes.black} onClick={this.props.passwordMenuShow.bind(this)} />
                <div className={classes.wrapper} style={getBlockPosition(418, 585)}>
                    <div className={classes.password}>
                        <div className={classes.icon} />
                        <div className={classes.gradient} />
                        <div className={classes.main}>
                            <div className={classes.header}>Смена пароля</div>
                            <div className={classes.body}>
                                <div className={classes.raw}>
                                    <div className={classes.text}>Старый пароль:</div>
                                    <Input
                                        type='password'
                                        name='currentPass'
                                        value={this.props.password.currentPass}
                                        onChange={this.props.changePassInput}
                                        autoFocus='true'
                                    />
                                    <div className='error-block'>
                                        { this.props.showErrors ? error : '' }
                                    </div>
                                </div>
                                <div className={classes.raw}>
                                    <div className={classes.text}>Новый пароль:</div>
                                    <Input
                                        type='password'
                                        name='newPass'
                                        className={this.props.checkPassStatus}
                                        value={this.props.password.newPass}
                                        onChange={this.props.changePassInput}
                                        onBlur={this.props.confirmPassCheck.bind(this, this.props.password)}
                                    />
                                </div>
                                <div className={classes.raw}>
                                    <div className={classes.text}>Подтверждение:</div>
                                    <Input
                                        type='password'
                                        name='confirmPass'
                                        className={this.props.checkPassStatus}
                                        value={this.props.password.confirmPass}
                                        onChange={this.props.changePassInput}
                                        onBlur={this.props.confirmPassCheck.bind(this, this.props.password)}
                                    />
                                </div>
                                <div className={classes.buttons}>
                                    <div className={classes.cancel} onClick={this.props.passwordMenuShow.bind(this)}>oтмена</div>
                                    <Button
                                        value='Изменить пароль'
                                        className={this.props.checkPassStatus === 'success' && this.props.password.currentPass ? 'change' : 'blockedChange'}
                                        onClick={this.props.checkPassStatus === 'success' && this.props.password.currentPass
                                            ? this.props.passUpdate.bind(this, this.props.password)
                                            : this.props.confirmPassCheck.bind(this, this.props.password)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangePassword;
