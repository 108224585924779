import { api, getPeriodTitle, httpBuildQuery } from 'utils';
import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
const REQUEST_CLIENT_AVATAR = 'requestClientAvatar';
const RECEIVE_CLIENT_AVATAR = 'receiveClientAvatar';

const DELETE_CLIENT_AVATAR = 'deleteClientAvatar';

const ADD_CLIENT_AVATAR = 'addClientAvatar';

const CLEAR_NEW_CLIENT_DATA = 'clearNewClientData';

const CHANGE_VALUE = 'changeValue';

const REQUEST_SEARCH_CLIENTS = 'requestSearchClients';
const RECEIVE_SEARCH_CLIENTS = 'receiveSearchClients';

const ERROR = 'error';

const REQUEST_CLIENT = 'requestClient';
const RECEIVE_CLIENT = 'receiveClient';
const SHOW_CLIENT_CARD = 'showClientCard';
const DELETE_LOCAL_STORAGE_CLIENT = 'deleteLocalStorageClient';

const EDIT_CLIENT_CHANGE = 'editClient';
const CHANGE_CLIENT = 'changeClient';
const EDIT_CLIENTS_NICK = 'editClientsNick';
const CHANGE_FIELD = 'changeField';
const SHADE_MENU_CHANGE = 'shadeMenuChange';
const CLEAR_CLIENTS = 'clearClients';
const CLEAR_CLIENTS_BY_SEARCH = 'clearClientsBySearch';
const CYCLIC_NOTIFICATION_EDIT = 'cyclicNotificationEdit';
const CYCLIC_NOTIFICATION_CHANGE = 'cyclicNotificationChange';
const CYCLIC_NOTIFICATION_SHOW = 'cyclicNotificationShow';
const CHANGE_CLIENT_NOTIFICATIONS = 'changeClientNotifications';
const ERROR_MENU_CHANGE = 'errorMenuChange';
const REQUEST_CLIENT_ORDERS_AND_CALLS = 'requestClientOrdersAndCalls';
const RECEIVE_CLIENT_ORDERS_AND_CALLS = 'receiveClientOrdersAndCalls';

const CHECK_TOOLTIP = 'checkTooltip';

const REQUEST_CLIENT_CARS = 'requestClientCars';
const RECEIVE_CLIENT_CARS = 'receiveClientCars';
const RECEIVE_CLIENT_CAR = 'receiveClientCar';
const CHANGE_CLIENT_CARS = 'changeClientCars';
const CHANGE_CLIENT_CAR_MARK = 'updateClientCarMark';
const CLEAR_CLIENT_CARS = 'clearClientCars';

const REMOVE_CLIENT_CAR = 'removeClientCar';

const REQUEST_CAR_REFERENCES = 'requestCarReferences';
const RECEIVE_CAR_REFERENCES = 'receiveCarReferences';
const RECEIVE_CAR_MODELS = 'receiveCarModels';

const REQUEST_CLIENTS_STREAM = 'requestClientsStream';
const RECEIVE_CLIENTS_STREAM = 'receiveClientsStream';

const CREATING_NOTIFICATION = 'creatingNotification';
const NOTIFICATION_IS_CREATED = 'notificationIsCreated';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchClientsStream = (companyId, data) => {
    return function(dispatch) {
        dispatch(requestClientsStream());
        return api('/v1/partner/orders/company/' + companyId + '?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(ordersResponse => {
                return api('/v1/partner/reserves?' + httpBuildQuery(data, '', '&'), {
                    method: 'GET'
                }, dispatch)
                    .then(reservesResponse => dispatch(receiveClientsStream(ordersResponse, reservesResponse)));
            });
    };
};

function requestClientsStream() {
    return {
        type: REQUEST_CLIENTS_STREAM
    };
}

function receiveClientsStream(ordersResponse, reservesResponse) {
    return {
        type: RECEIVE_CLIENTS_STREAM,
        payload: { ordersResponse, reservesResponse }
    };
}

export const fetchSearchClients = (search, offset, limit, merge) => {
    const queryData = {};
    if (search) {
        queryData.data = search.trim();
    }
    if (limit) {
        queryData.limit = limit;
    }
    if (offset) {
        queryData.offset = offset;
    } else {
        queryData.offset = 0;
    }

    return function(dispatch) {
        dispatch(requestSearchClients());
        return api('/v3/partner/clients/search?' + httpBuildQuery(queryData), {
            method: 'GET',
            headers: { 'X-Api-Token': localStorage.getItem('token') }
        }, dispatch)
            .then(response => dispatch(receiveSearchClients(response, queryData, merge)));
    };
};

function requestSearchClients() {
    return {
        type: REQUEST_SEARCH_CLIENTS
    };
}

function receiveSearchClients(res, queryData, merge) {
    return {
        type: RECEIVE_SEARCH_CLIENTS,
        res: res,
        queryData: queryData,
        merge: merge
    };
}

// получение клиента
export const fetchClient = (id) => {
    return function(dispatch) {
        dispatch(requestClient(id));
        return api('/v1/partner/clients/' + id, {
            method: 'GET',
            headers: { 'X-Api-Token': localStorage.getItem('token') }
        }, dispatch)
            .then(response => {
                if (response) {
                    dispatch(receiveClient(response));
                } else {
                    dispatch(deleteLocalStorageClient(id));
                }
            });
    };
};

export const deleteLocalStorageClient = (id) => {
    return {
        type: DELETE_LOCAL_STORAGE_CLIENT,
        payload: id
    };
};

function requestClient(id) {
    return {
        payload: id,
        type: REQUEST_CLIENT
    };
}

function receiveClient(res) {
    return {
        type: RECEIVE_CLIENT,
        res: res
    };
}

// функция обнуления статуста зазрузки клиента дабы не открывать повторно панель с оным клиентом
export const showClientCard = (open) => {
    return function(dispatch) {
        dispatch({ type: SHOW_CLIENT_CARD, open: open });
    };
};

// получение клиентского аватара ========================================================================================
export const fetchClientAvatar = (id) => {
    return function(dispatch) {
        dispatch(requestClientAvatar());
        return api('/v3/partner/clients/' + id + '/avatar', {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveClientAvatar(response)));
    };
};

function requestClientAvatar() {
    return {
        type: REQUEST_CLIENT_AVATAR
    };
}

function receiveClientAvatar(data) {
    return {
        type: RECEIVE_CLIENT_AVATAR,
        data: data
    };
}

// удаление клиентского аватара =========================================================================================
export const deleteClientAvatar = (id) => {
    return function(dispatch) {
        return api('/v3/partner/clients/' + id + '/avatar', {
            method: 'DELETE'
        }, dispatch)
            .then(response => dispatch(clientAvatarIsDeleted(response)));
    };
};

function clientAvatarIsDeleted() {
    return {
        type: DELETE_CLIENT_AVATAR
    };
}

export const changeValue = (e) => {
    return {
        type: CHANGE_VALUE,
        payload: {
            name: e.target.name,
            value: e.target.value
        }
    };
};

export const changeClient = (e) => {
    return {
        type: CHANGE_CLIENT,
        name: e.target.name,
        value: e.target.value
    };
};

export const shadeMenuChange = (type) => {
    return {
        type: SHADE_MENU_CHANGE,
        payload: type
    };
};

export const editClient = (field, e) => {
    if (e) {
        // e.preventDefault();
        e.stopPropagation();
    }
    return {
        type: EDIT_CLIENT_CHANGE,
        payload: field
    };
};

export const editClientsNick = (nick) => {
    return {
        type: EDIT_CLIENTS_NICK,
        payload: nick
    };
};

export const createClient = (e) => {
    if (e) {
        e.preventDefault();
    }
    return (dispatch, getState) => {
        const state = getState().clients;
        return api('/v3/partner/clients/', {
            method: 'POST',
            body: JSON.stringify({
                origin: 'clubPartner',
                phone: state.newClientPhone.replace(/([^0-9+]*)/ig, ''),
                nick: state.newClientNick.trim(),
                message_name: state.newClientMessageName.trim(),
                comment: state.newClientComment.trim()
            })
        }, dispatch)
            .then(res => {
                if (res.id) {
                    dispatch(fetchClient(res.id));
                    dispatch(shadeMenuChange());
                    dispatch(clearNewClientData());
                    return true;
                }
            });
    };
};

function clearNewClientData() {
    return {
        type: CLEAR_NEW_CLIENT_DATA
    };
}

export const updateClient = (id) => {
    return (dispatch, getState) => {
        const state = getState().clients;
        const clientData = {
            nick: state.changedClient.nick,
            message_name: state.changedClient.message_name,
            fields: state.changedClient.fields
        };
        if (state.changedClient.comment) {
            clientData.comment = state.changedClient.comment;
        }
        return api('/v1/partner/clients/' + id, {
            method: 'PUT',
            body: JSON.stringify(clientData)
        }, dispatch)
            .then(res => {
                if (res.client) {
                    dispatch(editClient());
                } else {
                    dispatch(error(res));
                }
            })
            .then(dispatch(editClientsNick(state.changedClient)));
    };
};

export const updateClientV2 = (data) => {
    return (dispatch) => {
        return api('/v1/partner/clients/' + data.id, {
            method: 'PUT',
            body: JSON.stringify(data)
        }, dispatch)
            .then(res => {
                if (res.client) {
                    // dispatch(editClient());
                } else {
                    dispatch(error(res));
                }
            });
    };
};

export const activateClient = (id) => {
    return (dispatch, getState) => {
        const state = getState().clients;
        const fields = [];
        _.map(state.client.fields, field => {
            if (field.value) {
                fields.push(field);
            } else {
                field.value = '';
                fields.push(field);
            }
        });
        return api('/v1/partner/clients/' + id + '/activate', {
            method: 'PUT',
            body: JSON.stringify({
                fields: fields
            })
        }, dispatch)
            .then(res => {
                if (res.client) {
                    dispatch(fetchClient(id));
                    dispatch(shadeMenuChange());
                } else {
                    dispatch(error(res));
                }
            });
    };
};

export const searchClientsByNickAndPhone = (request, merge = false) => {
    request = _.extend({
        limit: 1000,
        offset: 0
    }, request);
    return function(dispatch) {
        dispatch(requestSearchClients());
        return api('/v3/partner/clients/search-by-nick-and-phone?' + httpBuildQuery(request, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(res => {
                return dispatch(receiveSearchClients(res, merge));
            });
    };
};

export const error = (res) => {
    return {
        type: ERROR,
        payload: res
    };
};

export const changeField = (value, field) => {
    return {
        type: CHANGE_FIELD,
        payload: {
            value: value,
            field: field
        }
    };
};

export const changeSelectField = (value, field, clientId) => {
    return (dispatch) => {
        dispatch(changeField(value, field));
        dispatch(updateClient(clientId));
    };
};

export const clearClients = () => {
    return {
        type: CLEAR_CLIENTS
    };
};

export const clearClientsBySearch = () => {
    return {
        type: CLEAR_CLIENTS_BY_SEARCH
    };
};

export const cyclicNotificationShow = (notification) => {
    return {
        type: CYCLIC_NOTIFICATION_SHOW,
        payload: notification

    };
};

export const cyclicNotificationEdit = (field, value, notifications) => {
    return {
        type: CYCLIC_NOTIFICATION_EDIT,
        payload: {
            field: field,
            value: value,
            notifications: notifications
        }
    };
};

export const cyclicNotificationChange = (e) => {
    return {
        type: CYCLIC_NOTIFICATION_CHANGE,
        payload: {
            name: e.target.name,
            value: e.target.value
        }
    };
};

export const updateCyclicNotification = (notification) => {
    return (dispatch, getState) => {
        return api('/v1/partner/notifications/' + notification.id, {
            method: 'PUT',
            body: JSON.stringify({
                client_id: notification.client_id,
                service_template_id: notification.serviceTemplate.id,
                service_id: notification.service_id,
                period: notification.period.length,
                repeats_during_period: notification.repeats_during_period,
                text: notification.text,
                next_notified_at: notification.next_notified_at
            })
        }, dispatch)
            .then(res => {
                if (res.notifications) {
                    dispatch(changeClientNotifications(notification));
                    dispatch(shadeMenuChange());
                } else if (getState().hangar.ajaxError) {
                    dispatch(error(getState().hangar.ajaxError.response.message));
                } else {
                    // ToDo Set SentryError
                    console.log(res);
                }
            });
    };
};

export const createCyclicNotification = (data) => {
    return (dispatch, getState) => {
        dispatch({ type: CREATING_NOTIFICATION });
        const id = getState().clients.client.id;
        return api('/v1/partner/notifications/', {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(res => {
                if (res.notifications) {
                    // TODO зачем столько диспатчей
                    dispatch(fetchClient(id));
                    dispatch({ type: NOTIFICATION_IS_CREATED });
                    dispatch(shadeMenuChange());
                } else if (getState().hangar.ajaxError) {
                    dispatch(error(getState().hangar.ajaxError.response.message));
                } else {
                    // ToDo Set SentryError
                    console.log(res);
                }
            });
    };
};

export const deleteCyclicNotification = (notification) => {
    return function(dispatch, getState) {
        return api('/v1/partner/notifications/' + notification.id, {
            method: 'DELETE',
            headers: { 'X-Api-Token': localStorage.getItem('token') }
        }, dispatch)
            .then(res => {
                if (!_.isUndefined(res.notifications)) {
                    dispatch(changeClientNotifications(notification, 'delete'));
                    dispatch(shadeMenuChange());
                } else if (getState().hangar.ajaxError) {
                    dispatch(error(getState().hangar.ajaxError.response.message));
                } else {
                    // ToDo Set SentryError
                    console.log(res);
                }
            });
    };
};

export const changeClientNotifications = (notification, label) => {
    return {
        type: CHANGE_CLIENT_NOTIFICATIONS,
        payload: {
            notification: notification,
            label: label
        }
    };
};

export const errorMenuChange = (field, e) => {
    e.stopPropagation();
    return {
        type: ERROR_MENU_CHANGE,
        payload: field
    };
};

export const fetchClientOrdersAndCalls = (id, data) => {
    return function(dispatch) {
        dispatch(requestClientOrdersAndCalls());
        return api('/v1/partner/clients/' + id + '/history?' + httpBuildQuery(data, '', '&'), {
            method: 'GET',
            headers: { 'X-Api-Token': localStorage.getItem('token') }
        }, dispatch)
            .then(response => dispatch(receiveClientOrdersAndCalls(response)));
    };
};

function requestClientOrdersAndCalls() {
    return {
        type: REQUEST_CLIENT_ORDERS_AND_CALLS
    };
}

function receiveClientOrdersAndCalls(res) {
    return {
        type: RECEIVE_CLIENT_ORDERS_AND_CALLS,
        payload: res
    };
}

export const checkTooltip = (nameWidth, maxWidth, label) => {
    return {
        type: CHECK_TOOLTIP,
        payload: {
            nameWidth: nameWidth,
            maxWidth: maxWidth,
            label: label
        }
    };
};

export const fetchClientCars = (id) => {
    return function(dispatch) {
        dispatch(requestClientCars());
        return api('/v3/partner/clients/cars?client_id=' + id, {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveClientCars(response)));
    };
};

function requestClientCars() {
    return {
        type: REQUEST_CLIENT_CARS
    };
}

function receiveClientCars(res) {
    return {
        type: RECEIVE_CLIENT_CARS,
        payload: res
    };
}

export const changeClientCars = (id, e) => {
    e.stopPropagation();
    return {
        type: CHANGE_CLIENT_CARS,
        payload: {
            name: e.target.name,
            value: e.target.value,
            id: id
        }
    };
};

export const fetchCarReferences = () => {
    return function(dispatch) {
        dispatch(requestCarReferences());
        return api('/v1/references/cars', {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveCarReferences(response)));
    };
};

export const fetchCarMarks = () => {
    return function(dispatch) {
        dispatch(requestCarReferences());
        return api('/v1/references/cars/marks', {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveCarReferences(response)));
    };
};

export const fetchCarModels = (markId) => {
    return function(dispatch) {
        dispatch(requestCarReferences());
        return api('/v1/references/cars/models?mark_id=' + markId, {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveCarModels(response)));
    };
};

export const fetchCarEngines = () => {
    return function(dispatch) {
        dispatch(requestCarReferences());
        return api('/v1/references/cars/engines', {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveCarReferences(response)));
    };
};

export const fetchCarBodies = () => {
    return function(dispatch) {
        dispatch(requestCarReferences());
        return api('/v1/references/cars/bodies', {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveCarReferences(response)));
    };
};

export const fetchCarTransmissions = () => {
    return function(dispatch) {
        dispatch(requestCarReferences());
        return api('/v1/references/cars/transmissions', {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveCarReferences(response)));
    };
};

function requestCarReferences() {
    return {
        type: REQUEST_CAR_REFERENCES
    };
}

function receiveCarReferences(res) {
    return {
        type: RECEIVE_CAR_REFERENCES,
        payload: res
    };
}

function receiveCarModels(res) {
    return {
        type: RECEIVE_CAR_MODELS,
        payload: res
    };
}

export const updateClientCars = (label, value, id) => {
    return (dispatch, getState) => {
        return api('/v1/partner/clients/cars/' + id, {
            method: 'PUT',
            body: JSON.stringify({
                [label]: value
            })
        }, dispatch)
            .then(res => {
                if (res && res.car) {
                    return dispatch(receiveClientCar(res.car));
                } else if (getState().hangar.ajaxError) {
                    dispatch(error(getState().hangar.ajaxError.response.message));
                } else {
                    // ToDo Set SentryError
                    console.log(res);
                }
            });
    };
};

function receiveClientCar(res) {
    return {
        type: RECEIVE_CLIENT_CAR,
        payload: res
    };
}

export const updateClientCarMark = (mark, carId) => {
    return {
        type: CHANGE_CLIENT_CAR_MARK,
        payload: {
            mark: mark,
            carId: carId
        }
    };
};

export const clearClientCars = () => {
    return {
        type: CLEAR_CLIENT_CARS
    };
};

export const createClientCar = (data) => {
    return (dispatch, getState) => {
        dispatch(requestClientCars());
        return api('/v1/partner/clients/cars', {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(res => {
                if (res && res.car) {
                    dispatch(receiveClientCar(res.car));
                } else if (getState().hangar.ajaxError) {
                    dispatch(error(getState().hangar.ajaxError.response.message));
                } else {
                    // ToDo Set SentryError
                    console.log(res);
                }
            });
    };
};

export const deleteClientCar = (id) => {
    return function(dispatch) {
        dispatch(requestClientCars());
        return api('/v1/partner/clients/cars/' + id, {
            method: 'DELETE'
        }, dispatch)
            .then(res => {
                if (res) {
                    dispatch(removeClientCar(id));
                }
            });
    };
};

function removeClientCar(id) {
    return {
        type: REMOVE_CLIENT_CAR,
        payload: id
    };
}

export const addClientAvatar = (avatarId) => {
    return {
        type: ADD_CLIENT_AVATAR,
        payload: avatarId
    };
};

export const actions = {
    changeValue,
    fetchSearchClients,
    searchClientsByNickAndPhone,
    fetchClient,
    fetchClientAvatar,
    deleteLocalStorageClient,
    deleteClientAvatar,
    createClient,
    error,
    receiveClient,
    updateClient,
    updateClientV2,
    editClient,
    changeClient,
    editClientsNick,
    showClientCard,
    changeField,
    shadeMenuChange,
    changeSelectField,
    activateClient,
    clearClients,
    clearClientsBySearch,
    cyclicNotificationEdit,
    cyclicNotificationChange,
    updateCyclicNotification,
    createCyclicNotification,
    deleteCyclicNotification,
    cyclicNotificationShow,
    changeClientNotifications,
    errorMenuChange,
    fetchClientOrdersAndCalls,
    checkTooltip,
    fetchClientCars,
    fetchCarReferences,
    changeClientCars,
    updateClientCars,
    updateClientCarMark,
    clearClientCars,
    createClientCar,
    deleteClientCar,
    addClientAvatar,
    fetchCarMarks,
    fetchCarModels,
    fetchCarEngines,
    fetchCarBodies,
    fetchCarTransmissions
};
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [CHANGE_VALUE]: (state, action) => {
        return ({ ...state, [action.payload.name]: action.payload.value });
    },
    [REQUEST_SEARCH_CLIENTS]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_SEARCH_CLIENTS]: (state, action) => {
        let clients = Object.assign({}, state.clientsBySearch);
        if (_.isEmpty(clients)) {
            clients = { items: [], total: 0 };
        }
        if (action.merge) {
            clients.items = clients.items.concat(action.res.items);
            clients.total = action.res.total;
            clients = Object.assign(clients, action.queryData);
        } else {
            clients = Object.assign(action.res, action.queryData);
        }
        return ({ ...state, clientsBySearch: clients, fetching: false });
    },
    [REQUEST_CLIENT]: (state, action) => {
        return ({ ...state, fetching: true, client: { id: action.payload } });
    },
    [RECEIVE_CLIENT]: (state, action) => {
        let clients = JSON.parse(localStorage.getItem('clients.v1'));

        const partnerId = JSON.parse(localStorage.getItem('partner')).account_id;
        if (!clients) {
            clients = {};
        }
        if (_.find(clients[partnerId], ['id', action.res.client.id])) {
            clients[partnerId].splice(_.findKey(clients[partnerId], ['id', action.res.client.id]), 1);
        }
        if (!clients[partnerId]) {
            clients[partnerId] = [];
        }

        clients[partnerId].unshift({
            id: action.res.client.id,
            nick: action.res.client.nick,
            phone: action.res.client.phone,
            partnerId: JSON.parse(localStorage.getItem('partner')).account_id
        });

        while (clients[partnerId].length > 10) {
            clients[partnerId].pop();
        }
        action.res.client.comment = _.get(
            _.find(action.res.client.companies, ['id', JSON.parse(localStorage.getItem('partner')).company_id]),
            'pivot.comment'
        );
        action.res.client.status = _.get(
            _.find(action.res.client.companies, ['id', JSON.parse(localStorage.getItem('partner')).company_id]),
            'pivot.status'
        );
        localStorage.setItem('clients.v1', JSON.stringify(clients));
        action.res.client.justLoaded = true;
        return ({ ...state, client: action.res.client, editClientChange: false, fetching: false });
    },
    [DELETE_LOCAL_STORAGE_CLIENT]: (state, action) => {
        const clients = JSON.parse(localStorage.getItem('clients.v1'));

        const partnerAccountId = _.get(JSON.parse(localStorage.getItem('partner')), 'account_id');

        const partnerClients = _.get(clients, partnerAccountId);

        if (_.find(partnerClients, ['id', action.payload])) {
            partnerClients.splice(_.findKey(partnerClients, ['id', action.payload]), 1);
        }
        localStorage.setItem('clients.v1', JSON.stringify(clients));
        return ({ ...state, client: {}, editClientChange: false, fetching: false });
    },
    [REQUEST_CLIENT_AVATAR]: (state) => {
        return ({ ...state, avatar: { fetching: true } });
    },
    [RECEIVE_CLIENT_AVATAR]: (state, action) => {
        return ({ ...state, avatar: action.data });
    },
    [DELETE_CLIENT_AVATAR]: (state) => {
        const client = Object.assign({}, state.client);
        _.remove(client.client_files, ['type', 'avatar']);
        if (client.avatarAdded) {
            client.avatarAdded = false;
        }
        return ({ ...state, avatar: {}, client: client });
    },
    [CLEAR_NEW_CLIENT_DATA]: (state) => {
        return ({ ...state, newClientPhone: '', newClientNick: '', newClientComment: '', newClientMessageName: '' });
    },
    [SHOW_CLIENT_CARD]: (state, action) => {
        return ({ ...state, clientCardState: action.open });
    },
    [SHADE_MENU_CHANGE]: (state, action) => {
        if (action.payload === state.shadeMenuStatus) {
            action.payload = false;
        }
        return ({ ...state, shadeMenuStatus: action.payload, errorMessage: false });
    },
    [EDIT_CLIENT_CHANGE]: (state, action) => {
        const client = Object.assign({}, state.client);
        const cars = Object.assign([], state.cars);
        if (action.payload === state.editClientChange) {
            action.payload = false;
        }
        return ({
            ...state,
            editClientChange: action.payload,
            changedClient: client,
            changedCars: cars,
            errorMenu: false,
            errorMessage: false
        });
    },
    [ERROR]: (state, action) => {
        return ({ ...state, errorMessage: action.payload });
    },
    [CHANGE_CLIENT]: (state, action) => {
        const client = Object.assign({}, state.client);
        if (client.is_activated) {
            client.fields = Object.assign({}, state.client.fields);
        }
        if (action.name === 'nick' || action.name === 'comment' || action.name === 'message_name') {
            client[action.name] = action.value;
        } else {
            const fieldKey = _.findKey(client.fields, ['id', parseInt(action.name)]);
            const clientField = Object.assign({}, client.fields[fieldKey]);
            if (clientField.label === 'vin') {
                clientField.value = action.value.replace(/[ ]/g, '');
            } else {
                clientField.value = action.value;
            }
            client.fields[fieldKey] = clientField;
        }
        return ({ ...state, changedClient: client });
    },
    [EDIT_CLIENTS_NICK]: (state, action) => {
        const clients = state.clients;
        const inactiveClients = state.inactiveClients;
        if (clients && _.find(clients.entities, item => {
            return item.id === action.payload.id;
        })) {
            _.find(clients.entities, item => {
                return item.id === action.payload.id;
            }).client_companies[0].nick = action.payload.nick;
        }
        if (inactiveClients && _.find(inactiveClients, item => {
            return item.id === action.payload.id;
        })) {
            _.find(inactiveClients.entities, item => {
                return item.id === action.payload.id;
            }).entities.client_companies[0].nick = action.payload.nick;
        }

        const partnerId = JSON.parse(localStorage.getItem('partner')).account_id;
        if (localStorage.getItem('clients.v1')) {
            const historyClients = JSON.parse(localStorage.getItem('clients.v1'));

            if (_.find(historyClients[partnerId], ['id', action.payload.id])) {
                _.find(historyClients[partnerId], ['id', action.payload.id]).nick = action.payload.nick;
            }
            localStorage.setItem('clients.v1', JSON.stringify(historyClients));
        }

        return ({ ...state, clients: clients, client: action.payload, inactiveClients: inactiveClients });
    },
    [CHANGE_FIELD]: (state, action) => {
        const client = state.client;
        _.find(client.fields, ['id', action.payload.field.id]).value = action.payload.value;
        return ({ ...state, client: client });
    },
    [CLEAR_CLIENTS]: (state) => {
        return ({ ...state, clients: false, searchPassed: false });
    },
    [CLEAR_CLIENTS_BY_SEARCH]: (state) => {
        return ({ ...state, clientsBySearch: null, searchPassed: false });
    },
    [CYCLIC_NOTIFICATION_SHOW]: (state, action) => {
        return ({
            ...state,
            cyclicNotification: action.payload,
            shadeMenuStatus: 'cyclicNotification',
            editClientChange: false
        });
    },
    [CYCLIC_NOTIFICATION_EDIT]: (state, action) => {
        const cyclicNotification = Object.assign({}, state.cyclicNotification);

        const notification = _.find(action.payload.notifications, ['service_id', action.payload.value.serviceId]);
        cyclicNotification.period = Object.assign({}, state.cyclicNotification.period);

        switch (action.payload.field) {
        case 'serviceTemplate':
            cyclicNotification.serviceTemplate.id = action.payload.value.serviceTemplateId;
            cyclicNotification.serviceTemplate.name = action.payload.value.label;
            cyclicNotification.service_id = action.payload.value.serviceId;
            if (notification) {
                cyclicNotification.period.title = getPeriodTitle(notification.period);
                cyclicNotification.period.length = notification.period;
                cyclicNotification.repeats_during_period = notification.repeats_during_period;
                cyclicNotification.text = notification.text;
            } else {
                cyclicNotification.period.title = 'месяц';
                cyclicNotification.period.length = 30;
                cyclicNotification.repeats_during_period = 2;
                cyclicNotification.text = '';
            }
            break;
        case 'period':
            cyclicNotification.period.length = action.payload.value.length;
            cyclicNotification.period.title = action.payload.value.title;
            break;
        case 'next_notified_at':
            cyclicNotification.next_notified_at = action.payload.value.format();
            break;
        case 'regulatory':
            cyclicNotification.period.length = 30;
            cyclicNotification.period.title = 'месяц';
            cyclicNotification.repeats_during_period = 2;
            break;
        default:
        }

        return ({ ...state, cyclicNotification: cyclicNotification, editClientChange: false });
    },
    [CYCLIC_NOTIFICATION_CHANGE]: (state, action) => {
        const cyclicNotification = Object.assign({}, state.cyclicNotification);

        const value = action.payload.value;

        let error = false;
        if (value.charAt(0) === '0' && value.length > 1 && action.payload.name === 'repeats_during_period') {
            cyclicNotification[action.payload.name] = value.substring(1);
        } else if (action.payload.name === 'repeats_during_period' &&
            ((!/^\d+$/.test(value) && value.length !== 0) || value.charAt(0) === '0')) {
            error = action.payload.name;
        } else {
            cyclicNotification[action.payload.name] = action.payload.value;
        }
        return ({ ...state, cyclicNotification: cyclicNotification, errorMenu: error });
    },
    [CHANGE_CLIENT_NOTIFICATIONS]: (state, action) => {
        const client = Object.assign({}, state.client);
        if (action.payload.label === 'delete') {
            client.notifications.splice(_.findKey(client.notifications, ['id', action.payload.notification.id]), 1);
        } else {
            client.notifications[_.findKey(client.notifications, ['id', action.payload.notification.id])] = action.payload.notification;
        }
        return ({ ...state, client: client });
    },
    [ERROR_MENU_CHANGE]: (state, action) => {
        return ({ ...state, errorMenu: action.payload });
    },
    [REQUEST_CLIENT_ORDERS_AND_CALLS]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_CLIENT_ORDERS_AND_CALLS]: (state, action) => {
        _.remove(action.payload.orders, ['status', 'PENDING']);
        return ({ ...state, clientOrdersAndCalls: action.payload, fetching: false });
    },
    [CHECK_TOOLTIP]: (state, action) => {
        let tooltipShow = false;
        if (action.payload.nameWidth >= action.payload.maxWidth) {
            tooltipShow = true;
        }
        return ({ ...state, [action.payload.label]: tooltipShow });
    },
    [REQUEST_CLIENT_CARS]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_CLIENT_CARS]: (state, action) => {
        return ({ ...state, cars: action.payload.items, fetching: false });
    },
    [REQUEST_CAR_REFERENCES]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_CAR_REFERENCES]: (state, action) => {
        const references = _.merge(Object.assign({}, state.carReferences), action.payload);

        return ({ ...state, carReferences: references, fetching: false });
    },
    [RECEIVE_CAR_MODELS]: (state, action) => {
        let references = Object.assign({}, state.carReferences);
        if (references.models) {
            references.models = _.concat(references.models, action.payload.models);
        } else {
            references = action.payload;
        }

        return ({ ...state, carReferences: references, fetching: false });
    },
    [CHANGE_CLIENT_CARS]: (state, action) => {
        const cars = Object.assign({}, state.changedCars);
        const carKey = _.findKey(cars, ['id', parseInt(action.payload.id)]);
        const car = Object.assign({}, cars[carKey]);

        if (_.includes(['power', 'mileage'], action.payload.name)) {
            if (/^\d+$/.test(action.payload.value)) {
                car[action.payload.name] = parseInt(action.payload.value);
            } else {
                car[action.payload.name] = 0;
            }
        } else {
            car[action.payload.name] = action.payload.value;
        }
        cars[carKey] = car;
        return ({ ...state, changedCars: cars });
    },
    [RECEIVE_CLIENT_CAR]: (state, action) => {
        const cars = Object.assign([], state.cars);
        const carKey = _.findKey(cars, ['id', parseInt(action.payload.id)]);
        if (carKey) {
            cars[carKey] = action.payload;
        } else {
            cars.push(action.payload);
        }

        return ({ ...state, cars: cars, shadeMenuStatus: null, fetching: false });
    },
    [REMOVE_CLIENT_CAR]: (state, action) => {
        const cars = Object.assign([], state.cars);
        _.remove(cars, ['id', action.payload]);
        return ({ ...state, cars: cars, fetching: false });
    },
    [CHANGE_CLIENT_CAR_MARK]: (state, action) => {
        const cars = Object.assign([], state.cars);
        const carKey = _.findKey(cars, ['id', parseInt(action.payload.carId)]);
        cars[carKey].model.mark = action.payload.mark;
        cars[carKey].model.title = null;

        return ({ ...state, cars: cars, editClientChange: false });
    },
    [CLEAR_CLIENT_CARS]: (state) => {
        return ({ ...state, cars: null });
    },
    [ADD_CLIENT_AVATAR]: (state, action) => {
        const client = Object.assign({}, state.client);
        client.avatarAdded = true;
        client.client_files.push({ file_id: action.payload, type: 'avatar' });

        return ({ ...state, client: client });
    },
    [RECEIVE_CLIENTS_STREAM]: (state, action) => {
        let stream = [];
        _.each(action.payload.ordersResponse.orders, order => {
            if (order.client && order.status !== 'CANCELED') {
                stream.push(
                    {
                        client: order.client,
                        entity: 'order',
                        entityId: order.id,
                        date: _.find(order.orderDetails).scheduledTo,
                        orderDetails: order.orderDetails,
                        clentName: order.clientName,
                        clientPhone: order.clientPhone,
                        clientAvatar: order.clientAvatar
                    }
                );
            }
        });
        _.each(action.payload.reservesResponse.reserves, reserve => {
            if (reserve.client && reserve.status !== 'CANCELED') {
                stream.push(
                    {
                        client: reserve.client,
                        entity: 'reserve',
                        entityId: reserve.id,
                        date: reserve.scheduledTo,
                        clentName: reserve.clientName,
                        clientPhone: reserve.clientPhone,
                        clientAvatar: reserve.clientAvatar,
                        id: reserve.id,
                        resource: reserve.resource
                    }
                );
            }
        });
        stream = _.sortBy(stream, ['date']);
        return ({ ...state, clientsStream: stream, fetching: false });
    },
    [CREATING_NOTIFICATION]: (state) => {
        return ({ ...state, fetching: true });
    },
    [NOTIFICATION_IS_CREATED]: (state) => {
        return ({ ...state, fetching: false });
    }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    user: {},
    notifies: {},
    menuIsOpen: false,
    clientsListVisible: false,
    clientsSearch: '',
    clients: null, // {entities: [], offset: (int), limit: (int)}
    clientsBySearch: null,
    inactiveClients: null, // {entities: [], offset: (int), limit: (int)}
    searchPassed: false,
    client: {},
    avatar: {},
    editClientChange: false,
    newClientPhone: '',
    newClientNick: '',
    newClientMessageName: '',
    newClientComment: '',
    errorMessage: false,
    shading: false,
    orderMode: false,
    menuStatus: 'mainInfo',
    privateMenuStatus: 'privateInfo',
    cyclicNotification: {
        last_notified_at: null,
        next_notified_at: null,
        period: {
            length: null,
            title: null
        },
        repeats_during_period: '',
        serviceTemplate: {
            name: null,
            id: null
        },
        text: ''
    },
    fetching: false,
    clientNameTooltipShow: false,
    cyclNotifNameTooltipShow: false,
    cars: null,
    changedCars: null,
    carReferences: null

};

export default function homeViewReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
