import { connect } from 'react-redux';
import { deleteCompanyFile, pushCompanyFile } from 'modules/company';
import { actions } from '../../../modules/settings';

import Gallery from './Gallery';

const mapStateToProps = state => ({
    company: state.company.company
});

export default connect(mapStateToProps, Object.assign(actions, { deleteCompanyFile, pushCompanyFile }))(Gallery);
