/**
 * Created by user on 25.05.2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Select';
import Preloader from 'components/Preloader';
import PollCard from '../../Modals/PollCard';

import { declOfNum } from 'utils';
import moment from 'moment';
import _ from 'lodash';

import classes from '../Reports.module.scss';

const IMAGE_FILTERS = [
    { type: 'item', label: 'дате, сначала новые', value: { order_by: 'created_at', order_direction: 'desc' } },
    { type: 'item', label: 'дате, сначала старые', value: { order_by: 'created_at', order_direction: 'asc' } }
];

class Reports extends React.Component {
    state = {
        color: null
    };

    UNSAFE_componentWillMount() {
        this.props.fetchReports(this.props.sort);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.showPollCard && !this.props.showPollCard) {
            const body = document.querySelectorAll('body');

            const html = document.querySelectorAll('html');
            if (body[0] && body[0].style) {
                body[0].style.overflowY = 'hidden';
            }
            if (html[0] && html[0].style) {
                html[0].style.overflowY = 'hidden';
            }
        }
        if (!nextProps.showPollCard && this.props.showPollCard) {
            const body = document.querySelectorAll('body');

            const html = document.querySelectorAll('html');
            if (body[0] && body[0].style) {
                body[0].style.overflowY = 'auto';
            }
            if (html[0] && html[0].style) {
                html[0].style.overflowY = 'auto';
            }
        }
        if (!_.isEqual(this.props.sort, nextProps.sort)) {
            this.props.fetchReports(nextProps.sort);
        }
    }

    changeSort(sort) {
        this.props.changeSort(sort.value);
    }

    showPollCard(id) {
        this.props.switchPollCard();
        this.props.fetchVisitResult(id);
    }

    closePollCard() {
        this.props.resetPollCardData();
        this.props.switchPollCard();
    }

    exportResults(ids) {
        const data = Object.assign({}, this.props.sort);
        let formattedIds = '';
        if (ids) {
            _.map(ids, (id, key) => {
                formattedIds += `&ids[${key}]=${id}`;
            });
        }

        this.props.exportResults(data, formattedIds);
    }

    render() {
        if (!this.props.reports) {
            return null;
        }

        const reports = this.props.reports.items;

        const chosenIds = this.props.reports.chosenIds;

        const chosenCount = chosenIds ? chosenIds.length : 0;

        let exportPopup;
        if (this.props.exportingResults) {
            if (this.props.exportResultsId) {
                exportPopup = (
                    <div className={classes.exportPopup}>
                        Экспорт клиентов завершен. Файл будет доступен в течение 5 минут.&nbsp;
                        <span onClick={this.props.downloadExportResults.bind(this, this.props.exportResultsId)}>Скачать файл</span>
                    </div>
                );
            } else {
                exportPopup = (
                    <div className={classes.exportPopup}>
                        Экспортируем клиентов...
                    </div>
                );
            }
        }

        return (
            <div className={classes.reports}>
                { this.props.showPollCard && this.props.pollCardData
                    ? <PollCard onClick={this.closePollCard.bind(this)} data={this.props.pollCardData} />
                    : null }
                <div className={classes.wrapper}>
                    <div className={classes.header}>
                        <div className={classes.title}>Предложения и негативные отзывы</div>
                        <div className={classes.filter}>
                            <div className={classes.text}>Сортировать по</div>
                            <Select
                                name='filters'
                                placeholder='...'
                                value={this.props.sort}
                                options={IMAGE_FILTERS}
                                type='inline'
                                icon
                                onChange={this.changeSort.bind(this)}
                            />
                            <div className={classes.export} onClick={this.exportResults.bind(this, null)}>Экспорт всего списка в excel</div>
                        </div>
                    </div>
                    <div className={classes.list}>
                        <div className={classes.header}>
                            <div className={classes.name}>Фамилия и имя</div>
                            <div className={classes.date}>Дата ответа ОКК</div>
                            <div className={classes.card}>
                                Переход на карточку
                            </div>
                            <div className={classes.add}>Добавить<br /> в список</div>
                        </div>
                        { _.map(reports, report => {
                            return (
                                <div key={report.id} className={classes.item}>
                                    <div className={classes.name}>{ report.client.name }</div>
                                    <div className={classes.date}>
                                        { report.completed_at
                                            ? moment(report.completed_at).format('DD MMMM`YY')
                                            : moment(report.updated_at).format('DD MMMM`YY') }
                                    </div>
                                    <div className={classes.card} onClick={this.showPollCard.bind(this, report.id)}>
                                        карточка опроса
                                    </div>
                                    <div
                                        className={classes.add + ' ' + (report.chosen ? classes.active : '')}
                                        onClick={this.props.chooseReport.bind(this, report.id)}
                                    />
                                </div>
                            );
                        }) }
                    </div>
                    <div className={classes.footer + ' ' + (chosenCount ? classes.active : '')}>
                        <div className={classes.content}>
                            <div className={classes.reportsCount}>
                                Выбрано { chosenCount } { declOfNum(chosenCount, ['отзыв', 'отзыва', 'отзывов']) }
                            </div>
                            <div className={classes.export} onClick={this.exportResults.bind(this, chosenIds)}>
                                Экспорт выбранных в excel
                            </div>
                            <div className={classes.addList}>Создрать/добавить список</div>
                        </div>
                    </div>
                </div>
                <Preloader trigger={this.props.fetching} fixed='true' />
                { exportPopup }
            </div>
        );
    }
}
Reports.contextTypes = {
    router: PropTypes.object
};
export default Reports;
