import React from 'react';
import { getOffsetRect } from 'utils';
import RenderInBody from 'components/RenderInBody';
import moment from 'moment';
import _ from 'lodash';

require('./contextmodal.scss');

class ContextModal extends React.Component {
    state = {
        trigger: false,
        modalClass: ''
    };

    checkModalHandler = this.checkModalPosition.bind(this);
    modalOffHandler = this.modalOff.bind(this);

    componentDidMount() {
        this.checkModalPosition();
    }

    componentWillUnmount() {
        if (this.state.trigger) {
            window.removeEventListener('scroll', this.checkModalHandler);
            window.removeEventListener('resize', this.checkModalHandler);
            window.addEventListener('click', this.modalOffHandler);
        }
    }

    modalOff() {
        this.setState({ trigger: false });
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.trigger && this.state.trigger) {
            window.addEventListener('scroll', this.checkModalHandler);
            window.addEventListener('resize', this.checkModalHandler);
            setTimeout(function() {
                window.addEventListener('click', this.modalOffHandler);
                window.addEventListener('contextmenu', this.modalOffHandler);
            }.bind(this));
            this.checkModalPosition();
        }
        if (prevState.trigger && !this.state.trigger) {
            window.removeEventListener('scroll', this.checkModalHandler);
            window.removeEventListener('resize', this.checkModalHandler);
            window.removeEventListener('click', this.modalOffHandler);
            window.removeEventListener('contextmenu', this.modalOffHandler);
        }
    }

    checkModalPosition() {
        // точки на чанке, к которым будет хорошо прикрепить модальное окно
        const el = document.getElementById('contextModal');
        if (el) {
            const chunk = el.parentNode;
            let chunkPoints = [
                { x: 12, y: 0, modalClass: 'topAlign', points: [] },
                { x: 25, y: 12, modalClass: 'rightAlign', points: [] },
                { x: 12, y: 25, modalClass: 'bottomAlign', points: [] },
                { x: 0, y: 12, modalClass: 'leftAlign', points: [] }
            ];
            // определяем размер окна браузера и координаты ценра вьюпорта
            const client_w = document.documentElement.clientWidth; const client_h = document.documentElement.clientHeight;

            const center_x = Math.round(client_w / 2);

            const center_y = Math.round(client_h / 2);

            const chunkPos = getOffsetRect(chunk);
            // определяем ближайшую точку на чанке
            _.each(chunkPoints, point => {
                point.distance = Math.round(Math.sqrt(Math.pow(center_x - (chunkPos.boxLeft + point.x), 2) + Math.pow(center_y - (chunkPos.boxTop + point.y), 2)));
            });

            chunkPoints = _.orderBy(chunkPoints, ['distance'], ['asc']);
            this.setState({ modalClass: chunkPoints[0].modalClass });
        }
    }

    render() {
        moment.locale('ru');
        if (this.state.trigger) {
            const chunkInPast = moment(this.props.chunkDate).isBefore(moment());
            const orderDisabled = chunkInPast;

            const availableLinks = (!this.props.free ? 0 : 1) + (orderDisabled ? 0 : 1) + (chunkInPast ? 0 : 1);
            return (
                <div className='contextModalTrigger' id='contextModal'>
                    <RenderInBody byId='contextModal' closeHandler={() => {}}>
                        <div className='contextModal'>
                            <div className={'contextModal__body ' + this.state.modalClass + ' availableLinks-' + availableLinks}>
                                <div
                                    className={'contextModal__link contextModal__link--order' + (!this.props.free ? ' disabled' : '')}
                                    onClick={this.props.clickToOrder}
                                >
                                    Запись на услугу
                                </div>
                                <div
                                    className={'contextModal__link contextModal__link--reserve' + (orderDisabled ? ' disabled' : '')}
                                    onClick={this.props.clickToReserve}
                                >
                                    Забронировать без клиента
                                </div>
                                <div
                                    className={'contextModal__link contextModal__link--takeoff' + (chunkInPast ? ' disabled' : '')}
                                    onClick={this.props.clickToTakeOff}
                                >
                                    Выключить время
                                </div>
                                <div className='triangleArrow left' />
                                <div className='triangleArrow top' />
                                <div className='triangleArrow right' />
                                <div className='triangleArrow bottom' />
                            </div>
                        </div>
                    </RenderInBody>
                </div>
            );
        } else {
            return (
                <div
                    className='contextModalTrigger'
                    onContextMenu={(e) => {
                        e.preventDefault();
                        if (!this.props.toOrder) {
                            this.setState({ trigger: true });
                        }
                    }}
                />
            );
        }
    }
}

export default ContextModal;
