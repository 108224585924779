import React from 'react';
import cx from 'classnames';
import Select from 'components/Select';
import Peninput from 'components/Peninput';
import _ from 'lodash';
import Schedule from '../Schedule';

require('../styles_posts.scss');

class CreatePost extends React.Component {
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (_.get(this.props.newResource, 'id', false) && _.get(nextProps.newResource, 'id', false)) {
            this.saveSchedule();
            this.props.toCreateResource();
        }
    }

    changeResourceName(e) {
        e.preventDefault();
        const value = e.target.value;
        this.props.changeNewResource('name', value);
    }

    changeResourceDescr(e) {
        e.preventDefault();
        const value = e.target.value;
        this.props.changeNewResource('descr', value);
    }

    changeServices(service) {
        const services = _.get(this.props.resource, 'services', []);
        const alreadyIn = _.find(services, { id: service.value.id }) || false;
        if (alreadyIn === false) {
            services.push(service.value);
            this.props.changeNewResource('services', services);
        }
    }

    removeService(removeId, e) {
        e.preventDefault();
        let services = _.get(this.props.resource, 'services', []);
        services = _.filter(services, service => service.id !== removeId);
        this.props.changeNewResource('services', services);
    }

    createResource(e) {
        e.preventDefault();
        if (!_.isEmpty(this.props.resource.name)) {
            this.props.createResource(this.props.resource).then(() => {
                // TODO сделать обновление данных без загрузки компании
                this.props.fetchCompany(this.props.company.id, [
                    'city',
                    'divisions',
                    'resources',
                    'resources.divisions',
                    'resources.schedules',
                    'files',
                    'files.thumbs',
                    'notifications',
                    'catalogues',
                    'services'
                ]);
                this.props.fetchResources();
            });
        }
    }

    switchResourceIsOnline(value) {
        this.props.changeNewResource('is_online', value);
    }

    render() {
        const company = this.props.company;

        const divisions = _.get(company, 'divisions', []);

        const resource = this.props.resource;

        const selDivisions = [];
        _.map(divisions, (division) => {
            if (!_.includes(resource.divisions, division.id)) {
                selDivisions.push({ label: division.title, value: division.id, type: 'item' });
            }
        });

        const services = _.map(_.get(resource, 'services', []), (rService) => {
            const service = _.find(company.services.services, { id: rService.id });
            return (
                <div className='service' key={service.id}>
                    <div className='service__title'>{ service.serviceTemplate.name }</div>
                    <div className='service__price'>
                        { service.minPrice }
                        { ' ' }
                        руб.
                    </div>
                    <div className='service__remove'>
                        <div onClick={this.removeService.bind(this, service.id)} />
                    </div>
                </div>
            );
        });

        const selServices = [];
        _.map(company.services.services, (service) => {
            if (!_.some(resource.services, ['id', service.id])) {
                selServices.push({
                    label: service.serviceTemplate.name,
                    value: { id: service.id, price: service.minPrice, duration: service.averageDuration },
                    type: 'item'
                });
            }
        });

        return (
            <div className='createPost' onClick={this.props.toCreateResource.bind(this)}>
                <div
                    className='createPost__content' onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <div className='createPost__header'>
                        <div className='col-xs-9'>
                            <h1>Настройки мастера</h1>
                            <div className='peninput__label'>Имя { _.get(this.props.company, 'angaraInfo.resourceTitleGenitive', 'мастера').toLowerCase() }</div>
                            <Peninput
                                name='name'
                                defaultValue={'Именование ' + _.get(this.props.company, 'angaraInfo.resourceTitleGenitive', 'мастера').toLowerCase()}
                                value={_.get(this.props.resource, 'name', '')}
                                changeHandler={this.changeResourceName.bind(this)}
                            />
                        </div>
                    </div>
                    <div className='body'>
                        <div className='schedule'>
                            <div className='createPost__infoBlock'>
                                <div className='createPost__infoBlock__logo' />
                                <div className='createPost__infoBlock__info'>
                                    <h2>Режим
                                        работы { _.get(this.props.company, 'angaraInfo.resourceTitleGenitive', 'мастера').toLowerCase() }
                                    </h2>
                                    { company.integrated_with_1c
                                        ? (
                                            <div className='text'>
                                                Редактирование расписания отключено.
                                                <br />
                                                Режим
                                                работы { _.get(this.props.company, 'angaraInfo.resourceTitle', 'мастер').toLowerCase() }}ов
                                                компании интегрируется из 1С.
                                            </div>
                                        )
                                        : (
                                            <div>
                                                <div className='text'>
                                                    { _.get(this.props.company, 'angaraInfo.resourceTitle', 'Мастер') }&nbsp;
                                                    будет работать согласно указанному вами графику либо по общему
                                                    расписанию компании.
                                                </div>
                                                <Schedule
                                                    resource={this.props.newResource} setProceed={(proceed) => {
                                                        this.saveSchedule = proceed;
                                                    }}
                                                />
                                            </div>
                                        ) }
                                </div>
                            </div>
                        </div>
                        <div className='services'>
                            <div className='createPost__infoBlock'>
                                <div className='createPost__infoBlock__logo' />
                                <div className='createPost__infoBlock__info'>
                                    <h2>Привязка услуг</h2>
                                    <div className='text'>
                                        Услуга будет доступна
                                        у { _.get(this.props.company, 'angaraInfo.resourceTitleGenitive', 'мастера').toLowerCase() }&nbsp;
                                        к которому привязана. Возможна привязка услуги к
                                        нескольким мастерам (ресурсам). Непривязанная услуга будет недоступна для онлайн записи.
                                    </div>
                                </div>
                                <div className='list'>
                                    { services }
                                </div>
                                { selServices.length
                                    ? (
                                        <div className='selectService'>
                                            <Select
                                                name='services'
                                                placeholder='Прикрепить услугу'
                                                value={null}
                                                options={selServices}
                                                type='select'
                                                disabled={_.isEmpty(_.get(this.props.resource, 'name', ''))}
                                                onChange={this.changeServices.bind(this)}
                                            />
                                        </div>
                                    )
                                    : null }
                            </div>
                        </div>
                    </div>
                    <div className='createPost-footer'>
                        <div
                            className='createPost-footer__cancel'
                            onClick={this.props.toCreateResource.bind(this)}
                        >
                            Отменить
                        </div>
                        <div
                            className={cx({ 'createPost-footer__save': true, disabled: _.isEmpty(resource.name) })}
                            onClick={this.createResource.bind(this)}
                        >
                            Сохранить
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreatePost;
