/**
 * Created by user on 04.10.16.
 */
import { connect } from 'react-redux';
import { changeValue } from '../../../modules/Calendar';
import { createClient } from 'modules/clients';
import { clearAjaxError } from 'modules/hangar';
import CreateClientModal from './CreateClientModal';

const mapStateToProps = (state) => ({
    client: state.clients.client,
    newClientPhone: state.clients.newClientPhone,
    newClientNick: state.clients.newClientNick,
    newClientMessageName: state.clients.newClientMessageName,
    newClientComment: state.clients.newClientComment,
    errorMessage: state.clients.errorMessage,
    error: state.hangar.ajaxError
});

export default connect(mapStateToProps, Object.assign({}, {
    changeValue,
    createClient,
    clearAjaxError
}))(CreateClientModal);
