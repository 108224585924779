import { api } from './../../../utils';

// ------------------------------------
// Constants
// ------------------------------------
const CHANGE_VALUE = 'changeValue';
const CHANGE_STEP = 'changeStep';
const CHANGE_STEP_TO_SEND_CODE = 'changeStepToSendCode';
const SET_NEW_USER = 'setNewUser';
const SET_ERROR = 'setError';
const SET_BUSINESS_TYPES = 'setBusinessTypes';
const SET_BUSINESS_TYPE = 'setBusinessType';
const PASS_CHECK = 'passCheck';

// ------------------------------------
// Actions
// ------------------------------------

export const getBusinessTypes = () => {
    // e.preventDefault();
    return (dispatch) => {
        return api('/v3/references/business-types', {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res && res.items) {
                    dispatch(setBusinessTypes(res));
                }
            });
    };
};

function setBusinessTypes(res) {
    return {
        type: SET_BUSINESS_TYPES,
        payload: res
    };
}

export const setBusinessType = (e) => {
    return {
        type: SET_BUSINESS_TYPE,
        payload: e.target.value
    };
};

export const submitPhone = () => {
    return (dispatch, getState) => {
        const state = getState().createCompany;
        return api('/partner/registration', {
            method: 'POST',
            body: JSON.stringify({ phone: state.phone.replace(/([^0-9+]*)/ig, ''), companyName: state.companyName, password: state.newPassword })
        }, dispatch)
            .then(res => {
                if (res && res.responseData.activationKey && res.responseData.id) {
                    dispatch(changeStepToSendCode(res.responseData));
                } else if (getState().hangar.ajaxError) {
                    dispatch(setError(getState().hangar.ajaxError.response.message));
                }
            });
    };
};

export const submitCode = (e) => {
    e.preventDefault();
    return (dispatch, getState) => {
        const state = getState().createCompany;
        return api('/partner/registration/confirm', {
            method: 'POST',
            body: JSON.stringify({
                ownerId: state.partnerId,
                activationCode: state.activationCode,
                activationKey: state.activationKey,
                companyName: state.companyName,
                businessTypeId: parseInt(state.businessType),
                phone: state.phone.replace(/([^0-9+]*)/ig, '')
            })
        }, dispatch)
            .then(res => {
                if (res && res.partner && res.token) {
                    dispatch(setNewUser(res));
                } else if (getState().hangar.ajaxError) {
                    dispatch(setError(getState().hangar.ajaxError.response.message));
                }
            });
    };
};

export const changeStep = (step) => {
    return {
        type: CHANGE_STEP,
        payload: step
    };
};

export const changeStepToSendCode = (res) => {
    return {
        type: CHANGE_STEP_TO_SEND_CODE,
        payload: {
            step: 'sendCode',
            activationKey: res.activationKey,
            partnerId: res.id
        }
    };
};

export const changeValue = (e) => {
    return {
        type: CHANGE_VALUE,
        payload: {
            name: e.target.name,
            value: e.target.value
        }
    };
};

export const setNewUser = (tokenAndUser) => {
    return {
        type: SET_NEW_USER,
        payload: tokenAndUser
    };
};

function setError(error) {
    return {
        type: SET_ERROR,
        error: error
    };
}

export const passCheck = () => {
    return {
        type: PASS_CHECK
    };
};

export const actions = {
    changeValue,
    submitPhone,
    submitCode,
    changeStep,
    getBusinessTypes,
    setBusinessType,
    setError,
    passCheck
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [CHANGE_VALUE]: (state, action) => {
        let status = state.checkPassStatus;
        if (action.payload.value && action.payload.name === 'passwordConfirm' && state.newPassword === action.payload.value) {
            status = 'success';
        } else if (action.payload.value && action.payload.name === 'newPassword' && state.passwordConfirm === action.payload.value) {
            status = 'success';
        } else if (action.payload.name === 'newPassword' || action.payload.name === 'passwordConfirm') {
            status = null;
        }
        return ({ ...state, [action.payload.name]: action.payload.value, error: null, checkPassStatus: status });
    },
    [CHANGE_STEP]: (state, action) => {
        return ({ ...state, step: action.payload, error: null });
    },
    [CHANGE_STEP_TO_SEND_CODE]: (state, action) => {
        return ({
            ...state,
            step: action.payload.step,
            partnerId: action.payload.partnerId,
            activationKey: action.payload.activationKey,
            error: null
        });
    },
    [SET_NEW_USER]: (state, action) => {
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem('partner', JSON.stringify(action.payload.partner));
        window.location = '/';
        return ({ ...state });
    },
    [SET_ERROR]: (state, action) => {
        return ({
            ...state,
            error: action.error
        });
    },
    [SET_BUSINESS_TYPES]: (state, action) => {
        return ({ ...state, businessTypes: action.payload.items });
    },
    [SET_BUSINESS_TYPE]: (state, action) => {
        return ({ ...state, businessType: action.payload });
    },
    [PASS_CHECK]: (state) => {
        let status = null;
        if (state.passwordConfirm) {
            if (state.newPassword === state.passwordConfirm) {
                status = 'success';
            } else {
                status = 'fail';
            }
        }

        return ({ ...state, checkPassStatus: status });
    }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    phone: '',
    companyName: '',
    activationKey: null,
    activationCode: '',
    partnerId: '',
    step: 'sendPhone', // sendPhone, sendCode
    error: null,
    checkPassStatus: null,
    newPassword: '',
    passwordConfirm: ''
};

export default function createCompanyReducer(state = initialState, action) {
    state = Object.assign({}, initialState, state);

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
