import React from 'react';
import Input from 'components/Input';
import { Link } from 'react-router-dom';
import InputElement from 'react-input-mask';
import _ from 'lodash';

require('./createCompany.scss');

class CreateCompany extends React.Component {
    UNSAFE_componentWillMount() {
        this.props.getBusinessTypes();
    }

    submitPhone() {
        if (!this.props.phone) {
            this.props.setError('Введите номер телефона');
        } else if (!this.props.companyName) {
            this.props.setError('Введите название компании');
        } else if (!this.props.businessType) {
            this.props.setError('Выберите специализацию компании');
        } else if (this.props.checkPassStatus !== 'success') {
            this.props.setError('Пароли не совпадают');
        } else if (this.props.phone.replace(/([^0-9+]*)/ig, '').length > 12) {
            this.props.setError('Указан неверный формат телефона компании для оповещений');
        } else {
            this.props.submitPhone();
        }
    }

    submitCode(e) {
        if (!this.props.activationCode) {
            this.props.setError('Введите код активации');
        } else if (!/^\d{4}$/.test(this.props.activationCode)) {
            this.props.setError('Неверный SMS код');
        } else {
            this.props.submitCode(e);
        }
    }

    render() {
        let content; let readyToCreate = false;
        if (this.props.phone && this.props.companyName && this.props.checkPassStatus === 'success' && this.props.businessType) {
            readyToCreate = true;
        }
        switch (this.props.step) {
        case 'sendCode':
            content = (
                <div className={'page' + (this.props.error ? ' error' : '')}>
                    <div className='inputs'>
                        <div className='wrapper'>
                            <h1>{ this.props.error || 'Регистрация компании' }</h1>
                            <div className='row'>
                                <Input
                                    type='text'
                                    name='activationCode'
                                    placeholder='XXXX'
                                    value={this.props.activationCode}
                                    onChange={this.props.changeValue}
                                    onKeyPress={e => {
                                        if (e.key === 'Enter') {
                                            this.submitCode(e);
                                        }
                                    }}
                                />
                                <div className='link' onClick={this.props.changeStep.bind(this, 'sendPhone')}>назад</div>
                            </div>
                            <div className='submit'>
                                <div onClick={this.submitCode.bind(this)}>подтвердить телефон</div>
                            </div>
                        </div>
                    </div>
                </div>
            );
            break;
        case 'sendPhone':
        default:
            content = (
                <div className={'page' + (this.props.error ? ' error' : '')}>
                    <div className='inputs'>
                        <div className='wrapper'>
                            <h1>{ this.props.error || 'Регистрация компании' }</h1>
                            <form>
                                <div className='row'>
                                    <InputElement
                                        type='text'
                                        name='phone'
                                        placeholder='телефон'
                                        autoComplete='off'
                                        value={this.props.phone}
                                        onChange={this.props.changeValue}
                                        mask='+7 (999) 999-99-99'
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                this.submitPhone(e);
                                            }
                                        }}
                                    />
                                    <Link className='link' to='/login'>вернуться к авторизации</Link>
                                </div>
                                <div className='row'>
                                    <Input
                                        type='text'
                                        name='companyName'
                                        placeholder='название компании'
                                        autoComplete='off'
                                        value={this.props.companyName}
                                        onChange={this.props.changeValue}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                this.submitPhone(e);
                                            }
                                        }}
                                    />
                                    <select onChange={this.props.setBusinessType.bind(this)} value={this.props.businessType}>
                                        <option hidden>Специализация компании</option>
                                        { _.map(this.props.businessTypes, businessType => {
                                            return (
                                                <option value={businessType.id} key={'businessType' + businessType.id}>
                                                    { businessType.name }
                                                </option>
                                            );
                                        }) }
                                    </select>
                                </div>
                                <div className='row'>
                                    <Input
                                        type='password'
                                        name='newPassword'
                                        placeholder='пароль'
                                        autoComplete='new-password'
                                        className={this.props.checkPassStatus}
                                        value={this.props.newPassword}
                                        onChange={this.props.changeValue}
                                        onBlur={this.props.passCheck.bind(this)}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                this.submitPhone(e);
                                            }
                                        }}

                                    />
                                </div>
                                <div className='row'>
                                    <Input
                                        type='password'
                                        name='passwordConfirm'
                                        placeholder='подтверждение пароля'
                                        autoComplete='new-password'
                                        className={this.props.checkPassStatus}
                                        value={this.props.passwordConfirm}
                                        onChange={this.props.changeValue}
                                        onBlur={this.props.passCheck.bind(this)}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                this.submitPhone(e);
                                            }
                                        }}
                                    />
                                </div>
                            </form>
                            <div className='submit'>
                                <div className={!readyToCreate ? 'disabled' : ''} onClick={this.submitPhone.bind(this)}>зарегистрировать</div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (<div className='createCompany'>{ content }</div>);
    }
}

export default CreateCompany;
