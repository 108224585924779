import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
// import { syncHistoryWithStore } from 'react-router-redux';
import createStore from './store/createStore';
import AppContainer from './containers/AppContainer';
import routes from './routes';
import ErrorBoundary from './layouts/ErrorBoundary';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
// import makeRootReducer from '../src/store/reducers';

// ========================================================
// Browser History Setup
// ========================================================
const history = createBrowserHistory();

// ========================================================
// Store and History Instantiation
// ========================================================
// Create redux store and sync with react-router-redux. We have installed the
// react-router-redux reducer under the routerKey "router" in src/routes/index.js,
// so we need to provide a custom `selectLocationState` to inform
// react-router-redux of its location.
const initialState = {};
export const store = createStore(initialState, history);
/* const history = syncHistoryWithStore(browserHistory, store, {
    selectLocationState: (state) => state.router
}); */

// ========================================================
// Developer Tools Setup
// ========================================================
if (process.env.__DEBUG__) {
    if (window.devToolsExtension) {
        window.devToolsExtension.open();
    }
}

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root');

console.log('MOUNT_NODE!');

const render = (routerKey = null) => {
    console.log('routes!');

    ReactDOM.render(
        <Provider store={store}>
            <ErrorBoundary>
                <Router history={history}>
                    <AppContainer
                        store={store}
                        routes={routes}
                        routerKey={routerKey}
                    />
                </Router>
            </ErrorBoundary>
        </Provider>,
        MOUNT_NODE
    );
};

console.log('RENDER!');

// ========================================================
// Go!
// ========================================================
render();
