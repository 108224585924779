import React from 'react';
import classes from '../OrderFooter.module.scss';

import _ from 'lodash';
// Comps
import Resource from '../Resource';

class Division extends React.Component {
    componentDidMount() {

    }

    componentWillUnmount() {

    }

    componentDidUpdate() {

    }

    shouldComponentUpdate(nextProps) {
        if (_.isEqual(nextProps, this.props)) {
            return false;
        }
        if (_.get(nextProps.toOrder, 'mode') === 'takeOff' || _.get(this.props.toOrder, 'mode') === 'takeOff') {
            return true;
        }
        // отрабатываем модальные окна управления чанками
        if (!_.isEqual(nextProps.toOrder, this.props.toOrder) && nextProps.dayTime === this.props.dayTime) {
            if (_.get(this.props.toOrder, 'divisionId') !== this.props.id && _.get(nextProps.toOrder, 'divisionId') !== this.props.id) {
                return false;
            }
        }
        // отрабатываем services
        if (!_.isEqual(nextProps.services, this.props.services)) {
            if (_.get(this.props.toOrder, 'divisionId') !== this.props.id) {
                return false;
            }
        }
        return true;
    }

    render() {
        const company = this.props.company;

        const division = _.find(company.divisions, { id: this.props.id });

        const orders = this.props.orders;

        const tomorrowOrders = this.props.tomorrowOrders;

        const reserves = this.props.reserves;

        const tomorrowReserves = this.props.tomorrowReserves;

        const dayTime = this.props.dayTime;

        const timeHours = []; const timePeriods = [];

        const timeHoursValues = {
            day: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
            night: [20, 21, 22, 23, '00', 1, 2, 3, 4, 5, 6, 7, 8]
        };

        const timePeriodsLabels = {
            day: ['Утро', '1 половина дня', 'Обед', '2 половина дня', 'Вечер'],
            night: ['Вечер', 'Поздний вечер', 'Ночь', 'Раннее утро', 'Утро']
        };

        _.each(timeHoursValues[dayTime], value => {
            timeHours.push(<div key={'timeHour' + value}>{ value }<sup>00</sup></div>);
        });

        _.each(timePeriodsLabels[dayTime], label => {
            timePeriods.push(<div key={'timePeriod' + label}>{ label }</div>);
        });

        return (
            <div
                id={'division' + division.id}
                className={'division' + division.id + ' ' + classes.wrapper + (this.props.toOrder && this.props.toOrder.divisionId === division.id ? (' ' + classes.current) : '')}
                key={division.id}
                name={'division' + division.id}
            >
                <div className={classes.schedules}>

                    <div className={classes.headers}>
                        <div className={classes.divisionTitle}>
                            { division.title }
                        </div>
                        <div className={classes.timePeriods}>
                            { timePeriods }
                        </div>
                        <div className={classes.timeHours}>
                            { timeHours }
                        </div>
                    </div>
                    { _.map(division.resources, resource_id => {
                        // нормализованные заказы и брони передаем отсюда, чтобы не генерировать их
                        // в каждом ресурсе отдельно
                        return (
                            <Resource
                                toOrder={this.props.toOrder}
                                company={company}
                                client={this.props.client}
                                schedules={this.props.schedules}
                                orders={orders}
                                tomorrowOrders={tomorrowOrders}
                                reserves={reserves}
                                tomorrowReserves={tomorrowReserves}
                                division={division}
                                currentDay={this.props.currentDay}
                                dayTime={dayTime}
                                id={resource_id}
                                key={resource_id}
                                takeOffChunk={this.props.takeOffChunk}
                                pastChunksNumber={this.props.pastChunksNumber}
                            />
                        );
                    }) }
                </div>
            </div>);
    }
}

export default Division;
