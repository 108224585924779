import { connect } from 'react-redux';
import { actions } from '../../../../modules/calls';
import { changeNavigate, changeDate } from '../../reducer';
import { clearClients, fetchSearchClients } from 'modules/clients';

import CallCreate from './CallCreate';

const mapStateToProps = (state) => ({
    call: state.calls.call,
    calls: state.calls.calls,
    navigate: state.callcenter.navigate,
    clients: state.clients.clients,
    employees: state.company.employees,
    fetching_create: state.calls.fetching_create
});

export default connect(mapStateToProps, Object.assign(actions, { fetchSearchClients, clearClients, changeNavigate, changeDate }))(CallCreate);
