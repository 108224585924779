import { api, httpBuildQuery } from 'utils';
import moment from 'moment';

// ------------------------------------
// Constants
// ------------------------------------
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const CHANGE_CLIENT = 'CHANGE_CLIENT';
export const CLEAR_CLIENT = 'CLEAR_CLIENT';

export const REQUEST_ROUTE_ORDERS = 'requestRouteOrders';
export const RECEIVE_ROUTE_ORDERS = 'receiveRouteOrders';

export const CHANGE_ORDERS_PAGE = 'changeOrdersPage';

export const CHANGE_ORDERS_DATES = 'changeOrdersDates';

// ------------------------------------
// Actions
// ------------------------------------

export const changeStatus = (status, e) => {
    if (e) {
        e.preventDefault();
    }
    return {
        type: CHANGE_STATUS,
        status: status
    };
};

export const fetchOrders = (companyId, data, merge = false) => {
    data.sort = 'scheduled_to';
    return function(dispatch) {
        dispatch(requestRouteOrders());
        return api('/v1/partner/orders/company/' + companyId + '?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receiveRouteOrders(res, merge)));
    };
};

function requestRouteOrders() {
    return {
        type: REQUEST_ROUTE_ORDERS
    };
}

function receiveRouteOrders(res, merge) {
    return {
        type: RECEIVE_ROUTE_ORDERS,
        payload: res,
        merge: merge
    };
}

export const changeOrdersPage = (page) => {
    return {
        type: CHANGE_ORDERS_PAGE,
        payload: page
    };
};

export const changeOrdersDates = (value, label) => {
    return {
        type: CHANGE_ORDERS_DATES,
        payload: {
            value: value,
            label: label
        }
    };
};

export const changeClient = (client) => {
    return function(dispatch) {
        dispatch({ type: CHANGE_CLIENT, client: client });
    };
};

const clearClient = () => {
    return function(dispatch) {
        dispatch({ type: CLEAR_CLIENT });
    };
};

export const cancelOrder = (id) => {
    return function(dispatch) {
        dispatch(requestRouteOrders());
        return api('/v1/partner/orders/' + id, {
            method: 'PUT',
            body: JSON.stringify({ origin: 'clubPartner', status: 'CANCELED' })
        }, dispatch);
    };
};

export const executeOrder = (id, details) => {
    return function(dispatch) {
        dispatch(requestRouteOrders());
        return api('/v3/partner/orders/' + id, {
            method: 'PUT',
            body: JSON.stringify({ origin: 'clubPartner', status: 'EXECUTED', orderDetails: details })
        }, dispatch);
    };
};

export const actions = {
    changeStatus,
    changeClient,
    clearClient,
    fetchOrders,
    changeOrdersPage,
    changeOrdersDates,
    cancelOrder,
    executeOrder
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [CHANGE_STATUS]: (state, action) => {
        return ({ ...state, status: action.status });
    },
    [CHANGE_CLIENT]: (state, action) => {
        return ({ ...state, client: action.client });
    },
    [CLEAR_CLIENT]: (state) => {
        return ({ ...state, client: null });
    },
    [REQUEST_ROUTE_ORDERS]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_ROUTE_ORDERS]: (state, action) => {
        const orders = Object.assign({}, action.payload);
        if (action.merge) {
            orders.orders = state.orders.orders.concat(orders.orders);
        }
        return ({ ...state, orders: orders, fetching: false });
    },
    [CHANGE_ORDERS_PAGE]: (state, action) => {
        return ({ ...state, page: action.payload });
    },
    [CHANGE_ORDERS_DATES]: (state, action) => {
        const ordersDates = Object.assign({}, state.ordersDates);
        ordersDates[action.payload.label] = action.payload.value;
        return ({ ...state, ordersDates: ordersDates });
    }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    status: null, // статус отображаемых заказов в компоненте,
    client: null,
    fetching: false,
    page: 1,
    orders: null,
    ordersDates: {
        rangeStart: moment().add(-1, 'M').startOf('day'),
        rangeEnd: moment().endOf('day')
    }
};
export default function cmtOrdersReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
