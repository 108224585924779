/**
 * Created by user on 25.05.2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classes from './Settings.module.scss';
import Preloader from 'components/Preloader';
import Select from 'components/Select';

import './../../../../../../lib/slim/slim.scss';
import Slim from './../../../../../../lib/slim/slim.react.jsx';
import _ from 'lodash';

const IMAGE_FILTERS = [
    { type: 'item', label: 'Красный', value: '#ff000e' },
    { type: 'item', label: 'Зеленый', value: '#00FBC3' },
    { type: 'item', label: 'Синий', value: '#00caff' }
];

class Settings extends React.Component {
    state = {
        poll_header_color: null,
        poll_footer_color: null
    };

    UNSAFE_componentWillMount() {
        this.props.fetchStyles().then(res => {
            const headerColor = res && _.get(_.find(res.payload, ['label', 'poll_header_color']), 'pivot.value', null);

            const footerColor = res && _.get(_.find(res.payload, ['label', 'poll_footer_color']), 'pivot.value', null);
            if (headerColor) {
                this.setState({ poll_header_color: headerColor });
            }
            if (footerColor) {
                this.setState({ poll_footer_color: footerColor });
            }
        });
    }

    UNSAFE_componentWillReceiveProps() {

    }

    changeImageFilter(label, filter) {
        this.setState({ [label]: filter.value });
        this.props.updateStyles({ label: label, data: filter.value });
    }

    imageWillSave(data, ready) {
        ready(data);
    }

    imageDidUpload(label) {
        this.props.removeOkkImage(label);
    }

    getSlim(imageStyle, imageUrl, label, id, className, ratio) {
        return (
            <div className={classes.imageBlock + ' ' + classes[className]}>
                <Slim
                    ratio={ratio}
                    service={process.env.REACT_APP_API_PATH + '/v1/partner/okk/styles?label=' + label}
                    label=''
                    push
                    edit={false}
                    instantEdit
                    buttonEditLabel='Редактировать'
                    buttonRemoveLabel='Удалить'
                    buttonDownloadLabel='Скачать'
                    buttonUploadLabel='Загрузить'
                    buttonCancelLabel='Отмена'
                    buttonConfirmLabel='Ок'
                    maxFileSize='3.5'
                    statusFileSize='Максимально допустимый размер: $0 Мб'
                    minSize={{ width: 10, height: 10 }}
                    statusImageTooSmall='Минимально допустимый размер: $0 пикселей'
                    statusNoSupport='Не поддерживается браузером'
                    statusUnknownResponse='Ошибка загрузки'
                    statusUploadSuccess='Ок'
                    willSave={this.imageWillSave.bind(this)}
                    didUpload={this.imageDidUpload.bind(this, label)}
                >
                    <input type='file' name='data' id={id} />
                </Slim>
                <div className={classes.filter} style={imageStyle} />
                <label htmlFor={id} />
                { imageUrl ? (
                    <div className={classes.img}>
                        <img src={imageUrl} alt='' />
                    </div>
                ) : null }
            </div>
        );
    }

    render() {
        const { styles } = this.props;

        const headerImage = _.find(styles, ['label', 'poll_header_image']);

        const headerImageUrl = _.get(headerImage, 'image.url');

        const headerImageFilter = _.get(_.find(styles, ['label', 'poll_header_color']), 'pivot.value', null);

        const headerImageStyle = headerImageFilter ? { backgroundColor: headerImageFilter } : null;

        const footerImage = _.find(styles, ['label', 'poll_footer_image']);

        const footerImageUrl = _.get(footerImage, 'image.url');

        const footerImageFilter = _.get(_.find(styles, ['label', 'poll_footer_color']), 'pivot.value', null);

        const footerImageStyle = footerImageFilter ? { backgroundColor: footerImageFilter } : null;

        return (
            <div className={classes.wrapper}>
                <div className={classes.visualSettings}>
                    { this.getSlim(headerImageStyle, headerImageUrl, 'poll_header_image', 'pollHeaderImage', 'header', '50:38') }
                    { this.getSlim(footerImageStyle, footerImageUrl, 'poll_footer_image', 'pollFooterImage', 'footer', '75:100') }
                    <div className={classes.firstBlock}>
                        <div className={classes.title}>Настройка внешнего вида ОКК</div>
                        <div className={classes.changeImage}>
                            <Select
                                name='filters'
                                placeholder='Цветофильтр'
                                value={this.state.poll_header_color}
                                options={IMAGE_FILTERS}
                                type='inline'
                                icon
                                onChange={this.changeImageFilter.bind(this, 'poll_header_color')}
                            />
                            <div className={classes.loadImage}>
                                <div>Изображение шапки опроса</div>
                                <label className={classes.button} htmlFor='pollHeaderImage'>
                                    загрузить
                                </label>
                            </div>
                        </div>
                        <div className={classes.pollItem + ' ' + classes.general}>
                            <div className={classes.link}>Блок общей оценки качества</div>
                            <div>1 вопрос, 800 опрошенных</div>
                        </div>
                    </div>
                    <div className={classes.secondBlock}>
                        <div className={classes.pollItem + ' ' + classes.params}>
                            <div className={classes.link}>Блок оценки по параметрам</div>
                            <div>6 вопросов, 800 опрошенных</div>
                        </div>
                        <div className={classes.pollItem + ' ' + classes.wishes}>
                            <div className={classes.link}>Блок пожеланий</div>
                            <div>6 опрос, 800 опрошенных</div>
                        </div>
                        <div className={classes.changeImage}>
                            <Select
                                name='filters'
                                placeholder='Цветофильтр'
                                value={this.state.poll_footer_color}
                                options={IMAGE_FILTERS}
                                type='inline'
                                icon
                                onChange={this.changeImageFilter.bind(this, 'poll_footer_color')}
                            />
                            <div className={classes.loadImage}>
                                <div>Изображение подвала</div>
                                <label className={classes.button} htmlFor='pollFooterImage'>
                                    загрузить
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.additionalSettings}>
                    <div className={classes.pollItem}>
                        <div className={classes.link}>
                            <div>Блок опроса в случае</div>
                            <div>выбора общей оценки</div>
                            <div className={classes.badIcon}>«Плохо»</div>
                        </div>
                        <div>1 опрос, 800 опрошенных</div>
                    </div>
                    <div className={classes.pollItem}>
                        <div className={classes.link}>
                            <div>Блок опроса в случае</div>
                            <div>выбора общей оценки</div>
                            <div className={classes.excellentIcon}>«Вау»</div>
                        </div>
                        <div>1 опрос, 800 опрошенных</div>
                    </div>
                </div>
                <Preloader trigger={this.props.fetching} fixed='true' />
            </div>
        );
    }
}
Settings.contextTypes = {
    router: PropTypes.object
};
export default Settings;
