/**
 * Created by user on 04.10.16.
 */
import React from 'react';
import classes from '../../Calendar.module.scss';
import { getBlockPosition } from 'utils';

class ActivateClientModal extends React.Component {
    renderTrack({ style, ...props }) {
        const trackStyle = {};
        return (
            <div
                className={classes.scrollTrack}
                style={{ ...style, ...trackStyle }}
                {...props}
            />
        );
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {};
        return (
            <div
                className={classes.scrollThumb}
                style={{ ...style, ...thumbStyle }}
                {...props}
            />
        );
    }

    activateClient() {
        this.props.activateClient(this.props.client.id);
    }

    /*    changeClientFields(id, item) {
     this.props.changeField(id, item);
     this.props.editClient();
     }

     selectClientFieldsOuterMenu(item) {
     return (
     <div className={classes.outerMenuFields} onClick={e => {e.stopPropagation();}}>
     <Scrollbars
     thumbSize={16}
     autoHeight={true}
     autoHide={false}
     renderTrackVertical={this.renderTrack}
     renderThumbVertical={this.renderThumb}
     >
     {_.map(item.references, reference => {
     if (reference.parent != 0 &&
     _.find(this.props.client.fields, field => {
     return field.value == reference.parent;
     })) {
     return (
     <div
     key={reference.label}
     className={classes.item}
     onClick={this.changeClientFields.bind(this, reference.id, item)}>
     {reference.title}
     </div>);
     } else if (reference.parent == 0) {
     return (
     <div
     key={reference.label}
     className={classes.item}
     onClick={this.changeClientFields.bind(this, reference.id, item)}>
     {reference.title}
     </div>);
     }
     })}
     </Scrollbars>
     </div>
     );
     }

     activateFieldMask(field) {
     switch (field.label) {
     case 'year':
     return (
     <div className={classes.changeValue + ' ' + classes.inputValue}>
     <InputElement
     type="text"
     name={field.id}
     value={_.find(this.props.client.fields, [ 'id', field.id ]).value || ''}
     onChange={this.props.changeClient}
     mask="9999"
     maskChar="Г"
     />
     {
     this.props.errorMenu && /[_Г]/.test(field.value) ?
     <div className={classes.errorMenu}>
     Неверный формат года
     </div> :
     null
     }
     </div>
     );
     case 'vin':
     return (
     <div className={classes.changeValue + ' ' + classes.inputValue}>
     <InputElement
     className={classes.vin}
     type="text"
     name={field.id}
     value={_.find(this.props.client.fields, [ 'id', field.id ]).value || ''}
     onChange={this.props.changeClient}
     mask="VVV  VVVVVV  VVVVVVVV"
     formatChars={{ 'V': '[A-HJ-NPR-Za-hj-npr-z0-9]' }}
     />
     {
     this.props.errorMenu && /[_Г]/.test(field.value) ?
     <div className={classes.errorMenuVin}>
     Неверный формат Вин
     </div> :
     null
     }
     </div>
     );
     case 'power':
     return (
     <div className={classes.changeValue + ' ' + classes.inputValue}>
     <input
     type="text"
     name={field.id}
     value={_.find(this.props.client.fields, [ 'id', field.id ]).value || ''}
     onChange={this.props.changeClient}
     />
     <div className={classes.power}>&nbsp;&nbsp;л.с.</div>
     {this.props.errorMenu && !/^\d+$/.test(field.value) && field.value ?
     <div className={classes.errorMenu} onClick={e => e.stopPropagation()}>
     В этом поле можно ввести только цифры
     </div> :
     null
     }
     </div>
     );
     default:
     return (
     <div className={classes.changeValue + ' ' + classes.inputValue}>
     <input
     type="text"
     name={field.id}
     value={_.find(this.props.client.fields, [ 'id', field.id ]).value || ''}
     onChange={this.props.changeClient}
     />
     </div>
     );
     }
     }

     checkDependentField(field) { //обнуление значения поля при изменении значения родительского поля
     if (field.type === 'reference' && field.value) {
     let parentId;
     parentId = _.find(field.references, reference => {
     return reference.id == field.value;
     }).parent;
     if (parentId && !_.find(this.props.client.fields, item => {
     return item.value == parentId;
     })) {
     this.props.changeField(null, field);
     }
     }
     } */

    render() {
        /*        let activateFields = [];
         _.map(_.orderBy(this.props.client.fields, [ 'sort' ], [ 'asc' ]), item => {
         if (item.field_group_id === 1) {
         this.checkDependentField(item);
         activateFields.push(
         <div className={classes.activateData} key={'data' + item.id}>
         <div className={classes.fieldTitle} key={'title' + item.id}>{item.title}:</div>
         {item.type === 'reference' ?
         <div className={classes.changeValue} onClick={this.props.editClient.bind(this, item.id)}>
         <div className={classes.value}>
         {
         item.value
         ? _.find(item.references, reference => {
         return reference.id == item.value;
         }).title
         : 'Не выбрано'
         }
         </div>
         <div className={classes.select}></div>
         {this.props.editClientChange === item.id ? this.selectClientFieldsOuterMenu(item) : null}
         </div> :
         this.activateFieldMask(item)
         }
         </div>
         );
         }
         }); */

        return (
            <div
                className={classes.black}
                onClick={e => {
                    e.stopPropagation();
                    if (this.props.cancel) {
                        this.props.cancel();
                    }
                }}
            >
                <div
                    className={classes.wrapper} style={getBlockPosition(200, 771)}
                    onClick={e => {
                        e.stopPropagation();
                    }}
                >
                    <div className={classes.activateClient}>
                        <div className={classes.activateClientIcon} />
                        <div className={classes.activateClientGradient} />
                        <div className={classes.activateClientMain}>
                            <div className={classes.activateHeader}>
                                <div className={classes.activateClientTitle}>Активация
                                    клиента { this.props.client.nick }
                                </div>
                                { /* <div className={classes.activateText}>Для активации клиента введите его данные</div> */ }
                            </div>
                            { /* {activateFields} */ }
                            <div className={classes.buttons}>
                                <div
                                    className={classes.cancel} onClick={(e) => {
                                        e.preventDefault();
                                        if (this.props.cancel) {
                                            this.props.cancel();
                                        }
                                    }}
                                >отмена
                                </div>
                                <div
                                    className={classes.activateClientButton}
                                    onClick={this.activateClient.bind(this)}
                                >Активировать
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ActivateClientModal;
