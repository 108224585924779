import React from 'react';
import classes from './Question.module.scss';

export const Question = (props) => (
    <div className={classes.question} onClick={props.onClick}>
        <div className={classes.content} onClick={e => e.stopPropagation()}>
            <div className={classes.header}>Добавление вопроса</div>
            <div className={classes.body}>
                <div className={classes.title}>Вопрос клиентам</div>
                <textarea
                    rows='3'
                    placeholder='Текст вопроса'
                    value={props.data.title}
                    onChange={props.changeQuestionTitle.bind(this)}
                    name='question'
                />
                <div className={classes.title}>Варианты ответов</div>
                <div className={classes.answer}>
                    <div>1 вариант ответа:&nbsp;</div>
                    <div className={classes.orange}>Да (лайк)</div>
                </div>
                <div className={classes.answer}>
                    <div>2 вариант ответа:&nbsp;</div>
                    <div className={classes.orange}>Нет (дизлайк)</div>
                </div>
                <div className={classes.btnContainer}>
                    { props.data.id
                        ? (
                            <div
                                className={classes.button + ' ' + classes.archive}
                                onClick={props.archive.bind(this, props.data.id)}
                            >
                                В архив
                            </div>
                        )
                        : null }
                    <div className={classes.cancel} onClick={props.onClick}>Отмена</div>
                    <div
                        className={classes.button + ' ' + (props.data.title ? classes.active : '')}
                        onClick={props.save.bind(this, props.data)}
                    >
                        Сохранить
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Question;
