/**
 * Created by user on 25.05.2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import Select from 'components/Select';
import Preloader from 'components/Preloader';
import PollCard from '../../Modals/PollCard';
import { declOfNum, httpBuildQuery } from 'utils';
import classes from '../Reports.module.scss';

const IMAGE_FILTERS = [
    { type: 'item', label: 'Красный', value: 'red' },
    { type: 'item', label: 'Зеленый', value: 'green' },
    { type: 'item', label: 'Синий', value: 'blue' }
];

class ReportByAnswer extends React.Component {
    state = {
        color: null
    };

    UNSAFE_componentWillMount() {
        const { location } = this.props;
        if (_.get(location, 'query.question_id') && _.get(location, 'query.answer_id')) {
            this.props.fetchReportsByAnswer(location.query);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.showPollCard && !this.props.showPollCard) {
            const body = document.querySelectorAll('body');

            const html = document.querySelectorAll('html');
            if (body[0] && body[0].style) {
                body[0].style.overflowY = 'hidden';
            }
            if (html[0] && html[0].style) {
                html[0].style.overflowY = 'hidden';
            }
        }
        if (!nextProps.showPollCard && this.props.showPollCard) {
            const body = document.querySelectorAll('body');

            const html = document.querySelectorAll('html');
            if (body[0] && body[0].style) {
                body[0].style.overflowY = 'auto';
            }
            if (html[0] && html[0].style) {
                html[0].style.overflowY = 'auto';
            }
        }
    }

    changeSort(sort) {
        this.props.changeSort(sort.value);
    }

    showPollCard(id) {
        this.props.switchPollCard();
        this.props.fetchVisitResult(id);
    }

    closePollCard() {
        this.props.resetPollCardData();
        this.props.switchPollCard();
    }

    exportResults(ids) {
        const data = Object.assign({}, this.props.sort);

        data.question_id = _.get(this.props.location, 'query.question_id');
        data.answer_id = _.get(this.props.location, 'query.answer_id');

        let formattedIds = '';
        if (ids) {
            _.map(ids, (id, key) => {
                formattedIds += `&ids[${key}]=${id}`;
            });
        }

        this.props.exportByAnswerResults(data, formattedIds);
    }

    changeAnswer(answer) {
        const { location } = this.props;

        const answerId = answer.value;

        location.query.answer_id = answerId;

        this.props.history.replace('/okk/reports/by-answer?' + httpBuildQuery(location.query));
        this.props.fetchReportsByAnswer(location.query);
    }

    render() {
        const reportsByAnswer = _.get(this.props.reportsByAnswer, 'items');

        const question = _.get(this.props.reportsByAnswer, 'question');

        const answerId = _.get(this.props.location, 'query.answer_id');

        const chosenIds = _.get(this.props.reportsByAnswer, 'chosenIds');

        const chosenCount = chosenIds ? chosenIds.length : 0;
        if (!reportsByAnswer) {
            return null;
        }

        const answers = [];
        _.map(question.answers, answer => {
            answers.push({ type: 'item', label: answer.title, value: answer.id });
        });

        return (
            <div className={classes.reports}>
                { this.props.showPollCard && this.props.pollCardData
                    ? <PollCard onClick={this.closePollCard.bind(this)} data={this.props.pollCardData} />
                    : null }
                <div className={classes.filter}>Фильтр данных</div>
                <div className={classes.wrapper}>
                    <div className={classes.header}>
                        <div className={classes.title}>
                            <span>Список клиентов, ответивших&nbsp;</span>
                            <Select
                                name='answers'
                                placeholder='...'
                                value={answerId}
                                options={answers}
                                type='inline'
                                icon
                                onChange={this.changeAnswer.bind(this)}
                            />
                            <span>&nbsp;({ reportsByAnswer.length } { declOfNum(chosenCount, ['человек', 'человека', 'человек']) })</span>
                            <span>в опросе «{ question.title }»</span>
                        </div>
                        <div className={classes.filter}>
                            <div className={classes.text}>Сортировать по</div>
                            <Select
                                name='filters'
                                placeholder='дате, сначала новые'
                                value={this.state.color}
                                options={IMAGE_FILTERS}
                                type='inline'
                                icon
                                onChange={this.changeSort.bind(this)}
                            />
                            <div className={classes.export} onClick={this.exportResults.bind(this, null)}>Экспорт всего списка в excel</div>
                        </div>
                    </div>
                    <div className={classes.list}>
                        { reportsByAnswer && reportsByAnswer.length
                            ? (
                                <div className={classes.header}>
                                    <div className={classes.name}>Фамилия и имя</div>
                                    <div className={classes.date}>Дата ответа ОКК</div>
                                    <div className={classes.card}>Переход на карточку</div>
                                    <div className={classes.add}>Добавить<br /> в список</div>
                                </div>
                            )
                            : null }
                        { _.map(reportsByAnswer, report => {
                            let date;
                            if (report.completed_at) {
                                date = moment(report.completed_at).format('DD MMMM`YY');
                            } else {
                                if (report.status === 'COMPLETED') {
                                    date = moment(report.updated_at).format('DD MMMM`YY');
                                } else {
                                    date = 'Не завершен';
                                }
                            }
                            return (
                                <div key={report.id} className={classes.item}>
                                    <div className={classes.name}>{ report.client.name }</div>
                                    <div className={classes.date}>
                                        { date }
                                    </div>
                                    <div className={classes.card} onClick={this.showPollCard.bind(this, report.id)}>
                                        карточка опроса
                                    </div>
                                    <div
                                        className={classes.add + ' ' + (report.chosen ? classes.active : '')}
                                        onClick={this.props.chooseReportByAnswer.bind(this, report.id)}
                                    />
                                </div>
                            );
                        }) }
                    </div>
                    <div className={classes.footer + ' ' + (chosenCount ? classes.active : '')}>
                        <div className={classes.content}>
                            <div className={classes.reportsCount}>Выбрано { chosenCount } { declOfNum(chosenCount, ['отзыв', 'отзыва', 'отзывов']) }</div>
                            <div className={classes.export} onClick={this.exportResults.bind(this, chosenIds)}>
                                Экспорт выбранных в excel
                            </div>
                            <div className={classes.addList}>Создрать/добавить список</div>
                        </div>
                    </div>
                </div>
                <Preloader trigger={this.props.fetching} fixed='true' />
            </div>
        );
    }
}
ReportByAnswer.contextTypes = {
    router: PropTypes.object
};
export default ReportByAnswer;
