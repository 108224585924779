import OkkLayout from 'layouts/OkkLayout';
import Main from './Main';
import CustomLists from './CustomLists';
import Archive from './Archive';
import Reports from './Reports';

export const OkkRoute = (store) => ({
    path: 'okk',
    component: OkkLayout,
    indexRoute: Main(store),
    childRoutes: [
        CustomLists(store),
        Archive(store),
        Reports(store)
    ]
});

export default OkkRoute;
