import moment from 'moment';
import { api, httpBuildQuery } from './../utils';
import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
const CHANGE_CURRENT_DIVISION = 'changeCurrentDivision';
const SHOW_HIDE_DP = 'showHideDp';
const CHANGE_DATE = 'changeDate';

const REQUEST_COMPANY = 'requestCompany';
const RECEIVE_COMPANY = 'receiveCompany';
const COMPANY_IS_UPDATED = 'companyIsUpdated';
const MERGE_COMPANY = 'mergeCompany';

const RECEIVE_DIVISIONS = 'receiveDivisions';

const REQUEST_LOGO = 'requestLogo';
const RECEIVE_LOGO = 'receiveLogo';

const REQUEST_SCHEDULE = 'requestSchedule';
const RECEIVE_SCHEDULE = 'receiveSchedule';

const REQUEST_SCHEDULES = 'requestSchedules';
const RECEIVE_SCHEDULES = 'receiveSchedules';
const UPDATE_SCHEDULES = 'updateSchedules';

const REQUEST_SERVICES = 'requestServices';
const RECEIVE_SERVICES = 'receiveServices';
const REQUEST_SERVICE = 'requestService';
const RECEIVE_SERVICE = 'receiveService';
const UPDATE_SERVICE = 'updateService';
const SERVICE_IS_UPDATED = 'serviceIsUpdated';
const CREATE_SERVICES = 'createServices';
const SERVICES_ARE_CREATED = 'servicesAreCreated';
const SERVICE_IS_CREATED = 'serviceIsCreated';
const SERVICE_IS_DELETED = 'serviceIsDeleted';
const CHANGE_SERVICE_PRICELIST = 'changeServicePriceList';
const REMOVE_SERVICE_PRICELIST = 'removeServicePriceList';
const RECEIVE_EMPLOYEES = 'receiveEmployees';
const REQUEST_EMPLOYEES = 'requestEmployees';

const DELETE_RESOURCE = 'deleteResource';

const CHANGE_DP_OVER = 'changeDpOver';

const CHANGE_COMPANY = 'changeCompany';
const CHANGE_COMPANY_FIELD = 'changeCompanyField';

const CLEAR_COMPANY_LOGO = 'clearCompanyLogo';
const CLEAR_COMPANY_COVER = 'clearCompanyCover';
const CHANGE_COMPANY_COVER = 'changeCompanyCover';

const DELETE_FROM_FILES = 'deleteFromFiles';
const PUSH_COMPANY_FILE = 'pushCompanyFile';

const SET_EMPLOYEE_SCHEDULE = 'setSchedule';

const ADD_RESOURCE_AVATAR = 'addResourceAvatar';
const REMOVE_RESOURCE_AVATAR = 'removeResourceAvatar';

const REQUEST_RESOURCES = 'requestResources';
const RECEIVE_RESOURCES = 'receiveResources';

const RESOURCE_IS_CREATED = 'resourceIsCreated';
const RESOURCE_IS_UPDATED = 'resourceIsUpdated';

const INITIAL_FIELDS_VALUE = [
    {
        label: 'company_phones',
        value: []
    },
    {
        label: 'company_city',
        value: ''
    },
    {
        label: 'company_address',
        value: {}
    },
    {
        label: 'company_worktime',
        value: '111111111111111111111111111111111111111111111111'
    },
    {
        label: 'company_workdays',
        value: []
    },
    {
        label: 'company_specs',
        value: []
    },
    {
        label: 'company_booking_limit',
        value: 10
    },
    {
        label: 'company_desc',
        value: ''
    },
    {
        label: 'company_short_desc',
        value: ''
    },
    {
        label: 'company_pre_booking_limit',
        value: ''
    },
    {
        label: 'company_foundation_year',
        value: ''
    },
    {
        label: 'company_site_url',
        value: ''
    },
    {
        label: 'company_warranty',
        value: ''
    }
];

// ------------------------------------
// Actions
// ------------------------------------

export const editSchedulesChunks = (chunks) => {
    return function(dispatch) {
        const schedulesToLoad = [];
        _.each(chunks, (resource, resourceId) => {
            const _schedule = { resourceId: resourceId };
            const dates = _.map(resource, (sch, date) => {
                return date;
            });
            let csRangeStart = _.find(dates); let csRangeEnd = _.find(dates);
            _.each(dates, date => {
                // определяем диапазон дат в отредактированных расписаниях дабы получить существующие
                // касмотныее расписания по указанному диапазону
                if (moment(date).isBefore(moment(csRangeStart))) {
                    csRangeStart = date;
                }
                if (moment(date).isAfter(moment(csRangeEnd))) {
                    csRangeEnd = date;
                }
            });
            _schedule.csRangeStart = csRangeStart;
            _schedule.csRangeEnd = csRangeEnd;
            schedulesToLoad.push(_schedule);
        });
        Promise.all(_.map(schedulesToLoad, (sch) => {
            return api('/v1/partner/resources/' + sch.resourceId + '/custom-schedules?' + httpBuildQuery({
                range_start: sch.csRangeStart,
                range_end: sch.csRangeEnd
            }), {
                method: 'GET'
            }, dispatch).then((res) => {
                res.resourceId = sch.resourceId;
                return res;
            });
        })).then((res) => {
            const existSchedules = []; const schedulesToDelete = [];
            _.each(res, rSch => {
                _.each(_.get(rSch, 'schedules.resourceCustomSchedules', []), sch => {
                    existSchedules.push({
                        resourceId: rSch.resourceId,
                        date: sch.date,
                        includePattern: sch.includePattern,
                        excludePattern: sch.excludePattern
                    });
                    schedulesToDelete.push(sch.id);
                });
            });
            const defaultSchedule = {
                excludePattern: _.fill(Array(48), 0).join(''),
                includePattern: _.fill(Array(48), 0).join('')
            };
            const toSave = {};
            _.each(chunks, (resource, resourceId) => {
                toSave[resourceId] = [];
                _.each(resource, (sch, date) => {
                    const existSchedule = _.find(existSchedules, {
                        date: moment(date, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss') + 'Z',
                        resourceId: resourceId
                    }) || defaultSchedule;
                    const newSchedule = {
                        date: date,
                        excludePattern: existSchedule.excludePattern.split(''),
                        includePattern: existSchedule.includePattern.split('')
                    };
                    _.each(sch.exclude, chunk => {
                        newSchedule.includePattern[chunk] = 0;
                        newSchedule.excludePattern[chunk] = 1;
                    });
                    _.each(sch.include, chunk => {
                        newSchedule.excludePattern[chunk] = 0;
                        newSchedule.includePattern[chunk] = 1;
                    });
                    newSchedule.excludePattern = newSchedule.excludePattern.join('');
                    newSchedule.includePattern = newSchedule.includePattern.join('');
                    toSave[resourceId].push(newSchedule);
                });
            });
            const updateSchedulesHandler = function() {
                Promise.all(_.map(toSave, (schedules, resourceId) => {
                    return api('/v1/partner/resources/' + resourceId + '/custom-schedules', {
                        method: 'PUT',
                        body: JSON.stringify({ schedules: schedules })
                    }, dispatch);
                })).then(() => {
                    dispatch(updateSchedules(toSave));
                });
            };

            if (schedulesToDelete.length === 0) {
                updateSchedulesHandler();
            } else {
                api('/v1/partner/resources/custom-schedules', {
                    method: 'DELETE',
                    body: JSON.stringify({ ids: schedulesToDelete })
                }, dispatch).then(() => {
                    updateSchedulesHandler();
                });
            }

            /*            _.each(dates, date => {
             let existSchedule = _.find(existSchedules, { date: moment(date).format('YYYY-MM-DDTHH:mm:ss') + 'Z', resourceId }) || defaultSchedule;
             let newSchedule = {
             date: date,
             excludePattern: existSchedule.excludePattern.split(''),
             includePattern: existSchedule.includePattern.split('')
             };
             _.each(resource[ date ].exclude, chunk => {
             newSchedule.includePattern[ chunk ] = 0;
             newSchedule.excludePattern[ chunk ] = 1;
             });
             _.each(resource[ date ].include, chunk => {
             newSchedule.excludePattern[ chunk ] = 0;
             newSchedule.includePattern[ chunk ] = 1;
             });
             newSchedule.excludePattern = newSchedule.excludePattern.join('');
             newSchedule.includePattern = newSchedule.includePattern.join('');
             toSave.push(newSchedule);
             }); */
        });
        /* _.each(chunks, (resource, resourceId) => {
         let toSave = [];
         let dates = _.map(resource, (sch, date) => {
         return date;
         });
         if (!_.isEmpty(dates)) {
         let csRangeStart = _.find(dates), csRangeEnd = _.find(dates);
         _.each(dates, date => {
         //определяем диапазон дат в отредактированных расписаниях дабы получить существующие
         // касмотныее расписания по указанному диапазону
         if (moment(date).isBefore(moment(csRangeStart))) {
         csRangeStart = date;
         }
         if (moment(date).isAfter(moment(csRangeEnd))) {
         csRangeEnd = date;
         }
         });
         /!*                api('/v1/partner/resources/' + resourceId + '/custom-schedules?' + httpBuildQuery({
         range_start: csRangeStart,
         range_end: csRangeEnd
         }), {
         method: 'GET'
         }, dispatch)
         .then((res) => {
         let schToDelete = [];
         _.each(_.get(res, 'schedules.resourceCustomSchedules'), sch => {
         schToDelete.push(sch.id);
         });
         let defaultSchedule = {
         excludePattern: _.fill(Array(48), 0).join(''),
         includePattern: _.fill(Array(48), 0).join('')
         }
         _.each(dates, date => {
         let existSchedule = _.find(_.get(res, 'schedules.resourceCustomSchedules'), { date: moment(date).format('YYYY-MM-DDTHH:mm:ss') + 'Z' }) || defaultSchedule;
         let newSchedule = {
         date: date,
         excludePattern: existSchedule.excludePattern.split(''),
         includePattern: existSchedule.includePattern.split('')
         };
         _.each(resource[ date ].exclude, chunk => {
         newSchedule.includePattern[ chunk ] = 0;
         newSchedule.excludePattern[ chunk ] = 1;
         });
         _.each(resource[ date ].include, chunk => {
         newSchedule.excludePattern[ chunk ] = 0;
         newSchedule.includePattern[ chunk ] = 1;
         });
         newSchedule.excludePattern = newSchedule.excludePattern.join('');
         newSchedule.includePattern = newSchedule.includePattern.join('');
         toSave.push(newSchedule);
         });
         let _updateschedules = function () {
         api('/v1/partner/resources/' + resourceId + '/custom-schedules', {
         method: 'PUT',
         body: JSON.stringify({ schedules: toSave })
         }, dispatch)
         .then((res) => {
         dispatch(updateSchedules(resourceId, toSave));
         });
         }.bind(this);

         if (schToDelete.length == 0) {
         _updateschedules();
         } else {
         api('/v1/partner/resources/custom-schedules', {
         method: 'DELETE',
         body: JSON.stringify({ ids: schToDelete })
         }, dispatch).then(()=> {
         _updateschedules();
         });
         }
         });*!/

         ////////////////////////////////////////////////////////////////////

         }
         }) */
    };
};

function updateSchedules(schedules) {
    return {
        type: UPDATE_SCHEDULES,
        payload: schedules
    };
}

export const updateEmployeeSchedule = (id, date) => {
    return function(dispatch) {
        return api('/v1/partner/employees/schedules/' + id, {
            method: 'PUT',
            body: JSON.stringify(date)
        }, dispatch)
            .then(res => {
                if (res) {
                    dispatch(setEmployeeSchedule(id));
                }
            });
    };
};

function setEmployeeSchedule(id) {
    return {
        type: SET_EMPLOYEE_SCHEDULE,
        payload: id
    };
}

export const fetchEmployees = (data) => {
    return function(dispatch) {
        dispatch(requestEmployees());
        return api('/v1/partner/employees/?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receiveEmployees(res)));
    };
};

function receiveEmployees(res) {
    return {
        type: RECEIVE_EMPLOYEES,
        payload: res
    };
}

function requestEmployees() {
    return {
        type: REQUEST_EMPLOYEES
    };
}

export const fetchLogo = (id) => {
    return function(dispatch) {
        dispatch(requestLogo());
        return api('/v1/partner/companies/' + id + '/logo', {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveLogo(response)));
    };
};

function requestLogo() {
    return {
        type: REQUEST_LOGO
    };
}

function receiveLogo(response) {
    return {
        type: RECEIVE_LOGO,
        data: response
    };
}

export const fetchSchedules = (dates) => {
    return function(dispatch) {
        dispatch(requestSchedules());
        return api('/v1/partner/resources/schedules?apply_past_time=0&range_start=' + dates[0] + '&range_end=' + dates[1], {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveSchedules(response)));
    };
};

function requestSchedules() {
    return {
        type: REQUEST_SCHEDULES
    };
}

function receiveSchedules(response) {
    return {
        type: RECEIVE_SCHEDULES,
        data: response.schedules
    };
}

export const updateDivisions = (divisions) => {
    return function(dispatch) {
        return api('/v1/partner/divisions/bulk-update', {
            method: 'PUT',
            body: JSON.stringify(divisions)
        }, dispatch).then(responce => {
            dispatch(receiveDivisions(responce));
        });
    };
};

function receiveDivisions(responce) {
    return {
        type: RECEIVE_DIVISIONS,
        payload: responce

    };
}

export const fetchResources = () => {
    return function(dispatch) {
        dispatch(requestResources());
        return api('/v1/partner/resources?with_schedules=1', {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveResources(response)));
    };
};

function requestResources() {
    return {
        type: REQUEST_RESOURCES
    };
}

function receiveResources(response) {
    return {
        type: RECEIVE_RESOURCES,
        payload: response
    };
}

export const createResource = (resource) => {
    return function(dispatch) {
        return api('/v1/partner/resources', {
            method: 'POST',
            body: JSON.stringify(resource)
        }, dispatch).then((res) => {
            if (res.resource) {
                return dispatch({ type: RESOURCE_IS_CREATED, payload: res });
            }
        });
    };
};

export const updateResource = (resource) => {
    return function(dispatch) {
        return api('/v3/partner/resources/' + resource.id, {
            method: 'PUT',
            body: JSON.stringify(resource)
        }).then(() => {
            return dispatch({ type: RESOURCE_IS_UPDATED, resource: resource });
        });
    };
};

export const deleteResource = (resourceId) => {
    return function(dispatch) {
        return api('/v1/partner/resources/' + resourceId, {
            method: 'DELETE'
        }, dispatch)
            .then(() => dispatch(removeResourceFromState(resourceId)));
    };
};

export const deleteResourceAvatar = (resourceId) => {
    return function(dispatch) {
        return api('/v1/partner/resources/' + resourceId + '/avatar', {
            method: 'DELETE'
        }, dispatch)
            .then((res) => {
                if (res.id) {
                    return dispatch(removeResourceAvatar(res.id));
                }
            });
    };
};

function removeResourceAvatar(id) {
    return {
        type: REMOVE_RESOURCE_AVATAR,
        payload: id
    };
}

function removeResourceFromState(resourceId) {
    return {
        type: DELETE_RESOURCE,
        resourceId: resourceId
    };
}

export const changeCompany = (path, value) => {
    return {
        type: CHANGE_COMPANY,
        path: path,
        value: value
    };
};

export const changeCompanyField = (label, value) => {
    return {
        type: CHANGE_COMPANY_FIELD,
        label: label,
        value: value
    };
};

export const fetchServices = (resourceId) => {
    return function(dispatch) {
        dispatch(requestServices());
        return api('/v3/partner/services?resource_id=' + resourceId + '&expand=serviceOptions,serviceTemplate,serviceOptionTemplates,serviceOptionReference,reference,values', {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveServices(resourceId, response)));
    };
};

export const changeDpOver = (is) => {
    return {
        type: CHANGE_DP_OVER,
        is: is
    };
};

export const changeServiceCatalogue = (resourceId, catalogueId) => {
    return function(dispatch) {
        dispatch(requestServices());
        return api('/v1/partner/services?resource_id=' + resourceId + '&catalogue_id=' + catalogueId, {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveServices(resourceId, catalogueId, response)));
    };
};

export const requestServices = () => {
    return {
        type: REQUEST_SERVICES
    };
};

export const receiveServices = (resourceId, response) => {
    return {
        type: RECEIVE_SERVICES,
        resourceId: resourceId,
        data: response
    };
};

export const changeDate = (date) => {
    return {
        type: CHANGE_DATE,
        date: date
    };
};

export const showHideDp = (e) => {
    if (e) {
        e.preventDefault();
    }
    return {
        type: SHOW_HIDE_DP
    };
};

export const changeCurrentDivision = (id) => {
    return {
        type: CHANGE_CURRENT_DIVISION,
        id: id
    };
};

export const fetchSchedule = (resourceId, dates) => {
    return function(dispatch) {
        dispatch(requestSchedule(resourceId));
        return api('/v1/partner/resources/schedules?resource_angara_id=' + resourceId + '&range_start=' + dates.range_start + '&range_end=' + dates.range_end, {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveSchedule(resourceId, response)));
    };
};

function requestSchedule(resourceId) {
    return {
        type: REQUEST_SCHEDULE,
        resourceId: resourceId
    };
}

function receiveSchedule(resourceId, response) {
    return {
        type: RECEIVE_SCHEDULE,
        resourceId: resourceId,
        payload: response
    };
}

export const deleteCompanyLogo = (id) => {
    return function(dispatch) {
        return api('/v1/partner/companies/' + id + '/images?scheme=logo', {
            method: 'DELETE'
        })
            .then(dispatch(clearCompanyLogo()));
    };
};

function clearCompanyLogo() {
    return {
        type: CLEAR_COMPANY_LOGO
    };
}

export const deleteCompanyCover = (id) => {
    return function(dispatch) {
        return api('/v1/partner/companies/' + id + '/images?scheme=cover', {
            method: 'DELETE'
        })
            .then(dispatch(clearCompanyCover()));
    };
};

function clearCompanyCover() {
    return {
        type: CLEAR_COMPANY_COVER
    };
}

export const deleteCompanyFile = (id, fileId) => {
    return function(dispatch) {
        return api('/v1/partner/companies/' + id + '/images/' + fileId, {
            method: 'DELETE'
        })
            .then(dispatch(deleteFromFiles(fileId)));
    };
};

function deleteFromFiles(fileId) {
    return {
        type: DELETE_FROM_FILES,
        fileId: fileId
    };
}

export const pushCompanyFile = (file) => {
    return {
        type: PUSH_COMPANY_FILE,
        file: file
    };
};

export const saveCompany = (data, expand) => {
    return function(dispatch) {
        dispatch(requestCompany());
        expand = expand ? '?expand=' + expand.join(',') : '';
        return api('/v3/partner/companies/' + data.id + expand, {
            method: 'PUT',
            body: JSON.stringify(data)
        }, dispatch)
            .then(response => dispatch(receiveCompany(response)));
    };
};

export const fetchCompany = (id, expand) => {
    return function(dispatch) {
        dispatch(requestCompany());
        expand = expand ? '?expand=' + expand.join(',') : '';
        return api(
            '/v1/partner/companies/' + id + expand, {
                method: 'GET'
            }, dispatch)
            .then(response => dispatch(receiveCompany(response)));
    };
};

export const requestCompany = () => {
    return {
        type: REQUEST_COMPANY
    };
};

export const receiveCompany = (response) => {
    return {
        type: RECEIVE_COMPANY,
        payload: response
    };
};
export const mergeCompany = (response) => {
    return {
        type: MERGE_COMPANY,
        payload: response
    };
};

export const updateCompanySpecs = (id, specs) => {
    return function(dispatch) {
        return api('/v3/partner/companies/' + id, {
            method: 'PUT',
            body: JSON.stringify({ fields: [{ label: 'company_specs', type: 'array', value: specs }] })
        }, dispatch)
            .then(() => dispatch({ type: COMPANY_IS_UPDATED }));
    };
};

export const addResourceAvatar = (resource) => {
    return {
        type: ADD_RESOURCE_AVATAR,
        payload: resource
    };
};

export const changeCompanyCover = (cover) => {
    return {
        type: CHANGE_COMPANY_COVER,
        payload: cover
    };
};

export const createServices = (services) => {
    // TODO никаких нормалайзеров здесь, как пришло - так и ушло
    return (dispatch) => {
        const bodyServices = [];
        _.each(services, service => {
            const body = {
                average_duration: service.averageDuration,
                catalogue_id: service.catalogueId,
                min_price: service.minPrice
            };
            if (service.comment) {
                body.comment = service.comment;
            }
            if (service.isPeriodic !== false) {
                body.period = service.period;
                body.repeats_during_period = service.repeats_during_period;
            }

            if (service.isCustom) {
                body.name = service.name;
            } else {
                body.service_template_id = service.serviceTemplateId;
            }

            if (service.serviceOptions && service.serviceOptions.length) {
                body.service_options = _.map(service.serviceOptions, so => {
                    return { template_id: so.serviceOptionTemplate.id, value_by_ref_id: so.valueByReference.id };
                });
            }
            bodyServices.push(body);
        });
        dispatch({ type: CREATE_SERVICES });
        return api('/v3/partner/services/bulk-store', {
            method: 'POST',
            body: JSON.stringify(bodyServices)
        }, dispatch)
            .then(res => {
                dispatch({ type: SERVICES_ARE_CREATED, payload: res });
                return res;
            });
    };
};

export const createService = (service) => {
    return (dispatch) => {
        dispatch({ type: REQUEST_SERVICE });
        const body = {
            average_duration: service.averageDuration,
            catalogue_id: service.catalogueId,
            min_price: service.minPrice
        };
        if (service.comment) {
            body.comment = service.comment;
        }
        if (service.isPeriodic !== false) {
            body.period = service.period;
            body.repeats_during_period = service.repeatsDuringPeriod;
        }

        if (service.isCustom) {
            body.name = service.name;
        } else {
            body.service_template_id = service.serviceTemplateId;
        }

        if (service.serviceOptions && service.serviceOptions.length) {
            body.service_options = _.map(service.serviceOptions, so => {
                return { template_id: so.serviceOptionTemplate.id, value_by_ref_id: so.valueByReference.id };
            });
        }

        return api('/v3/partner/services/', {
            method: 'POST',
            body: JSON.stringify(body)
        }, dispatch)
            .then(res => {
                if (res.items) {
                    dispatch({ type: SERVICE_IS_CREATED, payload: res.items[0] });
                } else {
                    // dispatch(responseError(res));
                }
                return res;
            });
    };
};

export const updateService = (service) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_SERVICE });
        const data = {
            average_duration: service.averageDuration,
            catalogue_id: service.serviceTemplate.catalogue.id,
            min_price: service.minPrice,
            service_id: service.id,
            is_custom: service.isCustom,
            comment: service.comment
        };

        if (service.isCustom) {
            data.name = service.name;
        }

        data.service_template_id = service.serviceTemplateId || _.get(service, 'serviceTemplate.id');

        if (service.isPeriodic !== false && service.period && service.repeats_during_period) {
            data.period = service.period;
            data.repeats_during_period = service.repeats_during_period;
        }

        data.is_reference = !!service.serviceOptions.length;

        if (service.serviceOptions && service.serviceOptions.length) {
            data.service_options = _.map(service.serviceOptions, so => {
                return { template_id: so.serviceOptionTemplate.id, value_by_ref_id: so.valueByReference.id };
            });
        }

        return api('/v3/partner/services/', {
            method: 'PUT',
            body: JSON.stringify(data)
        }, dispatch)
            .then(res => {
                if (res.items) {
                    dispatch({ type: SERVICE_IS_UPDATED, payload: res.items[0] });
                } else {
                    // dispatch(responseError(res));
                }
            });
    };
};

export const deleteService = (id) => {
    return function(dispatch) {
        dispatch({ type: REQUEST_SERVICES });
        return api('/v1/partner/services/', {
            method: 'DELETE',
            body: JSON.stringify({
                service_id: id
            })
        }, dispatch)
            .then(res => {
                if (res.ids) {
                    dispatch({ type: SERVICE_IS_DELETED, payload: id });
                }
            });
    };
};

export const updatePriceList = (serviceId, file) => {
    return {
        type: CHANGE_SERVICE_PRICELIST,
        serviceId: serviceId,
        file: file
    };
};

export const removePriceList = (serviceId) => {
    return {
        type: REMOVE_SERVICE_PRICELIST,
        serviceId: serviceId
    };
};

export const deleteServicePriceList = (serviceId) => {
    return function(dispatch) {
        dispatch(removePriceList(serviceId));
        return api('/v3/partner/services/' + serviceId +'/price-list', {
            method: 'DELETE',
        }, dispatch)
            .then(res => {

            });
    };
}

export const actions = {
    fetchCompany,
    fetchResources,
    fetchServices,
    fetchLogo,
    showHideDp,
    changeDate,
    fetchSchedule,
    changeCompany,
    changeCompanyField,
    saveCompany,
    updateCompanySpecs,
    deleteCompanyLogo,
    deleteCompanyFile,
    pushCompanyFile,
    createResource,
    updateResource,
    deleteResourceAvatar,
    fetchSchedules,
    fetchEmployees,
    updateEmployeeSchedule,
    editSchedulesChunks,
    addResourceAvatar,
    changeCompanyCover,
    createServices,
    updateService,
    deleteService,
    updatePriceList,
    removePriceList,
    deleteServicePriceList
};
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [REQUEST_COMPANY]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.company = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_COMPANY]: (state, action) => {
        if (action.payload !== false) {
            const fetching = Object.assign({}, state.fetching);
            fetching.company = false;
            // выбираем активным первое подразделение
            let currentDivision = state.currentDivision;
            if (!currentDivision) {
                currentDivision = _.get(_.find(_.get(action.payload, 'company.divisions', null)), 'id', null);
            }

            if (_.get(action.payload, 'company.fields', []).length === 0) {
                action.payload.company.fields = INITIAL_FIELDS_VALUE;
            } else {
                _.map(INITIAL_FIELDS_VALUE, field => {
                    if (!_.some(action.payload.company.fields, { label: field.label })) {
                        action.payload.company.fields.push(field);
                    }
                });
            }

            // TODO выпилить когда будет функционал с alias;
            delete action.payload.company.alias;

            return ({
                ...state,
                fetching: fetching,
                company: action.payload.company,
                currentDivision: currentDivision
            });
        } else {
            return ({ ...state });
        }
    },
    [MERGE_COMPANY]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.company = false;
        // выбираем активным первое подразделение
        return ({ ...state, fetching: fetching, company: _.merge(action.payload.company, state.company) });
    },
    [CHANGE_COMPANY]: (state, action) => {
        const company = Object.assign({}, state.company);
        _.set(company, action.path, action.value);
        company.unsaved = true;
        return ({ ...state, company });
    },
    [CHANGE_COMPANY_FIELD]: (state, action) => {
        const company = Object.assign({}, state.company);

        const fKey = _.findKey(company.fields, { label: action.label });
        company.fields[fKey].value = action.value;
        return ({ ...state, company });
    },
    [RECEIVE_DIVISIONS]: (state, action) => {
        const company = Object.assign({}, state.company);
        company.divisions = action.payload;
        return ({ ...state, company: company });
    },
    [REQUEST_LOGO]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.logo = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_LOGO]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.logo = false;
        return ({ ...state, logo: action.data.logo, fetching: fetching });
    },
    [CLEAR_COMPANY_LOGO]: (state) => {
        return ({ ...state, logo: null });
    },
    [CLEAR_COMPANY_COVER]: (state) => {
        const company = Object.assign({}, state.company);
        company.cover_id = 0;
        return ({ ...state, company: company });
    },
    [DELETE_FROM_FILES]: (state, action) => {
        const company = Object.assign({}, state.company);
        _.remove(company.files, { id: action.fileId });
        if (company.cover_id === action.fileId) {
            company.cover_id = 0;
        }
        return ({ ...state, company: company });
    },
    [PUSH_COMPANY_FILE]: (state, action) => {
        const company = Object.assign({}, state.company);
        company.files.push(action.file);
        return ({ ...state, company: company });
    },
    [REQUEST_SCHEDULES]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.allSchedules = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_SCHEDULES]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.allSchedules = false;
        return ({ ...state, fetching: fetching, schedules: action.data });
    },
    [UPDATE_SCHEDULES]: (state, action) => {
        const fetching = Object.assign({}, state.fetching); const schedules = _.cloneDeep(state.schedules);
        _.each(action.payload, (cSchedules, resourceId) => {
            _.each(cSchedules, sch => {
                const resKey = _.findIndex(schedules, sch => {
                    return sch.id === parseInt(resourceId);
                });
                if (schedules[resKey][sch.date]) {
                    schedules[resKey][sch.date] = schedules[resKey][sch.date].split('');
                    _.each(_.get(sch, 'includePattern', '').split(''), (ch, chKey) => {
                        if (ch === '1') {
                            schedules[resKey][sch.date][chKey] = 1;
                        }
                    });
                    _.each(_.get(sch, 'excludePattern', '').split(''), (ch, chKey) => {
                        if (ch === '1') {
                            schedules[resKey][sch.date][chKey] = 0;
                        }
                    });
                    schedules[resKey][sch.date] = schedules[resKey][sch.date].join('');
                }
            });
        });
        return ({ ...state, fetching: fetching, schedules: schedules });
    },
    [REQUEST_RESOURCES]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.resources = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_RESOURCES]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.resources = false;
        return ({ ...state, fetching: fetching, resources: _.get(action.payload, 'resources', []) });
    },
    [RESOURCE_IS_CREATED]: (state, action) => {
        const resources = state.resources;
        resources.push(action.payload.resource);
        return ({ ...state, newResource: action.payload.resource, resources: resources });
    },
    [RESOURCE_IS_UPDATED]: (state, action) => {
        const updatedResource = _.get(action, 'resource');

        const resources = _.cloneDeep(state.resources);

        const company = Object.assign({}, state.company);

        const services = _.get(company, 'services.services', []);
        if (updatedResource) {
            const editedResourceKey = _.findKey(resources, { id: updatedResource.id });
            // editedResource = _.merge(editedResource, updatedResource);
            // merge не подходит для обновления ресурса при удалении подразделений и услуг; так должно работать
            // некоторая костылезация затирания аватаров
            _.each(updatedResource, (value, rKey) => {
                resources[editedResourceKey][rKey] = value;
            });
            // в массиве услуг компании редактируем привязку к ресурсу
            if (updatedResource.services) {
                _.each(updatedResource.services, s => {
                    const servicesKey = _.findKey(services, { id: s.id });
                    if (servicesKey) {
                        if (!_.some(services[servicesKey].resources, { id: updatedResource.angara_id })) {
                            services[servicesKey].resources.push({ id: updatedResource.angara_id });
                        }
                    }
                });
            }
            // удаляем привязку к услугам ресурса, коли не выполняет их отныне
            _.each(services, s => {
                s.resources = _.filter(s.resources, r => {
                    return r.id !== updatedResource.angara_id || !updatedResource.services || _.some(updatedResource.services, { id: s.id });
                });
            });
            _.set(company, 'services.services', services);
        }
        return ({ ...state, resources: resources, company: company });
    },
    [REQUEST_SCHEDULE]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.schedules[action.resourceId] = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_SCHEDULE]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.schedules[action.resourceId] = false;
        const schedules = state.schedules;
        _.merge(schedules[_.findIndex(schedules, { id: action.payload.id })], _.get(action, 'payload', {}));
        return ({ ...state, fetching: fetching, schedules: schedules });
    },
    [REQUEST_SERVICES]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.services = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_SERVICES]: (state, action) => {
        const toOrder = Object.assign({}, state.toOrder);
        toOrder.service = null;
        const services = Object.assign({}, state.services);
        if (!services[action.resourceId]) {
            services[action.resourceId] = {};
        }
        const _services = {};
        _.map(action.data.items, service => {
            if (service.serviceTemplate.catalogue && !_services[service.serviceTemplate.catalogue.id]) {
                _services[service.serviceTemplate.catalogue.id] = service.serviceTemplate.catalogue;
                _services[service.serviceTemplate.catalogue.id].services = [];
            }
            if (service.serviceTemplate.catalogue) {
                _services[service.serviceTemplate.catalogue.id].services.push(service);
            }
        });
        services[action.resourceId] = _services;
        // загрузка
        const fetching = Object.assign({}, state.fetching);
        fetching.services = false;
        // TODO вынести группировку услуг по каталогам в компоненту
        return ({ ...state, services: action.data, groupedServices: services, toOrder, fetching: fetching });
    },
    [SERVICE_IS_DELETED]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.services = false;
        const company = Object.assign({}, state.company);

        const services = _.get(company, 'services.services', []);
        _.set(company, 'services.services', _.filter(services, s => {
            return s.id !== action.payload;
        }));

        return ({ ...state, company: company, fetching: fetching });
    },
    [DELETE_RESOURCE]: (state, action) => {
        const company = Object.assign({}, state.company); let resources = state.resources;
        company.resources = _.filter(company.resources, resource => {
            return resource.id !== action.resourceId;
        });
        resources = _.filter(resources, resource => {
            return resource.id !== action.resourceId;
        });
        return ({ ...state, company: company, resources: resources });
    },
    [CHANGE_CURRENT_DIVISION]: (state, action) => {
        return ({ ...state, currentDivision: action.id });
    },
    [SHOW_HIDE_DP]: (state) => {
        return ({ ...state, dpSwitcher: !state.dpSwitcher });
    },
    [CHANGE_DATE]: (state, action) => {
        return ({ ...state, currentDay: action.date });
    },
    [CHANGE_DP_OVER]: (state, action) => {
        return ({ ...state, dpOver: action.is });
    },
    [RECEIVE_EMPLOYEES]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.employees = false;
        return ({ ...state, employees: action.payload.employees, fetching: fetching });
    },
    [REQUEST_EMPLOYEES]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.employees = true;
        return ({ ...state, fetching: fetching });
    },
    [SET_EMPLOYEE_SCHEDULE]: (state, action) => {
        const employees = Object.assign({}, state.employees);
        _.update(_.find(employees, ['id', action.payload]), 'working', value => {
            return !value;
        });
        return ({ ...state, employees: employees });
    },
    [ADD_RESOURCE_AVATAR]: (state, action) => {
        const company = _.cloneDeep(state.company);

        const resource = action.payload;

        const resourceKey = _.findKey(company.resources, ['angara_id', resource.id]);

        if (resourceKey) {
            company.resources[resourceKey].imageUrl = resource.imageUrl;
            company.resources[resourceKey].thumbnails = resource.thumbnails;
        }

        return ({ ...state, company: company });
    },
    [REMOVE_RESOURCE_AVATAR]: (state, action) => {
        const company = _.cloneDeep(state.company);

        const resourceId = action.payload;

        const resourceKey = _.findKey(company.resources, ['angara_id', resourceId]);

        if (resourceKey) {
            company.resources[resourceKey].imageUrl = null;
            company.resources[resourceKey].thumbnails = [];
        }

        return ({ ...state, company: company });
    },
    [CHANGE_COMPANY_COVER]: (state, action) => {
        const company = _.cloneDeep(state.company);
        company.files.push(action.payload);
        company.cover_id = action.payload.id;

        return ({ ...state, company: company });
    },
    [REQUEST_SERVICE]: (state) => {
        return ({
            ...state,
            fetching: true
        });
    },
    [RECEIVE_SERVICE]: (state, action) => {
        const serviceKey = _.findKey(action.payload.company.services.services, { id: action.payload.service.id });

        if (serviceKey) {
            action.payload.company.services.services[serviceKey] = action.payload.service;
        } else {
            action.payload.company.services.services.unshift(action.payload.service);
        }

        return ({
            ...state,
            company: action.payload.company,
            fetching: false

        });
    },
    [CREATE_SERVICES]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.services = true;
        return ({
            ...state,
            fetching: fetching
        });
    },
    [SERVICES_ARE_CREATED]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.services = false;
        const company = Object.assign({}, state.company);

        let services = _.get(company, 'services.services', []);
        if (action.payload.items && action.payload.items.length) {
            services = services.concat(action.payload.items);
        }
        _.set(company, 'services.services', services);
        return ({
            ...state,
            company: company,
            fetching: false
        });
    },
    [UPDATE_SERVICE]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.services = true;
        return ({
            ...state,
            fetching: fetching
        });
    },
    [SERVICE_IS_UPDATED]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.services = false;
        const company = Object.assign({}, state.company);

        let services = _.get(company, 'services.services', []);
        if (action.payload && action.payload.id) {
            const serviceKey = _.findKey(services, { id: action.payload.id });
            if (serviceKey) {
                services = _.merge(services, { [serviceKey]: action.payload });
            }
        }
        _.set(company, 'services.services', services);
        return ({
            ...state,
            company: company,
            fetching: false
        });
    },
    [CHANGE_SERVICE_PRICELIST]: (state, action) => {
        console.log('updatePriceList reducer');
        const company = Object.assign({}, state.company);
        const services = _.get(company, 'services.services', []);
        if (action.file && action.file.id) {
            const serviceKey = _.findKey(services, { id: action.serviceId });
            if (serviceKey) {
                services[serviceKey].pricelist = action.file;
            }
        }
        _.set(company, 'services.services', services);
        return ({
            ...state,
            company: company,
            fetching: false
        });
    },
    [REMOVE_SERVICE_PRICELIST]: (state, action) => {
        console.log('REMOVE_SERVICE_PRICELIST');
        const company = Object.assign({}, state.company);
        const services = _.get(company, 'services.services', []);
        const serviceKey = _.findKey(services, { id: action.serviceId });
        if (serviceKey) {
            console.log('here is the key ' + action.serviceId);
            services[serviceKey].pricelist = null;
        }
        _.set(company, 'services.services', services);
        return ({
            ...state,
            company: company,
            fetching: false
        });
    }
};

const initialState = {
    company: {},
    resources: [],
    newResource: null,
    schedules: [],
    logo: null,
    currentDivision: null,
    currentDay: null,
    fetching: { company: false, services: false, allSchedules: false, schedules: {} },
    dpSwitcher: false,
    dpOver: false,
    tooltip: {},
    catalogues: {},
    services: {},
    employees: null
};

export default function companyReducer(state = initialState, action) {
    state = Object.assign({}, initialState, state);

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
