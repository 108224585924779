import { injectReducer } from 'store/reducers';

export default (store) => ({

    path: 'archive',

    getComponent(nextState, cb) {
        Promise.all([
            import('../modules/okk'),
            import('./containers/OkkContainer')
        ]).then(([reducer, /* webpackChunkName: "Archive" */OkkContainer]) => {
            injectReducer(store, { key: 'okk', reducer: reducer.default });
            /*  Return getComponent   */
            cb(null, OkkContainer.default);
        });
    }
});
