import React from 'react';
import PropTypes from 'prop-types';
import classes from './Login.module.scss';
import Input from 'components/Input';
import { Link } from 'react-router-dom';
import InputElement from 'react-input-mask';

class Login extends React.Component {
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.partner && nextProps.token) {
            this.props.history.push('/');
        }
    }

    componentDidMount() {
        if (this.props.partner && this.props.token) {
            this.props.history.push('/');
        }
    }

    render() {
        return (
            <div className={classes.loginPage}>
                <div className={classes.inputs}>
                    <div className={classes.wrapper}>
                        <h1>Добро пожаловать в Connect.Pro</h1>
                        <form>
                            <div className={classes.row}>
                                <InputElement
                                    type='text'
                                    name='login'
                                    placeholder='логин'
                                    value={this.props.login}
                                    onChange={this.props.changeValue}
                                    mask='+7 (999) 999-99-99'
                                    autoComplete='on'
                                    onKeyPress={e => {
                                        if (e.key === 'Enter') {
                                            this.props.submit(e);
                                        }
                                    }}
                                />
                            </div>
                            <div className={classes.row}>
                                <Input
                                    type='password'
                                    name='password'
                                    placeholder='пароль'
                                    value={this.props.password}
                                    autoComplete='on'
                                    onChange={this.props.changeValue}
                                    onKeyPress={e => {
                                        if (e.key === 'Enter') {
                                            this.props.submit(e);
                                        }
                                    }}
                                />
                                <Link className={classes.forgot} to='/remind-password'>забыли пароль</Link>
                            </div>

                            <div className={classes.submit}>
                                <div onClick={this.props.submit}>
                                    { this.props.duringAuthorization
                                        ? 'идёт авторизация...'
                                        : (this.props.error ? 'Неверный логин или пароль'
                                            : (this.props.partner && this.props.token ? 'вход' : 'войти')) }
                                </div>
                            </div>
                            <div className={classes.row}>
                                <Link className={classes.forgot} to='/create-company'>регистрация</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    login: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    changeValue: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired
};

Login.contextTypes = {
    router: PropTypes.object
};

export default Login;
