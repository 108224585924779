import List from './List';
import Item from './Item';
import React from 'react';

const FeedbackLayout = ({ children }) => (
    <div>
        { children }
    </div>
);

export const FeedbackRoute = (store) => ({
    path: 'feedback',
    component: FeedbackLayout,
    indexRoute: List(store),
    childRoutes: [
        Item(store)
    ]
});

export default FeedbackRoute;
