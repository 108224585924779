import ByResources from './Main/components/ByResources';
import ByServices from './Main/components/ByServices';
import ByOkk from './Main/components/ByOkk';
import ByOrders from './Main/components/ByOrders';
import BySms from './Main/components/BySms';

import { injectReducer } from '../../store/reducers';

export const StatisticsRoute = (store) => ({
    path: 'statistics',
    getComponent(nextState, cb) {
        Promise.all([
            import('./Main/modules/statistics'),
            import('./Main/containers/StatisticsContainer')
        ]).then(([reducer, /* webpackChunkName: "statistics" */StatisticsContainer]) => {
            injectReducer(store, { key: 'statistics', reducer: reducer.default });
            /*  Return getComponent   */
            cb(null, StatisticsContainer.default);
        });
    },
    indexRoute: { onEnter: (nextState, replace) => replace('/statistics/resources') },
    childRoutes: [
        ByResources(store),
        ByServices(store),
        ByOkk(store),
        ByOrders(store),
        BySms(store)
    ]
});

export default StatisticsRoute;
