import React from 'react';
import moment from 'moment';
import cx from 'classnames';
import _ from 'lodash';

// require('react-datepicker/dist/react-datepicker.css');
require('./datepicker.scss');

class DatePicker extends React.Component {
    state = {
        open: false,
        startMonth: moment().startOf('month'),
        lastCreated: null
    };

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    componentDidUpdate() {

    }

    UNSAFE_componentWillReceiveProps() {

    }

    change(date) {
        this.setState({ lastCreated: date });
        this.props.onChange(date);
    }

    changeMonth(change = 1) {
        let month = this.state.startMonth;
        month = month.add(change, 'months');
        this.setState({ startMonth: month });
    }

    makeScheduleString(schedule) {
        if (!schedule) {
            return null;
        }
        let result = `0${schedule.begin.hour}:0${schedule.begin.minute}-0${schedule.end.hour}:0${schedule.end.minute}`;
        result = result.replace(/0(\d\d)/g, '$1');
        return result;
    }

    makeScheduleBreakString(schedule) {
        if (!schedule || !schedule.lunchBreak) {
            return null;
        }
        let result = `0${schedule.lunchBreak.begin.hour}:0${schedule.lunchBreak.begin.minute}-0${schedule.lunchBreak.end.hour}:0${schedule.lunchBreak.end.minute}`;
        result = result.replace(/0(\d\d)/g, '$1');
        return result;
    }

    render() {
        moment.locale('ru');

        let _day; let _inPropDay; const days = [[], [], []]; let workDayIndex; let offDayIndex; let selectedDayIndex; let scheduleString;

        let scheduleSecondString;

        const result = [];
        for (let j = 0; j <= 2; j++) {
            const startMonth = moment(this.state.startMonth).add(j, 'M').startOf('month');

            const weekDay = startMonth.day();

            const startBlock = startMonth.subtract(weekDay - 1, 'd');
            for (let i = 0; i <= 34; i++) {
                _day = moment(startBlock).add(i, 'd');
                _inPropDay = _day.format();
                workDayIndex = _.findIndex(this.props.workDates, { date: _inPropDay });
                offDayIndex = _.findIndex(this.props.offDates, { date: _inPropDay });
                selectedDayIndex = this.props.selectedDates.indexOf(_inPropDay);
                scheduleString = '';
                if (workDayIndex !== -1) {
                    scheduleString = this.makeScheduleString(this.props.workDates[workDayIndex].schedule);
                    scheduleSecondString = this.makeScheduleBreakString(this.props.workDates[workDayIndex].schedule);
                }
                if (offDayIndex !== -1) {
                    scheduleString = 'не рабочий';
                    scheduleSecondString = '';
                }
                const notAvailable = (this.props.availableDates && (!_day.isBetween(this.props.availableDates[0], this.props.availableDates[1], null, '()')));
                days[j].push(
                    <div
                        key={i}
                        className={cx({
                            day: true,
                            byCompany: (workDayIndex !== -1 && this.props.workDates[workDayIndex].byCompany) || (offDayIndex !== -1 && this.props.offDates[offDayIndex].byCompany),
                            expired: _day.isBefore(),
                            notAvailable,
                            workDay: workDayIndex !== -1 && !this.props.disabled,
                            withBreak: workDayIndex !== -1 && scheduleSecondString,
                            offDay: offDayIndex !== -1 && !this.props.disabled,
                            selected: selectedDayIndex !== -1 && !this.props.disabled,
                            lastCreated: this.state.lastCreated && _day.isSame(this.state.lastCreated)
                        })}
                        onClick={((offDayIndex !== -1 && this.props.offDates[offDayIndex].byCompany) || notAvailable) ? null : this.change.bind(this, _day)}
                    >
                        <div className='day__number'>{ _day.format('D') }</div>
                        <div className='timeBlock'>
                            <div>
                                <span className='main'>{ scheduleString }</span>
                                <span className='second'>{ scheduleSecondString }</span>
                                <span className='clear'>Очистить</span>
                            </div>
                        </div>
                    </div>
                );
            }
            result.push(
                <div className='col-md-4' key={j}>
                    <div className='schedule-datepicker'>
                        <div className='schedule-datepicker__header'>
                            <div
                                className='arrow arrow--prev'
                                onClick={() => {
                                    this.changeMonth(-1);
                                }}
                            >
                                { moment(this.state.startMonth).add(-1, 'M').locale('ru').format('MMMM') }
                            </div>
                            <div
                                className='month-name'
                            >
                                { moment(this.state.startMonth).add(j, 'M').locale('ru').format('MMMM') }
                            </div>
                            <div
                                className='arrow arrow--next'
                                onClick={() => {
                                    this.changeMonth(1);
                                }}
                            >
                                { moment(this.state.startMonth).add(3, 'M').locale('ru').format('MMMM') }
                            </div>
                        </div>
                        <div className='schedule-datepicker__weekdays'>
                            <div className='day'>пн</div>
                            <div className='day'>вт</div>
                            <div className='day'>ср</div>
                            <div className='day'>чт</div>
                            <div className='day'>пт</div>
                            <div className='day'>сб</div>
                            <div className='day'>вс</div>
                        </div>
                        <div className='schedule-datepicker__body'>
                            <div className='wrapper'>
                                { days[j] }
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className='datepicker-container'>
                <div className='row'>
                    { result }
                </div>
                <div className='schedule-datepicker__footer footer'>
                    {
                        (this.props.pattern !== 'custom' && this.props.pattern !== 'disabled') ? (
                            <div className='footer__selectedDays'>
                                Выберите первый рабочий день
                            </div>
                        ) : (
                            <div
                                className={`footer__selectedDays${this.props.selectedDates.length === 0 ? ' hidden' : ''}`}
                            >
                                Выбрано дней:
                                { ' ' }
                                <span>{ this.props.selectedDates.length }</span>
                                <div
                                    className='clearAll'
                                    onClick={() => {
                                        this.props.clearAll();
                                    }}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default DatePicker;
