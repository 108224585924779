import React from 'react';
import classes from './Main.module.scss';
import CallsDiary from './CallsDiary';
import CallsMain from './CallsMain';
import CallsForEmployee from './CallsForEmployee';
import CallView from './CallView';
import CallCreate from './CallCreate';
import Client from '../../../routes/Calendar/components/Clients/components/Client';
import _ from 'lodash';

class Main extends React.Component {
    state = {
        navigate: 'callsDiary'
    };

    componentDidMount() {
        if (this.props.partner.role === 'PARTNER') {
            this.props.fetchEmployees({ department: 'callcenter' });
        }// грузим сотрудников, всем нужны
        const params = this.props.location.query;
        this.updateByParams(params);
    }

    updateByParams(params) {
        if (params.call) {
            this.props.changeNavigate({ mode: 'callView', id: params.call });
        } else {
            this.props.changeNavigate({ mode: 'callsDiary' });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const newParams = nextProps.location.query; const params = this.props.location.query;
        if (JSON.stringify(newParams) !== JSON.stringify(params)) {
            this.updateByParams(newParams);
        }
    }

    render() {
        let navigateContent, content;
        if (this.props.partner.role === 'PARTNER') {
            switch (this.props.navigate.mode) {
            case 'callsDiary':
                navigateContent = (<CallsDiary />);
                content = (<CallsMain />);
                break;
            case 'callView':
                navigateContent = (<CallView callId={this.props.navigate.id} />);
                content = _.get(this.props.call, 'client.id', null) ? (
                    <Client
                        clientId={this.props.call.client.id}
                        location={this.props.location}
                    />) : (<CallsMain />);
                break;
            case 'callCreate':
                navigateContent = (<CallCreate />);
                content = (<CallsMain />);
                break;
            default:
                navigateContent = (<CallsDiary />);
                content = (<CallsMain />);
            }
        }

        if (this.props.partner.role === 'CALL_CENTER_EMPLOYEE') {
            switch (this.props.navigate.mode) {
            case 'callsDiary':
                navigateContent = (<CallsDiary />);
                content = (<CallsForEmployee />);
                break;
            case 'callView':
                navigateContent = (<CallView callId={this.props.navigate.id} />);
                content = _.get(this.props.call, 'client.id', null) ? (
                    <Client
                        clientId={this.props.call.client.id}
                        location={this.props.location}
                    />) : (<CallsMain />);
                break;
            case 'callCreate':
            default:
                navigateContent = (<CallsDiary />);
                content = (<CallsForEmployee />);
            }
        }

        return (
            <div className={classes.body}>
                <div className={classes.leftColumn}>
                    { navigateContent }
                </div>
                <div className={classes.rightColumn}>
                    { content }
                </div>
            </div>
        );
    }
}

export default Main;
