import React from 'react';

import WithClickOutside from 'extends/WithClickOutside';
import { Scrollbars } from 'react-custom-scrollbars';

import { formatPhone } from 'utils';

import moment from 'moment';
import _ from 'lodash';
import classes from './ClientsStream.module.scss';
import rsScroller from 'react-smooth-scroller';

class ClientsStream extends WithClickOutside {
    state = {
        open: false,
        data: null
    };

    openOrderModal = false;

    UNSAFE_componentWillMount() {
        this.updateStream();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (_.every(nextProps.activityFetching, f => {
            return !f;
        }) && _.some(this.props.activityFetching)) {
            this.updateStream();
        }
    }

    componentDidUpdate(prevProps) {
        const { toOrder } = this.props;

        const modalMode = _.get(toOrder, 'mode');

        const oldModalMode = _.get(prevProps.toOrder, 'mode');

        let orderId; let oldOrderId;

        if (modalMode === 'viewOrder') {
            orderId = _.get(toOrder, 'orderId');
            oldOrderId = _.get(prevProps.toOrder, 'orderId', null);
        }

        if (modalMode === 'viewReserve') {
            orderId = _.get(toOrder, 'reserveId');
            oldOrderId = _.get(prevProps.toOrder, 'reserveId', null);
        }

        if (this.props.scrollToOrder && orderId && (orderId !== oldOrderId || modalMode !== oldModalMode)) {
            const scrollSpeed = 1800; const // пикселей в секунду
                orderFooterHeaderHeight = 63;
            // отступ сверху в пикселях

            const minDuration = 200;

            const revise = window.pageYOffset || document.documentElement.scrollTop;

            const el = document.getElementById('division' + toOrder.divisionId + 'resource' + toOrder.resId);

            const rect = el.getBoundingClientRect();

            const topScroll = rect.top - orderFooterHeaderHeight;

            const duration = Math.max(Math.abs(parseInt(topScroll / scrollSpeed * 1000)), minDuration); // минимум 200
            rsScroller.scrollToTarget(el.className, {
                easing: 'easeInSine',
                duration: duration,
                frame: 25,
                revise: revise - orderFooterHeaderHeight
            });
            this.props.changeScrollToOrder(false);
        }

        if (this.openOrderModal && prevProps.fetching && !this.props.fetching && this.state.data) {
            this.props.chooseToOrder(this.state.data);
            this.openOrderModal = false;
        }
    }

    updateStream() {
        const range_start = moment().startOf('date').format(); const range_end = moment().startOf('date').add(1, 'd').format();
        if (_.get(this.props.partner, 'company_id')) {
            this.props.fetchClientsStream(this.props.partner.company_id, {
                range_start: range_start,
                range_end: range_end
            });
        }
    }

    renderTrack({ style, ...props }) {
        const trackStyle = {};
        return (
            <div
                className={classes.scrollTrack}
                style={{ ...style, ...trackStyle }}
                {...props}
            />
        );
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {};
        return (
            <div
                className={classes.scrollThumb}
                style={{ ...style, ...thumbStyle }}
                {...props}
            />
        );
    }

    openOrder(client) {
        let resourceId;

        const data = {
            mode: _.camelCase('view ' + client.entity),
            date: moment(client.date),
            chunk: moment(client.date).diff(moment(client.date).startOf('date'), 'minutes', true) / 30
        };

        if (client.entity === 'order') {
            resourceId = client.orderDetails[0].resource.id;
            data.orderId = client.orderDetails[0].order.id;
            data.resId = resourceId;
        }

        if (client.entity === 'reserve') {
            resourceId = client.resource.id;
            data.reserveId = client.id;
            data.resId = resourceId;
        }

        const resource = _.find(_.get(this.props.company, 'resources'), ['angara_id', resourceId]);
        const division = _.first(_.get(resource, 'divisions'));
        data.divisionId = _.get(division, 'id', 'noDivision');

        const currentDay = this.props.currentDay || moment().startOf('day');

        if (moment(client.date).isSame(currentDay, 'day')) {
            if (data.chunk > 41 && this.props.dayTime === 'day') {
                this.props.changeDayTime('night');
            }

            if (data.chunk < 42 && data.chunk > 15 && this.props.dayTime === 'night') {
                this.props.changeDayTime('day');
            }

            if (data.chunk < 16) {
                if (this.props.dayTime === 'day') {
                    this.props.changeDayTime('night');
                }
                this.changeDate(moment(client.date).startOf('date').add(-1, 'd'));
            }

            this.props.chooseToOrder(data);
        } else if (moment(client.date).isAfter(currentDay) && moment(client.date).isBefore(currentDay.clone().add(33, 'h'))) {
            if (this.props.dayTime === 'day') {
                this.props.changeDayTime('night');
            }
            this.props.chooseToOrder(data);
        } else {
            let date = moment(client.date).startOf('date');
            if (data.chunk > 41 && this.props.dayTime === 'day') {
                this.props.changeDayTime('night');
            }

            if (data.chunk < 42 && data.chunk > 15 && this.props.dayTime === 'night') {
                this.props.changeDayTime('day');
            }

            if (data.chunk < 16) {
                if (this.props.dayTime === 'day') {
                    this.props.changeDayTime('night');
                }
                date = date.add(-1, 'd');
            }

            this.changeDate(date);
            this.setState({ data: data });
            this.openOrderModal = true;
        }
        this.props.changeScrollToOrder(true);
    }

    changeDate(date) {
        const day = moment(date);
        const range_start = moment(date).startOf('date').format();

        const range_end = moment(date).startOf('date').add(2, 'd').format();
        this.props.fetchSchedules([day.format('Y-MM-DD'), moment(day).add(1, 'd').format('Y-MM-DD')]);
        this.props.fetchOrders(this.props.partner.company_id, { range_start: range_start, range_end: range_end }, true);
        this.props.fetchReserves({ range_start: range_start, range_end: range_end });
        this.props.changeDate(date);
    }

    outerClick() {
        this.setState({ open: false });
    }

    render() {
        const timeChunks = []; const clients = [];

        _.each(this.props.clientsStream, client => {
            let date = moment(client.date).startOf('h');
            if (moment(client.date).minutes() > 30) {
                date.minutes(30);
            }
            date = date.format('H:mm');
            if (timeChunks.indexOf(date) === -1) {
                timeChunks.push(date);
                clients.push(<div className='timePeriod' key={'time' + date}>{ date }</div>);
            }
            clients.push(
                <div
                    className='client'
                    key={'client' + client.entityId + '-' + client.client.id}
                    onClick={this.openOrder.bind(this, client)}
                >
                    <div className='client__avatar'>
                        <img src={client.clientAvatar} alt='' />
                    </div>
                    <div className='client__info'>
                        <div className='client__name'>{ client.clentName }</div>
                        <div className='client__phone'>{ formatPhone(client.clientPhone) }</div>
                        <div className='client__service-desc'>
                            { client.entity === 'order' ? (
                                _.map(client.orderDetails, o => {
                                    return (
                                        <div
                                            key={'od' + client.entityId + '-' + client.client.id + '-' + o.id}
                                            className='client__service-descr'
                                        >{ _.get(o, 'service.serviceTemplate.name') }
                                        </div>
                                    );
                                }))
                                : (<div className='client__service-descr'>{ _.get(client, 'reserveDescr') }</div>) }
                        </div>
                    </div>
                </div>);
        });

        return (
            <div className={'clientsStream' + (this.state.open ? ' open' : '')}>
                <div
                    className='clientsStream__top' onClick={() => {
                        this.setState({ open: !this.state.open });
                    }}
                >Ожидаемые клиенты
                </div>

                <Scrollbars
                    ref='searchClientsStreamScroll'
                    style={{ height: '100%' }}
                    thumbSize={16}
                    autoHide
                    renderTrackVertical={this.renderTrack}
                    renderThumbVertical={this.renderThumb}
                    onScrollStop={this.scrollClientsList}
                    onWheel={event => {
                        if (event.deltaY > 0) {
                            const { searchClientsScroll } = this.refs;
                            const scroll = searchClientsScroll.getValues();
                            if (scroll.clientHeight !== scroll.scrollHeight && scroll.clientHeight + scroll.scrollTop === scroll.scrollHeight) {
                                event.preventDefault();
                            }
                        }
                    }}
                >
                    <div className='clientsStream__clients'>
                        { clients }
                    </div>
                </Scrollbars>

            </div>
        );
    }
}

export default ClientsStream;
