/**
 * Created by user on 25.05.2016.
 */
import React from 'react';
import PropTypes from 'prop-types';

class Archive extends React.Component {
    state = {

    };

    UNSAFE_componentWillMount() {

    }

    UNSAFE_componentWillReceiveProps() {

    }

    render() {
        return (
            <div>
                Archive
            </div>
        );
    }
}
Archive.contextTypes = {
    router: PropTypes.object
};
export default Archive;
