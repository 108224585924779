/**
 * Created by user on 04.10.16.
 */
import { connect } from 'react-redux';
import { activateClient, changeField, editClient, changeClient } from 'modules/clients';

import ActivateClientModal from './ActivateClientModal';

const mapStateToProps = (state) => ({
    client: state.clients.client,
    errorMenu: state.clients.errorMenu,
    editClientChange: state.clients.editClientChange,
    changedClient: state.clients.changedClient
});

export default connect(mapStateToProps, Object.assign({}, {
    activateClient,
    changeField,
    editClient,
    changeClient
}))(ActivateClientModal);
