/**
 * Created by user on 26.05.2016.
 */

import { connect } from 'react-redux';
import { actions } from 'modules/company';
import { fetchOrders, fetchReserves, setOrderDay, chooseToOrder } from 'modules/orders';
import { setFooterMinHeight, changeDayTime } from 'modules/hangar';

import OrderFooter from './OrderFooter';

const mapStateToProps = (state) => ({
    company: state.company.company,
    resources: state.company.resources,
    schedules: state.company.schedules,
    currentDivision: state.company.currentDivision,
    currentDay: state.company.currentDay,
    toOrder: state.orders.toOrder,
    dpSwitcher: state.company.dpSwitcher,
    dpOver: state.company.dpOver,
    fetching: Object.assign(state.company.fetching, state.orders.fetching),
    partner: state.account.partner,
    orderMode: state.orders.orderMode,
    services: state.company.services,
    orders: state.orders.orders,
    reserves: state.orders.reserves,
    client: state.clients.client,
    footerMinHeight: state.hangar.footerMinHeight,
    dayTime: state.hangar.dayTime
});

export default connect(mapStateToProps, Object.assign(actions, {
    fetchOrders,
    fetchReserves,
    setOrderDay,
    chooseToOrder,
    setFooterMinHeight,
    changeDayTime
}))(OrderFooter);
