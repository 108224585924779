import React from 'react';
import PropTypes from 'prop-types';
import classes from './BySms.module.scss';
import DatePicker from 'components/DatePicker';
import Button from 'components/Button';
import RouteSelect from './../RouteSelect';
import moment from 'moment';
import { getParamFromLocation } from '../../../../../utils';

class ByOrders extends React.Component {
    UNSAFE_componentWillMount() {
        this.props.resetSmsExportError();
    }

    componentDidMount() {

    }

    closeModal = () => {
        this.props.resetSmsExportError();
    };

    render() {
        const { smsError } = this.props;
        const { start, end } = getParamFromLocation(this.props.location);

        const rangeStart = start ? moment(start) : moment().add(-1, 'M');

        const rangeEnd = end ? moment(end) : moment();

        const dateInterval = { range_start: rangeStart.startOf('d').format(), range_end: rangeEnd.endOf('d').format() };

        let exportClients;
        if (this.props.exportingSms) {
            if (this.props.exportSmsId) {
                exportClients = (
                    <div className={classes.excel} onClick={this.props.downloadExportSms.bind(this, this.props.exportSmsId)}>Скачать</div>
                );
            } else {
                exportClients = (
                    <div className={classes.excel + ' ' + classes.processing}>Подождите, идет экспорт...</div>
                );
            }
        } else {
            exportClients = (
                <div className={classes.excel} onClick={this.props.exportSms.bind(this, dateInterval)}>Экспорт в excel</div>
            );
        }

        let exportPopup;
        if (this.props.exportingSms) {
            if (this.props.exportSmsId) {
                exportPopup = (
                    <div className={classes.exportPopup}>
                        Экспорт отчета по sms завершен. Файл будет доступен в течение 5 минут.&nbsp;
                        <span onClick={this.props.downloadExportSms.bind(this, this.props.exportSmsId)}>Скачать файл</span>
                    </div>
                );
            } else {
                exportPopup = (
                    <div className={classes.exportPopup}>
                        Экспортируем отчет по sms...
                    </div>
                );
            }
        }

        let errorModal;
        if (smsError) {
            errorModal = (
                <div
                    className={classes.modal}
                    onClick={this.closeModal}
                >
                    <div className={classes.content} onClick={e => e.stopPropagation()}>
                        { smsError }
                        <div className={classes.btnContainer}>
                            <Button
                                value='Ok'
                                className='orange'
                                onClick={this.closeModal}
                            />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className='statistics-block statistics-block--service'>
                <div className='statistics-block__h1'>
                    <div>Отчет по</div>
                    <RouteSelect value='sms' httpQuery={this.props.location.search} />
                </div>
                <div className='date-filter'>
                    <div>за период с</div>
                    <DatePicker
                        stringFormatting={(date) => {
                            return moment(date).locale('ru').format('DD MMMM YY');
                        }}
                        selected={rangeStart}
                        className='inline orange'
                        align={['center', 'bottom']}
                        onChange={(date) => {
                            this.props.history.push('/statistics/sms?range_start=' + moment(date).format('YYYY-MM-DD') + '&range_end=' + moment(rangeEnd).format('YYYY-MM-DD'));
                        }}
                        locale='ru'
                        maxDate={new Date(rangeEnd)}
                    />
                    <div>по</div>
                    <DatePicker
                        stringFormatting={(date) => {
                            return moment(date).locale('ru').format('DD MMMM YY');
                        }}
                        selected={rangeEnd}
                        className='inline orange'
                        align={['center', 'bottom']}
                        onChange={(date) => {
                            this.props.history.push('/statistics/sms?range_start=' + moment(rangeStart).format('YYYY-MM-DD') + '&range_end=' + moment(date).format('YYYY-MM-DD'));
                        }}
                        locale='ru'
                        minDate={new Date(rangeStart)}
                    />
                </div>
                { exportClients }
                { exportPopup }
                { errorModal }
            </div>
        );
    }
}

ByOrders.contextTypes = {
    router: PropTypes.object
};

export default ByOrders;
