/**
 * Created by user on 19.07.16.
 */
import { connect } from 'react-redux';
import { actions } from 'modules/clients';

import PrivateInfo from './PrivateInfo';

const mapStateToProps = (state) => ({
    client: state.clients.client,
    editClientChange: state.clients.editClientChange,
    changedClient: state.clients.changedClient,
    errorMenu: state.clients.errorMenu,
    privateMenuStatus: state.clients.privateMenuStatus,
    cars: state.clients.cars,
    changedCars: state.clients.changedCars,
    carReferences: state.clients.carReferences,
    shadeMenuStatus: state.clients.shadeMenuStatus,
    businessType: state.company.company.business_type
});

export default connect(mapStateToProps, actions)(PrivateInfo);
