import { connect } from 'react-redux';
import { actions } from './../../modules/auth';
import { showHeader, changePassInput, confirmPassCheck, passUpdate, passwordMenuShow, clearAjaxError } from './../../modules/hangar';

import Header from './Header';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => ({
    partner: state.account.partner,
    company: state.company.company,
    showHeaderMenu: state.hangar.showHeaderMenu,
    password: state.hangar.password,
    checkPassStatus: state.hangar.checkPassStatus,
    passwordMenu: state.hangar.passwordMenu,
    showErrors: state.hangar.showErrors,
    ajaxError: state.hangar.ajaxError
});

export default connect(mapStateToProps, Object.assign(actions, {
    showHeader,
    changePassInput,
    confirmPassCheck,
    passUpdate,
    passwordMenuShow,
    clearAjaxError
}))(withRouter(Header));
