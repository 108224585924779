/**
 * Created by user on 26.05.2016.
 */

import { connect } from 'react-redux';
import { actions } from 'modules/orders';
import { fetchCompany, fetchSchedule } from 'modules/company';

import ViewReserveModal from './ViewReserveModal';

const mapStateToProps = (state) => ({
    company: state.company.company,
    loading: state.company.fetching,
    toOrder: state.orders.toOrder,
    reserves: state.orders.reserves,
    currentDay: state.company.currentDay
});

export default connect(mapStateToProps, Object.assign(actions, { fetchCompany, fetchSchedule }))(ViewReserveModal);
