import React from 'react';
import classes from './CallCreate.module.scss';
import cx from 'classnames';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import Select from 'components/Select';
import DatePicker from 'components/DatePicker';
import { formatPhone } from 'utils';
import _ from 'lodash';

class CallCreate extends React.Component {
    constructor(props) {
        super(props);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.searchClients = this.searchClients.bind(this);
        this.createCall = this.createCall.bind(this);
    }

    state = {
        timeIsOpen: false,
        call: {
            reason: '',
            call_at: this.props.navigate.initialDate ? this.props.navigate.initialDate : null,
            call_at_time: this.props.navigate.initialDate ? this.props.navigate.initialDate.format('HH:mm') : null,
            is_important: 0,
            comment: '',
            client_id: null
        },
        clientsSearch: '',
        currentClient: null
    };

    componentDidMount() {
        // this.props.fetchEmployees({ department: 'callcenter' });

    }

    componentDidUpdate(prevProps) {
        if (prevProps.fetching_create === true && this.props.fetching_create === false) {
            this.props.changeDate(moment(this.state.call.call_at).startOf('date').format());
            this.props.changeNavigate({ mode: 'callsDiary' });
        }
    }

    UNSAFE_componentWillReceiveProps() {

    }

    renderTrack({ style, ...props }) {
        const trackStyle = {};
        return (
            <div
                className='scrollTrack'
                style={{ ...style, ...trackStyle }}
                {...props}
            />
        );
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {};
        return (
            <div
                className='scrollThumb'
                style={{ ...style, ...thumbStyle }}
                {...props}
            />
        );
    }

    setTimeCallAt(time, e) {
        e.preventDefault();
        const call = this.state.call;
        call.call_at_time = time;
        this.setState({ call: call });
    }

    onChangeDate(date) {
        const call = this.state.call;
        call.call_at = date;
        // проверяем адекватность выбранного времени звонка с учетом выбранной даты
        if (call.call_at_time) {
            const time = (call.call_at_time).split(':'); const testDate = moment(call.call_at).hours(time[0]).minutes(time[1]);
            if (testDate.isBefore(moment())) {
                delete call.call_at_time;
            }
        }
        this.setState({ call: call });
    }

    searchClients() {
        if (this.state.clientsSearch.trim().length > 1) {
            // при поиске обнуляем клиента
            if (this.state.call.client_id) {
                this.setState({ call: _.merge(this.state.call, { client_id: null }) });
            }
            clearTimeout(this.searchTimeOutId);
            var fetchClients = function() {
                this.props.fetchSearchClients(this.state.clientsSearch, 0, 10, false);
            }.bind(this);
            this.searchTimeOutId = setTimeout(fetchClients, 250);
        } else {
            this.props.clearClients();
        }
    }

    createCall(e) {
        e.preventDefault();
        const call = this.state.call; const time = (call.call_at_time || '00:00').split(':');
        call.call_at = moment(call.call_at).hours(time[0]).minutes(time[1]).format();
        if (moment(call.call_at).isBefore(moment())) {
            call.call_at = moment().format();
        }
        call.is_important = call.is_important ? 1 : 0;
        this.props.fetchStoreCall(call);
    }

    readyToCreate() {
        const call = this.state.call; const notReady = [];
        if (!call.reason || call.reason === '') {
            notReady.push({ field: 'reason', error: 'Поле не должно быть пустым' });
        }
        if (!call.client_id) {
            notReady.push({ field: 'client_id', error: 'Нужно выбрать клиента' });
        }
        if (!call.call_at) {
            notReady.push({ field: 'call_at', error: 'Не выбрана дата звонка' });
        }
        if (!call.call_at_time) {
            notReady.push({ field: 'call_at', error: 'Не выбрано время звонка' });
        }
        return { ready: _.isEmpty(notReady), notReady: [] };
    }

    render() {
        const call = this.state.call; let i; let timeToCall; const callTimeList = []; const searchClientsList = [];

        const callReasons = [
            { type: 'item', label: 'Знакомство/презентация', value: 'Знакомство/презентация' },
            { type: 'item', label: 'Консультация/напоминание', value: 'Консультация/напоминание' },
            { type: 'item', label: 'Благодарность/поздравление', value: 'Благодарность/поздравление' },
            { type: 'item', label: 'Оценка качества/претензия', value: 'Оценка качества/претензия' },
            { type: 'item', label: 'Информирование/акция', value: 'Информирование/акция' }
        ];
        // формулируем список для вывода времени
        for (i = 10; i < 21; i = i + 0.5) {
            if (call.call_at) {
                timeToCall = moment(call.call_at);
            } else {
                timeToCall = moment();
            }
            timeToCall = timeToCall.hour(Math.floor(i)).minute((i % 1) * 60);
            if (!timeToCall.isBefore(moment())) {
                callTimeList.push({ value: timeToCall.format('HH:mm'), type: 'item', label: timeToCall.format('HH:mm') });
            }
        }
        if (this.props.clients) {
            _.map(this.props.clients.entities, (item, key) => {
                if (key <= 7) {
                    searchClientsList.push(
                        <div
                            className={cx({ [classes.client]: true })}
                            key={'client' + item.id}
                            id={'client' + item.id}
                            onClick={() => {
                                this.setState({
                                    call: _.merge(call, { client_id: (this.state.call.client_id ? null : item.id) }),
                                    currentClient: item.nick
                                });
                            }}
                        >
                            <div className={classes.name}>{ item.nick }</div>
                            <div className={classes.phone}>{ formatPhone(item.phone) }</div>
                        </div>
                    );
                }
            });
        }
        const readyTocreate = this.readyToCreate();
        return (
            <div className={classes.callCreate}>
                <div
                    className={classes.goback}
                    onClick={e => {
                        e.preventDefault();
                        this.props.changeNavigate({ mode: 'callsDiary' });
                    }}
                >
                    <div className={classes.wrapper}>Назад к звонкам</div>
                </div>
                <div className={classes.header}>Создать звонок</div>
                <div className={classes.body}>
                    <Scrollbars
                        ref='callScrollbars'
                        thumbSize={16}
                        autoHeight={false}
                        autoHide
                        renderTrackVertical={this.renderTrack}
                        renderThumbVertical={this.renderThumb}
                    >
                        <div className={classes.call + (call.client_id ? (' ' + classes.withClient) : '')}>
                            <div className={classes.clientBlock}>
                                <div className={classes.search}>
                                    <input
                                        type='text'
                                        name='clientsSearch'
                                        value={this.state.clientsSearch}
                                        placeholder='Поиск клиента'
                                        onChange={e => {
                                            this.setState({ clientsSearch: e.target.value });
                                        }}
                                        onKeyUp={this.searchClients}
                                    />
                                    <div className={classes.submit} />
                                </div>
                                { searchClientsList.length > 0 ? null : (
                                    <div className={classes.text}>
                                        Найдите клиента для звонка
                                    </div>
                                ) }
                                <div className={classes.clientsList}>
                                    { searchClientsList }
                                </div>
                            </div>
                            <div className={classes.callDetails}>
                                <div className={classes.callClient}>
                                    <div className={classes.name}>{ this.state.currentClient }</div>
                                </div>
                                <div className={classes.callDate + ' ' + classes.block}>
                                    <div className={classes.text}>Время и дата звонка</div>
                                    <div className={classes.dateTime}>
                                        <div className={classes.date}>
                                            <DatePicker
                                                className='orange'
                                                onChange={this.onChangeDate}
                                                selected={call.call_at ? call.call_at : null}
                                                placeholder='Выберите дату'
                                                align={['center', 'bottom']}
                                                minDate={moment()}
                                            />
                                        </div>
                                        <div className={classes.time}>
                                            <Select
                                                name='time'
                                                placeholder='--:--'
                                                value={call.call_at_time ? call.call_at_time : null}
                                                options={callTimeList}
                                                type='byLink'
                                                className={cx({ selectTime: true, disabled: !call.client_id || !call.call_at })}
                                                onChange={e => {
                                                    this.setState({ call: _.merge(call, { call_at_time: e.value }) });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.block}>
                                    <div className={classes.label}>Причина звонка</div>
                                    <div className={classes.value}>
                                        <Select
                                            name='employee'
                                            placeholder='Выберите причину'
                                            value={call.reason ? call.reason : null}
                                            options={callReasons}
                                            type='byLink'
                                            className={cx({
                                                selectReason: true,
                                                disabled: (!call.client_id || !call.call_at_time || !call.call_at) && !call.reason
                                            })}
                                            onChange={e => {
                                                this.setState({ call: _.merge(call, { reason: e.value }) });
                                            }}
                                            withCustom
                                            customText='Добавить свою причину'
                                        />
                                    </div>
                                </div>
                                <div className={classes.block + ' ' + classes.commence}>
                                    <textarea
                                        placeholder='Комментарий к звонку'
                                        rows='6'
                                        value={call.comment}
                                        onChange={e => {
                                            this.setState({ call: _.merge(call, { comment: e.target.value }) });
                                        }}
                                    />
                                </div>
                                <div className={classes.block}>
                                    <div
                                        className={cx({
                                            [classes.isImportant]: true,
                                            [classes.active]: call.is_important
                                        })}
                                        onClick={() => {
                                            this.setState({ call: _.merge(call, { is_important: call.is_important ? 0 : 1 }) });
                                        }}
                                    >
                                        Важный звонок
                                    </div>
                                </div>
                                <hr />
                                <div className={classes.block}>
                                    <div
                                        className={cx({
                                            [classes.proceed]: true,
                                            [classes.disabled]: !readyTocreate.ready
                                        })}
                                        onClick={this.createCall}
                                    >Создать звонок
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        );
    }
}

export default CallCreate;
