/**
 * Created by user on 17.05.2016.
 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classes from './SlimDecorator.module.scss';
import Slim from '../../lib/slim/slim.react';

export const SlimDecorator = ({ img, willSave, didUpload, willRemove, uploadPath, remove }) => {
    const cropper = useRef();
    const [imageError, setImageError] = useState(false);

    useEffect(() => {
        if (imageError) {
            setTimeout(function() {
                setImageError(false);
            }, 1000);
        }
    }, [imageError]);

    return (
        <div className={classes.cover}>
            <Slim
                ref={ref => { cropper.current = ref; }}
                service={uploadPath}
                label=''
                push
                buttonEditLabel='Редактировать'
                buttonRemoveLabel='Удалить'
                buttonDownloadLabel='Скачать'
                buttonUploadLabel='Загрузить'
                buttonCancelLabel='Отмена'
                buttonConfirmLabel='Ок'
                maxFileSize='3.5'
                statusFileSize='Максимально допустимый размер: $0 Мб'
                minSize={{ width: 10, height: 10 }}
                statusImageTooSmall='Минимально допустимый размер: $0 пикселей'
                statusNoSupport='Не поддерживается браузером'
                statusUnknownResponse='Ошибка загрузки'
                statusUploadSuccess='Ок'
                uploadBase64
                internalCanvasSize={{
                    width: 10000,
                    height: 10000
                }}
                // willSave={willSave}
                didUpload={(data, ready, file) => {
                    didUpload(data, ready, file);
                    if (cropper.current) {
                        cropper.current.instance.remove();
                    }
                }}
                willRemove={willRemove}
            >
                <input type='file' name='file' id='slimCover' />
            </Slim>
            { img ? (
                <div className={classes.coverImg + ' ' + (imageError ? classes.error : '')}>
                    <img
                        src={img + '?' + new Date().getTime()} alt='Изображение прайслиста' onError={() => {
                            setImageError(true);
                        }}
                    />
                    <button
                        className={classes.remove}
                        title='Удалить'
                        type='button'
                        onClick={() => remove()}
                    />
                </div>
            ) : null }
        </div>
    );
};

SlimDecorator.propTypes = {
    img: PropTypes.string,
    willSave: PropTypes.func,
    didUpload: PropTypes.func,
    willRemove: PropTypes.func,
    uploadPath: PropTypes.string.isRequired,
    remove: PropTypes.func
};

export default SlimDecorator;
