import Common from './Main/components/Common';
// import Spec from './Main/components/Spec';
import Posts from './Main/components/Posts';
import Services from './Main/components/Services';
import Cpro from './Main/components/Cpro';

import { injectReducer } from '../../store/reducers';

export const SettingsRoute = store => ({
    path: 'settings',
    getComponent(nextState, cb) {
        Promise.all([
            import('./Main/modules/settings'),
            import('./Main/containers/SettingsContainer')
        ]).then(([reducer, /* webpackChunkName: "settings" */SettingsContainer]) => {
            injectReducer(store, { key: 'settings', reducer: reducer.default });
            /*  Return getComponent   */
            cb(null, SettingsContainer.default);
        });
    },
    indexRoute: { onEnter: (nextState, replace) => replace('/settings/common') },
    childRoutes: [
        Common(store),
        Posts(store),
        Services(store),
        Cpro(store)
    ]
});

export default SettingsRoute;
