import React from 'react';
import ReactDOM from 'react-dom';

import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import { HELP_CSS, handleClickOutside } from 'utils';

import classes from './Editor.module.scss';

class SelectDurationMenu extends React.Component {
    state = {
        open: false
    };

    componentDidMount() {
        this.onDocumentClick = this.onDocumentClick.bind(this);
        document.addEventListener('click', this.onDocumentClick, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onDocumentClick);
    }

    onDocumentClick(event) {
        if (this.state.open && handleClickOutside(event, ReactDOM.findDOMNode(this))) {
            this.setState({ open: false });
        }
    }

    renderTrack({ style, ...props }) {
        const trackStyle = {};
        return (
            <div
                className={classes.scrollTrack}
                style={{ ...style, ...trackStyle }}
                {...props}
            />
        );
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {};
        return (
            <div
                className={classes.scrollThumb}
                style={{ ...style, ...thumbStyle }}
                {...props}
            />
        );
    }

    formatServiceDuration(duration) {
        const hours = parseInt(duration / 60);

        const minutes = duration - hours * 60;

        let result;

        result = hours ? `${hours} ч. ` : '';
        result += minutes ? `${+minutes} минут` : '';
        return result;
    }

    render() {
        const durations = [
            30, 60, 90, 120,
            150, 180, 210, 240,
            270, 300, 330, 360,
            390, 420, 450, 480
        ];
        return (
            <div className={classes.inputGroup + ' ' + HELP_CSS.hlpInline}>
                { this.props.noTitle ? null : (<div className={`${classes.title} ${classes.row}`}>Длительность:</div>) }
                <div
                    className={classes.changeValue}
                    onClick={() => {
                        this.setState({ open: !this.state.open });
                    }}
                >
                    <div className={classes.value}>{ this.formatServiceDuration(this.props.value) }</div>
                    &nbsp;
                    <div className={HELP_CSS.hlpSelectIcon} />
                    { this.state.open ? (
                        <div
                            className={classes.outerDurationMenu}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <div className={classes.duration}>
                                Длительность
                            </div>
                            <Scrollbars
                                thumbSize={16}
                                autoHeight
                                autoHide={false}
                                renderTrackVertical={this.renderTrack}
                                renderThumbVertical={this.renderThumb}
                            >
                                { _.map(durations, duration => (
                                    <div
                                        className={classes.item}
                                        key={`duration${duration}`}
                                        onClick={() => {
                                            if (this.props.onChange) {
                                                this.props.onChange(duration);
                                            }

                                            this.setState({ open: false });
                                        }}
                                    >
                                        { this.formatServiceDuration(duration) }
                                    </div>
                                )) }
                            </Scrollbars>
                        </div>
                    ) : null }
                </div>
            </div>
        );
    }
}

export default SelectDurationMenu;
