import React from 'react';
import PropTypes from 'prop-types';

import classes from './CoreLayout.module.scss';
import moment from 'moment';
import 'moment/locale/ru';
import * as Sentry from '@sentry/browser';

import 'styles/core.scss';

import FrontFrame from 'components/FrontFrame';

import _ from 'lodash';

import { httpBuildQuery } from 'utils';

// const FREE_PATHS = ['login', 'remind-password', 'create-company', 'download', '*'];
const FREE_PATHS_NAMES = ['/login', '/remind-password', '/create-company', '/download'];

const LONG_POLL_FAIL_TIMEOUT = 5000;

class CoreLayout extends React.Component {
    eventTypes = [
        { label: 'new client order', update: false, event: true },
        { label: 'client order', update: false, event: true },
        { label: 'new client reserve', update: false, event: true },
        { label: 'client reserve', update: false, event: true },
        { label: 'client feedback', update: false, event: true },
        { label: 'client registration', update: false, event: true },
        { label: 'client incoming call', update: false, event: true },
        // события ангары
        { label: 'order-activated', update: true, event: false },
        { label: 'order-created', update: true, event: false },
        { label: 'order-updated', update: true, event: false },
        { label: 'order-create-fw', update: true, event: false },
        { label: 'order-canceled', update: true, event: false },
        { label: 'order-deleted', update: true, event: false },
        { label: 'order-left-off', update: true, event: false },
        { label: 'reserve-created', update: true, event: false },
        { label: 'reserve-canceled', update: true, event: false },
        { label: 'reserve-left-off', update: true, event: false },
        { label: 'company-schedule-changed', update: true, event: false },
        { label: 'company-custom-schedule-changed', update: true, event: false },
        { label: 'resource-custom-schedule-changed', update: true, event: false }
    ];

    UNSAFE_componentWillMount() {
        /* eslint-disable quote-props */
        Sentry.init({ dsn: 'https://5d68a3d7ac394cf08c49452754fa6ebd@sentry.ortus.ru/10', environment: process.env.__ENV__ });
        if (this.props.partner) {
            Sentry.configureScope(scope => {
                scope.setUser({
                    company_id: this.props.partner.company_id,
                    company_name: this.props.partner.name,
                    owner_phone: this.props.partner.owner.phone
                });
            });
        }
        var OneSignal = window.OneSignal || [];

        const oneSignalAppKey = process.env.REACT_APP_ONESIGNAL_APP_KEY;

        const safariWebId = process.env.REACT_APP_SAFARI_WEB_ID;

        const subdomainName = process.env.REACT_APP_SUBDOMAIN_NAME;

        OneSignal.push(['init', {
            appId: oneSignalAppKey,
            safari_web_id: safariWebId,
            autoRegister: false, /* Set to true to automatically prompt visitors */
            subdomainName: subdomainName,
            /*
             subdomainName: Use the value you entered in step 1.4: http://imgur.com/a/f6hqN
             */
            httpPermissionRequest: {
                enable: false,
                modalTitle: 'Спасибо, что подписались!',
                modalMessage: 'Вы подписаны на уведомления. Вы можете отписаться в любое время.',
                modalButtonText: 'Закрыть'
            },
            notifyButton: {
                enable: false /* Set to false to hide */
                // displayPredicate: function() {
                //    return OneSignal.isPushNotificationsEnabled()
                //        .then(function(isPushEnabled) {
                //            /* The user is subscribed, so we want to return "false" to hide the notify button */
                //            return !isPushEnabled;
                //        });
                // }
            },
            promptOptions: {
                /* These prompt options values configure both the HTTP prompt and the HTTP popup. */
                /* actionMessage limited to 90 characters */
                actionMessage: 'Разрешить уведомления на этом сайте?',
                /* acceptButtonText limited to 15 characters */
                acceptButtonText: 'Разрешить',
                /* cancelButtonText limited to 15 characters */
                cancelButtonText: 'Нет, спасибо',

                /* Change bold title, limited to 30 characters */
                siteName: 'Connect Pro',
                /* Subtitle, limited to 90 characters */
                /* Example notification title */
                exampleNotificationTitle: 'Уведомление',
                /* Example notification message */
                exampleNotificationMessage: 'Это пример уведомления',

                /* Text below example notification, limited to 50 characters */
                exampleNotificationCaption: '',
                /* Change click allow text, limited to 30 characters */
                autoAcceptTitle: ''
            },
            welcomeNotification: {
                'title': 'Connect Pro',
                'message': 'Спасибо, что подписались!'
                // "url": "" /* Leave commented for the notification to not open a window on Chrome and Firefox (on Safari, it opens to your webpage) */
            }
        }]);
        OneSignal.push(function() {
            OneSignal.on('subscriptionChange', function(isSubscribed) {
                if (isSubscribed) {
                    // The user is subscribed
                    //   Either the user subscribed for the first time
                    //   Or the user was subscribed -> unsubscribed -> subscribed
                    OneSignal.getUserId(function(userId) {
                        // Make a POST call to your server with the user ID
                        this.props.storeOnesignalId(userId);
                    }.bind(this));
                }
            }.bind(this));
        }.bind(this));
        /* eslint-enable quote-props */
    }

    componentDidMount() {
        if ((!this.props.partner || !this.props.token) && !(_.includes(FREE_PATHS_NAMES, this.props.location.pathname))) {
            this.props.history.push('/login');
        }
        try {
            if (this.props.partner && this.props.token && !this.props.onesignalId && !(_.includes(FREE_PATHS_NAMES, this.props.location.pathname))) {
                var OneSignal = window.OneSignal || [];
                OneSignal.isPushNotificationsEnabled(function(isEnabled) {
                    if (!isEnabled) {
                        OneSignal.push(function() {
                            OneSignal.showHttpPrompt();
                        });
                    } else {
                        OneSignal.getUserId(function(userId) {
                            // Make a POST call to your server with the user ID
                            this.props.storeOnesignalId(userId);
                        }.bind(this));
                    }
                    // OneSignal.push(function() {
                    //    OneSignal.showHttpPermissionRequest();
                    // });
                }.bind(this));
            }
        } catch (e) {
            // линтф фикс не разрешает пустые блоки а без catch не работает
        }

        if (this.props.partner) {
            this.setLongPoll();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.error && this.props.error.status === 401 && !(_.includes(FREE_PATHS_NAMES, this.props.location.pathname))) {
            this.props.logout();// разлогиниваемся
            this.props.clearAjaxError();// ощищаем ошибку
            this.props.history.push('/login');
        }
        if (!prevProps.partner && this.props.partner && this.props.token && !this.props.onesignalId) {
            var OneSignal = window.OneSignal || [];

            OneSignal.isPushNotificationsEnabled(function(isEnabled) {
                if (!isEnabled) {
                    OneSignal.push(function() {
                        OneSignal.showHttpPrompt();
                    });
                } else {
                    OneSignal.getUserId(function(userId) {
                        // Make a POST call to your server with the user ID
                        this.props.storeOnesignalId(userId);
                    }.bind(this));
                }
                // OneSignal.push(function() {
                //    OneSignal.showHttpPermissionRequest();
                // });
            }.bind(this));
        }
    }

    componentWillUnmount() {
        if (this.longPollXhr) {
            this.longPollXhr.abort();
        }
    }

    setLongPoll(since) {
        const data = this.props.partner ? { companyId: this.props.partner.company_id } : undefined;
        if (since) {
            data.timestamp = since;
        }
        this.longPollXhr = new XMLHttpRequest();
        this.longPollXhr.open('GET', process.env.REACT_APP_WAIT_EVENT_URL + '?' + httpBuildQuery(data, '', '&'));
        this.longPollXhr.send();
        this.longPollXhr.onload = (e) => {
            let res = e.currentTarget;
            if (res.status !== 200) {
                if (res.status === 504) {
                    this.setLongPoll(since);
                } else {
                    setTimeout(() => {
                        this.setLongPoll(since);
                    }, LONG_POLL_FAIL_TIMEOUT);
                }
            } else {
                res = JSON.parse(res.responseText);
                const event = _.find(this.eventTypes, { label: res.name });

                const eventsData = { offset: 0, limit: 10 };

                if (this.props.eventsType === 'withoutCalls') {
                    eventsData.excluding_type = 'client incoming call';
                } else {
                    eventsData.type = 'client incoming call';
                }

                // this.props.pushEvent(res);
                this.props.fetchEvents(eventsData, false);

                if (event && event.update) {
                    const day = this.props.currentDay ? this.props.currentDay : moment().startOf('date');
                    const range_start = moment(day).startOf('date').format();

                    const range_end = moment(day).startOf('date').add(2, 'd').format();
                    this.props.fetchSchedules([day.format('Y-MM-DD'), moment(day).add(1, 'd').format('Y-MM-DD')]);
                    this.props.fetchOrders(this.props.partner.company_id, {
                        range_start: range_start,
                        range_end: range_end
                    }, true);
                    this.props.fetchReserves({ range_start: range_start, range_end: range_end }, false);
                }
                this.setLongPoll(res.id);
            }
        };
    }

    render() {
        const isFree = _.includes(FREE_PATHS_NAMES, this.props.location.pathname);
        return (
            <div className='container text-center'>
                <div className={classes.mainContainer}>
                    { isFree
                        ? this.props.children
                        : (<FrontFrame {...this.props}>{ this.props.children }</FrontFrame>) }
                </div>
            </div>
        );
    }
}

CoreLayout.contextTypes = {
    router: PropTypes.object
};

export default CoreLayout;
