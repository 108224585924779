import { connect } from 'react-redux';
import { actions } from './../modules/widgets';
import { showHeader, clearAjaxError } from 'modules/hangar';
import { fetchCompany } from 'modules/company';

import Clients from '../components/Widgets';

const mapStateToProps = (state) => ({
    fetching: state.widgets.fetching || state.company.fetching.company,
    widgets: state.widgets.widgets,
    ajaxError: state.hangar.ajaxError,
    company: state.company.company,
    partner: state.account.partner
});

export default connect(mapStateToProps, Object.assign(actions, { showHeader, clearAjaxError, fetchCompany }))(Clients);
