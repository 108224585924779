import React from 'react';
import PropTypes from 'prop-types';
// comps
import Select from 'components/Select';
import { withRouter } from 'react-router-dom';

class RouteSelect extends React.Component {
    componentDidMount() {

    }

    UNSAFE_componentWillReceiveProps() {

    }

    render() {
        const sectionItems = [
            { label: 'мастерам (отображение услуг у мастеров)', type: 'item', value: 'resources' },
            { label: 'услугам (отображение мастеров при услуге)', type: 'item', value: 'services' },
            { label: 'заказам', type: 'item', value: 'orders' },
            { label: 'sms', type: 'item', value: 'sms' }
            /* { label: 'ОКК', type: 'item', value: 'okk' }, */
        ];

        return (
            <Select
                name='sorts'
                value={this.props.value}
                options={sectionItems}
                type='select'
                className='statistics-block__statistic_type_select'
                onChange={(val) => {
                    this.props.history.push('/statistics/' + val.value + (this.props.httpQuery || ''));
                }}
            />
        );
    }
}

RouteSelect.contextTypes = {
    router: PropTypes.object
};

export default withRouter(RouteSelect);
