import React from 'react';
import ClientSearch from 'components/ClientSearch';
import CreateClient from '../../../ModalWindows/CreateClient';

require('./ClientTop.scss');

class CreateClientModal extends React.Component {
    state = {
        shadeMenuStatus: null,
        addClient: false
    };

    searchClients(e) {
        const value = e.currentTarget.value.trim();
        if (value.length > 1) {
            if (this.searchTimeOutId) {
                clearTimeout(this.searchTimeOutId);
            }
            var fetchClients = function() {
                this.props.fetchSearchClients(this.props.clientsSearch, 0, 10, false);
            }.bind(this);
            this.searchTimeOutId = setTimeout(fetchClients, 250);
        } else {
            this.props.clearClients();
        }
    }

    render() {
        return (
            <div className={'clientTop ' + (this.props.open ? 'open' : '')}>
                <div className='wrapper'>
                    <div className='clientTop__name'>{ this.props.client.nick }</div>
                    <div
                        className={'arrow ' + (this.props.open ? 'open' : '')}
                        onClick={this.props.openHandler}
                    />
                    <div className='text'>
                        { this.props.open ? 'Скрыть карточку клиента' : 'Показать карточку клиента' }
                    </div>
                    <ClientSearch
                        openHandler={this.props.openHandler}
                        open={this.props.open}
                    />
                    <div
                        className='addClient' onClick={(e) => {
                            e.preventDefault();
                            this.setState({ addClient: true });
                        }}
                    >+
                    </div>
                </div>
                { this.state.addClient ? <CreateClient closeHandler={() => { this.setState({ addClient: false }); }} /> : null }
            </div>
        );
    }
}

export default CreateClientModal;
