import { connect } from 'react-redux';
import { actions } from '../../../modules/okk';

import Reports from '../Reports';

const mapStateToProps = (state) => ({
    showPollCard: state.okk.showPollCard,
    pollCardData: state.okk.pollCardData,
    fetching: state.okk.fetching,
    reports: state.okk.reports,
    sort: state.okk.sort,
    exportingResults: state.okk.exportingResults,
    exportResultsId: state.okk.exportResultsId

});

export default connect(mapStateToProps, actions)(Reports);
