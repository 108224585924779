import React from 'react';

import cn from 'classnames';

import classes from './orderModal.module.scss';
// import InputElement from 'react-input-mask';
import { /* getOffsetRect, formatNumber, */ formatDuration, togglePushObjectById, HELP_CSS/*, isEmpty */ } from 'utils';
import moment from 'moment';
import ModalWindow from 'components/ModalWindow';
import ModalWindowCL from 'components/ModalWindowCL';
/* import Select from 'components/Select';
import ClientsList from 'components/ClientsList'; */
import { Scrollbars } from 'react-custom-scrollbars';
import WithClickOutside from 'extends/WithClickOutside';
import _ from 'lodash';
import ClientSearch from 'components/ClientSearchV2';
import Select from '../../Select';

const DEFAULT_DURATION = 30;

class OrderModal extends WithClickOutside {
    state = {
        activeService: null,
        reserveClient: true,
        viewMode: 'order', // searchClient, addClient,
        step: 'services',
        checkedOptions: {}
    };

    UNSAFE_componentWillMount() {
        if (this.props.toOrder.reserveClient === false || this.props.toOrder.reserveClient === true) {
            this.setState({ reserveClient: this.props.toOrder.reserveClient });
        }
    }

    componentDidMount() {
        super.componentDidMount();
        // грузим каталоги по выбранному ресурсу
        if (!this.props.services[this.props.resource.angara_id]) {
            this.props.fetchServices(this.props.resource.angara_id);
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    outerClick() {
        this.props.chooseToOrder(null);
    }

    handlerFetchOrder() {
        // резерв, если...
        const toOrder = this.props.toOrder;

        if (this.props.company.integrated_with_1c ||// интегрирован с 1c
            !toOrder.services ||// нет услуг
            toOrder.services.length === 0 ||// совсем нет услуг
            (
                !toOrder.client ||
                (!_.get(toOrder, 'client.id') && (toOrder.client && (!toOrder.client.name || !toOrder.client.phone)))
            )
        ) {
            const reserveData = {
                resources: [this.props.resource.angara_id],
                scheduled_to: moment(toOrder.date).format(),
                duration: toOrder.duration || _.sum(_.map(toOrder.services, s => (s.duration))),
                origin: 'clubPartner'
            };

            if (toOrder.descr) {
                reserveData.descr = toOrder.descr;
            }

            if (this.props.toOrder.client) {
                if (this.props.toOrder.client.id) {
                    reserveData.client_id = this.props.toOrder.client.id;
                } else if (this.props.toOrder.client.phone && this.props.toOrder.client.name) {
                    reserveData.phone = this.props.toOrder.client.phone;
                    reserveData.nick = this.props.toOrder.client.name;
                }
            }

            this.props.postReserve(reserveData);
        } else {
            const customDuration = _.get(this.props.toOrder, 'duration');

            const realDuration = _.sum(_.map(this.props.toOrder.services, s => s.duration));
            if (customDuration && customDuration !== realDuration) {
                _.each(this.props.toOrder.services, s => {
                    s.duration = parseInt(customDuration / this.props.toOrder.services.length);
                });
            }

            const data = {
                status: 'ACTIVE',
                company_id: this.props.company.id,
                services: this.props.toOrder.services,
                resources: [this.props.resource.angara_id],
                scheduled_to: moment(this.props.toOrder.date).format(),
                sms_confirm: false,
                origin: 'clubPartner'
            };

            if (toOrder.descr) {
                data.descr = toOrder.descr;
            }

            if (this.props.toOrder.client) {
                if (this.props.toOrder.client.id) {
                    data.client_id = this.props.toOrder.client.id;
                } else if (this.props.toOrder.client.phone && this.props.toOrder.client.name) {
                    data.phone = this.props.toOrder.client.phone;
                    data.nick = this.props.toOrder.client.name;
                }
            }

            // удаляем поле с описанием, если оно пустое
            if (data.descr === '') {
                delete data.descr;
            }

            this.props.postOrder(this.props.client.id, data);
            this.props.deleteLocalStorageClient(this.props.client.id);
        }
    }

    changeService(service) {
        let duration = service.averageDuration; let price = service.minPrice; let totalPrice = 0;

        const resource = _.find(this.props.resources, { angara_id: this.props.toOrder.resId });

        const resService = _.find(resource.services, { id: service.id });

        const serviceTemplate = _.cloneDeep(_.pick(_.get(_.find(this.props.services.items, { id: service.id }), 'serviceTemplate'), ['id', 'name']));
        // смотрим в ресурсах есть ли длительность и цена для обозначенной услуги
        duration = resService && resService.duration ? resService.duration : duration;
        price = _.get(resService, 'price', price);

        const serviceToPush = _.merge(_.pick(_.cloneDeep(service), ['id', 'serviceOptions', 'serviceResourceDurations']),
            { duration, price, serviceTemplate });

        let services = null;
        if (service) {
            services = this.props.toOrder.services || [];
            togglePushObjectById(serviceToPush, services);
            totalPrice = price;
            if (this.props.toOrder.services) {
                totalPrice += this.props.toOrder.totalPrice;
            }
        }

        this.props.chooseToOrder({
            services: services,
            duration: services && services.length ? null : DEFAULT_DURATION,
            totalPrice: totalPrice
        });
    }

    changeDuration(value) {
        this.props.chooseToOrder({ duration: value.value });
    }

    removeService(service) {
        let services = this.props.toOrder.service;

        let duration = this.props.toOrder.duration - service.duration;

        const price = this.props.toOrder.totalPrice - service.price;
        _.remove(services, ['id', service.id]);

        if (!services.length) {
            services = null;
            duration = 30;
        }

        this.props.chooseToOrder({
            service: services,
            duration: duration,
            totalPrice: price
        });
    }

    clearOrder(e) {
        e.preventDefault();
        e.stopPropagation();
        // let currentDay = this.props.currentDay ? this.props.currentDay : moment().startOf('date');
        // лшиние запросы при включенном лонгпулле
        /*        this.props.fetchOrders(this.props.company.id, {
            range_start: moment(currentDay).startOf('date').format(),
            range_end: moment(currentDay).startOf('date').add(2, 'd').format()
        });
        this.props.fetchReserves(this.props.company.id, {
            range_start: moment(currentDay).startOf('date').format(),
            range_end: moment(currentDay).startOf('date').add(2, 'd').format()
        });
        this.props.fetchSchedule(this.props.resource.angara_id, {
            range_start: currentDay.format('YYYY-MM-DD'),
            range_end: moment(currentDay).add(1, 'd').format('YYYY-MM-DD')
        }, this.props.company.id); */
        this.props.clearOrder();
        // this.props.shading();
    }

    changeActiveService(id, e) {
        if (e) {
            e.stopPropagation();
        }
        this.setState({ activeService: id });
    }

    changeServicePrice(id, price) {
        const template = /^[0-9]+$/;

        const services = _.cloneDeep(this.props.toOrder.services);

        const serviceKey = _.findKey(this.props.toOrder.services, { id: id });
        if (!price) {
            price = 0;
        }
        if (template.test(price)) {
            services[serviceKey].price = Number(price);
            this.props.chooseToOrder({
                services: services
            });
        }
    }

    switchReserveClient() {
        this.setState({ reserveClient: !this.state.reserveClient });
    }

    changeViewMode(value, e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({ viewMode: value });
    }

    createClient(ready, e) {
        e.preventDefault();
        if (ready) {
            this.props.createClient().then(res => {
                if (res) {
                    this.changeViewMode('order');
                }
            });
        }
    }

    normalizeServices(servicesByCatalogues, resource) {
        const result = {};
        _.each(servicesByCatalogues, catalogue => {
            result[catalogue.id] = _.omit(catalogue, ['services', 'serviceTemplates']);
            const services_ = _.filter(catalogue.services, service => {
                return !_.isEmpty(service.resources) && _.some(service.resources, r => {
                    return !resource || r.id === resource.angara_id;
                }) && !service.serviceTemplate.isHidden;
            });
            const nServices = {};
            _.each(services_, s => {
                if (!nServices[s.serviceTemplate.id]) {
                    nServices[s.serviceTemplate.id] = _.cloneDeep(s.serviceTemplate);
                    nServices[s.serviceTemplate.id].services = [];
                }
                nServices[s.serviceTemplate.id].services.push(_.omit(s, ['serviceTemplate']));
            });
            result[catalogue.id].serviceTemplates = nServices;
        });
        return result;
    }

    checkOption(templateId, valueId, removeValueIds, services, justCheckIt = false, e) {
        if (e) {
            e.stopPropagation();
        }

        const checkedOptions = _.clone(this.state.checkedOptions);
        if (!checkedOptions[templateId]) {
            checkedOptions[templateId] = [];
        }
        // удаляем все возможно чекнутые id значений данного справочника данного шаблона за исключением чекнутого значения
        checkedOptions[templateId] = _.filter(checkedOptions[templateId], vId => {
            return removeValueIds.indexOf(vId) === -1 || vId === valueId;
        });
        // или чекаем или не чекаем
        if (checkedOptions[templateId].indexOf(valueId) === -1) {
            checkedOptions[templateId].push(valueId);
        } else {
            checkedOptions[templateId].splice(checkedOptions[templateId].indexOf(valueId), 1);
        }
        // определяем наличие возможной услуги дабы не чекать то, чего не может быть
        const availableServices = _.some(services, s => {
            const soIds = _.map(s.serviceOptions, so => _.get(so, 'valueByReference.id'));
            return _.every(checkedOptions[templateId], vId => {
                return soIds.indexOf(vId) !== -1;
            });
        });
        if (!availableServices) {
            return null;
        }
        // определяем наличие соответствующей услуги
        const checkedServices = _.find(services, s => {
            return _.every(s.serviceOptions, so => {
                return checkedOptions[templateId].indexOf(_.get(so, 'valueByReference.id')) !== -1;
            });
        });

        if (justCheckIt) {
            return true;
        } else {
            this.setState({ checkedOptions: checkedOptions });
            if (checkedServices) {
                this.changeService(checkedServices);
            }
        }
        return false;
    }

    render() {
        moment.locale('ru');
        const { company, toOrder } = this.props;
        const resourceServices = _.get(this.props.groupedServices, this.props.toOrder.resId, []);
        const resource = _.find(company.resources, { angara_id: toOrder.resId });
        let content = null;

        const isOrder = !this.props.company.integrated_with_1c &&// не интегрирован с 1c
            toOrder.services && toOrder.services.length &&// есть услуги
            toOrder.client && (toOrder.client.id || (toOrder.client.phone && !toOrder.client.nick));// есть клиент;

        const fetching = _.some(this.props.fetching);

        // отрабатываем вывод
        switch (this.props.toOrder.status) {
        case 'finish':
            return (
                <ModalWindowCL>
                    <div
                        className={classes.finishedWindow + ' ' + classes.finish + ' ' + (this.state.modalClass ? classes[this.state.modalClass] : '')}
                    >
                        <div className={classes.text}>
                            { isOrder ? 'Клиент успешно записан' : 'Время забронировано' }
                        </div>
                        <div className={classes.clear} onClick={this.clearOrder.bind(this)}>Продолжить</div>
                    </div>
                </ModalWindowCL>
            );
        case 'failed':
            return (
                <ModalWindowCL>
                    <div className={classes.finishedWindow + ' ' + classes.failed}>
                        <div className={classes.text}>Ошибка записи</div>
                        <div className={classes.clear} onClick={this.clearOrder.bind(this)}>Продолжить</div>
                    </div>
                </ModalWindowCL>
            );
        default: {
            const currentDay = this.props.orderDay ? this.props.orderDay : moment().startOf('date');

            const servicesTree = this.normalizeServices(_.cloneDeep(resourceServices), resource);

            // определяем возможный временной промежуток записи

            const schedule = _.get(_.find(this.props.schedules, { id: resource.id }), moment(currentDay).format('YYYY-MM-DD'));

            let lastAvailableChunkService;
            const firstChunkService = (parseInt(this.props.toOrder.date.format('H') * 60 + parseInt(this.props.toOrder.date.format('m'))) / (60 * this.props.chunkSize));
            lastAvailableChunkService = schedule.indexOf('0', firstChunkService + 1);
            if (lastAvailableChunkService === -1) {
                lastAvailableChunkService = schedule.length;
            }
            const availableDurationChunks = lastAvailableChunkService - firstChunkService;

            const availableDuration = availableDurationChunks * 60 * this.props.chunkSize;
            const proposedDurations = [
                { value: 30, label: '30 мин', type: 'item' },
                { value: 60, label: '1 ч.', type: 'item' },
                { value: 90, label: '1 ч. 30 мин.', type: 'item' },
                { value: 120, label: '2 ч.', type: 'item' },
                { value: 150, label: '2 ч. 30 мин', type: 'item' },
                { value: 180, label: '3 ч.', type: 'item' },
                { value: 210, label: '3 ч. 30 мин', type: 'item' },
                { value: 240, label: '4 ч.', type: 'item' },
                { value: 270, label: '4 ч. 30 мин', type: 'item' },
                { value: 300, label: '5 ч.', type: 'item' },
                { value: 330, label: '5 ч. 30 мин', type: 'item' },
                { value: 360, label: '6 ч.', type: 'item' },
                { value: 390, label: '6 ч. 30 мин', type: 'item' },
                { value: 420, label: '7 ч.', type: 'item' },
                { value: 450, label: '7 ч. 30 мин', type: 'item' },
                { value: 480, label: '8 ч.', type: 'item' }
            ];
                // если в фиксированном списке длительности нет рекомендованного для выбранной услуги
            if (toOrder.duration && !_.find(proposedDurations, { value: toOrder.duration || _.sum(_.map(toOrder.services, s => (s.duration))) })) {
                proposedDurations.push(
                    {
                        value: toOrder.duration,
                        label: formatDuration(toOrder.duration || _.sum(_.map(toOrder.services, s => (s.duration)))),
                        type: 'item'
                    });
            }

            // определяем возможные для выбора длительности

            const durations = _.filter(proposedDurations, duration => {
                return (duration.value / (60 * this.props.chunkSize)) <= availableDurationChunks;
            });

            let durationError = null;
            if (!_.find(durations, { value: toOrder.duration || _.sum(_.map(toOrder.services, s => (s.duration))) })) {
                durationError = 'Скорректируйте продолжительность оказания услуг';
            }
            content = (
                <div className={classes.container + ' ' + classes[this.state.step]}>
                    <div className={classes.header}>
                        <div className={classes.title}>Запись к
                            мастеру { resource.name } { toOrder.date.format('DD MMMM HH:mm') }
                        </div>
                    </div>
                    <div className={classes.services}>
                        <div className={classes.blockHeader}>Выберите услугу</div>
                        <Scrollbars>
                            <div className={classes.servicesCatalogue}>
                                <h3>Бронирование времени</h3>
                                <div className={classes.items}>
                                    { _.map([30, 60, 90], duration => (
                                        <div
                                            key={duration} className={cn({
                                                [classes.item]: true,
                                                [classes.active]: (!toOrder.services || toOrder.services.length === 0) && toOrder.duration === duration
                                            })}
                                            onClick={() => {
                                                this.setState({ checkedOptions: [] });
                                                this.props.chooseToOrder({ services: [], duration });
                                            }}
                                        >
                                            <div
                                                className={classes.title}
                                            >
                                                <span>{ formatDuration(duration) }</span>
                                            </div>
                                        </div>
                                    )) }
                                </div>
                            </div>
                            { _.map(servicesTree, catalogue => (
                                <div key={catalogue.id} className={classes.servicesCatalogue}>
                                    <h3>{ catalogue.name }</h3>
                                    <div className={classes.items}>
                                        { _.map(catalogue.serviceTemplates, t => {
                                            let price;
                                            const optioned = t.serviceOptionTemplates && t.serviceOptionTemplates.length;
                                            return (
                                                <div
                                                    className={cn({
                                                        [classes.item]: true,
                                                        [classes.active]: _.some(t.services, s => (_.some(_.get(this.props.toOrder, 'services', {}), { id: s.id })))
                                                    })}
                                                    key={t.id}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        if (t.services && t.services.length === 1) {
                                                            this.changeService(_.find(t.services));
                                                        }
                                                    }}
                                                >
                                                    <div
                                                        className={classes.title + ' ' + (optioned ? classes.withOptions : '')}
                                                    >
                                                        <span>{ t.name }</span>
                                                    </div>
                                                    {
                                                        t.serviceOptionTemplates && t.serviceOptionTemplates.length
                                                            ? (
                                                                <div className={classes.options}>
                                                                    { _.map(t.serviceOptionTemplates, ot => (
                                                                        <div key={ot.id} className={classes.template}>
                                                                            { _.map(ot.serviceOptionReference.values,
                                                                                (v) => {
                                                                                    const active = _.get(this.state.checkedOptions, t.id, []).indexOf(v.id) !== -1;
                                                                                    return (
                                                                                        <div
                                                                                            key={v.id}
                                                                                            className={cn({
                                                                                                [classes.value]: true,
                                                                                                [classes.available]: this.checkOption(t.id, v.id, _.map(ot.serviceOptionReference.values, v => v.id), t.services, true),
                                                                                                [classes.active]: active
                                                                                            })}
                                                                                            onClick={this.checkOption.bind(this, t.id, v.id, _.map(ot.serviceOptionReference.values, v => v.id), t.services, false)}
                                                                                        >
                                                                                            { v.value }
                                                                                        </div>
                                                                                    );
                                                                                }) }
                                                                        </div>
                                                                    )) }
                                                                </div>
                                                            )
                                                            : null
                                                    }
                                                    { price > 0
                                                        ? (<div className='price'>от { price } руб., </div>)
                                                        : null }
                                                </div>
                                            );
                                        }) }
                                    </div>
                                </div>
                            )) }
                        </Scrollbars>
                    </div>
                    <div className={classes.confirm}>
                        <div
                            className={classes.tab} onClick={() => {
                                this.setState({ step: 'services' });
                            }}
                        >
                            Каталог услуг <div className={HELP_CSS.hlpSelectIcon} />
                        </div>
                        <div className={classes.choosenServices}>
                            { _.get(toOrder, 'services.length')
                                ? (
                                    <div className={classes.blockHeader}>Выбранные услуги</div>
                                )
                                : (
                                    <div className={classes.blockHeader + ' ' + classes.noBlock}>Забронировано
                                        время
                                    </div>
                                ) }

                            { _.map(toOrder.services, (service, key) => {
                                const serviceOptions = _.map(service.serviceOptions, s => (s.valueByReference.value));
                                return (
                                    <div
                                        key={key}
                                        className={classes.service + (serviceOptions.length ? (' ' + classes.withOptions) : '')}
                                    >
                                        <div className={classes.title + ' row'}>
                                            <div className='col-md-6'>
                                                { service.serviceTemplate.name }&nbsp;
                                                <div className={HELP_CSS.hlpRemoveIcon} />
                                            </div>
                                            <div className='col-md-3'>
                                                <div className={classes.price}>
                                                    <input
                                                        type='text' value={service.price}
                                                        style={{ width: Math.ceil(String(service.price).length / 1.8) + 'rem' }}
                                                        onChange={(e) => {
                                                            this.changeServicePrice(service.id, e.target.value);
                                                        }}
                                                    />
                                                        &nbsp;руб.
                                                </div>
                                            </div>
                                            <div className='col-md-3'>
                                                <div className={classes.duration}>
                                                    { formatDuration(service.duration) }
                                                </div>
                                            </div>
                                        </div>
                                        { _.map(serviceOptions, (so, key) => {
                                            return (<div className={classes.option} key={key}>{ so }</div>);
                                        }) }
                                    </div>
                                );
                            }) }

                        </div>
                        <div className={classes.clientSearch}>
                            <ClientSearch
                                select={(client) => {
                                    this.props.chooseToOrder({
                                        client: client
                                    });
                                }}
                                selectedIsOk={!!(this.props.toOrder.client && ((!!this.props.toOrder.client.id || this.props.toOrder.client.phone) && this.props.toOrder.client.name))}
                                onChange={() => (this.props.chooseToOrder({ client: null }))}
                            />
                        </div>
                        <div className={classes.description}>
                            <textarea
                                name='description' placeholder='Комментарий:'
                                onChange={(e) => (this.props.chooseToOrder({ descr: e.target.value }))}
                            >
                                { toOrder.descr }
                            </textarea>
                        </div>
                    </div>
                    <div className={classes.footer}>
                        <div className='row'>
                            <div className={classes.info + ' col-md-8'}>
                                <div className={classes.serviceCount}>Выбрано
                                    услуг: { _.get(toOrder, 'services.length', 0) }
                                </div>
                                <div
                                    className={classes.duration}
                                >Время:&nbsp;
                                    <Select
                                        className='orderDuration'
                                        name='duration'
                                        placeholder='...'
                                        value={formatDuration(this.props.toOrder.duration || _.sum(_.map(toOrder.services, s => (s.duration))))}
                                        options={durations}
                                        wrong={!!durationError}
                                        type='link toTop'
                                        onChange={this.changeDuration.bind(this)}
                                    />
                                    { durationError ? (<div className={classes.error}>{ durationError }</div>) : null }
                                </div>
                                <div className={classes.price}>Стоимость: { _.get(toOrder, 'price', 0) }</div>
                            </div>
                            <div className={classes.links + ' col-md-4'}>
                                {
                                    this.state.step === 'services'
                                        ? (
                                            <div
                                                className={classes.cancel}
                                                onClick={() => this.props.chooseToOrder(null)}
                                            >
                                                Отмена
                                            </div>
                                        )
                                        : (
                                            <div
                                                className={classes.backward}
                                                onClick={() => this.setState({ step: 'services' })}
                                            >
                                                назад
                                            </div>
                                        )
                                }
                                <div
                                    className={classes.proceed + ' ' +
                                        (fetching || availableDuration < (toOrder.duration || _.sum(_.map(toOrder.services, s => (s.duration)))) ? classes.disabled : '')}
                                    onClick={() => {
                                        if (this.state.step === 'services') {
                                            this.setState({ step: 'confirm' });
                                        } else {
                                            if (availableDuration >= (toOrder.duration || _.sum(_.map(toOrder.services, s => (s.duration))))) {
                                                this.handlerFetchOrder();
                                            }
                                        }
                                    }}
                                >Продолжить
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
            break;
        }
        }

        return <ModalWindow>{ content }</ModalWindow>;
    }
}

export default OrderModal;
