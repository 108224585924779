/**
 * Created by user on 19.07.16.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classes from './ClientHistory.module.scss';
import _ from 'lodash';
import moment from 'moment';
import Pager from 'components/Pager';
import OrderModal from '../../../../ModalWindows/Order';
import CallModal from '../../../../ModalWindows/Call';
import ReserveModal from '../../../../ModalWindows/Reserve';

const LIMIT = 10;

class ClientHistory extends React.Component {
    UNSAFE_componentWillMount() {
        if (this.props.client) {
            this.props.fetchClientOrdersAndCalls(this.props.client.id, { limit: LIMIT, offset: 0 });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const newParams = nextProps.location.query;
        const oldParams = this.props.location.query;
        if (JSON.stringify(newParams) !== JSON.stringify(oldParams)) {
            this.props.fetchClientOrdersAndCalls(this.props.client.id, {
                limit: LIMIT,
                offset: LIMIT * ((newParams.page || 1) - 1),
                page: newParams.page
            });
        }

        const newClient = nextProps.client.id !== this.props.client.id;
        if (newClient) {
            this.props.fetchClientOrdersAndCalls(nextProps.client.id, { limit: LIMIT, offset: 0 });
        }
    }

    showModal(type, item) {
        this.setState({ item: item });
        this.props.shadeMenuChange(type);
    }

    search(e) {
        if (!e.target.value) {
            this.props.fetchClientOrdersAndCalls(this.props.client.id, { limit: LIMIT, offset: 0 });
        } else {
            this.props.fetchClientOrdersAndCalls(
                this.props.client.id, { limit: LIMIT, offset: 0, search: e.target.value.trim() }
            );
        }
    }

    render() {
        const orders = [];

        let modal;

        const status = {
            ACTIVE: 'Активный',
            EXECUTED: 'Выполнен',
            CANCELED: 'Отменен'
        };

        if (this.props.clientOrdersAndCalls) {
            _.map(this.props.clientOrdersAndCalls.items, (dateItems, index) => {
                orders.push(
                    <div className={classes.date} key={index}>{ moment(index).format('DD MMMM YYYY') }</div>
                );
                _.map(dateItems, item => {
                    switch (item.type) {
                    case 'call':
                        orders.push(
                            <div className={classes.call} key={'call' + item.model.id} onClick={this.showModal.bind(this, 'call', item.model)}>
                                <div className={classes.itemHeader}>
                                    <div className={classes.title}>Звонок в { moment(item.model.processed_at).format('HH:mm') }</div>
                                    <div className={classes.number}>№ { item.model.id }</div>
                                </div>
                                <div className={classes.row}>
                                    <div className={classes.data}>
                                        <div className={classes.label}>Причина звонка</div>
                                        <div className={classes.value}>{ item.model.reason }</div>
                                    </div>
                                </div>
                                <div className={classes.comment}>{ item.model.comment }</div>
                            </div>
                        );
                        break;
                    case 'order':
                        orders.push(
                            <div className={classes.order} key={'order' + item.model.id} onClick={this.showModal.bind(this, 'order', item.model)}>
                                <div className={classes.itemHeader}>
                                    <div className={classes.title}>
                                        Посещение в { moment(item.model.orderDetails[0].scheduledTo).format('HH:mm') }
                                    </div>
                                    <div className={classes.number}>№ { item.model.number }</div>
                                </div>
                                <div className={classes.row}>
                                    <div className={classes.data}>
                                        <div className={classes.label}>Причина посещения</div>
                                        <div className={classes.value}>Оказание услуг</div>
                                    </div>
                                    <div className={classes.data}>
                                        <div className={classes.label}>Статус</div>
                                        <div className={classes.value}>{ status[item.model.status] }</div>
                                    </div>
                                </div>
                                <div className={classes.comment}>{ item.comment }</div>
                            </div>
                        );
                        break;
                    case 'reserve':
                        orders.push(
                            <div className={classes.order} key={'reserve' + item.model.id} onClick={this.showModal.bind(this, 'reserve', item.model)}>
                                <div className={classes.itemHeader}>
                                    <div className={classes.title}>
                                        Резерв в { moment(item.model.scheduledTo).format('HH:mm') }
                                    </div>
                                    <div className={classes.number}># { item.model.id }</div>
                                </div>
                                <div className={classes.row}>
                                    <div className={classes.data}>
                                        <div className={classes.label}>Статус</div>
                                        <div className={classes.value}>{ status[item.model.status] }</div>
                                    </div>
                                </div>
                            </div>
                        );
                        break;
                    default:
                    }
                });
            });
        }

        switch (this.props.shadeMenuStatus) {
        case 'order':
            modal = (<OrderModal shadeMenuChange={this.props.shadeMenuChange} client={this.props.client} order={this.state.item} />);
            break;
        case 'call':
            modal = (<CallModal shadeMenuChange={this.props.shadeMenuChange} client={this.props.client} call={this.state.item} />);
            break;
        case 'reserve':
            modal = (<ReserveModal shadeMenuChange={this.props.shadeMenuChange} client={this.props.client} reserve={this.state.item} />);
            break;
        default:
        }
        return (
            <div className={classes.element}>
                <div className={classes.search}>
                    <input
                        type='text'
                        placeholder='Введите номер заказ наряда или дату в формате дд.мм.гг'
                        onChange={this.search.bind(this)}
                    />
                    <div className={classes.submit} />
                </div>
                <div className={classes.items}>
                    { orders }
                </div>
                { modal }
                { this.props.clientOrdersAndCalls ? <Pager {...this.props} total={this.props.clientOrdersAndCalls.totalCount} size={LIMIT} /> : null }
            </div>
        );
    }
}
ClientHistory.contextTypes = {
    router: PropTypes.object
};

export default ClientHistory;
