/**
 * Created by user on 19.07.16.
 */
import { connect } from 'react-redux';
import { cyclicNotificationShow, fetchClientCars } from 'modules/clients';

import BoardComputer from './BoardComputer';

const mapStateToProps = (state) => ({
    client: state.clients.client,
    cars: state.clients.cars,
    shadeMenuStatus: state.clients.shadeMenuStatus,
    businessType: state.company.company.business_type
});

export default connect(mapStateToProps, Object.assign({}, { cyclicNotificationShow, fetchClientCars }))(BoardComputer);
