import { connect } from 'react-redux';
import { actions } from '../../modules/statistics';

import BySms from './BySms';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => ({
    exportingSms: state.statistics.exportingSms,
    exportSmsId: state.statistics.exportSmsId,
    smsError: state.statistics.smsError
});

export default connect(mapStateToProps, actions)(withRouter(BySms));
