import React from 'react';
import classes from './Calendar.module.scss';
import Clients from './Clients';
import OrderFooter from 'components/OrderFooter';
import ClientsStream from './ClientsStream';
import Events from './Events';
import _ from 'lodash';

class Calendar extends React.Component {
    componentDidMount() {
        this.props.showHeader('hide');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.shadeMenuStatus && !this.props.shadeMenuStatus) {
            const body = document.querySelectorAll('body');

            const html = document.querySelectorAll('html');
            if (body[0] && body[0].style) {
                body[0].style.overflowY = 'hidden';
            }
            if (html[0] && html[0].style) {
                html[0].style.overflowY = 'hidden';
            }
        }
        if (!nextProps.shadeMenuStatus && this.props.shadeMenuStatus) {
            const body = document.querySelectorAll('body');

            const html = document.querySelectorAll('html');
            if (body[0] && body[0].style) {
                body[0].style.overflowY = 'visible';
            }
            if (html[0] && html[0].style) {
                html[0].style.overflowY = 'visible';
            }
        }
    }

    hideAllMenu(e) {
        let hideClientMenu = true;

        let id = null;

        let label = null;
        if (this.props.editClientChange) {
            id = this.props.editClientChange.split('-')[0];
            label = this.props.editClientChange.split('-')[1];
        }
        if (_.includes(['vin', 'year'], label)) {
            const car = _.find(this.props.changedCars, car => {
                return car.id === parseInt(id);
            });
            if (/[_Г]/.test(car[label]) || car[label].length === 0) {
                hideClientMenu = false;
            }
        }

        if (hideClientMenu) {
            this.props.editClient(false, e);
        }
        // this.props.showHideDp(e);
    }

    render() {
        return (
            <div onClick={this.hideAllMenu.bind(this, false)}>
                <div className={classes.body}>
                    <OrderFooter />
                    <ClientsStream />
                    <Events />
                    <Clients {...this.props} />
                </div>
            </div>
        );
    }
}

export default Calendar;
