import { connect } from 'react-redux';
import { actions } from 'modules/clients';

import ClientTop from './ClientTop';

const mapStateToProps = (state) => ({
    clients: state.clients.clients,
    client: state.clients.client
});

export default connect(mapStateToProps, actions)(ClientTop);
