import { connect } from 'react-redux';
import { actions } from '../../modules/feedback';
import { showHeader } from 'modules/hangar';

import Feedback from '../components/List';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => ({
    feedback: state.feedback.feedback,
    fetching: state.feedback.fetching
});

export default connect(mapStateToProps, Object.assign(actions, { showHeader }))(withRouter(Feedback));
