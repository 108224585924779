/**
 * Created by user on 25.05.2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classes from './Main.module.scss';
import Settings from './Settings';
import Statistic from './Statistic';

class Main extends React.Component {
    componentDidMount() {
        this.props.showHeader('hide');
    }

    render() {
        const { mainView } = this.props;
        let content;
        if (mainView === 'settings') {
            content = <Settings {...this.props} />;
        } else {
            content = <Statistic {...this.props} />;
        }
        return (
            <div className={classes.main}>
                <div className={classes.header}>
                    <div
                        className={classes.button + ' ' + (mainView === 'statistic' ? classes.active : '')}
                        onClick={this.props.changeMainView.bind(this, 'statistic')}
                    >
                        Статистика ОКК
                    </div>
                    <div
                        className={classes.button + ' ' + (mainView === 'settings' ? classes.active : '')}
                        onClick={this.props.changeMainView.bind(this, 'settings')}
                    >
                        Настройка внешнего вида ОКК
                    </div>
                </div>
                { content }
            </div>
        );
    }
}
Main.contextTypes = {
    router: PropTypes.object
};
export default Main;
