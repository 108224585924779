/**
 * Created by user on 19.07.16.
 */
import { connect } from 'react-redux';
import { fetchClient, changeClient, updateClient, editClient } from 'modules/clients';
import { checkTooltip, clearClients, clearClientCars, shadeMenuChange } from '../../../../modules/Calendar';

import Client from './Client';

const mapStateToProps = (state) => ({
    client: state.clients.client,
    clients: state.clients.clientsBySearch,
    editClientChange: state.clients.editClientChange,
    changedClient: state.clients.changedClient,
    menuStatus: state.clients.menuStatus,
    fetching: state.clients.fetching,
    shadeMenuStatus: state.clients.shadeMenuStatus,
    clientNameTooltipShow: state.clients.clientNameTooltipShow
});

export default connect(mapStateToProps, Object.assign({}, {
    fetchClient,
    changeClient,
    updateClient,
    editClient,
    checkTooltip,
    clearClients,
    clearClientCars,
    shadeMenuChange
}))(Client);
