import React from 'react';
import PropTypes from 'prop-types';

import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import rsScroller from 'react-smooth-scroller';
import WithClickOutside from 'extends/WithClickOutside';
import _ from 'lodash';

require('./Events.scss');

class Events extends WithClickOutside {
    constructor(props) {
        super(props);
        this.switchOpen = this.switchOpen.bind(this);
        this.showCalls = this.showCalls.bind(this);
        this.showAllEvents = this.showAllEvents.bind(this);
    }

    limit = 10;
    state = {
        open: false,
        offset: 0,
        data: null
    };

    openOrderModal = false;
    scrollToOrder = false;

    eventsTitles = {
        'client create': 'Новый клиент',
        'client registration': 'Регистрация клиента в Ortus',
        'client feedback': 'Обратная связь от клиента',
        'new client order': 'Добавлена запись',
        'new client reserve': 'Добавлена бронь',
        'client order': 'Добавлена запись',
        'client reserve': 'Забронировано время',
        'order update': 'Изменение записи',
        'reserve update': 'Бронь изменена',
        'client update': 'Обновление карточки клиента',
        'client remove': 'Удаление клиента',
        'order cancel': 'Отмена записи',
        'order date update': 'Перенос записи',
        'order services update': 'Отмена записи',
        'reserve cancel': 'Отмена бронирования',
        'client incoming call': 'Звонок от клиента'
    };

    UNSAFE_componentWillMount() {
        const data = { offset: 0, limit: this.limit };

        if (this.props.eventsType === 'withoutCalls') {
            data.excluding_type = 'client incoming call';
        } else {
            data.type = 'client incoming call';
        }

        this.props.fetchEvents(data, false);
        this.props.fetchLastCall();
    }

    renderTrack({ style, ...props }) {
        const trackStyle = {};
        return (
            <div
                className='scrollTrack'
                style={{ ...style, ...trackStyle }}
                {...props}
            />
        );
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {};
        return (
            <div
                className='scrollThumb'
                style={{ ...style, ...thumbStyle }}
                {...props}
            />
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.open && prevState.open && this.props.events.items && this.props.events.items.length > 0) {
            if (_.some(_.get(this.props.events, 'items', []), i => {
                return !i.viewed;
            })) {
                this.props.markAsRead({
                    ids: _.map(_.get(this.props.events, 'items', []), i => {
                        return i.id;
                    })
                });
            }
        }

        const { toOrder } = this.props; const modalMode = _.get(this.props.toOrder, 'mode'); let orderId; let oldOrderId;
        if (modalMode === 'viewOrder') {
            orderId = _.get(toOrder, 'orderId');
            oldOrderId = _.get(prevProps.toOrder, 'orderId', null);
        }
        if (modalMode === 'viewReserve') {
            orderId = _.get(toOrder, 'reserveId');
            oldOrderId = _.get(prevProps.toOrder, 'reserveId', null);
        }
        if (this.scrollToOrder && orderId && orderId !== oldOrderId) {
            const scrollSpeed = 1800; const // пикселей в секунду
                orderFooterHeaderHeight = 63;
            // отступ сверху в пикселях

            const minDuration = 200;

            const revise = window.pageYOffset || document.documentElement.scrollTop;

            const el = document.getElementById('division' + toOrder.divisionId + 'resource' + toOrder.resId);

            const rect = el.getBoundingClientRect();

            const topScroll = rect.top - orderFooterHeaderHeight;

            const duration = Math.max(Math.abs(parseInt(topScroll / scrollSpeed * 1000)), minDuration); // минимум 200
            rsScroller.scrollToTarget(el.className, {
                easing: 'easeInSine',
                duration: duration,
                frame: 25,
                revise: revise - orderFooterHeaderHeight
            });
            this.scrollToOrder = false;
        }

        const { data } = this.state;
        if (this.openOrderModal && prevProps.fetching && !this.props.fetching && data) {
            this.props.chooseToOrder(data);
            this.openOrderModal = false;
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.iReadIt && !this.props.iReadIt) {
            this.props.fetchEvents({ offset: 0, limit: this.limit }, false);
        }

        if (nextProps.eventsType !== this.props.eventsType) {
            const offset = 0;

            const data = { offset: offset, limit: this.limit };

            if (nextProps.eventsType === 'withoutCalls') {
                data.excluding_type = 'client incoming call';
            } else {
                data.type = 'client incoming call';
            }

            this.props.fetchEvents(data, false);
        }
    }

    scrollEventsList() {
        const { searchEventsScroll } = this.refs;
        const scroll = searchEventsScroll.getValues();
        if (scroll.clientHeight + scroll.scrollTop === scroll.scrollHeight) {
            // догружаем клиентов
            if (this.props.events.items && this.props.events.items.length < this.props.events.total) { // проверяем, что возможно еще есть что догружать
                const data = { offset: this.props.offset + this.limit, limit: this.limit };

                if (this.props.eventsType === 'withoutCalls') {
                    data.excluding_type = 'client incoming call';
                } else {
                    data.type = 'client incoming call';
                }

                this.props.fetchEvents(data, true);
            }
        }
    }

    openEvent(event) {
        const currentDay = this.props.currentDay || moment().startOf('day');

        const { company, dayTime, events } = this.props;

        const data = {};
        let entity;
        let resourceId;
        let scheduledTo;

        if (event.type === 'client feedback') {
            if (event.entity_id) {
                this.props.history.push('/feedback/' + event.entity_id);
            }
            return true;
        }

        if (event.type === 'client incoming call') {
            this.props.fetchClient(event.client_id);
        }

        // this.props.fetchClient(this.props.clientId);
        if (~event.type.indexOf('order')) {
            entity = _.find(_.get(events.orders, 'orders'), ['id', event.entity_id]);
            if (!entity) {
                return false;
            }

            scheduledTo = _.get(entity, 'orderDetails[0].scheduledTo');
            data.date = moment(scheduledTo);
            data.chunk = moment(scheduledTo).diff(moment(scheduledTo).startOf('date'), 'minutes', true) / 30;
            data.mode = 'viewOrder';
            data.orderId = event.entity_id;
            resourceId = _.get(entity, 'orderDetails[0].resource.id');
        }
        if (~event.type.indexOf('reserve')) {
            entity = _.find(_.get(events.reserves, 'reserves'), ['id', event.entity_id]);
            if (!entity) {
                return false;
            }

            scheduledTo = _.get(entity, 'scheduledTo');
            data.date = moment(scheduledTo);
            data.chunk = moment(scheduledTo).diff(moment(scheduledTo).startOf('date'), 'minutes', true) / 30;
            data.mode = 'viewReserve';
            data.reserveId = event.entity_id;
            resourceId = _.get(entity, 'resource.id');
        }

        const resource = _.find(_.get(company, 'resources'), ['angara_id', resourceId]);
        const division = _.first(_.get(resource, 'divisions'));

        data.divisionId = _.get(division, 'id', 'noDivision');
        data.resId = resourceId;

        if (moment(scheduledTo).isSame(currentDay, 'day')) {
            if (data.chunk > 41 && dayTime === 'day') {
                this.props.changeDayTime('night');
            }

            if (data.chunk < 42 && data.chunk > 15 && dayTime === 'night') {
                this.props.changeDayTime('day');
            }

            if (data.chunk < 16) {
                if (dayTime === 'day') {
                    this.props.changeDayTime('night');
                }
                this.changeDate(moment(scheduledTo).startOf('date').add(-1, 'd'));
            }

            this.props.chooseToOrder(data);
        } else if (moment(scheduledTo).isAfter(currentDay) && moment(scheduledTo).isBefore(currentDay.clone().add(33, 'h'))) {
            if (dayTime === 'day') {
                this.props.changeDayTime('night');
            }
            this.props.chooseToOrder(data);
        } else {
            let date = moment(scheduledTo).startOf('date');
            if (data.chunk > 41 && dayTime === 'day') {
                this.props.changeDayTime('night');
            }

            if (data.chunk < 42 && data.chunk > 15 && dayTime === 'night') {
                this.props.changeDayTime('day');
            }

            if (data.chunk < 16) {
                if (dayTime === 'day') {
                    this.props.changeDayTime('night');
                }
                date = date.add(-1, 'd');
            }

            this.changeDate(date);
            this.setState({ data: data });
            this.openOrderModal = true;
        }

        this.scrollToOrder = true;
    }

    changeDate(date) {
        const day = moment(date);
        const range_start = moment(date).startOf('date').format();

        const range_end = moment(date).startOf('date').add(2, 'd').format();
        this.props.fetchSchedules([day.format('Y-MM-DD'), moment(day).add(1, 'd').format('Y-MM-DD')]);
        this.props.fetchOrders(this.props.partner.company_id, { range_start: range_start, range_end: range_end }, true);
        this.props.fetchReserves({ range_start: range_start, range_end: range_end });
        this.props.changeDate(date);
    }

    outerClick() {
        this.setState({ open: false });
    }

    switchOpen() {
        this.setState({ open: !this.state.open });
    }

    showCalls() {
        this.props.changeEventsType('onlyCalls');
    }

    showAllEvents() {
        this.props.changeEventsType('withoutCalls');
    }

    render() {
        const { eventsType, lastCall } = this.props;
        const events = _.get(this.props.events, 'items', []);

        const readEvents = _.get(this.props.readEvents, 'items', []);
        return (
            <div className={'events' + (this.state.open ? ' open' : '') + (lastCall ? ' withLastCall' : '')}>
                <div className='events__top'>
                    <div className='events__top__bell' onClick={this.switchOpen}>
                        { this.props.events && this.props.events.unViewedTotal
                            ? <div className='events__top__counter'>{ this.props.events.unViewedTotal }</div>
                            : null }
                    </div>
                    <div className={'events__top__text' + (eventsType === 'withoutCalls' ? ' active' : '')} onClick={this.showAllEvents}>Оповещения</div>
                    <div className={'events__top__calls' + (eventsType === 'onlyCalls' ? ' active' : '')} onClick={this.showCalls} />
                </div>
                { lastCall
                    ? (
                        <div
                            className={'events__list__event event event--last-call' + (lastCall.viewed ? ' event--viewed' : '')}
                            onClick={this.openEvent.bind(this, lastCall)}
                        >
                            <div className='event__info'>
                                <div className='event__title'>Последний звонок</div>
                                <div className='event__client'>{ _.get(lastCall, 'event_details.client_name', '') }</div>
                                <div
                                    className='event__date'
                                >{ moment.duration(moment(lastCall.created_at).diff(moment())).humanize() }
                                    &nbsp;назад
                                </div>
                            </div>
                        </div>
                    )
                    : null }
                <Scrollbars
                    ref='searchEventsScroll'
                    style={{ height: '100%' }}
                    thumbSize={16}
                    autoHide
                    renderTrackVertical={this.renderTrack}
                    renderThumbVertical={this.renderThumb}
                    onScrollStop={this.scrollEventsList.bind(this)}
                    onWheel={event => {
                        if (event.deltaY > 0) {
                            const { searchEventsScroll } = this.refs;
                            const scroll = searchEventsScroll.getValues();
                            if (scroll.clientHeight !== scroll.scrollHeight && scroll.clientHeight + scroll.scrollTop === scroll.scrollHeight) {
                                event.preventDefault();
                            }
                        }
                    }}
                >
                    <div className='events__list'>
                        { _.map(readEvents, event => {
                            return (
                                <div
                                    key={'readEvent' + event.id} className='events__list__event event event--read'
                                    onClick={this.openEvent.bind(this, event)}
                                >
                                    <div className='event__info'>
                                        <div className='event__title'>{ this.eventsTitles[event.type] }</div>
                                        <div className='event__client'>{ _.get(event, 'event_details.client_name', '') }</div>
                                        <div
                                            className='event__date'
                                        >{ moment.duration(moment(event.created_at).diff(moment())).humanize() }
                                            &nbsp;назад
                                        </div>
                                    </div>
                                </div>
                            );
                        }) }
                        { _.map(events, event => {
                            return (
                                <div
                                    key={'event' + event.id}
                                    className={'events__list__event event' + (event.viewed ? ' event--viewed' : '')}
                                    onClick={this.openEvent.bind(this, event)}
                                >
                                    <div className='event__info'>
                                        <div className='event__title'>{ this.eventsTitles[event.type] }</div>
                                        <div className='event__client'>{ _.get(event, 'event_details.client_name', '') }</div>
                                        <div
                                            className='event__date'
                                        >{ moment.duration(moment(event.created_at).diff(moment())).humanize() }
                                            &nbsp;назад
                                        </div>
                                    </div>
                                </div>
                            );
                        }) }
                    </div>
                </Scrollbars>
                <div className='events__footer'>Акции и доп. инфо</div>
            </div>
        );
    }
}

Events.contextTypes = {
    router: PropTypes.object
};

export default Events;
