import React from 'react';
import classes from './CallsForEmployee.module.scss';

class CallsForEmployee extends React.Component {
    render() {
        return (
            <div className={classes.main} />
        );
    }
}

export default CallsForEmployee;
