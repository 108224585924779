import { connect } from 'react-redux';
import { actions } from '../../../modules/settings';

import Schedule from './Schedule';

const mapStateToProps = state => ({
    company: state.company.company,
    schedules: state.settings.customSchedules,
    createdSchedules: state.settings.createdSchedules,
    fetching: state.settings.fetchingCustomSchedules ||
    state.settings.fetchingCreateCustomSchedules ||
    state.settings.fetchingDeleteCustomSchedules

});

export default connect(mapStateToProps, actions)(Schedule);
