import React from 'react';
import classes from './ViewOrderModal.module.scss';
import moment from 'moment';
import _ from 'lodash';
import {
    getOffsetRect,
    formatPhone,
    formatNumber,
    formatDuration
} from 'utils';

import WithClickOutside from 'extends/WithClickOutside';

class ViewOrderModal extends WithClickOutside {
    state = {};

    checkModalHandler = this.checkModalPosition.bind(this);

    componentDidMount() {
        super.componentDidMount();
        window.addEventListener('scroll', this.checkModalHandler);
        window.addEventListener('resize', this.checkModalHandler);
        this.checkModalPosition();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener('scroll', this.checkModalHandler);
        window.removeEventListener('resize', this.checkModalHandler);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.orders.items.length !== this.props.orders.items.length) {
            this.props.chooseToOrder(null);
            const currentDay = this.props.currentDay ? this.props.currentDay : moment().startOf('date');
            this.props.fetchSchedule(this.props.toOrder.resId, {
                range_start: currentDay.format('YYYY-MM-DD'),
                range_end: currentDay.format('YYYY-MM-DD')
            });
        }
    }

    cancelOrder(id) {
        this.props.fetchCancelOrder(id);
    }

    outerClick() {
        this.props.chooseToOrder(null);
    }

    checkModalPosition() {
        // debugger;
        // точки на чанке, к которым будет хорошо прикрепить модальное окно
        let chunkPoints = [
            { x: 12, y: 0, modalClass: 'topAlign', points: [] },
            { x: 25, y: 12, modalClass: 'rightAlign', points: [] },
            { x: 12, y: 25, modalClass: 'bottomAlign', points: [] },
            { x: 0, y: 12, modalClass: 'leftAlign', points: [] }
        ];
        // определяем размер окна браузера и координаты ценра вьюпорта
        const client_w = document.documentElement.clientWidth; const client_h = document.documentElement.clientHeight;

        const center_x = Math.round(client_w / 2);

        const center_y = Math.round(client_h / 2);

        const el = document.getElementById('ordermodal'); const chunk = el.parentNode;

        const chunkPos = getOffsetRect(chunk);
        // определяем ближайшую точку на чанке
        _.each(chunkPoints, point => {
            point.distance = Math.round(Math.sqrt(Math.pow(center_x - (chunkPos.boxLeft + point.x), 2) + Math.pow(center_y - (chunkPos.boxTop + point.y), 2)));
        });

        chunkPoints = _.orderBy(chunkPoints, ['distance'], ['asc']);
        this.setState({ modalClass: chunkPoints[0].modalClass });
    }

    toShiftOrder(e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.chooseToOrder({
            mode: 'editOrder',
            orderId: this.props.orderId,
            status: 'chooseToShift',
            newChunk: null
        });
    }

    toChangeOrder(e) {
        const order = _.find(this.props.orders.items, { id: this.props.toOrder.orderId });

        const orderDetails = order.orderDetails;

        const services = [];

        let averageDuration = null;

        let totalPrice = null;
        _.map(orderDetails, item => {
            averageDuration += item.duration;
            totalPrice += item.price;
            services.push({
                duration: item.duration,
                id: item.service.id,
                name: item.service.serviceTemplate.name,
                price: item.price,
                orderDetailsId: item.id
            });
        });
        this.props.chooseToOrder({
            mode: 'editOrder',
            status: 'edit',
            orderId: this.props.toOrder.orderId,
            resId: this.props.toOrder.resId,
            service: services,
            averageDuration: averageDuration,
            totalPrice: totalPrice,
            date: moment(orderDetails[0].scheduledTo),
            newChunk: this.props.toOrder.chunk
        }, e);
    }

    render() {
        moment.locale('ru');
        const order = _.find(this.props.orders.items, { id: this.props.orderId });

        const companyTimeZone = _.get(this.props.company, 'angaraInfo.timezone', 0);
        if (!order) {
            return null;
        }

        const orderIsActive = order.status === 'ACTIVE';

        const resource = _.find(this.props.company.resources, { angara_id: _.find(order.orderDetails).resource.id });
        // выясняем дату и продолжительность оказания услуги/услуг
        let orderDate = _.find(order.orderDetails).scheduledTo; let orderDuration = 0;
        _.map(order.orderDetails, service => {
            if (moment(service.scheduledTo).isBefore(orderDate)) {
                orderDate = service.scheduledTo;
            }
            orderDuration += service.duration;
        });
        const orderDurationHours = parseInt(orderDuration / 60);// сколько часов займет
        const orderDurationMinutes = parseInt(orderDuration % 60);// сколько минут займет

        // выясняем дату заказа по услугам в оном
        const orderDateDay = moment(orderDate).utcOffset(companyTimeZone).format('D MMMM'); const // дата чанка
            orderDateTime = moment(orderDate).utcOffset(companyTimeZone).format('HH:mm');// время чанка

        let cancelBlock;
        if (moment(orderDate).isBefore() || !orderIsActive) {
            cancelBlock = '';
        } else {
            cancelBlock = this.props.confirmCancelOrder
                ? (
                    <div className={classes.cancelOrder + ' ' + classes.toConfirm}>Отменить запись?&nbsp;
                        <div onClick={this.cancelOrder.bind(this, order.id)}>Да</div>&nbsp;
                        <div onClick={this.props.toConfirmCancelOrder.bind(this)}>Нет</div>
                    </div>
                )
                : (
                    <div
                        href='#' className={classes.cancelOrder}
                        onClick={this.props.toConfirmCancelOrder.bind(this)}
                    >
                        Отменить запись
                    </div>
                );
        }
        let servicesCounter = 0;

        let totalPrice = 0;
        // TODO переделать вёрстку опционного блока, вставить сразу после названия, коунтер отдельно
        return (
            <div
                id='ordermodal'
                className={classes.viewordermodal + ' ' + (this.state.modalClass ? classes[this.state.modalClass] : '')}
            >
                <div className={classes.header}>
                    <div className={classes.name} onClick={this.checkModalPosition}>{ order.clientName }</div>
                    <div className={classes.phone}>{ formatPhone(order.clientPhone) }</div>
                </div>
                <div className={classes.body}>
                    <div className={classes.resource}>Исполнитель услуги: <span>{ resource.name }</span>
                    </div>
                    { (order.descr !== null && order.descr !== '')
                        ? <div className={classes.description}>{ order.descr }</div>
                        : null }
                    <div className={classes.dateBlock}>
                        <div className={classes.dates}>
                            <span className={classes.date}>{ orderDateDay }</span>
                            <span>&nbsp;в&nbsp;</span>
                            <span className={classes.time}>{ orderDateTime }</span>
                            &nbsp;
                            { this.props.company.integrated_with_1c === 1 || !orderIsActive
                                ? null
                                : (
                                    <div className={classes.shift} onClick={this.toShiftOrder.bind(this)}>
                                        Перенести на другое время
                                    </div>
                                ) }
                        </div>
                        <div className={classes.duration}>
                            длительность услуг{ order.orderDetails.length === 1 ? 'и' : '' }&nbsp;
                            <span className={classes.value}>
                                { orderDurationHours ? orderDurationHours + 'ч.' : '' }&nbsp;
                                { orderDurationMinutes ? orderDurationMinutes + 'мин.' : '' }
                            </span>
                        </div>
                    </div>
                    <div className={classes.serviceBlock}>
                        { _.map(order.orderDetails, service => {
                            servicesCounter++;
                            totalPrice += service.price;
                            let options = _.get(service, 'service.serviceOptions');
                            if (options != null && options.length > 0) {
                                options = '(' + _.map(options, o => (o.valueByReference.value)).join(', ') + ')';
                            }
                            return (
                                <div className={classes.row} key={'service' + service.id}>
                                    <div
                                        className={classes.name}
                                    >{ servicesCounter + '. ' + service.service.serviceTemplate.name }
                                    </div>
                                    <div className={classes.duration}>
                                        { formatDuration(service.duration) }
                                    </div>
                                    <div className={classes.price}>
                                        { formatNumber(service.price) } руб
                                    </div>{ options ? (<div className={classes.options}>{ options }</div>) : null }
                                </div>
                            );
                        }) }
                        <div className={classes.totalPrice}>
                            <div className={classes.label}>Сумма заказа:</div>
                            <div className={classes.value}>
                                { formatNumber(totalPrice) } руб
                            </div>
                        </div>
                    </div>
                    { cancelBlock }
                </div>
                <hr />
                <div className={classes.footer}>
                    <div className={classes.cancel} onClick={this.props.chooseToOrder.bind(this, null)}>
                        Отмена
                    </div>
                    { orderIsActive
                        ? (
                            <div className={classes.change} onClick={this.toChangeOrder.bind(this)}>
                                Изменить
                            </div>
                        )
                        : null }
                </div>
                <div className={classes.triangle + ' ' + classes.left} />
                <div className={classes.triangle + ' ' + classes.top} />
                <div className={classes.triangle + ' ' + classes.right} />
                <div className={classes.triangle + ' ' + classes.bottom} />
            </div>
        );
    }
}

export default ViewOrderModal;
