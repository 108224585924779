import _ from 'lodash';
import { api, httpBuildQuery } from '../../../../utils';

// ------------------------------------
// Constants
// ------------------------------------
export const CHANGE_MODE = 'CHANGE_MODE';
export const CHANGE_TEMP_WORK_TIME = 'CHANGE_TEMP_WORK_TIME';
export const TO_CREATE_DIVISION = 'toCreateDivision';

export const TO_CREATE_RESOURCE = 'toCreateResource';
export const TO_EDIT_RESOURCE = 'toEditResource';

export const CHANGE_NEW_RESOURCE = 'changeNewResource';
export const CHANGE_EDITED_RESOURCE = 'changeEditedResource';

export const SEARCHING_CITY = 'searchingCity';
export const RECEIVE_CITIES = 'receiveCities';
export const CLEAR_CITIES_LIST = 'clearCitiesList';

export const REQUEST_CUSTOM_SCHEDULES = 'requestCustomSchedules';
export const RECEIVE_CUSTOM_SCHEDULES = 'receiveCustomSchedules';

export const UPDATING_CUSTOM_SCHEDULES = 'updatingCustomSchedules';
export const CUSTOM_SCHEDULES_UPDATED = 'customSchedulesUpdated';

export const DELETING_CUSTOM_SCHEDULES = 'deletingCustomSchedules';
export const CUSTOM_SCHEDULES_IS_DELETED = 'customSchedulesIsDeleted';

const ADD_EDIT_RESOURCE_AVATAR = 'addEditResourceAvatar';
const REMOVE_EDIT_RESOURCE_AVATAR = 'removeEditResourceAvatar';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchCustomSchedules = (resourceId, dateBegin, dateEnd) => function(dispatch) {
    dispatch(requestCustomSchedules());
    return api(`/v1/partner/resources/${resourceId}/custom-schedules?${httpBuildQuery({
        range_start: dateBegin,
        range_end: dateEnd
    })}`, {
        method: 'GET'
    }, dispatch)
        .then(res => dispatch(receiveCustomSchedules(res)));
};

function requestCustomSchedules() {
    return {
        type: REQUEST_CUSTOM_SCHEDULES
    };
}

function receiveCustomSchedules(res) {
    return {
        type: RECEIVE_CUSTOM_SCHEDULES,
        payload: res
    };
}

export const deleteCustomSchedules = (id, ids) => function(dispatch) {
    dispatch({ type: DELETING_CUSTOM_SCHEDULES });
    return api('/v1/partner/resources/custom-schedules', {
        method: 'DELETE',
        body: JSON.stringify({ ids })
    }, dispatch).then(() => dispatch({ type: CUSTOM_SCHEDULES_IS_DELETED }));
};

export const updateCustomSchedules = (resourceId, schedules) => function(dispatch) {
    dispatch(updatingCustomSchedules());
    return api(`/v1/partner/resources/${resourceId}/custom-schedules`, {
        method: 'PUT',
        body: JSON.stringify({ schedules })
    }, dispatch)
        .then(res => dispatch(customSchedulesUpdated(res, schedules)));
};

function updatingCustomSchedules() {
    return {
        type: UPDATING_CUSTOM_SCHEDULES
    };
}

function customSchedulesUpdated(res, schedules) {
    return {
        type: CUSTOM_SCHEDULES_UPDATED,
        payload: res,
        schedules
    };
}

export const toEditResource = (resource, e) => {
    if (e) {
        e.preventDefault();
    }
    return {
        type: TO_EDIT_RESOURCE,
        resource
    };
};

export const changeNewResource = (path, value, e) => {
    if (e) {
        e.preventDefault();
    }
    return {
        type: CHANGE_NEW_RESOURCE,
        path,
        value
    };
};

export const changeEditedResource = (path, value, e) => {
    if (e) {
        e.preventDefault();
    }
    return {
        type: CHANGE_EDITED_RESOURCE,
        path,
        value
    };
};

export const toCreateResource = (divisionId, e) => {
    if (e) {
        e.preventDefault();
    }
    return {
        type: TO_CREATE_RESOURCE,
        payload: divisionId
    };
};

export const toCreateDivision = (e) => {
    if (e) {
        e.preventDefault();
    }
    return {
        type: TO_CREATE_DIVISION
    };
};

export const changeTempWorkTime = workTime => ({
    type: CHANGE_TEMP_WORK_TIME,
    workTime
});

export const changeMode = (mode, e) => {
    e.preventDefault();
    return {
        type: CHANGE_MODE,
        mode
    };
};

export const searchCity = (queryString) => function(dispatch) {
    dispatch(searchingCity());
    return api(`/v1/location/cities?search=${queryString}&offset=0&limit=5`, {
        method: 'GET'
    }, dispatch)
        .then(res => dispatch(receiveCities(res)));
};

function searchingCity() {
    return {
        type: SEARCHING_CITY
    };
}

function receiveCities(res) {
    return {
        type: RECEIVE_CITIES,
        payload: res
    };
}

export const clearCitiesList = () => ({
    type: CLEAR_CITIES_LIST
});

export const removeEditResourceAvatar = () => ({
    type: REMOVE_EDIT_RESOURCE_AVATAR
});

export const addEditResourceAvatar = resource => ({
    type: ADD_EDIT_RESOURCE_AVATAR,
    payload: resource
});

export const actions = {
    changeMode,
    changeTempWorkTime,
    toCreateDivision,
    toCreateResource,
    toEditResource,
    changeNewResource,
    changeEditedResource,
    searchCity,
    clearCitiesList,
    fetchCustomSchedules,
    deleteCustomSchedules,
    updateCustomSchedules,
    removeEditResourceAvatar,
    addEditResourceAvatar
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [CHANGE_MODE]: (state, action) => ({ ...state, currentMode: action.mode }),
    [CHANGE_TEMP_WORK_TIME]: (state, action) => {
        const workTime = Object.assign({}, action.workTime);
        return ({ ...state, workTime });
    },
    [TO_CREATE_DIVISION]: state => ({ ...state, addDivision: !state.addDivision }),
    [TO_CREATE_RESOURCE]: (state, action) => {
        const addResource = { is_online: false };
        if (action.payload) {
            addResource.divisions = [action.payload];
        }
        return ({ ...state, addResource: (state.addResource === null ? addResource : null) });
    },
    [TO_EDIT_RESOURCE]: (state, action) => ({ ...state, editResource: action.resource }),
    [CHANGE_NEW_RESOURCE]: (state, action) => {
        const addResource = Object.assign({}, state.addResource);
        _.set(addResource, action.path, action.value);
        return ({ ...state, addResource });
    },
    [CHANGE_EDITED_RESOURCE]: (state, action) => {
        const editResource = Object.assign({}, state.editResource);
        _.set(editResource, action.path, action.value);
        return ({ ...state, editResource });
    },
    [SEARCHING_CITY]: state => ({ ...state, fetchingCities: true }),
    [RECEIVE_CITIES]: (state, action) => ({ ...state, fetchingCities: false, cities: action.payload.cities }),
    [CLEAR_CITIES_LIST]: state => ({ ...state, cities: null }),
    [REQUEST_CUSTOM_SCHEDULES]: state => ({ ...state, fetchingCustomSchedules: true }),
    [RECEIVE_CUSTOM_SCHEDULES]: (state, action) => ({
        ...state,
        customSchedules: action.payload.schedules.resourceCustomSchedules,
        fetchingCustomSchedules: false
    }),
    [CUSTOM_SCHEDULES_UPDATED]: (state, action) => ({
        ...state,
        createdSchedules: _.map(action.payload.schedules, sch => ({ id: sch })),
        fetchingCreateCustomSchedules: false
    }),
    [DELETING_CUSTOM_SCHEDULES]: state => ({ ...state, fetchingDeleteCustomSchedules: true }),
    [CUSTOM_SCHEDULES_IS_DELETED]: state => ({ ...state, fetchingDeleteCustomSchedules: false }),
    [ADD_EDIT_RESOURCE_AVATAR]: (state, action) => {
        const editResource = Object.assign({}, state.editResource);

        const resource = action.payload;

        editResource.imageUrl = resource.imageUrl;
        editResource.thumbnails = resource.thumbnails;

        return ({ ...state, editResource });
    },
    [REMOVE_EDIT_RESOURCE_AVATAR]: (state) => {
        const editResource = Object.assign({}, state.editResource);

        editResource.imageUrl = null;
        editResource.thumbnails = [];

        return ({ ...state, editResource });
    }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    currentMode: 'common',
    currentEdited: null,
    workTime: null,
    addDivision: false,
    addResource: null,
    editResource: null,
    cities: null,
    customSchedules: [],
    createdSchedules: [],
    fetchingCities: false,
    fetchingCustomSchedules: false,
    fetchingCreateCustomSchedules: false,
    fetchingDeleteCustomSchedules: false
};
export default function settingsReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
