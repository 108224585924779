/**
 * Created by user on 19.07.16.
 */
import React from 'react';
// Libs
import _ from 'lodash';
import classes from './PrivateInfo.module.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
// Comps
import InputElement from 'react-input-mask';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';

class Cars extends React.Component {
    state = {
        newCar: {
            marks: { id: null, name: 'Марка', value: '', type: 'select', orange: true, action: 'fetchCarMarks' },
            models: { id: null, name: 'Модель', updateLabel: 'model_id', title: '', type: 'select', orange: true, action: 'fetchCarModels' },
            year: { name: 'Год', value: '', type: 'edit', orange: true },
            engines: { id: null, name: 'Тип ДВС', updateLabel: 'engine', value: '', type: 'select', action: 'fetchCarEngines' },
            transmissions: { id: null, name: 'Тип КПП', updateLabel: 'transmission', value: '', type: 'select', action: 'fetchCarTransmissions' },
            bodies: { id: null, name: 'Тип Кузова', updateLabel: 'body', value: '', type: 'select', action: 'fetchCarBodies' },
            vin: { name: 'Вин', value: '', type: 'edit' },
            power: { name: 'Мощность', value: '0', type: 'edit', rule: 'number' },
            mileage: { name: 'Пробег', value: '0', type: 'edit', rule: 'number' },
            insurance_deadline: { name: 'Дата окончания страховки', value: null, type: 'date' },
            license_plate: { name: 'Номер', value: '', type: 'edit' },
            region_id: { name: 'Регион', value: '', type: 'edit' }
        },
        newChangedCar: null,
        id: 'newCar',
        editedField: null,
        errorLabel: null
    };

    renderTrack({ style, ...props }) {
        const trackStyle = {};
        return (
            <div
                className={classes.scrollTrack}
                style={{ ...style, ...trackStyle }}
                {...props}
            />
        );
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {};
        return (
            <div
                className={classes.scrollThumb}
                style={{ ...style, ...thumbStyle }}
                {...props}
            />
        );
    }

    getCars(item) {
        const id = this.state.id;
        return (
            <div>
                { _.map(item, (field, key) => {
                    let input, value;
                    switch (field.type) {
                    case 'edit':
                        if (key === 'license_plate') {
                            value = this.formatLicensePlate(field.value);
                        } else if (key === 'power') {
                            value = field.value + ' л.с.';
                        } else {
                            value = field.value;
                        }
                        input = this.state.editedField === (key)
                            ? this.carFieldInput(key)
                            : (
                                <div
                                    className={classes.changeValue} key={'changeValue' + id}
                                    onClick={this.editInputField.bind(this, key)}
                                >
                                    <div
                                        className={classes.value + ' ' + (field.orange ? classes.orange : '')}
                                        key={'value' + id}
                                    >
                                        { value }
                                    </div>
                                    <div className={classes.edit} key={'type' + id} />
                                </div>
                            );
                        break;
                    case 'select':
                        input = (
                            <div
                                className={classes.changeValue} key={'changeValue' + id}
                                onClick={this.changeCarReference.bind(this, field, key)}
                            >
                                <div
                                    className={classes.value + ' ' + (field.orange ? classes.orange : '')}
                                    key={'value' + id}
                                >
                                    { field.value ? field.value : 'Не выбрано' }
                                </div>
                                <div className={classes.select} key={'type' + id} />
                                { this.state.editedField === (key)
                                    ? this.carFieldSelect(field, key)
                                    : null }
                            </div>
                        );
                        break;
                    case 'date':
                        input = (
                            <div className={classes.changeValue}>
                                <DatePicker
                                    className='full-orange no-header-arrows'
                                    align={['center', 'bottom']}
                                    selected={field.value ? new Date(field.value) : new Date()}
                                    placeholder='Не выбрано'
                                    onChange={(date) => {
                                        const newCar = this.state.newCar;
                                        newCar.insurance_deadline.value = date;
                                        this.setState({ newCar: newCar });
                                    }}
                                />
                            </div>
                        );
                        break;
                    default:
                    }
                    return (
                        <div className={classes.carData} key={'data' + key + id}>
                            <div className={classes.title} key={'title' + id}>{ field.name }:</div>
                            { input }
                        </div>
                    );
                }) }
            </div>
        );
    }

    changeCarReference(field, label, e) {
        if (e) {
            e.stopPropagation();
        }
        const markId = this.state.newCar.marks.id;

        const models = _.get(this.props.carReferences, 'models');

        if (markId && label === 'models' && models && !_.find(models, ['mark_id', markId])) {
            this.props.fetchCarModels(markId);
        }

        if (!this.props.carReferences || !this.props.carReferences[label]) {
            this.props[field.action](markId);
        }

        this.setState({ editedField: this.state.editedField === label ? null : label, errorLabel: null });
    }

    carFieldSelect(field, key) {
        if (!this.props.carReferences || !this.props.carReferences[key]) {
            return false;
        }
        return (
            <div>
                <div className={classes.triangle} />
                <div
                    className={classes.outerMenu} onClick={e => {
                        e.stopPropagation();
                    }}
                >
                    <Scrollbars
                        id='fieldScrollbar'
                        ref='fieldScrollbar'
                        thumbSize={16}
                        autoHeight
                        autoHide={false}
                        renderTrackVertical={this.renderTrack}
                        renderThumbVertical={this.renderThumb}
                        onWheel={event => {
                            const { fieldScrollbar } = this.refs;
                            const scroll = fieldScrollbar.getValues();
                            if (event.deltaY > 0 && scroll.clientHeight + scroll.scrollTop === scroll.scrollHeight) {
                                event.preventDefault();
                            }
                            if (event.deltaY < 0 && scroll.scrollTop === 0) {
                                event.preventDefault();
                            }
                        }}
                    >
                        { _.map(this.props.carReferences[key], item => {
                            if (key === 'models' && _.get(this.state.newCar, 'marks.id') === item.mark_id) {
                                return (
                                    <div
                                        key={'carReference' + item.id}
                                        className={classes.item}
                                        onClick={this.changeSelectField.bind(this, field.value, key, item)}
                                    >
                                        { item.title }
                                    </div>
                                );
                            } else if (key !== 'models') {
                                return (
                                    <div
                                        key={'carReference' + item.id}
                                        className={classes.item}
                                        onClick={this.changeSelectField.bind(this, field.value, key, item)}
                                    >
                                        { item.title }
                                    </div>
                                );
                            }
                        }) }
                    </Scrollbars>
                </div>
            </div>
        );
    }

    changeSelectField(value, key, field, e) {
        if (value === field.title) {
            this.changeEditedField(null, e);
            return true;
        }
        const car = this.state.newCar;
        if (key === 'marks') {
            car.models.id = null;
            car.models.value = '';
            car.marks.id = field.id;
            car.marks.value = field.title;
        } else {
            car[key].id = field.id;
            car[key].value = field.title;
        }
        this.setState({ newCar: car });
        this.changeEditedField(null, e);
    }

    editInputField(label, e) {
        const car = _.cloneDeep(this.state.newCar);
        this.setState({ newChangedCar: car });
        this.changeEditedField(label, e);
    }

    carFieldInput(key) {
        let mask;

        let maskChar;

        let text;

        const formatChars = { 9: '[0-9]', V: '[A-HJ-NPR-Za-hj-npr-z0-9]', R: '[а-яёЁА-Я0-9]', N: '[авекм-ор-ухАВЕКМ-ОР-УХ]' };

        // const input = this.state.newCar[key];

        const car = this.state.newChangedCar;
        switch (key) {
        case 'year':
            mask = '9999';
            maskChar = 'Г';
            text = 'Введите год автомобиля';
            break;
        case 'vin':
            mask = 'VVV  VVVVVV  VVVVVVVV';
            maskChar = '_';
            text = 'Вин должен состоять из 17 символов (цифры и буквы латинского алфавита, кроме I Q O)';
            break;
        case 'license_plate':
            mask = 'N 999 NN';
            maskChar = '_';
            text = 'Неверный формат номера';
            break;
        default:
        }

        if (text) {
            return (
                <div className={classes.changeValue}>
                    <InputElement
                        type='text'
                        name={key}
                        autoFocus
                        value={car[key].value}
                        onBlur={e => this.changeInputField(key, car[key].value, this.state.editedField, e)}
                        onChange={this.changeNewCar.bind(this)}
                        onClick={e => e.stopPropagation()}
                        mask={mask}
                        maskChar={maskChar}
                        formatChars={formatChars}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                this.changeInputField(key, car[key].value, null, e);
                            } else {
                                return null;
                            }
                        }}
                        onKeyUp={e => {
                            if (e.key === 'Escape') {
                                e.preventDefault();
                                this.setState({ editedField: null });
                            } else {
                                return null;
                            }
                        }}
                    />
                    <div
                        className={classes.editOk}
                        onClick={this.changeInputField.bind(this, key, car[key].value, null)}
                    />
                    { this.state.errorLabel
                        ? (
                            <div className={classes.errorMenu} onClick={e => e.stopPropagation()}>
                                { text }
                            </div>
                        )
                        : null }
                </div>
            );
        } else {
            return (
                <div className={classes.changeValue}>
                    <input
                        type='text'
                        name={key}
                        autoFocus
                        value={car[key].value}
                        onBlur={e => this.changeInputField(key, car[key].value, this.state.editedField, e)}
                        onChange={this.changeNewCar.bind(this)}
                        style={key === 'power' ? { width: 9 + 'em' } : { width: 11 + 'em' }}
                        onClick={e => e.stopPropagation()}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                this.changeInputField(key, car[key].value, null, e);
                            }
                        }}
                        onKeyUp={e => {
                            if (e.key === 'Escape') {
                                e.preventDefault();
                                this.setState({ editedField: null });
                            }
                        }}
                    />
                    {
                        key === 'power'
                            ? <div className={classes.power}>&nbsp;&nbsp;л.с.</div>
                            : null
                    }
                    <div
                        className={classes.editOk}
                        onClick={this.changeInputField.bind(this, key, car[key].value, null)}
                    />
                    { this.state.errorLabel === 'power'
                        ? (
                            <div className={classes.errorMenu} onClick={e => e.stopPropagation()}>
                                В этом поле можно ввести только цифры
                            </div>
                        )
                        : null }
                </div>
            );
        }
    }

    changeNewCar(e) {
        const car = this.state.newChangedCar; let value;
        switch (car[e.target.name].rule) {
        case 'number':
            if (/^\d+$/.test(e.target.value)) {
                value = parseInt(e.target.value);
            } else {
                value = 0;
            }
            break;
        default:
            value = e.target.value;
            break;
        }
        car[e.target.name].value = value;
        this.setState({ newChangedCar: car, errorLabel: null });
    }

    changeInputField(label, value, editedField, e) {
        if (e) {
            e.stopPropagation();
        }
        let test;
        switch (label) {
        case 'year':
        case 'vin':
        case 'license_plate':
            value = value.replace(/\s+/g, '');
            test = /[_Г]/.test(value);
            break;
        case 'power':
            test = !/^\d+$/.test(value);
            break;
        default:
        }
        if (test && value.length !== 0) {
            this.errorLabelChange(label);
        } else {
            const car = this.state.newCar;
            car[label].value = value;
            this.setState({ newCar: car, editedField: editedField });
        }
    }

    errorLabelChange(label) {
        this.setState({ errorLabel: label });
    }

    changeEditedField(label, e) {
        if (e) {
            e.stopPropagation();
        }

        if (label !== null || !_.includes(['vin', 'year', 'license_plate'], this.state.editedField) || !/[_Г]/.test(this.state.newChangedCar[this.state.editedField].value)) {
            this.setState({ editedField: this.state.editedField === label ? null : label, errorLabel: null });
        }
    }

    createCar() {
        const car = this.state.newCar;

        const data = { client_id: this.props.client.id };
        _.map(car, (field, key) => {
            if (field.value && key !== 'marks') {
                switch (field.type) {
                case 'edit':
                    data[key] = field.value;
                    break;
                case 'date':
                    data[key] = moment(field.value).format();
                    break;
                default:
                    data[field.updateLabel] = _.includes(['engine', 'transmission', 'body'], field.updateLabel) ? { id: field.id } : field.id;
                    break;
                }
            }
        });
        if (data.year && data.model_id) {
            this.props.createClientCar(data);
        }
    }

    formatLicensePlate(value) {
        if (value) {
            value = value.toUpperCase();
            return value.substr(0, 1) + ' ' + value.substr(1, 3) + ' ' + value.substr(4, 2);
        } else {
            return null;
        }
    }

    render() {
        let readyToSave = false;

        const car = this.state.newCar;

        if (car.models.id && car.year.value) {
            readyToSave = true;
        }
        return (
            <div className={classes.addCar} onClick={this.props.shadeMenuChange.bind(this, null)}>
                <div className={classes.newCarWrapper} onClick={this.changeEditedField.bind(this, null)}>
                    <div className={classes.headerCar}>Добавление автомобиля</div>
                    <div className={classes.newCar}>
                        { this.getCars(this.state.newCar) }
                        <div className={classes.buttons}>
                            <div className={classes.cancel} onClick={this.props.shadeMenuChange.bind(this, null)}>
                                Отмена
                            </div>
                            <Button
                                value='Сохранить'
                                className={readyToSave ? 'save' : 'blockedSave'}
                                onClick={this.createCar.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Cars;
