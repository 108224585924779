const SHOW_EDITOR = 'showEditor';
const EDIT_SERVICE_CHANGE = 'editService';
const SHOW_SERVICE = 'showService';
const CHANGE_SERVICE = 'changeService';
const CHANGE_SERVICE_INPUT = 'changeServiceInput';
const RECEIVE_SERVICE = 'receiveService';
const REMOVE_SERVICE = 'removeService';
const SHOW_ERRORS = 'showErrors';
const RESPONSE_ERROR = 'responseError';
const CHECK_SERVICE_NAME_TOOLTIP = 'checkServiceNameTooltip';

// ------------------------------------
// Actions
// ------------------------------------

export const showEditor = () => ({
    type: SHOW_EDITOR
});

export const editService = (name, e) => {
    e.preventDefault();
    e.stopPropagation();
    return {
        type: EDIT_SERVICE_CHANGE,
        payload: name
    };
};

export const showService = (service, catalogueId, catalogueName, e) => {
    e.preventDefault();
    // e.stopPropagation();
    return {
        type: SHOW_SERVICE,
        payload: {
            service,
            catalogueId,
            catalogueName
        }
    };
};

export const changeService = (field, value, e) => {
    if (e) {
        e.preventDefault();
    }
    // e.stopPropagation();
    return {
        type: CHANGE_SERVICE,
        payload: {
            field,
            value
        }
    };
};

export const receiveService = (service, company) => ({
    type: RECEIVE_SERVICE,
    payload: {
        service,
        company
    }
});

export const removeService = (id, company) => ({
    type: REMOVE_SERVICE,
    payload: {
        id,
        company
    }
});

/* export const showError = (error, e) => {
    if (e.preventDefault) {
        e.preventDefault();
        e.stopPropagation();
    }
    return {
        type: SHOW_ERRORS
    };
}; */

export const responseError = (error, field) => ({
    type: RESPONSE_ERROR,
    payload: {
        error,
        field
    }
});

export const checkServiceNameTooltip = (nameWidth, maxWidth) => ({
    type: CHECK_SERVICE_NAME_TOOLTIP,
    payload: {
        nameWidth,
        maxWidth
    }
});

export const actions = {
    editService,
    showService,
    changeService,
    checkServiceNameTooltip,
    responseError
};
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [SHOW_EDITOR]: state => ({ ...state, editorVisible: !state.editorVisible, editServiceChange: false }),
    [SHOW_SERVICE]: (state, action) => {
        action.payload.service.catalogueId = action.payload.catalogueId;
        action.payload.service.catalogueName = action.payload.catalogueName;
        return ({
            ...state,
            editorVisible: !state.editorVisible,
            service: action.payload.service,
            responseErrorMessage: false
        });
    },
    [EDIT_SERVICE_CHANGE]: (state, action) => {
        if (action.payload === state.editServiceChange) {
            action.payload = false;
        }
        return ({
            ...state,
            editServiceChange: action.payload,
            error: false,
            responseErrorMessage: {}
        });
    },
    [CHANGE_SERVICE]: (state, action) => {
        const service = Object.assign({}, state.service);
        service[action.payload.field] = action.payload.value;

        return ({ ...state, service, editServiceChange: false });
    },
    [CHANGE_SERVICE_INPUT]: (state, action) => {
        const service = Object.assign({}, state.service);

        const value = action.payload.value;

        let error = false;

        if (value.charAt(0) === '0' && value.length > 1 &&
            (action.payload.field === 'minPrice' || action.payload.field === 'repeatsDuringPeriod')) {
            service[action.payload.field] = action.payload.value.substring(1);
        } else if (action.payload.field === 'repeatsDuringPeriod' &&
            ((!/^\d+$/.test(value) && value.length !== 0) || value.charAt(0) === '0')) {
            error = action.payload.field;
        } else if (action.payload.field === 'minPrice' && !/^\d+$/.test(value) && value.length !== 0) {
            error = action.payload.field;
        } else {
            service[action.payload.field] = action.payload.value;
        }

        return ({ ...state, service, error });
    },
    /*    [REQUEST_SERVICE]: (state) => {
        return ({
            ...state,
            fetching: true
        });
    }, */
    /* [RECEIVE_SERVICE]: (state, action) => {
        let serviceKey = _.findKey(action.payload.company.services.services, {'id': action.payload.service.id});

        if (serviceKey) {
            action.payload.company.services.services[serviceKey] = action.payload.service;
        } else {
            action.payload.company.services.services.unshift(action.payload.service);
        }

        return ({
            ...state,
            company: action.payload.company,
            fetching: false

        });
    }, */
    [SHOW_ERRORS]: state => ({ ...state, showErrors: !state.showErrors }),
    [RESPONSE_ERROR]: (state, action) => {
        const responseErrorMessage = Object.assign({}, state.responseErrorMessage);
        responseErrorMessage[action.payload.field] = action.payload.error;
        return ({ ...state, responseErrorMessage });
    },
    [CHECK_SERVICE_NAME_TOOLTIP]: (state, action) => {
        let tooltipShow = false;
        if (action.payload.nameWidth >= action.payload.maxWidth) {
            tooltipShow = true;
        }
        return ({ ...state, tooltipShow });
    }
    // [GET_PADDING_TOP]: (state, action) => {
    //    return ({ ...state, paddingTop: action.payload });/*прокрутка модального окна, версия №2*/
    // }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    editServiceChange: false,
    service: {},
    responseErrorMessage: {},
    fetching: false
};

export default function servicesReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
