import { connect } from 'react-redux';
import { actions } from '../../reducer';
import { fetchClient } from 'modules/clients';

import CallsDiary from './CallsDiary';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => ({
    partner: state.account.partner,
    currentDay: state.callcenter.currentDay,
    calls: state.calls.calls,
    employees: state.company.employees
});

export default connect(mapStateToProps, Object.assign(actions, { fetchClient }))(withRouter(CallsDiary));
