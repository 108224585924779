import React from 'react';
import PropTypes from 'prop-types';
import classes from './Widgets.module.scss';
import Button from 'components/Button';
import Select from 'components/Select';
import _ from 'lodash';
import Preloader from 'components/Preloader';

class Widgets extends React.Component {
    state = {
        widget: null,
        catalogue: null,
        service: null,
        resource: null,
        cataloguesIds: []
    };

    catalogueTree = [];

    UNSAFE_componentWillMount() {
        if (this.props.partner && (!this.props.company.resources || !this.props.company.catalogues || !this.props.company.services)) {
            this.props.fetchCompany(this.props.partner.company_id, ['resources', 'services', 'catalogues']);
        }
        this.props.fetchWidgets();
        this.props.clearAjaxError();
    }

    componentDidMount() {
        this.props.showHeader('hide');
    }

    closeModal() {
        this.setState({ widget: null, catalogue: null, service: null, resource: null });
        this.props.clearAjaxError();
    }

    createWidget() {
        this.setState({ widget: { settings: {} }, cataloguesIds: [] });
    }

    updateWidget(widget) {
        widget = Object.assign({}, widget);
        const company = this.props.company;

        let catalogueName = null;

        let serviceName = null;

        let resourceName = null;

        let cataloguesIds = [];

        if (widget.settings) {
            widget.settings = JSON.parse(widget.settings);
            if (widget.settings.catalogue_id) {
                const catalogue = _.find(company.catalogues.catalogues, ['id', widget.settings.catalogue_id]);
                if (catalogue) {
                    catalogueName = catalogue.name;
                    cataloguesIds = this.collectCatalogues(catalogue);
                    cataloguesIds.push(catalogue.id);
                }
            }
            if (widget.settings.service_id) {
                serviceName = _.get(_.find(company.services.services, ['id', widget.settings.service_id]), 'serviceTemplate.name', null);
            }
            if (widget.settings.resource_id) {
                resourceName = _.get(_.find(company.resources, ['angara_id', widget.settings.resource_id]), 'name', null);
            }
        } else {
            widget.settings = {};
        }

        this.setState({ widget: widget, catalogue: catalogueName, service: serviceName, resource: resourceName, cataloguesIds: cataloguesIds });
    }

    changeCatalogue(catalogue) {
        const widget = this.state.widget;

        const cataloguesIds = this.collectCatalogues(catalogue);

        let service;
        cataloguesIds.push(catalogue.value);
        widget.settings.catalogue_id = catalogue.value;

        if (widget.settings.service_id) {
            service = _.find(this.props.company.services.services, ['id', widget.settings.service_id]);
            if (service && !_.includes(cataloguesIds, service.serviceTemplate.catalogue.id)) {
                delete widget.settings.service_id;
                this.setState({ service: null });
            }
        }

        if (widget.settings.resource_id) {
            const companyResource = _.find(this.props.company.resources, ['angara_id', widget.settings.resource_id]);

            const removeResource = !_.some(companyResource.services, item => {
                service = _.find(this.props.company.services.services, ['id', item.id]);
                return _.includes(cataloguesIds, _.get(service, 'serviceTemplate.catalogue.id'));
            });

            if (removeResource) {
                delete widget.settings.resource_id;
                this.setState({ resource: null });
            }
        }

        this.setState({ catalogue: catalogue.label, widget: widget, cataloguesIds: cataloguesIds });
    }

    collectCatalogues(catalogue) {
        let result = [];
        _.each(catalogue.childrenCatalogues, item => {
            const childrenCatalogue = _.find(this.props.company.catalogues.catalogues, ['id', item.id]);
            if (childrenCatalogue) {
                result.push(item.id);
                result = result.concat(this.collectCatalogues(childrenCatalogue));
            }
        });
        return result;
    }

    changeService(service) {
        const widget = this.state.widget;
        widget.settings.service_id = service.value;
        // if (widget.settings.catalogue_id) {
        //    delete widget.settings.catalogue_id;
        //    this.setState({ catalogue: null });
        // }

        if (widget.settings.resource_id) {
            const companyService = _.find(this.props.company.services.services, ['id', service.value]);
            if (companyService && !_.find(companyService.resources, ['id', widget.settings.resource_id])) {
                delete widget.settings.resource_id;
                this.setState({ resource: null });
            }
        }

        this.setState({ service: service.label, widget: widget });
    }

    changeResource(resource) {
        const widget = this.state.widget;
        widget.settings.resource_id = resource.value;

        if (widget.settings.service_id) {
            const companyResource = _.find(this.props.company.resources, ['angara_id', resource.value]);
            if (companyResource && !_.find(companyResource.services, ['id', widget.settings.service_id])) {
                delete widget.settings.service_id;
                this.setState({ service: null });
            }
        }

        if (widget.settings.catalogue_id) {
            const companyResource = _.find(this.props.company.resources, ['angara_id', resource.value]);

            let service;

            const removeCatalogue = !_.some(companyResource.services, item => {
                service = _.find(this.props.company.services.services, ['id', item.id]);
                return _.includes(this.state.cataloguesIds, _.get(service, 'serviceTemplate.catalogue.id'));
            });

            if (removeCatalogue) {
                delete widget.settings.catalogue_id;
                this.setState({ catalogue: null });
            }
        }

        this.setState({ resource: resource.label, widget: widget });
    }

    saveWidget() {
        const widget = this.state.widget;

        let data;

        if (widget.id) {
            data = _.isEmpty(widget.settings) ? { settings: null } : { settings: JSON.stringify(widget.settings) };
            this.props.updateWidget(data, widget.id);
        } else {
            data = _.isEmpty(widget.settings) ? {} : { settings: JSON.stringify(widget.settings) };
            this.props.createWidget(data);
        }
        this.setState({ widget: null, catalogue: null, service: null, resource: null });
    }

    deleteWidget(id) {
        this.props.deleteWidget(id);
        this.setState({ widget: null, catalogue: null, service: null, resource: null });
    }

    resetWidgetSettings() {
        const widget = this.state.widget;
        widget.settings = {};
        this.setState({ widget: widget, catalogue: null, service: null, resource: null });
    }

    selectText(id, e) {
        e.stopPropagation();
        if (document.selection) {
            const range = document.body.createTextRange();
            range.moveToElementText(document.getElementById(id));
            range.select();
            document.execCommand('copy');
        } else if (window.getSelection()) {
            const range = document.createRange();
            range.selectNode(document.getElementById(id));
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
            document.execCommand('copy');
        }
    }

    buildCatalogueTree(parent, level = 1) {
        const companyCatalogues = this.props.company.catalogues.catalogues;
        _.each(_.filter(companyCatalogues, c => {
            return _.get(c, 'parentCatalogue.id', null) === parent;
        }), c => {
            this.catalogueTree.push(_.merge(c, { level: level }));
            this.buildCatalogueTree(c.id, level + 1);
        });
    }

    render() {
        const widgets = _.get(this.props.widgets, 'items', null);

        const company = this.props.company;

        const catalogues = []; const services = []; const resources = []; let serviceCatalogues = [];

        let companyCatalogues; let companyServices; let companyResources;
        if (company.id) {
            companyCatalogues = company.catalogues.catalogues;
            companyResources = company.resources;
            companyServices = company.services.services;
            serviceCatalogues = _.map(companyServices, s => {
                return _.get(s, 'serviceTemplate.catalogue.id');
            });

            this.catalogueTree = [];
            this.buildCatalogueTree(null);
            _.each(companyCatalogues, item => {
                catalogues.push({ value: item.id, label: item.name, type: 'item' });
            });
            _.each(companyResources, item => {
                resources.push({ value: item.angara_id, label: item.name, type: 'item' });
            });
            _.each(companyServices, item => {
                if (_.includes(this.state.cataloguesIds, item.serviceTemplate.catalogue.id)) {
                    services.push({ value: item.id, label: item.serviceTemplate.name, type: 'item' });
                }
            });
        }
        let header;
        if (widgets) {
            header = (
                <div className={classes.widget + ' ' + classes.header}>
                    <div className={classes.is_disabled} />
                    <div className={classes.number}>#</div>
                    <div className={classes.settingsItem}>Каталог</div>
                    <div className={classes.settingsItem}>Услуга</div>
                    <div className={classes.settingsItem}>{ _.get(this.props.company, 'angaraInfo.resourceTitle', 'Мастер') }</div>
                    <div className={classes.url}>Ссылка</div>
                </div>
            );
        }
        return (
            <div className={classes.wrapper}>
                <div className={classes.body}>
                    { header }
                    { _.map(widgets, widget => {
                        const settings = JSON.parse(widget.settings) || {};
                        return (
                            <div
                                className={classes.widget} onClick={this.updateWidget.bind(this, widget)}
                                key={widget.id}
                            >
                                <div
                                    className={classes.is_disabled + ' ' + (widget.is_disabled === 1 ? classes.true : classes.false)}
                                    title='Активация виджета'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.props.updateWidget(_.merge(widget, { is_disabled: widget.is_disabled === 0 ? 1 : 0 }), widget.id);
                                    }}
                                />
                                <div className={classes.number}>{ widget.id }</div>
                                <div className={classes.settingsItem}>
                                    { _.get(_.find(companyCatalogues, ['id', settings.catalogue_id]), 'name') }
                                </div>
                                <div className={classes.settingsItem}>
                                    { _.get(_.find(companyServices, ['id', settings.service_id]), 'serviceTemplate.name') }
                                </div>
                                <div className={classes.settingsItem}>
                                    { _.get(_.find(companyResources, ['angara_id', settings.resource_id]), 'name') }
                                </div>
                                <div
                                    id={'widget' + widget.id + 'url'}
                                    className={classes.url}
                                    onClick={this.selectText.bind(this, 'widget' + widget.id + 'url')}
                                >
                                    { process.env.REACT_APP_MAIN_ORTUS_PATH + '/w/' + widget.id }
                                </div>
                            </div>
                        );
                    }) }
                    <div className={classes.btnContainer}>
                        <Button value='Добавить виджет' className='orange' onClick={this.createWidget.bind(this)} />
                    </div>
                </div>
                <Preloader trigger={this.props.fetching} fixed />
                <div
                    className={classes.modal + ' ' + (this.state.widget ? classes.show : '')}
                    onClick={this.closeModal.bind(this)}
                >
                    <div className={classes.content} onClick={e => e.stopPropagation()}>
                        <Select
                            name='catalogues'
                            placeholder='Выберите каталог'
                            value={this.state.catalogue || 'Добавить каталог'}
                            options={_.map(this.catalogueTree, c => {
                                const type = serviceCatalogues.indexOf(c.id) === -1 ? (c.level === 1 ? 'group' : 'disabled') : 'item';
                                return { value: c.id, label: c.name, type: type, level: c.level, childrenCatalogues: c.childrenCatalogues };
                            })}
                            type='select'
                            onChange={this.changeCatalogue.bind(this)}
                            search
                        />
                        <Select
                            name='services'
                            placeholder='Выберите услугу'
                            value={this.state.service || 'Добавить услугу'}
                            options={services}
                            type='select'
                            onChange={this.changeService.bind(this)}
                            search
                        />
                        <Select
                            name='resources'
                            placeholder={'Выберите ' + _.get(this.props.company, 'angaraInfo.resourceTitleGenitive', 'мастера').toLowerCase()}
                            value={this.state.resource || 'Добавить ' + _.get(this.props.company, 'angaraInfo.resourceTitleGenitive', 'мастера').toLowerCase()}
                            options={resources}
                            type='select'
                            onChange={this.changeResource.bind(this)}
                            search
                        />
                        <Button
                            value='Сбросить настройки' className='primary'
                            onClick={this.resetWidgetSettings.bind(this)}
                        />
                        { _.get(this.state.widget, 'id')
                            ? (
                                <Button
                                    value='Удалить' className='carRemove'
                                    onClick={this.deleteWidget.bind(this, this.state.widget.id)}
                                />
                            )
                            : null }
                        <Button value='Сохранить' className='save' onClick={this.saveWidget.bind(this)} />
                    </div>
                </div>
            </div>
        );
    }
}

Widgets.contextTypes = {
    router: PropTypes.object
};

export default Widgets;
