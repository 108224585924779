import React from 'react';
import classes from './OrderModal.module.scss';
import moment from 'moment';
import { formatPhone, formatNumber, formatDuration } from 'utils';
import _ from 'lodash';

class OrderModal extends React.Component {
    state = {
        activeService: null
    };

    componentDidMount() {

    }

    changeActiveService(id, e) {
        if (e) {
            e.stopPropagation();
        }
        this.setState({ activeService: id });
    }

    render() {
        const order = this.props.order;

        let servicesCounter = 0;

        let totalPrice = 0;
        let orderDate = _.find(order.orderDetails).scheduledTo;
        _.map(order.orderDetails, service => {
            if (moment(service.scheduledTo).isBefore(orderDate)) {
                orderDate = service.scheduledTo;
            }
        });
        const orderDateDay = moment(orderDate).utcOffset(this.props.companyTimeZone).format('D MMMM');

        const orderDateTime = moment(orderDate).utcOffset(this.props.companyTimeZone).format('HH:mm');

        let cancelBlock; let executeButton; const executableOrder = order.status === 'ACTIVE' || order.isExpired;

        if (executableOrder) {
            cancelBlock = this.props.confirmCancelOrder
                ? (
                    <div className={classes.cancelOrder + ' ' + classes.toConfirm}>Отменить запись?&nbsp;
                        <div onClick={this.props.cancelOrder.bind(this, order.id)}>Да</div>&nbsp;
                        <div onClick={this.props.switchConfirmCancelOrder.bind(this)}>Нет</div>
                    </div>
                )
                : (
                    <div
                        className={classes.cancelOrder}
                        onClick={this.props.switchConfirmCancelOrder.bind(this)}
                    >
                        Отменить запись
                    </div>
                );

            executeButton = (
                <div className={classes.change} onClick={this.props.executeOrder.bind(this, order.id)}>
                    Выполнить
                </div>
            );
        }

        return (
            <div className={classes.orderModal} onClick={this.props.switchOrderModal.bind(this, null)}>
                <div className={classes.element} onClick={this.changeActiveService.bind(this, null)}>
                    <div className={classes.header}>
                        <div className={classes.name}>{ order.clientName }</div>
                        <div className={classes.phone}>{ formatPhone(order.clientPhone) }</div>
                    </div>
                    <div className={classes.body}>
                        <div className={classes.resource}>Исполнитель услуги: <span>{ order.resourceName }</span>
                        </div>
                        <div className={classes.dateBlock}>
                            <div className={classes.dates}>
                                <span className={classes.date}>{ orderDateDay }</span>
                                <span>&nbsp;в&nbsp;</span>
                                <span className={classes.time}>{ orderDateTime }</span>
                            </div>
                        </div>
                        <div className={classes.serviceBlock}>
                            { _.map(order.orderDetails, (service, key) => {
                                servicesCounter++;
                                totalPrice += service.price;
                                return (
                                    <div className={classes.row} key={'service' + service.id}>
                                        <div className={classes.name}>{ servicesCounter + '. ' + service.service.serviceTemplate.name }</div>
                                        <div className={classes.duration}>
                                            { formatDuration(service.duration) }
                                        </div>
                                        <div className={classes.price} onClick={this.changeActiveService.bind(this, service.id)}>
                                            { service.id === this.state.activeService && executableOrder
                                                ? (
                                                    <input
                                                        value={service.price}
                                                        onChange={this.props.changeServicePrice.bind(this, key)}
                                                        autoFocus
                                                        onKeyPress={e => { (e.key === 'Enter') && this.changeActiveService(null); }}
                                                    />
                                                )
                                                : (
                                                    <div className={classes.value + ' ' + (executableOrder ? classes.active : '')}>
                                                        { formatNumber(service.price) } руб
                                                    </div>
                                                ) }
                                        </div>
                                    </div>
                                );
                            }) }
                            <div className={classes.totalPrice}>
                                <div className={classes.label}>Сумма заказа:</div>
                                <div className={classes.value}>
                                    { formatNumber(totalPrice) } руб
                                </div>
                            </div>
                        </div>
                        { cancelBlock }
                    </div>
                    <hr />
                    <div className={classes.footer}>
                        <div
                            className={classes.cancel}
                            onClick={this.props.switchOrderModal.bind(this, null)}
                        >
                            Отмена
                        </div>
                        { executeButton }
                    </div>
                </div>
            </div>
        );
    }
}

export default OrderModal;
