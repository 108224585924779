import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import classes from './OkkLayout.module.scss';

export const OkkLayout = ({ children, location, history }) => {
    let menu;
    if (location.pathname === '/okk/reports/by-answer') {
        menu = (
            <div className={classes.menu}>
                <div onClick={history.goBack} className={classes.link + ' ' + classes.back}>
                    <span>Назад к разделу «Статистика<br />и настройка ОКК»</span>
                </div>
            </div>
        );
    } else {
        menu = (
            <div className={classes.menu}>
                <NavLink
                    to='/okk'
                    activeClassName={classes.active}
                    exact
                    className={classes.link}
                >
                    <span>Статистика<br />и настройка ОКК</span>
                </NavLink>
                <NavLink
                    to='/okk/reports'
                    activeClassName={classes.active}
                    className={classes.link}
                >
                    <span>Предложения<br />и негативные отзывы</span>
                </NavLink>
                <NavLink
                    to='/okk/custom-lists'
                    activeClassName={classes.active}
                    className={classes.link}
                >
                    <span>Созданные<br />списки</span>
                </NavLink>
                <NavLink
                    to='/okk/archive'
                    activeClassName={classes.active}
                    className={classes.link}
                >
                    <span>Архив<br />опросов</span>
                </NavLink>
            </div>
        );
    }

    return (
        <div className={classes.container}>
            { menu }
            { children }
        </div>
    );
};

OkkLayout.propTypes = {
    children: PropTypes.element.isRequired
};

OkkLayout.propTypes = {
    location: PropTypes.object.isRequired
};

OkkLayout.contextTypes = {
    router: PropTypes.object
};

export default withRouter(OkkLayout);
