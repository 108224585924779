import React from 'react';
import classes from './Statistic.module.scss';
import cx from 'classnames';
import moment from 'moment';
import DatePicker from 'components/DatePicker';
import Select from 'components/Select';
import ReactHighcharts from 'react-highcharts';
import { Scrollbars } from 'react-custom-scrollbars';
import { declOfNum } from 'utils';
import _ from 'lodash';

class Statistic extends React.Component {
    constructor(props) {
        super(props);
        this.changeEmployee = this.changeEmployee.bind(this);
        this.changeByPeriod = this.changeByPeriod.bind(this);
    }

    state = {
        byEmployee: null,
        byPeriod: false,
        period: [moment(), moment()],
        mode: 'byLid',
        statusFilter: ['delivered', 'missed', 'moved', 'blacklisted'],
        serviceFilter: []
    };

    UNSAFE_componentWillMount() {
        ReactHighcharts.Highcharts.setOptions({
            lang: {
                loading: 'Загрузка...',
                noData: 'Нет данных на выбранный период по указанным параметрам клиентов и якорей',
                months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
                shortWeekdays: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
                shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
                exportButtonTitle: 'Экспорт',
                printButtonTitle: 'Печать',
                rangeSelectorFrom: 'С',
                rangeSelectorTo: 'По',
                rangeSelectorZoom: 'Период',
                downloadPNG: 'Скачать PNG',
                downloadJPEG: 'Скачать JPEG',
                downloadPDF: 'Скачать PDF',
                downloadSVG: 'Скачать SVG',
                printChart: 'Напечатать график'
            }
        });
    }

    componentDidMount() {
        this.props.fetchStatistics(this.getParams(this.state));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (JSON.stringify(nextProps.anchorStatistics) !== JSON.stringify(this.props.anchorStatistics)) {
            const serviceFilter = _.map(_.get(nextProps.anchorStatistics, 'notifications', {}), (notify, key) => {
                return key;
            });
            this.setState({ serviceFilter: serviceFilter });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const prevData = this.getParams(prevState); const nextData = this.getParams(this.state);
        if (JSON.stringify(prevData) !== JSON.stringify(nextData)) {
            if (this.state.mode === 'byAnchor') {
                this.props.fetchAnchorStatistics(nextData);
            } else {
                this.props.fetchStatistics(nextData);
            }
        }
    }

    getParams(state) {
        const data = {};
        if (state.byEmployee) {
            data.employee_id = state.byEmployee;
        }
        if (state.period[0].isBefore(state.period[1])) {
            data.range_start = state.period[0].format();
            data.range_end = state.period[1].format();
        }
        if (state.mode === 'byLid') {
            data.client_type = 'lid';
        }
        if (state.mode === 'byRegular') {
            data.client_type = 'active';
        }
        return data;
    }

    renderTrack({ style, ...props }) {
        const trackStyle = {};
        return (
            <div
                className='scrollTrack'
                style={{ ...style, ...trackStyle }}
                {...props}
            />
        );
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {};
        return (
            <div
                className='scrollThumb'
                style={{ ...style, ...thumbStyle }}
                {...props}
            />
        );
    }

    changeEmployee(value) {
        this.setState({ byEmployee: value.value });
    }

    onChangeDate(index, date) {
        const period = _.clone(this.state.period);
        period[index] = date;
        this.setState({ byPeriod: 'period', period: period });
    }

    changeByPeriod(value) {
        if (value.value === 'month') {
            this.setState({ byPeriod: value.value, period: [moment().add(-1, 'month'), moment()] });
        }
        if (value.value === 'week') {
            this.setState({ byPeriod: value.value, period: [moment().add(-1, 'w'), moment()] });
        }
        if (value.value === 'period') {
            this.setState({ byPeriod: value.value });
        }
    }

    changeMode(mode, e) {
        e.preventDefault();
        this.setState({ mode: mode });
    }

    changeStatusFilter(filterValue) {
        const filter = this.state.statusFilter;
        if (_.includes(filter, filterValue)) {
            _.pull(filter, filterValue);
        } else {
            filter.push(filterValue);
        }
        this.setState({ statusFilter: filter });
    }

    changeServiceFilter(filterValue) {
        const filter = this.state.serviceFilter;
        if (_.includes(filter, filterValue)) {
            _.pull(filter, filterValue);
        } else {
            filter.push(filterValue);
        }
        this.setState({ serviceFilter: filter });
    }

    render() {
        const emplSelItems = _.map(this.props.employees, employee => {
            return { type: 'item', label: employee.name, value: employee.id };
        });

        const byPeriod = [
            { type: 'item', label: 'за период', value: 'period' },
            { type: 'item', label: 'за месяц', value: 'month' },
            { type: 'item', label: 'за неделю', value: 'week' }
        ];
        emplSelItems.push({ type: 'item', label: 'для всех сотрудников', value: null });
        const fullCount = {};
        _.map(['delivered', 'missed', 'moved', 'blacklisted'], status => {
            if (this.props.statistics.calls && this.props.statistics.calls[status]) {
                const statArray = _.map(this.props.statistics.calls[status], calls => {
                    return parseInt(calls);
                });
                fullCount[status] = _.sum(statArray);
            } else {
                fullCount[status] = 0;
            }
        });
        // генерим график
        const mode = this.state.mode;

        const names = { byLid: 'лидам', byRegular: 'постоянным', byAnchor: 'якорям' };

        const nounes = {
            byLid: ['лид', 'лида', 'лидов'],
            byRegular: ['постоянный', 'постоянных', 'постоянных'],
            byAnchor: ['якорь', 'якоря', 'якорей']
        };

        const config = {
            chart: {
                zoomType: 'x'
            },
            colors: ['#1dbd9d', '#ff7113', '#216997', '#3c3c3c'],
            legend: {
                enabled: false
            },
            title: {
                text: ''
            },
            tooltip: {
                borderWidth: 0,
                backgroundColor: 'rgba(255, 255, 255, 1)',
                dateTimeLabelFormats: {
                    millisecond: '%A, %b %e, %H:%M:%S.%L',
                    second: '%A, %b %e, %H:%M:%S',
                    minute: '%A, %b %e, %H:%M',
                    hour: '%A, %b %e, %H:%M',
                    day: '%A, %b %e, %Y',
                    week: 'Week from %A, %b %e, %Y',
                    month: '%B %Y',
                    year: '%Y'
                },
                xDateFormat: '%a ',
                headerFormat: '<span style="color: #ff954e">{point.key} </span>',
                pointFormatter: function() {
                    return '<span style="color:#73ae2e">' + this.y + ' ' + declOfNum(this.y, nounes[mode]) + '</span>';
                }
            },
            responsive: {
                rules: [{
                    condition: { maxHeight: 265, maxWidth: 200 }
                }]
            },
            xAxis: {
                type: 'datetime',
                maxZoom: 48 * 3600 * 1000
            },
            yAxis: {
                title: null
            },
            series: this.state.mode === 'byAnchor' ? _.map(_.get(this.props.anchorStatistics, 'notifications', {}), (notify, serviceId) => {
                return {
                    name: notify.service_name,
                    visible: _.includes(this.state.serviceFilter, serviceId),
                    data: _.sortBy(_.map(notify.statistic, (value, key) => {
                        return [moment.utc(key, 'DD-MM-YYYY').valueOf(), value];
                    }), [0])
                };
            })
                : _.map(['delivered', 'missed', 'moved', 'blacklisted'], (status, key) => {
                    return {
                        name: (['Одобренные', 'Пропущенные', 'Перенесенные', 'Черный список'])[key],
                        visible: _.includes(this.state.statusFilter, status),
                        data: _.sortBy(_.map(_.get(this.props.statistics, 'calls.' + status, []), (statistic, key) => {
                            return [moment.utc(key, 'DD-MM-YYYY').valueOf(), statistic];
                        }), [0])
                    };
                })
        };

        if (this.state.mode === 'byAnchor') {
            config.legend.enabled = false;
        }

        return (
            <div className={classes.anchorStatistic}>
                <div className={classes.header}>
                    <div className={classes.text}>Статистика по { names[this.state.mode] }</div>
                    &nbsp;
                    { this.state.mode !== 'byAnchor' ? (
                        <Select
                            name='employee'
                            placeholder='для всех сотрудников'
                            value={this.state.byEmployee}
                            options={emplSelItems}
                            type='inline'
                            className='callcenterStatisticEmployeeFilter'
                            onChange={this.changeEmployee}
                        />
                    ) : null }
                </div>
                <div className={classes.period}>
                    <Select
                        name='byPeriod'
                        placeholder='за период'
                        value={this.state.byPeriod}
                        options={byPeriod}
                        type='inline'
                        className='callcenterStatisticPeriodFilter'
                        onChange={this.changeByPeriod}
                    />
                    <div className={classes.date}>
                        <span>&nbsp;c&nbsp;</span>
                        <DatePicker
                            className='orange'
                            onChange={this.onChangeDate.bind(this, 0)}
                            selected={this.state.period[0]}
                            align={['center', 'bottom']}
                        />
                    </div>
                    <div className={classes.date}>
                        <span>&nbsp;по&nbsp;</span>
                        <DatePicker
                            className='orange'
                            onChange={this.onChangeDate.bind(this, 1)}
                            selected={this.state.period[1]}
                            align={['center', 'bottom']}
                        />
                    </div>
                </div>
                <div className={classes.modes}>
                    <div
                        className={cx({
                            [classes.mode]: true,
                            [classes.byLid]: true,
                            [classes.active]: this.state.mode === 'byLid'
                        })}
                        onClick={this.changeMode.bind(this, 'byLid')}
                    >
                        лиды
                    </div>
                    <div
                        className={cx({
                            [classes.mode]: true,
                            [classes.byRegular]: true,
                            [classes.active]: this.state.mode === 'byRegular'
                        })}
                        onClick={this.changeMode.bind(this, 'byRegular')}
                    >
                        постоянные
                    </div>
                    <div
                        className={cx({
                            [classes.mode]: true,
                            [classes.byAnchor]: true,
                            [classes.active]: this.state.mode === 'byAnchor'
                        })}
                        onClick={this.changeMode.bind(this, 'byAnchor')}
                    >
                        якоря
                    </div>
                </div>
                <div className={classes.statistics}>
                    <Scrollbars
                        ref='callStatScrollbars'
                        id='callStatScrollbars'
                        thumbSize={16}
                        renderTrackVertical={this.renderTrack}
                        renderThumbVertical={this.renderThumb}
                        onWheel={event => {
                            if (event.deltaY > 0) {
                                const { callStatScrollbars } = this.refs;
                                const scroll = callStatScrollbars.getValues();
                                if (scroll.clientHeight + scroll.scrollTop === scroll.scrollHeight) {
                                    event.preventDefault();
                                }
                            }
                        }}
                    >
                        <div className={classes.wrapper}>
                            <div className={classes.graphBlock}>
                                { this.props.statistics.total === 0
                                    ? (<div className={classes.noData}>Нет данных по вашему запросу.</div>)
                                    : (<ReactHighcharts config={config} />) }
                            </div>
                            { this.state.mode === 'byAnchor' ? (
                                <div className={classes.statusList}>
                                    { _.map(_.get(this.props.anchorStatistics, 'notifications', {}), (notify, key) => {
                                        return (
                                            <div
                                                key={key} className={cx({
                                                    [classes.status]: true,
                                                    [classes.active]: _.includes(this.state.serviceFilter, key)
                                                })}
                                                onClick={this.changeServiceFilter.bind(this, key)}
                                            >
                                                <div className={classes.title}>{ notify.service_name }</div>
                                                <div className={classes.count}>{ _.sum(_.map(notify.statistic, count => {
                                                    return count;
                                                })) }
                                                </div>
                                            </div>
                                        );
                                    }) }
                                    <div className={classes.total}>
                                        Общее количество:
                                        <div
                                            className={classes.count}
                                        >{ _.get(this.props.notifications, 'total', 0) }
                                        </div>
                                    </div>
                                </div>

                            ) : (
                                <div className={classes.statusList}>
                                    <div
                                        className={cx({
                                            [classes.status]: true,
                                            [classes.delivered]: true,
                                            [classes.active]: _.includes(this.state.statusFilter, 'delivered')
                                        })}
                                        onClick={this.changeStatusFilter.bind(this, 'delivered')}
                                    >
                                        <div className={classes.title}>Количество успешных звонков</div>
                                        <div className={classes.count}>{ fullCount.delivered }</div>
                                    </div>
                                    <div
                                        className={cx({
                                            [classes.status]: true,
                                            [classes.missed]: true,
                                            [classes.active]: _.includes(this.state.statusFilter, 'missed')
                                        })}
                                        onClick={this.changeStatusFilter.bind(this, 'missed')}
                                    >
                                        <div className={classes.title}>Не взяли трубку</div>
                                        <div className={classes.count}>{ fullCount.missed }</div>
                                    </div>
                                    <div
                                        className={cx({
                                            [classes.status]: true,
                                            [classes.moved]: true,
                                            [classes.active]: _.includes(this.state.statusFilter, 'moved')
                                        })}
                                        onClick={this.changeStatusFilter.bind(this, 'moved')}
                                    >
                                        <div className={classes.title}>Перенесено звонков</div>
                                        <div className={classes.count}>{ fullCount.moved }</div>
                                    </div>
                                    <div
                                        className={cx({
                                            [classes.status]: true,
                                            [classes.blacklisted]: true,
                                            [classes.active]: _.includes(this.state.statusFilter, 'blacklisted')
                                        })}
                                        onClick={this.changeStatusFilter.bind(this, 'blacklisted')}
                                    >
                                        <div className={classes.title}>В черный список</div>
                                        <div className={classes.count}>{ fullCount.blacklisted }</div>
                                    </div>
                                    <div className={classes.total}>
                                        Количество клиентов:
                                        <div className={classes.count}>{ this.props.statistics.total }</div>
                                    </div>
                                </div>
                            ) }

                        </div>
                    </Scrollbars>
                </div>
            </div>
        );
    }
}

export default Statistic;
