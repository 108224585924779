import { connect } from 'react-redux';
import {
    changeCompany, changeCompanyField, deleteCompanyCover, deleteCompanyFile, pushCompanyFile, changeCompanyCover
} from 'modules/company';
import { actions } from '../../modules/settings';

import Common from './Common';

const mapStateToProps = state => ({
    company: state.company.company,
    cities: state.settings.cities
});

export default connect(mapStateToProps, Object.assign(actions, {
    changeCompany, changeCompanyField, deleteCompanyCover, deleteCompanyFile, pushCompanyFile, changeCompanyCover
}))(Common);
