import React from 'react';
import ReactDOM from 'react-dom';

import { declOfNum, getPeriodTitle, handleClickOutside } from 'utils';

import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';

import classes from './Editor.module.scss';

class SetCyclic extends React.Component {
    state = {
        open: false
    };

    componentDidMount() {
        this.onDocumentClick = this.onDocumentClick.bind(this);
        document.addEventListener('click', this.onDocumentClick, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onDocumentClick);
    }

    onDocumentClick(event) {
        if (this.state.open && handleClickOutside(event, ReactDOM.findDOMNode(this))) {
            this.setState({ open: false });
        }
    }

    renderTrack({ style, ...props }) {
        const trackStyle = {};
        return (
            <div
                className={classes.scrollTrack}
                style={{ ...style, ...trackStyle }}
                {...props}
            />
        );
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {};
        return (
            <div
                className={classes.scrollThumb}
                style={{ ...style, ...thumbStyle }}
                {...props}
            />
        );
    }

    render() {
        const periods = [
            { title: 'год', text: 'Год', length: 365 },
            { title: 'месяц', text: 'Месяц', length: 30 },
            { title: 'неделю', text: 'Неделя', length: 7 }
        ];

        return (
            <div
                className={`${classes.inputGroup} ${classes.cyclic}`}
            >
                { this.props.noTitle ? null : (<div className={`${classes.title} ${classes.row}`}>Цикличность:</div>) }
                <div className={classes.row + (!this.props.value.isPeriodic ? (` ${classes.disabled}`) : '')}>
                    <input
                        type='number'
                        name='repeatsDuringPeriod'
                        value={this.props.value.repeats_during_period}
                        onChange={(e) => {
                            if (this.props.onChange) {
                                this.props.onChange({
                                    isPeriodic: this.props.value.isPeriodic,
                                    period: this.props.value.period,
                                    repeats_during_period: e.target.value
                                });
                            }
                        }}
                        disabled={false}
                    />
                    <div className={classes.text}>
                        { declOfNum(this.props.value.repeats_during_period, ['раз в', 'раза в', 'раз в']) }
                        &nbsp;
                    </div>
                    <div
                        className={classes.changeValue}
                        onClick={() => {
                            this.setState({ open: !this.state.open });
                        }}
                    >
                        <div
                            className={classes.value}
                        >
                            { getPeriodTitle(this.props.value.period) }
                        </div>
                        <div className={classes.select} />
                        { this.state.open
                            ? (
                                <div
                                    className={classes.outerPeriodMenu}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <Scrollbars
                                        thumbSize={16}
                                        autoHeight
                                        autoHide={false}
                                        renderTrackVertical={this.renderTrack}
                                        renderThumbVertical={this.renderThumb}
                                    >
                                        { _.map(periods, period => (
                                            <div
                                                key={`period${period.title}`}
                                                className={classes.item}
                                                onClick={() => {
                                                    if (this.props.onChange) {
                                                        this.props.onChange({
                                                            isPeriodic: true,
                                                            period: period.length,
                                                            repeats_during_period: this.props.value.repeats_during_period
                                                        });
                                                    }
                                                    this.setState({ open: false });
                                                }}
                                            >
                                                { period.text }
                                            </div>
                                        )) }
                                    </Scrollbars>
                                </div>
                            )
                            : null }
                    </div>
                </div>

                <div>
                    <label
                        className={classes.isPeriodic}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <input
                            type='checkbox'
                            checked={!this.props.value.isPeriodic}
                            onChange={(e) => {
                                e.stopPropagation();
                                if (this.props.onChange) {
                                    this.props.onChange(_.merge(_.clone(this.props.value), { isPeriodic: !this.props.value.isPeriodic }));
                                }
                            }}
                        />
                        { ' ' }
                        не цикличная услуга
                    </label>
                </div>

            </div>
        );
    }
}

export default SetCyclic;
