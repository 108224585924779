import { connect } from 'react-redux';
import { actions } from '../../modules/okk';

import CustomLists from '../components/CustomLists';

const mapStateToProps = () => ({

});

export default connect(mapStateToProps, actions)(CustomLists);
