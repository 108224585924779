import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import CreatePost from './CreatePost';
import EditPost from './EditPost';

class Posts extends React.Component {
    componentDidMount() {

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((nextProps.editResource && !this.props.editResource) || (nextProps.addResource && !this.props.addResource)) {
            const body = document.querySelectorAll('body');

            const html = document.querySelectorAll('html');
            if (body[0] && body[0].style) {
                body[0].style.overflowY = 'hidden';
            }
            if (html[0] && html[0].style) {
                html[0].style.overflowY = 'hidden';
            }
        }
        if ((!nextProps.editResource && this.props.editResource) || (!nextProps.addResource && this.props.addResource)) {
            const body = document.querySelectorAll('body');

            const html = document.querySelectorAll('html');
            if (body[0] && body[0].style) {
                body[0].style.overflowY = 'auto';
            }
            if (html[0] && html[0].style) {
                html[0].style.overflowY = 'auto';
            }
        }
    }

    toEditResource(resource) {
        if (!resource) {
            this.props.history.push('/settings/posts');
        }
        const thumbnail = _.find(_.get(resource, 'thumbnails', null), ['templateName', 'm1']);

        const resourceData = {
            id: resource.id,
            angara_id: resource.angara_id,
            name: resource.name,
            descr: resource.descr,
            divisions: _.map(resource.divisions, division => division.id),
            is_online: resource.isOnline,
            imageUrl: resource.imageUrl ? _.get(thumbnail, 'url', null) : null
        };
        if (!_.isEmpty(resource.services)) {
            resourceData.services = _.map(resource.services, service => ({
                id: service.id,
                duration: service.duration,
                price: service.price
            }));
        }
        this.props.toEditResource(resourceData);
    }

    editResource(data) {
        if (data.divisions) {
            data.divisions = _.map(data.divisions, (d) => {
                if (d.id) {
                    return d.id;
                }
                if (parseInt(d) === d) {
                    return d;
                }
            });
        }
        this.props.updateResource(data).then(() => {
            // TODO сделать обновление данных без загрузки компании
            /*            this.props.fetchCompany(this.props.company.id, [
                'city',
                'divisions',
                'resources',
                'resources.divisions',
                'resources.schedules',
                'files',
                'files.thumbs',
                'notifications',
                'catalogues',
                'services'
            ]); */
        });
    }

    render() {
        const company = this.props.company;

        const resources = _.get(this.props, 'resources', []);

        const services = _.get(company, 'services.services', []);

        const divHasRes = {};

        const resHasServices = {};

        const orphanResources = [];
        _.map(resources, (resource) => {
            if (!resource.divisions || resource.divisions.length === 0) {
                orphanResources.push(resource.id);
            }
            _.map(resource.divisions, (division) => {
                if (!divHasRes[division.id]) {
                    divHasRes[division.id] = [];
                }
                divHasRes[division.id].push(resource.id);
            });
        });
        _.map(services, (service) => {
            _.map(service.resources, (resource) => {
                if (!resHasServices[resource.id]) {
                    resHasServices[resource.id] = [];
                }
                resHasServices[resource.id].push(service.id);
            });
        });

        return (
            <div className='settings-block'>
                <div
                    className='settings-block__h1'
                >
                    Настройка
                    { ' ' }
                    { `${_.get(company, 'angaraInfo.resourceTitle', 'мастер')}ов` }
                </div>
                <div className='settings-block--posts'>
                    <div className='infoHeader'>
                        <div>Работают:&nbsp;{ _.filter(resources, r => r.services && r.services.length > 0 && r.isOnline).length }</div>
                        <div>Без прикреплённых услуг:&nbsp;
                            <span className='red'>
                                { _.filter(resources, r => !r.services || r.services.length === 0).length }
                            </span>
                        </div>
                        { /* <div>Не настроен режим работы: <span className="red">2</span></div> */ }
                    </div>
                    <div className='resources'>
                        <div className='resources__theader'>
                            <div className='resources__theader__td'>
                                Имя мастера
                            </div>
                            <div className='resources__theader__td'>
                                Количество услуг
                            </div>
                            <div className='resources__theader__td'>
                                онлайн запись
                            </div>
                        </div>
                        { _.map(resources, (resource) => {
                            const avatarPath = _.get(_.find(_.get(resource, 'thumbnails', []), { templateName: 's1' }), 'url', null);
                            return (
                                <Link
                                    className={`resource${_.get(resource, 'services', []).length !== 0 ? '' : ' unserviced'}`}
                                    key={resource.id}
                                    to={`/settings/posts/${resource.id}`}
                                >
                                    <div className='resource__avatar'>
                                        { avatarPath ? (<img src={avatarPath} alt='' />) : null }
                                    </div>
                                    <div className='resource__title'>
                                        { resource.name }
                                    </div>
                                    <div className='resource__service'>
                                        { _.get(resource, 'services', []).length === 0 ? (
                                            <div className='warning-icon' title='Не прикреплено ни одной услуги' />) : _.get(resource, 'services', []).length }
                                    </div>
                                    <div className={`resource__isOnline ${resource.isOnline ? 'on' : 'off'}`}>
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.editResource({
                                                    id: resource.id,
                                                    divisions: resource.divisions,
                                                    is_online: !resource.isOnline,
                                                    isOnline: !resource.isOnline
                                                });
                                            }}
                                            className={`checkbox ${resource.isOnline ? 'active' : ''}`}
                                        />
                                    </div>
                                </Link>
                            );
                        }) }
                        { company.integrated_with_1c !== 1
                            ? (
                                <div
                                    className='addResource'
                                    onClick={this.props.toCreateResource.bind(this, null)}
                                >
                                    Добавить
                                    { ' ' }
                                    { _.get(company, 'angaraInfo.resourceTitlePlural') }
                                </div>
                            )
                            : '' }
                    </div>
                    { this.props.addResource ? (
                        <CreatePost />
                    ) : '' }
                    { this.props.params && this.props.params.id ? (
                        <EditPost id={+this.props.params.id} />
                    ) : '' }
                </div>
            </div>
        );
    }
}

Posts.contextTypes = {
    router: PropTypes.object
};
export default Posts;
