import React from 'react';
// Libs
import classes from './OrderFooter.module.scss';
import moment from 'moment';
import _ from 'lodash';

// CommonComps
import Preloader from 'components/Preloader';
import DatePicker from 'components/DatePicker';
// Comps
import Division from './Division';
import RenderInBody from 'components/RenderInBody';
import OrderModal from './OrderModal';

class ResourseFooter extends React.Component {
    constructor(props) {
        super(props);

        this.scrollAction = this.scrollAction.bind(this);
        this.takeOffChunk = this.takeOffChunk.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scrollAction);
        const day = this.props.currentDay ? moment(this.props.currentDay) : moment();
        const range_start = moment(day).startOf('date').format(); const range_end = moment(day).startOf('date').add(2, 'd').format();
        if (this.props.partner) {
            this.props.fetchCompany(this.props.partner.company_id, ['city', 'divisions', 'resources', 'resources.divisions', 'services', 'catalogues', 'notifications']);
            this.props.fetchResources();
            this.props.fetchSchedules([moment(range_start).format('Y-MM-DD'), moment(range_start).add(1, 'd').format('Y-MM-DD')]);
            this.props.fetchOrders(this.props.partner.company_id, { range_start: range_start, range_end: range_end, expand: 'orderDetails,service,serviceOptions,reference,values' });
            this.props.fetchReserves({ range_start: range_start, range_end: range_end });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollAction);
    }

    scrollAction() {
        // реакцию на сроклл делаем в случае, если в текущий момент не включен какой либо функционал по записи и расписаню
        if (!_.get(this.props.toOrder, 'mode', false)) {
            const el = document.getElementById('orderfooter');

            const elHeader = document.getElementById('footerheader');

            const rect = el.getBoundingClientRect();

            const className = 'footer-fixed-header';
            if (rect.top >= 742 && el.clientHeight === document.documentElement.clientHeight) {
                this.props.setFooterMinHeight('300px');
            }
            if (rect.top < 0) {
                if (elHeader.classList) {
                    elHeader.classList.add(className);
                } else {
                    elHeader.className += ' ' + className;
                }
            } else {
                if (elHeader.classList) {
                    elHeader.classList.remove(className);
                } else {
                    elHeader.className = elHeader.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
                }
            }
        }
    }

    cancelOrdering(e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.chooseToOrder(null);
    }

    changeDate(date) {
        const day = moment(date);
        const range_start = moment(date).startOf('date').format();

        const range_end = moment(date).startOf('date').add(2, 'd').format();
        if (this.props.toOrder && this.props.toOrder.mode === 'onOrder' && this.props.toOrder.chunk) {
            this.props.chooseToOrder({ date: moment(day).add(this.props.toOrder.chunk * 30, 'm') });
        }
        this.props.fetchSchedules([day.format('Y-MM-DD'), moment(day).add(1, 'd').format('Y-MM-DD')]);
        this.props.fetchOrders(this.props.partner.company_id, { range_start: range_start, range_end: range_end }, true);
        this.props.fetchReserves({ range_start: range_start, range_end: range_end });

        this.props.changeDate(day);
        this.props.showHideDp();
    }

    changeDayTime(dayTime, e) {
        e.preventDefault();
        this.props.changeDayTime(dayTime);
    }

    // генерит из портянки заказов массив с ключами по ресурсам с тремя элементами занятые чанки, услуги и заказы
    normalizeOrders(orders, currentDay, companyTimezone) {
        const chunkSize = 30;
        const result = {};
        // let currentDay = this.props.currentDay ? this.props.currentDay : moment().startOf('date');
        _.map(orders, order => {
            if (order.status === 'ACTIVE' || order.status === 'EXECUTED') {
                _.map(order.orderDetails,
                    service => {
                        if (_.isEmpty(result[_.get(service, 'resource.id')])) {
                            result[service.resource.id] = { chunks: {}, orders: {}, services: [] };
                        }
                        if (_.isEmpty(result[service.resource.id].orders[order.id])) {
                            const orderDuration = _.sum(_.map(order.orderDetails, d => {
                                return d.duration;
                            }));
                            result[service.resource.id].orders[order.id] = {
                                id: order.id,
                                client: order.client,
                                duration: orderDuration
                            };
                        }
                        const scheduledTo = moment(service.scheduledTo).utcOffset(companyTimezone);

                        if (scheduledTo.format('DD MMMM YY') === currentDay.format('DD MMMM YY')) {
                            const firstChunk = Math.floor((parseInt(scheduledTo.format('H')) * 60 + parseInt(scheduledTo.format('m'))) / chunkSize);

                            const lastChunk = (firstChunk + Math.ceil((service.duration + parseInt(scheduledTo.format('m')) % chunkSize) / chunkSize)) - 1;

                            service.chunks = [];
                            for (let i = firstChunk; i <= lastChunk; i++) {
                                service.chunks.push(i);
                                result[service.resource.id].chunks[i] = order.id;
                            }
                            result[service.resource.id].services.push(service);
                        }
                    });
            }
        });
        return result;
    }

    normalizeReserves(reserves, currentDay, companyTimezone) {
        const chunkSize = 30;
        const result = {};
        _.map(reserves, reserve => {
            if (reserve.status === 'ACTIVE') {
                if (_.isEmpty(result[reserve.resource.id])) { result[reserve.resource.id] = { chunks: {}, reserves: {} }; }
                if (_.isEmpty(result[reserve.resource.id].reserves[reserve.id])) {
                    result[reserve.resource.id].reserves[reserve.id] = {
                        id: reserve.id,
                        client: reserve.client,
                        duration: reserve.duration
                    };
                }
                const scheduledTo = moment(reserve.scheduledTo).utcOffset(companyTimezone);
                if (scheduledTo.format('DD MMMM YY') === currentDay.format('DD MMMM YY')) {
                    const firstChunk = Math.floor((parseInt(scheduledTo.format('H')) * 60 + parseInt(scheduledTo.format('m'))) / chunkSize);

                    const lastChunk = (firstChunk + Math.ceil((reserve.duration + parseInt(scheduledTo.format('m')) % chunkSize) / chunkSize)) - 1;
                    reserve.chunks = [];
                    for (let i = firstChunk; i <= lastChunk; i++) {
                        reserve.chunks.push(i);
                        result[reserve.resource.id].chunks[i] = reserve.id;
                    }
                }
            }
        });
        return result;
    }

    normalizeCompany(company) {
        // ищем ресурсы бездельники
        const slackers = _
            .chain(company.resources)
            .filter(resource => {
                return _.isEmpty(resource.services);
            })
            .map(resource => {
                return resource.id;
            })
            .value();

        const orphanRes = [];
        _.map(company.divisions, division => {
            division.resources = [];
        });
        _.map(company.resources, resource => {
            if (_.isEmpty(resource.divisions)) {
                // запихиваем в ресурсы сироты
                if (!_.includes(slackers, resource.id)) {
                    orphanRes.push(resource.id);
                }
            } else {
                _.map(resource.divisions, parentDivision => {
                    const division = _.find(company.divisions, { id: parentDivision.id });
                    if (division) {
                        if (!_.includes(slackers, resource.id)) {
                            division.resources.push(resource.id);
                        }
                    }
                });
            }
        });
        if (orphanRes.length > 0) {
            company.divisions = Object.assign([], company.divisions);
            company.divisions.push(
                {
                    id: 'noDivision',
                    label: 'noDivision',
                    resources: orphanRes,
                    title: company.name
                }
            );
        }
        return company;
    }

    showHideDp(e) {
        if (!this.props.dpOver && this.props.dpSwitcher) {
            this.props.showHideDp(e);
        }

        /*        if (this.props.toOrder && this.props.toOrder.mode) {
            if (this.props.toOrder.mode == 'editOrder') {
                if (this.props.toOrder.status === 'edit') {
                    this.props.chooseToOrder(null);
                } else {
                    this.props.chooseToOrder({ newChunk: null });
                }
            }
            if (this.props.toOrder.mode == 'onOrder') {
                this.props.chooseToOrder(null);
            }
            if (this.props.toOrder.mode == 'viewOrder') {
                this.props.chooseToOrder(null);
            }
            //TODO сделать в самой компоненте просмотра резерва
            /!*if (this.props.toOrder.mode == 'viewReserve') {
                this.props.chooseToOrder(null);
            }*!/
        } */
    }

    takeOffChunk(divisionId, resourceId, chNumber) {
        let chunksKey; let lastChunk; const lastChunks = _.get(_.cloneDeep(this.props.toOrder), 'lastChunks', []);

        const oldLastChunk = _.get(this.props.toOrder, 'lastChunk', {});

        const currentDay = this.props.currentDay ? this.props.currentDay : moment().startOf('date');

        const schedule = _.get(_.find(this.props.schedules, { id: resourceId }), currentDay.format('YYYY-MM-DD'), '');
        if (schedule[chNumber] === '1') {
            chunksKey = 'exclude';
        } else {
            chunksKey = 'include';
        }
        const editedChunks = _.get(_.cloneDeep(this.props.toOrder), 'chunks.' + resourceId + '.' + moment(currentDay).format('YYYY-MM-DD') + '.' + chunksKey, []);

        const isSetKey = editedChunks.indexOf(chNumber);
        if (isSetKey !== -1) {
            editedChunks.splice(isSetKey, 1);
            if (lastChunks.length > 0) {
                if (_.isEqual(oldLastChunk, { divId: divisionId, resId: resourceId, date: moment(currentDay).format('YYYY-MM-DD'), chNumber: chNumber })) {
                    lastChunk = lastChunks[lastChunks.length - 1];
                    lastChunks.splice(lastChunks.length - 1, 1);
                } else {
                    _.remove(lastChunks, { divId: divisionId, resId: resourceId, date: moment(currentDay).format('YYYY-MM-DD'), chNumber: chNumber });
                    lastChunk = oldLastChunk;
                }
            }
        } else {
            editedChunks.push(chNumber);
            lastChunk = {
                divId: divisionId,
                resId: resourceId,
                date: moment(currentDay).format('YYYY-MM-DD'),
                chNumber: chNumber
            };
            if (this.props.toOrder.lastChunk) {
                lastChunks.push(this.props.toOrder.lastChunk);
            }
        }
        const chunks = _.get(_.cloneDeep(this.props.toOrder), 'chunks', {});
        _.set(chunks, resourceId + '.' + moment(currentDay).format('YYYY-MM-DD') + '.' + chunksKey, editedChunks);
        const takeOffResourcesIds = [];

        _.some(lastChunks.concat(lastChunk), chunk => {
            if (chunk && !_.includes(takeOffResourcesIds, chunk.resId)) {
                takeOffResourcesIds.push(chunk.resId);
            }
        });

        this.props.chooseToOrder({ chunks: chunks, lastChunk: lastChunk, lastChunks: lastChunks, takeOffResourcesIds: takeOffResourcesIds });
    }

    render() {
        moment.locale('ru');
        if (!_.get(this.props.company, 'id', false)) {
            return null;
        }
        const timeZone = _.get(this.props.company, 'angaraInfo.timezone');
        const currentDay = this.props.currentDay ? this.props.currentDay : moment().utcOffset(timeZone).startOf('day');// день на который грузится расписание
        const
            orders = this.normalizeOrders(_.get(this.props.orders, 'items', []), currentDay, timeZone);

        const tomorrowOrders = this.normalizeOrders(_.get(this.props.orders, 'items', []), currentDay.clone().add(1, 'd'), timeZone);

        const reserves = this.normalizeReserves(_.get(this.props.reserves, 'items', []), currentDay, timeZone);

        const tomorrowReserves = this.normalizeReserves(_.get(this.props.reserves, 'items', []), currentDay.clone().add(1, 'd'), timeZone);

        const company = this.normalizeCompany(Object.assign({}, this.props.company));

        let order; const toOrder = null;
        if (this.props.toOrder) {
            if (this.props.toOrder.mode === 'onOrder') {
                order = toOrder;
            }
            if (this.props.toOrder.mode === 'editOrder' && this.props.toOrder.status === 'chooseToShift') {
                const editedOrder = _.find(this.props.orders.items, { id: this.props.toOrder.orderId });
                order = (
                    <div className='editOrder'>
                        <div className='wrapper'>
                            <div className='text'>Выберите ячейку времени для переноса записи клиента&nbsp;
                                <span>{ editedOrder.clientName }</span>
                            </div>
                            &nbsp;
                            <div className='cancel' onClick={this.cancelOrdering.bind(this)}>Отменить</div>
                        </div>
                    </div>
                );
            }

            if (this.props.toOrder.mode === 'takeOff') {
                order = (
                    <div className='editOrder'>
                        <div className='wrapper'>
                            <div className='text'>Выберите временные ячейки в которых нужжно отключить запись</div>
                            &nbsp;
                            <div className='cancel' onClick={this.cancelOrdering.bind(this)}>Отменить</div>
                        </div>
                    </div>
                );
            }

            if (this.props.toOrder.mode === 'viewOrder') {
                order = (
                    null
                );
            }
        } else {
            if (this.props.client.id) {
                order = toOrder;
            }
        }

        const dayTime = this.props.dayTime;

        const timeHours = []; const timePeriods = [];

        const timeHoursValues = {
            day: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
            night: [20, 21, 22, 23, '00', 1, 2, 3, 4, 5, 6, 7, 8]
        };

        const timePeriodsLabels = {
            day: ['Утро', '1 половина дня', 'Обед', '2 половина дня', 'Вечер'],
            night: ['Вечер', 'Поздний вечер', 'Ночь', 'Раннее утро', 'Утро']
        };

        _.each(timeHoursValues[dayTime], value => {
            timeHours.push(<div key={'timeHour' + value}>{ value }<sup>00</sup></div>);
        });

        _.each(timePeriodsLabels[dayTime], label => {
            timePeriods.push(<div key={'timePeriod' + label}>{ label }</div>);
        });

        let time; let pastChunksNumber = 0;
        if (currentDay.isSame(moment(), 'day')) {
            time = moment().format('HH:mm').split(':');
            pastChunksNumber = time[0] * 2 + parseInt(time[1] / 30);
        }

        // относительные значения для календаря
        const result = (
            <div
                id='orderfooter'
                className={'orderfooter' + (this.props.visibility === false ? ' orderfooter--hidden' : '')}
                onClick={this.showHideDp.bind(this)}
                style={{ minHeight: this.props.footerMinHeight }}
            >
                <div id='toOrder' className='footer-fixed-header'>
                    { order }
                </div>
                <div className={classes.resfooter + ' ' + (dayTime === 'night' ? classes.night : '')}>
                    <div id='footerheader' className={classes.headbgr + (order ? ' footer-fixed-header' : '')}>
                        <div className={classes.wrapper + ' ' + classes.first}>
                            <div className={classes.date}>
                                <DatePicker
                                    stringFormatting={date => { return (<div>{ moment(date).format('DD MMMM`YY') }<span>&nbsp;({ moment(date).format('dddd') })</span></div>); }}
                                    onChange={this.changeDate.bind(this)}
                                    selected={currentDay}
                                    className='white'
                                    align={['center', 'bottom']}
                                    activeDate
                                />
                            </div>
                            <div className={classes.dayTimes}>
                                <div
                                    className={dayTime === 'day' ? classes.active : null}
                                    onClick={this.changeDayTime.bind(this, 'day')}
                                >
                                    <div className={classes.day}>
                                        День (с 8:00 до 20:00)
                                    </div>
                                </div>
                                <div
                                    className={dayTime === 'night' ? classes.active : null}
                                    onClick={this.changeDayTime.bind(this, 'night')}
                                >
                                    <div className={classes.night}>
                                        Ночь (с 20:00 до 8:00)
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { _.map(_.filter(company.divisions, division => {
                        // не выводим подразделения без ресурсов
                        return (division.resources && division.resources.length > 0);
                    }), division => {
                        return (
                            <Division
                                key={division.id}
                                toOrder={this.props.toOrder}
                                company={company}
                                client={this.props.client}
                                schedules={this.props.schedules}
                                orders={orders}
                                tomorrowOrders={tomorrowOrders}
                                reserves={reserves}
                                tomorrowReserves={tomorrowReserves}
                                currentDay={currentDay}
                                dayTime={dayTime}
                                id={division.id}
                                takeOffChunk={this.takeOffChunk}
                                pastChunksNumber={pastChunksNumber}
                            />
                        );
                    }) }
                    <Preloader trigger={_.includes(this.props.fetching, true)} fixed='true' />
                </div>
                {
                    (this.props.toOrder &&
                    this.props.toOrder.mode === 'onOrder')
                        ? (
                            <RenderInBody
                                byId={this.props.toOrder.chunkElId} closeHandler={() => {}}
                            >
                                <OrderModal
                                    catalogues={this.props.company.catalogues}
                                    resource={_.find(this.props.company.resources, { angara_id: this.props.toOrder.resId })}
                                    date={this.props.toOrder.date}
                                />
                            </RenderInBody>
                        ) : ''
                }
            </div>
        );
        return result;
    }
}

export default ResourseFooter;
