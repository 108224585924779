import React from 'react';
import classes from './ViewReserveModal.module.scss';
import moment from 'moment';
import _ from 'lodash';
import { formatPhone, getElScreenOffset, addClass, removeClass, hasClass } from 'utils';
import WithClickOutside from 'extends/WithClickOutside';

class ViewReserveModal extends WithClickOutside {
    state = {
        confirmCancelReserve: false
    };

    componentDidMount() {
        super.componentDidMount();
        window.addEventListener('scroll', this.checkModalPosition);
        window.addEventListener('resize', this.checkModalPosition);
        this.checkModalPosition();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener('scroll', this.checkModalPosition);
        window.removeEventListener('resize', this.checkModalPosition);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.reserves.items.length !== this.props.reserves.items.length) {
            this.props.chooseToOrder(null);
            const currentDay = this.props.currentDay ? this.props.currentDay : moment().startOf('date');
            this.props.fetchSchedule(this.props.toOrder.resId, {
                range_start: currentDay.format('YYYY-MM-DD'),
                range_end: currentDay.format('YYYY-MM-DD')
            });
        }
    }

    checkModalPosition() {
        const el = document.getElementById('ordermodal'); let offsetX = 0; let offsetY = 0;
        if (hasClass(el, classes.rightOverflow)) {
            offsetX = -190;// смещение модального окна вниз при выходе за границы окна сверху
        }
        if (hasClass(el, classes.topOverflow)) {
            offsetY = 370;// смещение модального окна влево при выходе за границы окна справа
        }
        const overflow = getElScreenOffset(el, offsetX, offsetY);
        if (overflow.overflowTop) {
            addClass(el, classes.topOverflow);
        } else {
            removeClass(el, classes.topOverflow);
        }
        if (overflow.overflowRight) {
            addClass(el, classes.rightOverflow);
        } else {
            removeClass(el, classes.rightOverflow);
        }
    }

    outerClick() {
        this.props.chooseToOrder(null);
    }

    cancelReserve(id, e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.fetchCancelReserve(id);
    }

    render() {
        moment.locale('ru');
        const reserve = _.find(this.props.reserves.items, { id: this.props.reserveId });

        const companyTimeZone = _.get(this.props.company, 'angaraInfo.timezone', 0);
        if (!reserve) {
            return null;
        }
        // выясняем дату и продолжительность брони
        const reserveDate = reserve.scheduledTo;
        const reserveDuration = reserve.duration;
        const reserveDurationHours = parseInt(reserveDuration / 60);// сколько часов займет
        const reserveDurationMinutes = parseInt(reserveDuration % 60);// сколько минут займет
        // нормализуем ресурсы из портянки
        const resources = {};
        _.map(this.props.company.resources, resource => {
            resources[resource.angara_id] = resource;
        });
        // выясняем дату заказа по услугам в оном
        const reserveDateDay = moment(reserveDate).utcOffset(companyTimeZone).format('D MMMM'); const // дата чанка
            reserveDateTime = moment(reserveDate).utcOffset(companyTimeZone).format('HH:mm');// время чанка

        let cancelBlock;
        if (moment(reserveDate).isBefore()) {
            cancelBlock = '';
        } else {
            cancelBlock = this.state.confirmCancelReserve
                ? (
                    <div className={classes.cancelOrder + ' ' + classes.toConfirm}>Отменить запись?&nbsp;
                        <a href='/cancel' onClick={this.cancelReserve.bind(this, reserve.id)}>Да</a>&nbsp;
                        <a
                            href='/confirm-cancel' onClick={e => {
                                e.preventDefault();
                                this.setState({ confirmCancelReserve: false });
                            }}
                        >
                            Нет
                        </a>
                    </div>
                )
                : (
                    <div
                        className={classes.cancelOrder}
                        onClick={e => {
                            e.preventDefault();
                            this.setState({ confirmCancelReserve: true });
                        }}
                    >
                        Отменить запись
                    </div>
                );
        }

        return (
            <div id='ordermodal' className={classes.viewordermodal}>
                <div className={classes.header}>
                    { _.get(reserve, 'client.id', null)
                        ? (
                            <div>
                                <div className={classes.name} onClick={this.checkModalPosition}>{ reserve.clientName }</div>
                                <div className={classes.phone}>{ formatPhone(reserve.clientPhone) }</div>
                            </div>
                        )
                        : <div className={classes.reserve}>Резерв</div> }
                </div>
                <div className={classes.body}>
                    <div className={classes.dateBlock}>
                        <div className={classes.dates}>
                            <span className={classes.date}>{ reserveDateDay }</span>
                            <span>&nbsp;в&nbsp;</span>
                            <span className={classes.time}>{ reserveDateTime }</span>

                        </div>
                        <div className={classes.duration}>
                            Зарезервировано&nbsp;
                            <span className={classes.value}>
                                { reserveDurationHours ? reserveDurationHours + 'ч.' : '' }&nbsp;
                                { reserveDurationMinutes ? reserveDurationMinutes + 'мин.' : '' }
                            </span>
                        </div>
                    </div>
                    <div className={classes.serviceBlock}>
                        { _.map(_.split(reserve.descr, ','), service => {
                            return <div key={service} className={service}>{ service }</div>;
                        }) }
                    </div>
                    { cancelBlock }
                </div>
            </div>
        );
    }
}

export default ViewReserveModal;
