import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

// Comps
import { HELP_CSS } from 'utils';

class ServiceTemplate extends React.Component {
    state = {
        service: null
    };

    UNSAFE_componentWillMount() {
        if (this.props.serviceTemplate && !this.state.serviceTemplate) {
            this.setState({ serviceTemplate: this.props.serviceTemplate });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((nextProps.serviceTemplate && !this.state.serviceTemplate) || JSON.stringify(nextProps.serviceTemplate) !== JSON.stringify(this.state.serviceTemplate)) {
            this.setState({ serviceTemplate: nextProps.serviceTemplate });
        }
    }

    formatServiceDuration(templateServices, handleChange) {
        let duration; let hours; let minutes; const service = _.find(templateServices);

        if (templateServices.length === 0) {
            return null;
        }

        if (templateServices.length === 1) {
            duration = _.get(_.find(templateServices), 'averageDuration');
            hours = Math.floor(duration / 60);
            minutes = duration % 60;
            return (
                <div>
                    <input
                        type='number'
                        value={hours}
                        onKeyUp={(e) => {
                            if (e.keyCode === 13 && service.id) {
                                this.props.updateHandler(service);
                            }
                        }}
                        onBlur={() => {
                            if (service.id) {
                                this.props.updateHandler(service);
                            }
                        }}
                        onChange={(e) => {
                            if (handleChange) {
                                handleChange(e.target.value * 60 + minutes);
                            }
                        }}
                    />
                    :
                    <input
                        type='number'
                        value={minutes}
                        onKeyUp={(e) => {
                            if (e.keyCode === 13 && service.id) {
                                this.props.updateHandler(service);
                            }
                        }}
                        onChange={(e) => {
                            if (handleChange) {
                                handleChange(parseInt(e.target.value) + hours * 60);
                            }
                        }}
                        onBlur={() => {
                            if (service.id) {
                                this.props.updateHandler(service);
                            }
                        }}
                    />
                </div>
            );
        }

        if (templateServices.length > 1) {
            duration = _.get(_.find(templateServices), 'averageDuration');
            hours = Math.floor(duration / 60);
            minutes = duration - hours * 60;
            return (
                <div>
                    { hours }
&nbsp;:&nbsp;
                    { minutes }
                </div>
            );
        }
    }

    formatServicePrice(templateServices, handleChange) {
        let price; const service = _.find(templateServices);

        if (templateServices.length === 0) {
            return null;
        }

        if (templateServices.length === 1) {
            price = _.get(_.find(templateServices), 'minPrice');
            return (
                <div>
                    <input
                        type='number'
                        value={price}
                        onChange={(e) => {
                            if (handleChange) {
                                handleChange(e.target.value);
                            }
                        }}
                        onKeyUp={(e) => {
                            if (e.keyCode === 13 && service.id) {
                                this.props.updateHandler(service);
                            }
                        }}
                        onBlur={() => {
                            if (service.id) {
                                this.props.updateHandler(service);
                            }
                        }}
                    />
                    <div className={HELP_CSS.hlpInline}>руб.</div>
                </div>
            );
        }

        if (templateServices.length > 1) {
            price = _.get(_.find(templateServices), 'minPrice');
            return (
                <div>
                    { price }
                    <div className={HELP_CSS.hlpInline}>руб.</div>
                </div>
            );
        }
    }

    render() {
        const s = _.clone(this.state.serviceTemplate);
        const icon = _.get(s, 'image.url', false);

        s.serviceOptionTemplates = _.get(s, 'serviceOptionTemplates', []);
        return (
            <div
                className={`catalogueServices__item${s.isCustom ? ' custom' : ''}`}
                key={`service${s.id}`}
            >
                <div
                    className='name'
                    onClick={(e) => {
                        this.props.onClick(e);
                    }}
                >
                    <div className='icon'>
                        { icon ? <img src={icon} alt='' /> : null }
                    </div>
                    <div className='name__title'>{ s.name }</div>
                    &nbsp;
                    { s.services.length === 1
                        ? (
                            <div
                                className={HELP_CSS.hlpRemoveIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (this.props.removeHandler) {
                                        this.props.removeHandler(s.services);
                                    }
                                }}
                            />
                        )
                        : (
                            <div
                                className={HELP_CSS.hlpAddIcon}
                                onClick={() => {

                                }}
                            />
                        ) }
                    { s.isCustom ? <div className='name__isCustom'>своя услуга</div> : '' }
                </div>
                <div className='options'>
                    { s.serviceOptionTemplates && s.serviceOptionTemplates.length
                        ? s.services.length + '/' + _.reduce(
                            _.map(s.serviceOptionTemplates, s => {
                                return s.type === 'REFERENCE' ? _.get(s, 'serviceOptionReference.values.length', 1) : 1;
                            }),
                            (sum, n) => _.multiply(sum, n), 1)
                        : null }
                </div>
                <div className='duration'>
                    { this.formatServiceDuration(s.services, (duration) => {
                        const service = _.find(s.services);
                        service.averageDuration = duration;
                        s.services = [service];
                        this.setState({ serviceTemplate: s });
                    }) }
                </div>

                <div className='price'>
                    { /* s.minPrice
                     <div className={HELP_CSS.hlpInline + ' text'}>руб.</div> */ }
                    { this.formatServicePrice(s.services, (price) => {
                        const service = _.find(s.services);
                        service.minPrice = price;
                        s.services = [service];
                        this.setState({ serviceTemplate: s });
                    }) }
                </div>
            </div>
        );
    }
}

ServiceTemplate.propTypes = {
    removeHandler: PropTypes.func,
    updateHandler: PropTypes.func
};

export default ServiceTemplate;
