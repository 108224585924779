import { connect } from 'react-redux';
import { actions } from '../../modules/okk';
import { showHeader } from 'modules/hangar';

import Main from '../components/Main';

const mapStateToProps = (state) => ({
    mainView: state.okk.mainView,
    showQuestionModal: state.okk.showQuestionModal,
    questionModalData: state.okk.questionModalData,
    statistic: state.okk.statistic,
    fetching: state.okk.fetching,
    statisticDates: state.okk.statisticDates,
    styles: state.okk.styles
});

export default connect(mapStateToProps, Object.assign(actions, { showHeader }))(Main);
