import React from 'react';
import PropTypes from 'prop-types';
// comps
import DatePicker from 'components/DatePicker';
import RouteSelect from './../RouteSelect';

// libs
import { formatNumber, getParamFromLocation } from 'utils';
import moment from 'moment';
import _ from 'lodash';

class ByOrders extends React.Component {
    state = {
        rangeStart: moment().add(-1, 'M'),
        dateEnd: moment(),
        sortBy: 'bySumDesc',
        byOrders: []
    };

    componentDidMount() {
        const params = this.props.location.query;
        const { start, end } = getParamFromLocation(this.props.location);

        const rangeStart = start ? moment(start) : moment().add(-1, 'M');

        const rangeEnd = end ? moment(end) : moment();
        this.props.statisticByOrders({
            status: params && params.status,
            range_start: rangeStart.format('YYYY-MM-DD'),
            range_end: rangeEnd.format('YYYY-MM-DD')
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const params = this.props.location.query;
        const nextParams = prevProps.location.query;

        if (this.props.location !== prevProps.location || ((params && params.status) !== (nextParams && nextParams.status))) {
            const { start, end } = getParamFromLocation(this.props.location);
            this.props.statisticByOrders({
                status: nextParams && nextParams.status,
                range_start: start && moment(start).format('YYYY-MM-DD'),
                range_end: end && moment(end).format('YYYY-MM-DD')
            });
        }
    }

    render() {
        const { start, end } = getParamFromLocation(this.props.location);
        const rangeStart = start ? moment(start) : moment().add(-1, 'M');

        const rangeEnd = end ? moment(end) : moment();

        const statistics = _.get(this.props.statistics, 'byOrders.items', []);
        const orders = _.filter(statistics, i => {
            return i.status !== 'CANCELED';
        });

        const allCount = _.sum(_.map(orders, (item) => {
            return item.count;
        }));

        const allSum = _.sum(_.map(orders, (item) => {
            return item.sum;
        }));

        const partnerOrders = _.filter(statistics, i => {
            return i.origin === 'clubPartner' && i.status !== 'CANCELED';
        });

        const sumPartnerOrders = _.sum(_.map(partnerOrders, (item) => {
            return item.sum;
        }));

        const countPartnerOrders = _.sum(_.map(partnerOrders, (item) => {
            return item.count;
        }));

        const onlineOrders = _.filter(statistics, i => {
            return i.origin === 'clubMobile' && i.status !== 'CANCELED';
        });

        const allSumOnline = _.sum(_.map(onlineOrders, (item) => {
            return item.sum;
        }));

        const allCountOnline = _.sum(_.map(onlineOrders, (item) => {
            return item.count;
        }));

        const executedOrders = _.filter(statistics, { status: 'EXECUTED' });

        const countExecutedOrders = _.sum(_.map(executedOrders, (item) => {
            return item.count;
        }));

        const sumExecutedOrders = _.sum(_.map(executedOrders, (item) => {
            return item.sum;
        }));

        const canceledOrders = _.filter(statistics, { status: 'CANCELED' });

        const countCanceledOrders = _.sum(_.map(canceledOrders, (item) => {
            return item.count;
        }));

        const sumCanceledOrders = _.sum(_.map(canceledOrders, (item) => {
            return item.sum;
        }))
            ;
        return (
            <div className='statistics-block statistics-block--service'>
                <div className='statistics-block__h1'>
                    <div>Отчет по</div>
                    <RouteSelect value='orders' />
                </div>
                <div className='date-filter'>
                    <div>за период с</div>
                    <DatePicker
                        stringFormatting={(date) => {
                            return moment(date).locale('ru').format('DD MMMM YY');
                        }}
                        selected={rangeStart}
                        className='inline orange'
                        align={['center', 'bottom']}
                        onChange={(date) => {
                            this.props.history.push('/statistics/orders?range_start=' + moment(date).format('YYYY-MM-DD') + '&range_end=' + moment(rangeEnd).format('YYYY-MM-DD'));
                        }}
                        locale='ru'
                    />
                    <div>по</div>
                    <DatePicker
                        stringFormatting={(date) => {
                            return moment(date).locale('ru').format('DD MMMM YY');
                        }}
                        selected={rangeEnd}
                        className='inline orange'
                        align={['center', 'bottom']}
                        onChange={(date) => {
                            this.props.history.push('/statistics/orders?range_start=' + moment(rangeStart).format('YYYY-MM-DD') + '&range_end=' + moment(date).format('YYYY-MM-DD'));
                        }}
                        locale='ru'
                    />
                </div>
                <div className='statistics-block__table'>
                    <div className='statistics-block__table__headers'>
                        <div>Источник</div>
                        <div>Количество заказов</div>
                        <div>Сумма</div>
                    </div>
                    <div className='statistics-block__table__item statistics-block__table__item--order'>
                        <div className='stat-info'>
                            <div>Заказов через онлайн запись</div>
                            <div>{ allCountOnline }</div>
                            <div>{ formatNumber(allSumOnline) } руб.</div>
                        </div>
                        <div className='stat-info'>
                            <div>Заказов через партнерское приложение</div>
                            <div>{ countPartnerOrders }</div>
                            <div>{ formatNumber(sumPartnerOrders) } руб.</div>
                        </div>
                        <div className='stat-info'>
                            <div>Закрытых заказов</div>
                            <div>{ countExecutedOrders }</div>
                            <div>{ formatNumber(sumExecutedOrders) } руб.</div>
                        </div>
                        <div className='statistics-block__table__summary'>
                            <div>Всего заказов</div>
                            <div>{ allCount }</div>
                            <div>{ formatNumber(allSum) } руб.</div>
                        </div>
                        <div className='stat-info'>
                            <div>Отмененных заказов</div>
                            <div>{ countCanceledOrders }</div>
                            <div>{ formatNumber(sumCanceledOrders) } руб.</div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

ByOrders.contextTypes = {
    router: PropTypes.object
};

export default ByOrders;
