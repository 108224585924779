import { connect } from 'react-redux';
import { actions } from 'modules/clients';

import Client from './Client';

const mapStateToProps = (state) => ({
    company: state.company.company,
    clients: state.clients.clientsBySearch,
    fetching: state.clients.fetching,
    backState: state.hangar.backState
});

export default connect(mapStateToProps, actions)(Client);
