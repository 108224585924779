/**
 * Created by user on 26.05.2016.
 */

import { connect } from 'react-redux';
import { actions } from 'modules/orders';

import ContextModal from './ContextModal';

const mapStateToProps = (state) => ({
    toOrder: state.orders.toOrder
});

export default connect(mapStateToProps, Object.assign(actions, { }))(ContextModal);
