/**
 * Created by user on 17.05.2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import RenderInBody from '../RenderInBody';
import cx from 'classnames';
import _ from 'lodash';
require('./select.scss');

class Select extends React.Component {
    constructor(props) {
        super(props);
        this.toggleOpen = this.toggleOpen.bind(this);
        this.close = this.close.bind(this);
        this.changeCustomValue = this.changeCustomValue.bind(this);
        this.switchCustom = this.switchCustom.bind(this);
        this.changeSearchValue = this.changeSearchValue.bind(this);
    }

    state = {
        open: false,
        outerClose: false,
        placeholder: 'Выберите значение',
        randomId: 'select' + Math.floor(Math.random() * 9000 + 1000),
        addCustom: false,
        customValue: '',
        searchValue: ''
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.open && !prevState.open) {
            const el = document.getElementById('root');
            el.addEventListener('click', this.close);
        }
        if (!this.state.open && prevState.open) {
            const el = document.getElementById('root');
            el.removeEventListener('click', this.close);
        }
    }

    componentWillUnmount() {
        const el = document.getElementById('root');
        el.removeEventListener('click', this.close);
    }

    close() {
        this.setState({ open: false });
    }

    open() {
        this.setState({ open: true, searchValue: '' });
    }

    changeValue(selected) {
        if (selected.type === 'item') {
            this.props.onChange(selected);
            this.setState({ open: false });
        }
    }

    changeCustomValue(e) {
        this.setState({ customValue: e.target.value });
        const value = { type: 'item', label: e.target.value, value: e.target.value };
        this.props.onChange(value);
    }

    toggleOpen() {
        if (this.state) {
            if (!this.props.disabled && this.props.options && this.props.options.length > 0) {
                this.setState({ open: !this.state.open, searchValue: '' });
            }
        }
    }

    renderTrack({ style, ...props }) {
        const trackStyle = {};
        return (
            <div
                className='scrollTrack'
                style={{ ...style, ...trackStyle }}
                {...props}
            />
        );
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {};
        return (
            <div
                className='scrollThumb'
                style={{ ...style, ...thumbStyle }}
                {...props}
            />
        );
    }

    switchCustom() {
        this.setState({ addCustom: !this.state.addCustom });
    }

    changeSearchValue(e) {
        this.setState({ searchValue: e.target.value });
    }

    render() {
        let selectedValue, options;
        if (_.isObjectLike(this.props.value)) {
            selectedValue = _.find(this.props.options, option => {
                return _.isEqual(option.value, this.props.value);
            });
        } else {
            selectedValue = _.find(this.props.options, option => {
                return option.value === this.props.value;
            });
        }
        if (!selectedValue) {
            selectedValue = { value: this.props.value, label: this.props.value, type: 'item' };
        }
        if (this.state.searchValue.length >= 2) {
            options = _.filter(this.props.options, item => {
                return item.type !== 'item' || item.label.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1;
            });
        } else {
            options = this.props.options;
        }
        let addCustom;
        if (this.props.withCustom) {
            addCustom = (
                <div className='selectComponentCustom' onClick={this.switchCustom}>
                    { this.props.customText }
                </div>
            );
        }
        if (this.state.addCustom) {
            return (
                <div className='clubSelect selectReason'>
                    <div className='control'>
                        <input
                            type='text'
                            name='customReason'
                            value={this.state.customValue}
                            onChange={this.changeCustomValue}
                            onKeyUp={e => {
                                if (e.key === 'Escape' || e.key === 'Enter') {
                                    this.setState({ addCustom: false });
                                }
                            }}
                        />
                        <div className='arrow' onClick={this.switchCustom} />
                    </div>
                </div>
            );
        }
        return (
            <div
                className={cx({
                    clubselect: true,
                    disabled: this.props.disabled,
                    [this.props.className]: this.props.className,
                    [this.props.type]: true,
                    opened: this.state.open,
                    wrong: this.props.wrong

                })}
            >
                <RenderInBody
                    byId={this.state.randomId}
                    align={['center', 'bottom']}
                    hidden={!this.state.open}
                    closeHandler={this.close}
                >
                    <div className={cx({ selectTriangle: true, toTop: this.props.type.split(' ').indexOf('toTop') !== -1 })} />
                    <div
                        className={cx({
                            clubselect__outerMenu: true,
                            outerMenu: true,
                            [this.props.className]: true,
                            [this.props.type]: true,
                            closed: !this.state.open,
                            withIcon: this.props.icon,
                            wrong: this.props.wrong
                        })}
                    >
                        <Scrollbars
                            thumbSize={16}
                            autoHeight
                            autoHide={false}
                            renderTrackVertical={this.renderTrack}
                            renderThumbVertical={this.renderThumb}
                        >
                            { this.props.search
                                ? <input type='text' value={this.state.searchValue} onChange={this.changeSearchValue} />
                                : null }
                            { _.map(options, (option, key) => {
                                if (option.type !== 'item' && (options.length <= key + 1 || options[key + 1].type !== 'item')) {
                                    return false;
                                }
                                return (
                                    <div
                                        key={JSON.stringify(option.value)}
                                        className={option.type + (option.level ? (' level' + option.level) : '')}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            this.changeValue(option);
                                        }}
                                    >
                                        { option.label }
                                    </div>
                                );
                            }) }
                        </Scrollbars>
                        { addCustom }
                    </div>
                </RenderInBody>
                { this.state.open
                    ? (
                        <div key='opened' className='control' onClick={this.toggleOpen}>
                            {
                                (this.props.value || this.props.value === 0)
                                    ? (
                                        <div className='value'>{ selectedValue.label }</div>
                                    )
                                    : (
                                        <div className='placeHolder'>
                                            { this.props.placeholder
                                                ? this.props.placeholder
                                                : this.state.placeholder }
                                        </div>
                                    )
                            }
                            <div className={cx({ arrow: true, icon: this.props.icon })} id={this.state.randomId} />
                        </div>
                    )
                    : (
                        <div key='closed' className='control' onClick={this.toggleOpen}>
                            {
                                (this.props.value || this.props.value === 0)
                                    ? (
                                        <div className='value'>{ selectedValue.label }</div>
                                    )
                                    : (
                                        <div className='placeHolder'>
                                            { this.props.placeholder
                                                ? this.props.placeholder
                                                : this.state.placeholder }
                                        </div>
                                    )
                            }
                            <div className={cx({ arrow: true, icon: this.props.icon })} id={this.state.randomId} />
                        </div>
                    ) }
                {
                    _.isEmpty(this.props.error)
                        ? null
                        : (
                            <div className='error'>
                                <div className='wrapper'>
                                    { this.props.error }
                                </div>
                            </div>
                        )
                }
            </div>
        );
    }
}

Select.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    options: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired, // byLink - ссылка, bySelect - список
    onChange: PropTypes.func.isRequired
};

export default Select;
