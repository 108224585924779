/**
 * Created by user on 19.07.16.
 */
import React from 'react';
import classes from './BoardComputer.module.scss';
import _ from 'lodash';
import moment from 'moment';
import CyclicNotificationModal from '../../../../ModalWindows/CyclicNotification';

class BoardComputer extends React.Component {
    UNSAFE_componentWillMount() {
        if (this.props.businessType === 1 && !this.props.cars) {
            this.props.fetchClientCars(this.props.client.id);
        }
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (this.props.businessType === 1 && (this.props.client.id !== nextProps.client.id)) {
            this.props.fetchClientCars(nextProps.client.id);
        }
    }

    cyclicCarNotificationShow(notification, carId) {
        notification.car_id = carId;
        this.props.cyclicNotificationShow(notification);
    }

    render() {
        const newCyclicNotification = {
            client_id: this.props.client.id,
            last_notified_at: null,
            next_notified_at: null,
            period: {
                length: 30,
                title: 'месяц'
            },
            repeats_during_period: '2',
            serviceTemplate: {
                name: null,
                id: null
            },
            text: ''
        };

        const cars = this.props.cars;

        const services = []; const carServices = {};
        _.map(cars, car => {
            carServices[car.id] = [];
        });

        _.map(this.props.client.notifications, item => {
            if (cars && item.car_id) {
                if (carServices[item.car_id]) {
                    carServices[item.car_id].push(
                        <div
                            className={classes.row}
                            key={'service' + item.id}
                            onClick={this.props.cyclicNotificationShow.bind(this, item)}
                        >
                            <div className={classes.value} key={'name' + item.id}>
                                { item.serviceTemplate.name }
                            </div>
                            <div className={classes.value} key={'date' + item.id}>
                                { !item.last_notified_at
                                    ? 'отсутствует'
                                    : moment(item.last_notified_at).format('DD.MM.YY') } /
                                <div
                                    className={classes.orange}
                                >
                                    { '  ' + moment(item.next_notified_at).format('DD.MM.YY') }
                                </div>
                            </div>
                        </div>
                    );
                }
            } else {
                services.push(
                    <div
                        className={classes.row}
                        key={'service' + item.id}
                        onClick={this.props.cyclicNotificationShow.bind(this, item)}
                    >
                        <div className={classes.value} key={'name' + item.id}>{ item.serviceTemplate.name }</div>
                        <div className={classes.value} key={'date' + item.id}>
                            { !item.last_notified_at
                                ? 'отсутствует'
                                : moment(item.last_notified_at).format('DD.MM.YY') }
                            /
                            <div className={classes.orange}>
                                { '  ' + moment(item.next_notified_at).format('DD.MM.YY') }
                            </div>
                        </div>
                    </div>
                );
            }
        });

        return (
            <div>
                <div className={classes.addService}>
                    <div className={classes.addServiceText}>Услуги привязанные к клиенту</div>
                    <div className={classes.addServiceButton} onClick={this.props.cyclicNotificationShow.bind(this, newCyclicNotification)}>
                        Добавить
                    </div>
                    { services }
                </div>
                { _.map(cars, item => {
                    return (
                        <div className={classes.addService} key={item.id}>
                            <div className={classes.addServiceText}>Услуги привязанные к авто { item.model.title }'{ `${item.year}`.slice(-2) }</div>
                            <div className={classes.addServiceButton} onClick={this.cyclicCarNotificationShow.bind(this, newCyclicNotification, item.id)}>
                                Добавить
                            </div>
                            { carServices[item.id] }
                        </div>
                    );
                }) }
                { this.props.shadeMenuStatus === 'cyclicNotification'
                    ? <CyclicNotificationModal />
                    : null }
            </div>
        );
    }
}

export default BoardComputer;
