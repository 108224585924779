import { connect } from 'react-redux';
import { actions } from 'modules/clients';
import { ordering, chooseToOrder } from 'modules/orders';
import { showHeader } from 'modules/hangar';
import { showHideDp } from 'modules/company';

import Calendar from './components/Calendar';

const mapStateToProps = (state) => (
    {
        clients: state.clients.clients, // клиенты
        clientsListVisible: state.clients.clientsListVisible, // переключатель для разных списков клиентов (история поиска и неактивированные)
        inactiveClients: state.clients.inactiveClients, // неактивированные клиенты
        clientsSearch: state.clients.clientsSearch, // строка поиска в списке клиентов
        searchPassed: state.clients.searchPassed, // в зависимости от того, дал ли результаты поиск в списке клиентов, показывается блок с текстом о формате поиска или с текстом о неудачных результатах поиска
        client: state.clients.client, // выбранный клиент
        clientCardState: state.clients.clientCardState, // первоначальное состояние карточки клиента, открыто/закрыто
        avatar: state.clients.avatar, // портрет клиента ежели таковой имеется, fetching оного внутри
        changedClient: state.clients.changedClient, // используется для того, чтобы, в случае изменения текстового поля клиента и отмены редактирования, сам клиент не менялся
        editClientChange: state.clients.editClientChange, // отвечает за отображение селект-менюшек и текстовых инпутов (редактирование клиента)
        newClientPhone: state.clients.newClientPhone,
        newClientNick: state.clients.newClientNick,
        newClientComment: state.clients.newClientComment,
        errorMessage: state.clients.errorMessage,
        shaded: state.clients.shaded,
        orderMode: state.orders.orderMode,
        menuStatus: state.clients.menuStatus, // переключатель для карточки клиента (основная информация, личная информация, бортовой компьютер, история посещений)
        privateMenuStatus: state.clients.privateMenuStatus, // переключатель для кнопок в личной информаци карточки клиента (личная информация, автомобиль, данные страхования)
        shadeMenuStatus: state.clients.shadeMenuStatus, // переключатель, отвечающий за отображение менюшек, при которых остальной экран затемняется(создание и активация клиента, редактирование и создание циклических уведомлений)
        cyclicNotification: state.clients.cyclicNotification, // циклическое уведомление, с которым работаем в данный момент (создание и редактирование)
        services: state.company.company.services, // услуги компании
        catalogues: state.company.company.catalogues, // каталоги компании
        errorMenu: state.clients.errorMenu,
        fetching: state.clients.fetching,
        notifications: state.company.company.notifications,
        partner: state.account.partner,
        clientOrdersAndCalls: state.clients.clientOrdersAndCalls,
        changedCars: state.clients.changedCars
    }
);

export default connect(mapStateToProps, Object.assign(actions, { chooseToOrder, ordering, showHeader, showHideDp }))(Calendar);
