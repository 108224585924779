import { connect } from 'react-redux';
import { actions } from '../../../../../modules/calls';

import Statistic from './Statistic';

const mapStateToProps = (state) => ({
    call: state.calls.call,
    calls: state.calls.calls,
    statistics: state.calls.statistics,
    anchorStatistics: state.calls.anchorStatistics,
    employees: state.company.employees
});

export default connect(mapStateToProps, actions)(Statistic);
