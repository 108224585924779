import { connect } from 'react-redux';
import { actions } from 'modules/clients';

import ClientSearch from './ClientSearch';

const mapStateToProps = (state) => ({
    clients: state.clients.clients
});

export default connect(mapStateToProps, actions)(ClientSearch);
