import { connect } from 'react-redux';
import { changeCompany, fetchLogo, deleteCompanyLogo } from 'modules/company';
import { actions } from '../../modules/settings';

import Cpro from './Cpro';

const mapStateToProps = state => ({
    company: state.company.company,
    logo: state.company.logo,
    fetching: state.company.fetching,
    _workTime: state.settings.workTime
});

export default connect(mapStateToProps, Object.assign(actions, { changeCompany, fetchLogo, deleteCompanyLogo }))(Cpro);
