import { connect } from 'react-redux';
import { actions } from 'modules/clients';

import ClientsList from './ClientsList';

const mapStateToProps = (state) => ({
    client: state.clients.client,
    clients: state.clients.clientsBySearch,
    clientsSearch: state.clients.clientsSearch
});

export default connect(mapStateToProps, actions)(ClientsList);
