import React from 'react';
import classes from '../OrderFooter.module.scss';
import moment from 'moment';
import _ from 'lodash';
import ViewOrderModal from '../ViewOrderModal';
import ViewReserveModal from '../ViewReserveModal';
import EditOrderModal from '../EditOrderModal';
import ContextModal from '../ContextModal';
import RenderInBody from 'components/RenderInBody';

import cx from 'classnames';

class Chunk extends React.Component {
    componentDidMount() {

    }

    componentWillUnmount() {

    }

    componentDidUpdate() {

    }

    shouldComponentUpdate(nextProps) {
        // при изменении занятости чанка

        if (this.props.schedule[this.props.i] !== nextProps.schedule[this.props.i]) {
            return true;
        }
        // при кликах в режиме редактировании расписания
        if (_.get(this.props.toOrder, 'mode') === 'takeOff' && _.get(nextProps, 'toOrder.mode') === 'takeOff') {
            // проверяем необходимости обновления если учавствуют в отключении
            const inTakeOff = this.inTakeOff(this.props);

            const nextInTakeOff = this.inTakeOff(nextProps);
            if (!_.isEqual(inTakeOff, nextInTakeOff)) {
                return true;
            }
            // определяем необходимость обновления будучи станучи последним чанком
            if (!_.isEqual(this.props.toOrder.lastChunk, nextProps.toOrder.lastChunk)) {
                const compare = {
                    divId: this.props.division.id,
                    resId: this.props.resource.id,
                    date: moment(this.props.currentDay).format('YYYY-MM-DD'),
                    chNumber: this.props.i
                };
                if (_.isEqual(compare, this.props.toOrder.lastChunk) || _.isEqual(compare, nextProps.toOrder.lastChunk)) {
                    return true;
                }
            }
        }
        // при отключении режима редактирования расписания
        if (_.get(this.props.toOrder, 'mode') === 'takeOff' && _.get(nextProps, 'toOrder.mode') !== 'takeOff') {
            const inTakeOff = this.inTakeOff(this.props);
            if (inTakeOff.include !== -1 || inTakeOff.exclude !== -1) {
                return true;
            }
        }

        // при всяческом упоминании оного чанка в функционале
        if (_.get(this.props, 'toOrder.mode', null) || _.get(nextProps, 'toOrder.mode', null)) {
            const chunkId = this.props.resource.angara_id + '-' + this.props.division.id + '-' + this.props.i;

            const toOrderChunkId = nextProps.toOrder ? nextProps.toOrder.resId + '-' + nextProps.toOrder.divisionId + '-' + (nextProps.toOrder.chunk) : '';

            const prevToOrderChunkId = this.props.toOrder ? this.props.toOrder.resId + '-' + this.props.toOrder.divisionId + '-' + this.props.toOrder.chunk : '';
            if (chunkId === prevToOrderChunkId || chunkId === toOrderChunkId) {
                return true;
            }
        }

        /*        //при клике для заказа
         if (_.get(nextProps, 'toOrder.mode') == 'onOrder') {
         let chunkId = this.props.resource.angara_id + '-' + this.props.division.id + '-' + this.props.i,
         toOrderChunkId = nextProps.toOrder ? nextProps.toOrder.resId + '-' + nextProps.toOrder.divisionId + '-' + (nextProps.toOrder.chunk) : '',
         prevToOrderChunkId = this.props.toOrder ? this.props.toOrder.resId + '-' + this.props.toOrder.divisionId + '-' + this.props.toOrder.chunk : '';
         if (chunkId == prevToOrderChunkId || chunkId == toOrderChunkId) {
         return true;
         }
         }
         //при отключенинии модального окна заказа
         if (_.get(this.props, 'toOrder.mode') == 'onOrder' && _.get(nextProps, 'toOrder.mode') != 'onOrder') {
         let chunkId = this.props.resource.angara_id + '-' + this.props.division.id + '-' + this.props.i,
         prevToOrderChunkId = this.props.toOrder ? this.props.toOrder.resId + '-' + this.props.toOrder.divisionId + '-' + this.props.toOrder.chunk : '';
         if (chunkId == prevToOrderChunkId) {
         return true;
         }
         } */
        // а ежели измененные заказы и брони
        if (!_.isEqual(this.props.orders, nextProps.orders) || !_.isEqual(this.props.reserves, nextProps.reserves) || !_.isEqual(this.props.schedules, nextProps.schedules)) {
            return true;
        }
        if (!_.isEqual(nextProps.toOrder, this.props.toOrder)) {
            const chunkId = this.props.resource.angara_id + '-' + this.props.division.id + '-' + this.props.i;

            const toOrderChunkId = nextProps.toOrder ? nextProps.toOrder.resId + '-' + nextProps.toOrder.divisionId + '-' + (nextProps.toOrder.chunk) : '';

            const prevToOrderChunkId = this.props.toOrder ? this.props.toOrder.resId + '-' + this.props.toOrder.divisionId + '-' + this.props.toOrder.chunk : '';
            // если тот bkb другой иkb занят был редактируемым заказом
            if (
                chunkId === prevToOrderChunkId ||
                chunkId === toOrderChunkId ||
                // проверяем на вхождение чанка в редактируемый заказ
                _.get(nextProps.orders, this.props.resource.angara_id + '.chunks.' + this.props.i, false) === _.get(this.props.toOrder, 'orderId', null) ||
                _.get(nextProps.orders, this.props.resource.angara_id + '.chunks.' + this.props.i, false) === _.get(nextProps.toOrder, 'orderId', null)
            ) {
                return true;
            }
        }
        return false;
    }

    inTakeOff(props) {
        const excludes = _.get(props.toOrder, 'chunks.' + props.resource.id + '.' + moment(props.currentDay).format('YYYY-MM-DD') + '.exclude', []);

        const includes = _.get(props.toOrder, 'chunks.' + props.resource.id + '.' + moment(props.currentDay).format('YYYY-MM-DD') + '.include', []);

        const includeKey = _.findIndex(includes, item => {
            return parseInt(item) === props.i;
        });

        const excludeKey = _.findIndex(excludes, item => {
            return parseInt(item) === props.i;
        });
        return { includeKey, excludeKey };
    }

    clickOnChunk(chunkElId, divisionId, resourceId, resourceAngaraId, chNumber, orders, reserves, currentDay, reserveClient, e) {
        if (e) {
            e.preventDefault();
        }
        this.props.setOrderDay(currentDay);
        // отрабатываем исходные данные
        const schedule = this.props.schedule;

        const chankDate = moment(currentDay).add(chNumber / 2, 'hours');

        const orderOnChunk = _.get(orders, resourceAngaraId + '.chunks.' + chNumber, false);

        const reserveOnChunk = _.get(reserves, resourceAngaraId + '.chunks.' + chNumber, false);

        // отрабатываем клик при включении отключении записи
        if (_.get(this.props.toOrder, 'mode') === 'takeOff') {
            // только если чанк не занят бронью/заказом
            if (!orderOnChunk && !reserveOnChunk) {
                this.props.takeOffChunk(divisionId, resourceId, chNumber);
            }
        } else {
            // отрабатываем клик по занятому заказом чанку
            if (orderOnChunk && _.get(this.props.toOrder, 'status') !== 'chooseToShift' && _.get(this.props.toOrder, 'status') !== 'shift') {
                this.props.chooseToOrder({
                    mode: 'viewOrder',
                    chunk: chNumber,
                    orderId: orderOnChunk,
                    divisionId: divisionId,
                    resId: resourceAngaraId,
                    date: chankDate
                });
            }
            // отрабатываем клик по занятому бронью чанку
            if (reserveOnChunk && _.get(this.props.toOrder, 'status') !== 'chooseToShift' && _.get(this.props.toOrder, 'status') !== 'shift') {
                this.props.chooseToOrder({
                    mode: 'viewReserve',
                    chunk: chNumber,
                    reserveId: reserveOnChunk,
                    divisionId: divisionId,
                    resId: resourceAngaraId,
                    date: chankDate
                });
            }
        }
        // клик для переноса на другое время
        if (this.props.toOrder &&
            this.props.toOrder.mode === 'editOrder' &&
            (this.props.toOrder.status === 'chooseToShift' || this.props.toOrder.status === 'shift') &&
            resourceAngaraId === this.props.toOrder.resId &&
            (schedule[chNumber] === '1' || orderOnChunk)) {
            // вычисления для режима переноса записи клиента
            // выясняем первый и последний чанк переносимого заказа
            const order = _.find(orders[resourceAngaraId].orders, { id: this.props.toOrder.orderId });
            const newfirstChunk = chNumber;

            const newlastChunk = newfirstChunk + order.duration / ((60 * 0.5));

            const newOrdersChunks = _.range(newfirstChunk, newlastChunk);

            const ordersChunks = _.filter(_.map(orders[resourceAngaraId].chunks, (order, chunk) => {
                if (order === this.props.toOrder.orderId) {
                    return parseInt(chunk);
                }
            }));
            const allowToShift = _.every(newOrdersChunks, chunk => {
                return schedule[chunk] === '1' || ordersChunks.indexOf(chunk) !== -1;
            });

            if (allowToShift) {
                this.props.chooseToOrder({
                    status: 'shift',
                    divisionId: divisionId,
                    chunk: chNumber,
                    date: chankDate
                });
            }
        }

        // отрабатываем клик по чанку с целью записать клиента
        if (!moment(chankDate).isBefore(moment()) && (_.get(this.props.toOrder, 'mode') === 'onOrder' || _.get(this.props.toOrder, 'mode', '') === '') && _.get(this.props.toOrder, 'status', '') !== 'chooseToShift' && _.get(this.props.toOrder, 'status', '') !== 'shift' && schedule[chNumber] === '1' && !orderOnChunk && !reserveOnChunk) {
            this.props.chooseToOrder({
                mode: 'onOrder',
                chunk: chNumber,
                chunkElId: chunkElId,
                divisionId: divisionId,
                resId: resourceAngaraId,
                date: chankDate,
                duration: 30,
                reserveClient: reserveClient
            });
        }
    }

    // функция для однозначной записи при клике на контекстном меню
    clickOnChunkToOrder(chunkElId, divisionId, resourceId, resourceAngaraId, chNumber, orders, reserves, currentDay, reserveClient, e) {
        if (e) {
            e.preventDefault();
        }
        this.props.setOrderDay(currentDay);
        // отрабатываем исходные данные
        const schedule = this.props.schedule;

        const chankDate = moment(currentDay).add(chNumber / 2, 'hours');

        const orderOnChunk = _.get(orders, resourceAngaraId + '.chunks.' + chNumber, false);

        const reserveOnChunk = _.get(reserves, resourceAngaraId + '.chunks.' + chNumber, false);
        // отрабатываем клик по чанку с целью записать клиента
        if ((_.get(this.props.toOrder, 'mode') === 'onOrder' || _.get(this.props.toOrder, 'mode', '') === '') && _.get(this.props.toOrder, 'status', '') !== 'chooseToShift' && schedule[chNumber] === '1' && !orderOnChunk && !reserveOnChunk) {
            this.props.chooseToOrder({
                mode: 'onOrder',
                chunk: chNumber,
                chunkElId: chunkElId,
                divisionId: divisionId,
                resId: resourceAngaraId,
                date: chankDate,
                duration: 30,
                reserveClient: reserveClient
            });
        }
    }

    render() {
        const chunkSize = 0.5;// размер чанка по отношению к часу времени
        const currentDay = this.props.currentDay ? this.props.currentDay : moment().utcOffset(600).startOf('date');// день на который грузится расписание
        const orders = this.props.orders;

        const reserves = this.props.reserves;

        const resource = this.props.resource;

        const i = this.props.i;

        const evenOddOrder = this.props.evenOddOrder;

        const evenOddReserve = this.props.evenOddReserve;

        let chunkClass;
        const schKey = currentDay.format('YYYY-MM-DD');
        const schedule = _.get(_.find(this.props.schedules, { id: resource.id }), schKey, '');
        let firstChunkService, lastChunkService;

        // вычисления для режима записи клиента
        if (this.props.toOrder && this.props.toOrder.mode === 'onOrder' && this.props.toOrder.resId) {
            const services = _.get(this.props.services, this.props.toOrder.resId + '.' + this.props.toOrder.catalogueId, []);// загруженные услуги по каталогу
            const oService = this.props.toOrder.service ? _.find(services.services, service => {
                return parseInt(this.props.toOrder.service) === service.id;
            }) : null;// выбранная услуга

            // выясняем первый и последний чанк выбранной услуги
            firstChunkService = (parseInt(this.props.toOrder.date.format('H') * 60 + parseInt(this.props.toOrder.date.format('m'))) / (60 * chunkSize));
            if (this.props.toOrder.date && oService) {
                const serviceFinishDate = moment(this.props.toOrder.date).add(this.props.toOrder.averageDuration, 'm');
                lastChunkService = (parseInt(serviceFinishDate.format('H') * 60 + parseInt(serviceFinishDate.format('m'))) / (60 * chunkSize)) - 1;
            }
        }

        // вычисления для режима переноса записи клиента
        if (this.props.toOrder && this.props.toOrder.mode === 'editOrder' && this.props.toOrder.status !== 'edit') {
            // выясняем первый и последний чанк переносимого заказа
            firstChunkService = this.props.toOrder.newChunk ? this.props.toOrder.newChunk : _.findKey(orders[this.props.toOrder.resId].chunks, this.props.toOrder.orderId);
            lastChunkService = firstChunkService + this.props.toOrder.duration / ((60 * chunkSize)) - 1;
        }

        if (
            (orders[this.props.resource.angara_id] && orders[this.props.resource.angara_id].chunks[i]) ||
            (reserves[this.props.resource.angara_id] && reserves[this.props.resource.angara_id].chunks[i])

        ) {
            // заказ или бронь
            // заказ
            if (orders[this.props.resource.angara_id] && orders[this.props.resource.angara_id].chunks[i]) {
                // здесь есть заказ
                // определяем не выбранный ли
                if (this.props.toOrder && this.props.toOrder.orderId === orders[this.props.resource.angara_id].chunks[i] && this.props.division.id === this.props.toOrder.divisionId) {
                    chunkClass = classes.active;
                } else {
                    const clientId = orders[this.props.resource.angara_id].orders[orders[this.props.resource.angara_id].chunks[i]].client.id;
                    chunkClass = cx({
                        [classes.orderOn]: true,
                        [classes.odd]: evenOddOrder.counter % 2 === 0,
                        [classes.activeClient]: this.props.client.companies && this.props.client.companies[0].pivot.angara_client_id === clientId
                    });
                }
            }
            // бронь
            if (reserves[resource.angara_id] && reserves[resource.angara_id].chunks[i]) {
                // здесь есть заказ
                // определяем не выбранный ли
                if (this.props.toOrder && this.props.toOrder.reserveId === reserves[resource.angara_id].chunks[i] && this.props.division.id === this.props.toOrder.divisionId) {
                    chunkClass = classes.active;
                } else {
                    const clientId = _.get(reserves[resource.angara_id].reserves[reserves[resource.angara_id].chunks[i]], 'client.id', 0);
                    chunkClass = cx({
                        [classes.reserveOn]: true,
                        [classes.odd]: evenOddReserve.counter % 2 === 0,
                        [classes.activeClient]: this.props.client.companies && this.props.client.companies[0].pivot.angara_client_id === clientId
                    });
                }
            }
        } else {
            if (_.isEmpty(schedule) || schedule[i] === '0') {
                if (moment(this.props.chunkDate)) { chunkClass = classes.unactive; }
                // значица уже прошедшее или нерабочее время время
            } else {
                chunkClass = (this.props.toOrder && resource.angara_id === this.props.toOrder.resId && this.props.division.id === this.props.toOrder.divisionId &&
                i >= firstChunkService && i <= lastChunkService)
                    ? classes.active // значица сюда будем записывать
                    : '';
                if (moment(this.props.chunkDate).isBefore()) {
                    chunkClass = classes.inpast;
                }
            }
        }

        // определяем класс когда запись залазит на недоступный чанк
        if (this.props.toOrder && this.props.toOrder.mode === 'onOrder' && schedule[i] === '0' &&
            resource.angara_id === this.props.toOrder.resId &&
            i >= firstChunkService && i <= lastChunkService) {
            chunkClass = classes.alert;
        }

        // определяем класс чанка откуда переносим заказ
        if (this.props.toOrder && this.props.toOrder.mode === 'editOrder' && i === this.props.toOrder.chunk &&
            this.props.division.id === this.props.toOrder.divisionId &&
            this.props.toOrder.status !== 'edit' && resource.angara_id === this.props.toOrder.resId) {
            chunkClass = classes.replaceOrderFromHere;
        }
        // определяем класс выбранных чанков времени для переноса
        if (this.props.toOrder && this.props.toOrder.mode === 'editOrder' && this.props.toOrder.status !== 'edit' &&
            this.props.division.id === this.props.toOrder.divisionId &&
            resource.angara_id === this.props.toOrder.resId &&
            i >= firstChunkService && i <= lastChunkService) {
            chunkClass = classes.active;
        }
        if (this.props.toOrder && this.props.toOrder.mode === 'takeOff') {
            const excludes = _.get(this.props.toOrder, 'chunks.' + this.props.resource.id + '.' + moment(currentDay).format('YYYY-MM-DD') + '.exclude', []);

            const includes = _.get(this.props.toOrder, 'chunks.' + this.props.resource.id + '.' + moment(currentDay).format('YYYY-MM-DD') + '.include', []);

            const includeKey = _.findIndex(includes, item => {
                return item === this.props.i;
            });

            const excludeKey = _.findIndex(excludes, item => {
                return item === this.props.i;
            });

            if (includeKey !== -1) {
                chunkClass = classes.takeOff;
            }
            if (excludeKey !== -1) {
                chunkClass = classes.takeOff + ' ' + classes.unactive;
            }
        }
        const chunkElId = 'chunk_' + this.props.division.id + '_' + resource.id + '_' + this.props.i;
        return (
            <div
                onClick={this.clickOnChunk.bind(this, chunkElId, this.props.division.id, resource.id, resource.angara_id, this.props.i, orders, reserves, currentDay, true)}
                className={classes.chunk + ' ' + chunkClass}
                id={chunkElId}
            >
                { this.props.toOrder
                    ? ''
                    : (
                        <div className={classes.tooltip}>
                            <span>{ this.props.chunkDateDay }</span> <span>{ this.props.chunkDate.format('HH:mm') }</span>
                            <span>(занято)</span>
                        </div>
                    ) }
                {
                    this.props.toOrder &&
                    this.props.toOrder.mode === 'viewOrder' &&
                    this.props.division.id === this.props.toOrder.divisionId &&
                    this.props.resource.angara_id === this.props.toOrder.resId &&
                    this.props.i === this.props.toOrder.chunk
                        ? (
                            <RenderInBody closeHandler={() => {}}>
                                <ViewOrderModal orderId={this.props.toOrder.orderId} />
                            </RenderInBody>

                        ) : ''
                }
                {
                    this.props.toOrder &&
                    this.props.toOrder.mode === 'viewReserve' &&
                    this.props.division.id === this.props.toOrder.divisionId &&
                    this.props.resource.angara_id === this.props.toOrder.resId &&
                    this.props.i === this.props.toOrder.chunk
                        ? (
                            <RenderInBody closeHandler={() => {}}>
                                <ViewReserveModal reserveId={this.props.toOrder.reserveId} />
                            </RenderInBody>
                        ) : ''
                }

                {
                    this.props.toOrder &&
                    this.props.toOrder.mode === 'editOrder' &&
                    this.props.division.id === this.props.toOrder.divisionId &&
                    this.props.resource.angara_id === this.props.toOrder.resId &&
                    this.props.i === this.props.toOrder.chunk
                        ? (
                            <RenderInBody closeHandler={() => {}}>
                                <EditOrderModal orderId={this.props.toOrder.orderId} />
                            </RenderInBody>
                        ) : ''
                }

                {
                    this.props.toOrder &&
                    this.props.toOrder.mode === 'takeOff' &&
                    this.props.division.id === _.get(this.props.toOrder, 'lastChunk.divId') &&
                    this.props.resource.id === _.get(this.props.toOrder, 'lastChunk.resId') &&
                    this.props.i === _.get(this.props.toOrder, 'lastChunk.chNumber')
                        ? (
                            <RenderInBody closeHandler={() => {}}>
                                <div
                                    className={classes.takeOffSubmit} onClick={() => {
                                        this.props.editSchedulesChunks(this.props.toOrder.chunks);
                                        this.props.chooseToOrder(null);
                                    }}
                                >Сохранить ячейки
                                </div>
                            </RenderInBody>

                        ) : null
                }

                <ContextModal
                    clickToOrder={this.clickOnChunkToOrder.bind(this, chunkElId, this.props.division.id, resource.id, resource.angara_id, this.props.i, orders, reserves, currentDay, true)}
                    clickToReserve={this.clickOnChunkToOrder.bind(this, chunkElId, this.props.division.id, resource.id, resource.angara_id, this.props.i, orders, reserves, currentDay, false)}
                    clickToTakeOff={() => {
                        this.props.chooseToOrder({
                            mode: 'takeOff'
                        });
                    }}
                    free={!!schedule[i]}
                    chunkDate={moment(currentDay).add(this.props.i * 60 * chunkSize, 'm')}
                />
            </div>
        );
    }
}
/// //////////////////////
/// //////////////////////
export default Chunk;
