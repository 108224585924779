import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import account from '../modules/auth';
import company from '../modules/company';
import orders from '../modules/orders';
import hangar from '../modules/hangar';
import calls from '../modules/calls';
import clients from '../modules/clients';
import events from '../modules/events';
import clientsExcel from '../routes/Clients/modules/clientsExcel';
import cmtOrders from '../routes/Orders/modules/cmtOrders';
import settings from '../routes/Settings/Main/modules/settings';
import services from '../routes/Settings/Main/components/Services/modules/services';
import widgets from '../routes/Widgets/modules/widgets';
import statistics from '../routes/Statistics/Main/modules/statistics';
import feedback from '../routes/FeedBack/modules/feedback';
import callcenter from '../routes/Callcenter/reducer';
import okk from '../routes/Okk/modules/okk';
import createCompany from '../routes/CreateCompany/modules/createCompany';

export const makeRootReducer = (history) => {
    const res = combineReducers({
        router: connectRouter(history),
        account,
        company,
        orders,
        cmtOrders,
        hangar,
        clients,
        clientsExcel,
        calls,
        events,
        settings,
        services,
        widgets,
        statistics,
        feedback,
        callcenter,
        okk,
        createCompany
    });

    return (state, action) => {
        if (action.type === 'logout') {
            state = {};
        }
        return res(state, action);
    };
};

export const injectReducer = (store, { key, reducer }) => {
    store.asyncReducers[key] = reducer;
    store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
