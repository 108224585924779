import React from 'react';
import PropTypes from 'prop-types';
// comps
import DatePicker from 'components/DatePicker';
import RouteSelect from './../RouteSelect';
// libs
import moment from 'moment';
import cn from 'classnames';
import { toggleArrayPush, formatNumber, getParamFromLocation } from 'utils';
import _ from 'lodash';

const EXECUTED_STATUS = 'executed';
const ACTIVE_STATUS = 'active';
const CANCELED_STATUS = 'canceled';

class ByServices extends React.Component {
    state = {
        rangeStart: moment().add(-1, 'M'),
        rangeEnd: moment(),
        sortBy: 'title',
        byOrders: []
    };

    componentDidMount() {
        const { start, end } = getParamFromLocation(this.props.location);
        const rangeStart = start ? moment(start) : moment().add(-1, 'M');
        const rangeEnd = end ? moment(end) : moment();
        this.props.statisticByServices({
            range_start: rangeStart.format('YYYY-MM-DD'),
            range_end: rangeEnd.format('YYYY-MM-DD')
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.byOrders.length !== this.state.byOrders.length || this.props.location !== prevProps.location) {
            const { start, end } = getParamFromLocation(this.props.location);
            const rangeStart = start ? moment(start) : moment().add(-1, 'M');
            const rangeEnd = end ? moment(end) : moment();
            this.props.statisticByServices({
                status: this.state.byOrders.join(','),
                range_start: rangeStart.format('YYYY-MM-DD'),
                range_end: rangeEnd.format('YYYY-MM-DD')
            });
        }
    }

    changeOrderStatus(status) {
        let byOrders = _.cloneDeep(this.state.byOrders);
        byOrders = toggleArrayPush(byOrders, status);
        this.setState({ byOrders: byOrders });
    }

    render() {
        const { start, end } = getParamFromLocation(this.props.location);
        const rangeStart = start ? moment(start) : moment().add(-1, 'M');
        const rangeEnd = end ? moment(end) : moment();

        const statistics = _.get(this.props.statistics, 'byServices.items', []);
        let summary = 0; let summaryVisits = 0;
        return (
            <div className='statistics-block statistics-block--service'>
                <div className='statistics-block__h1'>
                    <div>Отчет по</div>
                    <RouteSelect value='services' httpQuery={this.props.location && this.props.location.search} />
                </div>
                <div className='date-filter'>
                    <div>за период с</div>
                    <DatePicker
                        stringFormatting={(date) => { return moment(date).locale('ru').format('DD MMMM YY'); }}
                        selected={rangeStart}
                        className='inline orange'
                        align={['center', 'bottom']}
                        onChange={(date) => {
                            this.props.history.push('/statistics/services?range_start=' + moment(date).format('YYYY-MM-DD') + '&range_end=' + moment(rangeEnd).format('YYYY-MM-DD'));
                        }}
                        locale='ru'
                    />
                    <div>по</div>
                    <DatePicker
                        stringFormatting={(date) => { return moment(date).locale('ru').format('DD MMMM YY'); }}
                        selected={rangeEnd}
                        className='inline orange'
                        align={['center', 'bottom']}
                        onChange={(date) => {
                            this.props.history.push('/statistics/services?range_start=' + moment(rangeStart).format('YYYY-MM-DD') + '&range_end=' + moment(date).format('YYYY-MM-DD'));
                        }}
                        locale='ru'
                    />
                </div>
                <div className='status-filter'>
                    <div className='status-filter__title'>по заказам</div>
                    <div
                        className={cn({ 'status-filter__status': true, active: this.state.byOrders.indexOf(ACTIVE_STATUS) !== -1 })}
                        onClick={this.changeOrderStatus.bind(this, ACTIVE_STATUS)}
                    >Активные
                    </div>
                    <div
                        className={cn({ 'status-filter__status': true, active: this.state.byOrders.indexOf(CANCELED_STATUS) !== -1 })}
                        onClick={this.changeOrderStatus.bind(this, CANCELED_STATUS)}
                    >Отменённые
                    </div>
                    <div
                        className={cn({ 'status-filter__status': true, active: this.state.byOrders.indexOf(EXECUTED_STATUS) !== -1 })}
                        onClick={this.changeOrderStatus.bind(this, EXECUTED_STATUS)}
                    >Выполненные
                    </div>
                </div>
                <div className='statistics-block__table'>
                    <div className='statistics-block__table__headers'>
                        <div>Услуга</div>
                        <div>Количество услуг</div>
                        <div>Сумма</div>
                    </div>
                    { _.map(statistics, stItem => {
                        let resSummary = 0; let resSummaryVisits = 0;
                        return (
                            <div key={stItem.id} className='statistics-block__table__item'>
                                <div className='title'>{ stItem.name }</div>
                                { _.map(stItem.resources, resource => {
                                    resSummaryVisits = resSummaryVisits + resource.count;
                                    resSummary = resSummary + _.get(resource, 'sum', 0);
                                    summaryVisits = summaryVisits + resource.count;
                                    summary = summary + _.get(resource, 'sum', 0);
                                    return (
                                        <div key={resource.id} className='stat-info'>
                                            <div>{ resource.name }</div>
                                            <div>{ resource.count }</div>
                                            <div>{ formatNumber(_.get(resource, 'sum', 0)) } руб.</div>
                                        </div>
                                    );
                                }) }
                                <div className='stat-info stat-info--summary'>
                                    <div />
                                    <div>{ resSummaryVisits }</div>
                                    <div>{ formatNumber(resSummary) } руб.</div>
                                </div>
                            </div>
                        );
                    }) }
                </div>
                <div className='statistics-block__table__summary'>
                    <div>Общий итог</div>
                    <div>{ summaryVisits }</div>
                    <div>{ formatNumber(summary) } руб.</div>
                </div>
            </div>
        );
    }
}

ByServices.contextTypes = {
    router: PropTypes.object
};

export default ByServices;
