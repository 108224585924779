import { connect } from 'react-redux';
import {
    deleteResource, updateResource, fetchCompany, fetchResources, deleteResourceAvatar, addResourceAvatar
} from 'modules/company';
import { actions } from '../../../modules/settings';

import EditPost from './EditPost';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => ({
    company: state.company.company,
    resource: state.settings.editResource,
    resources: state.company.resources,
    schedules: state.settings.customSchedules
});

export default connect(mapStateToProps, Object.assign(actions, {
    fetchResources,
    deleteResource,
    updateResource,
    fetchCompany,
    deleteResourceAvatar,
    addResourceAvatar
}))(withRouter(EditPost));
