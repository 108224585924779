/**
 * Created by user on 26.05.2016.
 */

import { connect } from 'react-redux';
import { actions } from 'modules/orders';
import { fetchServices, fetchSchedule } from 'modules/company';
import { changeValue } from '../../../routes/Calendar/modules/Calendar';
import { createClient, deleteLocalStorageClient } from 'modules/clients';

import OrderModal from './OrderModal';

const mapStateToProps = (state) => ({
    company: state.company.company,
    resources: state.company.resources,
    schedules: state.company.schedules,
    currentDivision: state.company.currentDivision,
    currentDay: state.company.currentDay,
    catalogues: state.company.catalogues,
    services: state.company.services,
    groupedServices: state.company.groupedServices,
    loading: state.company.fetching,
    partner: state.account.partner,
    orderMode: state.orders.orderMode,
    toOrder: state.orders.toOrder,
    orderDay: state.orders.orderDay,
    chunkSize: 0.5, // значение отрезка времени по умолчанию - 30 минут
    client: state.clients.client,
    newClientPhone: state.clients.newClientPhone,
    newClientNick: state.clients.newClientNick,
    newClientComment: state.clients.newClientComment,
    fetching: state.orders.fetching
});

export default connect(mapStateToProps, Object.assign(actions, { fetchServices, fetchSchedule, changeValue, createClient, deleteLocalStorageClient }))(OrderModal);
