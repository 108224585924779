// ------------------------------------
// Constants
// ------------------------------------

const CHANGE_DATE = 'changeDate';
const CHANGE_NAVIGATE = 'changeNavigate';

// ------------------------------------
// Actions
// ------------------------------------

export const changeNavigate = (value) => {
    return {
        type: CHANGE_NAVIGATE,
        payload: value
    };
};

export const changeDate = (date) => {
    return {
        type: CHANGE_DATE,
        payload: date
    };
};

export const actions = {
    changeDate,
    changeNavigate
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [CHANGE_DATE]: (state, action) => {
        return ({ ...state, currentDay: action.payload });
    },
    [CHANGE_NAVIGATE]: (state, action) => {
        return ({ ...state, navigate: action.payload });
    }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    navigate: { mode: 'callsDiary' },
    content: { mode: 'callCenter' },
    currentDay: null
};
export default function callCenterReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
