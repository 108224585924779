import { api, httpBuildQuery } from 'utils';
import download from 'downloadjs';
import _ from 'lodash';
import moment from 'moment';
const EXPORT_EXPIRED_TIMEOUT = 300000;
const EXPORT_DOWNLOAD_TIMEOUT = 2000;

// ------------------------------------
// Constants
// ------------------------------------
export const CHANGE_MAIN_VIEW = 'changeMainView';

export const SWITCH_POLL_CARD = 'switchPollCard';

export const CHANGE_QUESTION_MODAL = 'changeQuestionModal';

export const REQUEST_REPORTS = 'requestReports';

export const REQUEST_REPORTS_STOP = 'requestReportsStop';

export const RECEIVE_REPORTS = 'receiveReports';

export const CHANGE_SORT = 'changeSort';

export const RECEIVE_VISIT_RESULT = 'receiveVisitResult';

export const RESET_POLL_CARD_DATA = 'resetPollCardData';

export const CHANGE_EXPORT_RESULTS_ID = 'changeExportResultsId';
export const CHANGE_EXPORTING_RESULTS = 'changeExportingResults';

export const CHOOSE_REPORT = 'chooseReport';
export const CHOOSE_REPORT_BY_ANSWER = 'chooseReportByAnswer';

export const RECEIVE_STATISTIC = 'receiveStatistic';

export const RECEIVE_REPORTS_BY_ANSWER = 'receiveReportsByAnswer';

export const CHANGE_STATISTIC_DATES = 'changeStatisticsDates';

export const RECEIVE_QUESTION = 'receiveQuestion';
export const REMOVE_QUESTION = 'removeQuestion';

export const RECEIVE_STYLES = 'receiveStyles';
export const RECEIVE_STYLE = 'receiveStyle';

export const REMOVE_OKK_IMAGE = 'removeOkkImage';

// ------------------------------------
// Actions
// ------------------------------------
export const changeMainView = (value) => {
    return {
        type: CHANGE_MAIN_VIEW,
        payload: value
    };
};

export const switchPollCard = (data) => {
    return {
        type: SWITCH_POLL_CARD,
        payload: data
    };
};

export const changeQuestionModal = (data, show) => {
    return {
        type: CHANGE_QUESTION_MODAL,
        payload: {
            data: data,
            show: show
        }
    };
};

export const fetchReports = (data) => {
    return function(dispatch) {
        dispatch(requestReports());
        return api('/v1/partner/okk/negative-reviews-and-suggestions/visits?' + httpBuildQuery(data), {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receiveReports(res)))
            .catch(() => {
                return dispatch(stopLoader());
            });
    };
};

function requestReports() {
    return {
        type: REQUEST_REPORTS
    };
}

function receiveReports(res) {
    return {
        type: RECEIVE_REPORTS,
        payload: res
    };
}

export const fetchReportsByAnswer = (data) => {
    return function(dispatch) {
        dispatch(requestReports());
        return api('/v1/partner/okk/statistic/visits?' + httpBuildQuery(data), {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receiveReportsByAnswer(res)))
            .catch(() => {
                return dispatch(stopLoader());
            });
    };
};

function receiveReportsByAnswer(res) {
    return {
        type: RECEIVE_REPORTS_BY_ANSWER,
        payload: res
    };
}

export const changeSort = (sort) => {
    return {
        type: CHANGE_SORT,
        payload: sort
    };
};

export const fetchVisitResult = (id) => {
    return function(dispatch) {
        dispatch(requestReports());
        return api('/v1/partner/okk/negative-reviews-and-suggestions/visits/' + id, {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receiveVisitResult(res)))
            .catch(() => {
                return dispatch(stopLoader());
            });
    };
};

function receiveVisitResult(res) {
    return {
        type: RECEIVE_VISIT_RESULT,
        payload: res
    };
}

export const resetPollCardData = () => {
    return {
        type: RESET_POLL_CARD_DATA
    };
};

export const exportResults = (data, ids) => {
    return function(dispatch) {
        dispatch(changeExportingResults(true));
        return api('/v1/partner/okk/negative-reviews-and-suggestions/export?' + httpBuildQuery(data) + ids, {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res.refId) {
                    return getExportResults(res.refId, dispatch);
                }
            });
    };
};

function getExportResults(refId, dispatch) {
    return fetch(process.env.REACT_APP_API_PATH + '/v1/partner/okk/negative-reviews-and-suggestions/export/' + refId,
        {
            method: 'get',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Api-Token': localStorage.getItem('token')
            }
        }
    ).then(res => {
        if (res.status === 200) {
            if (dispatch) {
                dispatch(changeExportResultsId(refId));
                setTimeout(() => {
                    dispatch(changeExportingResults(false));
                    dispatch(changeExportResultsId(null));
                }, EXPORT_EXPIRED_TIMEOUT);
            }
        } else if (res.status === 404) {
            setTimeout(() => {
                getExportResults(refId, dispatch);
            }, EXPORT_DOWNLOAD_TIMEOUT);
        } else {
            if (dispatch) {
                dispatch(changeExportingResults(false));
                dispatch(changeExportResultsId(null));
            }
        }
    });
}

function changeExportResultsId(res) {
    return {
        type: CHANGE_EXPORT_RESULTS_ID,
        payload: res
    };
}

export const downloadExportResults = (refId) => {
    return function(dispatch) {
        return fetch(process.env.REACT_APP_API_PATH + '/v1/partner/okk/negative-reviews-and-suggestions/export/' + refId,
            {
                method: 'get',
                mode: 'cors',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Api-Token': localStorage.getItem('token')
                }
            }
        ).then(res => {
            if (res.status === 200) {
                dispatch(changeExportingResults(false));
                dispatch(changeExportResultsId(null));
                return res.blob();
            }
        }).then(blob => {
            if (blob) {
                download(blob, 'Список отзывов.xlsx');
            }
        });
    };
};

function changeExportingResults(status) {
    return {
        type: CHANGE_EXPORTING_RESULTS,
        payload: status
    };
}

export const exportByAnswerResults = (data, ids) => {
    return function(dispatch) {
        dispatch(changeExportingResults(true));
        return api('/v1/partner/okk/statistic/export?' + httpBuildQuery(data) + ids, {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res.refId) {
                    return getExportByAnswerResults(res.refId, dispatch);
                }
            });
    };
};

function getExportByAnswerResults(refId, dispatch) {
    return fetch(process.env.REACT_APP_API_PATH + '/v1/partner/okk/statistic/export/' + refId,
        {
            method: 'get',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Api-Token': localStorage.getItem('token')
            }
        }
    ).then(res => {
        if (res.status === 200) {
            if (dispatch) {
                dispatch(changeExportResultsId(refId));
                setTimeout(() => {
                    dispatch(changeExportingResults(false));
                    dispatch(changeExportResultsId(null));
                }, EXPORT_EXPIRED_TIMEOUT);
            }
        } else if (res.status === 404) {
            setTimeout(() => {
                getExportByAnswerResults(refId, dispatch);
            }, EXPORT_DOWNLOAD_TIMEOUT);
        } else {
            if (dispatch) {
                dispatch(changeExportingResults(false));
                dispatch(changeExportResultsId(null));
            }
        }
    });
}

export const downloadExportByAnswerResults = (refId) => {
    return function(dispatch) {
        return fetch(process.env.REACT_APP_API_PATH + '/v1/partner/okk/negative-reviews-and-suggestions/export/' + refId,
            {
                method: 'get',
                mode: 'cors',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Api-Token': localStorage.getItem('token')
                }
            }
        ).then(res => {
            if (res.status === 200) {
                dispatch(changeExportingResults(false));
                dispatch(changeExportResultsId(null));
                return res.blob();
            }
        }).then(blob => {
            if (blob) {
                download(blob, 'Список отзывов.xlsx');
            }
        });
    };
};

export const chooseReport = (id) => {
    return {
        type: CHOOSE_REPORT,
        payload: id
    };
};

export const chooseReportByAnswer = (id) => {
    return {
        type: CHOOSE_REPORT_BY_ANSWER,
        payload: id
    };
};

export const fetchStatistic = (request) => {
    return function(dispatch) {
        dispatch(requestReports());
        return api('/v1/partner/okk/statistic?' + httpBuildQuery(request), {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res && res.polls) {
                    return dispatch(receiveStatistic(res));
                }
            })
            .catch(() => {
                return dispatch(stopLoader());
            });
    };
};

function receiveStatistic(res) {
    return {
        type: RECEIVE_STATISTIC,
        payload: res
    };
}

function stopLoader() {
    return {
        type: REQUEST_REPORTS_STOP
    };
}

export const changeStatisticsDates = (value, label) => {
    return {
        type: CHANGE_STATISTIC_DATES,
        payload: {
            value: value,
            label: label
        }
    };
};

export const createQuestion = (data) => {
    return function(dispatch) {
        dispatch(requestReports());
        return api('/v1/partner/okk/questions', {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(res => {
                if (res && res.item) {
                    return dispatch(receiveQuestion(res.item));
                }
            })
            .catch(() => {
                return dispatch(stopLoader());
            });
    };
};

function receiveQuestion(res) {
    return {
        type: RECEIVE_QUESTION,
        payload: res
    };
}

export const updateQuestion = (data) => {
    return function(dispatch) {
        dispatch(requestReports());
        return api('/v1/partner/okk/questions/' + data.id, {
            method: 'PUT',
            body: JSON.stringify(data)
        }, dispatch)
            .then(res => {
                if (res && res.item) {
                    return dispatch(receiveQuestion(res.item));
                }
            })
            .catch(() => {
                return dispatch(stopLoader());
            });
    };
};

export const archiveQuestion = (id) => {
    return function(dispatch) {
        dispatch(requestReports());
        return api('/v1/partner/okk/questions/' + id, {
            method: 'DELETE'
        }, dispatch)
            .then(res => {
                if (res && res.item) {
                    return dispatch(removeQuestion(res.item));
                }
            })
            .catch(() => {
                return dispatch(stopLoader());
            });
    };
};

function removeQuestion(res) {
    return {
        type: REMOVE_QUESTION,
        payload: res
    };
}

export const fetchStyles = () => {
    return function(dispatch) {
        dispatch(requestReports());
        return api('/v1/partner/okk/styles', {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res && res.items) {
                    return dispatch(receiveStyles(res.items));
                }
            })
            .catch(() => {
                return dispatch(stopLoader());
            });
    };
};

function receiveStyles(res) {
    return {
        type: RECEIVE_STYLES,
        payload: res
    };
}

export const removeOkkImage = (label) => {
    return {
        type: REMOVE_OKK_IMAGE,
        payload: label
    };
};

export const updateStyles = (data) => {
    return function(dispatch) {
        dispatch(requestReports());
        return api('/v1/partner/okk/styles', {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(res => {
                if (res && res.style) {
                    return dispatch(receiveStyle(res.style));
                }
            })
            .catch(() => {
                return dispatch(stopLoader());
            });
    };
};

function receiveStyle(res) {
    return {
        type: RECEIVE_STYLE,
        payload: res
    };
}

export const actions = {
    changeMainView,
    switchPollCard,
    changeQuestionModal,
    fetchReports,
    changeSort,
    fetchVisitResult,
    resetPollCardData,
    exportResults,
    downloadExportResults,
    chooseReport,
    fetchStatistic,
    fetchReportsByAnswer,
    chooseReportByAnswer,
    exportByAnswerResults,
    downloadExportByAnswerResults,
    changeStatisticsDates,
    createQuestion,
    updateQuestion,
    archiveQuestion,
    fetchStyles,
    removeOkkImage,
    updateStyles
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [CHANGE_MAIN_VIEW]: (state, action) => {
        return ({ ...state, mainView: action.payload });
    },
    [SWITCH_POLL_CARD]: (state) => {
        const showPollCard = !state.showPollCard;
        return ({ ...state, showPollCard: showPollCard });
    },
    [CHANGE_QUESTION_MODAL]: (state, action) => {
        return ({ ...state, showQuestionModal: action.payload.show, questionModalData: action.payload.data });
    },
    [REQUEST_REPORTS]: (state) => {
        return ({ ...state, fetching: true });
    },
    [REQUEST_REPORTS_STOP]: (state) => {
        return ({ ...state, fetching: false });
    },
    [RECEIVE_REPORTS]: (state, action) => {
        return ({ ...state, reports: action.payload, fetching: false });
    },
    [CHANGE_SORT]: (state, action) => {
        return ({ ...state, sort: action.payload });
    },
    [RECEIVE_VISIT_RESULT]: (state, action) => {
        return ({ ...state, pollCardData: action.payload.item, fetching: false });
    },
    [RESET_POLL_CARD_DATA]: (state) => {
        return ({ ...state, pollCardData: null });
    },
    [CHANGE_EXPORT_RESULTS_ID]: (state, action) => {
        return ({ ...state, exportResultsId: action.payload });
    },
    [CHANGE_EXPORTING_RESULTS]: (state, action) => {
        return ({ ...state, exportingResults: action.payload });
    },
    [CHOOSE_REPORT]: (state, action) => {
        const reports = Object.assign({}, state.reports);

        const reportKey = _.findKey(reports.items, ['id', action.payload]);
        if (reports.items[reportKey].chosen) {
            reports.items[reportKey].chosen = false;
            _.remove(reports.chosenIds, id => { return id === reports.items[reportKey].id; });
        } else {
            reports.items[reportKey].chosen = true;
            if (!reports.chosenIds) {
                reports.chosenIds = [];
            }
            reports.chosenIds.push(reports.items[reportKey].id);
        }

        return ({ ...state, reports: reports });
    },
    [CHOOSE_REPORT_BY_ANSWER]: (state, action) => {
        const reports = Object.assign({}, state.reportsByAnswer);

        const reportKey = _.findKey(reports.items, ['id', action.payload]);
        if (reports.items[reportKey].chosen) {
            reports.items[reportKey].chosen = false;
            _.remove(reports.chosenIds, id => { return id === reports.items[reportKey].id; });
        } else {
            reports.items[reportKey].chosen = true;
            if (!reports.chosenIds) {
                reports.chosenIds = [];
            }
            reports.chosenIds.push(reports.items[reportKey].id);
        }

        return ({ ...state, reportsByAnswer: reports });
    },
    [RECEIVE_STATISTIC]: (state, action) => {
        const statistic = action.payload;
        _.map(statistic.polls, poll => {
            _.map(poll.questions, question => {
                const report = statistic.report[question.id];
                question.totalAnswersCount = _.get(report, 'totalAnswersCount', 0);
                _.map(question.answers, answer => {
                    answer.count = _.get(report, `answers[${answer.id}].count`, 0);
                    answer.percent = _.get(report, `answers[${answer.id}].percent`, 0);
                });
            });
        });
        return ({ ...state, statistic: action.payload, fetching: false });
    },
    [RECEIVE_REPORTS_BY_ANSWER]: (state, action) => {
        return ({ ...state, reportsByAnswer: action.payload, fetching: false });
    },
    [CHANGE_STATISTIC_DATES]: (state, action) => {
        const statisticDates = Object.assign({}, state.statisticDates);
        statisticDates[action.payload.label] = action.payload.value;
        return ({ ...state, statisticDates: statisticDates });
    },
    [RECEIVE_QUESTION]: (state, action) => {
        const statistic = Object.assign({}, state.statistic);

        const question = Object.assign({}, action.payload);

        const pollKey = _.findKey(statistic.polls, ['id', question.poll_id]);

        const questionKey = _.findKey(statistic.polls[pollKey].questions, ['id', question.id]);

        if (questionKey) {
            _.merge(statistic.polls[pollKey].questions[questionKey], question);
        } else {
            question.totalAnswersCount = 0;
            _.map(question.answers, answer => {
                answer.count = 0;
                answer.percent = 0;
            });

            statistic.polls[pollKey].questions.push(question);
        }

        return ({ ...state, statistic: statistic, showQuestionModal: false, fetching: false });
    },
    [REMOVE_QUESTION]: (state, action) => {
        const statistic = Object.assign({}, state.statistic);

        const question = Object.assign({}, action.payload);

        const pollKey = _.findKey(statistic.polls, ['id', question.poll_id]);

        _.remove(statistic.polls[pollKey].questions, ['id', question.id]);

        return ({ ...state, statistic: statistic, showQuestionModal: false, fetching: false });
    },
    [RECEIVE_STYLES]: (state, action) => {
        return ({ ...state, styles: action.payload, fetching: false });
    },
    [REMOVE_OKK_IMAGE]: (state, action) => {
        const styles = Object.assign([], state.styles);
        _.remove(styles, ['label', action.payload]);
        return ({ ...state, styles: styles, fetching: false });
    },
    [RECEIVE_STYLE]: (state, action) => {
        const styles = Object.assign([], state.styles);

        const styleKey = _.findKey(styles, ['label', action.payload.label]);
        styles[styleKey] = action.payload;
        return ({ ...state, styles: styles, fetching: false });
    }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    mainView: 'statistic', // settings, statistic
    showPollCard: false,
    pollCardData: null,
    showQuestionModal: false,
    questionModalData: null,
    fetching: false,
    reports: null,
    reportsByAnswer: null,
    sort: {
        order_by: 'created_at',
        order_direction: 'desc'
    },
    exportResultsId: null,
    exportingResults: false,
    statistic: null,
    statisticDates: {
        rangeStart: moment().add(-1, 'M').startOf('day').locale('ru'),
        rangeEnd: moment().endOf('day').locale('ru')
    },
    styles: null
};

export default function okkReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
