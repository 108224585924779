import { connect } from 'react-redux';
import { createResource, fetchCompany } from 'modules/company';
import { actions } from '../../../modules/settings';

import CreatePost from './CreatePost';

const mapStateToProps = state => ({
    company: state.company.company,
    newResource: state.company.newResource,
    resource: state.settings.addResource
});

export default connect(mapStateToProps, Object.assign(actions, { createResource, fetchCompany }))(CreatePost);
