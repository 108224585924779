/**
 * Created by user on 04.10.16.
 */
import React from 'react';
import classes from '../../Calendar.module.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { declOfNum, getBlockPosition } from 'utils';
import _ from 'lodash';

// require('react-datepicker/dist/react-datepicker.css');

const SERVICE_NAME_MAX_WIDTH = 574;

class CyclicNotificationModal extends React.Component {
    constructor(props) {
        super(props);
        this.changeDate = this.changeDate.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
    }

    state = {
        nameError: null,
        periodError: null,
        dateError: null
    };

    componentDidMount() {
        if (document.getElementById('serviceNameWidth')) {
            const nameWidth = document.getElementById('serviceNameWidth').getBoundingClientRect().width;

            this.props.checkTooltip(nameWidth, SERVICE_NAME_MAX_WIDTH, 'cyclNotifNameTooltipShow');
        }
    }

    componentDidUpdate() {
        if (document.getElementById('serviceNameWidth')) {
            const nameWidth = document.getElementById('serviceNameWidth').getBoundingClientRect().width;

            this.props.checkTooltip(nameWidth, SERVICE_NAME_MAX_WIDTH, 'cyclNotifNameTooltipShow');
        }
    }

    renderTrack({ style, ...props }) {
        const trackStyle = {};
        return (
            <div
                className={classes.scrollTrack}
                style={{ ...style, ...trackStyle }}
                {...props}
            />
        );
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {};
        return (
            <div
                className={classes.scrollThumb}
                style={{ ...style, ...thumbStyle }}
                {...props}
            />
        );
    }

    selectCyclNotifNameOuterMenu() {
        const serviceTemplates = _.uniqBy(this.props.services.services, 'serviceTemplate.id');
        // схлопываем услуги с одинаковым именем

        const services = [];

        let currentCatalogue;

        let lastCatalogue;

        const childrenCatalogues = [];

        _.remove(serviceTemplates, service => { // убираем услуги, на которые нельзя создавать циклические уведомления
            if (!service.isPeriodic) {
                return true;
            }
        });

        if (this.props.cyclicNotification.car_id) {
            _.remove(serviceTemplates, service => { // убираем услуги, на которые уже созданы циклические уведомления дял авто
                return _.find(this.props.client.notifications, item => {
                    return item.serviceTemplate.id === service.serviceTemplate.id && item.car_id === this.props.cyclicNotification.car_id;
                });
            });
        } else {
            _.remove(serviceTemplates, service => { // убираем услуги, на которые уже созданы циклические уведомления для клиента
                return _.find(this.props.client.notifications, item => {
                    return item.serviceTemplate.id === service.serviceTemplate.id && item.car_id === null;
                });
            });
        }

        _.map(this.props.catalogues.catalogues, catalogue => { // находим каталоги с дочерними каталогами
            if (catalogue.childrenCatalogues.length > 0) {
                _.map(catalogue.childrenCatalogues, childCatalogue => {
                    childrenCatalogues.push({ id: childCatalogue.id, parentId: catalogue.id, name: catalogue.name });
                });
            }
        });

        _.map(serviceTemplates, item => { // если услуга принадлежит childrenCatalogue, то меняем у неё id каталога на родительский
            if (_.find(childrenCatalogues, catalogue => {
                return catalogue.id === item.serviceTemplate.catalogue.id;
            })) {
                item.serviceTemplate.catalogue.id = _.find(childrenCatalogues, childrenCatalogue => {
                    return childrenCatalogue.id === item.serviceTemplate.catalogue.id;
                }).parentId;
            }
        });

        _.remove(serviceTemplates, service => { // убираем услуги, каталоги которых не добавлены в компанию
            return !_.find(this.props.catalogues.catalogues, ['id', service.serviceTemplate.catalogue.id]);
        });

        _.remove(serviceTemplates, ['resources.length', 0]);// убираем услуги без ресурса

        _.map(serviceTemplates, service => { // формируем список каталогов и услуг для выпадающего меню
            if (!_.find(services, catalogue => {
                return catalogue.id === service.serviceTemplate.catalogue.id;
            })) {
                currentCatalogue = _.find(this.props.catalogues.catalogues, catalogue => {
                    return catalogue.id === service.serviceTemplate.catalogue.id;
                });

                if (currentCatalogue) {
                    services.push({
                        id: service.serviceTemplate.catalogue.id,
                        label: currentCatalogue.name,
                        type: 'group'
                    });
                }
            }

            lastCatalogue = _.findLast(services, catalogue => {
                return catalogue.type === 'group';
            });

            _.map(serviceTemplates, item => {
                if ((item.serviceTemplate.catalogue.id === lastCatalogue.id) &&
                    !_.find(services, repeat => {
                        return repeat.label === item.serviceTemplate.name;
                    })
                ) {
                    services.push({
                        label: item.serviceTemplate.name,
                        type: 'item',
                        serviceTemplateId: item.serviceTemplate.id,
                        serviceId: item.id
                    });
                }
            });
        });
        return (
            <div className={classes.outerMenu} onClick={e => { e.stopPropagation(); }}>
                <Scrollbars
                    thumbSize={16}
                    autoHeight
                    autoHide={false}
                    renderTrackVertical={this.renderTrack}
                    renderThumbVertical={this.renderThumb}
                >
                    { _.map(services, service => {
                        if (service.type === 'group') {
                            return (
                                <div key={'service' + service.id + service.childId} className={classes.group}>
                                    { service.label }
                                </div>);
                        } else if (service.type === 'item') {
                            return (
                                <div
                                    key={'service' + service.label}
                                    className={classes.item}
                                    onClick={this.props.cyclicNotificationEdit.bind(
                                        this,
                                        'serviceTemplate',
                                        service,
                                        this.props.notifications
                                    )}
                                >
                                    { service.label }
                                </div>);
                        }
                    }) }
                </Scrollbars>
            </div>
        );
    }

    selectCyclNotifPeriodOuterMenu() {
        const periods = [
            { title: 'год', text: 'Год', length: 365 },
            { title: 'месяц', text: 'Месяц', length: 30 },
            { title: 'неделю', text: 'Неделя', length: 7 }
        ];

        return (
            <div className={classes.outerMenuPeriod} onClick={e => { e.stopPropagation(); }}>
                <Scrollbars
                    thumbSize={16}
                    autoHeight
                    autoHide={false}
                    renderTrackVertical={this.renderTrack}
                    renderThumbVertical={this.renderThumb}
                >
                    { _.map(periods, period => {
                        return (
                            <div
                                key={'period' + period.title}
                                className={classes.item}
                                onClick={this.props.cyclicNotificationEdit.bind(
                                    this,
                                    'period',
                                    period
                                )}
                            >
                                { period.text }
                            </div>);
                    }) }
                </Scrollbars>
            </div>
        );
    }

    changeDate(date) {
        this.props.cyclicNotificationEdit('next_notified_at', moment(date));
    }

    showErrors(e) {
        e.preventDefault();
        e.stopPropagation();

        const notification = this.props.cyclicNotification;
        if (!notification.serviceTemplate.name) {
            this.setState({ nameError: <div className={classes.serviceNameError}>Выберите услугу</div> });
        }
        if (!notification.repeats_during_period) {
            this.setState({ periodError: <div className={classes.periodError}>Укажите цикличность приглашений</div> });
        }
        if (!notification.next_notified_at) {
            this.setState({ dateError: <div className={classes.dateError}>Укажите дату следующего оказания услуги</div> });
        }
    }

    clearErrors(e) {
        this.props.editClient(false, e);
        this.setState({
            nameError: null,
            periodError: null,
            dateError: null
        });
    }

    editClient(label, e) {
        this.props.editClient(label, e);
        this.setState({
            nameError: null,
            periodError: null,
            dateError: null
        });
    }

    createCyclicNotification(notification) {
        const data = {
            client_id: notification.client_id,
            service_template_id: notification.serviceTemplate.id,
            period: notification.period.length,
            repeats_during_period: notification.repeats_during_period,
            text: notification.text,
            next_notified_at: notification.next_notified_at,
            service_id: notification.service_id
        };
        if (notification.car_id) {
            data.car_id = notification.car_id;
        }
        this.props.createCyclicNotification(data);
    }

    render() {
        let lastDate,
            nextDate;

        if (moment(this.props.cyclicNotification.last_notified_at).format('DD MMMM YYYY') !== 'Invalid date') {
            lastDate = moment(this.props.cyclicNotification.last_notified_at).format('DD MMMM YYYY').split(' ');
        }

        if (moment(this.props.cyclicNotification.next_notified_at).format('DD MMMM YYYY') !== 'Invalid date') {
            nextDate = moment(this.props.cyclicNotification.next_notified_at).format('DD MMMM YYYY').split(' ');
        }

        const disabled = {
            period: this.props.cyclicNotification.serviceTemplate.name === null,
            date: this.props.cyclicNotification.repeats_during_period === ''
        };

        const readyToUpdate = (
            this.props.cyclicNotification.serviceTemplate.name !== null &&
            this.props.cyclicNotification.repeats_during_period !== '' &&
            this.props.cyclicNotification.next_notified_at !== null &&
            !this.props.fetching
        );

        const tooltip = this.props.cyclNotifNameTooltipShow
            ? (
                <div>
                    <div className={classes.tooltip}>{ this.props.cyclicNotification.serviceTemplate.name }</div>
                    <div className={classes.shadowTriangle} />
                </div>
            )
            : null;

        return (
            <div className={classes.black} onClick={this.props.shadeMenuChange.bind(this, null)}>
                <div className={classes.wrapper} style={getBlockPosition(600, 690)} onClick={e => { e.stopPropagation(); }}>
                    <div className={classes.cyclicNotification} onClick={this.clearErrors}>
                        <div className={classes.header}>
                            { this.props.cyclicNotification.id
                                ? (
                                    <div id='serviceNameWidth' className={classes.value + ' ' + classes.selectedValue}>
                                        { this.props.cyclicNotification.serviceTemplate.name }
                                        { tooltip }
                                    </div>
                                )
                                : (
                                    <div
                                        className={classes.changeValue}
                                        onClick={this.editClient.bind(this, 'cyclNotifName')}
                                    >
                                        <div
                                            id='serviceNameWidth' className={this.props.cyclicNotification.serviceTemplate.name
                                                ? classes.value + ' ' + classes.selectedValue
                                                : classes.value}
                                        >
                                            { this.props.cyclicNotification.serviceTemplate.name
                                                ? this.props.cyclicNotification.serviceTemplate.name
                                                : 'Выбрать услугу' }
                                            { tooltip }
                                        </div>
                                        { !this.props.cyclicNotification.serviceTemplate.name
                                            ? <div className={classes.select} />
                                            : <div className={classes.blueSelect} /> }
                                        { this.props.editClientChange === 'cyclNotifName' ? this.selectCyclNotifNameOuterMenu() : null }
                                    </div>
                                ) }
                            { this.state.nameError }
                        </div>
                        <div className={disabled.period ? classes.disabled : ''}>
                            <div className={classes.cyclicTitle}>Цикличность приглашений:</div>
                            <input
                                type='text'
                                name='repeats_during_period'
                                value={this.props.cyclicNotification.repeats_during_period}
                                onChange={this.props.cyclicNotificationChange}
                                disabled={disabled.period}
                            />
                            <div className={classes.text}>
                                { declOfNum(this.props.cyclicNotification.repeats_during_period, ['раз в', 'раза в', 'раз в']) }
                            </div>
                            <div
                                className={classes.period}
                                onClick={disabled.period ? null : this.props.editClient.bind(this, 'cyclNotifPeriod')}
                            >
                                <div className={classes.periodValue}>
                                    { this.props.cyclicNotification.period.title }
                                </div>
                                <div className={classes.select} />
                                { this.props.editClientChange === 'cyclNotifPeriod' ? this.selectCyclNotifPeriodOuterMenu() : null }
                            </div>
                            <div
                                className={classes.regulatory} onClick={this.props.cyclicNotificationEdit.bind(
                                    this,
                                    'regulatory')}
                            >
                                Выставить нормативную
                            </div>
                        </div>
                        { this.state.periodError }
                        { this.props.errorMenu === 'repeats_during_period'
                            ? (
                                <div className={classes.errorMenuRepeats}>
                                    В этом поле можно ввести только целое число (кроме 0)
                                </div>
                            )
                            : null }
                        <div className={classes.cyclicTitle}>Дата последнего приглашения</div>
                        { lastDate
                            ? (
                                <div className={classes.serviceDate}>
                                    { lastDate[0] + '  ' }
                                    <div className={classes.orange}>
                                        { lastDate[1] }
                                    </div>
                                    { '  ' + lastDate[2] }
                                </div>
                            )
                            : (
                                <div className={classes.serviceDate}>
                                    отсутствует
                                </div>
                            ) }
                        <div className={disabled.date || disabled.period ? classes.disabled : ''}>
                            <div className={classes.cyclicTitle + ' ' + classes.lastTitle}>
                                Дата следующего приглашения
                            </div>
                            <div
                                className={classes.serviceDate + ' ' + classes.hover}
                                onClick={disabled.date || disabled.period ? null : this.editClient.bind(this, 'cyclNotifNextDate')}
                            >
                                { nextDate
                                    ? (
                                        <div className={classes.serviceDateChange}>
                                            { nextDate[0] + ' ' }
                                            <div className={classes.orange}>{ nextDate[1] }</div>
                                            { ' ' + nextDate[2] }
                                        </div>
                                    )
                                    : (
                                        <div className={classes.serviceDateChange}>
                                            выберите дату
                                        </div>
                                    ) }
                                <div className={classes.select}>
                                    { this.props.editClientChange === 'cyclNotifNextDate'
                                        ? (
                                            <div
                                                id='datapicker'
                                                className={classes.datePicker}
                                                onClick={e => { e.stopPropagation(); }}
                                            >
                                                <div className='boardComputer'>
                                                    <DatePicker
                                                        dateFormatCalendar='MMMM`YY'
                                                        inline
                                                        minDate={new Date(moment().add(1, 'd'))}
                                                        selected={nextDate ? new Date(moment(this.props.cyclicNotification.next_notified_at)) : null}
                                                        className='schdulePicker'
                                                        onChange={this.changeDate}
                                                        locale='ru'
                                                    />
                                                </div>
                                            </div>
                                        )
                                        : null }
                                </div>
                            </div>
                        </div>
                        { this.state.dateError }
                        <div className={classes.buttons + ' ' + classes.notification}>
                            { this.props.cyclicNotification.id
                                ? (
                                    <div
                                        className={classes.deleteButton}
                                        onClick={this.props.deleteCyclicNotification.bind(this, this.props.cyclicNotification)}
                                    >
                                        Удалить
                                    </div>
                                )
                                : null }
                            <div className={classes.cancel} onClick={this.props.shadeMenuChange.bind(this, null)}>отмена</div>
                            <div
                                className={classes.cyclicNotificationButton + (readyToUpdate ? '' : ' ' + classes.disabled)}
                                onClick={
                                    readyToUpdate
                                        ? this.props.cyclicNotification.id
                                            ? this.props.updateCyclicNotification.bind(this, this.props.cyclicNotification)
                                            : this.createCyclicNotification.bind(this, this.props.cyclicNotification)
                                        : this.showErrors
                                }
                            >
                                Сохранить
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CyclicNotificationModal;
