/**
 * Created by user on 26.05.2016.
 */

import { connect } from 'react-redux';
import { actions } from 'modules/orders';
import { fetchServices } from 'modules/company';

import EditOrderModal from './EditOrderModal';

const mapStateToProps = (state) => ({
    company: state.company.company,
    orders: state.orders.orders,
    fetching: state.orders.fetching,
    schedules: state.company.schedules,
    currentDay: state.company.currentDay,
    services: state.company.services,
    groupedServices: state.company.groupedServices,
    toOrder: state.orders.toOrder,
    chunkSize: 0.5, // значение отрезка времени по умолчанию - 30 минут
    orderDay: state.orders.orderDay
});

export default connect(mapStateToProps, Object.assign(actions, { fetchServices }))(EditOrderModal);
