/**
 * Created by user on 07.10.16.
 */
import React from 'react';
import classes from './Call.module.scss';
import Button from 'components/Button';
import { getBlockPosition } from 'utils';
import moment from 'moment';

class CallModal extends React.Component {
    render() {
        return (
            <div className={classes.black} onClick={this.props.shadeMenuChange.bind(this, null)}>
                <div className={classes.wrapper} style={getBlockPosition(666, 690)} onClick={e => { e.stopPropagation(); }}>
                    <div className={classes.order}>
                        <div className={classes.header}>
                            Карточка звонка
                        </div>
                        <div className={classes.body}>
                            <div className={classes.row}>
                                <div className={classes.item}>
                                    <div className={classes.title}>Клиент</div>
                                    <div className={classes.orange}>{ this.props.client.nick },</div>
                                    <div className={classes.phone}>{ this.props.client.phone }</div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.title}>Дата и время звонка</div>
                                    <div className={classes.orange + ' ' + classes.inlineBlock}>
                                        { moment(this.props.call.processed_at).format('DD MMMM \'YY') },
                                    </div>
                                    <div className={classes.green + ' ' + classes.inlineBlock}>
                                        &nbsp;{ moment(this.props.call.processed_at).format('HH:mm') }
                                    </div>
                                </div>
                            </div>
                            <div className={classes.row}>
                                <div className={classes.item}>
                                    <div className={classes.title}>Причина посещения</div>
                                    <div>{ this.props.call.reason }</div>
                                </div>
                            </div>
                            <div className={classes.comment}>{ this.props.call.comment }</div>
                            <div className={classes.btnContainer}>
                                <Button
                                    value='Закрыть карточку'
                                    className='save'
                                    onClick={this.props.shadeMenuChange.bind(this, null)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CallModal;
