import { api, httpBuildQuery } from './../utils';

// ------------------------------------
// Constants
// ------------------------------------

const REQUEST_CATALOGUES = 'requestCatalogues';
const RECEIVE_CATALOGUES = 'receiveCatalogues';
const REQUEST_PLAIN_CATALOGUES = 'requestPlainCatalogues';
const RECEIVE_PLAIN_CATALOGUES = 'receivePlainCatalogues';
const SHOW_HEADER = 'showHeader';
const CHANGE_PASS_INPUT = 'changePassInput';
const CONFIRM_PASS_CHECK = 'confirmPassCheck';
const PASSWORD_MENU_SHOW = 'passwordMenuShow';
const PASSWORD_DATA_CLEAR = 'passwordDataClear';
const PASSWORD_UPDATE_FAIL = 'passwordUpdateFail';

const SET_AJAX_ERROR = 'setAjaxError';
const CLEAR_AJAX_ERROR = 'clearAjaxError';

const SET_FOOTER_MIN_HEIGHT = 'setFooterMinHeight';

const CHANGE_DAY_TIME = 'changeDayTime';

const CHANGE_SCROLL_TO_ORDER = 'changeScrollToOrder';

// ------------------------------------
// Actions
// ------------------------------------

export const setAjaxError = (response) => {
    return {
        type: SET_AJAX_ERROR,
        payload: response
    };
};

export const clearAjaxError = (response) => {
    return {
        type: CLEAR_AJAX_ERROR,
        payload: response
    };
};

export const fetchCatalogues = (data) => {
    return function(dispatch) {
        dispatch(requestCatalogues());
        return api('/v3/references/catalogues?' + httpBuildQuery(data), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveCatalogues(response)));
    };
};

export const fetchPlainCatalogues = (data) => {
    return function(dispatch) {
        dispatch({ type: REQUEST_PLAIN_CATALOGUES });
        return api('/v3/references/catalogues?' + httpBuildQuery(data), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch({ type: RECEIVE_PLAIN_CATALOGUES, payload: response }));
    };
};

function requestCatalogues() {
    return {
        type: REQUEST_CATALOGUES
    };
}

function receiveCatalogues(response) {
    return {
        type: RECEIVE_CATALOGUES,
        payload: response
    };
}

export const showHeader = (label) => {
    return {
        type: SHOW_HEADER,
        payload: label
    };
};

export const changePassInput = (e) => {
    return {
        type: CHANGE_PASS_INPUT,
        payload: {
            type: e.target.name,
            value: e.target.value
        }
    };
};

export const confirmPassCheck = (password) => {
    return {
        type: CONFIRM_PASS_CHECK,
        payload: password
    };
};

export const passUpdate = (password) => {
    return function(dispatch) {
        return api('/partner/password/change', {
            method: 'POST',
            body: JSON.stringify({
                password: password.currentPass,
                password_new: password.newPass
            })
        }, dispatch)
            .then(res => {
                if (res === false) {
                    dispatch(passwordUpdateFail());
                } else {
                    dispatch(passwordDataClear());
                }
            });
    };
};

function passwordUpdateFail(res) {
    return {
        type: PASSWORD_UPDATE_FAIL,
        payload: res
    };
}

export const passwordMenuShow = () => {
    return {
        type: PASSWORD_MENU_SHOW
    };
};

export const passwordDataClear = () => {
    return {
        type: PASSWORD_DATA_CLEAR
    };
};

export const setFooterMinHeight = (minHeight) => {
    return {
        type: SET_FOOTER_MIN_HEIGHT,
        payload: minHeight
    };
};

export const changeDayTime = (label) => {
    return {
        type: CHANGE_DAY_TIME,
        payload: label
    };
};

export const changeScrollToOrder = (value) => {
    return {
        type: CHANGE_SCROLL_TO_ORDER,
        payload: value
    };
};

export const actions = {
    fetchCatalogues,
    showHeader,
    changePassInput,
    confirmPassCheck,
    passUpdate,
    passwordMenuShow,
    clearAjaxError,
    setFooterMinHeight,
    changeDayTime,
    changeScrollToOrder
};
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [REQUEST_CATALOGUES]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.catalogues = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_CATALOGUES]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.catalogues = false;
        return ({ ...state, fetching: fetching, catalogues: action.payload.items });
    },
    [REQUEST_PLAIN_CATALOGUES]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.plainCatalogues = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_PLAIN_CATALOGUES]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.plainCatalogues = false;
        return ({ ...state, fetching: fetching, plainCatalogues: action.payload.items });
    },
    [SHOW_HEADER]: (state, action) => {
        let showHeaderMenu = !state.showHeaderMenu;
        if (action.payload === 'hide') {
            showHeaderMenu = false;
        }
        return ({ ...state, showHeaderMenu: showHeaderMenu });
    },
    [CHANGE_PASS_INPUT]: (state, action) => {
        const password = Object.assign({}, state.password);

        let status = state.checkPassStatus;
        password[action.payload.type] = action.payload.value;
        if (action.payload.value && action.payload.type === 'confirmPass' && password.newPass === action.payload.value) {
            status = 'success';
        } else if (action.payload.value && action.payload.type === 'newPass' && password.confirmPass === action.payload.value) {
            status = 'success';
        } else if (action.payload.type !== 'currentPass') {
            status = null;
        }

        return ({ ...state, showErrors: false, password: password, checkPassStatus: status });
    },
    [CONFIRM_PASS_CHECK]: (state, action) => {
        let status = null;
        if (action.payload.confirmPass) {
            if (action.payload.newPass === action.payload.confirmPass) {
                status = 'success';
            } else {
                status = 'fail';
            }
        }

        return ({ ...state, checkPassStatus: status });
    },
    [PASSWORD_MENU_SHOW]: (state) => {
        return ({ ...state, passwordMenu: !state.passwordMenu });
    },
    [PASSWORD_DATA_CLEAR]: (state) => {
        return ({
            ...state,
            passwordMenu: false,
            password: { currentPass: '', confirmPass: '', newPass: '' },
            checkPassStatus: null
        });
    },
    [PASSWORD_UPDATE_FAIL]: (state) => {
        return ({ ...state, showErrors: true });
    },
    [SET_AJAX_ERROR]: (state, action) => {
        return ({ ...state, ajaxError: action.payload });
    },
    [CLEAR_AJAX_ERROR]: (state) => {
        return ({ ...state, ajaxError: null });
    },
    [SET_FOOTER_MIN_HEIGHT]: (state, action) => {
        return ({ ...state, footerMinHeight: action.payload });
    },
    [CHANGE_DAY_TIME]: (state, action) => {
        return ({ ...state, dayTime: action.payload });
    },
    [CHANGE_SCROLL_TO_ORDER]: (state, action) => {
        return ({ ...state, scrollToOrder: action.payload });
    }
};

const initialState = {
    catalogues: {},
    plainCatalogues: [],
    showHeaderMenu: false,
    password: {
        currentPass: '',
        confirmPass: '',
        newPass: ''
    },
    checkPassStatus: null,
    passwordMenu: false,
    showErrors: false,
    error: null,
    ajaxError: null,
    footerMinHeight: '300px',
    dayTime: 'day',
    scrollToOrder: false
};

export default function hangarReducer(state = initialState, action) {
    state = Object.assign({}, initialState, state);

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
