import { injectReducer } from '../../../../../store/reducers';

export default store => ({
    path: 'services',
    getComponent(nextState, cb) {
        Promise.all([
            import('./modules/services'),
            import('./container')
        ]).then(([reducer, /* webpackChunkName: "specs-settings" */Services]) => {
            injectReducer(store, { key: 'services', reducer: reducer.default });
            /*  Return getComponent   */
            cb(null, Services.default);
        });
    }
});
