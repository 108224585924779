import { injectReducer } from '../../store/reducers';

export default (store) => ({
    path: 'orders',
    /*  Async getComponent is only invoked when route matches   */
    getComponent(nextState, cb) {
        /*  Webpack - use 'require.ensure' to create a split point
         and embed an async module loader (jsonp) when bundling   */
        Promise.all([
            import('./modules/cmtOrders'),
            import('./containers/OrdersContainer')
        ]).then(([reducer, /* webpackChunkName: "orders" */OrdersContainer]) => {
            injectReducer(store, { key: 'cmtOrders', reducer: reducer.default });
            /*  Return getComponent   */
            cb(null, OrdersContainer.default);
        });
    }
});
