import React from 'react';
import Preloader from 'components/Preloader';
import _ from 'lodash';

require('./statistics.scss');

class Main extends React.Component {
    componentDidMount() {
        if (this.props.partner) {
            this.props.fetchCompany(this.props.partner.company_id, [
                'city',
                'divisions',
                'resources',
                'resources.divisions',
                'resources.schedules',
                'files',
                'files.thumbs',
                'notifications',
                'catalogues',
                'services'
            ]);
        }
        this.props.showHeader('hide');
    }

    changeMode() {

    }

    saveCompany(e) {
        e.preventDefault();
        const data = this.props.company;

        const expand = [
            'city',
            'divisions',
            'resources',
            'resources.divisions',
            'resources.schedules',
            'files',
            'files.thumbs',
            'notifications',
            'catalogues',
            'services'];
        // TODO удалить когда при регистрации компании будет задаваться город и в базе не будет пустых значений city_id
        // у компаний созданных до переделки города
        if (data.city_id === 0) {
            delete data.city_id;
        }
        // фильруем поле телефонов на предмет некорректных телефонов
        const phonesKey = _.findKey(this.props.company.fields, { label: 'company_phones' });

        const phones = this.props.company.fields[phonesKey];
        phones.value = _.filter(phones.value, phone => {
            return (phone.value !== '' && phone.label !== '');
        });
        this.props.saveCompany(data, expand);
    }

    render() {
        return (
            <div className='statistics'>
                { this.props.children }
                <Preloader trigger={this.props.fetching} />
            </div>
        );
    }
}

export default Main;
