import React from 'react';
import { Link } from 'react-router-dom';
require('./notfound.scss');

class NotFound extends React.Component {
    state = {
        page: 1
    };

    componentDidMount() {

    }

    UNSAFE_componentWillReceiveProps() {

    }

    render() {
        return (
            <div className='notFoundcomponent'>
                <div className='block'>
                    <Link to='/' className='logo' />
                    <div className='text'>
                        <div className='errorCode'>404</div>
                        <div className='errorText'>Страница<br /> не найдена</div>
                        <Link to='/'>На главную</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotFound;
