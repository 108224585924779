import React from 'react';
import classes from './Employees.module.scss';
import moment from 'moment';
import Button from 'components/Button';
import _ from 'lodash';
import Preloader from 'components/Preloader';

class Employees extends React.Component {
    UNSAFE_componentWillReceiveProps(nextProps) {
        const newParams = nextProps.currentDay; const params = this.props.currentDay;
        if (newParams !== params) {
            this.props.fetchEmployees({ department: 'callcenter', date: moment(newParams).format('YYYY-MM-DD') });
        }
    }

    renderTrack({ style, ...props }) {
        const trackStyle = {};
        return (
            <div
                className='scrollTrack'
                style={{ ...style, ...trackStyle }}
                {...props}
            />
        );
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {};
        return (
            <div
                className='scrollThumb'
                style={{ ...style, ...thumbStyle }}
                {...props}
            />
        );
    }

    krasnayaKnopka() {
        function func1() {
            alert('2...');
        }
        function func2() {
            alert('1...');
            alert('Наше заведение приветствует своих клиентов!!!');
        }
        alert('Бордель откроется через 3...');
        setTimeout(func1, 1000);
        setTimeout(func2, 2000);
    }

    render() {
        const currentDay = this.props.currentDay ? this.props.currentDay : moment().startOf('date');

        const spreadable = _.some(this.props.employees, ['working', true]) &&
                this.props.calls.length &&
                moment(currentDay).isSame(moment().startOf('date'));

        return (
            <div>
                <div className={classes.list}>
                    { _.map(this.props.employees, employee => {
                        return (
                            <div className={classes.employee} key={employee.id}>
                                <div className={employee.working ? classes.dotTrue : classes.dotFalse} />
                                <div className={employee.working ? classes.name : classes.nameInactive}>
                                    { employee.name }
                                </div>
                                <div
                                    className={employee.working ? classes.workingActive : classes.workingInactive}
                                    onClick={
                                        employee.working
                                            ? null
                                            : this.props.updateEmployeeSchedule.bind(this, employee.id, { date: moment(currentDay).format('YYYY-MM-DD') })
                                    }
                                >
                                    Работает
                                </div>
                                <div
                                    className={employee.working ? classes.notWorkingInactive : classes.notWorkingActive}
                                    onClick={
                                        employee.working
                                            ? this.props.updateEmployeeSchedule.bind(this, employee.id, { date: moment(currentDay).format('YYYY-MM-DD') })
                                            : null
                                    }
                                >
                                    Не работает
                                </div>
                            </div>
                        );
                    }) }
                </div>
                <div className={classes.btnContainer}>
                    <Button
                        value='Распределить задачи'
                        className={spreadable ? 'save' : 'blockedSave'}
                        onClick={spreadable ? this.props.spreadCalls.bind(this) : null}
                    />
                </div>
                <Preloader trigger={this.props.fetching.employees} />
            </div>
        );
    }
}

export default Employees;
