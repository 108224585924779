import React from 'react';
import Input from 'components/Input';
import { Link } from 'react-router-dom';
import InputElement from 'react-input-mask';

require('./remindPassword.scss');

class RemindPassword extends React.Component {
    defaultInterval = 60;
    decreaseInterval = 5;

    state = {
        allowIntervalTimer: null
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.partner && nextProps.token) {
            this.props.history.push('/');
        }
    }

    componentDidMount() {
        this.props.resetError();
        this.props.clearAjaxError();
        if (this.props.partner && this.props.token) {
            this.props.history.push('/');
        }
        this.props.setPhone(this.props.loginPhone);
    }

    componentWillUnmount() {
        if (this.allowIntervalTimer) {
            clearTimeout(this.allowIntervalTimer);
        }
    }

    submitPhone(e) {
        this.props.clearAjaxError();
        if (!this.props.phone) {
            this.props.setError('Введите номер телефона');
        } else if (this.props.phone.replace(/([^0-9+]*)/ig, '').length > 12) {
            this.props.setError('Указанный пользователь не найден');
        } else {
            this.setState({ allowInterval: this.defaultInterval });
            this.allowIntervalTimer = setTimeout(this.decreaseAllowInterval.bind(this), 1000 * this.decreaseInterval);
            this.props.submitPhone(e);
        }
    }

    decreaseAllowInterval() {
        const newInterval = this.state.allowInterval - this.decreaseInterval;

        this.setState({ allowInterval: newInterval });
        if (newInterval > 0) {
            this.allowIntervalTimer = setTimeout(this.decreaseAllowInterval.bind(this), 1000 * this.decreaseInterval);
        } else {
            this.allowIntervalTimer = null;
        }
    }

    submitCode(e) {
        this.props.clearAjaxError();
        if (!this.props.activationCode) {
            this.props.setError('Введите код активации');
        } else if (!/^\d{4}$/.test(this.props.activationCode)) {
            this.props.setError('Неверный SMS код');
        } else {
            this.props.submitCode(e);
        }
    }

    render() {
        switch (this.props.reminded) {
        case 'sendCode':
            return (
                <div className='remindPassword'>
                    <div className={'page' + (this.props.error ? ' error' : '')}>
                        <div className='inputs'>
                            <div className='wrapper'>
                                { this.props.error ? <h1>{ this.props.error.message }</h1> : <h1>Восстановление пароля</h1> }
                                <div className='row'>
                                    <Input
                                        type='text'
                                        name='activationCode'
                                        placeholder='XXXX'
                                        value={this.props.activationCode}
                                        onChange={this.props.changeValue}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') {
                                                this.submitCode(e);
                                            }
                                        }}
                                    />
                                    <div
                                        className='link'
                                        onClick={this.props.changeRemind.bind(this, 'sendPhone')}
                                    >назад
                                    </div>
                                </div>
                                <div className='submit'>
                                    <div onClick={this.submitCode.bind(this)}>выслать пароль</div>
                                </div>
                                <div className={'submit-repeat' + ((this.state.allowInterval > 0) ? ' disabled' : '')}>
                                    <div onClick={this.submitPhone.bind(this)}>
                                        выслать SMS повторно { (this.state.allowInterval > 0) ? (<span> (через { this.state.allowInterval }  сек)</span>) : null }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case 'sendPassword':
        case 'sendPhone':
        default:
            return (
                <div className='remindPassword'>
                    <div className={'page' + (this.props.error ? ' error' : '')}>
                        <div className='inputs'>
                            <div className='wrapper'>
                                { this.props.error ? <h1>{ this.props.error.message }</h1> : <h1>Восстановление пароля</h1> }
                                <div className='row'>
                                    <InputElement
                                        type='text'
                                        name='phone'
                                        placeholder='ваш телефон'
                                        value={this.props.phone}
                                        onChange={this.props.changeValue}
                                        mask='+7 (999) 999-99-99'
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') {
                                                this.submitPhone(e);
                                            }
                                        }}
                                    />
                                    <Link className='link' to='/login'>вернуться к авторизации</Link>
                                </div>
                                <div className='submit'>
                                    <div onClick={this.submitPhone.bind(this)}>выслать код</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default RemindPassword;
