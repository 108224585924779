import React from 'react';

require('./ClientSearch.scss');

class ClientSearch extends React.Component {
    state = {
        searchValue: ''
    };

    searchClients(e) {
        const clearValue = e.currentTarget.value; const value = e.currentTarget.value.trim();

        if (value.length > 1) {
            if (this.searchTimeOutId) {
                clearTimeout(this.searchTimeOutId);
            }
            const fetchClients = function() {
                this.props.fetchSearchClients(value, 0, 10, false);
            }.bind(this);
            this.searchTimeOutId = setTimeout(fetchClients, 250);
        } else {
            this.props.clearClients();
        }
        this.props.changeValue(e);
        this.setState({ searchValue: clearValue });
    }

    render() {
        return (
            <div className='clientSearch'>
                <input
                    type='text' name='clientsSearch'
                    autoComplete='off'
                    className={'search' + (this.props.clients && this.props.clients.entities.length ? ' withClear' : '')}
                    value={this.state.searchValue}
                    placeholder={this.props.placeholder || ''}
                    onChange={this.searchClients.bind(this)}
                    onClick={(e) => {
                        if (!this.props.open && this.props.openHandler) {
                            this.props.openHandler(e);
                        }
                    }}
                    onBlur={() => {
                        this.setState({ searchValue: '' });
                    }}
                />
                <div className='label'>Поиск клиента</div>
                <div
                    className={'clientSearch__clear' +
(this.props.clients && this.props.clients.entities.length ? '' : '--hidden')}
                    onClick={() => {
                        this.setState({ searchValue: '' });
                        this.props.clearClients();
                    }}
                />
            </div>
        );
    }
}

export default ClientSearch;
