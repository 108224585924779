import { api, httpBuildQuery } from './../utils';
import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
const ON_ORDER = 'orderMode';
const CHOOSE_TO_ORDER = 'chooseToOrder';

const SEND_ORDER = 'order';
const FINISH_ORDER = 'finishOrder';

const SEND_RESERVE = 'reserve';
const FINISH_RESERVE = 'finishReserve';

const CLEAR_ORDER = 'clearOrder';
const CONFIRM_CANCEL_ORDER = 'confirmCancelOrder';
const CANCEL_ORDER = 'cancelOrder';
const EXECUTE_ORDER = 'executeOrder';

const REQUEST_ORDERS = 'requestOrders';
const RECEIVE_ORDERS = 'receiveOrders';

const REQUEST_RESERVES = 'requestReserves';
const RECEIVE_RESERVES = 'receiveReserves';

const CANCEL_RESERVE = 'cancelReserve';

const SET_ORDER_DAY = 'setOrderDay';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchCancelOrder = (id) => {
    return function(dispatch) {
        dispatch(sendOrder());
        return api('/v3/partner/orders/' + id, {
            method: 'PUT',
            body: JSON.stringify({ origin: 'clubPartner', status: 'CANCELED' })
        }, dispatch)
            .then(() => dispatch(cancelOrder(id)));
    };
};

function cancelOrder(id) {
    return {
        type: CANCEL_ORDER,
        id: id
    };
}

export const fetchExecuteOrder = (id, e) => {
    if (e) {
        e.preventDefault();
    }
    return function(dispatch) {
        dispatch(sendOrder());
        return api('/v3/partner/orders/' + id, {
            method: 'PUT',
            body: JSON.stringify({ origin: 'clubPartner', status: 'EXECUTED' })
        }, dispatch)
            .then(() => dispatch(executeOrder(id)));
    };
};

function executeOrder(id) {
    return {
        type: EXECUTE_ORDER,
        id: id
    };
}

export const toConfirmCancelOrder = (e) => {
    if (e) {
        e.preventDefault();
    }
    return {
        type: CONFIRM_CANCEL_ORDER
    };
};

export const ordering = (mode) => {
    return {
        type: ON_ORDER,
        mode: mode
    };
};

export const fetchOrders = (companyId, data, merge = false) => {
    return function(dispatch) {
        dispatch(requestOrders());
        return api('/v3/partner/orders?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveOrders(response, merge)));
    };
};

function requestOrders() {
    return {
        type: REQUEST_ORDERS
    };
}

function receiveOrders(data, merge) {
    return {
        type: RECEIVE_ORDERS,
        merge: merge,
        data: data
    };
}

export const fetchReserves = (data, merge = false) => {
    return function(dispatch) {
        dispatch(requestReserves());
        return api('/v1/partner/reserves?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveReserves(response, merge)));
    };
};

function requestReserves() {
    return {
        type: REQUEST_RESERVES
    };
}

function receiveReserves(data, merge) {
    return {
        type: RECEIVE_RESERVES,
        merge: merge,
        data: data
    };
}

export const postOrder = (clientId, data) => {
    data.origin = 'clubPartner';
    return function(dispatch) {
        dispatch(sendOrder());
        return api('/v3/partner/orders', {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(response => dispatch(finishOrder(response)));
    };
};

export const updateOrderDetails = (id, data) => {
    data.origin = 'clubPartner';
    return function(dispatch) {
        dispatch(sendOrder());
        return api('/v3/partner/orders/' + id + '/details', {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: { 'X-Api-Token': localStorage.getItem('token') }
        }, dispatch)
            .then(response => dispatch(finishOrder(response)));
    };
};

function sendOrder() {
    return {
        type: SEND_ORDER
    };
}

function finishOrder(res) {
    return {
        type: FINISH_ORDER,
        payload: res
    };
}

export const postReserve = (data) => {
    data.origin = 'clubPartner';
    return function(dispatch) {
        dispatch(sendReserve());
        return api('/v3/partner/reserves', {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(response => dispatch(finishReserve(response)));
    };
};

function sendReserve() {
    return {
        type: SEND_RESERVE
    };
}

function finishReserve(res) {
    return {
        type: FINISH_RESERVE,
        payload: res
    };
}

export const fetchCancelReserve = (id, e) => {
    if (e) {
        e.preventDefault();
    }
    return function(dispatch) {
        dispatch(sendReserve());
        return api('/v3/partner/reserves/' + id + '/cancel?origin=clubPartnerMobile', {
            method: 'DELETE'
        }, dispatch)
            .then(() => dispatch(cancelReserve(id)));
    };
};

function cancelReserve(id) {
    return {
        type: CANCEL_RESERVE,
        id: id
    };
}

export const chooseToOrder = (data, e) => {
    if (e) {
        e.preventDefault();
        e.stopPropagation();
    }
    return {
        type: CHOOSE_TO_ORDER,
        data: data
    };
};

export const clearOrder = () => {
    return {
        type: CLEAR_ORDER
    };
};

export const setOrderDay = (day) => {
    return {
        type: SET_ORDER_DAY,
        payload: day
    };
};

export const actions = {
    fetchOrders,
    clearOrder,
    chooseToOrder,
    postOrder,
    updateOrderDetails,
    ordering,
    toConfirmCancelOrder,
    fetchCancelOrder,
    fetchExecuteOrder,
    fetchReserves,
    fetchCancelReserve,
    postReserve,
    setOrderDay
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [ON_ORDER]: (state, action) => {
        return ({
            ...state,
            orderMode: state.orderMode ? null
                : (action.mode ? action.mode : 'onOrder')
        });
    },
    [CHOOSE_TO_ORDER]: (state, action) => {
        let toOrder = _.cloneDeep(state.toOrder);
        if (toOrder === null) {
            toOrder = {};
        }
        if (action.data) {
            _.each(action.data, (value, key) => {
                toOrder[key] = value;
            });
        } else {
            toOrder = null;
        }
        return ({ ...state, toOrder: toOrder });
    },
    [SEND_ORDER]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.sendOrder = true;
        return ({ ...state, fetching });
    },
    [FINISH_ORDER]: (state, action) => {
        const fetching = Object.assign({}, state.fetching); const toOrder = Object.assign({}, state.toOrder);
        fetching.sendOrder = false;
        if (action.payload) {
            toOrder.status = 'finish';
        } else {
            toOrder.status = 'failed';
        }
        return ({ ...state, fetching, toOrder });
    },
    [SEND_RESERVE]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.sendOrder = true;
        return ({ ...state, fetching });
    },
    [FINISH_RESERVE]: (state, action) => {
        const fetching = Object.assign({}, state.fetching); const toOrder = Object.assign({}, state.toOrder);
        fetching.sendOrder = false;
        if (action.payload) {
            toOrder.status = 'finish';
        } else {
            toOrder.status = 'failed';
        }
        return ({ ...state, fetching, toOrder });
    },
    [CLEAR_ORDER]: (state) => {
        return ({ ...state, toOrder: null });
    },
    [REQUEST_ORDERS]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.orders = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_ORDERS]: (state, action) => {
        let orders;

        const fetching = Object.assign({}, state.fetching);
        fetching.orders = false;
        if (action.merge && _.get(state.orders, 'items')) {
            orders = Object.assign({}, state.orders);
            _.map(action.data.items, order => {
                if (!_.some(orders.items, { id: order.id })) {
                    orders.items.push(order);
                } else {
                    orders.items[_.findKey(orders.orders, { id: order.id })] = order;
                }
            });
        } else {
            orders = action.data;
        }
        return ({ ...state, orders: orders, fetching: fetching });
    },
    [CONFIRM_CANCEL_ORDER]: (state) => {
        return ({ ...state, confirmCancelOrder: !state.confirmCancelOrder });
    },
    [CANCEL_ORDER]: (state, action) => {
        const orders = Object.assign({}, state.orders);

        const fetching = Object.assign({}, state.fetching);
        fetching.sendOrder = false;
        orders.items = _.filter(orders.items, order => {
            return order.id !== action.id;
        });
        return ({ ...state, confirmCancelOrder: false, orders: orders, fetching: fetching });
    },
    [EXECUTE_ORDER]: (state, action) => {
        const orders = Object.assign({}, state.orders);
        _.some(orders.orders, order => {
            if (order.id === action.id) {
                order.status = 'EXECUTED';
            }
            return order.id === action.id;
        });
        return ({ ...state, orders: orders });
    },
    [REQUEST_RESERVES]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.reserves = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_RESERVES]: (state, action) => {
        let reserves;

        const fetching = Object.assign({}, state.fetching);
        fetching.reserves = false;
        if (action.merge) {
            reserves = Object.assign({}, state.reserves);
            _.map(action.data.reserves, reserve => {
                if (!_.some(reserves.items, { id: reserve.id })) {
                    reserves.items.push(reserve);
                } else {
                    reserves.items[_.findKey(reserves.items, { id: reserve.id })] = reserve;
                }
            });
        } else {
            reserves = { items: action.data.reserves, total: action.data.reservesTotalCount };
        }
        return ({ ...state, reserves: reserves, fetching: fetching });
    },
    [CANCEL_RESERVE]: (state, action) => {
        const reserves = Object.assign({}, state.reserves);

        const fetching = Object.assign({}, state.fetching);
        fetching.sendOrder = false;
        reserves.items = _.filter(reserves.items, item => {
            return item.id !== action.id;
        });
        return ({ ...state, reserves: reserves, fetching: fetching });
    },
    [SET_ORDER_DAY]: (state, action) => {
        return ({ ...state, orderDay: action.payload });
    }
};

const initialState = {
    fetching: { sendOrder: false, reserves: false, orders: false },
    orderMode: false,
    toOrder: null,
    orders: {},
    reserves: {},
    confirmCancelOrder: false,
    orderDay: null
};

export default function ordersReducer(state = initialState, action) {
    state = Object.assign({}, initialState, state);

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
