import React from 'react';
import _ from 'lodash';

require('./peninput.scss');

class Peninput extends React.Component {
    constructor(props) {
        super(props);
        this.editTrigger = this.editTrigger.bind(this);
    }

    state = {
        onEdit: false
    };

    editTrigger() {
        const onEdit = (this.props.onEdit === false || this.props.onEdit === true) ? this.props.onEdit : this.state.onEdit;
        if (onEdit) {
            if (this.props.onSave) {
                this.props.onSave(this.props.value);
            }
            this.setState({ onEdit: false });
        } else {
            if (this.props.onTrigger) {
                this.props.onTrigger();
            }
            this.setState({ onEdit: true });
        }
    }

    render() {
        const onEdit = (this.props.onEdit === false || this.props.onEdit === true) ? this.props.onEdit : this.state.onEdit;
        return (
            <div className={'peninput' + (this.props.className ? this.props.className : '')}>
                { onEdit ? (
                    <div className='peninput__label peninput__label--onEdit'>
                        <input
                            type='text'
                            value={this.props.value}
                            onChange={this.props.changeHandler.bind(this)}
                            autoFocus
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    this.editTrigger();
                                } else {
                                    return null;
                                }
                            }}
                        />
                        <div className='peninput-trigger--save' onClick={this.editTrigger} />
                    </div>
                ) : (
                    <div className='peninput__label peninput__label' onClick={this.editTrigger}>
                        { _.isEmpty(this.props.value) ? this.props.defaultValue : this.props.value } <div className='peninput-trigger' />
                    </div>
                ) }
            </div>
        );
    }
}

Peninput.contextTypes = {};

export default Peninput;
