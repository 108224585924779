import { connect } from 'react-redux';
import { fetchEmployees, updateEmployeeSchedule } from '../../../../../modules/company';
import { spreadCalls } from '../../../../../modules/calls';

import Employees from './Employees';

const mapStateToProps = (state) => ({
    employees: state.company.employees,
    calls: state.calls.calls,
    fetching: state.company.fetching,
    currentDay: state.callcenter.currentDay
});

export default connect(mapStateToProps, Object.assign({}, {
    fetchEmployees,
    updateEmployeeSchedule,
    spreadCalls
}))(Employees);
