import { connect } from 'react-redux';
import { actions } from '../../modules/hangar';
import { logout, storeOnesignalId } from '../../modules/auth';
import { fetchSchedules } from 'modules/company';
import { fetchOrders, fetchReserves } from 'modules/orders';
import { fetchEvents, pushEvent } from 'modules/events';

import CoreLayout from './CoreLayout';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => ({
    currentDay: state.company.currentDay,
    error: state.hangar.ajaxError,
    partner: state.account.partner,
    token: state.account.token,
    onesignalId: state.account.onesignalId,
    eventsType: state.events.eventsType
});

export default connect(
    mapStateToProps,
    Object.assign(actions, { fetchSchedules, fetchOrders, fetchReserves, fetchEvents, pushEvent, logout, storeOnesignalId })
)(withRouter(CoreLayout));
