import React from 'react';
import Header from 'components/Header';
// require('./frontframe.scss');

class FrontFrame extends React.Component {
    render() {
        return (
            <div className='frontframe'>
                <Header {...this.props} />
                { this.props.children }
            </div>
        );
    }
}

export default FrontFrame;
