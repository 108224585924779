/**
 * Created by user on 26.05.2016.
 */

import { connect } from 'react-redux';

import { actions } from 'modules/company';

import Chunk from './Chunk';

const mapStateToProps = () => ({
/*    company: state.company.company,
    schedules: state.company.schedules,
    toOrder: state.orders.toOrder,
    partner: state.account.partner,
    client: state.clients.client    */
});

export default connect(mapStateToProps, actions)(Chunk);
