import { connect } from 'react-redux';

import Download from './../components';

const mapStateToProps = (state) => ({
    partner: state.account.partner,
    token: state.account.token
});

export default connect(mapStateToProps, {})(Download);
