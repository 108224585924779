/**
 * Created by user on 17.05.2016.
 */
import React from 'react';
import './Preloader.scss';

export const Preloader = (props) => (
    <div className={'preloader' + (props.trigger ? ' on' : '') + (props.fixed ? ' fixed' : '') + (props.notPointed ? ' notPointed' : '')} />
);

Preloader.propTypes = {

};

export default Preloader;
