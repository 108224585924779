import React from 'react';
import cx from 'classnames';
import { HELP_CSS } from 'utils';
import _ from 'lodash';
import Editor from './Editor';

import './styles_services.scss';
import ServiceTemplate from './ServiceTemplate';

const BUSINESS_TYPES = { 1: 'auto', 2: 'beauty', 3: 'catering' };

class Services extends React.Component {
    state = {
        expandedTree: [],
        serviceTemplateForEdit: false,
        showServices: false,
        linkedCatalogues: null,
        _linkedCatalogues: [],
        _unlinkedCatalogues: [],
        servicedCatalogues: null
    };

    servicedTemplatesIds = [];

    componentDidMount() {
        if (this.props.company && this.props.company.id && _.get(this.props.company, 'angaraInfo.businessType.id')) {
            this.fetchCataloguesHandler(this.props.company);
            this.fetchPlainCataloguesHandler(this.props.company);
        }

        if (_.get(this.props.company, 'services.services') && this.props.plainCatalogues.length &&
            this.state.servicedCatalogues === null && this.state.linkedCatalogues === null) {
            this.setState({
                servicedCatalogues: this.generateLinkedCatalogues(this.props.plainCatalogues),
                linkedCatalogues: localStorage.getItem('linkedCatalogues')
                    ? JSON.parse(localStorage.getItem('linkedCatalogues'))
                    : []
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.company.id !== nextProps.company.id && _.get(nextProps.company, 'angaraInfo.businessType.id')) {
            this.fetchCataloguesHandler(nextProps.company);
            this.fetchPlainCataloguesHandler(nextProps.company);
        }
        if (_.get(this.props.company, 'services.services') && nextProps.plainCatalogues.length &&
            this.state.servicedCatalogues === null && this.state.linkedCatalogues === null) {
            this.setState({
                servicedCatalogues: this.generateLinkedCatalogues(nextProps.plainCatalogues),
                linkedCatalogues: localStorage.getItem('linkedCatalogues')
                    ? JSON.parse(localStorage.getItem('linkedCatalogues'))
                    : []
            });
        }
    }

    componentDidUpdate() {
        this.updateCompanySpecs();
    }

    updateCompanySpecs() {
        if (this.state.servicedCatalogues) {
            const company = this.props.company;

            const companySpecsKey = _.findKey(company.fields, { label: 'company_specs' });

            const companySpecs = _.get(company.fields, companySpecsKey + '.value', []);
            if (_.some(this.state.servicedCatalogues, sc => (companySpecs.indexOf(sc) === -1))) {
                this.props.changeCompany(`fields.${companySpecsKey}.value`, this.state.servicedCatalogues);
                this.props.updateCompanySpecs(company.id, this.state.servicedCatalogues);
            }
        }
    }

    fetchCataloguesHandler(company) {
        this.props.fetchCatalogues({
            root_only: 1,
            expand: 'childrenCatalogues,businessType,image,thumbnails,serviceTemplates,serviceOptionTemplates,reference,values',
            business_type_id: company.angaraInfo.businessType.id
        });
    }

    fetchPlainCataloguesHandler(company) {
        this.props.fetchPlainCatalogues({
            root_only: 0,
            business_type_id: company.angaraInfo.businessType.id
        });
    }

    getServicedTemplatesIds() {
        return _.map(_.get(this.props.company, 'services.services'), s => s.serviceTemplate.id);
    }

    getCataloguePath(plainCatalogues, catalogueId) {
        const result = [catalogueId];
        let catalogue = _.find(plainCatalogues, { id: catalogueId });

        while (_.get(catalogue, 'parentCatalogue.id')) {
            catalogue = _.find(plainCatalogues, { id: _.get(catalogue, 'parentCatalogue.id') });
            result.push(catalogue.id);
        }
        return result;
    }

    generateLinkedCatalogues(plainCatalogues) {
        let linkedCatalogues = [];
        _.each(_.get(this.props.company, 'services.services'), (service) => {
            const cataloguePath = this.getCataloguePath(plainCatalogues, _.get(service, 'serviceTemplate.catalogue.id'));
            linkedCatalogues = linkedCatalogues.concat(cataloguePath);
        });
        return _.uniq(linkedCatalogues);
    }

    generateChildrenCatalogues(catalogue) {
        let children = [catalogue];
        const childrenCatalogues = _.get(_.find(_.get(this.props.company, 'catalogues.catalogues'), { id: catalogue }), 'childrenCatalogues');
        _.each(childrenCatalogues, (c) => {
            children = children.concat(this.generateChildrenCatalogues(c.id));
        });
        return children;
    }

    getCataloguesTree(catalogues, companyCataloguesIds, parentsCataloguesIds = []) {
        return _.map(_.sortBy(catalogues, ['sort', 'id']), (catalogue) => {
            const parentsCataloguesIdsCopy = Object.assign([], parentsCataloguesIds);
            parentsCataloguesIdsCopy.push(catalogue.id);
            const catalogueTemplates = _.filter(_.get(catalogue, 'serviceTemplates', []), t => {
                return !t.isCustom;
            });
            _.each(catalogueTemplates, (t) => {
                t.catalogue = catalogue;
                t.services = _.filter(this.props.company.services.services, s => s.serviceTemplate.id === t.id);
            });

            const catalogueIcon = _.get(_.find(_.get(catalogue, 'image.thumbnails', []), { templateName: 's1' }), 'url', false);
            const linked = (_.includes(this.state.linkedCatalogues, catalogue.id) ||
                _.includes(this.state.servicedCatalogues, catalogue.id) ||
                _.includes(this.state._linkedCatalogues, catalogue.id)) &&
                !_.includes(this.state._unlinkedCatalogues, catalogue.id);
            const businessType = BUSINESS_TYPES[_.get(this.props.company, 'angaraInfo.businessType.id', 1)] || _.first(BUSINESS_TYPES);
            return (
                <div
                    className={cx({
                        'settings-block__catalogues__link link': true,
                        active: this.state.expandedTree.indexOf(catalogue.id) !== -1,
                        withChildren: _.get(catalogue.childrenCatalogues, 'length', false) || _.get(catalogue, 'serviceTemplates.length', false),
                        unlinked: !linked
                    })}
                    key={catalogue.id}
                >
                    <div
                        className='catalogueName'
                        onClick={() => {
                            const expandedTree = this.state.expandedTree;
                            if (expandedTree.indexOf(catalogue.id) === -1) {
                                expandedTree.push(catalogue.id);
                            } else {
                                expandedTree.splice(expandedTree.indexOf(catalogue.id), 1);
                            }
                            this.setState({ expandedTree });
                        }}
                    >
                        <div className={'catalogueIcon ' + businessType}>
                            {
                                catalogueIcon
                                    ? (<img src={catalogueIcon} alt='' />)
                                    : null
                            }
                            <div
                                className={HELP_CSS.hlpCheckbox + (
                                    linked
                                        ? ' is_on'
                                        : '')}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    const cataloguePath = this.getCataloguePath(this.props.plainCatalogues, catalogue.id);
                                    if (!linked) {
                                        const _unlinkedCatalogues = _.filter(this.state._unlinkedCatalogues, c => cataloguePath.indexOf(c) === -1);
                                        let _linkedCatalogues = this.state._linkedCatalogues;
                                        if (_unlinkedCatalogues.length === this.state._unlinkedCatalogues.length) {
                                            _linkedCatalogues = _linkedCatalogues.concat(cataloguePath);
                                        }
                                        this.setState({ _linkedCatalogues, _unlinkedCatalogues });
                                    } else {
                                        const _linkedCatalogues = _.filter(this.state._linkedCatalogues, c => c !== catalogue.id);
                                        const _unlinkedCatalogues = this.state._unlinkedCatalogues;
                                        if (_linkedCatalogues.length === this.state._linkedCatalogues.length) {
                                            _unlinkedCatalogues.push(catalogue.id);
                                        }
                                        this.setState({ _linkedCatalogues, _unlinkedCatalogues });
                                    }
                                }}
                            />
                        </div>
                        <span>{ catalogue.name }</span>
                    </div>
                    {
                        this.state.expandedTree.indexOf(catalogue.id) !== -1
                            ? (
                                <div className='catalogueTemplates'>
                                    { _.map(catalogueTemplates, t => {
                                        const templateIcon = _.get(_.find(_.get(t, 'image.thumbnails', []), { templateName: 's1' }), 'url', false);
                                        return (
                                            <div key={t.id} className='catalogueTemplates__template'>
                                                <div className={'catalogueTemplates__template__icon ' + businessType}>
                                                    { templateIcon ? (<mg src={templateIcon} alt='' />) : null }
                                                </div>
                                                { t.name }
                                            </div>
                                        );
                                    }) }
                                </div>
                            )
                            : null
                    }
                    {
                        this.state.expandedTree.indexOf(catalogue.id) !== -1
                            ? this.getCataloguesTree(catalogue.childrenCatalogues, companyCataloguesIds, parentsCataloguesIdsCopy)
                            : null
                    }
                </div>
            );
        });
    }

    getServicesCataloguesTree(catalogues, companyCataloguesIds, parentsCataloguesIds = []) {
        return _.map(_.filter(_.sortBy(catalogues, ['sort', 'id']),
            c => (this.state.servicedCatalogues && this.state.servicedCatalogues.indexOf(c.id) !== -1) ||
                (this.state.linkedCatalogues && this.state.linkedCatalogues.indexOf(c.id) !== -1)), (catalogue) => {
            const parentsCataloguesIdsCopy = Object.assign([], parentsCataloguesIds);
            parentsCataloguesIdsCopy.push(catalogue.id);

            const catalogueTemplates = _.get(catalogue, 'serviceTemplates', []);

            const servicedTemplates = [];

            const unservicedTemplates = [];
            _.each(catalogueTemplates, (t) => {
                t.catalogue = catalogue;
                t.services = _.filter(this.props.company.services.services, s => s.serviceTemplate.id === t.id);
                if (this.servicedTemplatesIds.indexOf(t.id) !== -1) {
                    servicedTemplates.push(t);
                } else if (!t.isCustom) {
                    unservicedTemplates.push(t);
                }
            });

            const catalogueIcon = _.get(_.find(_.get(catalogue, 'image.thumbnails', []), { templateName: 's1' }), 'url', false);
            return (
                <div
                    className={cx({
                        'settings-block__services__link link': true,
                        active: this.state.expandedTree.indexOf(catalogue.id) !== -1,
                        withChildren: _.get(catalogue.childrenCatalogues, 'length', false)
                    })}
                    key={catalogue.id}
                >
                    <div
                        className='catalogueName'
                        onClick={() => {
                            const expandedTree = this.state.expandedTree;
                            if (expandedTree.indexOf(catalogue.id) === -1) {
                                expandedTree.push(catalogue.id);
                            } else {
                                expandedTree.splice(expandedTree.indexOf(catalogue.id), 1);
                            }
                            this.setState({ expandedTree });
                        }}
                    >
                        <div className='catalogueIcon'>
                            {
                                catalogueIcon
                                    ? (<img src={catalogueIcon} alt='' />)
                                    : null
                            }
                        </div>
                        <span>{ catalogue.name }</span>
                    </div>
                    {
                        this.state.expandedTree.indexOf(catalogue.id) !== -1
                            ? (
                                <div className='catalogueServices'>
                                    {
                                        servicedTemplates.length
                                            ? (
                                                <div className='catalogueServices__services'>
                                                    <div className='theader'>
                                                        <div>Подключенные услуги</div>
                                                        <div>Опции</div>
                                                        <div>Длительность</div>
                                                        <div>Стоимость1</div>
                                                    </div>
                                                    <div className='body'>
                                                        {
                                                            _.map(servicedTemplates, t => {
                                                                return (
                                                                    <ServiceTemplate
                                                                        key={t.id}
                                                                        serviceTemplate={_.pick(t, [
                                                                            'id',
                                                                            'name',
                                                                            'isCustom',
                                                                            'minPrice',
                                                                            'averageDuration',
                                                                            'image',
                                                                            'services',
                                                                            'serviceOptionTemplates'
                                                                        ])}
                                                                        removeHandler={this.removeServices.bind(this)}
                                                                        updateHandler={this.updateService.bind(this)}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            this.setState({ serviceTemplateForEdit: t });
                                                                        }}
                                                                    />
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                            : null
                                    }
                                    {
                                        unservicedTemplates.length
                                            ? (
                                                <div className='catalogueServices__templates'>
                                                    <div className='theader'>
                                                        <div>Доступны для подключения</div>
                                                        <div>Опции</div>
                                                        <div>{ /* Длительность */ }</div>
                                                        <div>{ /* Стоимость */ }</div>
                                                    </div>
                                                    <div className='body'>
                                                        {
                                                            _.map(unservicedTemplates, t => (
                                                                <ServiceTemplate
                                                                    key={t.id}
                                                                    serviceTemplate={_.pick(t, [
                                                                        'id',
                                                                        'name',
                                                                        'isCustom',
                                                                        'minPrice',
                                                                        'averageDuration',
                                                                        'image',
                                                                        'services'
                                                                    ])}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        this.setState({ serviceTemplateForEdit: t });
                                                                    }}
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            )
                                            : null
                                    }
                                </div>
                            )
                            : null
                    }
                    {
                        (this.state.expandedTree.indexOf(catalogue.id) !== -1)
                            ? (
                                <div
                                    className='addService'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.setState({
                                            serviceTemplateForEdit: {
                                                id: null,
                                                isCustom: true,
                                                catalogue
                                            }
                                        });
                                    }}
                                >
                                    <div className={HELP_CSS.hlpAddIconBig} />
                                    <span>Добавить свою услугу</span>
                                </div>
                            )
                            : null
                    }
                    {
                        this.state.expandedTree.indexOf(catalogue.id) !== -1
                            ? this.getServicesCataloguesTree(catalogue.childrenCatalogues, companyCataloguesIds, parentsCataloguesIdsCopy)
                            : null
                    }
                </div>
            );
        });
    }

    getChildCataloguesIds(catalogue, companyCataloguesIds) {
        _.map(catalogue.childrenCatalogues, (childrenCatalogue) => {
            const childrenCatalogueKey = companyCataloguesIds.indexOf(childrenCatalogue.id);
            if (childrenCatalogueKey !== -1) {
                delete companyCataloguesIds[childrenCatalogueKey];
            }
            if (childrenCatalogue.childrenCatalogues.length) {
                companyCataloguesIds = this.getChildCataloguesIds(childrenCatalogue, companyCataloguesIds);
            }
        });

        return companyCataloguesIds;
    }

    updateService(service) {
        this.props.updateService(service, service.id);
    }

    unlinkCatalogueMessage(catalogues) {
        let confirmMessage = 'Вы уверены, что хотите открепить эту рубрику?';

        let childrenNames = [];

        let serviceNames = [];

        _.each(catalogues, (c) => {
            const children = this.generateChildrenCatalogues(c);

            const unlinkedCatalogues = _.filter(this.state.linkedCatalogues, lc => children.indexOf(lc) !== -1 && lc !== c.id);

            const linkedServices = _.filter(_.get(this.props.company, 'services.services'), service => children.indexOf(service.serviceTemplate.catalogue.id) !== -1);

            childrenNames = childrenNames.concat(_.map(unlinkedCatalogues, (child) => {
                const catalogue = _.find(this.props.plainCatalogues, { id: child });
                return catalogue.name;
            }));
            serviceNames = serviceNames.concat(_.map(linkedServices, ls => ls.serviceTemplate.name));
        });

        if (childrenNames.length) {
            confirmMessage = `${confirmMessage} Будут откреплены дочерние каталоги: ${childrenNames.join(', ')}.`;
        }
        if (serviceNames.length) {
            confirmMessage = `${confirmMessage} Будут удалены услуги: ${serviceNames.join(', ')}.`;
        }

        return confirmMessage;
    }

    unlinkCatalogue(catalogues) {
        let linkedServices = [];
        _.each(catalogues, (c) => {
            const children = this.generateChildrenCatalogues(c);
            linkedServices = linkedServices.concat(_.filter(_.get(this.props.company, 'services.services'), service => children.indexOf(service.serviceTemplate.catalogue.id) !== -1));
        });
        _.each(linkedServices, (service) => {
            this.props.deleteService(service.id);
        });
    }

    removeServices(services) {
        if (window.confirm('Вы уверены, что хотите открепить данную услугу?')) {
            _.each(services, (service) => {
                this.props.deleteService(service.id);
            });
        }
    }

    render() {
        const company = this.props.company;

        const companyCatalogues = _.find(company.fields, {
            label: 'company_specs'
        }, {});

        const companyCataloguesIds = _.get(companyCatalogues, 'value', []);
        this.servicedTemplatesIds = this.getServicedTemplatesIds();

        const catalogues = _.filter(this.props.catalogues, catalogue => _.get(catalogue, 'businessType.id') === _.get(company, 'angaraInfo.businessType.id', null));

        const editor = (
            (this.state.serviceTemplateForEdit)
                ? (
                    <div
                        className='black-wrapper'
                        onClick={() => {
                            this.setState({ serviceTemplateForEdit: null });
                        }}
                    >
                        <Editor
                            closeEditor={(e) => {
                                if (e) {
                                    e.preventDefault();
                                }
                                this.setState({ serviceTemplateForEdit: null });
                            }}
                            serviceTemplateForEdit={this.state.serviceTemplateForEdit}
                        />
                    </div>
                )
                : null);

        return (
            <div className='settings-block settings-block--services'>
                { editor }
                <h1 className='settings-block__h1'>Услуги компании</h1>

                {
                    this.state.mode === 'specializations'
                        ? (
                            <div className='settings-block__catalogues'>
                                <div className='settings-block--catalogues__buttons'>
                                    <div
                                        className='editSpec editSpec--cancel'
                                        onClick={() => {
                                            this.setState({
                                                _linkedCatalogues: [],
                                                _unlinkedCatalogues: [],
                                                mode: 'services'
                                            });
                                        }}
                                    >
                                        Отмена
                                    </div>
                                    <div
                                        className='editSpec editSpec--save'
                                        onClick={() => {
                                            if (this.state._unlinkedCatalogues.length === 0 || window.confirm(this.unlinkCatalogueMessage(this.state._unlinkedCatalogues))) {
                                                const linkedCatalogues = _.filter(this.state.linkedCatalogues.concat(this.state._linkedCatalogues), c => this.state._unlinkedCatalogues.indexOf(c) === -1);
                                                localStorage.setItem('linkedCatalogues', JSON.stringify(linkedCatalogues));
                                                const servicedCatalogues = _.filter(this.state.servicedCatalogues, c => this.state._unlinkedCatalogues.indexOf(c) === -1);
                                                this.unlinkCatalogue(this.state._unlinkedCatalogues);
                                                this.setState({
                                                    _linkedCatalogues: [],
                                                    _unlinkedCatalogues: [],
                                                    linkedCatalogues,
                                                    servicedCatalogues,
                                                    mode: 'services'
                                                });
                                            } else {
                                                this.setState({
                                                    _linkedCatalogues: [],
                                                    _unlinkedCatalogues: [],
                                                    mode: 'services'
                                                });
                                            }
                                            this.setState({ mode: 'services' });
                                        }}
                                    >
                                        Сохранить изменения
                                    </div>
                                </div>
                                { this.getCataloguesTree(catalogues, companyCataloguesIds) }
                            </div>
                        )
                        : (
                            <div className='settings-block__services'>
                                <div className='infoHeader'>
                                    <div>
                                        Подключены:&nbsp;
                                        <span
                                            className='red'
                                        >{ _.get(this.props.company, 'services.services.length', '...') }
                                        </span>
                                    </div>
                                    <div onClick={() => {
                                        this.setState({ showUnserviced: !this.state.showUnserviced });
                                    }}
                                    >
                                        Не прикреплены к мастерам:&nbsp;
                                        <span className='red'>
                                            {
                                                _.filter(_.get(this.props.company, 'services.services', []), s => s.resources && s.resources.length === 0).length
                                            }
                                        </span>
                                        {
                                            this.state.showUnserviced
                                                ? (
                                                    <div className='toolBar'>
                                                        { _.map(_.filter(_.get(this.props.company, 'services.services', []), s => s.resources && s.resources.length === 0), s => {
                                                            return (
                                                                <div
                                                                    key={s.id} onClick={() => {
                                                                        const catalogue = _.find(this.props.catalogues, { id: s.serviceTemplate.catalogue.id });

                                                                        const serviceTemplate = _.find(
                                                                            _.get(catalogue, 'serviceTemplates', []),
                                                                            { id: s.serviceTemplate.id });
                                                                        if (serviceTemplate) {
                                                                            this.setState({
                                                                                showUnserviced: false,
                                                                                serviceTemplateForEdit: serviceTemplate
                                                                            });
                                                                        }
                                                                    }}
                                                                >
                                                                    { s.serviceTemplate.name }
                                                                </div>
                                                            );
                                                        }) }
                                                    </div>
                                                ) : null
                                        }
                                    </div>
                                </div>
                                <div
                                    className='editSpec'
                                    onClick={() => {
                                        this.setState({ mode: 'specializations' });
                                    }}
                                >
                                    Добавить или удалить раздел
                                </div>
                                { this.getServicesCataloguesTree(catalogues, companyCataloguesIds) }
                            </div>
                        )
                }

            </div>
        );
    }
}

export default Services;
