/**
 * Created by user on 25.05.2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classes from './Statistic.module.scss';
import Preloader from 'components/Preloader';
import DatePicker from 'components/DatePicker';
import Question from '../../../../Modals/Question';
import { httpBuildQuery } from 'utils';
import _ from 'lodash';

const PARAMS_COLORS =
    [
        { values: ['#ec7e00', '#29afd9'], classNames: ['paramFirstYes', 'paramFirstNo'] },
        { values: ['#85d0a4', '#ce95c7'], classNames: ['paramSecondYes', 'paramSecondNo'] },
        { values: ['#47ba90', '#c32933'], classNames: ['paramThirdYes', 'paramThirdNo'] }
    ];

const PARAMS_COLORS_SET_COUNT = 3;

const CHECKBOX_ANSWERS_COLORS = ['#1d7590', '#29afd9', '#47ba90'];

const CHECKBOX_COLORS_SET_COUNT = 3;

class Statistic extends React.Component {
    state = {

    };

    UNSAFE_componentWillMount() {
        const request = {
            date_from: this.props.statisticDates.rangeStart.format('YYYY-MM-DD'),
            date_to: this.props.statisticDates.rangeEnd.format('YYYY-MM-DD')
        };
        this.props.fetchStatistic(request);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.showQuestionModal && !this.props.showQuestionModal) {
            const body = document.querySelectorAll('body');

            const html = document.querySelectorAll('html');
            if (body[0] && body[0].style) {
                body[0].style.overflowY = 'hidden';
            }
            if (html[0] && html[0].style) {
                html[0].style.overflowY = 'hidden';
            }
        }
        if (!nextProps.showQuestionModal && this.props.showQuestionModal) {
            const body = document.querySelectorAll('body');

            const html = document.querySelectorAll('html');
            if (body[0] && body[0].style) {
                body[0].style.overflowY = 'auto';
            }
            if (html[0] && html[0].style) {
                html[0].style.overflowY = 'auto';
            }
        }

        const newParams = nextProps.statisticDates; const oldParams = this.props.statisticDates;

        if (newParams.rangeStart.format() !== oldParams.rangeStart.format() || newParams.rangeEnd.format() !== oldParams.rangeEnd.format()) {
            const request = {
                date_from: newParams.rangeStart.format('YYYY-MM-DD'),
                date_to: newParams.rangeEnd.format('YYYY-MM-DD')
            };
            this.props.fetchStatistic(request);
        }
    }

    generateChart(answers, colors) {
        const results = []; let offset = 25;

        _.map(answers, (answer, key) => {
            if (!answer) {
                return null;
            }
            const percent = _.get(answer, 'percent', 0);
            results.push(
                <circle
                    key={answer.id}
                    cx='19'
                    cy='21'
                    r='15.91549430918954'
                    fill='transparent'
                    stroke={colors[key]}
                    strokeWidth='6'
                    strokeDasharray={percent + ' ' + (100 - percent)}
                    strokeDashoffset={offset}
                />
            );
            if (offset < percent) {
                offset = 100 + offset - percent;
            } else {
                offset = offset - percent;
            }
        });

        return (
            <svg width='185px' height='185px' viewBox='0 0 42 42'>
                <circle cx='19' cy='21' r='15.91549430918954' fill='#fff' />
                <circle cx='19' cy='21' r='15.91549430918954' fill='transparent' stroke='#d2d3d4' strokeWidth='6' />

                { results }
            </svg>
        );
    }

    getQuestionInfo(count) {
        const rangeStart = this.props.statisticDates.rangeStart;

        const rangeEnd = this.props.statisticDates.rangeEnd;
        return (
            <div className={classes.info}>С
                <DatePicker
                    dateFormatCalendar='MMMM'
                    selected={rangeStart}
                    className='inline'
                    align={['center', 'bottom']}
                    onChange={(date) => { this.props.changeStatisticsDates(date, 'rangeStart'); }}
                    locale='ru'
                    showYearDropdown
                    openByDateClick
                />
                по
                <DatePicker
                    dateFormatCalendar='MMMM'
                    selected={rangeEnd}
                    className='inline'
                    align={['center', 'bottom']}
                    onChange={(date) => { this.props.changeStatisticsDates(date, 'rangeEnd'); }}
                    locale='ru'
                    showYearDropdown
                    openByDateClick
                />
                Опрошено { count } человек
            </div>
        );
    }

    getCountLink(questionId, answerId, count, percent, className) {
        const { statisticDates } = this.props;

        const data = {
            question_id: questionId,
            answer_id: answerId,
            date_from: statisticDates.rangeStart.format('YYYY-MM-DD'),
            date_to: statisticDates.rangeEnd.format('YYYY-MM-DD')
        };

        return (
            <Link
                key={'link' + answerId} to={'/okk/reports/by-answer?' + httpBuildQuery(data)}
                className={classes[className]}
            >
                { count } чел.({ percent }%)
            </Link>
        );
    }

    showParamsQuestionModal(params, question) {
        const data = {
            poll_id: _.get(params, 'id'),
            title: '',
            type: 'radio',
            sorting: _.get(params, 'questions.length') + 1
        };

        if (question) {
            data.id = question.id;
            data.title = question.title;
        }

        this.props.changeQuestionModal(data, true);
    }

    changeQuestionTitle(e) {
        const data = Object.assign({}, this.props.questionModalData);
        data.title = e.target.value;

        this.props.changeQuestionModal(data, true);
    }

    saveQuestion(data) {
        if (data.id) {
            this.props.updateQuestion(data);
        } else {
            this.props.createQuestion(data);
        }
    }

    render() {
        let questionModal;
        if (this.props.showQuestionModal) {
            questionModal = (
                <Question
                    onClick={this.props.changeQuestionModal.bind(this, null, false)}
                    save={this.saveQuestion.bind(this)}
                    changeQuestionTitle={this.changeQuestionTitle.bind(this)}
                    data={this.props.questionModalData}
                    archive={this.props.archiveQuestion.bind(this)}
                />
            );
        }

        const { statistic } = this.props;

        const polls = _.get(statistic, 'polls', null);

        const general = _.find(polls, ['label', 'general']);

        const params = _.find(polls, ['label', 'params']);

        const wishes = _.find(polls, ['label', 'wishes']);

        const defences = _.find(polls, ['label', 'defences']);

        let wow, good, bad;

        if (general) {
            const answers = _.get(general, 'questions[0].answers');
            wow = _.find(answers, ['title', 'Вау']);
            good = _.find(answers, ['title', 'Хорошо']);
            bad = _.find(answers, ['title', 'Плохо']);
        }

        return (
            <div className={classes.main}>
                { questionModal }
                <div className={classes.pollBlock}>
                    <div className={classes.title}>Блок общей оценки качества</div>
                    <div className={classes.questionBlock}>
                        <div className={classes.number}>
                            <div>1</div>
                        </div>
                        <div className={classes.question}>
                            <div className={classes.value}>{ _.get(general, 'questions[0].title') }</div>
                            { this.getQuestionInfo(_.get(general, 'questions[0].totalAnswersCount')) }
                        </div>
                        <div className={classes.button + ' ' + classes.active}>Диаграмма</div>
                        <div className={classes.button}>График</div>
                    </div>
                    <div className={classes.chart}>
                        { this.generateChart([wow, good, bad], ['#e66050', '#f8cc9e', '#ef952e']) }
                        <div className={classes.answerColor + ' ' + classes.generalWow} />
                        <div className={classes.answer}>
                            <div className={classes.value}>Вау!</div>
                            { this.getCountLink(_.get(general, 'questions[0].id', 0), _.get(wow, 'id', 0), _.get(wow, 'count'), _.get(wow, 'percent'), 'count') }
                        </div>
                        <div className={classes.answerColor + ' ' + classes.generalGood} />
                        <div className={classes.answer}>
                            <div className={classes.value}>Хорошо</div>
                            { this.getCountLink(_.get(general, 'questions[0].id', 0), _.get(good, 'id', 0), _.get(good, 'count'), _.get(good, 'percent'), 'count') }
                        </div>
                        <div className={classes.answerColor + ' ' + classes.generalBad} />
                        <div className={classes.answer}>
                            <div className={classes.value}>Плохо</div>
                            { this.getCountLink(_.get(general, 'questions[0].id', 0), _.get(bad, 'id', 0), _.get(bad, 'count'), _.get(bad, 'percent'), 'count') }
                        </div>
                    </div>
                </div>
                <div className={classes.pollBlock}>
                    <div className={classes.title}>Блок оценки по параметрам</div>
                    <div className={classes.addQuestionBlock}>
                        <div className={classes.icon}><div>+</div></div>
                        <div className={classes.text}>Создать новый вопрос</div>
                        <div className={classes.button} onClick={this.showParamsQuestionModal.bind(this, params)}>
                            Создать
                        </div>
                        <div className={classes.description}>Вы можете задать не более 7 вопросов клиентам в блоке оценки по параметрам. Для того чтобы заменить неактуальные вопросы новыми, отправьте неактуальные вопросы в архив.</div>
                    </div>

                    { _.map(_.get(params, 'questions'), (question, questionKey) => {
                        const questionStatistic = [];

                        const colorKey = questionKey % PARAMS_COLORS_SET_COUNT;
                        questionStatistic.push(
                            <div key={question.id} className={classes.questionBlock}>
                                <div className={classes.number}>
                                    <div>{ questionKey + 1 }</div>
                                </div>
                                <div className={classes.question}>
                                    <div
                                        className={classes.value}
                                        onClick={this.showParamsQuestionModal.bind(this, params, question)}
                                    >
                                        { question.title }
                                    </div>
                                    { this.getQuestionInfo(question.totalAnswersCount) }
                                </div>
                                <div className={classes.button + ' ' + classes.active}>Диаграмма</div>
                                <div className={classes.button}>График</div>
                            </div>
                        );
                        questionStatistic.push(
                            <div key={'chart' + question.id} className={classes.chart}>
                                { this.generateChart(question.answers, PARAMS_COLORS[colorKey].values) }
                                { _.map(question.answers, (answer, answerKey) => {
                                    const answerStatistic = [];
                                    answerStatistic.push(
                                        <div key={'color' + answer.id} className={classes.answerColor + ' ' + classes[PARAMS_COLORS[colorKey].classNames[answerKey]]} />
                                    );
                                    answerStatistic.push(
                                        <div key={answer.id} className={classes.answer}>
                                            <div className={classes.value}>{ answer.title }</div>
                                            { this.getCountLink(question.id, answer.id, answer.count, answer.percent, 'count') }
                                        </div>
                                    );
                                    return answerStatistic;
                                }) }
                            </div>
                        );
                        return questionStatistic;
                    }) }
                </div>

                <div className={classes.pollBlock}>
                    <div className={classes.title}>Блок пожеланий</div>
                    { _.map(_.filter(_.get(wishes, 'questions'), ['type', 'checkbox']), (question, questionKey) => {
                        const questionStatistic = [];
                        questionStatistic.push(
                            <div key={question.id} className={classes.questionBlock}>
                                <div className={classes.number}>
                                    <div>{ questionKey + 1 }</div>
                                </div>
                                <div className={classes.question}>
                                    <div className={classes.value}>{ question.title }</div>
                                    { this.getQuestionInfo(question.totalAnswersCount) }
                                </div>
                                <div className={classes.button + ' ' + classes.active}>Диаграмма</div>
                                <div className={classes.button}>График</div>
                            </div>
                        );

                        questionStatistic.push(
                            <div key={'chart' + question.id} className={classes.lineChart}>
                                { _.map(question.answers, (answer, answerKey) => {
                                    answerKey = answerKey % CHECKBOX_COLORS_SET_COUNT;
                                    const answerStatistic = [];
                                    answerStatistic.push(
                                        <div
                                            key={'line' + answer.id} className={classes.line}
                                            style={{ width: answer.percent / 2 + '%', backgroundColor: CHECKBOX_ANSWERS_COLORS[answerKey] }}
                                        />
                                    );
                                    answerStatistic.push(
                                        this.getCountLink(question.id, answer.id, answer.count, answer.percent, 'lineCount')
                                    );
                                    answerStatistic.push(
                                        <div key={answer.id} className={classes.question}>{ answer.title }</div>
                                    );
                                    return answerStatistic;
                                }) }
                            </div>
                        );
                        return questionStatistic;
                    }) }
                </div>

                <div className={classes.pollBlock}>
                    <div className={classes.title}>Блок возражений</div>
                    { _.map(_.filter(_.get(defences, 'questions'), ['type', 'checkbox']), (question, questionKey) => {
                        const questionStatistic = [];
                        questionStatistic.push(
                            <div key={question.id} className={classes.questionBlock}>
                                <div className={classes.number}>
                                    <div>{ questionKey + 1 }</div>
                                </div>
                                <div className={classes.question}>
                                    <div className={classes.value}>{ question.title }</div>
                                    { this.getQuestionInfo(question.totalAnswersCount) }
                                </div>
                                <div className={classes.button + ' ' + classes.active}>Диаграмма</div>
                                <div className={classes.button}>График</div>
                            </div>
                        );

                        questionStatistic.push(
                            <div key={'chart' + question.id} className={classes.lineChart}>
                                { _.map(question.answers, (answer, answerKey) => {
                                    answerKey = answerKey % CHECKBOX_COLORS_SET_COUNT;
                                    const answerStatistic = [];
                                    answerStatistic.push(
                                        <div
                                            key={'line' + answer.id} className={classes.line}
                                            style={{ width: answer.percent / 2 + '%', backgroundColor: CHECKBOX_ANSWERS_COLORS[answerKey] }}
                                        />
                                    );
                                    answerStatistic.push(
                                        this.getCountLink(question.id, answer.id, answer.count, answer.percent, 'lineCount')
                                    );
                                    answerStatistic.push(
                                        <div key={answer.id} className={classes.question}>{ answer.title }</div>
                                    );
                                    return answerStatistic;
                                }) }
                            </div>
                        );
                        return questionStatistic;
                    }) }
                </div>

                <div className={classes.pollBlock}>
                    <div className={classes.title}>Блок дополнительных опросов</div>
                    <div className={classes.questionBlock}>
                        <div className={classes.number}>
                            <div>1</div>
                        </div>
                        <div className={classes.question}>
                            <div className={classes.value}>Блок опроса в случае выбора общей оценки Вау</div>
                            <div className={classes.info}>С Опрошено 900 человек</div>
                        </div>
                        <div className={classes.button + ' ' + classes.active}>Диаграмма</div>
                        <div className={classes.button}>График</div>
                    </div>

                    <div className={classes.lineChart}>
                        <div className={classes.line} style={{ width: 50 + '%', backgroundColor: '#1d7590' }} />
                        <Link to='/okk/reports/by-answer' className={classes.lineCount}>150 чел.</Link>
                        <div className={classes.question}>Увеличить парковочные места</div>
                        <div className={classes.line} style={{ width: 50 + '%', backgroundColor: '#29afd9' }} />
                        <Link to='/okk/reports/by-answer' className={classes.lineCount}>150 чел.</Link>
                        <div className={classes.question}>Увеличить парковочные места</div>
                        <div className={classes.line} style={{ width: 50 + '%', backgroundColor: '#47ba90' }} />
                        <Link to='/okk/reports/by-answer' className={classes.lineCount}>150 чел.</Link>
                        <div className={classes.question}>Увеличить парковочные места</div>
                    </div>

                    <div className={classes.questionBlock}>
                        <div className={classes.number}>
                            <div>2</div>
                        </div>
                        <div className={classes.question}>
                            <div className={classes.value}>Блок опроса в случае выбора общей оценки Плохо</div>
                            <div className={classes.info}>С Опрошено 900 человек</div>
                        </div>
                        <div className={classes.button + ' ' + classes.active}>Диаграмма</div>
                        <div className={classes.button}>График</div>
                    </div>
                    <div className={classes.lineChart}>
                        <div className={classes.line} style={{ width: 50 + '%', backgroundColor: '#1d7590' }} />
                        <Link to='/okk/reports/by-answer' className={classes.lineCount}>150 чел.</Link>
                        <div className={classes.question}>Увеличить парковочные места</div>
                        <div className={classes.line} style={{ width: 50 + '%', backgroundColor: '#29afd9' }} />
                        <Link to='/okk/reports/by-answer' className={classes.lineCount}>150 чел.</Link>
                        <div className={classes.question}>Увеличить парковочные места</div>
                        <div className={classes.line} style={{ width: 50 + '%', backgroundColor: '#47ba90' }} />
                        <Link to='/okk/reports/by-answer' className={classes.lineCount}>150 чел.</Link>
                        <div className={classes.question}>Увеличить парковочные места</div>
                    </div>
                </div>
                <Preloader trigger={this.props.fetching} fixed='true' />
            </div>
        );
    }
}
Statistic.contextTypes = {
    router: PropTypes.object
};
export default Statistic;
