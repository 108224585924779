import { injectReducer } from '../../store/reducers';

export default (store) => ({
    path: 'widgets',
    /*  Async getComponent is only invoked when route matches   */
    getComponent(nextState, cb) {
        /*  Webpack - use 'require.ensure' to create a split point
         and embed an async module loader (jsonp) when bundling   */

        Promise.all([
            import('./modules/widgets'),
            import('./containers/WidgetsContainer')
        ]).then(([reducer, /* webpackChunkName: "widgets" */WidgetsContainer]) => {
            injectReducer(store, { key: 'widgets', reducer: reducer.default });
            /*  Return getComponent   */
            cb(null, WidgetsContainer.default);
        });
    }
});
