import { injectReducer } from '../../store/reducers';

export default (store) => ({
    path: 'create-company',
    getComponent(nextState, cb) {
        Promise.all([
            import('./containers/CreateCompanyContainer'),
            import('./modules/createCompany')
        ]).then(([CreateCompany, reducer]) => {
            injectReducer(store, { key: 'createCompany', reducer: reducer.default });
            /*  Return getComponent   */
            cb(null, CreateCompany.default);
        });

        // import('./containers/CreateCompanyContainer').then(/* webpackChunkName: "createCompany" */CreateCompanyReducer => {
        //
        //    /*  Return getComponent   */
        //    cb(null, CreateCompanyReducer.default);
        //
        // });
    }
});
