/**
 * Created by user on 17.05.2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classes from './Input.module.scss';

export const Input = (props) => {
    // console.log('Input props', props);
    return (
        <input {...props} className={props.className ? classes[props.className] : classes.default} />
    );
};

Input.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    autoComplete: PropTypes.string,
    onBlur: PropTypes.func,
    onKeyPress: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string
};

export default Input;
