import { injectReducer } from '../../store/reducers';

export default (store) => ({
    path: 'clients',
    /*  Async getComponent is only invoked when route matches   */
    getComponent(nextState, cb) {
        /*  Webpack - use 'require.ensure' to create a split point
         and embed an async module loader (jsonp) when bundling   */
        Promise.all([
            import('./modules/clientsExcel'),
            import('./containers/ClientsExcelContainer')
        ]).then(([reducer, /* webpackChunkName: "clientsExcel" */ClientsExcelContainer]) => {
            injectReducer(store, { key: 'clientsExcel', reducer: reducer.default });
            /*  Return getComponent   */
            cb(null, ClientsExcelContainer.default);
        });
    }
});
