import { connect } from 'react-redux';
import { actions } from './../modules/createCompany';

import CreateCompany from './../components/CreateCompany';

const mapStateToProps = (state) => ({
    phone: state.createCompany.phone,
    companyName: state.createCompany.companyName,
    activationCode: state.createCompany.activationCode,
    activationKey: state.createCompany.activationKey,
    step: state.createCompany.step,
    error: state.createCompany.error,
    businessTypes: state.createCompany.businessTypes,
    businessType: state.createCompany.businessType,
    newPassword: state.createCompany.newPassword,
    passwordConfirm: state.createCompany.passwordConfirm,
    checkPassStatus: state.createCompany.checkPassStatus

});

export default connect(mapStateToProps, actions)(CreateCompany);
