import React from 'react';
import classes from './PollCard.module.scss';
import { formatPhone } from 'utils';
import moment from 'moment';
import _ from 'lodash';

export const PollCard = (props) => {
    const preparedResults = [];

    _.each(props.data.results, result => {
        const checkboxQuestionKey = _.findKey(preparedResults, ['questionId', result.question.id]);
        switch (result.question.type) {
        case 'checkbox':
            if (checkboxQuestionKey) {
                preparedResults[checkboxQuestionKey].value.push(
                    <div className={classes.li} key={result.id}>
                        <div className={classes.circle} />
                        { result.answer.title }
                    </div>
                );
            } else {
                preparedResults.push({
                    id: result.id,
                    title: result.question.title,
                    value: [
                        <div className={classes.li} key={result.id}>
                            <div className={classes.circle} />
                            { result.answer.title }
                        </div>
                    ],
                    questionId: result.question.id
                });
            }
            break;
        case 'textarea':
            preparedResults.push({ id: result.id, title: result.question.title, value: result.value });
            break;
        case 'radio':
            preparedResults.push({ id: result.id, title: result.question.title, value: result.answer.title });
            break;
        default:
        }
    });

    let completedAt;
    if (props.data.completed_at) {
        completedAt = (
            <div className={classes.dataBlock}>
                <div className={classes.title}>Дата ответа на ОКК</div>
                <div className={classes.data + ' ' + classes.orange}>{ moment(props.data.completed_at).format('DD MMMM`YY,') }&nbsp;</div>
                <div className={classes.data + ' ' + classes.green}>{ moment(props.data.completed_at).format('HH:mm') }</div>
            </div>
        );
    } else {
        if (props.data.status === 'COMPLETED') {
            completedAt = (
                <div className={classes.dataBlock}>
                    <div className={classes.title}>Дата ответа на ОКК</div>
                    <div className={classes.data + ' ' + classes.orange}>{ moment(props.data.updated_at).format('DD MMMM`YY,') }&nbsp;</div>
                    <div className={classes.data + ' ' + classes.green}>{ moment(props.data.updated_at).format('HH:mm') }</div>
                </div>
            );
        } else {
            completedAt = (
                <div className={classes.dataBlock}>
                    <div className={classes.title}>Дата ответа на ОКК</div>
                    <div className={classes.data + ' ' + classes.orange}>Не завершен</div>
                </div>
            );
        }
    }

    return (
        <div className={classes.pollCard} onClick={props.onClick}>
            <div className={classes.content} onClick={e => e.stopPropagation()}>
                <div className={classes.header}>Карточка опроса</div>
                <div className={classes.body}>
                    <div className={classes.clientBlock}>
                        <div className={classes.dataBlock}>
                            <div className={classes.title}>Клиент</div>
                            <div className={classes.data + ' ' + classes.orange}>{ props.data.client.name },</div>
                            <div className={classes.phone}>{ formatPhone(props.data.client.phone) }</div>
                        </div>
                        <div className={classes.dataBlock}>
                            <div className={classes.title}>Дата и время посещения</div>
                            <div className={classes.data}>{ moment(props.data.visited_at).format('DD MMMM`YY, HH:mm') }</div>
                        </div>
                        { completedAt }
                    </div>
                    <div className={classes.pollBlock}>
                        { _.map(preparedResults, result => {
                            return (
                                <div key={result.id}>
                                    <div className={classes.title}>{ result.title }</div>
                                    <div className={classes.value}>{ result.value }</div>
                                </div>
                            );
                        }) }
                    </div>
                    <div className={classes.button}>Закрыть карточку</div>
                </div>
            </div>
        </div>
    );
};

export default PollCard;
