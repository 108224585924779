import React from 'react';
import ClientTop from './components/ClientTop';
import Client from './components/Client';
import ClientsList from 'components/ClientsList';
import classes from './Clients.module.scss';

class Clients extends React.Component {
    state = {
        open: false
    };

    UNSAFE_componentWillMount() {
        this.props.clearClients();
        if (this.props.clientCardState) {
            this.setState({ open: true });
            this.props.showClientCard(false);
        }
    }

    render() {
        return (
            <div className={classes.element + ' ' + (this.state.open ? classes.open : '')}>
                <ClientTop
                    open={this.state.open}
                    openHandler={(e) => {
                        e.preventDefault();
                        this.setState({ open: !this.state.open });
                    }}
                />
                <div className={classes.body}>
                    <Client location={this.props.location} />
                    <ClientsList />
                </div>
            </div>
        );
    }
}

export default Clients;
