import React from 'react';
import PropTypes from 'prop-types';
import classes from './CallsDiary.module.scss';
import cx from 'classnames';
import moment from 'moment';
import DatePicker from 'components/DatePicker';
import Select from 'components/Select';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';

class CallsDiary extends React.Component {
    constructor(props) {
        super(props);
        this.timeGoesOn = this.timeGoesOn.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
    }

    state = {
        workDay: { start: 10, end: 20 }, // график работы кал-центра
        nowTime: moment().format(),
        filter: {
            all: true,
            regular: false,
            unactive: false,
            unProcessed: false,
            nameSearch: ''
        }
    };

    componentDidMount() {
        const currentDay = this.props.currentDay ? this.props.currentDay : moment().startOf('date');
        this.fetchOneDayCalls(currentDay);
        // подвинем звонки к текущему времени
        const { callScrollbars } = this.refs;
        const el = document.getElementById('calls');

        const callsHeight = el.offsetHeight;

        const hourOfWorkDay = moment().hour() - this.state.workDay.start + (moment().minute()) / 60;
        callScrollbars.scrollTop(callsHeight * (hourOfWorkDay / (this.state.workDay.end - this.state.workDay.start)));
        // и увеличиваем текущее время по интервалу
        this.intervalId = setInterval(this.timeGoesOn, 60000);
    }

    timeGoesOn() {
        this.setState(this.state);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((!this.props.currentDay && nextProps.currentDay) ||
            (this.props.currentDay && nextProps.currentDay && !moment(this.props.currentDay).isSame(nextProps.currentDay))) {
            this.fetchOneDayCalls(nextProps.currentDay);
        }
    }

    fetchOneDayCalls(day) {
        const from = moment(day).format();

        const to = moment(day).add(24, 'hour').format();

        const limit = 1000;
        this.props.fetchCalls(from, to, limit);
    }

    onChangeDate(date) {
        this.props.changeDate(date);
    }

    changeFilter(filterValue, e) {
        e.preventDefault();
        const filter = {
            all: false,
            regular: false,
            unactive: false,
            unProcessed: false,
            nameSearch: ''
        };
        filter[filterValue] = true;
        this.setState({ filter: filter });
    }

    changeNameSearch(value) {
        const filter = this.state.filter;
        filter.nameSearch = value;
        this.setState({ filter: filter });
    }

    normalizeCalls() {
        const calls = {};
        _.map(this.props.calls, call => {
            const callAt = moment(call.call_at); const minutes = moment(call.call_at).minute();

            const keyDate = callAt.format('HH:' + (minutes >= 30 ? '30' : '00'));
            const company = _.find(call.client.companies, { id: this.props.partner.company_id });
            call.client.status = _.get(company, 'pivot.status', null);

            // обрабатываем текущий фильтр
            if (((this.state.filter.all ||
                    (this.state.filter.regular && call.client.status === 'active') ||
                    (this.state.filter.unactive && !call.client.is_activated) ||
                    (this.state.filter.unProcessed && !call.processed_at)) &&
                    !this.state.filter.nameSearch
            ) ||
                ((this.state.filter.nameSearch &&
                    (call.client.companies[0].pivot.nick.toLowerCase().indexOf(this.state.filter.nameSearch.toLowerCase()) !== -1)) ||
                    (call.client.phone.toLowerCase().indexOf(this.state.filter.nameSearch.toLowerCase()) !== -1)
                )
            ) {
                if (!calls[keyDate]) {
                    calls[keyDate] = [];
                }
                calls[keyDate].push(call);
            }
        });
        return calls;
    }

    renderTrack({ style, ...props }) {
        const trackStyle = {};
        return (
            <div
                className='scrollTrack'
                style={{ ...style, ...trackStyle }}
                {...props}
            />
        );
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {};
        return (
            <div
                className='scrollThumb'
                style={{ ...style, ...thumbStyle }}
                {...props}
            />
        );
    }

    changeEmployee(call, e) {
        this.props.fetchProcessCall({ id: call.id, employee_id: e.value });
    }

    toCreateByDate(date, e) {
        e.preventDefault();
        this.props.changeNavigate({ mode: 'callCreate', initialDate: moment(date) });
    }

    getCalls(calls, timeKey, clientStatus, emplSelItems) {
        return _.map(_.get(calls, timeKey, []), (call, callKey) => {
            return (
                <div
                    className={cx({
                        [classes.call]: true,
                        [classes[call.client.status]]: true,
                        [classes.notActivated]: !call.client.is_activated,
                        [classes.expired]: moment(call.call_at).isBefore()
                    })}
                    style={{ zIndex: _.get(calls, timeKey, []).length + 1 - callKey }}
                    onClick={e => {
                        e.preventDefault();
                        this.props.history.push('/callcenter?call=' + call.id);
                        this.props.fetchClient(call.client.id);
                    }}
                    key={call.id}
                >
                    <div className={classes.wrapper}>
                        <div className={classes.name} title={call.client.companies[0].pivot.nick}>
                            { call.client.companies[0].pivot.nick }
                        </div>
                        <div className={classes.phone}>{ call.client.phone }</div>
                        <div
                            className={cx({
                                [classes.status]: true,
                                [classes.notActivated]: !call.client.is_activated,
                                [classes.regular]: (call.client.status === 'active')

                            })}
                        >{ call.client.is_activated ? clientStatus[call.client.status] : 'Не активированный' }
                        </div>
                        { this.props.partner.role === 'PARTNER' ? (
                            <Select
                                name='employee'
                                placeholder='--'
                                value={call.employee_id ? call.employee_id : null}
                                options={emplSelItems}
                                type='select'
                                className='selectEmployee'
                                onChange={this.changeEmployee.bind(this, call)}
                            />
                        ) : null }

                    </div>
                </div>);
        });
    }

    render() {
        moment.locale('ru');
        const currentDay = this.props.currentDay ? this.props.currentDay : moment().startOf('date');

        const clientStatus = { active: 'Постоянный', lid: 'Лид' };

        const calls = this.normalizeCalls();
        // рендер блока звонков
        const callsBlock = []; let timeKey; let i; let intervalChunkTime;

        const nowTimeVertikal = Math.floor((moment().minute() % 30) / 0.3);

        const nowKey = moment().minute(moment().minute() >= 30 ? 30 : 0).format('HH:mm');

        const emplSelItems = _.map(this.props.employees, employee => {
            const label = _.map(employee.name.split(' '), word => {
                return word.trim()[0];
            }).join('');
            return {
                type: 'item',
                label: (<div><span className='nameWords'>{ label }</span><span className='name'>{ employee.name }</span></div>),
                value: employee.id
            };
        });
        for (i = 10; i < 21; i = i + 0.5) {
            intervalChunkTime = moment(currentDay).hour(Math.floor(i)).minute((i % 1) * 60);
            timeKey = intervalChunkTime.format('HH:mm');
            callsBlock.push(
                <div key={timeKey} className={classes.interval} style={{ zIndex: (21 - i) * 2 }}>
                    <div className={classes.callsHeader}>{ timeKey }</div>
                    <div className={classes.calls}>
                        { _.isEmpty(_.get(calls, timeKey, []))
                            ? (
                                <div
                                    className={cx({ [classes.noCall]: true, [classes.expired]: intervalChunkTime.isBefore() })}
                                    onClick={this.toCreateByDate.bind(this, intervalChunkTime)}
                                >
                                    Нет звонков
                                </div>
                            )
                            : this.getCalls(calls, timeKey, clientStatus, emplSelItems) }
                    </div>
                    { timeKey === nowKey && moment(currentDay).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
                        ? (
                            <div className={classes.timeArrow} style={{ top: nowTimeVertikal + '%' }}>
                                <div className={classes.time}>{ moment().format('HH:mm') }</div>
                            </div>
                        )
                        : null }
                </div>
            );
        }

        return (
            <div className={classes.callsDiary}>
                <div className={classes.header}>
                    <div className={classes.wrapper}>
                        <div className='text'>Календарь звонков</div>
                        <DatePicker
                            className='white'
                            onChange={this.onChangeDate}
                            selected={currentDay}
                            align={['center', 'bottom']}
                        />
                    </div>
                </div>
                <div className={classes.searchForm}>
                    <input
                        type='text' placeholder='Поиск клиента'
                        value={this.state.filter.nameSearch}
                        onChange={e => {
                            this.changeNameSearch(e.target.value);
                        }}
                    />
                    <div className={classes.filter}>
                        Отобразить:&nbsp;
                        <div
                            className={cx({ [classes.active]: this.state.filter.all })}
                            onClick={this.changeFilter.bind(this, 'all')}
                        >Все
                        </div>
                        <div
                            className={cx({ [classes.active]: this.state.filter.regular })}
                            onClick={this.changeFilter.bind(this, 'regular')}
                        >Постоянный
                        </div>
                        <div
                            className={cx({ [classes.active]: this.state.filter.unactive })}
                            onClick={this.changeFilter.bind(this, 'unactive')}
                        >Не актив.
                        </div>
                    </div>
                </div>
                <div className={classes.callsday}>
                    <Scrollbars
                        ref='callScrollbars'
                        thumbSize={16}
                        autoHeight={false}
                        autoHide={false}
                        renderTrackVertical={this.renderTrack}
                        renderThumbVertical={this.renderThumb}
                        onWheel={event => {
                            if (event.deltaY > 0) {
                                const { callScrollbars } = this.refs;
                                const scroll = callScrollbars.getValues();
                                if (scroll.clientHeight + scroll.scrollTop === scroll.scrollHeight) {
                                    event.preventDefault();
                                }
                            }
                        }}
                    >
                        <div id='calls'>
                            { callsBlock }
                        </div>
                    </Scrollbars>
                </div>
                <div className={classes.unProcessed}>
                    <div onClick={this.changeFilter.bind(this, 'unProcessed')}>Необработанные</div>
                </div>
                { this.props.partner.role === 'PARTNER' ? (
                    <div
                        className={classes.makeCall}
                        onClick={e => {
                            e.preventDefault();
                            this.props.changeNavigate({ mode: 'callCreate' });
                        }}
                    />
                ) : null }}

            </div>
        );
    }
}

CallsDiary.contextTypes = {
    router: PropTypes.object
};

export default CallsDiary;
