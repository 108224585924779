import React from 'react';
import PropTypes from 'prop-types';

export const Layout = ({ children }) => (
    <div>
        { children }
    </div>
);

Layout.propTypes = {
    children: PropTypes.element
};

Layout.contextTypes = {
    router: PropTypes.object
};

export default Layout;
