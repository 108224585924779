import { api, httpBuildQuery } from 'utils';
import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
export const REQUEST_WIDGETS = 'requestWidgets';
export const REQUEST_WIDGETS_STOP = 'requestWidgetsStop';
export const RECEIVE_WIDGETS = 'receiveWidgets';

export const RECEIVE_WIDGET = 'receiveWidget';
export const REMOVE_WIDGET = 'removeWidget';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchWidgets = (data) => {
    return function(dispatch) {
        dispatch(requestWidgets());
        return api('/v1/partner/widgets?' + httpBuildQuery(data), {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receiveWidgets(res)))
            .catch(() => {
                dispatch(requestWidgetsStop());
            });
    };
};

function requestWidgets() {
    return {
        type: REQUEST_WIDGETS
    };
}
function requestWidgetsStop() {
    return {
        type: REQUEST_WIDGETS_STOP
    };
}

function receiveWidgets(res) {
    return {
        type: RECEIVE_WIDGETS,
        payload: res
    };
}

export const createWidget = (data) => {
    return function(dispatch) {
        dispatch(requestWidgets());
        return api('/v1/partner/widgets', {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(res => {
                if (res) {
                    return dispatch(receiveWidget(res));
                }
            })
            .catch(() => {
                dispatch(requestWidgetsStop());
            });
    };
};

export const updateWidget = (data, id) => {
    return function(dispatch) {
        dispatch(requestWidgets());
        return api('/v1/partner/widgets/' + id, {
            method: 'PUT',
            body: JSON.stringify(data)
        }, dispatch)
            .then(res => {
                if (res) {
                    return dispatch(receiveWidget(res));
                }
            })
            .catch(() => {
                dispatch(requestWidgetsStop());
            });
    };
};

function receiveWidget(res) {
    return {
        type: RECEIVE_WIDGET,
        payload: res
    };
}

export const deleteWidget = (id) => {
    return function(dispatch) {
        dispatch(requestWidgets());
        return api('/v1/partner/widgets/' + id, {
            method: 'DELETE'
        }, dispatch)
            .then(res => {
                if (res) {
                    return dispatch(removeWidget(id));
                }
            })
            .catch(() => {
                dispatch(requestWidgetsStop());
            });
    };
};

function removeWidget(id) {
    return {
        type: REMOVE_WIDGET,
        payload: id
    };
}

export const actions = {
    fetchWidgets,
    createWidget,
    updateWidget,
    deleteWidget
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [REQUEST_WIDGETS]: (state) => {
        return ({ ...state, fetching: true });
    },
    [REQUEST_WIDGETS_STOP]: (state) => {
        return ({ ...state, fetching: false });
    },
    [RECEIVE_WIDGETS]: (state, action) => {
        return ({ ...state, widgets: action.payload, fetching: false });
    },
    [RECEIVE_WIDGET]: (state, action) => {
        const widgets = Object.assign({}, state.widgets);

        const key = _.findKey(widgets.items, ['id', action.payload.id]);

        if (key) {
            widgets.items[key] = action.payload;
        } else {
            widgets.items.push(action.payload);
        }

        return ({ ...state, widgets: widgets, fetching: false });
    },
    [REMOVE_WIDGET]: (state, action) => {
        const widgets = Object.assign({}, state.widgets);

        _.remove(widgets.items, ['id', action.payload]);

        return ({ ...state, widgets: widgets, fetching: false });
    }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    fetching: false,
    widgets: null
};
export default function widgetsReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
