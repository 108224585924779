import React from 'react';
import classes from './CallView.module.scss';
import cx from 'classnames';
import moment from 'moment';
import DatePicker from 'components/DatePicker';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';
import _ from 'lodash';

class CallView extends React.Component {
    constructor(props) {
        super(props);
        this.onChangeDate = this.onChangeDate.bind(this);
    }

    componentDidMount() {
        if (this.props.callId) {
            this.props.fetchCall(this.props.callId);
        }
    }

    UNSAFE_componentWillReceiveProps() {

    }

    renderTrack({ style, ...props }) {
        const trackStyle = {};
        return (
            <div
                className='scrollTrack'
                style={{ ...style, ...trackStyle }}
                {...props}
            />
        );
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {};
        return (
            <div
                className='scrollThumb'
                style={{ ...style, ...thumbStyle }}
                {...props}
            />
        );
    }

    onChangeDate(date) {
        const call_at = moment(date).hour(moment(this.props.call.call_at).hour()).minute(moment(this.props.call.call_at).hour()).format();
        this.props.fetchProcessCall({ id: this.props.call.id, call_at: call_at });

        // this.props.changeDate(date);
    }

    render() {
        const call = this.props.call;
        return (
            <div className={classes.callView}>
                <Link className={classes.goback} to='/callcenter'>
                    <div className={classes.wrapper}>Назад к звонкам</div>
                </Link>
                <div className={classes.header}>Карточка звонка</div>
                <div className={classes.body}>
                    <Scrollbars
                        ref='callScrollbars'
                        thumbSize={16}
                        autoHeight={false}
                        autoHide={false}
                        renderTrackVertical={this.renderTrack}
                        renderThumbVertical={this.renderThumb}
                    >
                        { call.processed_at
                            ? (
                                <div className={classes.call}>
                                    <div className={classes.id}>№ { call ? call.id : '--' }</div>
                                    <div className={classes.callDate + ' ' + classes.block}>
                                        <div className={classes.text}>Время и дата звонка</div>
                                        <div className={classes.dateTime}>
                                            <div
                                                className={classes.time}
                                            >{ call ? moment(call.call_at).format('HH:mm') : '--:--' },&nbsp;
                                            </div>
                                            <div
                                                className={classes.date}
                                            >{ call ? moment(call.call_at).format('DD MMMM`YY') : '--:--' }
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className={classes.block}>
                                        <div className={classes.label}>Причина звонка</div>
                                        <div className={classes.value}>{ call ? call.reason : '----' }</div>
                                    </div>
                                    <div className={classes.block}>
                                        <div className={classes.label}>Комментарий к звонку</div>
                                        <div className={classes.value}>{ call ? call.comment : '----' }</div>
                                    </div>
                                    <div className={classes.block}>
                                        <div className={classes.value}>Важный звонок</div>
                                    </div>
                                    <hr />
                                    <div className={classes.block}>
                                        <div className={cx({ [classes.proceed]: true, [classes.processed]: true })}>
                                            Звонок обработан
                                        </div>
                                    </div>
                                </div>
                            )
                            : (
                                <div className={classes.call}>
                                    <div className={classes.id}>№ { call ? call.id : '--' }</div>
                                    <div className={classes.callDate + ' ' + classes.block}>
                                        <div className={classes.text}>Время и дата звонка</div>
                                        <div className={classes.dateTime}>
                                            <div
                                                className={classes.time}
                                            >{ call ? moment(call.call_at).format('HH:mm') : '--:--' },&nbsp;
                                            </div>
                                            <div
                                                className={classes.date}
                                            >{ call ? moment(call.call_at).format('DD MMMM`YY') : '--:--' }
                                            </div>
                                        </div>
                                    </div>
                                    <div href='#' className={classes.callShift}>Перенести звонок
                                        <DatePicker
                                            onChange={this.onChangeDate}
                                            selected={call.call_at}
                                            className='callShift'
                                            align={['center', 'bottom']}
                                            minDate={moment()}
                                        />
                                    </div>
                                    <hr />
                                    <div className={classes.block}>
                                        <div className={classes.label}>Причина звонка</div>
                                        <div className={classes.value}>{ call ? call.reason : '----' }</div>
                                    </div>
                                    <div className={classes.block}>
                                        <div className={classes.label}>Комментарий</div>
                                        <textarea
                                            className={classes.commence} placeholder='Комментарий к звонку'
                                            rows='4'
                                            value={call ? call.comment : ''}
                                            onChange={e => { this.props.changeCall('comment', e.target.value); }}
                                        />
                                    </div>
                                    <div>
                                        <div
                                            className={cx({ [classes.isImportant]: true, [classes.active]: call.is_important })}
                                            onClick={this.props.changeCall.bind(this, 'is_important', call.is_important ? 0 : 1)}
                                        >
                                            Важный звонок
                                        </div>
                                    </div>
                                    <hr />
                                    <div className={classes.block}>
                                        <div
                                            className={cx({ [classes.proceed]: true, [classes.processed]: call.processed_at })}
                                            onClick={e => { e.preventDefault(); this.props.fetchProcessCall(_.merge(call, { status: 'delivered' })); }}
                                        >
                                            Обработать звонок
                                        </div>
                                    </div>
                                </div>) }
                    </Scrollbars>
                </div>
            </div>
        );
    }
}
export default CallView;
