/**
 * Created by user on 19.07.16.
 */
import React from 'react';
import _ from 'lodash';
import classes from './PrivateInfo.module.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import InputElement from 'react-input-mask';
import Cars from './Cars';

const orangeFields = ['mark', 'model', 'year'];

class PrivateInfo extends React.Component {
    renderTrack({ style, ...props }) {
        const trackStyle = {};
        return (
            <div
                className={classes.scrollTrack}
                style={{ ...style, ...trackStyle }}
                {...props}
            />
        );
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {};
        return (
            <div
                className={classes.scrollThumb}
                style={{ ...style, ...thumbStyle }}
                {...props}
            />
        );
    }

    getFieldValue(field) {
        if (field.type === 'reference') {
            let fieldValue,
                parentId;
            if (field.value) {
                parentId = _.find(field.references, reference => { return reference.id === parseInt(field.value); }).parent;
                parentId && !_.find(this.props.client.fields, item => { return parseInt(item.value) === parentId; })// обнуление значения поля при изменении значения родительского поля
                    ? this.props.changeSelectField(null, field, this.props.client.id)
                    : fieldValue = _.find(field.references, reference => { return reference.id === parseInt(field.value); }).title;
            }
            return (
                <div className={classes.changeValue} key={'changeValue' + field.id} onClick={this.props.editClient.bind(this, field.id)}>
                    <div
                        className={classes.value + ' ' + (orangeFields.indexOf(field.label) !== -1 ? classes.orange : '')}
                        key={'value' + field.id}
                    >{ field.value ? fieldValue : 'Не выбрано' }
                    </div>
                    <div className={classes.select} key={'select' + field.id} />
                    { this.props.editClientChange === field.id
                        ? (
                            <div>
                                <div className={classes.triangle} />
                                <div className={classes.outerMenu} onClick={e => { e.stopPropagation(); }}>
                                    <Scrollbars
                                        id='fieldScrollbar'
                                        ref='fieldScrollbar'
                                        thumbSize={16}
                                        autoHeight
                                        autoHide={false}
                                        renderTrackVertical={this.renderTrack}
                                        renderThumbVertical={this.renderThumb}
                                        onWheel={event => {
                                            const { fieldScrollbar } = this.refs;
                                            const scroll = fieldScrollbar.getValues();
                                            if (event.deltaY > 0 && scroll.clientHeight + scroll.scrollTop === scroll.scrollHeight) {
                                                event.preventDefault();
                                            }
                                            if (event.deltaY < 0 && scroll.scrollTop === 0) {
                                                event.preventDefault();
                                            }
                                        }}
                                    >
                                        { _.map(field.references, item => {
                                            if (item.parent !== 0 &&
                                                _.find(this.props.client.fields, field => { return parseInt(field.value) === item.parent; })) {
                                                return (
                                                    <div
                                                        key={item.label}
                                                        className={classes.item}
                                                        onClick={this.props.changeSelectField.bind(this, item.id, field, this.props.client.id)}
                                                    >
                                                        { item.title }
                                                    </div>);
                                            } else if (item.parent === 0) {
                                                return (
                                                    <div
                                                        key={item.label}
                                                        className={classes.item}
                                                        onClick={this.props.changeSelectField.bind(this, item.id, field, this.props.client.id)}
                                                    >
                                                        { item.title }
                                                    </div>);
                                            }
                                        }) }
                                    </Scrollbars>
                                </div>
                            </div>
                        )
                        : null }
                </div>
            );
        } else {
            return (
                this.props.editClientChange === field.id
                    ? this.fieldInput(field)
                    : (
                        <div
                            className={classes.changeValue} onClick={this.props.editClient.bind(this, field.id)}
                            key={'changeValue' + field.id}
                        >
                            <div
                                className={classes.value + ' ' + (orangeFields.indexOf(field.label) !== -1 ? classes.orange : '')}
                                key={'value' + field.id}
                            >
                                { field.label === 'power' && field.value ? field.value + ' л.с.' : field.value }
                            </div>
                            <div className={classes.edit} key={'edit' + field.id} />
                        </div>
                    )
            );
        }
    }

    fieldInput(field) {
        let mask;

        let maskChar;

        let text;

        const formatChars = { 9: '[0-9]', V: '[A-HJ-NPR-Za-hj-npr-z0-9]', R: '[а-яёЁА-Я0-9]' };

        switch (field.label) {
        case 'year':
            mask = '9999';
            maskChar = 'Г';
            text = 'Введите год автомобиля';
            break;
        case 'vin':
            mask = 'VVV  VVVVVV  VVVVVVVV';
            maskChar = '_';
            text = 'Вин должен состоять из 17 символов (цифры и буквы латинского алфавита, кроме I Q O)';
            break;
        case 'passport':
            mask = '99 99 999999';
            maskChar = '_';
            text = 'Введите серию и номер пасспорта';
            break;
        case 'sor':
            mask = 'RR RR RRRRRR';
            maskChar = '_';
            text = 'Введите номер СОР';
            break;
        case 'vu':
            mask = 'RR RR RRRRRR';
            maskChar = '_';
            text = 'Введите серию и номер ВУ';
            break;
        default:
        }

        if (text) {
            return (
                <div className={classes.changeValue}>
                    <InputElement
                        type='text'
                        name={field.id}
                        autoFocus
                        value={_.find(this.props.changedClient.fields, ['id', field.id]).value}
                        onChange={this.props.changeClient}
                        onClick={e => e.stopPropagation()}
                        mask={mask}
                        maskChar={maskChar}
                        formatChars={formatChars}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                this.fieldOnClick(field.label, _.find(this.props.changedClient.fields, ['id', field.id]).value, e);
                            }
                        }}
                    />
                    <div
                        className={classes.editOk}
                        onClick={this.fieldOnClick.bind(this, field.label, _.find(this.props.changedClient.fields, ['id', field.id]).value)}
                    />
                    { this.props.errorMenu
                        ? (
                            <div className={classes.errorMenu} onClick={e => e.stopPropagation()}>
                                { text }
                            </div>
                        )
                        : null }
                </div>
            );
        } else {
            return (
                <div className={classes.changeValue}>
                    <input
                        type='text'
                        name={field.id}
                        autoFocus
                        value={_.find(this.props.changedClient.fields, ['id', field.id]).value}
                        onChange={this.props.changeClient}
                        style={field.label === 'power' ? { width: 9 + 'em' } : { width: 11 + 'em' }}
                        onClick={e => e.stopPropagation()}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                this.fieldOnClick(field.label, _.find(this.props.changedClient.fields, ['id', field.id]).value, e);
                            }
                        }}
                    />
                    {
                        field.label === 'power'
                            ? <div className={classes.power}>&nbsp;&nbsp;л.с.</div>
                            : null
                    }
                    <div
                        className={classes.editOk}
                        onClick={this.fieldOnClick.bind(this, field.label, _.find(this.props.changedClient.fields, ['id', field.id]).value)}
                    />
                    { this.props.errorMenu === 'power'
                        ? (
                            <div className={classes.errorMenu} onClick={e => e.stopPropagation()}>
                                В этом поле можно ввести только цифры
                            </div>
                        )
                        : null }
                </div>
            );
        }
    }

    fieldOnClick(label, value, e) {
        let test;
        switch (label) {
        case 'year':
        case 'vin':
        case 'passport':
        case 'sor':
        case 'vu':
            test = /[_Г]/.test(value);
            break;
        case 'power':
            test = !/^\d+$/.test(value);
            break;
        case 'email':
            test = !/.+@.+\..+/i.test(value);
            break;
        default:
        }

        if (test && value.length !== 0) {
            this.props.errorMenuChange(label, e);
        } else {
            this.props.updateClient(this.props.client.id);
        }
    }

    getFields(fieldName, groupId, field) {
        if (field.field_group_id === groupId) {
            fieldName.push(
                <div
                    className={field.label === 'apartment' ? classes.fieldsData + ' ' + classes.margin : classes.fieldsData}
                    key={'data' + field.id}
                >
                    <div className={classes.title} key={'title' + field.id}>{ field.title }:</div>
                    { this.getFieldValue(field) }
                </div>
            );
        }
    }

    // TODO выпилить, когда будет верстка для нескольких автомобилей
    getCars(fieldName, groupId, field) {
        if (field.field_group_id === groupId) {
            fieldName.push(
                <div className={classes.carData} key={'data' + field.id}>
                    <div className={classes.title} key={'title' + field.id}>{ field.title }:</div>
                    { this.getFieldValue(field) }
                </div>
            );
        }
    }

    editField(field) {
        return (
            this.props.editClientChange === field.id
                ? (
                    <div className={classes.changeValue}>
                        <input
                            type='text'
                            name={field.id}
                            autoFocus
                            value={_.find(this.props.changedClient.fields, ['id', field.id]).value}
                            onChange={this.props.changeClient}
                            onClick={e => e.stopPropagation()}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    this.fieldOnClick(field.label, _.find(this.props.changedClient.fields, ['id', field.id]).value, e);
                                }
                            }}
                        />
                        <div
                            className={classes.editOk}
                            onClick={this.fieldOnClick.bind(this, field.label, _.find(this.props.changedClient.fields, ['id', field.id]).value)}
                        />
                        { this.props.errorMenu === 'email'
                            ? (
                                <div className={classes.errorMenu} onClick={e => e.stopPropagation()}>
                                    Неверный формат адреса электронной почты
                                </div>
                            )
                            : null }
                    </div>
                )
                : (
                    <div
                        className={classes.changeValue} onClick={this.props.editClient.bind(this, field.id)}
                        key={'changeValue' + field.id}
                    >
                        <div className={classes.value} key={'value' + field.id}>{ field.value }</div>
                        <div className={classes.edit} key={'edit' + field.id} />
                    </div>
                )
        );
    }

    removeCar() {
        alert('Перезагрузите компьютер для сохранения изменений');
    }

    render() {
        let wishes = [];

        let importantThings = [];

        const insurance = [];

        _.map(this.props.client.wishes, item => {
            wishes.push(
                <div className={classes.colorValue} key={'wishes' + item.id}>{ item.title }</div>
            );
        });

        _.map(_.sortBy(this.props.client.important_things, ['pivot.selected']), item => {
            importantThings.push(
                <div className={classes.colorValue} key={'wishes' + item.id}>{ item.title }</div>
            );
        });

        _.map(_.orderBy(this.props.client.fields, ['sort'], ['asc']), item => {
            this.getFields(insurance, 3, item);
        });

        if (wishes.length > 0) {
            wishes = (
                <div className={classes.fieldsData}>
                    <div className={classes.title}>Предпочтения клиента:</div>
                    { wishes }
                </div>
            );
        }

        if (importantThings.length > 0) {
            importantThings = (
                <div className={classes.fieldsData}>
                    <div className={classes.title}>Самое важное для клиента:</div>
                    { importantThings }
                </div>
            );
        }

        return (
            <div className={classes.element}>
                <div className={classes.body}>
                    { importantThings }
                </div>
                { this.props.businessType === 1
                    ? <Cars {...this.props} />
                    : null }
            </div>
        );
    }
}

export default PrivateInfo;
