import React from 'react';
import _ from 'lodash';
import cn from 'classnames';

import '../../../../../lib/slim/slim.scss';
import './styles_cpro.scss';

const PRE_BOOKING_LIMIT = 90;

class Cpro extends React.Component {
    changeCompanyField(field, e) {
        const value = e.target.value;
        const fKey = _.findKey(this.props.company.fields, { label: field });
        this.props.changeCompany(`fields.${fKey}.value`, value);
    }

    changeNoClientFreeTime(value, e) {
        e.preventDefault();
        this.props.changeCompany('no_client_free_time', value);
    }

    render() {
        const company = this.props.company;

        // возня с аватаром
        return (
            <div className='settings-block'>
                <div className='settings-block__h1'>Настройки Connect PRO</div>
                <div className='settings-block--cpro'>
                    <div className='inputGroup'>
                        <label>
                            Доступный интервал для записи
                            <input
                                type='number'
                                name='booking_limit'
                                placeholder=''
                                value={String(_.get(_.find(_.get(this.props.company, 'fields', []),
                                    { label: 'company_booking_limit' }), 'value', 10))}
                                onChange={(e) => {
                                    const value = e.target.value > PRE_BOOKING_LIMIT ? PRE_BOOKING_LIMIT : e.target.value;
                                    const fKey = _.findKey(this.props.company.fields, { label: 'company_booking_limit' });
                                    this.props.changeCompany(`fields.${fKey}.value`, value);
                                }}
                            />
                            суток
                        </label>
                        <div className='info'>Как далеко в будущее можно записаться online</div>
                    </div>
                    <div className='inputGroup'>
                        <label>
                            Отсрочка начала записи
                            <input
                                type='number'
                                name='booking_limit'
                                placeholder=''
                                value={String(_.get(_.find(_.get(this.props.company, 'fields', []),
                                    { label: 'company_pre_booking_limit' }), 'value', 10))}
                                onChange={this.changeCompanyField.bind(this, 'company_pre_booking_limit')}
                            />
                            часов
                        </label>
                        <div className='info'>Минимальный интервал, необходимый для предварительной обработки заказа</div>
                    </div>
                    <div className='inputGroup'>
                        <label>
                            Онлайн запись только на ближайшее свободное время каждого дня
                            часов&nbsp;
                            <div
                                className={cn({ checkbox: true, active: company.no_client_free_time })}
                                onClick={this.changeNoClientFreeTime.bind(this, !company.no_client_free_time)}
                            />
                        </label>
                        <div className='info'>
                            Клиент сможет записаться только на первый свободный отрезок мастера, что
                            предотвратит появление "дырок" в расписании
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Cpro;
