import React from 'react';

import cn from 'classnames';
import _ from 'lodash';

import { HELP_CSS } from 'utils';
import SelectDurationMenu from './SelectDurationMenu';
import SetCyclic from './SetCyclic';
import Resources from './Resources';

import classes from './Editor.module.scss';
import SlimDecorator from '../../../../../../components/Slim';

const COMMENT_LIMIT = 254;

class Editor extends React.Component {
    state = {
        serviceTemplateForEdit: null,
        resources: this.props.resources,
        serviceResources: null,
        editServiceChange: null,
        chooseResource: false,
        expandResourceServices: null
    };

    normalizeServiceTemplateFromProp(serviceTemplate) {
        const newService = {
            id: null,
            name: serviceTemplate.name,
            averageDuration: 30,
            minPrice: 0,
            period: null,
            isPeriodic: false,
            repeats_during_period: null,
            comment: '',
            catalogueId: serviceTemplate.catalogue.id,
            resources: [],
            serviceTemplateId: serviceTemplate.id,
            isCustom: !!serviceTemplate.isCustom
        };

        const serviceTemplateForEdit = serviceTemplate;
        serviceTemplateForEdit.availableServices = [];
        // генерим возможные сочетания значений справочников
        const optionsTemplatecombinations = this.optionsTemplatecombinations(_.cloneDeep(serviceTemplate.serviceOptionTemplates));
        _.each(optionsTemplatecombinations, (vComb) => {
            const _s = _.cloneDeep(newService);
            _s.serviceOptions = _.cloneDeep(vComb);
            _s.uniqueId = `${serviceTemplateForEdit.id}_${_.sortBy(_.map(vComb, vc => _.get(vc, 'valueByReference.id', null))).join('_')}`;
            serviceTemplateForEdit.availableServices.push(_s);
        });
        if (serviceTemplateForEdit.availableServices.length === 0) {
            const _s = _.clone(newService);
            _s.connected = true;
            _s.uniqueId = String(serviceTemplateForEdit.id);
            _s.serviceOptions = [];
            serviceTemplateForEdit.availableServices.push(_s);
        }

        // выясняем наличие существующих услуг и вставляем в массив возможных услуг
        if (serviceTemplateForEdit.services && serviceTemplateForEdit.services.length) {
            _.each(serviceTemplateForEdit.services, (s) => {
                if (s.serviceOptions && s.serviceOptions.length) {
                    s.uniqueId = `${s.serviceTemplate.id}_${_.sortBy(_.map(s.serviceOptions, vc => _.get(vc, 'valueByReference.id', null))).join('_')}`;
                } else {
                    s.uniqueId = String(s.serviceTemplate.id);
                }
                const existKey = _.findKey(serviceTemplateForEdit.availableServices, { uniqueId: s.uniqueId });
                if (existKey) {
                    s.connected = true;
                    _.merge(serviceTemplateForEdit.availableServices[existKey], s);
                }
            });
        }

        serviceTemplateForEdit.comment = _.get(_.find(serviceTemplateForEdit.services, s => s.comment), 'comment');

        return serviceTemplateForEdit;
    }

    optionsTemplatecombinations(serviceOptionTemplates, step = null, count = false, weight = null) {
        if (!serviceOptionTemplates || serviceOptionTemplates.length === 0) {
            return [];
        }
        if (step === null) {
            // Функция запущена в первый раз и запущена "снаружи", а не из самой себя.
            const count = serviceOptionTemplates.length;
            const weight = new Array(count).fill(1); // array_fill(-1, count+1, 1);
            let cSize = 1;
            // Подсчитываем:
            // cSize - количество возможных комбинаций,
            // weight - массив "весов" разрядов.
            _.each(serviceOptionTemplates, (t, tKey) => {
                const referenceSize = _.get(t, 'serviceOptionReference.values', []).length;
                cSize *= referenceSize;
                weight[tKey + 1] = _.get(weight, tKey - 1, 1) * referenceSize;
            });
            const result = [];
            for (let n = 0; n < cSize; n++) {
                result.push(this.optionsTemplatecombinations(serviceOptionTemplates, n, count, weight));
            }
            return result;
        }
        // Дано конкретное число, надо его "преобразовать" в комбинацию.
        // Заготавливаем нулевой массив состояний
        const combinations = new Array(count).fill(0); // array_fill(0, $count, 0);
        // Идём по радрядам начиная с наибольшего
        for (let i = count - 1; i >= 0; i--) {
            combinations[i] = Math.floor(step / weight[i]);
            step -= combinations[i] * weight[i];
        }
        const result = [];
        for (let i = 0; i < count; i++) {
            const _res = {
                serviceOptionTemplate: serviceOptionTemplates[i],
                valueByReference: _.get(serviceOptionTemplates[i], 'serviceOptionReference.values', [])[combinations[i]]
            };
            result[i] = _res;
        }
        return result;
    }

    renderTrack({
        style,
        ...props
    }) {
        const trackStyle = {};
        return (
            <div
                className={classes.scrollTrack}
                style={{
                    ...style,
                    ...trackStyle
                }}
                {...props}
            />
        );
    }

    renderThumb({
        style,
        ...props
    }) {
        const thumbStyle = {};
        return (
            <div
                className={classes.scrollThumb}
                style={{
                    ...style,
                    ...thumbStyle
                }}
                {...props}
            />
        );
    }

    UNSAFE_componentWillMount() {
        if (this.props.serviceTemplateForEdit && this.props.resources && !this.state.serviceTemplateForEdit && !this.state.serviceResources) {
            const serviceTemplateForEdit = this.normalizeServiceTemplateFromProp(this.props.serviceTemplateForEdit);
            const serviceResources = _.filter(_.cloneDeep(this.props.resources), r => _.some(r.services, s => s.serviceTemplate.id === this.props.serviceTemplateForEdit.id));
            this.setState({ serviceTemplateForEdit, serviceResources });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.serviceTemplateForEdit && !this.state.serviceTemplateForEdit && !this.state.serviceResources) {
            const serviceTemplateForEdit = this.normalizeServiceTemplateFromProp(nextProps.serviceTemplateForEdit, nextProps.resources);
            this.setState({ serviceTemplateForEdit, serviceResources: [] });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fetching && !this.props.fetching) {
            this.props.closeEditor();
        }
    }

    refreshHandler(company) {
        this.props.fetchCatalogues({
            root_only: 1,
            expand: 'childrenCatalogues,businessType,image,thumbnails,serviceTemplates,serviceOptionTemplates,reference,values',
            business_type_id: company.angaraInfo.businessType.id
        });
    }

    toEditService(fieldName, e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        this.setState({ onEdit: fieldName });
    }

    editService(field, value, e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        const service = this.state.service;
        service[field] = value;
        this.setState({ service });
    }

    showErrors(service, e) {
        e.preventDefault();
        e.stopPropagation();

        if (!service.name) {
            this.props.responseError('Укажите название услуги', 'name');
        }
        if (this.state.service.isPeriodic !== false) {
            if (!service.repeatsDuringPeriod) {
                this.props.responseError('Укажите цикличность приглашений', 'repeats_during_period');
            }
        }

        if (!service.averageDuration) {
            this.props.responseError(' Укажите длительность услуги', 'average_duration');
        }

        if (!service.minPrice) {
            this.props.responseError('Укажите стоимость услуги', 'min_price');
        }
    }

    saveChanges() {
        const serviceTemplate = this.state.serviceTemplateForEdit;

        const changedServicesIds = [];
        // создаём новые услуги
        const servicesToCreate = _.chain(serviceTemplate.availableServices).filter(aS => aS.connected && !aS.id).each(s => (s.comment = serviceTemplate.comment)).value();
        if (servicesToCreate.length) {
            this.props.createServices(servicesToCreate).then((res) => {
                _.each(res.items, (item, key) => {
                    const newId = item.id;

                    const preId = servicesToCreate[key].uniqueId;
                    if (newId && preId) {
                        changedServicesIds.push({ id: newId, preId });
                    }
                });
                _.each(this.state.serviceResources, (r) => {
                    r.services = _.filter(r.services, s => !s.removed);
                    let rServicesToUpdate;
                    if (r.removed) {
                        rServicesToUpdate = _.filter(r.services, s => !_.some(serviceTemplate.availableServices, { id: s.id }));
                    } else {
                        rServicesToUpdate = r.services;
                    }
                    _.each(changedServicesIds, (cService) => {
                        const keyService = _.findKey(rServicesToUpdate, s => !s.id && s.uniqueId === cService.preId);
                        if (keyService) {
                            rServicesToUpdate[keyService].id = cService.id;
                        }
                    });

                    this.props.updateResource({ id: r.id, angara_id: r.angara_id, services: rServicesToUpdate });
                });
                // TODO обработка создания кастомных услуг без обновления
                if (_.some(servicesToCreate, { serviceTemplateId: null })) {
                    this.refreshHandler(this.props.company);
                }
            });
        } else {
            _.each(this.state.serviceResources, (r) => {
                r.services = _.filter(r.services, s => !s.removed);

                let rServicesToUpdate;
                if (r.removed) {
                    rServicesToUpdate = _.filter(r.services, s => !_.some(serviceTemplate.availableServices, { id: s.id }));
                } else {
                    rServicesToUpdate = r.services;
                }
                this.props.updateResource({ id: r.id, angara_id: r.angara_id, services: rServicesToUpdate });
            });
        }

        _.each(serviceTemplate.availableServices, (s) => {
            if (s.connected) {
                s.comment = serviceTemplate.comment;
                if (s.id) {
                    this.props.updateService(s);
                    // обновляем темплэйт, ежели кастомный
                    if (s.isCustom) {
                        this.props.fetchCatalogues({
                            root_only: 1,
                            expand: 'childrenCatalogues,businessType,image,thumbnails,serviceTemplates,serviceOptionTemplates,reference,values',
                            business_type_id: this.props.company.angaraInfo.businessType.id
                        });
                    }
                }
            } else if (s.id) {
                this.props.deleteService(s.id);
            }
        });
        // удаляем присланные услуги, которые возможно пропустили из-за возможно некорректных наборов опций
        _.each(serviceTemplate.services, (s) => {
            if (!_.some(serviceTemplate.availableServices, { uniqueId: s.uniqueId })) {
                this.props.deleteService(s.id);
            }
        });
    }

    imageWillSave(data, ready) {
        this.showLoader();
        ready(data);
    }

    imageDidUpload(error, data, response) {
        this.hideLoader();
        // this.props.addServicePriceList(_.get(response, 'avatar.id', null));
    }

    deleteServicePriceList(data, remove, serviceId) {
        if (window.confirm('Удалить прайслист услуги?')) {
            this.props.deleteServicePriceList(serviceId);
        } else {
            return false;
        }
    }

    showLoader() {

    }

    hideLoader() {

    }

    render() {
        const serviceTemplate = _.clone(this.state.serviceTemplateForEdit);

        const resources = this.props.resources;

        const serviceResources = _.clone(this.state.serviceResources);

        // just - если речь идёт об одной услуге, нет опций

        const justService = (serviceTemplate && serviceTemplate.availableServices && serviceTemplate.availableServices.length === 1)
            ? _.find(serviceTemplate.availableServices)
            : false;

        const justServiceNotificationKey = justService && justService.id && justService.isPeriodic
            ? _.findKey(this.props.company.notifications, { service_id: justService.id })
            : null;

        const justServiceNotification = justServiceNotificationKey
            ? this.props.company.notifications[justServiceNotificationKey]
            : (
                justService
                    ? {
                        service_id: justService.id
                    }
                    : null);

        if (!serviceTemplate || !serviceResources) {
            return null;
        }
        const logo = _.get(serviceTemplate, 'image.url', false);
        return (
            <div
                className={classes.editorForService}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div id='maxWidth' className={classes.header}>
                    <div className={classes.logo}>
                        {
                            logo
                                ? (<img src={logo} alt='' />)
                                : null
                        }
                    </div>
                    <div className={classes.h2}>Подключение и редактирование услуг</div>
                    <div className={classes.catalogue}>
                        Подраздел
                        { ' ' }
                        { _.get(serviceTemplate.catalogue, 'name', 'Салоны красоты').toLowerCase() }
                    </div>

                </div>
                { /* основной блок редактирования одинчоной услуги */ }
                <div className={`${classes.body} row`}>
                    <div className='col-md-5'>
                        { /* название услуги */ }
                        <div className={classes.serviceName}>
                            <div className={classes.label}>Название услуги</div>
                            {
                                justService && justService.isCustom
                                    ? (
                                        <input
                                            type='text'
                                            name='name'
                                            value={justService.name || ''}
                                            onChange={(e) => {
                                                justService.name = e.target.value;
                                                this.setState({ serviceTemplateForEdit: serviceTemplate });
                                            }}
                                        />
                                    )
                                    : (<div className={classes.notInput}>{ this.state.serviceTemplateForEdit.name }</div>)
                            }
                            { justService
                                ? (
                                    <div>
                                        <div>Прайс-лист</div>
                                        <SlimDecorator
                                            uploadPath={process.env.REACT_APP_API_PATH + '/v3/partner/services/' + justService.id + '/price-list'}
                                            remove={(data, remove) => {
                                                justService.pricelist = null;
                                                this.setState({ serviceTemplateForEdit: serviceTemplate });
                                                this.deleteServicePriceList(data, remove, justService.id);
                                            }}
                                            didUpload={(data, ready, file) => {
                                                justService.pricelist = file;
                                                this.setState({ serviceTemplateForEdit: serviceTemplate });
                                                this.props.updatePriceList(justService.id, file);
                                            }}
                                            willSave={() => null}
                                            willRemove={() => null}
                                            img={_.get(justService, 'pricelist.url')}
                                        />
                                    </div>
                                ) : null }

                        </div>
                    </div>
                    <div className='col-md-7'>
                        {
                            justService
                                ? (
                                    <div className={classes.justServiceRow}>
                                        <div className='col-xs-5'>
                                            <SetCyclic
                                                value={{
                                                    isPeriodic: _.get(justService, 'isPeriodic', false),
                                                    repeats_during_period: _.get(justService, 'repeats_during_period', false) || _.get(justServiceNotification, 'repeats_during_period', 1),
                                                    period: _.get(justService, 'period', false) || _.get(justServiceNotification, 'period', 30)
                                                }}
                                                onChange={(cyclic) => {
                                                    _.each(cyclic, (cValue, cKey) => {
                                                        justService[cKey] = cValue;
                                                        justServiceNotification[cKey] = cValue;
                                                        this.props.changeCompany(`notifications.${justServiceNotificationKey}.${cKey}`, cValue);
                                                    });
                                                    this.setState({ serviceTemplate });
                                                }}
                                            />
                                        </div>
                                        <div className='col-xs-3'>
                                            <SelectDurationMenu
                                                value={justService.averageDuration}
                                                onChange={(duration) => {
                                                    justService.averageDuration = duration;
                                                    this.setState({ serviceTemplate });
                                                }}
                                            />
                                        </div>
                                        <div className='col-xs-4'>
                                            { /* Стоимость */ }
                                            <div className={cn({
                                                [classes.inputGroup]: true,
                                                [classes.disabled]: false,
                                                [classes.price]: true
                                            })}
                                            >
                                                <div className={`${classes.title} ${classes.row}`}>Стоимость:</div>
                                                <div className={classes.changeValue}>
                                                    <input
                                                        type='number'
                                                        name='minPrice'
                                                        onClick={e => e.stopPropagation()}
                                                        value={justService.minPrice}
                                                        onChange={(e) => {
                                                            justService.minPrice = e.target.value;
                                                            this.setState({ serviceTemplate });
                                                        }}
                                                        onKeyPress={(e) => {
                                                            if (e.key === 'Enter') {
                                                                this.toEditService(null);
                                                            }
                                                        }}
                                                    />
                                                    &nbsp;руб.
                                                    {
                                                        this.props.error === 'minPrice'
                                                            ? (
                                                                <div className={classes.errorMenu}>В этом поле можно ввести
                                                                    только целое число
                                                                </div>
                                                            )
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                : null
                        }

                        <div className='col-xs-12'>
                            <div className={`${classes.inputGroup} ${classes.comment}`}>
                                <label>Комментарий к
                                    услуге { (serviceTemplate.comment && serviceTemplate.comment.length) ? '(осталось символов ' + (COMMENT_LIMIT - serviceTemplate.comment.length) + ')' : '' }
                                </label>
                                <textarea
                                    rows='4'
                                    name='comment'
                                    maxLength='255'
                                    value={serviceTemplate.comment || ''}
                                    onChange={(e) => {
                                        serviceTemplate.comment = e.target.value;
                                        this.setState({ serviceTemplateForEdit: serviceTemplate });
                                    }}
                                />
                            </div>
                        </div>

                    </div>

                </div>
                { /* блок для нагенерированных по опциям услуг */ }
                {
                    serviceTemplate.availableServices && serviceTemplate.availableServices.length > 1
                        ? (
                            <div className={`${classes.body} ${classes.options}`}>
                                <div className={classes.h2}>Опции услуги</div>
                                <div className={classes.h3}>название опции</div>
                                <div className={classes.aServices}>
                                    {
                                        _.map(serviceTemplate.availableServices, (s, key) => (
                                            <div className={classes.aService} key={key}>
                                                <div className='col-xs-5'>
                                                    <div
                                                        className={`${HELP_CSS.hlpCheckbox} ${
                                                            s.connected
                                                                ? 'is_on'
                                                                : ''}`}
                                                        onClick={() => {
                                                            s.connected = !s.connected;
                                                            this.setState({ serviceTemplate });
                                                        }}
                                                    />
                                                    <div className={classes.sOptions}>
                                                        {
                                                            _.map(s.serviceOptions, (so, key) => (
                                                                <div className={classes.item} key={key}>
                                                                    { so.valueByReference.value }
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-xs-3'>
                                                    <SetCyclic
                                                        noTitle='noTitle'
                                                        value={{
                                                            isPeriodic: _.get(s, 'isPeriodic', false),
                                                            repeatsDuringPeriod: _.get(s, 'repeatsDuringPeriod', 1),
                                                            period: _.get(s, 'period', 30)
                                                        }}
                                                        onChange={(cyclick) => {
                                                            _.each(cyclick, (cValue, cKey) => {
                                                                s[cKey] = cValue;
                                                            });
                                                            this.setState({ serviceTemplate });
                                                        }}
                                                    />
                                                </div>
                                                <div className='col-xs-2'>
                                                    <SelectDurationMenu
                                                        noTitle='noTitle'
                                                        value={s.averageDuration}
                                                        onChange={(duration) => {
                                                            s.averageDuration = duration;
                                                            this.setState({ serviceTemplate });
                                                        }}
                                                    />
                                                </div>
                                                <div className='col-xs-2 pull-right'>
                                                    { /* Стоимость */ }
                                                    <div className={cn({
                                                        [classes.inputGroup]: true,
                                                        [classes.price]: true
                                                    })}
                                                    >
                                                        <div className={classes.changeValue}>
                                                            <input
                                                                type='number'
                                                                name='minPrice'
                                                                autoFocus
                                                                onClick={e => e.stopPropagation()}
                                                                value={s.minPrice || serviceTemplate.minPrice || 0}
                                                                onChange={(e) => {
                                                                    s.minPrice = e.target.value;
                                                                    this.setState({ serviceTemplate });
                                                                }}
                                                            />
                                                            &nbsp;руб.
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        )
                        : null
                }

                { /* блог с ресурсами и настройками под них услуг */ }
                <div className={`${classes.body} ${classes.resources}`}>
                    {
                        serviceResources && serviceResources.length
                            ? <div className={classes.h2}>Прикреплённые мастера</div>
                            : null
                    }
                    {
                        _.map(serviceResources, (resource) => {
                            if (resource.removed) {
                                return null;
                            }
                            const imageUrl = _.get(_.find(_.get(resource, 'thumbnails', []), { templateName: 's1' }), 'url');

                            // в случае, если услуга одна (темплэйт без опций), то ищем её среди услуг ресурса
                            const resourceJustService = justService
                                ? _.find(resource.services, rs => (rs.id && rs.id === justService.id) || rs.uniqueId === justService.uniqueId)
                                : null;

                            return (
                                <div key={resource.id} className={classes.resource}>
                                    <div className={`${classes.info} col-md-6`}>
                                        <div className={classes.image}>
                                            <div className={classes.wrapper}>
                                                {
                                                    imageUrl
                                                        ? (<img src={imageUrl} alt='' />)
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <div className={classes.title}>{ resource.name }</div>
                                        <div
                                            className={HELP_CSS.hlpRemoveIcon}
                                            onClick={() => {
                                                if (window.confirm('Вы уверены, что мастер не выполняет эту услугу (услуги) отныне?')) {
                                                    resource.removed = true;
                                                    this.setState({ serviceRecources: serviceResources });
                                                }
                                            }}
                                        />
                                    </div>
                                    {
                                        this.state.serviceTemplateForEdit.availableServices.length > 1
                                            ? (
                                                <div
                                                    className={`${classes.showResourceServices} col-md-6 pull-right`}
                                                    onClick={() => {
                                                        this.setState({
                                                            expandResourceServices: this.state.expandResourceServices === resource.angara_id
                                                                ? false
                                                                : resource.angara_id
                                                        });
                                                    }}
                                                >
                                                    Персональная настройка услуг&nbsp;
                                                    <div className={HELP_CSS.hlpSelectIcon} />
                                                </div>
                                            )
                                            : (
                                                <span>
                                                    <div
                                                        className='serviceProperty--resources__resource__duration col-md-3'
                                                    >
                                                        <SelectDurationMenu
                                                            noTitle='noTitle'
                                                            value={(resourceJustService && resourceJustService.duration) || justService.averageDuration}
                                                            onChange={(duration) => {
                                                                resourceJustService.duration = duration;
                                                                this.setState({ serviceResources });
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className='serviceProperty--resources__resource__price col-md-3'
                                                    >
                                                        <div className={cn({
                                                            [classes.inputGroup]: true,
                                                            [classes.price]: true
                                                        })}
                                                        >
                                                            <div className={classes.changeValue}>
                                                                <input
                                                                    type='number'
                                                                    name='minPrice'
                                                                    onClick={e => e.stopPropagation()}
                                                                    value={_.get(resourceJustService, 'price', justService.minPrice)}
                                                                    onChange={(e) => {
                                                                        resourceJustService.price = parseInt(e.target.value) || 0;
                                                                        this.setState({ serviceResources });
                                                                    }}
                                                                />
                                                                &nbsp;руб.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            )
                                    }
                                    { /* настройки подключенных услуг под конкретный ресурс */ }
                                    {
                                        this.state.expandResourceServices === resource.angara_id
                                            ? (
                                                <div className={classes.resourceServices}>
                                                    {
                                                        _.map(serviceTemplate.availableServices, (s) => {
                                                            const rServiceKey = _.findKey(resource.services, rs => (rs.id && rs.id === s.id) || rs.uniqueId === s.uniqueId);
                                                            return (
                                                                <div className={classes.service} key={s.uniqueId}>
                                                                    <div className={`${classes.title} col-xs-8`}>
                                                                        <div
                                                                            className={HELP_CSS.hlpCheckbox + (
                                                                                (rServiceKey && !resource.services[rServiceKey].removed)
                                                                                    ? ' is_on'
                                                                                    : '')}
                                                                            onClick={() => {
                                                                                if (rServiceKey) {
                                                                                    resource.services[rServiceKey].removed = !resource.services[rServiceKey].removed;
                                                                                } else {
                                                                                    resource.services.push({
                                                                                        id: s.id,
                                                                                        uniqueId: s.uniqueId,
                                                                                        price: s.minPrice,
                                                                                        duration: s.averageDuration,
                                                                                        serviceTemplate: {
                                                                                            id: serviceTemplate.id
                                                                                        }
                                                                                    });
                                                                                }
                                                                                s.connected = true;
                                                                                this.setState({
                                                                                    serviceTemplateForEdit: serviceTemplate,
                                                                                    serviceResources
                                                                                });
                                                                            }}
                                                                        />
                                                                        <div
                                                                            className={`${classes.title} ${HELP_CSS.hlpInline}`}
                                                                        >
                                                                            {
                                                                                _.map(s.serviceOptions, so => so.valueByReference.value).join('/')
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className={`${classes.duration} col-md-2`}>
                                                                        <SelectDurationMenu
                                                                            noTitle='noTitle'
                                                                            value={(rServiceKey && resource.services[rServiceKey].duration) || s.averageDuration}
                                                                            onChange={(duration) => {
                                                                                if (rServiceKey) {
                                                                                    resource.services[rServiceKey].duration = duration;
                                                                                } else {
                                                                                    resource.services.push({
                                                                                        id: s.id,
                                                                                        uniqueId: s.unique.id,
                                                                                        price: s.minPrice,
                                                                                        duration
                                                                                    });
                                                                                }
                                                                                resource.services[rServiceKey].duration = duration;
                                                                                this.setState({ serviceResources });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className={`${classes.price} col-md-2`}>
                                                                        <div className={cn({
                                                                            [classes.inputGroup]: true,
                                                                            [classes.price]: true
                                                                        })}
                                                                        >
                                                                            <div className={classes.changeValue}>
                                                                                <input
                                                                                    type='number'
                                                                                    name='minPrice'
                                                                                    onClick={e => e.stopPropagation()}
                                                                                    value={(rServiceKey && resource.services[rServiceKey].price) || s.minPrice}
                                                                                    onChange={(e) => {
                                                                                        if (rServiceKey) {
                                                                                            resource.services[rServiceKey].price = parseInt(e.target.value) || 0;
                                                                                        } else {
                                                                                            resource.services.push({
                                                                                                id: s.id,
                                                                                                uniqueId: s.unique.id,
                                                                                                price: parseInt(e.target.value),
                                                                                                duration: s.averageDuration
                                                                                            });
                                                                                        }
                                                                                        this.setState({ serviceResources });
                                                                                    }}
                                                                                />
                                                                                &nbsp;руб.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }

                                                </div>
                                            )
                                            : null
                                    }

                                </div>
                            );
                        })
                    }
                    <div className={classes.available}>
                        <div
                            className={classes.add}
                            onClick={() => {
                                this.setState({
                                    chooseResource: !this.state.chooseResource
                                });
                            }}
                        >
                            <span className={HELP_CSS.hlpAddIconBig} />
                            &nbsp; Выбрать мастера
                        </div>
                    </div>
                    {
                        this.state.chooseResource
                            ? (
                                <Resources
                                    resources={this.props.resources}
                                    selectedResources={_.map(_.filter(serviceResources, r => !r.removed), r => r.id)}
                                    selectHandler={(resourceId) => {
                                        const resource = _.find(resources, { angara_id: resourceId }) || false;

                                        const availableServices = _.clone(this.state.serviceTemplateForEdit.availableServices);

                                        const serviceResources = _.clone(this.state.serviceResources);
                                        if (!_.some(serviceResources, { angara_id: resourceId })) {
                                            serviceResources.push(_.pick(resource, [
                                                'id',
                                                'angara_id',
                                                'name',
                                                'imageUrl',
                                                'services',
                                                'thumbnails'
                                            ]));
                                        }
                                        const sResource = _.find(serviceResources, { angara_id: resourceId });
                                        sResource.removed = false;
                                        if (resource) {
                                            _.each(availableServices, (aService) => {
                                                if (aService.connected) {
                                                    if (!_.some(aService.resources, { id: resource.angara_id })) {
                                                        aService.resources.push({ id: resource.angara_id });
                                                    }
                                                }
                                                if (aService.connected && !_.some(sResource.services, {
                                                    id: aService.id,
                                                    uniqueId: aService.uniqueId
                                                })) {
                                                    sResource.services.push({
                                                        id: aService.id,
                                                        uniqueId: aService.uniqueId,
                                                        price: aService.minPrice,
                                                        duration: aService.averageDuration,
                                                        serviceTemplate: {
                                                            id: serviceTemplate.id
                                                        }
                                                    });
                                                }
                                            });
                                        }
                                        const serviceTemplateForEdit = _.clone(this.state.serviceTemplateForEdit);
                                        serviceTemplateForEdit.availableServices = availableServices;
                                        this.setState({ serviceTemplateForEdit, serviceResources });
                                    }}
                                />
                            )
                            : null
                    }

                </div>
                <div className={classes.footer}>
                    {
                        justService && justService.id
                            ? (
                                <div className={classes.leftButtons}>
                                    <div
                                        className={classes.deleteButton}
                                        onClick={() => {
                                            if (window.confirm('Вы уверенны, что хотите отключить данную услугу?')) {
                                                this.props.deleteService(justService.id);
                                            }
                                        }}
                                    >
                                        Отключить
                                    </div>
                                </div>
                            )
                            : null
                    }
                    { /* завершающие кнопки */ }
                    <div className={classes.rightButtons}>
                        <div className={classes.cancel} onClick={this.props.closeEditor.bind(this)}>отмена</div>
                        <div
                            className={cn({
                                [classes.addButton]: true,
                                [classes.disabled]: false
                            })}
                            onClick={this.saveChanges.bind(this)}
                        >
                            Сохранить изменения
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Editor;
