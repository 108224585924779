import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import ChangePassword from './Password/ChangePassword';
import _ from 'lodash';

require('./Header.scss');

var clickOnHeader = false;
var hideListstimer;
class Header extends React.Component {
    logOut(e) {
        e.preventDefault();
        this.props.fetchLogout().then(() => this.props.history.push('/login'));
    }

    componentDidMount() {
        const header = document.getElementById('pageHeader');
        header.onclick = function() {
            clickOnHeader = true;
        };
        const el = document.getElementById('root');
        el.onclick = function(e) {
            if (!clickOnHeader) {
                this.hideHeader(e);
            }
            clickOnHeader = false;
        }.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.passwordMenu && !this.props.passwordMenu) {
            const body = document.querySelectorAll('body');

            const html = document.querySelectorAll('html');
            if (body[0] && body[0].style) {
                body[0].style.overflowY = 'hidden';
            }
            if (html[0] && html[0].style) {
                html[0].style.overflowY = 'hidden';
            }
        }
        if (!nextProps.passwordMenu && this.props.passwordMenu) {
            const body = document.querySelectorAll('body');

            const html = document.querySelectorAll('html');
            if (body[0] && body[0].style) {
                body[0].style.overflowY = 'visible';
            }
            if (html[0] && html[0].style) {
                html[0].style.overflowY = 'visible';
            }
        }
    }

    hideHeader() {
        this.props.showHeader('hide');
    }

    openHeader(e) {
        e.stopPropagation();
        this.props.showHeader();
    }

    componentWillUnmount() {
        clearTimeout(hideListstimer);
        const header = document.getElementById('pageHeader');
        header.onclick = null;
        const el = document.getElementById('root');
        el.onclick = null;
    }

    render() {
        /*        function hideLists() {
         document.getElementById('headerLists').style.top = -150 + 'px';
         }

         if (!this.props.showHeaderMenu) {
         hideListstimer = setTimeout(hideLists, 400);
         } else {
         document.getElementById('headerLists').style.top = 57 + 'px';
         } */
        return (
            <div id='pageHeader' onClick={this.openHeader.bind(this)}>
                <div className={this.props.showHeaderMenu ? 'fullHeader' : 'html__header'}>
                    <div className='html__header-wrapper'>
                        <div className='html__header-wrapper__logo'>
                            <div
                                className='html__header-wrapper__logo__hamburger'
                                onClick={this.openHeader.bind(this)}
                            >
                                <span className={this.props.showHeaderMenu ? 'active' : ''} />
                                <span className={this.props.showHeaderMenu ? 'active' : ''} />
                                <span className={this.props.showHeaderMenu ? 'active' : ''} />
                            </div>
                            <NavLink to='/' className='html__header-wrapper__logo__home'>Connect <span>Pro</span></NavLink>
                        </div>
                        <div
                            className='html__header-wrapper__links' onClick={(e) => {
                                e.stopPropagation();
                                if (this.props.showHeaderMenu) {
                                    this.props.showHeader('hide');
                                }
                            }}
                        >
                            <NavLink
                                className='html__header-wrapper__links__link html__header-wrapper__links__link--clients'
                                exact
                                activeClassName='active'
                                to='/'
                            >Онлайн запись
                            </NavLink>

                            { _.get(this.props.partner, 'role') === 'PARTNER'
                                ? (
                                    <NavLink
                                        className='html__header-wrapper__links__link html__header-wrapper__links__link--clients'
                                        activeClassName='active'
                                        to='/clients'
                                    >
                                        Клиенты
                                    </NavLink>
                                )
                                : null }
                            { /*                            <Link
                                className="html__header-wrapper__links__link html__header-wrapper__links__link--callcenter"
                                activeClassName="active"
                                to="/callcenter">Call центр</Link> */ }
                            <NavLink
                                className='html__header-wrapper__links__link html__header-wrapper__links__link--orders'
                                activeClassName='active'
                                to='/orders'
                            >Заказы
                            </NavLink>
                            { _.get(this.props.partner, 'role') === 'PARTNER'
                                ? (
                                    <NavLink
                                        className='html__header-wrapper__links__link html__header-wrapper__links__link--statistics'
                                        activeClassName='active'
                                        to='/statistics'
                                    >
                                        Аналитика
                                    </NavLink>
                                )
                                : null }
                            <NavLink
                                className='html__header-wrapper__links__link html__header-wrapper__links__link--feedback'
                                activeClassName='active'
                                to='/feedback'
                            >Обратная связь
                            </NavLink>
                        </div>
                        <div
                            id='headerLists'
                            className={'html__header-wrapper__lists' + (this.props.showHeaderMenu ? '' : ' hidden')}
                        >
                            <div className='list list--settings'>
                                <div
                                    className='list__title list__title--settings'
                                >
                                    Настройки
                                </div>
                                <div className='list__block'>
                                    <NavLink
                                        className='list__link'
                                        activeClassName='active'
                                        to='/settings/common'
                                    >Общие
                                    </NavLink>
                                    <NavLink
                                        className='list__link'
                                        activeClassName='active'
                                        to='/settings/services'
                                    >Услуги
                                    </NavLink>
                                    <NavLink
                                        className='list__link'
                                        activeClassName='active'
                                        to='/settings/posts'
                                    >
                                        { _.get(this.props.company, 'angaraInfo.resourceTitlePlural', 'Мастера') }
                                    </NavLink>
                                </div>
                                <div className='list__block'>
                                    <NavLink
                                        className='list__link'
                                        activeClassName='active'
                                        to='/settings/connect-pro'
                                    >Онлайн запись
                                    </NavLink>
                                    { _.get(this.props.partner, 'role') === 'PARTNER'
                                        ? (
                                            <NavLink
                                                className='list__link'
                                                activeClassName='active'
                                                to='/widgets'
                                            >
                                                Виджеты
                                            </NavLink>
                                        )
                                        : null }
                                    <NavLink
                                        className='list__link'
                                        activeClassName='active'
                                        to='/okk'
                                    >ОКК
                                    </NavLink>
                                </div>
                            </div>
                            <div className='list list--account'>
                                <div
                                    className='list__title list__title--account'
                                >
                                    Аккаунт
                                </div>
                                <div
                                    className='list list__link list__link__account list__link__account--password'
                                    onClick={this.props.passwordMenuShow.bind(this)}
                                >Смена пароля
                                </div>
                                <div
                                    className='list list__link list__link__account list__link__account--logout'
                                    onClick={this.logOut.bind(this)}
                                >Выход
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { this.props.passwordMenu && <ChangePassword {...this.props} /> }
            </div>
        );
    }
}

Header.contextTypes = {
    router: PropTypes.object
};

export default Header;
