/**
 * Created by user on 19.07.16.
 */
import { connect } from 'react-redux';
import { fetchClientOrdersAndCalls, shadeMenuChange } from 'modules/clients';

import ClientHistory from './ClientHistory';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => ({
    client: state.clients.client,
    clientOrdersAndCalls: state.clients.clientOrdersAndCalls,
    shadeMenuStatus: state.clients.shadeMenuStatus,
    company: state.company.company
});

export default connect(mapStateToProps, Object.assign({}, { fetchClientOrdersAndCalls, shadeMenuChange }))(withRouter(ClientHistory));
