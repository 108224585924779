// import { injectReducer } from 'store/reducers';
//
// export default (store) => ({
//
//    path: 'reports',
//
//    getComponent(nextState, cb) {
//        // Проверяем авторизован ли пользователь и открываем соответствующую страницу
//        require.ensure([], (require) => {
//            /*  Webpack - use require callback to define
//             dependencies for bundling   */
//            const Reports = require('./containers/OkkContainer').default;
//            const reducer = require('../modules/okk').default;
//            injectReducer(store, { key: 'okk', reducer: reducer });
//            /*  Return getComponent   */
//            cb(null, Reports);
//            /* Webpack named bundle   */
//        }, 'Reports');
//    }
// });

import Layout from './Layout';
import Reports from './Reports';
import ReportByAnswer from './ReportByAnswer';

export const OkkReportsRoute = (store) => ({
    path: 'reports',
    component: Layout,
    indexRoute: Reports(store),
    childRoutes: [
        ReportByAnswer(store)
    ]
});

export default OkkReportsRoute;
