/**
 * Created by user on 26.05.2016.
 */

import { connect } from 'react-redux';

import Division from './Division';

import { setOrderDay, chooseToOrder } from 'modules/orders';

const mapStateToProps = (state) => ({
    services: state.company.services,
    schedules: state.company.schedules,
    toOrder: state.orders.toOrder
});

export default connect(mapStateToProps, { setOrderDay, chooseToOrder })(Division);
