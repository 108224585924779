import { connect } from 'react-redux';
import { actions } from '../../modules/statistics';

import ByResources from './ByResources';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => ({
    statistics: state.statistics.statistics,
    fetching: state.statistics.fetching
});

export default connect(mapStateToProps, actions)(withRouter(ByResources));
