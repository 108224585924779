import React from 'react';
import classes from '../../Calendar.module.scss';
import InputElement from 'react-input-mask';
import { getBlockPosition, getErrorOutput, isEmpty } from 'utils';
import _ from 'lodash';

class CreateClientModal extends React.Component {
    UNSAFE_componentWillMount() {
        this.props.clearAjaxError();
    }

    componentWillUnmount() {
        this.props.clearAjaxError();
    }

    createClient(e) {
        if (e) {
            e.preventDefault();
        }
        this.props.clearAjaxError();
        this.props.createClient().then(() => {
            this.props.closeHandler();
            if (this.props.createHandler) {
                this.props.createHandler();
            }
        });
    }

    render() {
        // ошибки не выводятся, Леша все поломал
        // Клевета!!! Ошибок не будет отныне, бо не дадим юзеру бестолковому творить непотребное!!!
        // Я здесь был, шатал ваш код)
        let createError = null;
        if (this.props.error) {
            createError = _.get(this.props.error, 'response.message', null);
        }
        const nickError = this.props.errorMessage.nick &&
                (<div className={classes.error}>{ getErrorOutput(this.props.errorMessage.nick[0]) }</div>);

        const phoneError = this.props.errorMessage.phone &&
                (<div className={classes.error}>{ getErrorOutput(this.props.errorMessage.phone[0]) }</div>);

        const readyToAddClient = !isEmpty(this.props.newClientPhone) &&
            !isEmpty(this.props.newClientNick) &&
            this.props.newClientPhone.replace(/([^0-9+]*)/ig, '').length === 12;

        return (
            <div className={classes.black} onClick={() => { this.props.closeHandler(); }}>
                <div className={classes.wrapper} style={getBlockPosition(500, 580)} onClick={e => { e.stopPropagation(); }}>
                    <div className={classes.addClient}>
                        <div className={classes.addClientIcon} />
                        <div className={classes.addClientGradient} />
                        <div className={classes.addClientMain}>
                            <div className={classes.addClientTitle}>Добавление клиента</div>
                            <div className={classes.data}>
                                <div className={classes.raw}>
                                    <div className={classes.label}>Телефон:</div>
                                    <InputElement
                                        className={classes.phone}
                                        type='text'
                                        name='newClientPhone'
                                        autoFocus
                                        value={this.props.newClientPhone}
                                        onChange={this.props.changeValue}
                                        placeholder='+7 (XXX) XXX-XX-XX'
                                        mask='+7 (999) 999-99-99'
                                    />
                                    { phoneError }
                                </div>
                                <div className={classes.raw}>
                                    <div className={classes.label}>Имя клиента:</div>
                                    <input
                                        type='text'
                                        name='newClientNick'
                                        value={this.props.newClientNick}
                                        onChange={this.props.changeValue}
                                    />
                                    { nickError }
                                </div>
                                <div className={classes.raw}>
                                    <div className={classes.label}>Как обращаться:</div>
                                    <input
                                        type='text'
                                        name='newClientMessageName'
                                        value={this.props.newClientMessageName}
                                        onChange={this.props.changeValue}
                                    />
                                    { nickError }
                                </div>
                                <div className={classes.raw}>
                                    <div className={classes.label}>Комментарий:</div>
                                    <textarea
                                        rows='3'
                                        name='newClientComment'
                                        value={this.props.newClientComment}
                                        onChange={this.props.changeValue}
                                    />
                                    <div className={classes.error}>
                                        { createError }
                                    </div>
                                </div>
                            </div>
                            <div className={classes.cancel} onClick={() => { this.props.closeHandler(); }}>отмена</div>
                            <div
                                className={classes.addClientButton + ' ' + (readyToAddClient ? '' : classes.disabled)}
                                onClick={readyToAddClient ? this.createClient.bind(this) : null}
                            >
                                Добавить клиента
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateClientModal;
