import { NavLink, withRouter } from 'react-router-dom';
import React from 'react';
import _ from 'lodash';

require('./pager.scss');

class Pager extends React.Component {
    state = {
        countIn: 2,
        countOut: 1
    };

    render() {
        const query = this.props.location.query;

        const page = (query && parseInt(query.page)) || 1;

        const total = parseInt(this.props.total);

        const size = parseInt(this.props.size);

        const totalPages = Math.ceil(total / size);

        const current = Math.min(Math.max(1, page), Math.max(1, totalPages));

        const n1 = 1;

        const n2 = Math.min(this.state.countOut, totalPages);

        const n7 = Math.max(1, totalPages - this.state.countOut + 1);

        const n4 = Math.max(n2 + 1, current - this.state.countIn);

        const n5 = Math.min(n7 - 1, current + this.state.countIn);

        const useMiddle = (n5 >= n4);

        const n3 = parseInt((n2 + n4) / 2);

        const useN3 = (useMiddle && ((n4 - n2) > 1));

        const n6 = parseInt((n5 + n7) / 2);

        const useN6 = (useMiddle && ((n7 - n5) > 1));

        const links = [];

        let pages = [];

        let i;

        let params;
        for (i = n1; i <= n2; i++) {
            links[i] = i;
        }
        if (useN3) {
            params = _.assign({}, query);
            params.page = n3;
            links[n3] = (
                <div key={n3} className='cmt-pager__page'>
                    <NavLink to={{ pathname: this.props.location.pathname, query: params }}>...</NavLink>
                </div>
            );
        }
        for (i = n4; i <= n5; i++) {
            links[i] = i;
        }
        if (useN6) {
            params = _.assign({}, query);
            params.page = n6;
            links[n6] = (
                <div key={n6} className='cmt-pager__page'>
                    <NavLink to={{ pathname: this.props.location.pathname, query: params }}>...</NavLink>
                </div>
            );
        }
        for (i = n7; i <= totalPages; i++) {
            links[i] = i;
        }

        if (totalPages > 1) {
            pages = _.map(links, link => {
                if (isNaN(link)) {
                    return link;
                } else {
                    params = _.assign({}, query);
                    params.page = link;
                    return (
                        <div
                            key={link}
                            className={'cmt-pager__page' + (parseInt(link) === current ? ' active' : '')}
                        >
                            <NavLink to={{ pathname: this.props.location.pathname, query: params }}>{ link }</NavLink>
                        </div>
                    );
                }
            });
        }
        return (<div className='cmt-pager'>{ pages }</div>);
    }
}

export default withRouter(Pager);
